import { createReducer, createAction } from '@reduxjs/toolkit';
import { builderFactory } from '../../../utils/reduxHelper';

import { InitialState } from './types';

const prefix = '@user';

export const fetchCurrentUser = createAction(`${prefix}/FETCH_CURRENT_USER`);
export const fetchMainProfile = createAction(`${prefix}/FETCH_MAIN_PROFILE`);
export const fetchChannelProfile = createAction(`${prefix}/FETCH_CHANNEL_PROFILE`);

const initialState: InitialState = {
  isLoading: false,
  currentUser: {},
  mainProfile: {
    id: 0,
    nickname: '',
    imageSrc: '',
    gender: '',
    birthYear: 0
  },
  channelProfiles: [],
  error: null
};

const reducer = createReducer(initialState, (builder) => {
  builderFactory(builder, [fetchCurrentUser, fetchMainProfile, fetchChannelProfile])
    .addCase(`${fetchCurrentUser}_COMPLETED`, (state, action: any) => {
      const { user } = action.payload.data.data;

      state.currentUser = user;
      state.channelProfiles = user.channelProfiles;
      state.mainProfile = user.defaultProfile;
    })
    .addCase(`${fetchMainProfile}_COMPLETED`, (state, action: any) => {
      const { user } = action.payload.data.data;

      state.mainProfile = user;
    })
    .addCase(`${fetchChannelProfile}_COMPLETED`, (state, action: any) => {
      const { user } = action.payload.data.data;
      state.channelProfiles = user.channelProfiles;
    })
    .addMatcher(
      (action) => action.type.startsWith(prefix) && action.type.endsWith('_COMPLETED'),
      (state) => {
        state.isLoading = false;
      }
    )
    .addMatcher(
      (action) => action.type.startsWith(prefix) && action.type.endsWith('_FAILED'),
      (state, action) => {
        state.isLoading = false;
        state.error = action.payload.data.message;
      }
    );
});

export default reducer;
