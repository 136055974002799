import IconType from '../IconType';

const Photo = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M19.4999,16.9998 C19.4999,17.8288 18.8289,18.4998 17.9999,18.4998 L5.9999,18.4998 C5.1719,18.4998 4.4999,17.8288 4.4999,16.9998 L4.4999,14.0148 L7.7599,11.3278 C8.5199,10.7018 9.6219,10.7218 10.3589,11.3748 L13.3539,14.0288 C13.7019,14.3378 14.2159,14.3658 14.5959,14.0968 L15.9499,13.1358 C16.6699,12.6248 17.6389,12.6468 18.3349,13.1888 L19.4999,14.0948 L19.4999,16.9998 Z M15.4999,6.9998 C16.3289,6.9998 16.9999,7.6718 16.9999,8.4998 C16.9999,9.3288 16.3289,9.9998 15.4999,9.9998 C14.6719,9.9998 13.9999,9.3288 13.9999,8.4998 C13.9999,7.6718 14.6719,6.9998 15.4999,6.9998 L15.4999,6.9998 Z M17.9999,3.9998 L5.9999,3.9998 C4.3429,3.9998 2.9999,5.3428 2.9999,6.9998 L2.9999,16.9998 C2.9999,18.6568 4.3429,19.9998 5.9999,19.9998 L17.9999,19.9998 C19.6569,19.9998 20.9999,18.6568 20.9999,16.9998 L20.9999,6.9998 C20.9999,5.3428 19.6569,3.9998 17.9999,3.9998 L17.9999,3.9998 Z"
      />
    </svg>
  );
};

export default Photo;
