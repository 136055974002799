import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import DateHelper from '@common/utils/dateHelper';
import styled from 'styled-components';

import { ContentBar } from '../../../components/molcules';

type ConfirmInfoProps = {
  selectDate: ReturnType<typeof DateHelper.getInstance>;
  selectTime: string;
  duration: number;
  requestComment?: string;
};

function ConfirmInfo({ selectDate, selectTime, duration, requestComment }: ConfirmInfoProps) {
  const { t } = useTranslation('translation');
  const startDate = selectDate.format('YYYY년 M월 D일');
  const history = useHistory();

  useEffect(() => {
    if (selectTime === '') {
      history.goBack();
    }
  }, [history, selectDate, selectTime]);

  return (
    <S_ConfirmInfoContainer>
      <ContentBar
        iconName="ic_Booking"
        title={t('str_14', '$t(str_papp_cbooking_rsv) 일시')}
        content={t('str_26', {
          startDate,
          startTime: selectTime,
          defaultValue: '{{startDate}} {{startTime}}'
        })}
      />
      <ContentBar
        iconName="ic_BookingTime"
        title={t('str_46')}
        content={t('str_42', { duration })}
      />
      <ContentBar
        iconName="ic_BookingTicket"
        title={t('str_papp_cbooking_ticket', '$t(str_papp_cbooking_ticket) 이력')}
        content={t('str_15', {
          ticketAmount: 1,
          defaultValue: '$t(str_papp_cbooking_ticket) {{ticketAmount}}장 차감'
        })}
      />
      <S_Divider />
      <ContentBar title={t('str_47', '요청 사항')} />
      <S_RequestText>{requestComment}</S_RequestText>
    </S_ConfirmInfoContainer>
  );
}

const S_ConfirmInfoContainer = styled.div`
  background-color: white;
`;

const S_Divider = styled.div`
  background: ${({ theme }) => theme.grey100};
  height: 1px;
  margin: 24px;
  width: 83%;
`;

const S_RequestText = styled.div`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  height: 100%;
  line-height: ${({ theme }) => theme.fontLineHeight};
  padding: 0 24px 15px 24px;
  text-align: left;
`;

export default ConfirmInfo;
