import { batchActions } from 'redux-batched-actions';

const prefix = '@middlewares/errorResponse';

const DIGEST_ERROR_RESPONSE = `${prefix}/DIGEST_ERROR_RESPONSE`;
const MSG_NON_SPECIFIED_ERROR = 'NON_SPECIFIED_ERROR';

export const digestErrorResponse = (response, originalType) => ({
  payload: response,
  type: DIGEST_ERROR_RESPONSE,
  meta: { originalType }
});

const createErrorResponseMiddleware = ({ digestError, digestAuthError }) => () => (next) => (
  action
) => {
  const { type, payload: response } = action;

  if (type !== DIGEST_ERROR_RESPONSE) {
    return next(action);
  }

  const standardizedErrorAction = {
    ...action,
    payload: response.data.error || {
      code: response.status,
      msg: MSG_NON_SPECIFIED_ERROR
    }
  };

  const errorAction = digestError(standardizedErrorAction, action.meta.originalType);

  if (digestAuthError && (response.status === 401 || response.status === 403)) {
    return next(batchActions([errorAction, digestAuthError(errorAction.payload)]));
  }

  return next(errorAction);
};

export default createErrorResponseMiddleware;
