import IconType from '../IconType';

const VideoPlay = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M4.8177,12.2848 L4.8177,6.5458 C4.8177,4.8438 6.6607,3.7798 8.1347,4.6308 L13.1057,7.4998 L18.0757,10.3698 C19.5497,11.2208 19.5497,13.3488 18.0757,14.1998 L13.1057,17.0698 L8.1347,19.9388 C6.6607,20.7908 4.8177,19.7268 4.8177,18.0238 L4.8177,12.2848 Z"
      />
    </svg>
  );
};

export default VideoPlay;
