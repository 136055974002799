import { put, call, fork, take, select } from 'redux-saga/effects';

import {
  addRecentDms,
  connectedNewClient,
  disconnectedClient,
  disconnectSocket,
  EVENT
} from '../reducers';
import {
  getCurrentCounterpartProfileId,
  getCurrentCounterpartLatestMessageTxid,
  getCounterpart
} from '../selectors';
import { getMyProfileIdByToken } from '../service';
import { watchFetchCounterparts, watchFetchAllRecentDms, watchAuthorize } from './watch';
import Socket from '../../../../services/socket';
import { Counterpart, Dm } from '../types';

export function* listenCreatedNewDm(socket: Socket) {
  const channel = socket.makeEventChannel(EVENT.CREATED_NEW_DM);

  while (true) {
    const payload: string = yield take(channel);
    const { dm }: { dm: Dm } = JSON.parse(payload).data;
    const { id: hasCounterpartInfo }: Counterpart = yield select(
      getCounterpart(dm.senderProfileId)
    );

    if (!hasCounterpartInfo) {
      yield call(watchFetchCounterparts);
    }

    yield put(
      addRecentDms({
        counterpartProfileId: dm.senderProfileId,
        messages: [dm],
        myProfileId: getMyProfileIdByToken()
      })
    );
  }
}

export function* listenNewClientInChannel(socket: Socket) {
  const channel = socket.makeEventChannel(EVENT.NEW_CLIENT_IN_CHANNEL);

  while (true) {
    const payload: string = yield take(channel);
    const ids: number[] = [JSON.parse(payload).profileId];

    yield put(
      connectedNewClient({
        data: ids,
        myProfileId: getMyProfileIdByToken()
      })
    );
  }
}

export function* listenClientOutInChannel(socket: Socket) {
  const channel = socket.makeEventChannel(EVENT.CLIENT_OUT_IN_CHANNEL);

  while (true) {
    const payload: string = yield take(channel);
    const ids: number[] = [JSON.parse(payload).profileId];

    yield put(
      disconnectedClient({
        data: ids,
        myProfileId: getMyProfileIdByToken()
      })
    );
  }
}

export function* listenAuth(socket: Socket) {
  const channel = socket.makeEventChannel(EVENT.AUTH_USER);

  while (true) {
    const payload: string = yield take(channel);
    const ids: number[] = JSON.parse(payload);

    yield put(connectedNewClient({ data: ids, myProfileId: getMyProfileIdByToken() }));
  }
}

export function* listenReconnect(socket: Socket) {
  const channel = socket.makeEventChannel(EVENT.RECONNECT);

  while (true) {
    yield take(channel);
    yield call(watchAuthorize, socket);

    const currentCounterpartProfileId: number = yield select(getCurrentCounterpartProfileId);

    if (currentCounterpartProfileId) {
      const latestMessageTxid: string = yield select(
        getCurrentCounterpartLatestMessageTxid(currentCounterpartProfileId)
      );
      const recentDms: Dm[] = yield call(watchFetchAllRecentDms, latestMessageTxid, []);

      if (recentDms.length) {
        yield put(
          addRecentDms({
            counterpartProfileId: currentCounterpartProfileId,
            messages: recentDms,
            myProfileId: getMyProfileIdByToken()
          })
        );
      }
    }
  }
}

export function* listenEvent(socket: Socket) {
  yield fork(listenCreatedNewDm, socket);
  yield fork(listenNewClientInChannel, socket);
  yield fork(listenClientOutInChannel, socket);
  yield fork(listenAuth, socket);
  yield fork(listenReconnect, socket);
}

export default function* watchConnectSocket() {
  while (true) {
    const socket = new Socket().connectTo('chat');

    yield fork(listenEvent, socket);
    yield fork(watchAuthorize, socket);
    yield take(disconnectSocket);

    socket.socket.disconnect();
  }
}
