import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }

  #root {
    height: 100%;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, Basier Square, Noto Sans KR, Segoe UI, Apple SD Gothic Neo, Roboto, Helvetica Neue, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    margin: 0;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  body::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }

  div, span, textarea {
    font-family: -apple-system, BlinkMacSystemFont, Basier Square, Noto Sans KR, Segoe UI, Apple SD Gothic Neo, Roboto, Helvetica Neue, Arial, sans-serif;
    font-stretch: normal;
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeightNormal};
    letter-spacing: normal;
    line-height: ${({ theme }) => theme.fontLineHeight};
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  div::-webkit-scrollbar, span::-webkit-scrollbar, textarea::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }

  input {
    font-family: -apple-system, BlinkMacSystemFont, Basier Square, Noto Sans KR, Segoe UI, Apple SD Gothic Neo, Roboto, Helvetica Neue, Arial, sans-serif;
    font-stretch: normal;
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeightNormal};
    letter-spacing: normal;
    line-height: normal;
  }

  a,
  [type='checkbox'],
  [type='radio'] {
    color: inherit;
    -webkit-tap-highlight-color: rgba(26, 26, 26, 0.301961);
    text-decoration: none;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  button {
    cursor: pointer;
    font-family: inherit;
    outline: none;
  }
`;

export default GlobalStyle;
