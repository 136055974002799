import api, { getEndpointSet } from '../services/api';
import { SubscriptionProductQueryParams } from './subscriptionProducts.types';

const { FETCH_ONE } = getEndpointSet('SUBSCRIPTION_PRODUCTS', 'subscriptionProducts');

class SubscriptionProductsRepository {
  static findOne(queryParams: SubscriptionProductQueryParams) {
    const { invitationCode, conversionLinkKeyword } = queryParams;
    return api.get(
      FETCH_ONE,
      {},
      conversionLinkKeyword !== ''
        ? {
            invitationCode,
            conversionLinkKeyword
          }
        : { invitationCode }
    );
  }
}

export default SubscriptionProductsRepository;
