import React from 'react';

import styled from 'styled-components';

type Props = {
  titleText?: string;
  captionText?: string;
  displayType?: 'none' | 'use';
  children?: React.ReactNode;
};

// TODO : 추후반영(type)

// selectionMode?: 'check' | 'none' | 'radio' | 'order';
// hitMode?: 'one' | 'separate';
// itemType?:
// | 'basic'
// | 'persona'
// | 'calendar'
// | 'comment'
// | 'information'
// | 'blog'
// | 'board'
// | 'history'
// | 'video';
// scrollMode?: 'infinite' | 'none' | 'page';
// scrollVisibleType?: 'moving' | 'hidden' | 'visible';
// itemCountUnit?: number;
// columns?: number;

// TODO : 추후반영(props)

// selectionMode = 'check',
// hitMode = 'one',
// itemType = 'basic',
// scrollMode = 'infinite',
// scrollVisibleType = 'moving',
// itemCountUnit = 30,
// columns = 1,
function BasicList({ titleText, captionText, displayType = 'none', children }: Props) {
  if (displayType === 'use') {
    return (
      <S_UseListContainer>
        {titleText && <S_ListTitle>{titleText}</S_ListTitle>}
        {captionText && <S_CaptionText>{captionText}</S_CaptionText>}
        <S_UseListWrapper>{children}</S_UseListWrapper>
      </S_UseListContainer>
    );
  }

  return (
    <S_NoneListContainer>
      {titleText && <S_ListTitle>{titleText}</S_ListTitle>}
      {captionText && <S_CaptionText>{captionText}</S_CaptionText>}
      <S_NoneListWrapper>{children}</S_NoneListWrapper>
    </S_NoneListContainer>
  );
}

const S_NoneListContainer = styled.div``;

const S_UseListContainer = styled.div`
  padding: 0px 24px;
`;

const S_ListTitle = styled.h1``;

const S_CaptionText = styled.span``;

const S_NoneListWrapper = styled.div``;

const S_UseListWrapper = styled.div``;

export default BasicList;
