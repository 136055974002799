import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormProvider, useForm } from 'react-hook-form';

import { Form, InputFieldButton, SubmitButton } from '@common/ui/components';
import { useTranslation } from 'react-i18next';

type SignInFormProps = {
  onSignIn: (values: any) => void;
};

interface ISignInForm {
  email: string;
  password: string;
}

/**
 * SignIn 뷰
 *
 * @param {function} props.onSignIn 로그인 폼 입력 완료 콜백
 */
function SignInForm({ onSignIn }: SignInFormProps) {
  const { t } = useTranslation('translation');

  const emailValidation = {
    required: t('str_input_email', '이메일을 입력해 주세요.')
  };

  const passwordValidation = {
    required: t('str_input_pw', '비밀번호를 입력해 주세요.')
  };

  const methods = useForm<ISignInForm>({ mode: 'onChange' });

  const {
    handleSubmit,
    formState: { isValid, isDirty }
  } = methods;

  const isButtonDisabled = !isValid || !isDirty;

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSignIn)}>
        <S_InputFieldBlock>
          <InputFieldButton
            name="email"
            placeholder={t('str_email', '이메일')}
            validation={emailValidation}
            cancel
          />
        </S_InputFieldBlock>
        <S_InputFieldBlock>
          <InputFieldButton
            type="password"
            name="password"
            placeholder={t('str_password', '비밀번호')}
            validation={passwordValidation}
            cancel
          />
        </S_InputFieldBlock>
        <SubmitButtonWrapper>
          <SubmitButton full disabled={isButtonDisabled} label={t('str_sign_in', '로그인')} />
        </SubmitButtonWrapper>
      </Form>
    </FormProvider>
  );
}

SignInForm.propTypes = {
  onSignIn: PropTypes.func.isRequired
};

const SubmitButtonWrapper = styled.div`
  margin-top: 24px;
`;

const S_InputFieldBlock = styled.div`
  margin-top: 16px;
`;

export default SignInForm;
