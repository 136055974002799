import { RootState } from '../../index';

export const getIsLoading = (state: RootState) => {
  return state.VODPApp.isLoading;
};

export const getError = (state: RootState) => {
  return state.VODPApp.error;
};

export const getVODPosts = (state: RootState) => {
  return state.VODPApp.VODPosts;
};

export const getVODPost = (state: RootState) => {
  return state.VODPApp.VODPost;
};

export const getPlaylists = (state: RootState) => {
  return state.VODPApp.playlists;
};

export const getPlaylist = (state: RootState) => {
  return state.VODPApp.playlist;
};

export const getPlaylistEpisodes = (state: RootState) => {
  return state.VODPApp.episodes;
};

export const getVODToken = (state: RootState) => {
  return state.VODPApp.VODToken;
};
