import React from 'react';

import { SmartLink, Icon } from '@common/ui/components';
import styled from 'styled-components';

type Props = {
  link?: string;
  icon: React.ReactElement;
  onClick?: () => void;
  children?: React.ReactNode;
};

function MenuItem({ children, onClick, icon, link = '' }: Props) {
  return (
    <SmartLink to={link}>
      <S_ItemWrapper onClick={onClick}>
        <S_Item>
          <S_IconWrapper>
            <S_Icon>{icon}</S_Icon>
          </S_IconWrapper>
          <S_Title>{children}</S_Title>
        </S_Item>
        {link && <Icon iconName="ic_ArrowRight" colorCode="grey500" size={16} />}
      </S_ItemWrapper>
    </SmartLink>
  );
}

const S_ItemWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: space-between;
  padding: 0 24px;
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
`;

const S_Item = styled.span`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-start;
`;

const S_IconWrapper = styled.span`
  align-items: center;
  background-color: ${({ theme }) => theme.grey50};
  border-radius: 16px;
  display: inline-flex;
  height: 48px;
  justify-content: center;
  margin-right: 16px;
  width: 48px;
`;

const S_Icon = styled.span`
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
`;

const S_Title = styled.span`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  line-height: normal;
  text-decoration: none;
`;

export default MenuItem;
