import IconType from '../IconType';

const ThumbUp = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M11.90985,2.20905 C12.33435,1.76955 13.00935,1.69605 13.51935,2.03205 C14.02785,2.36655 14.22585,3.01605 13.99035,3.57705 L13.99035,3.57705 L12.68385,6.71655 C12.54735,7.04655 12.78885,7.40955 13.14585,7.40955 L13.14585,7.40955 L21.28185,7.40955 C22.38585,7.40955 23.28285,8.30505 23.28285,9.40905 L23.28285,9.40905 L23.28285,11.78505 C23.28285,12.33855 23.12985,12.87855 22.84185,13.34955 L22.84185,13.34955 L18.86535,19.85505 C18.50235,20.45055 17.85735,20.81355 17.15985,20.81355 L17.15985,20.81355 L8.42535,20.81355 C7.31985,20.81355 6.42585,19.91805 6.42585,18.81405 L6.42585,18.81405 L6.42585,8.26905 C6.42585,7.72005 6.65085,7.19505 7.04985,6.81705 L7.04985,6.81705 Z M3.5379,7.4088 C4.3659,7.4088 5.0379,8.0808 5.0379,8.9088 L5.0379,19.3128 C5.0379,20.1423 4.3659,20.8128 3.5379,20.8128 L2.2179,20.8128 C1.3884,20.8128 0.7179,20.1423 0.7179,19.3128 L0.7179,8.9088 C0.7179,8.0808 1.3884,7.4088 2.2179,7.4088 L3.5379,7.4088 Z"
      />
    </svg>
  );
};

export default ThumbUp;
