import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Icon } from '@common/ui/components';
import { useDispatch } from 'react-redux';
import { openSideMenu } from '@common/redux/modules/channel/reducers';

type NavBarProps = {
  children: JSX.Element;
  underline?: boolean;
  isBack?: boolean;
  isMenu?: boolean;
  onBack?: (prop: any) => any;
};

function NavBar({ underline, onBack, children, isBack, isMenu }: NavBarProps) {
  const history = useHistory();
  const dispatch = useDispatch();

  function openMenu() {
    dispatch(openSideMenu());
  }

  return (
    <S_NavBar isBack={isBack} underline={underline}>
      {isMenu && (
        <S_BackButton
          onClick={() => {
            openMenu();
          }}
        >
          <Icon iconName="ic_Menu" size={24} />
        </S_BackButton>
      )}
      {isBack && (
        <S_BackButton onClick={onBack || history.goBack}>
          <Icon iconName="ic_ArrowLeft" />
        </S_BackButton>
      )}
      {!isBack && !isMenu && <S_Empty />}
      {children}
      <S_EmptyButton />
    </S_NavBar>
  );
}

const S_NavBar = styled.div<{ isBack?: boolean; underline?: boolean }>`
  align-items: center;
  background-color: ${(props) => props.theme.white};
  border-bottom: ${(props) => (props.underline ? `1px solid ${props.theme.grey100}` : '')};
  display: flex;
  height: 56px;
  justify-content: space-between;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  transition: transform 0.4s;
  z-index: ${({ isBack }) => (isBack ? 10 : 4)};
`;

const S_BackButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-left: 8px;
  width: 48px;

  .back:hover {
    opacity: 0.8;
  }

  .back:focus {
    outline: none;
  }
`;

const S_EmptyButton = styled.div`
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-left: 8px;
  width: 48px;
`;

const S_Empty = styled.div`
  height: 48px;
  width: 48px;
`;

export default NavBar;
