import { useTranslation } from 'react-i18next';

import { ReactComponent as TicketImageLeft } from '@assets/img/im-booking-myticket-left.svg';
import { ReactComponent as TicketImageRight } from '@assets/img/im-booking-myticket-right.svg';
import { Button } from '@common/ui/components';
import styled from 'styled-components';

type TicketInfoProps = {
  ticketLeft: number;
  handlePurchaseTicketModalOpen: () => void;
};

function TicketInfo({ ticketLeft, handlePurchaseTicketModalOpen }: TicketInfoProps) {
  const { t } = useTranslation('translation');
  return (
    <S_TicketInfoContainer>
      <S_TicketImageBox>
        <TicketImageLeft />
        <S_TicketTextArea>
          <S_Text>
            {t('str_papp_cbooking_ticket_hold_quantity', {
              defaultValue: '보유 $t(str_papp_cbooking_ticket)'
            })}
          </S_Text>
          <S_TicketLeft>
            {t('str_36', { ticketAmount: ticketLeft, defaultValue: '{{ticketAmount}} 장' })}
          </S_TicketLeft>
        </S_TicketTextArea>
        <TicketImageRight />
      </S_TicketImageBox>
      <S_ButtonBox>
        <Button
          label={t('str_papp_cbooking_ticket_purchase', '구매')}
          full
          onClick={() => {
            handlePurchaseTicketModalOpen();
          }}
        />
      </S_ButtonBox>
    </S_TicketInfoContainer>
  );
}

const S_TicketInfoContainer = styled.div`
  background-color: 'white';
`;

const S_TicketImageBox = styled.div`
  display: flex;
  height: 150px;
  justify-content: center;
  margin: 24px 0;
  width: 100%;
`;

const S_TicketTextArea = styled.div`
  background-color: #f5f6f8;
  padding-top: 40px;
  width: 75%;
`;

const S_Text = styled.div`
  color: ${({ theme }) => theme.grey500};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  line-height: ${({ theme }) => theme.fontLineHeight};
  margin: 2px;
  width: 100%;
`;

const S_TicketLeft = styled.div`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeH1};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  line-height: ${({ theme }) => theme.fontLineHeight};
  margin: 2px;
`;

const S_ButtonBox = styled.div`
  margin: 24px;
`;

export default TicketInfo;
