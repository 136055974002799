import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BookingDescHistoryType } from '@common/redux/modules/booking/types';
import { Icon } from '@common/ui/components';
import DateHelper from '@common/utils/dateHelper';
import styled from 'styled-components';

import { buildBookingMyBookingDescPath } from '@routers/routes';

function HistoryCard({ id, startDate, duration, cancel, channelID }: BookingDescHistoryType) {
  const { t } = useTranslation('translation');
  const history = useHistory();
  const startDateFormat = DateHelper.getInstance(startDate).format('YYYY-MM-DD');
  const startTime = DateHelper.getInstance(startDate).format('HH:mm');

  return (
    <S_HistoryCardContainer
      onClick={() => {
        history.push(buildBookingMyBookingDescPath(channelID), { id, fromHistory: true });
      }}
    >
      <S_ColorBar />
      <S_ContentArea>
        {!cancel && <S_Title>{t('str_papp_cbooking_subs_card_status_d', '상담 완료')}</S_Title>}
        {cancel && cancel.result === null && (
          <S_Title>{t('str_papp_cbooking_subs_card_status_d', '상담 완료')}</S_Title>
        )}
        {cancel && cancel.result === 0 && (
          <S_Title>{t('str_papp_cbooking_subs_card_status_d', '상담 완료')}</S_Title>
        )}
        {cancel && cancel.result === 2 && (
          <S_Title>{t('str_papp_cbooking_subs_card_status_e', '예약 취소')}</S_Title>
        )}
        {cancel && cancel.result === 4 && (
          <S_Title>{t('str_papp_cbooking_subs_card_status_e', '예약 취소')}</S_Title>
        )}
        {cancel && cancel.result === 6 && (
          <S_Title>{t('str_papp_cbooking_subs_card_status_f', '상담 거부')}</S_Title>
        )}
        <S_Time>
          {`${t('str_26', {
            startDate: startDateFormat,
            startTime,
            defaultValue: '{{startDate}} {{startTime}}'
          })}`}
          {` ${t('str_27', { duration, defaultValue: '({{duration}}$t(str_fm_minute))' })}`}
        </S_Time>
      </S_ContentArea>
      <S_ArrowBar>
        <Icon iconName="ic_ArrowRight" colorCode="grey400" />
      </S_ArrowBar>
    </S_HistoryCardContainer>
  );
}

const S_HistoryCardContainer = styled.div`
  display: flex;
  flex-direction: flex-start;
  height: 160px;
  margin: 24px;
`;

const S_ColorBar = styled.div`
  background-color: ${({ theme }) => theme.grey400};
  border-radius: 16px 0 0 16px;
  height: 160px;
  width: 16px;
`;

const S_ArrowBar = styled.div`
  background-color: white;
  border-radius: 0 16px 16px 0;
  height: 24px;
  padding: 68px 16px 68px 0;
  width: 24px;
`;

const S_ContentArea = styled.div`
  background-color: white;
  height: 160px;
  line-height: ${({ theme }) => theme.fontLineHeight};
  text-align: left;
  width: 100%;
`;

const S_Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizeSubtitle};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  margin: 16px 16px 0 16px;
`;

const S_Time = styled.div`
  color: ${({ theme }) => theme.grey400};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  height: 21px;
  margin: 4px 16px 8px 16px;
`;

export default HistoryCard;
