import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Icon } from '@common/ui/components';
import { buildVodPlusPath } from '@routers/routes';
import { cleanUpAllData } from '@common/redux/modules/VODPlusPApp/reducers';

import { Text } from '../../../components';

type Props = {
  invitationCode: string;
  isCategorySelected: boolean;
  categoryHandler: () => void;
  category: string;
};

function CategoryBox({ invitationCode, isCategorySelected, categoryHandler, category }: Props) {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const history = useHistory();

  const isSelected = isCategorySelected || category.length > 0;
  const vodPlusPath = buildVodPlusPath(invitationCode);

  const resetLabel = () => {
    dispatch(cleanUpAllData());
    history.push(`${vodPlusPath}/serieses`, { category: '' });
  };

  return (
    <S_CategoryBox isCategorySelected={isSelected} onClick={categoryHandler}>
      {!category && (
        <Icon iconName="ic_Filter" size={16} colorCode={isSelected ? 'white' : 'grey900'} />
      )}
      <S_TextWrapper>
        <Text fontSizes="fontSizeCaption1" color={isSelected ? 'white' : 'grey900'}>
          {category || t('str_papp_vodp_category', '카테고리')}
        </Text>
      </S_TextWrapper>
      {category && (
        <S_IconWrapper
          onClick={(e) => {
            e.stopPropagation();
            resetLabel();
          }}
        >
          <Icon iconName="ic_Xmark" size={12} colorCode="white" />
        </S_IconWrapper>
      )}
    </S_CategoryBox>
  );
}

const S_CategoryBox = styled.div<{ isCategorySelected: boolean }>`
  align-items: center;
  background-color: ${({ isCategorySelected, theme }) =>
    isCategorySelected ? theme.blue500 : 'none'};
  border: 1px solid;
  border-color: ${({ isCategorySelected, theme }) =>
    isCategorySelected ? theme.blue500 : theme.grey100};
  border-radius: 99px;
  color: ${({ isCategorySelected, theme }) => (isCategorySelected ? theme.white : theme.blue500)};
  display: flex;
  flex-direction: row;
  max-width: 70%;
  padding: 8px 16px;
`;

const S_TextWrapper = styled.div`
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  margin-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const S_IconWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 12px;
  margin: 5px;
  width: 12px;
`;

export default CategoryBox;
