import DateHelper from '@common/utils/dateHelper';
import styled from 'styled-components';

import { CalendarWeekGenerator, CalendarTitle } from '../molcules';

type CalendarProps = {
  bookingAvailableDates: { [key: string]: boolean };
  selectDate: ReturnType<typeof DateHelper.getInstance>;
  startWeek: number;
  endWeek: number;
  refDate: ReturnType<typeof DateHelper.getInstance>;
  handleSelectAnotherDay: (currentFormat: ReturnType<typeof DateHelper.getInstance>) => void;
  handleIsDropDownOpen: () => void;
  handleGoToday: () => void;
};

function CalendarGenerator({
  bookingAvailableDates,
  selectDate,
  startWeek,
  endWeek,
  refDate,
  handleSelectAnotherDay,
  handleIsDropDownOpen,
  handleGoToday
}: CalendarProps) {
  return (
    <>
      <CalendarTitle
        handleIsDropDownOpen={handleIsDropDownOpen}
        handleGoToday={handleGoToday}
        refDate={refDate}
      />
      <S_CalendarDayNameGrid>
        <S_CalendarDayName>일</S_CalendarDayName>
        <S_CalendarDayName>월</S_CalendarDayName>
        <S_CalendarDayName>화</S_CalendarDayName>
        <S_CalendarDayName>수</S_CalendarDayName>
        <S_CalendarDayName>목</S_CalendarDayName>
        <S_CalendarDayName>금</S_CalendarDayName>
        <S_CalendarDayName>토</S_CalendarDayName>
      </S_CalendarDayNameGrid>
      <CalendarWeekGenerator
        bookingAvailableDates={bookingAvailableDates}
        selectDate={selectDate}
        startWeek={startWeek}
        endWeek={endWeek}
        refDate={refDate}
        handleSelectAnotherDay={handleSelectAnotherDay}
      />
    </>
  );
}

const S_CalendarDayNameGrid = styled.div`
  display: grid;
  grid-auto-rows: 24px;
  grid-template-columns: repeat(7, minmax(50px, auto));
  margin: 0;
  text-align: center;
`;

const S_CalendarDayName = styled.div`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-stretch: normal;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  letter-spacing: normal;
  line-height: ${({ theme }) => theme.fontLineHeight};
  &:first-of-type {
    color: ${({ theme }) => theme.red500};
  }
  &:last-of-type {
    color: ${({ theme }) => theme.blue500};
  }
`;

export default CalendarGenerator;
