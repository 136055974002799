import { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Popup, PopupProvider, useModalOpenCount, Icon } from '@common/ui/components';
import { ProfileInfo } from '../molecules';
import { profileBarMenu } from '../../profileBarMenu';

type Props = {
  authorThumbnailSrc: string;
  author: string;
  createdAt: string;
  isAuthor: boolean;
  id: number;
  onClick: (value: string, id?: number) => void;
};

function ProfileBar({ id, authorThumbnailSrc, author, createdAt, isAuthor, onClick }: Props) {
  const location = useLocation();
  const { t } = useTranslation('translation');
  const modalOpenCount = useModalOpenCount();

  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  const ref = useRef<any>();

  useEffect(() => {
    setIsPopUpOpen(false);
  }, [modalOpenCount, location.pathname]);

  function handleToggle() {
    setIsPopUpOpen(!isPopUpOpen);
  }

  function handleClickOutside() {
    setIsPopUpOpen(false);
  }

  const PopUpComponent = (
    <Popup
      targetRef={ref}
      placement="bottom-end"
      isOpen={isPopUpOpen}
      onClickOutside={handleClickOutside}
      onClick={handleToggle}
    >
      <S_MenuBlock>
        {profileBarMenu.map(({ key, defaultValue }) => (
          <S_Menu key={key} onClick={() => onClick(defaultValue, id)}>
            {t(key, defaultValue)}
          </S_Menu>
        ))}
      </S_MenuBlock>
    </Popup>
  );

  return (
    <>
      <PopupProvider>
        <S_ProfileBar>
          <ProfileInfo
            authorThumbnailSrc={authorThumbnailSrc}
            author={author}
            createdAt={createdAt}
          />
          {isAuthor && (
            <S_IconWrapper ref={ref} onClick={handleToggle}>
              <Icon iconName="ic_More" colorCode="grey500" fill />
            </S_IconWrapper>
          )}
        </S_ProfileBar>
        {PopUpComponent}
      </PopupProvider>
    </>
  );
}

const S_ProfileBar = styled.div`
  padding: 0 24px;
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: space-between;
`;

const S_MenuBlock = styled.div`
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.grey100};
  border-radius: 8px;
  width: 128px;
`;

const S_Menu = styled.div`
  align-items: center;
  border-radius: 8px;
  color: ${({ theme }) => theme.grey900};
  cursor: pointer;
  display: flex;
  font-size: ${({ theme }) => theme.fontSizeBody2};
  height: 48px;
  justify-content: flex-start;
  padding-left: 16px;

  &:hover {
    background-color: ${({ theme }) => theme.grey100};
  }
`;

const S_IconWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: flex-end;
  -webkit-tap-highlight-color: transparent;
  width: 48px;
`;

export default ProfileBar;
