import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { SmartLink, Icon } from '@common/ui/components';

type NavBarProps = {
  isBack: boolean;
  title?: string;
  backUrl?: string;
  linkUrl?: string;
};

function NavBar({ title, isBack, backUrl, linkUrl }: NavBarProps) {
  const history = useHistory();

  /**
   * 뒤로가기 클릭 이벤트 핸들러
   */
  function handleBack() {
    if (backUrl) {
      history.push(backUrl);
    } else {
      history.goBack();
    }
  }

  return (
    <S_NavBar>
      {isBack ? (
        <S_Button onClick={handleBack}>
          <Icon iconName="ic_ArrowLeft" />
        </S_Button>
      ) : (
        <S_Empty />
      )}

      {title && <S_Title>{title}</S_Title>}
      {linkUrl ? (
        <SmartLink to={linkUrl}>
          <S_Button>
            <Icon iconName="ic_Setting" fill />
          </S_Button>
        </SmartLink>
      ) : (
        <S_Empty />
      )}
    </S_NavBar>
  );
}

const S_NavBar = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  height: 56px;
  justify-content: space-between;
  left: 0;
  padding-left: 8px;
  padding-right: 8px;
  position: sticky;
  right: 0;
  text-align: center;
  top: 0;
  transition: transform 0.4s;
  z-index: 10;
`;

const S_Button = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  width: 48px;
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
`;

const S_Title = styled.div`
  color: ${({ theme }) => theme.black};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  text-align: center;
`;

const S_Empty = styled.div`
  height: 48px;
  width: 48px;
`;

export default NavBar;
