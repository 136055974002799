import StringHelper from './stringHelper';

const REG_MATCH_PATH_SEPERATOR = /(\/)/g;
const REG_INTERPOLATION_PLACEHOLDER_MAP = {
  COLON: /:([^:/]*)/g,
  CURLY: /{([^{}]*)}/g
};

const REG_STR_PATH_VARIABLE_TYPE_MAP = {
  id: '(\\d+)',
  word: '(\\w+)',
  queryString: '([^/]?)+'
};

export type PlaceHolderType = keyof typeof REG_INTERPOLATION_PLACEHOLDER_MAP;
export type PathVariableType = keyof typeof REG_STR_PATH_VARIABLE_TYPE_MAP;

class URI {
  static genTemplateUriRegExp(
    templateUri: string,
    opts?: {
      placeholderType?: PlaceHolderType;
      pathVariableTypes?: { [key: string]: PathVariableType };
    }
  ) {
    const placeholderType = opts?.placeholderType || 'COLON';
    const pathVariableTypes = opts?.pathVariableTypes || {};

    const pathVariables = (Object.entries(pathVariableTypes) as [
      string,
      PathVariableType
    ][]).reduce((acc, [placeholder, type]) => {
      const tag = `?<${placeholder}>`;
      const namedGroup = StringHelper.insertAt(REG_STR_PATH_VARIABLE_TYPE_MAP[type], tag, 1);

      acc[placeholder] = namedGroup;
      return acc;
    }, {} as { [key: string]: ReturnType<typeof StringHelper.insertAt> });

    return new RegExp(
      URI.interpolate(templateUri, pathVariables, placeholderType).replace(
        REG_MATCH_PATH_SEPERATOR,
        '\\/'
      )
    );
  }

  static extractPathVariables(uri: string, regExp: RegExp) {
    const matchArray = uri.match(regExp);

    return matchArray ? matchArray.groups : matchArray;
  }

  static interpolate<T extends { [key: string]: string | number | boolean }>(
    templateUri: string,
    pathVariables: T,
    placeholderType: PlaceHolderType = 'COLON'
  ) {
    const regExp = REG_INTERPOLATION_PLACEHOLDER_MAP[placeholderType];

    return StringHelper.interpolate(templateUri, pathVariables, regExp);
  }

  static buildURI<T extends { [key: string]: string | number | boolean }>(
    host: string,
    path: string,
    pathVariables: T,
    placeholderType: PlaceHolderType = 'COLON'
  ) {
    return host + URI.interpolate(path, pathVariables || {}, placeholderType);
  }
}

export default URI;
