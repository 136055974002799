import { RootState } from '../../index';

export const getIsLoading = (state: RootState) => {
  return state.VODPlusPApp.isLoading;
};

export const getError = (state: RootState) => {
  return state.VODPlusPApp.error;
};

export const getVODPlusHome = (state: RootState) => {
  return state.VODPlusPApp.VODPlusHome;
};

export const getVODPlusPost = (state: RootState) => {
  return state.VODPlusPApp.VODPlusPost;
};

export const getSerieses = (state: RootState) => {
  return state.VODPlusPApp.serieses;
};

export const getBookmarks = (state: RootState) => {
  return state.VODPlusPApp.bookmarks;
};

export const getLabels = (state: RootState) => {
  return state.VODPlusPApp.labels;
};

export const getSeries = (state: RootState) => {
  return state.VODPlusPApp.series;
};

export const getSeriesEpisodes = (state: RootState) => {
  return state.VODPlusPApp.episodes;
};

export const getVODPlusToken = (state: RootState) => {
  return state.VODPlusPApp.VODPlusToken;
};
