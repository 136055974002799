import PropTypes from 'prop-types';

import styled from 'styled-components';

interface PurchaseTitleProps {
  children: string;
}

/**
 * 채널 소개 페이지 - 결제 타이틀
 *
 * @param {Object} props
 * @param {string} props.children 결제 타이틀
 */
function PurchaseTitle({ children }: PurchaseTitleProps) {
  return <S_PurchaseTitle>{children}</S_PurchaseTitle>;
}

PurchaseTitle.propTypes = {
  children: PropTypes.string.isRequired
};

const S_PurchaseTitle = styled.span`
  color: ${({ theme }) => theme.grey900};
  flex: 1;
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  line-height: ${({ theme }) => theme.fontLineHeight};
  text-align: left;
`;

export default PurchaseTitle;
