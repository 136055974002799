import { memo } from 'react';
import styled from 'styled-components';
import { StyledNamespace } from '@common/types/styledNamespace';

const S: StyledNamespace = {};

S.Bubble = styled.div<{ isMyMessage: boolean; hasTail: boolean }>`
  align-items: flex-end;
  display: flex;
  justify-content: ${(props) => (props.isMyMessage ? 'flex-end' : 'flex-start')};
  margin: 4px 30px;
  max-width: 100%;
  ${(props) => (props.hasTail ? 'margin-bottom: 16px' : '')}
`;

S.TextBox = styled.div<{ isMyMessage: boolean }>`
  background-color: ${(props) => (props.isMyMessage ? props.theme.blue500 : props.theme.grey50)};
  border-radius: 8px;
  color: ${(props) => (props.isMyMessage ? props.theme.white : props.theme.grey900)};
  font-size: ${(props) => props.theme.fontSizeBody2};
  max-width: 77%;
  padding: 8px 12px;
  position: relative;
  text-align: left;
`;

S.TimeBox = styled.div`
  color: ${(props) => props.theme.grey400};
  font-size: ${(props) => props.theme.fontSizeCaption2};
  margin: 0 4px;
`;

S.Tail = styled.div<{ isMyMessage: boolean }>`
  background-color: ${(props) => (props.isMyMessage ? props.theme.blue500 : props.theme.grey50)};
  border-radius: 8px;
  bottom: 0;
  height: 8px;
  position: absolute;
  transform: ${(props) => (props.isMyMessage ? 'rotate(20deg)' : 'rotate(-20deg)')};
  width: 20px;
  z-index: -1;

  ${(props) => (props.isMyMessage ? 'right: -10px;' : 'left: -10px;')}

  .shadow {
    background-color: white;
    border-radius: ${(props) => (props.isMyMessage ? '0 0 0 10px' : '0 0 10px 0')};
    bottom: -2px;
    height: 25px;
    position: absolute;
    transform: ${(props) => (props.isMyMessage ? 'rotate(-20deg)' : 'rotate(20deg)')};
    width: inherit;

    ${(props) =>
      props.isMyMessage
        ? `
    right: -7px;
  `
        : `left: -7px;`}
  }
`;

S.ResendButton = styled.button`
  align-items: center;
  align-self: center;
  background-color: ${(props) => props.theme.white};
  border: 2px solid ${(props) => props.theme.red500};
  border-radius: 30px;
  display: flex;
  height: 25px;
  justify-content: center;
  justify-self: center;
  margin-left: 5px;
  transition: background-color 0.2s;
  width: 25px;

  &:hover {
    background-color: ${(props) => props.theme.grey100};
  }
`;

type BubbleProps = {
  id: number;
  messageText: string;
  time: string;
  isMyMessage: boolean;
  hasTail: boolean;
  sendOK: boolean;
  resendDm: ({ message, messageId }: { message: string; messageId: number }) => void;
};

function Bubble({ id, messageText, time, isMyMessage, hasTail, sendOK, resendDm }: BubbleProps) {
  const handleResend = () => {
    resendDm({ message: messageText, messageId: id });
  };

  return (
    <S.Bubble isMyMessage={isMyMessage} hasTail={hasTail}>
      {isMyMessage && <S.TimeBox>{time}</S.TimeBox>}
      <S.TextBox isMyMessage={isMyMessage} hasTail={hasTail}>
        {messageText}
        {hasTail && (
          <S.Tail isMyMessage={isMyMessage}>
            <div className="shadow" />
          </S.Tail>
        )}
      </S.TextBox>
      {!sendOK && (
        <S.ResendButton onClick={handleResend} role="img" aria-label="resend">
          <span role="img" aria-label="resend" className="emoji">
            ❗️
          </span>
        </S.ResendButton>
      )}
      {!isMyMessage && sendOK && <S.TimeBox>{time}</S.TimeBox>}
    </S.Bubble>
  );
}

export default memo(Bubble);
