import { useTranslation } from 'react-i18next';
import { ModalDialog, Button } from '@common/ui/components';

type Props = {
  onClose: () => void;
  onClick: () => void;
  isOpen: boolean;
  message: string;
};

function CancelButtonModal({ isOpen, onClose, onClick, message }: Props) {
  const { t } = useTranslation('translation');
  return (
    <ModalDialog
      onClose={onClose}
      isOpen={isOpen}
      footer={<Button onClick={onClick} label={t('str_confirm', '확인')} />}
    >
      <span>
        {message.split('\n').map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </span>
    </ModalDialog>
  );
}

export default CancelButtonModal;
