import fillIcons from '@assets/icons/fill';
import lineIcons from '@assets/icons/line';
import { colors } from '@common/ui/styles/theme';

export type FillIconNameKeys = keyof typeof fillIcons;
export type LineIconNameKeys = keyof typeof lineIcons;
export type Colors = keyof typeof colors;

type IconProps = {
  iconName?: FillIconNameKeys | LineIconNameKeys | string;
  disabled?: boolean;
  fill?: boolean;
  colorCode?: Colors;
  size?: 12 | 16 | 20 | 24 | 32 | 48 | 56 | 64 | 72;
} & Record<string, any>;

const Icon = ({
  iconName,
  disabled,
  fill,
  colorCode = 'grey900',
  size = 24,
  ...rest
}: IconProps) => {
  const SelectedIcon = fill
    ? fillIcons[iconName as FillIconNameKeys] || lineIcons[iconName as LineIconNameKeys]
    : lineIcons[iconName as LineIconNameKeys] || fillIcons[iconName as FillIconNameKeys];

  return (
    <SelectedIcon color={!disabled ? colors[colorCode] : colors.grey400} size={size} {...rest} />
  );
};

export default Icon;
