import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { StyledNamespace } from '@common/types/styledNamespace';
import { ScrollProvider } from '../ScrollContext';

const S: StyledNamespace = {};

/**
 * 스크롤 뷰
 * ScrollProvider 보일러플레이트를 제공함.
 *
 * @param {Object} props
 * @param {any} props.component 스크롤 컨테이너로 제공될 개체, 기본은 div
 * @param {string} props.className
 */
function ScrollView({
  children,
  ...rest
}: {
  children?: React.ReactNode;
  style?: any;
  rest?: any;
}) {
  const containerRef = useRef<HTMLInputElement>();

  return (
    <ScrollProvider containerRef={containerRef}>
      <S.ScrollView ref={containerRef} {...rest}>
        {children}
      </S.ScrollView>
    </ScrollProvider>
  );
}

ScrollView.propTypes = {
  children: PropTypes.node.isRequired
};

S.ScrollView = styled.div`
  -ms-overflow-style: none;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
`;

export default ScrollView;
