import styled from 'styled-components';

type SquareImageProps = {
  src: string;
  alt: string;
};

export default function SquareImage({ src, alt }: SquareImageProps) {
  return <S_SquareImage src={src} alt={alt} />;
}

const S_SquareImage = styled.img`
  border-radius: 24px;
  height: 160px;
  width: 160px;
`;
