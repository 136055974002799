import { useHistory } from 'react-router-dom';

import { Icon } from '@common/ui/components';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { openSideMenu } from '@common/redux/modules/channel/reducers';

type Props = {
  title: string;
  isBack?: boolean;
  backUrl?: string;
  isMenu?: boolean;
};

function NavBar({ title, isBack, backUrl, isMenu }: Props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleBack = () => {
    if (backUrl) {
      history.push(backUrl);
      return;
    }

    history.goBack();
  };

  function openMenu() {
    dispatch(openSideMenu());
  }

  return (
    <S_NavBar>
      {isMenu && (
        <S_BackButton
          onClick={() => {
            openMenu();
          }}
        >
          <Icon iconName="ic_Menu" size={24} />
        </S_BackButton>
      )}
      {isBack && (
        <S_BackButton onClick={handleBack}>
          <Icon iconName="ic_ArrowLeft" />
        </S_BackButton>
      )}
      {!isBack && !isMenu && <S_Empty />}

      <S_Title>{title}</S_Title>
      <S_EmptyDiv />
    </S_NavBar>
  );
}

const S_NavBar = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  height: 56px;
`;

const S_BackButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-left: 8px;
  width: 48px;
`;

const S_Title = styled.span`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  margin: 12px 0;
  text-align: center;
  word-break: keep-all;
`;

const S_EmptyDiv = styled(S_BackButton)`
  margin-left: 0;
  margin-right: 8px;
`;

const S_Empty = styled.div`
  height: 48px;
  width: 48px;
`;

export default NavBar;
