import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

/**
 * 모바일 영상 플레이어
 *
 * @param {Object} props
 * @param {string} props.url 영상 URL
 * @param {string} props.thumbUrl 영상 섬네일 URL
 * @param {number} props.ratio 영상 비율 (가로 / 세로)
 */
function MobilePlayer({ url, thumbUrl, ratio = 16 / 9 }) {
  const [playerKey, setPlayerKey] = useState(1);
  const containerRef = useRef();
  const playerRef = useRef();
  const waitError = useRef(false);

  // HLS의 경우 내부적으로 영상을 계속 로딩하고 있는 버그가 있기 때문에 직접 destroy 메소드를 호출해줌.
  useEffect(() => {
    const player = playerRef.current;

    if (player) {
      return () => {
        const hls = player.getInternalPlayer('hls');

        if (hls) {
          hls.destroy();
        }
      };
    }
    return (...args: any) => args;
  }, [url]);

  /**
   * 에러 발생 이벤트 핸들러
   */
  async function handlePlayerError(hls) {
    // 에러 발생시 플레이어를 그냥 리셋함
    if (!waitError.current) {
      waitError.current = true;
      // await delay(5000);

      if (hls) {
        hls.destroy();
      }

      setPlayerKey((key) => key + 1);
      waitError.current = false;
    }
  }

  /**
   * 에러 이벤트 핸들러
   */
  function handleError(error, data, hls) {
    // bufferStalledError와 bufferNudgeOnStall과 같은 에러는 네트워크 등이 느려져서 버퍼에 데이터가 없는 상황이기 때문에
    // 플레이어가 자체적으로 기다려서 해결하도록 기다림. (심각한 에러가 아님.)
    if (data && data.details.indexOf('buffer') < 0) {
      handlePlayerError(hls);
    }
  }

  return (
    <Container ref={containerRef} ratio={ratio} thumbUrl={thumbUrl}>
      <Image src={thumbUrl} alt="video thumbnail" />

      <Player
        ref={playerRef}
        key={`${url}-${playerKey}`}
        url={url}
        width="100%"
        height="100%"
        controls
        muted
        playing
        playsinline
        onError={handleError}
      />
    </Container>
  );
}

MobilePlayer.defaultProps = {
  thumbUrl: '',
  ratio: 16 / 9
};

MobilePlayer.propTypes = {
  url: PropTypes.string.isRequired,
  thumbUrl: PropTypes.string,
  ratio: PropTypes.number
};

const Container = styled.div`
  background-image: ${({ thumbUrl }) => thumbUrl && `url(${thumbUrl})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  padding-top: ${({ ratio }) => ratio && `${Math.floor / ratio}%`};
  position: relative;
  width: 100%;
`;

const Image = styled.img`
  position: absolute;
`;

const Player = styled(ReactPlayer)`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  &::-webkit-media-controls-timeline {
    display: none;
  }

  &::-webkit-media-controls-current-time-display {
    display: none;
  }

  &::-webkit-media-controls-time-remaining-display {
    display: none;
  }
`;

export default MobilePlayer;
