import PropTypes from 'prop-types';
import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

const customHistory = createBrowserHistory();
const originalHistoryPush = customHistory.push;

customHistory.push = (path, state = {}) => {
  if (!customHistory.location) {
    originalHistoryPush(path, state);
  }

  const oldPath =
    customHistory.location.pathname + customHistory.location.search + customHistory.location.hash;

  if (oldPath !== path || JSON.stringify(state) !== JSON.stringify(customHistory.location.state)) {
    originalHistoryPush(path, state);
  } else {
    customHistory.replace(path, state);
  }
};

export { customHistory };

function MonkeyRouter({ children }) {
  return <Router history={customHistory}>{children}</Router>;
}

MonkeyRouter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default MonkeyRouter;
