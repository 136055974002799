import ic_Bell from './Bell';
import ic_Call from './Call';
import ic_Camera from './Camera';
import ic_Chat from './Chat';
import ic_ChatCounseling from './ChatCounseling';
import ic_CheckboxIndeterminate from './CheckboxIndeterminate';
import ic_CheckboxOn from './CheckboxOn';
import ic_Delete from './Delete';
import ic_Edit from './Edit';
import ic_Eye from './Eye';
import ic_Face from './Face';
import ic_FaceSlash from './FaceSlash';
import ic_Heart from './Heart';
import ic_Login from './Login';
import ic_MicOff from './MicOff';
import ic_MicOn from './MicOn';
import ic_More from './More';
import ic_Mute from './Mute';
import ic_PaperPlane from './PaperPlane';
import ic_Photo from './Photo';
import ic_PostCreate from './PostCreate';
import ic_PostFree from './PostFree';
import ic_PostNotice from './PostNotice';
import ic_PostSocial from './PostSocial';
import ic_Reply from './Reply';
import ic_Setting from './Setting';
import ic_Search from './Search';
import ic_ThumbDown from './ThumbDown';
import ic_ThumbUp from './ThumbUp';
import ic_Video from './Video';
import ic_VideoOff from './VideoOff';
import ic_VideoPause from './VideoPause';
import ic_VideoPlay from './VideoPlay';
import ic_VideoPlayCircle from './VideoPlayCircle';
import ic_VideoPlaylist from './VideoPlaylist';
import ic_Warning from './Warning';

const fillIcons = {
  ic_Bell,
  ic_Call,
  ic_Camera,
  ic_Chat,
  ic_ChatCounseling,
  ic_CheckboxIndeterminate,
  ic_CheckboxOn,
  ic_Delete,
  ic_Edit,
  ic_Eye,
  ic_Face,
  ic_FaceSlash,
  ic_Heart,
  ic_Login,
  ic_MicOff,
  ic_MicOn,
  ic_More,
  ic_Mute,
  ic_PaperPlane,
  ic_Photo,
  ic_PostCreate,
  ic_PostFree,
  ic_PostNotice,
  ic_PostSocial,
  ic_Reply,
  ic_Setting,
  ic_Search,
  ic_ThumbDown,
  ic_ThumbUp,
  ic_Video,
  ic_VideoOff,
  ic_VideoPause,
  ic_VideoPlay,
  ic_VideoPlayCircle,
  ic_VideoPlaylist,
  ic_Warning
} as const;

export default fillIcons;
