import { useTranslation } from 'react-i18next';

import styled, { css } from 'styled-components';

type Props = {
  isReadMoreOpen: boolean;
  description: string;
  onReadMore: () => void;
  total: number;
};

function PlaylistInfo({ isReadMoreOpen, description, onReadMore, total }: Props) {
  const { t } = useTranslation('translation');

  return (
    <S_PlaylistInfo>
      <S_Description isOpen={isReadMoreOpen}>{description}</S_Description>
      {description?.length > 100 && (
        <S_ReadMoreButton onClick={onReadMore}>
          {isReadMoreOpen ? t('str_78', '간략히보기') : t('str_77', '자세히보기')}
        </S_ReadMoreButton>
      )}

      <S_Line />

      <S_Count>
        {t('str_54', { playlistVodCnt: total, defaultValue: '총 {{playlistVodCnt}}개' })}
      </S_Count>
    </S_PlaylistInfo>
  );
}

const S_PlaylistInfo = styled.div`
  padding: 24px 24px 0 24px;
  text-align: left;
`;

const S_Description = styled.div<{
  isOpen: boolean;
}>`
  -webkit-box-orient: vertical;
  color: ${({ theme }) => theme.grey900};
  display: -webkit-box;
  font-size: ${({ theme }) => theme.fontSizeBody2};
  -webkit-line-clamp: 3;
  line-height: ${({ theme }) => theme.fontLineHeight};
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  word-wrap: break-word;

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
      text-overflow: none;
    `}
`;

const S_ReadMoreButton = styled.div`
  color: ${({ theme }) => theme.grey500};
  font-size: ${({ theme }) => theme.fontSizeCaption1};
  margin-top: 8px;
`;

const S_Line = styled.div`
  background-color: ${({ theme }) => theme.grey100};
  height: 1px;
  margin: 24px 0;
`;

const S_Count = styled(S_ReadMoreButton)`
  color: ${({ theme }) => theme.grey500};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  margin-bottom: 16px;
  margin-top: 0;
  text-align: left;
`;

export default PlaylistInfo;
