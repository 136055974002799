import styled from 'styled-components';

import { StyledNamespace } from '@common/types/styledNamespace';

import { ProfileImage } from '../../../components/atoms';
import { NavBarWrapper } from '../../../components/molecules';

const S: StyledNamespace = {};

S.NavBar = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.white};
  border-bottom: 1px solid ${(props) => props.theme.grey100};
  display: flex;
  height: 78px;
  justify-content: space-between;
  left: 0;
  position: sticky;
  right: 0;
  text-align: center;
  top: 0;
  transition: transform 0.4s;
  z-index: 10;
`;

S.ProfileInfo = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  .nameBox {
    color: ${(props) => props.theme.grey900};
    font-size: ${(props) => props.theme.fontSizeCaption1};
    font-weight: ${({ theme }) => theme.fontWeightNormal};
    line-height: ${(props) => props.theme.fontLineHeight};
    margin-top: 4px;
    text-align: center;
  }
`;

type NavBarProps = {
  imageSrc: string;
  nickname: string;
  isOnline: boolean;
  onBack?: (prop: any) => any;
};

function NavBar({ imageSrc, nickname, isOnline, onBack }: NavBarProps) {
  return (
    <NavBarWrapper underline onBack={onBack}>
      <S.ProfileInfo>
        <ProfileImage isOnline={isOnline} imageSrc={imageSrc} />
        <div className="nameBox">{nickname}</div>
      </S.ProfileInfo>
    </NavBarWrapper>
  );
}

export default NavBar;
