import IconType from '../IconType';

const Report = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M12,2.80548 C13.1208,2.80548 14.2416,3.35988 14.8812,4.46868 L14.8812,4.46868 L18.2688,10.33548 L21.6564,16.20348 C22.9368,18.42228 21.336,21.19428 18.7752,21.19428 L18.7752,21.19428 L5.2248,21.19428 C2.664,21.19428 1.0632,18.42228 2.3436,16.20348 L2.3436,16.20348 L5.7312,10.33548 L9.1188,4.46868 C9.7584,3.35988 10.8792,2.80548 12,2.80548 Z M12,4.30548 C11.6832,4.30548 10.8936,4.39428 10.4172,5.21988 L10.4172,5.21988 L7.0296,11.08548 L3.642,16.95348 C3.1668,17.77908 3.4836,18.50628 3.642,18.78108 C3.8004,19.05588 4.2732,19.69428 5.2248,19.69428 L5.2248,19.69428 L18.7752,19.69428 C19.7268,19.69428 20.1996,19.05588 20.358,18.78108 C20.5164,18.50628 20.8332,17.77908 20.3568,16.95348 L20.3568,16.95348 L16.9704,11.08548 L13.5816,5.21868 C13.1064,4.39428 12.3168,4.30548 12,4.30548 Z M12,15.80676 C12.2676,15.80676 12.4932,15.89916 12.6756,16.08276 C12.8592,16.26636 12.9516,16.49196 12.9516,16.75836 C12.9516,17.02596 12.8592,17.25276 12.6756,17.43516 C12.4932,17.61876 12.2676,17.70996 12,17.70996 C11.7324,17.70996 11.5068,17.61876 11.3244,17.43516 C11.1396,17.25276 11.0484,17.02596 11.0484,16.75836 C11.0484,16.49196 11.1396,16.26636 11.3244,16.08276 C11.5068,15.89916 11.7324,15.80676 12,15.80676 Z M12,7.98396 C12.3168,7.98396 12.5748,8.04996 12.7644,8.19516 C12.9432,8.33076 13.05,8.61396 13.0392,8.87916 L12.8016,14.36196 C12.792,14.61036 12.7056,14.78316 12.5424,14.87916 C12.378,14.97516 12.198,15.02316 12,15.02316 C11.802,15.02316 11.6208,14.97516 11.4576,14.87916 C11.2956,14.78316 11.208,14.61036 11.1984,14.36196 L10.9608,8.87916 C10.95,8.61396 11.0556,8.32956 11.2356,8.19516 C11.4144,8.05956 11.6832,7.98396 12,7.98396 Z"
      />
    </svg>
  );
};

export default Report;
