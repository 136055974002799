import { RootState } from '../../index';

export const getIsPostsLoading = (state: RootState) => {
  return state.freeBoardPApp.isLoading;
};

export const getError = (state: RootState) => {
  return state.freeBoardPApp.error;
};

export const getPosts = (state: RootState) => {
  return state.freeBoardPApp.posts;
};

export const getPost = (state: RootState) => {
  return state.freeBoardPApp.post;
};

export const getTotalPostsCount = (state: RootState) => {
  return state.freeBoardPApp.totalPostsCount;
};

export const getComments = (state: RootState) => {
  return state.freeBoardPApp.comments;
};

export const getTotalCommentsCount = (state: RootState) => {
  return state.freeBoardPApp.totalCommentsCount;
};

export const getResponseStatus = (state: RootState) => {
  return state.freeBoardPApp.responseStatus;
};

export const getIsCommentCreated = (state: RootState) => {
  return state.freeBoardPApp.isCommentCreated;
};
