import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { StyledNamespace } from '@common/types/styledNamespace';
import { Icon } from '@common/ui/components';
import styled from 'styled-components';
import { getProfile } from '@common/redux/modules/profile/selectors';

const S: StyledNamespace = {};

type CertifyButtonProps = {
  icon: React.ReactNode;
  children?: React.ReactNode;
  setIsCertify: (...args: any) => any;
};

/**
 * Account 메뉴 항목
 *
 * @param {Object} props
 * @param {any} props.component
 */
function CertifyButton({ children, icon, setIsCertify }: CertifyButtonProps) {
  const profile = useSelector(getProfile);
  const { hasCertifiedIdentity } = profile;
  const { t } = useTranslation('translation');

  return (
    <S.CertifyButton onClick={setIsCertify}>
      <S.CertifyButtonWrapper>
        <S.CertifyButtonTitle>{children}</S.CertifyButtonTitle>
        {icon && <Icon iconName="ic_ArrowRight" colorCode="grey500" size={16} />}
        <S.CertifyText>
          {hasCertifiedIdentity ? t('str_verified', '인증 완료') : t('str_not_verified', '미인증')}
        </S.CertifyText>
      </S.CertifyButtonWrapper>
      <S.CertifyNotiText>
        {t('str_1345', '인증문자 수신 시, (주)키클롭스 이름으로 문자가 전송됩니다.')}
      </S.CertifyNotiText>
    </S.CertifyButton>
  );
}

CertifyButton.defaultProps = {
  icon: false
};

CertifyButton.propTypes = {
  icon: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

S.CertifyButton = styled.div`
  cursor: pointer;
  align-items: center;
  height: 56px;
  justify-content: space-between;
  padding: 0 24px;
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
`;

S.CertifyButtonTitle = styled.span`
  color: ${({ theme }) => theme.grey900};
  font-family: 'Noto Sans CJK KR', sans-serif;
  font-size: ${({ theme }) => theme.fontSizeBody1};
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: ${({ theme }) => theme.fontLineHeight};
  vertical-align: middle;
`;

S.CertifyText = styled.span`
  display: block;
  color: ${({ theme }) => theme.grey500};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  vertical-align: middle;
`;

S.CertifyButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

S.CertifyNotiText = styled.div`
  margin-top: 4px;
  font-size: ${({ theme }) => theme.fontSizeCaption2};
  color: ${({ theme }) => theme.grey500};
  text-align: start;
`;

export default CertifyButton;
