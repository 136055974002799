import IconType from '../IconType';

const BookingHistory = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M17.9284,2.6925 C19.4144,2.6925 20.6224,3.9005 20.6224,5.3855 L20.6224,5.3855 L20.6224,19.6145 C20.6224,20.5475 19.8634,21.3075 18.9294,21.3075 C18.7864,21.3075 18.6454,21.2625 18.5284,21.1785 L18.5284,21.1785 L16.5964,19.8045 L15.0704,20.9565 C14.6544,21.2695 14.0884,21.2795 13.6624,20.9775 L13.6624,20.9775 L12.0084,19.8025 L10.4564,20.9595 C10.0324,21.2735 9.4614,21.2765 9.0374,20.9635 L9.0374,20.9635 L7.4504,19.7985 L5.4684,21.1915 C5.3514,21.2725 5.2134,21.3175 5.0704,21.3175 C4.1364,21.3175 3.3784,20.5575 3.3784,19.6245 L3.3784,19.6245 L3.3784,5.3955 C3.3784,3.9105 4.5864,2.7025 6.0704,2.7025 L6.0704,2.7025 Z M17.9294,4.0785 L6.0714,4.0885 C5.3494,4.0885 4.7624,4.6755 4.7624,5.3955 L4.7624,5.3955 L4.7624,19.6245 C4.7624,19.7365 4.8234,19.8345 4.9134,19.8885 L4.9134,19.8885 L6.7674,18.5855 C7.1884,18.2905 7.7464,18.2965 8.1594,18.6005 L8.1594,18.6005 L9.7424,19.7625 L11.2944,18.6075 C11.7104,18.2965 12.2734,18.2915 12.6974,18.5915 L12.6974,18.5915 L14.3484,19.7655 L15.8754,18.6145 C16.2944,18.2975 16.8604,18.2925 17.2854,18.5935 L17.2854,18.5935 L19.0894,19.8775 C19.1774,19.8235 19.2374,19.7255 19.2374,19.6145 L19.2374,19.6145 L19.2374,5.3855 C19.2374,4.6645 18.6504,4.0785 17.9294,4.0785 L17.9294,4.0785 Z M12.753,14.7068 C13.167,14.7068 13.503,15.0428 13.503,15.4568 C13.503,15.8708 13.167,16.2068 12.753,16.2068 L12.753,16.2068 L7.779,16.2068 C7.365,16.2068 7.029,15.8708 7.029,15.4568 C7.029,15.0428 7.365,14.7068 7.779,14.7068 L7.779,14.7068 Z M16.2208,10.8645 C16.6348,10.8645 16.9708,11.2005 16.9708,11.6145 C16.9708,12.0285 16.6348,12.3645 16.2208,12.3645 L16.2208,12.3645 L7.7798,12.3645 C7.3658,12.3645 7.0298,12.0285 7.0298,11.6145 C7.0298,11.2005 7.3658,10.8645 7.7798,10.8645 L7.7798,10.8645 Z M16.2208,7.0227 C16.6348,7.0227 16.9708,7.3587 16.9708,7.7727 C16.9708,8.1867 16.6348,8.5227 16.2208,8.5227 L16.2208,8.5227 L7.7798,8.5227 C7.3658,8.5227 7.0298,8.1867 7.0298,7.7727 C7.0298,7.3587 7.3658,7.0227 7.7798,7.0227 L7.7798,7.0227 Z"
      />
    </svg>
  );
};

export default BookingHistory;
