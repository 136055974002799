import { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeGetIsAuthenticated } from '@common/redux/modules/session';
import { autoSignIn } from '@common/redux/modules/session/reducers';

import { Splash } from './pages';

function SplashContainer() {
  const dispatch = useDispatch();
  const getIsAuthenticated = useMemo(makeGetIsAuthenticated, []);
  const isAuthenticated = useSelector(getIsAuthenticated);

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(autoSignIn());
    }
  }, [dispatch, isAuthenticated]);

  return <Splash />;
}

export default SplashContainer;
