import IconType from '../IconType';

const PaperPlane = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M22,2 L15.4588803,21.5982847 C15.4095583,21.7460617 15.2941731,21.8624043 15.1468085,21.9129456 C14.8856017,22.0025313 14.6012282,21.8634048 14.5116425,21.6021979 L14.5116425,21.6021979 L11.6806202,13.348 L16.5807039,7.72082226 L11.0416202,12.694 L2.47586855,10.3130645 C2.35286894,10.27888 2.24883331,10.1993695 2.18336206,10.0924648 L2.14179569,10.00743 C2.04453482,9.74898289 2.17520212,9.46062455 2.43364927,9.36336368 L2.43364927,9.36336368 L22,2 Z"
      />
    </svg>
  );
};

export default PaperPlane;
