import IconType from '../IconType';

const Camera = ({ color, size, ...rest }: IconType) => (
  <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M12.69855,17.0217 C9.71205,17.4792 7.17555,14.9442 7.63305,11.9562 C7.91805,10.0947 9.43905,8.5737 11.30055,8.2872 C14.28855,7.8297 16.82505,10.3662 16.36755,13.3542 C16.08105,15.2157 14.56005,16.7367 12.69855,17.0217 M15.83355,3.5127 C16.26105,3.5127 16.64655,3.7692 16.81155,4.1622 L16.81155,4.1622 L17.65905,6.1812 L20.07855,6.1812 C21.18405,6.1812 22.07805,7.0782 22.07805,8.1822 L22.07805,8.1822 L22.07805,18.4872 C22.07805,19.5927 21.18405,20.4882 20.07855,20.4882 L20.07855,20.4882 L3.92055,20.4882 C2.81655,20.4882 1.92105,19.5927 1.92105,18.4872 L1.92105,18.4872 L1.92105,8.1822 C1.92105,7.0782 2.81655,6.1812 3.92055,6.1812 L3.92055,6.1812 L6.34155,6.1812 L7.18905,4.1622 C7.35405,3.7692 7.73955,3.5127 8.16705,3.5127 L8.16705,3.5127 Z M19.75,9 L18.5,9 C18.2238576,9 18,9.22385763 18,9.5 L18,9.5 L18,10 C18,10.2761424 18.2238576,10.5 18.5,10.5 L18.5,10.5 L19.75,10.5 C20.0261424,10.5 20.25,10.2761424 20.25,10 L20.25,10 L20.25,9.5 C20.25,9.22385763 20.0261424,9 19.75,9 L19.75,9 Z"
    />
  </svg>
);

export default Camera;
