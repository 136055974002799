import { useEffect, useState, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import {
  getComments,
  getPost,
  getTotalCommentsCount,
  getIsCommentCreated,
  getIsCommentDeleted,
  getIsLoading
} from '@common/redux/modules/socialCommunityPApp';
import {
  cleanUpPost,
  fetchComments,
  fetchDetail,
  addComment,
  deleteComment,
  removePost,
  increaseViewCount
} from '@common/redux/modules/socialCommunityPApp/reducers';

import JWT from '@common/utils/jwt';
import { POP_UP_MENU, COMMENT_DEFAULT_LIMIT } from '@common/constants/index';
import styled from 'styled-components';

import { buildPostsPath, buildEditPostPath } from '@routers/routes';
import { NavBar } from '../../components/molecules';
import { CancelButtonModal } from '../../components/templates';

import { Comment } from './molecules';
import { CommentInput } from './organisms';
import { PostCard } from './templates';
import { Loading } from '@common/ui/components/Loading';

function PostDetail() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const { postId } = useParams<{ postId: string }>();

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);
  const socialPost = useSelector(getPost);
  const comments = useSelector(getComments);
  const totalCommentsCount = useSelector(getTotalCommentsCount);
  const isCommentCreated = useSelector(getIsCommentCreated);
  const isCommentDeleted = useSelector(getIsCommentDeleted);

  const [channelCode, setChannelCode] = useState('');
  const [channelId, setChannelId] = useState(0);
  const [userId, setUserId] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [isDeleteCommentModalOpen, setIsDeleteCommentModalOpen] = useState(false);
  const [isDeletePostModalOpen, setIsDeletePostModalOpen] = useState(false);
  const [isPostDeleted, setIsPostDeleted] = useState(false);

  const isLoading = useSelector(getIsLoading);

  const targetId = useRef<number>(0);

  useEffect(
    () => () => {
      dispatch(cleanUpPost());
    },
    [dispatch]
  );

  useEffect(() => {
    if (!currentChannel) return;
    const userMeta = JWT.getUserMetaInChannel(currentChannel.id);

    setChannelCode(currentChannel.primitiveInvitationCode);
    setChannelId(currentChannel.id);
    setUserId(userMeta?.profileId);
  }, [currentChannel]);

  useEffect(() => {
    if (!channelId || !postId) return;

    dispatch(fetchDetail({ postId, channelId }));
    dispatch(fetchComments({ limit: COMMENT_DEFAULT_LIMIT, page: currentPage, postId, channelId }));
  }, [dispatch, postId, channelId, currentPage]);

  useEffect(() => {
    if (!channelId || !postId) return;

    if (isCommentCreated || isCommentDeleted) {
      dispatch(
        fetchComments({ limit: COMMENT_DEFAULT_LIMIT, page: currentPage, postId, channelId })
      );
    }
  }, [dispatch, postId, isCommentCreated, isCommentDeleted, currentPage, channelId]);

  useEffect(() => {
    if (socialPost) {
      dispatch(increaseViewCount({ channelId, resourceId: socialPost.id }));
    }
  }, [socialPost, channelId]);

  useEffect(() => {
    if (isPostDeleted) {
      history.push(buildPostsPath(channelCode));
    }
  }, [isPostDeleted]);

  const handleScrollDown = () => {
    if (comments.length === totalCommentsCount) return;

    setCurrentPage(currentPage + 1);
  };

  const handleAddComment = (content: string) => {
    dispatch(addComment({ content, channelId, postId }));
  };

  const handleDeleteCommentModalOpen = (id: number) => {
    targetId.current = id;

    setIsDeleteCommentModalOpen(true);
  };

  const handleDeleteComment = () => {
    dispatch(deleteComment({ postId, commentId: targetId.current, channelId }));

    handleCloseDeleteCommentModal();
  };

  const handleCloseDeleteCommentModal = () => {
    targetId.current = 0;
    setIsDeleteCommentModalOpen(false);
  };

  const handleClickMenuButton = (value: string) => {
    if (!currentChannel) return;

    if (value === POP_UP_MENU.EDIT) {
      history.push(buildEditPostPath(channelCode, postId));
      return;
    }

    if (value === POP_UP_MENU.DELETE) {
      setIsDeletePostModalOpen(true);
    }
  };

  const handleDeletePost = () => {
    dispatch(removePost({ postId, channelId }));
    setIsDeletePostModalOpen(false);
    setIsPostDeleted(true);
  };

  return (
    <>
      {isLoading && <Loading />}
      <NavBar isBack backUrl={buildPostsPath(channelCode)} />

      <S_EmptyDiv />

      {socialPost && (
        <PostCard
          id={socialPost.id}
          author={socialPost.author}
          authorThumbnailSrc={socialPost.authorThumbnailSrc}
          createdAt={socialPost.insertedAt}
          isAuthor={socialPost.profileId === userId}
          content={socialPost.content}
          resource={socialPost.storyResource}
          handleClickPopUpMenu={handleClickMenuButton}
          isLiked={socialPost.hasUserLiked}
          isDisliked={socialPost.hasUserDisliked}
          likeCount={socialPost.totalPostLikes}
          dislikeCount={socialPost.totalPostDislikes}
          channelId={channelId}
        />
      )}

      <CommentInput commentCount={totalCommentsCount} handleAddComment={handleAddComment} />

      <S_ScrollField
        dataLength={comments.length}
        next={handleScrollDown}
        hasMore={comments.length !== totalCommentsCount}
      >
        {comments.length === 0 ? (
          <S_NoItem>
            {t('str_407', '아직 업로드한 소셜 게시글 [[str_comment]]이 없습니다.')}
          </S_NoItem>
        ) : (
          <>
            {comments.map(({ author, authorThumbnailSrc, content, profileId, insertedAt, id }) => (
              <Comment
                key={id}
                id={id}
                isAuthor={profileId === userId}
                nickname={author}
                avatarUrl={authorThumbnailSrc}
                content={content}
                createdAt={insertedAt}
                handleClickDeleteButton={handleDeleteCommentModalOpen}
              />
            ))}
          </>
        )}
      </S_ScrollField>

      <CancelButtonModal
        isOpen={isDeleteCommentModalOpen}
        onClick={handleDeleteComment}
        onClose={handleCloseDeleteCommentModal}
        message={t('str_62', '[[str_delete]]하시겠습니까?')}
      />

      <CancelButtonModal
        isOpen={isDeletePostModalOpen}
        onClick={handleDeletePost}
        onClose={() => setIsDeletePostModalOpen(false)}
        message={t(
          'str_delete_alert_dialog',
          '정말 삭제하시겠습니까?\n(삭제한 이후 되돌릴 수 없습니다.)'
        )}
      />
    </>
  );
}

const S_NoItem = styled.div`
  color: ${({ theme }) => theme.grey400};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  margin-bottom: 80px;
  margin-top: 48px;
  text-align: center;
`;

const S_EmptyDiv = styled.div`
  height: 48px;
`;

const S_ScrollField = styled(InfiniteScroll as any)`
  margin-bottom: 24px;
`;

export default PostDetail;
