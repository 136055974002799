import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Button } from '@common/ui/components';
import styled from 'styled-components';

function ChannelDeactivate() {
  const { t } = useTranslation('translation');
  const history = useHistory();

  return (
    <S_ChannelDeactivate>
      {t('str_channel_deactivate', 'Channel Deactivate')}
      <Button onClick={() => history.push('/account')} label={t('str_ok', '확인')} />
    </S_ChannelDeactivate>
  );
}

const S_ChannelDeactivate = styled.div`
  align-items: center;
  box-shadow: -30px 0 100px -100px #000, 30px 0 100px -100px #000;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  width: 100%;
`;

export default ChannelDeactivate;
