import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Button } from '@common/ui/components';

/**
 * Not Found Page
 */
function NotFoundPage() {
  const { t } = useTranslation('translation');
  const history = useHistory();

  return (
    <S_NotFoundPage>
      {t('str_not_found_page', 'Not Found Page')}
      <Button onClick={() => history.push('/account')} label={t('str_ok', '확인')} />
    </S_NotFoundPage>
  );
}

const S_NotFoundPage = styled.div`
  align-items: center;
  box-shadow: -30px 0 100px -100px #000, 30px 0 100px -100px #000;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  width: 100%;
`;

export default NotFoundPage;
