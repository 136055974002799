import { useHistory } from 'react-router-dom';

import { Icon } from '@common/ui/components';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { openSideMenu } from '@common/redux/modules/channel/reducers';

import { Text } from '../atoms';

type Props = {
  title?: string;
  backUrl?: string;
  isMenu?: boolean;
  isBack?: boolean;
};

function NavBar({ title = '', backUrl, isBack, isMenu }: Props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleBack = (backUrlValue?: string) => {
    if (backUrlValue) {
      history.push(backUrlValue);
      return;
    }

    history.goBack();
  };

  function openMenu() {
    dispatch(openSideMenu());
  }

  return (
    <S_NavBar isBack={isBack}>
      {isMenu && (
        <S_IconWrapper
          onClick={() => {
            openMenu();
          }}
        >
          <Icon iconName="ic_Menu" size={24} />
        </S_IconWrapper>
      )}
      {isBack && (
        <S_IconWrapper
          onClick={() => {
            handleBack(backUrl);
          }}
        >
          <Icon iconName="ic_ArrowLeft" size={24} />
        </S_IconWrapper>
      )}
      {!isBack && !isMenu && <S_Empty />}
      <S_TextWrapper>
        <Text fontSizes="fontSizeBody2" fontWeights="fontWeightBold">
          {title}
        </Text>
      </S_TextWrapper>
      <S_Empty />
    </S_NavBar>
  );
}

const S_NavBar = styled.div<{ isBack?: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 56px;
  justify-content: space-between;
  z-index: ${({ isBack }) => (isBack ? 10 : 4)};
`;

const S_TextWrapper = styled.div`
  align-items: flex-start;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  justify-content: center;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const S_IconWrapper = styled.div<{ isEmptyElem?: boolean }>`
  align-items: center;
  cursor: ${({ isEmptyElem }) => !isEmptyElem && 'pointer'};
  display: flex;
  flex-direction: row;
  height: 48px;
  justify-content: center;
  margin-left: 8px;
  width: 48px;
`;

const S_Empty = styled.div`
  height: 56px;
  width: 56px;
`;

export default NavBar;
