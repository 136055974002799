/**
 * 숫자를 통화 형식으로 변환한다.
 * ex) 1024 => 1,024
 */
export default function formatCurrency(value: string | number, decimals = 0) {
  const str = Number(value).toFixed(decimals);

  if (decimals) {
    return str.replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  }

  return str.replace(/(\d)(?=(\d{3})+$)/g, '$1,');
}
