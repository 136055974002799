/* eslint-disable */
export default {
  orders: [],
  virtualProducts: [],
  systemInvoices: [],
  systemReceipts: [],
  channelPlanProducts: [
    {
      id: 1,
      model: 'ChannelPlanProduct',
      name: 'Starter Plan',
      type: 'STARTER',
      price: {
        currency: 'KRW',
        amount: 30000
      },
      recurring: true,
      isDeactivated: false,
      isDeleted: false
    },
    {
      id: 2,
      model: 'ChannelPlanProduct',
      name: 'Basic Plan',
      type: 'BASIC',
      price: {
        currency: 'KRW',
        amount: 90000
      },
      recurring: true,
      isDeactivated: false,
      isDeleted: false
    },
    {
      id: 3,
      model: 'ChannelPlanProduct',
      name: 'Pro Plan',
      type: 'PRO',
      price: {
        currency: 'KRW',
        amount: 390000
      },
      recurring: true,
      isDeactivated: false,
      isDeleted: false
    },
    {
      id: 4,
      model: 'ChannelPlanProduct',
      type: 'MEMBER',
      name: 'Member Plan',
      price: {
        currency: 'KRW',
        amount: 10000
      },
      recurring: false,
      isDeactivated: false,
      isDeleted: false
    }
  ]
};
