import { useEffect, useState, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import {
  getPosts,
  getTotalPostsCount,
  getIsFetched
} from '@common/redux/modules/socialCommunityPApp';
import {
  cleanUpPosts,
  fetchList,
  makeIsFetchedStatus,
  removePost
} from '@common/redux/modules/socialCommunityPApp/reducers';
import { Icon } from '@common/ui/components';
import JWT from '@common/utils/jwt';
import { POP_UP_MENU, SOCIAL_POST_DEFAULT_LIMIT } from '@common/constants/index';
import styled from 'styled-components';

import { buildChannelsPath, buildCreatePostPath, buildEditPostPath } from '@routers/routes';
import { NavBar } from '../../components/molecules';
import { CancelButtonModal } from '../../components/templates';

import { PostCard } from './molecules';

function Posts() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const socialPosts = useSelector(getPosts);
  const totalSocialPostsCount = useSelector(getTotalPostsCount);

  const isFetched = useSelector(getIsFetched);

  const [channelCode, setChannelCode] = useState('');
  const [channelId, setChannelId] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userId, setUserId] = useState(0);
  const [postCount, setPostCount] = useState(0);

  const targetId = useRef(0);

  useEffect(
    () => () => {
      dispatch(cleanUpPosts());
      dispatch(makeIsFetchedStatus(false));
    },
    []
  );

  useEffect(() => {
    if (!currentChannel) return;

    const userMeta = JWT.getUserMetaInChannel(currentChannel.id);

    setChannelCode(currentChannel.primitiveInvitationCode);
    setChannelId(currentChannel.id);
    setUserId(userMeta?.profileId);
  }, [currentChannel]);

  useEffect(() => {
    if (!channelId) return;

    dispatch(fetchList({ limit: SOCIAL_POST_DEFAULT_LIMIT, page: currentPage, channelId }));
  }, [dispatch, currentPage, channelId]);

  useEffect(() => {
    if (!channelId) return;

    if (isFetched) {
      dispatch(fetchList({ limit: SOCIAL_POST_DEFAULT_LIMIT, page: currentPage, channelId }));
    }
  }, [dispatch, currentPage, channelId, isFetched]);

  useEffect(() => {
    if (socialPosts.length > 0) {
      setPostCount(socialPosts.length);
    }
  }, [socialPosts]);

  const handleScrollDown = () => {
    if (socialPosts.length === totalSocialPostsCount) return;

    setCurrentPage(currentPage + 1);
  };

  const handleClickMenuButton = (value: string, id?: number) => {
    if (value === POP_UP_MENU.EDIT) {
      history.push(buildEditPostPath(channelCode, id));
      return;
    }

    if (value === POP_UP_MENU.DELETE && id) {
      targetId.current = id;
      setIsDeleteModalOpen(true);
    }
  };

  const closeDeleteModal = () => {
    targetId.current = 0;
    setIsDeleteModalOpen(false);
  };

  const handleDeletePost = () => {
    if (!targetId.current) return;

    dispatch(removePost({ postId: targetId.current, channelId }));
    closeDeleteModal();
  };

  return (
    <S_Wrapper>
      <NavBar
        isMenu
        title={t('str_pg_title_papp_scommunity_posts', '소셜 게시판')}
        icon={<Icon iconName="ic_Edit" fill />}
        iconLinkUrl={buildCreatePostPath(channelCode)}
        backUrl={buildChannelsPath(channelCode)}
      />

      <S_EmptyBox />

      <InfiniteScroll
        dataLength={postCount}
        next={handleScrollDown}
        hasMore={postCount !== totalSocialPostsCount}
        loader={<div />}
        scrollThreshold={0.9}
      >
        {socialPosts.length === 0 ? (
          <S_NoItem>{t('str_374', '아직 업로드한 게시글이 없습니다.')}</S_NoItem>
        ) : (
          <>
            {socialPosts.map((post) => (
              <PostCard
                key={post.id}
                channelCode={channelCode}
                id={post.id}
                author={post.author}
                authorThumbnailSrc={post.authorThumbnailSrc}
                isAuthor={userId === post.profileId}
                type={post.storyResource.type}
                previews={post.storyResource.imageSrcs || post.storyResource.youtubeSrc}
                content={post.content}
                createdAt={post.insertedAt}
                commentCount={post.totalComments}
                likeCount={post.totalPostLikes}
                dislikeCount={post.totalPostDislikes}
                onPopUpMenuClick={handleClickMenuButton}
                isLiked={post.hasUserLiked}
                isDisliked={post.hasUserDisliked}
                channelId={channelId}
              />
            ))}
          </>
        )}
      </InfiniteScroll>

      <CancelButtonModal
        isOpen={isDeleteModalOpen}
        onClick={handleDeletePost}
        onClose={closeDeleteModal}
        message={t(
          'str_delete_alert_dialog',
          '정말 삭제하시겠습니까?\n(삭제한 이후 되돌릴 수 없습니다.)'
        )}
      />
    </S_Wrapper>
  );
}

const S_Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const S_EmptyBox = styled.div`
  height: 56px;
`;

const S_NoItem = styled.div`
  color: ${({ theme }) => theme.grey400};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  margin-top: 96px;
`;

export default Posts;
