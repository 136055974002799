import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getMyBookingHistory } from '@common/redux/modules/booking';
import { fetchMyBookingHistory } from '@common/redux/modules/booking/reducers';
import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import styled from 'styled-components';

import { MyBookingHistoryTemplate } from './templates';

type myBookingHistoryProps = {
  channelID: string;
};

function MyBookingHistory({ channelID }: myBookingHistoryProps) {
  const dispatch = useDispatch();

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const myBookingHistory = useSelector(getMyBookingHistory);

  const bookingList = myBookingHistory.bookings;
  const { total } = myBookingHistory.pagination;
  const [currentLimit, setCurrentLimit] = useState(5);
  const [lastHistory, setLastHistory] = useState(true);

  const handleScrollUp = () => {
    if (bookingList.length === total) {
      setLastHistory(false);
    }
    setCurrentLimit(currentLimit + 5);
  };

  useEffect(() => {
    currentChannel &&
      dispatch(
        fetchMyBookingHistory({ pagenation: { page: 1, limit: 5 }, channelId: currentChannel.id })
      );
  }, [currentChannel, dispatch]);

  useEffect(() => {
    if (currentLimit === 5) return;

    setTimeout(() => {
      currentChannel &&
        dispatch(
          fetchMyBookingHistory({
            pagenation: { page: 1, limit: currentLimit },
            channelId: currentChannel.id
          })
        );
    }, 500);
  }, [dispatch, currentLimit, currentChannel]);
  return (
    <S_MyBookingHistoryContainer>
      <MyBookingHistoryTemplate
        bookingList={bookingList}
        handleScrollUp={handleScrollUp}
        hasMore={lastHistory}
        channelID={channelID}
      />
    </S_MyBookingHistoryContainer>
  );
}

const S_MyBookingHistoryContainer = styled.div`
  background-color: #f5f6f8;
  min-height: 100vh;
`;

export default MyBookingHistory;
