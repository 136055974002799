import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import MonkeyRouter, { customHistory } from './common/overrides/MonkeyRouter';
import createReduxStore from './common/redux';

import GlobalStyle from './GlobalStyle';
import theme from './common/ui/styles/theme';
import App from './App';
import * as serviceWorker from './serviceWorker';

const store = createReduxStore(customHistory);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MonkeyRouter>
        <GlobalStyle theme={theme} />
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </MonkeyRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
