import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styled, { css } from 'styled-components';

type Props = {
  content: string;
  isFoldable?: boolean;
};

function Contents({ content, isFoldable = false }: Props) {
  const { t } = useTranslation('translation');

  const [hasReadMoreButton, setHasReadMoreButton] = useState(false);
  const [isTextOpen, setTextOpen] = useState(false);

  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    return () => {
      setHasReadMoreButton(false);
      setTextOpen(false);
    };
  }, []);

  useEffect(() => {
    if (!contentRef || !contentRef.current) return;

    const { scrollHeight, clientHeight } = contentRef.current;
    const isTextClamped = scrollHeight > clientHeight;

    setHasReadMoreButton(isTextClamped);
  }, []);

  useEffect(() => {
    if (!isFoldable) {
      setHasReadMoreButton(false);
      setTextOpen(true);
    }
  }, [isFoldable]);

  return (
    <>
      {isFoldable ? (
        <>
          <S_TextContent ref={contentRef} isOpen={isTextOpen}>
            {content}
          </S_TextContent>
          {hasReadMoreButton &&
            (isFoldable ? (
              <S_ReadMore onClick={() => setTextOpen(!isTextOpen)}>
                {isTextOpen ? t('str_78', '간략히보기') : t('str_77', '자세히보기')}
              </S_ReadMore>
            ) : (
              <S_ReadMore>{t('str_77', '자세히보기')}</S_ReadMore>
            ))}
        </>
      ) : (
        <S_TextContent isOpen>{content}</S_TextContent>
      )}
    </>
  );
}

const S_TextContent = styled.div<{
  isOpen?: boolean;
}>`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  line-height: ${({ theme }) => theme.fontLineHeight};
  margin-top: 12px;
  overflow-wrap: break-word;
  word-break: break-word;
  padding: 0 24px;
  white-space: pre-wrap;

  ${({ isOpen }) =>
    !isOpen &&
    css`
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      max-height: 4.5em;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

const S_ReadMore = styled.div`
  color: ${({ theme }) => theme.grey500};
  font-size: ${({ theme }) => theme.fontSizeCaption1};
  margin-top: 8px;
  padding-left: 24px;
`;

export default Contents;
