import { useTranslation } from 'react-i18next';

import { Icon } from '@common/ui/components';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { buildChannelsProfilePath } from '@routers/routes';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import { makeGetCurrentChannelProfile } from '@common/redux/modules/profile';
import { makeGetChannelProfiles } from '@common/redux/modules/user';
import { fetchCurrentChannelProfile } from '@common/redux/modules/profile/reducers';
import { fetchCurrentUser } from '@common/redux/modules/user/reducers';

function InfoItem() {
  const { t } = useTranslation('translation');
  const history = useHistory();
  const dispatch = useDispatch();
  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);

  const getCurrentChannelProfile = useMemo(makeGetCurrentChannelProfile, []);
  const getChannelProfiles = useMemo(makeGetChannelProfiles, []);

  const channelProfiles = useSelector(getChannelProfiles);
  const currentChannel = useSelector(getCurrentChannel);
  const currentChannelProfile = useSelector(getCurrentChannelProfile);

  const { nickname, imageSrc, id } = currentChannelProfile;

  useEffect(() => {
    if (channelProfiles && currentChannel) {
      let channelProfileId = null;

      channelProfiles.forEach((eachProfile: any) => {
        if (eachProfile.channelId === currentChannel.id) {
          channelProfileId = eachProfile.id;
        }
      });

      if (channelProfileId) {
        dispatch(fetchCurrentChannelProfile(channelProfileId));
      }
    }
  }, [channelProfiles, currentChannel]);

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, []);

  return (
    <S_InfoItem>
      <S_Profile>
        {imageSrc ? (
          <Image src={imageSrc} alt="InfoItem" />
        ) : (
          <S_ProfileIcon>
            <Icon iconName="ic_PostSocial" colorCode="blue500" size={48} fill />
          </S_ProfileIcon>
        )}
      </S_Profile>
      <S_Nickname>{nickname}</S_Nickname>
      <S_ChannelId onClick={() => navigator.clipboard.writeText(id.toString())}>
        {t('str_1235', { profileId: '', defaultValue: '프로필ID : {{profileId}}' })}
        <S_Value>{id}</S_Value>
      </S_ChannelId>
      <S_EditProfile
        onClick={() =>
          history.push(buildChannelsProfilePath(currentChannel?.primitiveInvitationCode))
        }
      >
        <S_EditIcon iconName="ic_Edit" colorCode="grey500" size={16} fill />
        {t('str_update_profile_info', '프로필 정보 수정')}
      </S_EditProfile>
    </S_InfoItem>
  );
}

const S_InfoItem = styled.div`
  align-items: center;
  border-bottom: 16px solid ${({ theme }) => theme.grey50};
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 16px;
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 24px;
`;

const S_Profile = styled.div`
  height: 64px;
  position: relative;
  width: 64px;
`;

const Image = styled.img`
  border-radius: 50%;
  width: 64px;
  height: 64px;
`;

const S_Nickname = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.grey900};
  display: flex;
  flex: 1;
  font-size: ${({ theme }) => theme.fontSizeH2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  justify-content: center;
  margin-bottom: 4px;
  margin-top: 4px;
  text-align: center;
`;

const S_EditIcon = styled(Icon)`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: 16px;
  justify-content: center;
  margin-right: 4px;
  width: 16px;
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
`;

const S_EditProfile = styled.span`
  align-items: center;
  color: ${({ theme }) => theme.grey500};
  cursor: pointer;
  display: flex;
  font-size: ${({ theme }) => theme.fontSizeCaption1};
  text-align: center;
`;

const S_ChannelId = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.grey50};
  border-radius: 12px;
  color: ${({ theme }) => theme.grey400};
  display: inline-flex;
  font-size: ${({ theme }) => theme.fontSizeCaption1};
  justify-content: center;
  margin-bottom: 24px;
  padding: 3px 12px;
  cursor: pointer;
`;

const S_Value = styled.span`
  color: ${({ theme }) => theme.grey900};
  padding-left: 4px;
`;

const S_ProfileIcon = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.grey50};
  border-radius: 9999px;
  display: flex;
  height: 64px;
  justify-content: center;
  width: 64px;
  &:focus {
    outline: none;
  }
`;

export default InfoItem;
