import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button } from '@common/ui/components';
import styled from 'styled-components';

import { certifyPath } from '@routers/routes';

import { refresh } from '@common/redux/modules/session/reducers';

function CertifyDesc() {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();

  const openCertify = () => {
    const win = window.open(certifyPath);

    const timer = setInterval(() => {
      if (win?.closed) {
        clearInterval(timer);
        dispatch(refresh());
      }
    }, 500);
  };
  /**
   * 이미지가 있을 경우와 없을 경우 프로필 정보 수정
   */

  return (
    <>
      <S_CertifyDescWrapper>
        <S_DescTextWrapper>
          <S_DescTitle>{t('str_verify_user', '본인인증')}</S_DescTitle>

          <S_DescText>
            {t(
              'str_1386',
              '* 서비스 내에 청소년 유해물 콘텐츠가 포함된 경우, 해당 콘텐츠는 휴대폰 본인인증 절차를 통해 연령 확인이 완료된 이용자에게 표시됩니다.'
            )}
          </S_DescText>
          <S_DescText>
            {t(
              'str_1387',
              '* 본인인증 절차 중 인증번호 문자 수신 시, 개인정보 처리주체인 "(주)키클롭스"의 이름으로 인증 문자가 전송될 예정입니다.'
            )}
          </S_DescText>
          <S_DescText>
            {t(
              'str_1388',
              '* 본인인증 완료 시, 기존에 설정하신 프로필 정보의 연령 정보(출생연도, 출생월, 출생일)는 본인인증으로 확인된 정보로 갱신되며 이후 임의로 수정할 수 없습니다.'
            )}
          </S_DescText>
          <S_DescText>
            {t(
              'str_1389',
              '* 본인인증으로 확인된 개인정보는 최대 1년까지 유효하며, 이후에 본인인증 절차를 다시 진행하여 개인정보를 갱신할 수 있습니다.'
            )}
          </S_DescText>
        </S_DescTextWrapper>
        <S_ButtonWrapper>
          <Button
            type="button"
            label={t('str_request_verify', '인증하기')}
            full
            onClick={() => {
              openCertify();
            }}
          />
        </S_ButtonWrapper>
      </S_CertifyDescWrapper>
    </>
  );
}
const S_ButtonWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0px 24px;
`;

const S_DescTitle = styled.h2`
  text-align: start;
`;

const S_CertifyDescWrapper = styled.div``;

const S_DescText = styled.p`
  text-align: start;
`;

const S_DescTextWrapper = styled.div`
  margin-bottom: 32px;
  padding: 0 24px;
`;

export default CertifyDesc;
