import React from 'react';

import styled from 'styled-components';

function StickyTop({ children }: { children: React.ReactNode }) {
  return (
    <S_StickyTop>
      <>{children}</>
    </S_StickyTop>
  );
}

const S_StickyTop = styled.div`
  background-color: ${({ theme }) => theme.white};
  border: none;
  position: sticky;
  top: 0;
  z-index: 5;
`;

export default StickyTop;
