import { EnumOfObjects, URI } from '../../utils';
import { PathVariableType } from '../../utils/uri';

import ENDPOINTS from './endpoints.json';

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';
type Permission = 'private' | 'public' | 'conditional';

export type ENDPOINT = {
  method: Method;
  path: string;
  permission: Permission;
  pathVariableTypes?: {
    [key: string]: PathVariableType;
  };
};

type EndpointType = typeof ENDPOINTS;
type EndpointKey = keyof EndpointType;

export type Endpoint = {
  [P in EndpointKey]: {
    [P2 in keyof EndpointType[P]]: {
      [P3 in keyof EndpointType[P][P2]]: ENDPOINT;
    };
  };
};

const {
  REACT_APP_API_HOST,
  REACT_APP_CHAT_API_HOST,
  REACT_APP_BOOKING_API_HOST,
  REACT_APP_VOD_API_HOST
} = process.env;

export function getEndpointSet<T extends keyof Endpoint, U extends keyof Endpoint[T]>(
  serverName: T,
  domain: U
) {
  const host = getHostByServerName(serverName);
  return EnumOfObjects.assignAndExtendAll(
    (ENDPOINTS as Endpoint)[serverName][domain],
    { host },
    {
      pathRegExp: (endpoint: ENDPOINT) => {
        return URI.genTemplateUriRegExp(endpoint.path, {
          pathVariableTypes: endpoint.pathVariableTypes
        });
      }
    }
  );
}

export function getHostByServerName(serverName: string) {
  switch (serverName) {
    case 'AUTH':
    case 'CHANNEL':
    case 'SUBSCRIPTION_PRODUCTS':
    case 'SOCIAL_COMMUNITY_PAPP':
      return REACT_APP_API_HOST;
    case 'BOOKING':
      return REACT_APP_BOOKING_API_HOST;
    case 'VOD_PAPP':
      return REACT_APP_API_HOST;
    case 'VOD':
      return REACT_APP_VOD_API_HOST;
    case 'VOD_PLUS_PAPP':
      return REACT_APP_API_HOST;
    case 'VOD_PLUS':
      return REACT_APP_VOD_API_HOST;
    case 'STATISTICS':
      return REACT_APP_API_HOST;
    case 'CHAT':
      return REACT_APP_CHAT_API_HOST;
    case 'NOTICE_PAPP':
      return REACT_APP_API_HOST;
    case 'FREE_BOARD_PAPP':
      return REACT_APP_API_HOST;
    default:
      return '';
  }
}
