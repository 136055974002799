import React from 'react';

import { StyledNamespace } from '@common/types/styledNamespace';
import styled, { css } from 'styled-components';

const S: StyledNamespace = {};

export type ButtonProps = {
  fill?: boolean;
  line?: boolean;
  text?: boolean;
  disabled?: boolean;
  full?: boolean;
  icon?: React.ReactElement;
  size?: 'large' | 'regular' | 'small';
  outer?: boolean;
  value?: string;
  label?: string;
  onlyIcon?: boolean;
  inputIcon?: boolean;
  reversed?: boolean;
  customColor?: {
    backgroundColor?: string;
    textColor?: string;
  };
  onClick?: (...args: any) => any;
  children?: React.ReactNode;
  type?: 'submit' | 'reset' | 'button';
};

export type StyleProps = {
  $fill?: boolean;
};

function Button({
  fill = true,
  line,
  text,
  disabled,
  full,
  icon,
  size = 'large',
  label,
  onlyIcon,
  inputIcon,
  reversed,
  customColor,
  onClick,
  type = 'button',
  ...rest
}: ButtonProps) {
  return (
    <S.Button
      id="Button"
      type={type}
      tabIndex={0}
      $fill={fill}
      line={line}
      text={text}
      onlyIcon={onlyIcon}
      disabled={disabled}
      full={full}
      reversed={reversed}
      size={size}
      inputIcon={inputIcon}
      onClick={onClick}
      onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) => e.preventDefault()}
      style={{
        backgroundColor: customColor && fill && !line && !text && customColor.backgroundColor,
        color: customColor && customColor.textColor
      }}
      {...rest}
    >
      {!!icon && (
        <S.Icon reversed={reversed} inputIcon={inputIcon} size={size} onlyIcon={onlyIcon}>
          {icon}
        </S.Icon>
      )}
      {label}
    </S.Button>
  );
}

const fillStyle = css`
  background-color: ${({ theme }) => theme.blue500};
  color: ${({ theme }) => theme.white};
`;

const fillDisabledStyle = css`
  background-color: ${({ theme }) => theme.grey100};
  color: ${({ theme }) => theme.textDisable};
`;

const textStyle = css`
  background-color: transparent;
  color: ${({ theme }) => theme.textPrimary};
`;

const textDisabledStyle = css`
  background-color: transparent;
  color: ${({ theme }) => theme.textDisable};
`;

const lineStyle = css`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.grey400};
  color: ${({ theme }) => theme.textPrimary};
`;

const lineDisabledStyle = css`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.grey100};
  color: ${({ theme }) => theme.textDisable};
`;

const inputIconStyle = css`
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  height: 48px;
  margin: 0;
  width: 48px;
`;

const inputIconDisabledStyle = css`
  border: 0;
  height: 48px;
  margin: 0;
  width: 48px;
`;

const buttonLargeStyle = css`
  border-radius: 24px;
  font-size: ${({ theme }) => theme.fontSizeBody1};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  height: 48px;
  padding: 0 24px;
`;

const buttonRegularStyle = css`
  border-radius: 16px;
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  height: 32px;
  padding: 0 16px;
`;

const buttonSmallStyle = css<StyleProps>`
  border-radius: 12px;
  color: ${({ theme, $fill }) => ($fill ? theme.white : theme.grey500)};
  font-size: ${({ theme }) => theme.fontSizeCaption1};
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  height: 24px;
  padding: 0 16px;
`;

const onlyIconStyle = css`
  background-color: ${({ theme }) => theme.white};
  border: 1px solid transparent;
  border-radius: 16px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  height: 48px;
  padding: 12px;
  width: 48px;
`;

S.Button = styled.button<ButtonProps & StyleProps>`
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  display: inline-flex;
  font-stretch: normal;
  font-style: normal;
  justify-content: center;
  letter-spacing: normal;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;

  ${({ onlyIcon, size }) =>
    !onlyIcon &&
    size &&
    {
      large: buttonLargeStyle,
      regular: buttonRegularStyle,
      small: buttonSmallStyle
    }[size]}

  ${({ $fill, onlyIcon, disabled }) =>
    $fill && !onlyIcon && (!disabled ? fillStyle : fillDisabledStyle)}

  ${({ text, onlyIcon, disabled }) =>
    text && !onlyIcon && (!disabled ? textStyle : textDisabledStyle)}

  ${({ line, onlyIcon, disabled }) =>
    line && !onlyIcon && (!disabled ? lineStyle : lineDisabledStyle)}

  ${({ reversed }) => (reversed ? 'flex-direction: row-reverse;' : 'flex-direction: row;')};
  ${({ full }) => full && `width: 100%;`};

  ${({ disabled, inputIcon }) =>
    inputIcon && (!disabled ? inputIconStyle : inputIconDisabledStyle)};

  ${({ disabled, onlyIcon }) => !disabled && onlyIcon && onlyIconStyle};
  ${({ disabled }) => !!disabled && 'cursor: default;'};

  &:focus {
    outline: none;
  }

  &:hover:enabled {
    ${({ $fill }) =>
      $fill &&
      'background-image: linear-gradient(to top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));'};
    ${({ line }) =>
      line &&
      css`
        background-color: ${({ theme }) => theme.grey50};
      `};
    ${({ text }) =>
      text &&
      css`
        background-color: ${({ theme }) => theme.grey50};
      `};
    ${({ onlyIcon }) =>
      onlyIcon &&
      css`
        background-color: ${({ theme }) => theme.grey50};
      `};
    ${({ inputIcon }) => inputIcon && 'background-image: none;'};
  }
  &:active:enabled {
    ${({ $fill }) =>
      $fill &&
      'background-image: linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));'};
    ${({ line }) =>
      line &&
      css`
        background-color: ${({ theme }) => theme.grey100};
      `};
    ${({ text }) =>
      text &&
      css`
        background-color: ${({ theme }) => theme.grey100};
      `};
    ${({ onlyIcon }) =>
      onlyIcon &&
      css`
        background-color: ${({ theme }) => theme.grey100};
      `};
  }
`;

const iconLargeStyle = css`
  height: 24px;
  width: 24px;
`;

const iconRegularStyle = css`
  height: 16px;
  width: 16px;
`;

S.Icon = styled.span<ButtonProps>`
  ${({ onlyIcon, size }) =>
    !onlyIcon &&
    size &&
    {
      large: iconLargeStyle,
      regular: iconRegularStyle,
      small: 'display: none;'
    }[size]}
  svg {
    ${({ onlyIcon, size }) =>
      !onlyIcon &&
      size &&
      {
        large: iconLargeStyle,
        regular: iconRegularStyle,
        small: 'display: none;'
      }[size]}
  }
  ${({ onlyIcon, reversed }) =>
    !onlyIcon && (reversed ? 'margin-left: 4px' : 'margin-right: 4px;')};
  ${({ inputIcon, size, reversed }) => inputIcon && size === 'large' && !reversed && 'margin: 0'};
`;

export default Button;
