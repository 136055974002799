import { Switch, Route, Redirect } from 'react-router-dom';
import { accountPath, rootPath, accountProfilePath } from '@routers/routes';

import { Account, ModifyAccount } from './pages';

function MainContainer() {
  return (
    <Switch>
      <Route exact path={accountPath} component={Account} />
      <Route exact path={accountProfilePath} component={ModifyAccount} />
      <Route>
        <Redirect to={rootPath} />
      </Route>
    </Switch>
  );
}

export default MainContainer;
