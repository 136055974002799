import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import { getPlaylist, getPlaylistEpisodes } from '@common/redux/modules/VODPApp';
import {
  fetchPlaylistById,
  cleanUpAllData,
  fetchPlaylistEpisodes
} from '@common/redux/modules/VODPApp/reducers';
import { SmartLink } from '@common/ui/components';
import styled from 'styled-components';

import { buildPlaylistEpisodePath } from '@routers/routes';
import { TopBar } from '../../components/organisms';

import { PlaylistInfo, EpisodeItem } from './molcules';

function PlaylistDetail() {
  const dispatch = useDispatch();
  const { playlistId } = useParams<{ playlistId: string }>();

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const [channelCode, setChannelCode] = useState('');
  const [channelId, setChannelId] = useState(0);
  const [isReadMore, setReadMore] = useState(false);

  const playlist = useSelector(getPlaylist);
  const episodes = useSelector(getPlaylistEpisodes);

  const handleReadMore = () => setReadMore(!isReadMore);

  useEffect(() => {
    if (!currentChannel) return;

    setChannelCode(currentChannel.primitiveInvitationCode);
    setChannelId(currentChannel.id);
  }, [currentChannel]);

  useEffect(() => {
    if (!playlistId || !channelId) return;

    dispatch(fetchPlaylistById({ seriesId: playlistId, channelId }));
    dispatch(fetchPlaylistEpisodes({ seriesId: playlistId, channelId }));
  }, [dispatch, playlistId, channelId]);

  useEffect(
    () => () => {
      dispatch(cleanUpAllData());
    },
    [dispatch]
  );

  return (
    <>
      {playlist && (
        <>
          <TopBar isBack title={playlist.title} channelCode={channelCode} />
          <S_EmptyDiv />
          <PlaylistInfo
            description={playlist.description}
            isReadMoreOpen={isReadMore}
            onReadMore={handleReadMore}
            total={episodes.length}
          />
        </>
      )}

      {episodes.length > 0 &&
        episodes.map(({ id, post }) => (
          <SmartLink
            key={id}
            to={buildPlaylistEpisodePath(channelCode, playlistId, post.id)}
            openNewTab={false}
          >
            <EpisodeItem
              thumbnailSrc={post.vodResource.thumbnailSrc}
              createdAt={post.insertedAt}
              title={post.title}
              runningTime={post.vodResource.duration}
            />
          </SmartLink>
        ))}
    </>
  );
}

const S_EmptyDiv = styled.div`
  height: 48px;
`;

export default PlaylistDetail;
