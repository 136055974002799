import styled from 'styled-components';

import { RatioImage } from '@common/ui/components';

import { Text } from '../atoms';

type Props = {
  src: string;
  title: string;
  width?: string;
};

function EpisodeEntry({ src, title, width = '200px' }: Props) {
  return (
    <>
      <S_ThumbnailWrapper width={width}>
        <RatioImage src={src} ratio={1.8} />
      </S_ThumbnailWrapper>
      <S_TextWrapper width={width}>
        <Text fontSizes="fontSizeBody2" fontWeights="fontWeightNormal">
          {title}
        </Text>
      </S_TextWrapper>
    </>
  );
}

const S_ThumbnailWrapper = styled.div<{ width: string }>`
  position: relative;
  width: ${({ width }) => width};
`;
const S_TextWrapper = styled.div<{ width: string }>`
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  margin-top: 8px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  width: ${({ width }) => width};
`;

export default EpisodeEntry;
