import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { SmartLink } from '@common/ui/components';
import { CounterpartFormat } from '@common/redux/modules/chat/types';
import { StyledNamespace } from '@common/types/styledNamespace';
import { makeGetCurrentChannel } from '@common/redux/modules/channel';

import ProfileImage from '../../../components/atoms/ProfileImage';
import { buildChatDetailPath } from '@routers/routes';

const S: StyledNamespace = {};

S.CounterpartBlock = styled(SmartLink)`
  align-items: center;
  display: flex;
  height: 72px;
  justify-content: flex-start;
  padding: 0 24px;
`;

S.ImageBox = styled.span`
  border-radius: 20px;
  height: 40px;
  margin-right: 12px;
  position: absolute;
  width: 40px;

  .circle {
    background-color: ${(props) => props.theme.green500};
    border: 2px solid ${(props) => props.theme.white};
    border-radius: 6px;
    height: 8px;
    left: 0;
    left: -2px;
    position: absolute;
    top: 0;
    top: -2px;
    width: 8px;
  }

  .image {
    border-radius: inherit;
    height: inherit;
    width: inherit;
  }
`;

S.UserBox = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 12px;
  width: calc(100% - 52px);
`;

S.NameBox = styled.div`
  display: flex;
  gap: 4px;
  justify-content: space-between;
  width: 100%;

  .name {
    font-size: ${(props) => props.theme.fontSizeBody2};
    font-weight: ${({ theme }) => theme.fontWeightBold};
  }

  .time {
    color: ${(props) => props.theme.textDisable};
    font-size: ${(props) => props.theme.fontSizeCaption1};
  }
`;

S.LatestMessage = styled.div`
  color: ${(props) => props.theme.grey500};
  font-size: ${(props) => props.theme.fontSizeCaption1};
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

type CounterpartBlockProps = {
  info: CounterpartFormat;
};

const CounterpartBlock = ({ info }: CounterpartBlockProps) => {
  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  return (
    <S.CounterpartBlock
      to={buildChatDetailPath(currentChannel && currentChannel.primitiveInvitationCode, info.id)}
    >
      <ProfileImage isOnline={info.isOnline} imageSrc={info.imageSrc} />
      <S.UserBox>
        <S.NameBox>
          <span className="name">{info.nickname}</span>
          <span className="time">{info.formatDate}</span>
        </S.NameBox>
        <S.LatestMessage>{info.latestMessageText}</S.LatestMessage>
      </S.UserBox>
    </S.CounterpartBlock>
  );
};

export default CounterpartBlock;
