import DateHelper from '@common/utils/dateHelper';
import styled from 'styled-components';

import { CalendarDateGenerator } from '../atoms';

type CalendarDateGeneratorProps = {
  bookingAvailableDates: { [key: string]: boolean };
  selectDate: ReturnType<typeof DateHelper.getInstance>;
  startWeek: number;
  endWeek: number;
  refDate: ReturnType<typeof DateHelper.getInstance>;
  handleSelectAnotherDay: (currentFormat: ReturnType<typeof DateHelper.getInstance>) => void;
};

function CalendarWeekGenerator({
  bookingAvailableDates,
  selectDate,
  startWeek,
  endWeek,
  refDate,
  handleSelectAnotherDay
}: CalendarDateGeneratorProps) {
  const calendarArr = [];

  for (let week = startWeek; endWeek === 1 ? week < 54 : week <= endWeek; week += 1) {
    calendarArr.push(
      <S_CalendarDayGrid key={week}>
        {Array(7)
          .fill(0)
          .map((date, i) => {
            const current = refDate
              .week(week)
              .startOf('week')
              .add(date + i, 'day');
            const bookingCondition = bookingAvailableDates[`${current.format('MD')}`];

            return (
              <CalendarDateGenerator
                key={i.toString()}
                bookingCondition={bookingCondition}
                current={current}
                selectDate={selectDate}
                handleSelectAnotherDay={handleSelectAnotherDay}
              />
            );
          })}
      </S_CalendarDayGrid>
    );
  }
  return <>{calendarArr}</>;
}

const S_CalendarDayGrid = styled.div`
  display: grid;
  grid-auto-rows: minmax(48px, auto);
  grid-template-columns: repeat(7, minmax(50px, auto));
  margin: 0;
  place-items: center;
`;

export default CalendarWeekGenerator;
