import { ImageCarousel, RatioImage } from '@common/ui/components';

type Props = {
  imageSrcs: string[];
};

function Image({ imageSrcs }: Props) {
  return (
    <>
      {imageSrcs.length === 1 ? (
        <RatioImage ratio={1 / 1} src={imageSrcs[0]} />
      ) : (
        <ImageCarousel cellSpacing={24} slideWidth={1.1} slidesToShow={1.22} wrapAround>
          {imageSrcs.map((src) => (
            <RatioImage ratio={1 / 1} src={src} key={src} />
          ))}
        </ImageCarousel>
      )}
    </>
  );
}

export default Image;
