import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Form, SubmitButton, ModalDialog } from '@common/ui/components';

import { Text } from '../../../components';
import { Select } from '../atoms';

type Props = {
  video: HTMLVideoElement | null;
  isPlayerSettingModalOpen: boolean;
  playerSettingHandler: (value: boolean) => void;
};

interface PlayerSettingInput {
  subtitle: string;
  quality: string;
}
function PlayerSettingModal({ video, isPlayerSettingModalOpen, playerSettingHandler }: Props) {
  const { t } = useTranslation('translation');

  const methods = useForm<PlayerSettingInput>({ mode: 'onChange' });

  const { handleSubmit, register } = methods;

  const videoSubtitleArr = [];

  if (video && video.textTracks) {
    for (const track in video.textTracks) {
      if (Object.prototype.hasOwnProperty.call(video.textTracks, track)) {
        video.textTracks[track].kind === 'subtitles' &&
          videoSubtitleArr.push(video.textTracks[track]);
      }
    }
  }

  const hlsVideoLevelOptions: string[] = [];
  const trackLabel =
    (videoSubtitleArr[0] && videoSubtitleArr[0].label && videoSubtitleArr[0].label) || '';

  const handleSubmitSetting: SubmitHandler<PlayerSettingInput> = () => {
    if (video) {
      for (const track in video.textTracks) {
        if (Object.prototype.hasOwnProperty.call(video.textTracks, track)) {
          if (video.textTracks[track].kind === 'subtitles') {
            video.textTracks[track].mode = 'showing';
          }
        }
      }
    }

    playerSettingHandler(false);
  };

  return (
    <S_PlayerSettingModal>
      <ModalDialog
        onClose={() => playerSettingHandler(false)}
        showCloseButton
        isOpen={isPlayerSettingModalOpen}
        title={t('str_papp_vodp_play_setting', '재생 설정')}
      >
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(handleSubmitSetting)}>
            <S_TextWrapper>
              <Text fontSizes="fontSizeBody2" color="grey500">
                {t('str_papp_vodp_subtitle', '자막')}
              </Text>
            </S_TextWrapper>
            <Select
              name="subtitle"
              isRequired={false}
              register={register}
              placeholder={t('ui_cpnt_dropdown_text_hint', '선택해주세요')}
              options={trackLabel.length > 0 ? [trackLabel] : []}
            />
            <S_TextWrapper>
              <Text fontSizes="fontSizeBody2" color="grey500">
                {t('str_papp_vodp_resolution', '화질')}
              </Text>
            </S_TextWrapper>
            <Select
              name="quality"
              isRequired={false}
              register={register}
              placeholder={t('ui_cpnt_dropdown_text_hint', '선택해주세요')}
              options={hlsVideoLevelOptions}
            />
            <S_ButtonBox>
              <SubmitButton label={t('str_confirm', '확인')} />
            </S_ButtonBox>
          </Form>
        </FormProvider>
      </ModalDialog>
    </S_PlayerSettingModal>
  );
}

const S_PlayerSettingModal = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
`;

const S_ButtonBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

const S_TextWrapper = styled.div`
  margin: 24px 0 16px;
  &:first-child {
    margin-top: 0;
  }
`;

export default PlayerSettingModal;
