import PropTypes from 'prop-types';

import styled, { keyframes } from 'styled-components';

type CoverTitleViewProps = {
  title: string;
};

/**
 * 채널 커버 - 타이틀
 */
function CoverTitleView({ title }: CoverTitleViewProps) {
  return (
    <S_CoverTitleView>{title ? <S_Title>{title}</S_Title> : <S_Placeholder />}</S_CoverTitleView>
  );
}

CoverTitleView.defaultProps = {
  title: ''
};

CoverTitleView.propTypes = {
  title: PropTypes.string
};

const S_CoverTitleView = styled.div`
  display: inline-block;
  margin-top: 24px;
  width: 100%;
`;

const S_Title = styled.div`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeH2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  line-height: ${({ theme }) => theme.fontLineHeight};
  margin-left: 48px;
  margin-right: 48px;
  text-align: center;
`;

const shimmer = keyframes`
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
`;

const S_Placeholder = styled.div`
  height: 36px;
  margin-left: 48px;
  margin-right: 48px;
  overflow: hidden;
  position: relative;
  &::before {
    animation: ${shimmer} 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 170px;
  }
`;

export default CoverTitleView;
