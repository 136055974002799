import IconType from '../IconType';

const FaceSlash = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M4.0009,6.1221 L7.1113,9.2333 C7.17374219,9.87754479 7.6782509,10.3872049 8.32006681,10.4575209 L8.3369,10.4591 L10.8579,12.9791 L6.3299,12.9791 C6.3299,15.9181 8.8679,18.3011 11.9999,18.3011 C13.2009,18.3011 14.3129,17.9481 15.2289,17.3511 L15.2289,17.3511 L17.8779,19.9991 C16.2299,21.2121 14.2029,21.9371 11.9999,21.9371 C6.5119,21.9371 2.0629,17.4881 2.0629,12.0001 C2.0629,9.7981 2.7879,7.7691 4.0009,6.1221 L4.0009,6.1221 Z M2.5809,2.5817 C2.8739,2.2887 3.3489,2.2887 3.6419,2.5817 L3.6419,2.5817 L9.7779,8.7177 L14.0399,12.9787 L16.8249,15.7647 L21.4189,20.3587 C21.7119,20.6517 21.7119,21.1267 21.4189,21.4197 C21.2729,21.5657 21.0809,21.6387 20.8889,21.6387 C20.6969,21.6387 20.5049,21.5657 20.3579,21.4197 L20.3579,21.4197 L18.4709,19.5317 L15.8329,16.8937 L11.9189,12.9787 L9.1979,10.2577 L7.3129,8.3737 L2.5809,3.6417 C2.2879,3.3487 2.2879,2.8747 2.5809,2.5817 Z M12.0002,2.0627 C17.4882,2.0627 21.9372,6.5117 21.9372,12.0007 C21.9372,14.2027 21.2122,16.2307 19.9992,17.8777 L19.9992,17.8777 L17.2072,15.0857 C17.5042,14.4397 17.6702,13.7277 17.6702,12.9787 L17.6702,12.9787 L15.1002,12.9787 L6.1222,4.0017 C7.7692,2.7887 9.7972,2.0627 12.0002,2.0627 Z M15.5312,7.7317 C15.0413765,7.7317 14.6118436,7.98835398 14.36966,8.37460332 L14.3083504,8.48336169 C14.2142277,8.66916263 14.1612,8.87935294 14.1612,9.102 C14.1612,9.859 14.7742,10.472 15.5312,10.472 C16.2882,10.472 16.9012,9.859 16.9012,9.102 C16.9012,8.87935294 16.8481723,8.66916263 16.7540496,8.48336169 L16.69274,8.37460332 C16.4505564,7.98835398 16.0210235,7.7317 15.5312,7.7317 Z"
      />
    </svg>
  );
};

export default FaceSlash;
