import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import { Icon } from '@common/ui/components';
import { fetchLabels } from '@common/redux/modules/VODPlusPApp/reducers';
import { getLabels } from '@common/redux/modules/VODPlusPApp/selectors';
import { buildVodPlusPath } from '@routers/routes';

import { Text, GradientCover } from '../../components';

function VodPlusCategory() {
  const history = useHistory();
  const location = useLocation<{ category: string; labelId: number }>();
  const dispatch = useDispatch();

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const [channelId, setChannelId] = useState<number>(0);
  const [invitationCode, setInvitationCode] = useState<string>('');

  const { category, labelId } = location.state;

  const vodPlusPath = buildVodPlusPath(invitationCode);
  const labels = useSelector(getLabels);

  const goToSeries = ({ category, labelId }: { category: string; labelId?: number }) => {
    history.push(`${vodPlusPath}/serieses`, { category, labelId });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!currentChannel) return;

    setInvitationCode(currentChannel.primitiveInvitationCode);
    setChannelId(currentChannel.id);
  }, [currentChannel]);

  useEffect(() => {
    if (!channelId) return;

    dispatch(fetchLabels({ useCase: category || '', channelId }));
  }, [dispatch, channelId]);

  return (
    <S_Category isOverflow={labels.length > 8}>
      <S_CategoryWrapper>
        {labels.length > 0 &&
          labels.map((label) => (
            <S_TextWrapper
              key={label.id.toString()}
              onClick={() => {
                goToSeries({ category: label.name, labelId: label.id });
              }}
            >
              <Text
                fontSizes="fontSizeSubtitle"
                color={label.name === category ? 'blue500' : 'grey900'}
                fontWeights={label.name === category ? 'fontWeightBold' : 'fontWeightNormal'}
              >
                {label.name}
              </Text>
            </S_TextWrapper>
          ))}
        <S_IconWrapper
          onClick={() => {
            goToSeries({ category, labelId });
          }}
        >
          <Icon iconName="ic_Xmark" size={24} colorCode="white" />
        </S_IconWrapper>
      </S_CategoryWrapper>
      <S_GradientWrapper position="bottom">
        <GradientCover height="156px" direction="to bottom" />
      </S_GradientWrapper>
      <S_GradientWrapper position="top">
        <GradientCover height="156px" direction="to top" />
      </S_GradientWrapper>
    </S_Category>
  );
}

const S_Category = styled.div<{ isOverflow: boolean }>`
  height: 100vh;
  left: 0;
  overflow: ${({ isOverflow }) => (isOverflow ? 'scroll' : 'none')};
  position: absolute;
  top: 0;
  width: 100%;
  ${({ isOverflow }) => isOverflow && overEight}
`;

const overEight = css`
  padding-bottom: 110px;
  padding-top: 110px;
`;

const S_CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0 24px;
`;

const S_TextWrapper = styled.div`
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  margin: 12px 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const S_IconWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.blue500};
  border-radius: 24px;
  bottom: 48px;
  display: flex;
  height: 48px;
  justify-content: center;
  left: 50%;
  margin-left: -24px;
  position: fixed;
  width: 48px;
  z-index: 2;
`;

const S_GradientWrapper = styled.div<{ position: string }>`
  height: 156px;
  position: fixed;
  width: 100%;
  ${({ position }) => position && `${position}:0`}
`;

export default VodPlusCategory;
