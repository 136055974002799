import IconType from '../IconType';

const Login = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <g fill="none" fillRule="evenodd">
        <circle cx="12" cy="12" r="11.25" stroke={color} strokeWidth="1.5" />
        <path
          fill={color}
          d="M11.9999333,12.1898 L12.247124,12.1948046 C15.0345478,12.3078767 17.3311714,14.324581 17.8719333,16.9804667 C17.9979333,17.6004667 17.5206,18.1824667 16.8879333,18.1824667 L16.8879333,18.1824667 L7.1126,18.1824667 C6.47993333,18.1824667 6.00193333,17.6004667 6.12793333,16.9804667 C6.6846,14.2464667 9.10193333,12.1898 11.9999333,12.1898 L11.9999333,12.1898 Z M11.9828,5.79993333 C13.4988,5.79993333 14.7274667,7.0286 14.7274667,8.5446 C14.7274667,10.0599333 13.4988,11.2892667 11.9828,11.2892667 C10.4668,11.2892667 9.23813333,10.0599333 9.23813333,8.5446 C9.23813333,7.0286 10.4668,5.79993333 11.9828,5.79993333 Z"
        />
      </g>
    </svg>
  );
};

export default Login;
