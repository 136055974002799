import { useTranslation } from 'react-i18next';

import styled, { css } from 'styled-components';

type EventTextProps = {
  time: string;
  bookingAbailable: boolean;
  duration: number;
};

function EventText({ time, bookingAbailable, duration }: EventTextProps) {
  const { t } = useTranslation('translation');

  return (
    <S_EventTextContainer>
      <S_EventTime bookingAbailable={bookingAbailable}>
        {t('str_41', { startTime: time, duration, defaultValue: '{{startTime}} ({{duration}}분)' })}
      </S_EventTime>
      <S_EventStatus>{t(bookingAbailable ? 'str_44' : 'str_43')}</S_EventStatus>
    </S_EventTextContainer>
  );
}

const S_EventTextContainer = styled.div`
  clear: both;
`;

const S_EventTime = styled.div<{ bookingAbailable: boolean }>`
  color: ${({ theme }) => theme.textSecondary};
  display: flex;
  font-size: ${({ theme }) => theme.fontSizeCaption1};
  &::before {
    background: ${({ theme }) => theme.grey400};
    border-radius: 8px;
    content: '';
    display: block;
    height: 8px;
    margin: 2px 8px 2px 0;
    position: relative;
    top: 4px;
    width: 8px;
  }
  ${({ bookingAbailable }) => bookingAbailable && AvailableStatus};
`;

const S_EventStatus = styled.div`
  color: ${({ theme }) => theme.textPrimary};
  display: flex;
  display: block;
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  margin-left: 16px;
`;

const AvailableStatus = css`
  &::before {
    background: ${({ theme }) => theme.blue500};
  }
`;

export default EventText;
