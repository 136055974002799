import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getMyBookings } from '@common/redux/modules/booking';
import { fetchMyBookings } from '@common/redux/modules/booking/reducers';
import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import styled from 'styled-components';

import { MyBookingTemplate } from './templates';

type MyBookingProps = {
  channelID: string;
};

function MyBooking({ channelID }: MyBookingProps) {
  const dispatch = useDispatch();

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const myBookings = useSelector(getMyBookings);
  const progressingBooking = myBookings.progressing;
  const incomingBooking = myBookings.bookings;

  useEffect(() => {
    currentChannel && dispatch(fetchMyBookings({ channelId: currentChannel.id }));
  }, [currentChannel, dispatch]);
  return (
    <S_MyBookingDescContainer>
      <MyBookingTemplate
        progressing={progressingBooking}
        bookings={incomingBooking}
        channelID={channelID}
      />
    </S_MyBookingDescContainer>
  );
}

const S_MyBookingDescContainer = styled.div`
  height: 100%;
`;

export default MyBooking;
