import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@common/ui/components';
import { STORY_TYPES } from '@common/constants/index';
import styled from 'styled-components';

import { SelectOption } from '../atoms';

type Props = {
  handleModal: () => void;
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>, value: string) => void;
};

function SelectBar({ handleModal, handleFileChange }: Props) {
  const { t } = useTranslation('translation');

  return (
    <S_SelectBar>
      <SelectOption
        icon={<Icon iconName="ic_Photo" />}
        label={t('str_image', '이미지')}
        fieldName="selectedImages"
        accept="image"
        handleFile={handleFileChange}
        value={STORY_TYPES.IMAGE}
      />
      <SelectOption
        icon={<Icon iconName="ic_Video" />}
        label={t('str_video', '동영상')}
        fieldName="selectedVideos"
        accept="video"
        handleFile={handleFileChange}
        value={STORY_TYPES.VIDEO}
      />
      <SelectOption
        icon={<Icon iconName="ic_Links" />}
        type="button"
        label={t('str_youtube', 'YouTube')}
        fieldName="modalSelector"
        accept="none"
        handleModal={handleModal}
        isModalButton
        value={STORY_TYPES.YOUTUBE}
      />
    </S_SelectBar>
  );
}

const S_SelectBar = styled.div`
  background-color: ${({ theme }) => theme.white};
  display: flex;
  padding: 12px 24px;
  margin-bottom: 24px;
`;

export default SelectBar;
