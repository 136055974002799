import { put, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { FreeBoardPAppRepository } from '../../../../repositories';
import { fetchDetail, fetchList, addPost, removePost, updatePost } from '../reducers';
import { digestResponse } from '../../../middlewares/response';

export function* watchFetchList(action: ReturnType<typeof fetchList>) {
  const { type, payload } = action;
  const { limit, page, isPublished, channelId } = payload;

  const response: AxiosResponse = yield call(() =>
    FreeBoardPAppRepository.findAllPosts({ limit, page, isPublished }, channelId)
  );

  yield put(digestResponse(response, type));
}

export function* watchFetchDetail(action: ReturnType<typeof fetchDetail>) {
  const { type, payload } = action;
  const { postId, channelId } = payload;

  const response: AxiosResponse = yield call(() =>
    FreeBoardPAppRepository.findPost(postId, channelId)
  );

  yield put(digestResponse(response, type));
}

export function* watchAddPost(action: ReturnType<typeof addPost>) {
  const { type, payload } = action;
  const { channelId, ...rest } = payload;

  const response: AxiosResponse = yield call(() =>
    FreeBoardPAppRepository.createPost(rest, channelId)
  );

  yield put(digestResponse(response, type));
}

export function* watchUpdatePost(action: ReturnType<typeof updatePost>) {
  const { type, payload } = action;
  const { postId, channelId, ...body } = payload;

  const response: AxiosResponse = yield call(() =>
    FreeBoardPAppRepository.updatePost(postId, body, channelId)
  );

  yield put(digestResponse(response, type));
}

export function* watchDeletePost(action: ReturnType<typeof removePost>) {
  const { type, payload } = action;
  const { postId, channelId } = payload;

  const response: AxiosResponse = yield call(() =>
    FreeBoardPAppRepository.removePost(postId, channelId)
  );

  yield put(digestResponse(response, type));
}
