import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import { StyledNamespace } from '@common/types/styledNamespace';
import { Button } from '@common/ui/components';
import { ModalDialog } from '@common/ui/components/ModalDialog';
import styled from 'styled-components';

const S: StyledNamespace = {};

type PurchaseModalProps = {
  isLoading: boolean;
  isOpen: boolean;
  onClose?: Function;
  onTempPermission?: Function;
};

/**
 * 채널 소개 페이지 구매 모달
 *
 * @param {Object} props
 * @param {boolean} props.isOpen 창이 열렸는지 여부
 * @param {boolean} props.isLoading 로딩 중 여부
 * @param {function} props.onClose 창 닫힘 이벤트 콜백
 * @param {function} props.onTempPermission 결과 전송 이벤트 콜백
 */
function PurchaseModal({ isLoading, isOpen, onClose, onTempPermission }: PurchaseModalProps) {
  const { t } = useTranslation('translation');
  const history = useHistory();
  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);

  const currentChannel = useSelector(getCurrentChannel);

  function handleCancel() {
    if (!isLoading && onClose) {
      onClose();
    }
  }

  function handleSubmit(e: React.ChangeEvent<HTMLInputElement>) {
    e.stopPropagation();
    e.preventDefault();

    if (onTempPermission) {
      onTempPermission(currentChannel);
    }

    if (onClose) {
      onClose();
    }

    if (currentChannel) {
      history.push(`/channels/${currentChannel.primitiveInvitationCode}`);
    }
  }

  return (
    <ModalDialog
      title={t('str_payment', '결제')}
      disableBackdropClick
      isOpen={isOpen}
      onClose={handleCancel}
      footer={
        <S.PaymentButton
          disabled={isLoading}
          onClick={handleSubmit}
          label={t('str_pay_pay', '결제하기')}
        />
      }
    >
      <S.PurchaseModal onSubmit={handleSubmit}>
        <S.CheckBoxBlock>
          {t(
            'str_1378',
            '필수 개인정보 제공 동의(판매자)\n1. 개인정보를 제공받는 자 : 상품 및 서비스 판매자\n2. 제공하는 개인정보 항목 : 이름, 회원 아이디(이메일), 상품 구매정보, 결제수단'
          )
            .split('\n')
            .map((line, index) =>
              index === 0 ? (
                <S_PaymentTitle key={index}>{line}</S_PaymentTitle>
              ) : (
                <S_Paragraph key={index}>{line}</S_Paragraph>
              )
            )}
          <br />
          <S_PaymentTitle>
            {t('str_1379', '셀러 계정/구독회원 간 이용약관')}
            <S_Detail
              onClick={() => window.open('https://publr.co/terms-seller-subscriber', 'popup')}
            >
              {t('str_77', '자세히보기')}
            </S_Detail>
          </S_PaymentTitle>
        </S.CheckBoxBlock>
      </S.PurchaseModal>
    </ModalDialog>
  );
}

PurchaseModal.defaultProps = {
  isLoading: false,
  isOpen: false
};

PurchaseModal.propTypes = {
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onTempPermission: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

S.PurchaseModal = styled.form`
  display: block;
`;

S.CheckBoxBlock = styled.div`
  font-size: 0.875rem;
  margin: 1rem;
  max-height: 535px;
  overflow-y: scroll;
  text-align: left;
`;

S.CheckBox = styled.label`
  display: inline-block;
`;

S.CheckBoxWrapper = styled.label`
  display: inline-block;
`;

S.Check = styled.input`
  font-size: ${({ theme }) => theme.fontSizeBody1};
`;

S.CheckTitle = styled.a`
  background-color: transparent;
  color: ${({ theme }) => theme.grey900};
  margin-left: 0.5rem;
  text-decoration: underline;
`;

const S_PaymentTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeightBold};
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

S.PaymentButton = styled(Button)`
  border-radius: 24px;
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.fontSizeBody1};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  padding: 12px 32px 12px 32px;
`;

const S_Paragraph = styled.div`
  margin-top: 1rem;
`;

const S_Detail = styled.span`
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.grey400};
  text-decoration: underline;
  cursor: pointer;
`;

export default PurchaseModal;
