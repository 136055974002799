import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import {
  getSeries,
  getSeriesEpisodes,
  getVODPlusPost,
  getVODPlusToken
} from '@common/redux/modules/VODPlusPApp/selectors';

import { DetailContent } from './molecules';
import { BottomSheet, VodPlusPlayer } from './organisms';
import { IsUserLegallyIneligibleScreen, UnPublishedScreen } from './templates';
import {
  cleanUpAllData,
  fetchVODPlusPostById,
  fetchVODPlusViewCounter,
  fetchVODPlusToken,
  fetchSeriesEpisodes,
  fetchSeriesById
} from '@common/redux/modules/VODPlusPApp/reducers';

function VodPlusEpisodeDetail() {
  const dispatch = useDispatch();
  const { seriesId, episodeId }: { seriesId: string; episodeId: string } = useParams();

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const playerBottomRef = useRef<HTMLDivElement | null>(null);

  const VODPlusPost = useSelector(getVODPlusPost);
  const episodes = useSelector(getSeriesEpisodes);
  const series = useSelector(getSeries);
  const VODPlusToken = useSelector(getVODPlusToken);

  const { isUserLegallyIneligible, isPublished, vodResource } = { ...VODPlusPost };
  const video = document.querySelector('video');

  const [channelId, setChannelId] = useState<number>(0);
  const [invitationCode, setInvitationCode] = useState<string>('');

  const [isPlayerSettingModalOpen, setIsPlayerSettingModalOpen] = useState<boolean>(false);

  const [topHeight, setTopHeight] = useState<number>(video?.offsetHeight || 0);

  const playerSettingHandler = (value: boolean) => {
    setIsPlayerSettingModalOpen(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    video && setTopHeight(video.offsetHeight);
    if (!currentChannel) return;
    setInvitationCode(currentChannel.primitiveInvitationCode);
    setChannelId(currentChannel.id);
  }, [currentChannel, video]);

  useEffect(() => {
    if (!playerBottomRef || !playerBottomRef.current) return;

    if (VODPlusPost) {
      dispatch(fetchVODPlusViewCounter({ resourceType: 'post', resourceId: episodeId }));
    }
  }, [VODPlusPost]);

  useEffect(() => {
    if (!episodeId || !channelId) return;

    dispatch(fetchVODPlusPostById({ postId: episodeId, channelId }));
    dispatch(fetchSeriesEpisodes({ seriesId, channelId }));
    dispatch(fetchSeriesById({ seriesId, channelId }));
    dispatch(fetchVODPlusToken({ channelId, vid: +episodeId }));
  }, [dispatch, episodeId, seriesId, channelId]);

  useEffect(() => {
    if (!VODPlusPost || !channelId) return;
    dispatch(fetchVODPlusToken({ channelId, vid: VODPlusPost.vodResource.vid }));
  }, [dispatch, VODPlusPost, channelId]);

  useEffect(() => {
    dispatch(cleanUpAllData());
  }, [dispatch]);

  return (
    <>
      {isUserLegallyIneligible && (
        <>
          <IsUserLegallyIneligibleScreen detailContentProps={VODPlusPost} />
        </>
      )}
      {!isPublished && (
        <>
          <UnPublishedScreen />
        </>
      )}
      {!isUserLegallyIneligible && isPublished && (
        <S_FixedField>
          <S_PlayerField ref={playerBottomRef}>
            <VodPlusPlayer
              isPlayerSettingModalOpen={isPlayerSettingModalOpen}
              playerSettingHandler={playerSettingHandler}
              videoSrc={vodResource?.playlistSrc || ''}
              videoToken={VODPlusToken}
              seriesId={seriesId}
              invitationCode={invitationCode}
            />
          </S_PlayerField>
          <DetailContent
            isUserLegallyIneligible={isUserLegallyIneligible}
            detailContentProps={VODPlusPost}
          />
        </S_FixedField>
      )}
      <BottomSheet
        offsetBottom={topHeight}
        episodes={episodes}
        series={series}
        invitationCode={invitationCode}
        episodeId={episodeId}
      />
    </>
  );
}

const S_FixedField = styled.div`
  height: auto;
  position: fixed;
  top: 0;
  width: 100%;
`;

const S_PlayerField = styled.div`
  height: auto;
  width: 100%;
`;

export default VodPlusEpisodeDetail;
