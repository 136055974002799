import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Icon, SubmitButton } from '@common/ui/components';
import styled from 'styled-components';

export type InputFieldButtonProps = {
  name: string;
  placeholder?: string;
  defaultValue?: string;
  maxLength?: number;
  value?: string;
};

type InputStyleProps = {
  isFocused?: boolean;
};

function InputFieldButton({ name, maxLength = 30, placeholder, ...rest }: InputFieldButtonProps) {
  const [isFocused, setIsFocused] = useState(false);

  const { register } = useFormContext();

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  return (
    <S_Container>
      <S_InputWrapper isFocused={isFocused}>
        <S_Input
          {...register(name as string)}
          name={name}
          placeholder={placeholder}
          maxLength={maxLength}
          onBlur={handleBlur}
          onFocus={handleFocus}
          {...rest}
        />
      </S_InputWrapper>
      <S_ButtonWrapper>
        <SubmitButton fill={false} inputIcon icon={<Icon iconName="ic_Search" size={24} />} />
      </S_ButtonWrapper>
    </S_Container>
  );
}

const S_Container = styled.div`
  position: relative;
  width: 100%;
  & + & {
    margin-top: 16px;
  }
`;

const S_InputWrapper = styled.div<InputStyleProps>`
  align-items: center;
  border: solid 1px ${({ isFocused, theme }) => (isFocused ? theme.blue500 : theme.grey100)};
  border-radius: 8px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.grey500};
  display: inline-flex;
  min-height: 40px;
  outline: 0;
  overflow: hidden;
  width: 100%;
`;

const S_Input = styled.input`
  background-color: transparent;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  display: inline-flex;
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  height: 100%;
  justify-content: center;
  outline: 0;
  overflow: hidden;
  padding: 0 12px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 90%;

  &::placeholder {
    color: ${({ theme }) => theme.grey500};
  }
`;

const S_ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  margin: 0 12px;
  position: absolute;
  right: 12px;
  top: -4px;
  width: 24px;
`;

export default InputFieldButton;
