import { useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';

import {
  addComment,
  deleteComment,
  fetchComments,
  fetchDetail,
  cleanUpPost,
  removePost
} from '@common/redux/modules/freeBoardPApp/reducers';
import {
  getComments,
  getIsCommentCreated,
  getPost,
  getTotalCommentsCount
} from '@common/redux/modules/freeBoardPApp';
import JWT from '@common/utils/jwt';
import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import { COMMENT_DEFAULT_LIMIT, POP_UP_MENU } from '@common/constants/index';

import { NavBar } from '../../components/molecules';
import { CommentInputControl, PostInfo, CommentCard } from './molecules';
import { buildFreeBoardPath, buildEditFreeBoardPath } from '@routers/routes';
import { CancelButtonModal, ConfirmModal } from '../../components/templates';

function FreeBoardPostDetail() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const { postId } = useParams<{ postId: string }>();

  const [userId, setUserId] = useState();
  const [channelCode, setChannelCode] = useState('');
  const [channelId, setChannelId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [isDeletePostModalOpen, setDeletePostModalOpen] = useState(false);
  const [isDeleteCommentModalOpen, setDeleteCommentModalOpen] = useState(false);
  const [isPostDeleted, setIsPostDeleted] = useState(false);

  const targetId = useRef<number>(0);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const noticePost = useSelector(getPost);
  const comments = useSelector(getComments);
  const totalCommentsCount = useSelector(getTotalCommentsCount);
  const isCommentCreated = useSelector(getIsCommentCreated);

  useEffect(() => {
    return () => {
      dispatch(cleanUpPost());
    };
  }, [dispatch]);

  useEffect(() => {
    if (currentChannel) {
      const userMeta = JWT.getUserMetaInChannel(currentChannel.id);

      setChannelCode(currentChannel.primitiveInvitationCode);
      setChannelId(currentChannel.id);
      setUserId(userMeta?.profileId);
    }
  }, [currentChannel]);

  useEffect(() => {
    if (!channelId || !postId) return;

    dispatch(fetchDetail({ postId, channelId }));
    dispatch(fetchComments({ postId, limit: COMMENT_DEFAULT_LIMIT, page: currentPage, channelId }));
  }, [dispatch, postId, currentPage, channelId]);

  useEffect(() => {
    if (!channelId || !postId) return;

    if (isCommentCreated) {
      dispatch(
        fetchComments({ postId, limit: COMMENT_DEFAULT_LIMIT, page: currentPage, channelId })
      );
    }
  }, [dispatch, postId, currentPage, channelId, isCommentCreated]);

  useEffect(() => {
    if (noticePost && contentRef && contentRef.current) {
      const dom = new DOMParser();
      const doc = dom.parseFromString(noticePost.content, 'text/html');

      contentRef.current.innerHTML = doc.documentElement.innerHTML;
    }
  }, [noticePost]);

  useEffect(() => {
    if (channelCode && isPostDeleted) {
      history.push(buildFreeBoardPath(channelCode));
    }
  }, [isPostDeleted]);

  const handleAddComment = (data: any) => {
    dispatch(addComment({ postId, channelId, ...data }));
  };

  const handleDeletePost = () => {
    if (!currentChannel) return;

    dispatch(removePost({ postId, channelId }));
    setDeletePostModalOpen(false);
    setIsPostDeleted(true);
  };

  const handleDeleteCommentModalOpen = (id: number) => {
    targetId.current = id;
    setDeleteCommentModalOpen(true);
  };

  const handleDeleteComment = () => {
    dispatch(deleteComment({ postId, commentId: targetId.current, channelId }));

    targetId.current = 0;
    setDeleteCommentModalOpen(false);
  };

  const handleScrollDown = () => {
    if (comments.length === totalCommentsCount) return;
    setCurrentPage(currentPage + 1);
  };

  const handleCloseDeleteCommentModal = () => {
    targetId.current = 0;
    setDeleteCommentModalOpen(false);
  };

  const handleClickMenuButton = (value: string) => {
    if (!currentChannel) return;

    if (value === POP_UP_MENU.EDIT) {
      history.push(buildEditFreeBoardPath(channelCode, postId));
      return;
    }

    if (value === POP_UP_MENU.DELETE) {
      setDeletePostModalOpen(true);
    }
  };

  return (
    <>
      <NavBar isBack />

      {noticePost && (
        <PostInfo
          title={noticePost.title}
          author={noticePost.author}
          createdAt={noticePost.insertedAt}
          viewCount={0}
          isAuthor={noticePost.profileId === userId}
          onClickPopUpMenu={handleClickMenuButton}
        />
      )}

      <S_ContentWrapper>
        <S_Content ref={contentRef} />
      </S_ContentWrapper>

      <S_Line />
      <S_CommentsBox>
        <S_CommentCount>
          {t('str_94', {
            cmtCnt: noticePost?.totalComments,
            defaultValue: '댓글 {{cmtCnt}} 개'
          })}
        </S_CommentCount>

        <CommentInputControl handleAddComment={handleAddComment} />

        {comments.length === 0 ? (
          <S_NoItem>{t('str_492', '아직 업로드한 자유게시판 게시글 댓글이 없습니다.')}</S_NoItem>
        ) : (
          <S_ScrollField
            dataLength={comments.length}
            next={handleScrollDown}
            hasMore={comments.length !== totalCommentsCount}
          >
            {comments.map(({ id, authorThumbnailSrc, author, insertedAt, content, profileId }) => (
              <CommentCard
                key={id}
                id={id}
                profileImage={authorThumbnailSrc}
                nickname={author}
                createdAt={insertedAt}
                content={content}
                isAuthor={profileId === userId}
                onDeleteComment={handleDeleteCommentModalOpen}
              />
            ))}
          </S_ScrollField>
        )}
      </S_CommentsBox>

      <ConfirmModal
        isOpen={isDeletePostModalOpen}
        onClick={handleDeletePost}
        onClose={() => setDeletePostModalOpen(false)}
        message={t(
          'str_delete_alert_dialog',
          '정말 삭제하시겠습니까?\n(삭제한 이후 되돌릴 수 없습니다.)'
        )}
      />

      <CancelButtonModal
        isOpen={isDeleteCommentModalOpen}
        onClick={handleDeleteComment}
        onClose={handleCloseDeleteCommentModal}
        message={t('str_62', '[[str_delete]]하시겠습니까?')}
      />
    </>
  );
}

const S_Line = styled.div`
  background-color: ${({ theme }) => theme.grey100};
  height: 1px;
  margin: 24px 0;
  width: 100%;
`;

const S_CommentsBox = styled.div`
  text-align: left;
`;

const S_CommentCount = styled.span`
  color: ${({ theme }) => theme.grey500};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  margin-left: 24px;
`;

const S_ScrollField = styled(InfiniteScroll as any)`
  margin-bottom: 24px;
  padding: 24px;
`;

const S_ContentWrapper = styled.div`
  padding: 0 24px;
`;

const S_Content = styled.div`
  color: ${({ theme }) => theme.grey900};
  text-align: left;
  word-break: keep-all;

  figure {
    margin: 0;
    max-width: 100%;

    img {
      width: 100%;
    }
  }
`;

const S_NoItem = styled.div`
  color: ${({ theme }) => theme.grey400};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  margin-bottom: 80px;
  margin-top: 48px;
  text-align: center;
`;

export default FreeBoardPostDetail;
