import { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';

import { Form, Icon, InputFieldButton } from '@common/ui/components';
import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import {
  updatePost,
  fetchDetail,
  resetResponseStatus
} from '@common/redux/modules/freeBoardPApp/reducers';
import { getPost, getResponseStatus } from '@common/redux/modules/freeBoardPApp';
import {
  FREE_BOARD_POST_DEFAULT_ROW,
  FREE_BOARD_POST_MAX_LENGTH,
  FREE_BOARD_TITLE_MAX_LENGTH,
  FREE_BOARD_TITLE_MIN_LENGTH
} from '@common/constants/index';

import { SubmitNavBar } from '../../components/molecules';
import { buildFreeBoardPath } from '@routers/routes';
import { CancelButtonModal, ConfirmModal } from '../../components/templates';

type FormField = {
  title: string;
  content: string;
};

function EditFreeBoardPost() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('translation');
  const { postId }: { postId: string } = useParams();

  const [isAlertModalOpen, setAlertModalOpen] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [contents, setContents] = useState({ title: '', content: '' });

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const post = useSelector(getPost);
  const responseStatus = useSelector(getResponseStatus);

  const methods = useForm<FormField>({
    mode: 'onChange',
    defaultValues: contents
  });

  const {
    handleSubmit,
    getValues,
    reset,
    formState: { isValid, isDirty }
  } = methods;
  const isButtonDisabled = !isValid || !isDirty;

  useEffect(() => {
    return () => {
      dispatch(resetResponseStatus());
    };
  }, [dispatch]);

  useEffect(() => {
    if (responseStatus >= 200 && responseStatus < 300 && currentChannel) {
      history.push(buildFreeBoardPath(currentChannel.primitiveInvitationCode));
    }
  }, [responseStatus, currentChannel, history]);

  useEffect(() => {
    if (!currentChannel || !postId) return;

    dispatch(fetchDetail({ postId, channelId: currentChannel.id }));
  }, [dispatch, currentChannel, postId]);

  useEffect(() => {
    if (!post) return;

    const { title, content } = post;
    setContents({ title, content });
    reset(post);
  }, [post, reset]);

  const handleCreatePost = () => {
    if (!currentChannel) return;

    dispatch(updatePost({ postId, channelId: currentChannel.id, ...getValues() }));
    setConfirmModalOpen(false);
  };

  const handleBackToPostsPage = () => {
    if (!isDirty || (!getValues('title') && !getValues('content'))) {
      history.goBack();
      return;
    }

    setAlertModalOpen(true);
  };

  const titleValidation = {
    required: true,
    minLength: {
      value: FREE_BOARD_TITLE_MIN_LENGTH,
      message: t('str_481', '제목은 최소 2자, 최대 30자까지 입력할 수 있습니다.')
    }
  };

  const contentsValidation = {
    required: true,
    maxLength: {
      value: FREE_BOARD_POST_MAX_LENGTH,
      message: t('str_515', '글자 수는 최대 2000자를 초과할 수 없습니다.')
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(() => setConfirmModalOpen(true))}>
          <SubmitNavBar
            icon={
              <Icon
                iconName="ic_PaperPlane"
                colorCode={isButtonDisabled ? 'grey400' : 'blue500'}
                fill
              />
            }
            disabled={isButtonDisabled}
            onClick={handleBackToPostsPage}
          />

          <S_EmptyDiv />

          <S_ContentsBox>
            <InputFieldButton
              name="title"
              maxLength={FREE_BOARD_TITLE_MAX_LENGTH}
              placeholder={t('str_93', '제목을 입력해 주세요...')}
              validation={titleValidation}
            />
            <InputFieldButton
              name="content"
              maxLength={FREE_BOARD_POST_MAX_LENGTH}
              textArea
              rows={FREE_BOARD_POST_DEFAULT_ROW}
              placeholder={t('str_92', '게시글을 작성해 주세요...')}
              validation={contentsValidation}
            />
          </S_ContentsBox>
        </Form>
      </FormProvider>

      <CancelButtonModal
        isOpen={isAlertModalOpen}
        onClick={() => history.push(buildFreeBoardPath(currentChannel?.primitiveInvitationCode))}
        onClose={() => setAlertModalOpen(false)}
        message={t(
          'str_85',
          '이전 화면으로 돌아가시겠습니까?\n(입력 중인 내용은 보존되지 않습니다.)'
        )}
      />

      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onClick={handleCreatePost}
        onClose={() => setConfirmModalOpen(false)}
        message={t('str_79', '게시물 게시가 완료되었습니다.')}
      />
    </>
  );
}

const S_EmptyDiv = styled.div`
  height: 24px;
`;

const S_ContentsBox = styled.div`
  padding: 26px 24px;
`;

export default EditFreeBoardPost;
