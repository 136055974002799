import { HLSPlayer } from '../atoms';

type Props = {
  isPlayerSettingModalOpen: boolean;
  playerSettingHandler: (value: boolean) => void;
  videoSrc: string;
  videoToken: string;
  seriesId: string;
  invitationCode: string;
};

function VodPlusPlayer({
  isPlayerSettingModalOpen,
  playerSettingHandler,
  videoSrc,
  videoToken,
  seriesId,
  invitationCode
}: Props) {
  return (
    <HLSPlayer
      src={videoSrc}
      token={videoToken}
      seriesId={seriesId}
      invitationCode={invitationCode}
      isPlayerSettingModalOpen={isPlayerSettingModalOpen}
      playerSettingHandler={playerSettingHandler}
    />
  );
}

export default VodPlusPlayer;
