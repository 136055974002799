import { Switch, Route, Redirect } from 'react-router-dom';
import { rootPath, subscriptionsPath, subsDetailPath } from '@routers/routes';
import { SubsDetail, Subscriptions } from './pages';

function MainContainer() {
  return (
    <Switch>
      <Route exact path={subscriptionsPath} component={Subscriptions} />
      <Route exact path={subsDetailPath} component={SubsDetail} />
      <Route>
        <Redirect to={rootPath} />
      </Route>
    </Switch>
  );
}

export default MainContainer;
