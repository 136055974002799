export function dataURItoBlob(dataURI: string) {
  const binary = atob(dataURI.split(',')[1]);
  const array = [];

  for (let i = 0; i < binary.length; i += 1) {
    array.push(binary.charCodeAt(i));
  }

  return new Blob([new Uint8Array(array)], { type: 'image/png' });
}

export function formatBytesToMB(bytes: number) {
  const fixedSize = (bytes / (1024 * 1024)).toFixed(2);

  return Math.floor(Number(fixedSize));
}

export const extractVideoMetaInfo = (file: File) => {
  const video = document.createElement('video');

  video.setAttribute('preload', 'auto');
  video.src = URL.createObjectURL(file);
  video.currentTime = 3;
  video.load();

  const promise: Promise<{ duration: number; dataUrl: string }> = new Promise((resolve, reject) => {
    video.onloadeddata = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      const dataUrl = canvas.toDataURL('image/png');

      resolve({ duration: video.duration, dataUrl });
    };
    video.onerror = reject;
  });

  return promise;
};
