import { colors } from '@common/ui/styles/theme';
import styled, { keyframes } from 'styled-components';

function Splash() {
  return (
    <S_Splash>
      <S_Circle color={colors.blue500} />
      <S_Circle color={colors.grey900} />
      <S_Circle color={colors.green500} />
    </S_Splash>
  );
}

const S_Splash = styled.div`
  align-items: center;
  box-shadow: -30px 0 100px -100px #000, 30px 0 100px -100px #000;
  display: flex;
  flex-direction: row;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
  width: 100%;
`;

const splashAnim = keyframes`
from {
  transform: scale(1);
}
50%{
  transform: scale(.5);
}
to{
  transform: scale(1);
}
`;

const S_Circle = styled.div<{ color: string }>`
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: ${splashAnim};
  animation-timing-function: linear;
  background-color: ${({ color }) => color};
  border-radius: 32px;
  height: 19px;
  margin-right: 16px;
  width: 19px;
  &:last-of-type {
    margin-right: 0;
  }

  &:nth-child(2) {
    animation-delay: 0.25s;
  }

  &:nth-child(3) {
    animation-delay: 0.5s;
  }
`;

export default Splash;
