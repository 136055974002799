import styled from 'styled-components';

import DateHelper from '@common/utils/dateHelper';

type Props = {
  author: string;
  createdAt: string;
  viewCount: number;
};

function PostInfo({ author, createdAt, viewCount }: Props) {
  return (
    <S_PostInfo>
      <S_Author>{author}</S_Author>
      <S_Text>
        {DateHelper.isToday(createdAt)
          ? DateHelper.formatUTCtoKST(createdAt, 'hh:mm')
          : DateHelper.formatUTCtoKST(createdAt, 'YYYY-MM-DD')}
      </S_Text>
      <S_Text>{new Intl.NumberFormat().format(viewCount)}</S_Text>
    </S_PostInfo>
  );
}

const S_PostInfo = styled.div`
  color: ${({ theme }) => theme.grey400};
  font-size: ${({ theme }) => theme.fontSizeCaption1};
`;

const S_Text = styled.span`
  color: ${({ theme }) => theme.grey400};
  font-size: ${({ theme }) => theme.fontSizeCaption1};
  margin-right: 8px;
`;

const S_Author = styled(S_Text)`
  color: ${({ theme }) => theme.grey500};
`;

export default PostInfo;
