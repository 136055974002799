import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { InputFieldButton, InputFieldContainer, Form, Button, Icon } from '@common/ui/components';
import { COMMENT_MAX_LENGTH } from '@common/constants/index';

interface FormField {
  comment: string;
}

export type Props = {
  commentCount: number;
  handleAddComment: (comment: string) => void;
};

function CommentInput({ commentCount, handleAddComment }: Props) {
  const { t } = useTranslation('translation');
  const [showIcon, setIcon] = useState(false);
  const [hasTarget, setTarget] = useState(false);

  const methods = useForm<FormField>({ mode: 'onBlur' });
  const { handleSubmit, reset, watch } = methods;

  const inputValue = watch('comment');

  const onSubmit = (formData: FormField) => {
    handleAddComment(formData.comment);

    if (hasTarget) {
      setIcon(false);
      setTarget(false);
      reset();
      return;
    }

    setIcon(true);
    reset();
  };

  const handleIcon = (isFocused: boolean) => {
    isFocused ? setIcon(true) : setIcon(false);
  };

  const handleTarget = () => {
    setTarget(true);
  };

  return (
    <>
      <S_Bar />

      <CommenCount>
        {t('str_94', { cmtCnt: commentCount, defaultValue: '[[str_comment]] {{cmtCnt}} 개' })}
      </CommenCount>

      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)} displayButton={showIcon}>
          <InputFieldContainer comment rightIcon={showIcon}>
            <InputFieldButton
              name="comment"
              placeholder={t('str_73', '[[str_comment]] 입력..')}
              onIcon={handleIcon}
              onTarget={handleTarget}
              maxLength={COMMENT_MAX_LENGTH}
            />
            {showIcon && (
              <S_ButtonWrapper>
                {inputValue ? (
                  <Button
                    type="submit"
                    fill={false}
                    inputIcon
                    icon={<Icon iconName="ic_PaperPlane" colorCode="blue500" fill />}
                  />
                ) : (
                  <Button
                    fill={false}
                    disabled
                    inputIcon
                    icon={<Icon iconName="ic_PaperPlane" colorCode="grey400" fill />}
                  />
                )}
              </S_ButtonWrapper>
            )}
          </InputFieldContainer>
        </Form>
      </FormProvider>
    </>
  );
}

const CommenCount = styled.div`
  color: ${({ theme }) => theme.grey500};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  margin-bottom: 16px;
  margin-left: 24px;
  margin-top: 24px;
  text-align: left;
`;

const S_Bar = styled.div`
  background-color: ${({ theme }) => theme.grey100};
  height: 1px;
  width: 100%;
`;

const S_ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 49px;
  margin: 0 8px;
  width: 48px;
`;

export default CommentInput;
