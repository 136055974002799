import IconType from '../IconType';

const Call = ({ color, size, ...rest }: IconType) => (
  <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M8.0272,8.4489 L9.0872,7.3889 C9.8052,6.6709 9.8052,5.5069 9.0872,4.7889 L6.7532,2.4549 C6.0352,1.7369 4.8712,1.7369 4.1532,2.4549 L3.3972,3.2109 L3.0992,3.5089 L2.8652,3.7429 C1.9512,4.6569 1.7952,5.9319 2.0042,7.1399 C2.3622,9.2169 3.7242,12.6209 7.5492,16.4449 C11.3732,20.2699 14.6132,21.5529 16.8542,21.9909 C18.0572,22.2249 19.3382,22.0429 20.2512,21.1289 L20.4852,20.8949 L20.7832,20.5969 L21.5392,19.8409 C22.2572,19.1229 22.2572,17.9589 21.5392,17.2409 L19.2052,14.9069 C18.4872,14.1889 17.3232,14.1889 16.6052,14.9069 L15.5452,15.9669 C15.3182,16.1939 14.9562,16.2149 14.7092,16.0109 C13.4782,14.9969 12.2352,13.9049 11.1622,12.8319 C10.0892,11.7599 8.9972,10.5159 7.9832,9.2849 C7.7792,9.0379 7.8002,8.6759 8.0272,8.4489"
    />
  </svg>
);

export default Call;
