import { createSelector } from 'reselect';
import JWT from '@common/utils/jwt';
import { RootState } from '../../index';

export const getIsMenuOpened = (state: RootState) => {
  return state.channel.isMenuOpened;
};

export const getIsChannelLoading = (state: RootState) => {
  return state.channel.isLoading;
};

export const getChannelDescParams = (state: RootState) => {
  return state.channel.channelDescParams;
};

export const getAvailableChannels = () => {
  const localStorageToken = localStorage.getItem(process.env.REACT_APP_KEY_ACCESS_TOKEN || '');
  const [tokenStatus, , jwtData] = JWT.validateJwtToken(localStorageToken);

  if (tokenStatus === 'ERROR') {
    return [];
  }

  if (jwtData.user) {
    return Object.keys(jwtData.user.availableChannels);
  }
  return false;
};

export const getChannelError = (state: RootState) => {
  return state.channel.error;
};

export const getCurrentChannel = (state: RootState) => {
  return state.channel.currentChannel;
};

export const getMyChannels = (state: RootState) => {
  return state.channel.myChannelList;
};

export const getCurrentChannelSellerInfo = (state: RootState) => {
  return state.channel.currentChannelSellerInfo;
};

export const makeGetChannelError = () => {
  return createSelector([getChannelError], (channelError) => channelError);
};

export const makeGetIsMenuOpened = () => {
  return createSelector([getIsMenuOpened], (isMenuOpened) => isMenuOpened);
};

export const makeGetIsChanelLoading = () => {
  return createSelector([getIsChannelLoading], (isLoading) => isLoading);
};

export const makeGetAvailableChannels = () => {
  return createSelector([getAvailableChannels], (availableChannels) => availableChannels);
};

export const makeGetCurrentChannel = () => {
  return createSelector([getCurrentChannel], (currentChannel) => currentChannel);
};

export const makeGetMyChannels = () => {
  return createSelector([getMyChannels], (myChannelList) => myChannelList);
};

export const makeGetCurrentChannelSellerInfo = () => {
  return createSelector(
    [getCurrentChannelSellerInfo],
    (currentChannelSellerInfo) => currentChannelSellerInfo
  );
};

export const makeGetChannelDescParams = () => {
  return createSelector([getChannelDescParams], (channelDescParams) => channelDescParams);
};
