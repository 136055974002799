import IconType from '../IconType';

const VideoPause = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M9.6591,4.78095 C10.2111,4.78095 10.6596,5.22945 10.6596,5.78145 L10.6596,18.21795 C10.6596,18.77145 10.2111,19.21845 9.6591,19.21845 L8.2836,19.21845 C7.7316,19.21845 7.2846,18.77145 7.2846,18.21795 L7.2846,5.78145 C7.2846,5.22945 7.7316,4.78095 8.2836,4.78095 L9.6591,4.78095 Z M15.7155,4.78095 C16.269,4.78095 16.716,5.22945 16.716,5.78145 L16.716,18.21795 C16.716,18.77145 16.269,19.21845 15.7155,19.21845 L14.3415,19.21845 C13.7895,19.21845 13.341,18.77145 13.341,18.21795 L13.341,5.78145 C13.341,5.22945 13.7895,4.78095 14.3415,4.78095 L15.7155,4.78095 Z"
      />
    </svg>
  );
};

export default VideoPause;
