import { myBookingType } from '@common/redux/modules/booking/types';
import styled from 'styled-components';

import { BookingCard } from '../molcules';

function MyBookingList({ bookings, channelID }: myBookingType) {
  return (
    <S_MyBookingListContainer>
      {bookings &&
        bookings
          .filter((booking) => booking.cancel)
          .map((booking, i) => {
            if (booking.cancel && booking.cancel.result === null) {
              return (
                <BookingCard
                  cancelWaiting
                  title={null}
                  body={booking.cancel.status}
                  time={booking.startDate}
                  duration={booking.duration}
                  key={i.toString()}
                  id={booking.id}
                  channelID={channelID}
                />
              );
            }
            if (booking.cancel && booking.cancel.result === 0) {
              return (
                <BookingCard
                  title={0}
                  body={booking.cancel.status}
                  time={booking.startDate}
                  duration={booking.duration}
                  key={i.toString()}
                  id={booking.id}
                  channelID={channelID}
                />
              );
            }
            return <></>;
          })}
      {bookings &&
        bookings
          .filter((booking) => !booking.cancel)
          .map((booking, i) => (
            <BookingCard
              title={0}
              body={7}
              time={booking.startDate}
              duration={booking.duration}
              key={i.toString()}
              id={booking.id}
              channelID={channelID}
            />
          ))}
      {!bookings && <>예약이 없습니다.</>}
    </S_MyBookingListContainer>
  );
}

const S_MyBookingListContainer = styled.div`
  background-color: 'white';
`;

export default MyBookingList;
