import { createReducer, createAction } from '@reduxjs/toolkit';

import { builderFactory } from '@common/utils/reduxHelper';

import { InitialStateType, FetchVODPostsPayload, FetchPlaylistPayload } from './types';

const prefix = '@VODPApp';

export const fetchVODPosts = createAction<FetchVODPostsPayload>(`${prefix}/FETCH_VOD_LIST`);
export const fetchVODPostById = createAction<{ postId: string; channelId: number }>(
  `${prefix}/FETCH_VOD_BY_ID`
);

export const fetchPlaylists = createAction<FetchPlaylistPayload>(`${prefix}/FETCH_PLAYLISTS`);
export const fetchPlaylistById = createAction<{ seriesId: string; channelId: number }>(
  `${prefix}/FETCH_PLAYLIST_BY_ID`
);
export const fetchPlaylistEpisodes = createAction<{ seriesId: string; channelId: number }>(
  `${prefix}/FETCH_PLAYLIST_EPISODES`
);

export const fetchVODToken = createAction<{ channelId: number; vid: number }>(
  `${prefix}/FETCH_VOD_TOKEN`
);
export const cleanUpAllData = createAction(`${prefix}/CLEAN_UP_LISTS`);

const initialState: InitialStateType = {
  isLoading: true,
  error: null,
  VODPosts: [],
  VODPost: null,
  playlists: [],
  playlist: null,
  episodes: [],
  VODToken: ''
};

const reducer = createReducer(initialState, (builder) => {
  builderFactory(builder, [fetchVODPosts, fetchVODPostById, fetchPlaylistById, fetchVODToken])
    .addCase(`${fetchVODPosts}_COMPLETED`, (state, action: any) => {
      const { posts } = action.payload.data.data;

      state.VODPosts = [...state.VODPosts, ...posts];
    })
    .addCase(`${fetchVODPostById}_COMPLETED`, (state, action: any) => {
      const { post } = action.payload.data.data;

      state.VODPost = post;
    })
    .addCase(`${fetchPlaylists}_COMPLETED`, (state, action: any) => {
      const { serieses } = action.payload.data.data;

      state.playlists = [...state.playlists, ...serieses];
    })
    .addCase(`${fetchPlaylistById}_COMPLETED`, (state, action: any) => {
      const { series } = action.payload.data.data;

      state.playlist = series;
    })
    .addCase(`${fetchPlaylistEpisodes}_COMPLETED`, (state, action: any) => {
      const { episodes } = action.payload.data.data;

      state.episodes = episodes;
    })
    .addCase(`${fetchVODToken}_COMPLETED`, (state, action: any) => {
      const token = action.payload.data;

      state.VODToken = token;
    })
    .addCase(`${cleanUpAllData}`, (state) => {
      state.VODPosts = [];
      state.VODPost = null;
      state.playlists = [];
      state.playlist = null;
      state.episodes = [];
      state.VODToken = '';
    })
    .addMatcher(
      (action) => action.type.startsWith(prefix) && action.type.endsWith('_COMPLETED'),
      (state) => {
        state.isLoading = false;
      }
    )
    .addMatcher(
      (action) => action.type.startsWith(prefix) && action.type.endsWith('_FAILED'),
      (state, action) => {
        state.isLoading = false;
        state.error = action.payload.data.message;
      }
    );
});

export default reducer;
