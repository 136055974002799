const REG_DEFAULT_INTERPOLATION_PLACEHOLER = /\${([^${}]*)}/g;

class StringHelper {
  static interpolate<T extends { [key: string]: any }>(
    templateString: string,
    variables: T,
    regExp = REG_DEFAULT_INTERPOLATION_PLACEHOLER
  ) {
    return templateString.replace(regExp, (match, substr) => {
      if (substr in variables) {
        return variables[substr];
      }

      return match;
    });
  }

  static insertAt(originalStr: string, targetStr: string, idx: number) {
    if (idx < 1) {
      return originalStr + targetStr;
    }

    return originalStr.slice(0, idx) + targetStr + originalStr.slice(idx);
  }
}

export default StringHelper;
