import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { InputFieldButton, SubmitButton, Icon } from '@common/ui/components';
import { MouseEventHandler } from 'react';
import { NavView } from '../../../../Chatting/components/templates';
import CheckBoxSeperateLabel from './CheckBoxSeperateLabel';

export type SignUpFormProps = {
  handleClickPrev: MouseEventHandler;
  methods: {
    watch: Function;
    formState: {
      isValid: boolean;
      isDirty: boolean;
    };
  };
};

function SignUpForm({ methods, handleClickPrev }: SignUpFormProps) {
  const { t } = useTranslation('translation');

  const {
    watch,
    formState: { isValid, isDirty }
  } = methods;

  const passwordValue = watch('password');
  const isButtonDisabled = !isValid || !isDirty;

  const required = {
    required: t('str_err_1116', '필수 정보를 입력해 주세요.')
  };

  const nicknameValidation = {
    required: t('str_err_1015', '닉네임을 입력해 주세요.'),
    minLength: {
      value: 2,
      message: t('str_err_1018', '닉네임은 최소 2자부터, 최대 12자까지 입력할 수 있습니다.')
    },
    maxLength: {
      value: 12,
      message: t('str_err_1018', '닉네임은 최소 2자부터, 최대 12자까지 입력할 수 있습니다.')
    },
    pattern: {
      value: /^[ㄱ-ㅎㅏ-ㅣ가-힣A-Za-z0-9]+$/,
      message: t('str_err_1016', '올바르지 않은 닉네임입니다.')
    }
  };

  const emailValidation = {
    required: t('str_err_1011', '이메일을 입력해 주세요.'),
    pattern: {
      // eslint-disable-next-line no-useless-escape
      value: /^([a-zA-Z0-9\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
      message: t('str_err_1009', '올바르지 않은 이메일입니다.')
    }
  };

  /**
   * 비밀번호 형식 유효성 검사
   */
  function checkPassword() {
    // 특수문자 여부
    const checkSpecial = passwordValue && passwordValue.search(/[-!#$%&'*+/=?^_{|}~.,@]/g);
    if (checkSpecial < 0) {
      return t(
        'str_err_1123',
        '올바르지 않은 비밀번호입니다. (최소 8자, 최대 30자, 영대소문자 및 숫자와 특수문자 1개 필수)'
      );
    }

    // 특수문자만 있는 경우 여부
    const checkWord = passwordValue && passwordValue.search(/[a-z0-9A-Z]/gi);
    if (checkWord < 0) {
      return t(
        'str_err_1123',
        '올바르지 않은 비밀번호입니다. (최소 8자, 최대 30자, 영대소문자 및 숫자와 특수문자 1개 필수)'
      );
    }

    // 그 외 에러
    return t(
      'str_err_1123',
      '올바르지 않은 비밀번호입니다. (최소 8자, 최대 30자, 영대소문자 및 숫자와 특수문자 1개 필수)'
    );
  }

  /**
   * 비밀번호 형식 유효성 검사
   */
  const passwordValidation = {
    required: t('str_err_1013', '비밀번호를 입력해 주세요.'),
    minLength: {
      value: 8,
      message: t(
        'str_err_1123',
        '올바르지 않은 비밀번호입니다. (최소 8자, 최대 30자, 영대소문자 및 숫자와 특수문자 1개 필수)'
      )
    },
    maxLength: {
      value: 30,
      message: t(
        'str_err_1123',
        '올바르지 않은 비밀번호입니다. (최소 8자, 최대 30자, 영대소문자 및 숫자와 특수문자 1개 필수)'
      )
    },
    pattern: {
      value: /(?=.*[a-zA-Z0-9])(?=.*[-!#$%&'*+/=?^_{|}~.,@])(?=.{8,30}$).*/,
      message: checkPassword()
    }
  };

  /**
   * 비밀번호 일치 유효성 검사
   */
  const passwordConfirmValidation = {
    required: t('str_err_1013', '비밀번호를 입력해주세요.'),
    validate: (value: string) =>
      value === passwordValue || t('str_err_1020', '비밀번호를 다시 한번 확인해 주세요.')
  };

  return (
    <NavView nav={<PrevButton onClick={handleClickPrev} />}>
      <S_Form>
        <S_Title>{t('str_input_account_info', '계정 정보 입력')}</S_Title>
        <InputFieldButton
          name="nickname"
          placeholder={t('str_nickname_hint', '닉네임')}
          validation={nicknameValidation}
          maxLength={12}
          cancel
        />
        <S_InputFieldBlock>
          <InputFieldButton
            name="email"
            placeholder={t('str_email_hint', '아이디(이메일)')}
            validation={emailValidation}
            cancel
          />
        </S_InputFieldBlock>
        <S_InputFieldBlock>
          <InputFieldButton
            type="password"
            name="password"
            placeholder={t('str_pw_hint', '비밀번호')}
            validation={passwordValidation}
            maxLength={30}
            cancel
          />
        </S_InputFieldBlock>
        <S_InputFieldBlock>
          <InputFieldButton
            type="password"
            name="passwordConfirm"
            placeholder={t('str_confirm_pw_hint', '비밀번호 확인')}
            validation={passwordConfirmValidation}
            maxLength={30}
            cancel
          />
        </S_InputFieldBlock>
        <S_CheckBoxWrapper>
          <CheckBoxSeperateLabel
            name="agreeMarketing"
            label={
              <div
                onClick={() =>
                  window.open(
                    'https://publr.co/ko/marketing/',
                    'popup',
                    'menubar=1,resizable=1,width=480,height=550'
                  )
                }
              >
                {t('str_agree_terms_marketing', '마케팅 수신 동의')}
              </div>
            }
          />
          <CheckBoxSeperateLabel
            bold
            name="agreeTerms"
            label={
              <div
                onClick={() =>
                  window.open(
                    'https://publr.co/ko/terms-for-user/',
                    'popup',
                    'menubar=1,resizable=1,width=480,height=550'
                  )
                }
              >
                {t('str_agree_terms', '이용약관')}
              </div>
            }
            validation={required}
          />
          <CheckBoxSeperateLabel
            bold
            name="agreePolicy"
            label={
              <div
                onClick={() =>
                  window.open(
                    'https://publr.co/ko/privacy/',
                    'popup',
                    'menubar=1,resizable=1,width=480,height=550'
                  )
                }
              >
                {t('str_agree_policy', '개인정보 정책')}
              </div>
            }
            validation={required}
          />
        </S_CheckBoxWrapper>
        <SubmitButton full disabled={isButtonDisabled} label={t('str_do_signup', '가입하기')} />
      </S_Form>
    </NavView>
  );
}

type PrevButtonProps = {
  onClick: MouseEventHandler;
};

function PrevButton({ onClick }: PrevButtonProps) {
  return (
    <S_Button onClick={onClick}>
      <Icon iconName="ic_ArrowLeft" />
    </S_Button>
  );
}

const S_InputFieldBlock = styled.div`
  margin-top: 16px;
`;

const S_Form = styled.div`
  background-color: white;
  height: 100%;
  padding-left: 24px;
  padding-right: 24px;
  text-align: left;
  user-select: none;
`;

const S_Title = styled.div`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeH2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  line-height: ${({ theme }) => theme.fontLineHeight};
  margin-bottom: 24px;
  margin-top: 24px;
  text-align: left;
`;

const S_CheckBoxWrapper = styled.div`
  margin: 24px 0;
`;

const S_Button = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-left: 8px;
  width: 48px;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }
`;

export default SignUpForm;
