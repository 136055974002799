import styled from 'styled-components';

type DescTagViewProps = {
  tags: string[];
};

/**
 * 채널 소개 페이지 하단 시트 - 태그
 */
function DescTagView({ tags = [] }: DescTagViewProps) {
  return (
    <S_DescTagView>
      {tags.map((tag) => (
        <S_Tag key={tag}>{tag}</S_Tag>
      ))}
    </S_DescTagView>
  );
}

const S_DescTagView = styled.div`
  color: white;
  display: flex;
  flex-wrap: wrap;
  height: 24px;
  max-width: 320px;
`;

const S_Tag = styled.span`
  background-color: ${({ theme }) => theme.grey50};
  border-radius: 12px;
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeCaption1};
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  line-height: normal;
  margin-bottom: 8px;
  margin-right: 8px;
  padding: 3px 12px;
`;

export default DescTagView;
