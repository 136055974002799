import { createSelector } from 'reselect';
import { RootState } from '../../index';

export const getIsSessionLoading = (state: RootState) => {
  return state.session.isLoading;
};

export const getIsAuthenticated = (state: RootState) => {
  return state.session.isAuthenticated;
};

export const getIsAuthenticating = (state: RootState) => {
  return state.session.isAuthenticating;
};

export const getSessionError = (state: RootState) => {
  return state.session.error;
};

export const makeGetIsSessionLoading = () => {
  return createSelector([getIsSessionLoading], (isLoading) => isLoading);
};

export const makeGetIsAuthenticated = () => {
  return createSelector([getIsAuthenticated], (isAuthenticated) => isAuthenticated);
};

export const makeGetIsAuthenticating = () => {
  return createSelector([getIsAuthenticating], (isAuthenticating) => isAuthenticating);
};
