import IconType from '../IconType';

const MicOff = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M5.4511,8.6778 C5.8651,8.6778 6.2011,9.0138 6.2011,9.4278 L6.2011,9.4278 L6.2011,12.4578 C6.2011,15.6548 8.8031,18.2558 12.0001,18.2558 C13.1741,18.2558 14.2661,17.9028 15.1801,17.3008 L15.1801,17.3008 L16.2551,18.3758 C15.2471,19.1028 14.0511,19.5838 12.7501,19.7178 L12.7501,19.7178 L12.7501,22.1138 L17.4281,22.1138 C17.8421,22.1138 18.1781,22.4498 18.1781,22.8638 C18.1781,23.2778 17.8421,23.6138 17.4281,23.6138 L17.4281,23.6138 L6.5721,23.6138 C6.1571,23.6138 5.8221,23.2778 5.8221,22.8638 C5.8221,22.4498 6.1571,22.1138 6.5721,22.1138 L6.5721,22.1138 L11.2501,22.1138 L11.2501,19.7178 C7.5761,19.3408 4.7011,16.2288 4.7011,12.4578 L4.7011,12.4578 L4.7011,9.4278 C4.7011,9.0138 5.0371,8.6778 5.4511,8.6778 Z M2.5808,2.5805 C2.8738,2.2875 3.3488,2.2875 3.6418,2.5805 L3.6418,2.5805 L21.4188,20.3575 C21.7118,20.6505 21.7118,21.1255 21.4188,21.4185 C21.2728,21.5645 21.0808,21.6375 20.8888,21.6375 C20.6968,21.6375 20.5048,21.5645 20.3578,21.4185 L20.3578,21.4185 L2.5808,3.6415 C2.2878,3.3485 2.2878,2.8735 2.5808,2.5805 Z M7.6039,9.7246 L14.1079,16.2286 C13.4789,16.5746 12.7679,16.7896 11.9999,16.7896 C9.5719,16.7896 7.6039,14.8216 7.6039,12.3936 L7.6039,12.3936 L7.6039,9.7246 Z M18.5482,8.6786 C18.9622,8.6786 19.2982,9.0146 19.2982,9.4286 L19.2982,9.4286 L19.2982,12.4576 C19.2982,13.8096 18.9242,15.0726 18.2812,16.1596 L18.2812,16.1596 L17.1762,15.0546 C17.5702,14.2726 17.7982,13.3926 17.7982,12.4576 L17.7982,12.4576 L17.7982,9.4286 C17.7982,9.0146 18.1342,8.6786 18.5482,8.6786 Z M11.9994,1.1351 C14.4274,1.1351 16.3954,3.1031 16.3954,5.5301 L16.3954,5.5301 L16.3954,12.3941 C16.3954,12.9511 16.2814,13.4791 16.0914,13.9701 L16.0914,13.9701 L7.6084,5.4871 C7.6324,3.0801 9.5864,1.1351 11.9994,1.1351 Z"
      />
    </svg>
  );
};

export default MicOff;
