import { createReducer, createAction } from '@reduxjs/toolkit';
import { builderFactory } from '../../../utils/reduxHelper';

import { InitialState, FetchLightningCheckoutPayload } from './types';

const prefix = '@subscription';

export const fetchLightningCheckout = createAction<FetchLightningCheckoutPayload>(
  `${prefix}/FETCH_LIGHTNING_CHECKOUT`
);
export const fetchRefresh = createAction(`${prefix}/REFRESH`);

const initialState: InitialState = {
  isLoading: false,
  checkout: {},
  error: null
};

const reducer = createReducer(initialState, (builder) => {
  builderFactory(builder, [fetchLightningCheckout])
    .addCase(`${fetchLightningCheckout}_COMPLETED`, (state, action: any) => {
      const { checkout } = action.payload.data.data;

      state.checkout = checkout;
    })
    .addMatcher(
      (action) => action.type.startsWith(prefix) && action.type.endsWith('_COMPLETED'),
      (state) => {
        state.isLoading = false;
      }
    )
    .addMatcher(
      (action) => action.type.startsWith(prefix) && action.type.endsWith('_FAILED'),
      (state, action) => {
        state.isLoading = false;
        state.error = action.payload.data.message;
      }
    );
});

export default reducer;
