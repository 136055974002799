import { URI, JWT, Request } from '../../utils';

import { getEndpointSet, ENDPOINT } from './endpoints';

import { RESP_AXIOS_UNAUTHENTICATED } from './responses.json';

const {
  REACT_APP_KEY_ACCESS_TOKEN,
  REACT_APP_KEY_REFRESH_TOKEN,
  REACT_APP_AUTH_TOKEN_REFRESH_THRESHOLD
} = process.env;

type EndpointWithHost = ENDPOINT & {
  host: string;
  pathRegExp: ReturnType<typeof URI.genTemplateUriRegExp>;
};

export type PathVariables = { [key: string]: string | number | boolean };

export type QueryParams = PathVariables;

// eslint-disable-next-line no-undef
export type Body = any;

// eslint-disable-next-line no-undef
export type Options = Partial<Record<keyof RequestInit, any>>;

class Api {
  token: string;
  refreshToken: string;
  ajaxRequest: Request;

  constructor() {
    const [tokenStatus, result] = JWT.validateJwtToken(
      localStorage.getItem(REACT_APP_KEY_ACCESS_TOKEN)
    );

    this.token = tokenStatus === 'OK' ? result : '';
    this.refreshToken = localStorage.getItem(REACT_APP_KEY_REFRESH_TOKEN) || '';

    this.ajaxRequest = new Request();
  }

  applyCredentials(credentials: { token: string; refreshToken: string }) {
    const { token, refreshToken } = credentials;

    this.setToken(token);
    this.setRefreshToken(refreshToken);
  }

  purgeCredentials() {
    this.unsetToken();
    this.unsetRefreshToken();
  }

  setToken(token: string) {
    this.token = token;
    localStorage.setItem(REACT_APP_KEY_ACCESS_TOKEN, token);
  }

  setRefreshToken(refreshToken: string) {
    this.refreshToken = refreshToken;
    localStorage.setItem(REACT_APP_KEY_REFRESH_TOKEN, refreshToken);
  }

  unsetToken() {
    this.token = '';
    localStorage.removeItem(REACT_APP_KEY_ACCESS_TOKEN);
  }

  unsetRefreshToken() {
    this.refreshToken = '';
    localStorage.removeItem(REACT_APP_KEY_REFRESH_TOKEN);
  }

  get(
    endpoint: EndpointWithHost,
    pathVariables?: PathVariables,
    queryParams?: QueryParams,
    options?: Options
  ) {
    return this.request(
      endpoint,
      pathVariables ?? undefined,
      queryParams ?? undefined,
      undefined,
      options ?? undefined
    );
  }

  post(endpoint: EndpointWithHost, pathVariables?: PathVariables, body?: Body, options?: Options) {
    return this.request(
      endpoint,
      pathVariables ?? undefined,
      undefined,
      body ?? undefined,
      options ?? undefined
    );
  }

  put(endpoint: EndpointWithHost, pathVariables?: PathVariables, body?: Body, options?: Options) {
    return this.request(
      endpoint,
      pathVariables ?? undefined,
      undefined,
      body ?? undefined,
      options ?? undefined
    );
  }

  remove(endpoint: EndpointWithHost, pathVariables?: PathVariables, options?: Options) {
    return this.request(endpoint, pathVariables ?? undefined, undefined, undefined, options);
  }

  async request(
    endpoint: EndpointWithHost,
    pathVariables: PathVariables = {},
    queryParams: QueryParams = {},
    body: Body = '',
    options: Options = {},
    enableRefreshStrategy = true
  ): Promise<any> {
    if (endpoint.permission === 'private') {
      const [tokenStatus, result, jwtData] = JWT.validateJwtToken(this.token);

      if (tokenStatus === 'ERROR') {
        this.unsetToken();

        return enableRefreshStrategy
          ? this.refreshAndContinue(false, endpoint, pathVariables, queryParams, body, options)
          : RESP_AXIOS_UNAUTHENTICATED;
      }

      if (
        enableRefreshStrategy &&
        JWT.willJwtTokenBeExpired(jwtData, Number(REACT_APP_AUTH_TOKEN_REFRESH_THRESHOLD))
      ) {
        return this.refreshAndContinue(true, endpoint, pathVariables, queryParams, body, options);
      }

      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${result}`
      };
    }

    if (endpoint.permission === 'conditional') {
      const [tokenStatus, result, jwtData] = JWT.validateJwtToken(this.token);
      if (tokenStatus !== 'ERROR') {
        if (
          enableRefreshStrategy &&
          JWT.willJwtTokenBeExpired(jwtData, Number(REACT_APP_AUTH_TOKEN_REFRESH_THRESHOLD))
        ) {
          return this.refreshAndContinue(true, endpoint, pathVariables, queryParams, body, options);
        }
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${result}`
        };
      } else if (result !== 'TOKEN_NOT_EXIST') {
        this.unsetToken();
        return enableRefreshStrategy
          ? this.refreshAndContinue(false, endpoint, pathVariables, queryParams, body, options)
          : RESP_AXIOS_UNAUTHENTICATED;
      }
    }

    return this.ajaxRequest.call(
      endpoint.method,
      URI.buildURI(endpoint.host, endpoint.path, pathVariables),
      pathVariables,
      queryParams,
      body,
      options
    );
  }

  async refetchToken() {
    if (!this.refreshToken) {
      return RESP_AXIOS_UNAUTHENTICATED;
    }

    const endpointSet = getEndpointSet('AUTH', 'session');
    const body = { refreshToken: this.refreshToken };
    const { status, data: responseBody } = await this.request(
      endpointSet.REFRESH,
      undefined,
      undefined,
      body,
      {},
      false
    );

    if (status !== 200) {
      this.unsetRefreshToken();
      return RESP_AXIOS_UNAUTHENTICATED;
    }

    const {
      data: { token }
    } = responseBody;

    const [tokenStatus, result] = JWT.validateJwtToken(token);

    if (tokenStatus === 'OK') {
      this.setToken(result);
    }
  }

  async refreshAndContinue(enableBypassStrategy = true, ...originalArgs: any[]) {
    if (!this.refreshToken) {
      return enableBypassStrategy
        ? // @ts-ignore
          this.request(...originalArgs, false)
        : RESP_AXIOS_UNAUTHENTICATED;
    }

    const endpointSet = getEndpointSet('AUTH', 'session');
    const body = { refreshToken: this.refreshToken };
    const { status, data: responseBody } = await this.request(
      endpointSet.REFRESH,
      undefined,
      undefined,
      body,
      {},
      false
    );

    if (status !== 200) {
      this.unsetRefreshToken();

      return enableBypassStrategy
        ? // @ts-ignore
          this.request(...originalArgs, false)
        : RESP_AXIOS_UNAUTHENTICATED;
    }

    const {
      data: { token }
    } = responseBody;

    const [tokenStatus, result] = JWT.validateJwtToken(token);

    if (tokenStatus === 'OK') {
      this.setToken(result);
    }

    // @ts-ignore
    return this.request(...originalArgs, false);
  }
}

export default new Api();
