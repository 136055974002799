import { useMemo } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PrivateRoute } from '@common/overrides/index';
import { makeGetIsAuthenticated } from '@common/redux/modules/session';
import { makeGetCurrentChannel } from '@common/redux/modules/channel';

import {
  SocialCommunityPApp,
  NoticePApp,
  VODPApp,
  VODPlusPApp,
  Chatting,
  Booking,
  FreeBoardPApp
} from '../../screens';
import {
  channelBasicPath,
  postsBasicPath,
  noticeBasicPath,
  vodBasicPath,
  vodPlusBasicPath,
  chatBasicPath,
  bookingBasicPath,
  freeboardBasicPath
} from '@routers/routes';
import { ChannelSetting, ChannelSettingProfile, SellerInfo } from '../../screens/Channels/pages';

import { SideMenu } from '../components/molecules';

const settingPath = `${channelBasicPath}/settings`;
const settingProfilePath = `${channelBasicPath}/settings/profile`;
const settingPaymentPath = `${channelBasicPath}/settings/payment`;
const settingPublrInfoPath = `${channelBasicPath}/settings/publr-info`;
const settingReportPath = `${channelBasicPath}/settings/report`;
const settingSellerInfoPath = `${channelBasicPath}/settings/seller-info`;

function PAppContainer({ defaultPath, channelID }: { defaultPath: string; channelID: string }) {
  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const getIsAuthenticated = useMemo(makeGetIsAuthenticated, []);
  const isAuthenticated = useSelector(getIsAuthenticated);

  return (
    <>
      {currentChannel && <SideMenu channelID={channelID} />}
      <Switch>
        <PrivateRoute
          path={noticeBasicPath}
          component={NoticePApp}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          path={postsBasicPath}
          component={SocialCommunityPApp}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute path={vodBasicPath} component={VODPApp} isAuthenticated={isAuthenticated} />
        <PrivateRoute
          path={vodPlusBasicPath}
          component={VODPlusPApp}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute path={chatBasicPath} component={Chatting} isAuthenticated={isAuthenticated} />
        <PrivateRoute
          path={bookingBasicPath}
          component={Booking}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          path={freeboardBasicPath}
          component={FreeBoardPApp}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path={settingPath}
          component={ChannelSetting}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path={settingSellerInfoPath}
          component={SellerInfo}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path={settingProfilePath}
          component={ChannelSettingProfile}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path={settingPaymentPath}
          component={ChannelSetting}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path={settingPublrInfoPath}
          component={ChannelSetting}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path={settingReportPath}
          component={ChannelSetting}
          isAuthenticated={isAuthenticated}
        />
        <Redirect to={defaultPath} />
      </Switch>
    </>
  );
}

export default PAppContainer;
