import { useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';

import { ModalDialog, Button } from '@common/ui/components';
import {
  createComment,
  deleteComment,
  fetchComments,
  fetchNoticePost,
  resetComments
} from '@common/redux/modules/noticePApp/reducers';
import {
  getComments,
  getIsCommentCreated,
  getIsCommentDeleted,
  getNoticePost,
  getTotalCommentsCount
} from '@common/redux/modules/noticePApp';
import JWT from '@common/utils/jwt';
import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import { COMMENT_DEFAULT_LIMIT } from '@common/constants/index';

import { NavBar } from '../../components/molcules';
import { CommentInputControl, NoticeDetailInfo, Comment } from './molecules';

function NoticeDetail() {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const { postId } = useParams<{ postId: string }>();

  const [userId, setUserId] = useState();
  const [channelId, setChannelId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const targetId = useRef<number>(0);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const noticePost = useSelector(getNoticePost);
  const comments = useSelector(getComments);
  const totalCommentsCount = useSelector(getTotalCommentsCount);
  const isCommentCreated = useSelector(getIsCommentCreated);
  const isCommentDeleted = useSelector(getIsCommentDeleted);

  useEffect(() => {
    return () => {
      dispatch(resetComments());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!currentChannel) return;

    const userMeta = JWT.getUserMetaInChannel(currentChannel.id);
    setChannelId(currentChannel.id);
    setUserId(userMeta?.profileId);
  }, [currentChannel]);

  useEffect(() => {
    if (!channelId || !postId) return;

    dispatch(fetchNoticePost({ postId, channelId }));
    dispatch(fetchComments({ postId, limit: COMMENT_DEFAULT_LIMIT, page: currentPage, channelId }));
  }, [dispatch, postId, currentPage, channelId]);

  useEffect(() => {
    if (!channelId || !postId) return;

    if (isCommentCreated || isCommentDeleted) {
      dispatch(
        fetchComments({ postId, limit: COMMENT_DEFAULT_LIMIT, page: currentPage, channelId })
      );
    }
  }, [dispatch, postId, currentPage, channelId, isCommentCreated, isCommentDeleted]);

  useEffect(() => {
    if (noticePost && contentRef && contentRef.current) {
      const dom = new DOMParser();
      const doc = dom.parseFromString(noticePost.content, 'text/html');

      contentRef.current.innerHTML = doc.documentElement.innerHTML;
    }
  }, [noticePost]);

  const handleAddComment = (data: any) => {
    dispatch(createComment({ postId, channelId, ...data }));
  };

  const handleDeleteComment = () => {
    dispatch(deleteComment({ postId, commentId: targetId.current, channelId }));

    targetId.current = 0;
    setDeleteModalOpen(false);
  };

  const handleDeleteModalOpen = (id: number) => {
    targetId.current = id;

    setDeleteModalOpen(true);
  };

  const handleScrollDown = () => {
    if (comments.length === totalCommentsCount) {
      return;
    }

    setCurrentPage(currentPage + 1);
  };

  const handleCloseDeleteModal = () => {
    targetId.current = 0;
    setDeleteModalOpen(false);
  };

  return (
    <>
      <NavBar isBack />

      {noticePost && (
        <NoticeDetailInfo
          title={noticePost.title}
          author={noticePost.author}
          createdAt={noticePost.insertedAt}
          viewCount={0}
        />
      )}

      <S_ContentWrapper>
        <S_Content ref={contentRef} />
      </S_ContentWrapper>

      <S_Line />
      <S_CommentArea>
        <S_CommentCount>
          {t('str_94', {
            cmtCnt: noticePost?.totalComments,
            defaultValue: '댓글 {{cmtCnt}} 개'
          })}
        </S_CommentCount>

        <CommentInputControl handleAddComment={handleAddComment} />

        <S_ScrollField
          dataLength={comments.length}
          next={handleScrollDown}
          hasMore={comments.length !== totalCommentsCount}
        >
          {comments.length === 0 ? (
            <S_NoItem>
              {t('str_489', '아직 업로드한 소셜 게시글 [[str_comment]]이 없습니다.')}
            </S_NoItem>
          ) : (
            <>
              {comments.map(
                ({ id, authorThumbnailSrc, author, insertedAt, content, profileId }) => (
                  <Comment
                    key={id}
                    id={id}
                    profileImage={authorThumbnailSrc}
                    nickname={author}
                    createdAt={insertedAt}
                    content={content}
                    isAuthor={profileId === userId}
                    onDeleteComment={handleDeleteModalOpen}
                  />
                )
              )}
            </>
          )}
        </S_ScrollField>
      </S_CommentArea>

      <ModalDialog
        onClose={handleCloseDeleteModal}
        isOpen={isDeleteModalOpen}
        footer={
          <S_ButtonBox>
            <Button onClick={handleDeleteComment} label={t('str_ok', '확인')} />
            <Button line onClick={handleCloseDeleteModal} label={t('str_cancel', '취소')} />
          </S_ButtonBox>
        }
      >
        {t('str_62', '[[str_delete]]하시겠습니까?')}
      </ModalDialog>
    </>
  );
}

const S_Line = styled.div`
  background-color: ${({ theme }) => theme.grey100};
  height: 1px;
  margin: 24px 0;
  width: 100%;
`;

const S_CommentArea = styled.div`
  text-align: left;
`;

const S_CommentCount = styled.span`
  color: ${({ theme }) => theme.grey500};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  margin-left: 24px;
`;

const S_ScrollField = styled(InfiniteScroll as any)`
  margin-bottom: 24px;
  padding: 24px;
`;

const S_ButtonBox = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
`;

const S_ContentWrapper = styled.div`
  padding: 0 24px;
`;

const S_Content = styled.div`
  color: ${({ theme }) => theme.grey900};
  text-align: left;
  white-space: pre-wrap;
  width: 100%;
  word-break: break-word;

  figure {
    margin: 0;

    img {
      width: 100%;
    }
  }

  blockquote {
    border-left: 4px solid ${({ theme }) => theme.grey400};
    margin: 4px;
    padding: 4px;

    p {
      font-style: oblique;
      margin: 0;
    }
  }
`;

const S_NoItem = styled.div`
  color: ${({ theme }) => theme.grey400};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  margin-bottom: 80px;
  margin-top: 48px;
  text-align: center;
`;

export default NoticeDetail;
