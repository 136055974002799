import axios from 'axios';

import {
  RESP_AXIOS_NETWORK_CONNECT_TIMEOUT_ERROR,
  RESP_AXIOS_UNDEFINED_ERROR
} from './responses.json';

class Request {
  constructor() {
    this.axios = axios;
  }

  getInstance() {
    return this.axios;
  }

  call(method, url, pathVariables, params, body, options) {
    const config = {
      method,
      url,
      pathVariables,
      params,
      ...options,
      ...(body != null && { data: body })
    };

    if (body instanceof FormData) {
      config.headers = {
        ...config.headers,
        'Content-Type': 'multipart/form-data'
      };
    }

    return this.axiosWithErrorHandler(config);
  }

  async axiosWithErrorHandler(config) {
    try {
      const response = await this.axios(config);
      return response;
    } catch (error) {
      return this.genResponseFromError(error);
    }
  }

  genResponseFromError(error) {
    if (error.response) {
      return error.response;
    }

    if (error.request) {
      return RESP_AXIOS_NETWORK_CONNECT_TIMEOUT_ERROR;
    }

    return RESP_AXIOS_UNDEFINED_ERROR;
  }
}

export default Request;
