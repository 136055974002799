export const profileBarMenu = [
  {
    key: 'str_edit',
    defaultValue: '수정'
  },
  {
    key: 'str_delete',
    defaultValue: '삭제'
  }
];
