import { useEffect } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

function VodPlusIFrame() {
  const location = useLocation<{ src: string }>();
  const { src } = location.state;

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <S_VodPlusPlayerIFrame
      title="video"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      referrerPolicy="no-referrer"
      sandbox="allow-same-origin allow-scripts"
      allowFullScreen
    >
      <video src={src} autoPlay controls />
    </S_VodPlusPlayerIFrame>
  );
}

const S_VodPlusPlayerIFrame = styled.iframe`
  border: none;
  bottom: 0;
  height: 100%;
  left: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999;
`;

export default VodPlusIFrame;
