import styled from 'styled-components';

function EventNonAvailable() {
  return <S_EventNonAvailableContainer>가능한 예약 일정이 없습니다.</S_EventNonAvailableContainer>;
}

const S_EventNonAvailableContainer = styled.div`
  color: ${({ theme }) => theme.textDisable};
  margin-top: 48px;
`;

export default EventNonAvailable;
