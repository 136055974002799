import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeGetCurrentChannel, makeGetChannelError } from '@common/redux/modules/channel';
import styled from 'styled-components';
import JWT from '@common/utils/jwt';
import { makeGetIsAuthenticated } from '@common/redux/modules/session';
import { buildChannelsDeactivate, notFoundPath } from '@routers/routes';
import { CoverImageView, CoverTitleView } from './atoms';

function ChannelCoverContainer({
  channelID,
  pAppCode,
  setIsLoading,
  setIsActivePApp,
  setIsDefault
}: {
  pAppCode: string;
  channelID: string;
  setIsLoading: (value: boolean) => void;
  setIsActivePApp: (value: boolean) => void;
  setIsDefault: (value: boolean) => void;
}) {
  const history = useHistory();
  const isChannelActivate = true;
  const getChannelError = useMemo(makeGetChannelError, []);
  const channelError = useSelector(getChannelError);

  const getIsAuthenticated = useMemo(makeGetIsAuthenticated, []);
  const isAuthenticated = useSelector(getIsAuthenticated);

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  useEffect(() => {
    if (channelError) {
      history.push(notFoundPath);
    }
  }, [channelError]);

  useEffect(() => {
    setIsDefault(false);
    if (!currentChannel) {
      return;
    }

    const { id } = currentChannel;
    let isActivePApp = false;
    let isAvailableChannels = false;

    if (isChannelActivate) {
      if (!isAuthenticated) {
        setIsLoading(false);
      } else {
        const availableChannels = JWT.getAvailableChannels();
        if (!availableChannels) {
          setIsLoading(false);
          return;
        }

        const isAvailable = availableChannels[id];
        if (!isAvailable) {
          setIsLoading(false);
          return;
        }
        const { activePApps } = availableChannels[id];
        const activePAppsList = Object.keys(activePApps);
        if (!activePAppsList) {
          setIsLoading(false);
          return;
        }

        isActivePApp = activePAppsList.indexOf(String(pAppCode)) > -1;

        const localStorageToken = localStorage.getItem(
          process.env.REACT_APP_KEY_ACCESS_TOKEN || ''
        );
        const [tokenStatus, , jwtData] = JWT.validateJwtToken(localStorageToken);

        if (tokenStatus !== 'ERROR' && jwtData.user) {
          const availableChannels = Object.keys(jwtData.user.availableChannels);
          if (!availableChannels) {
            setIsLoading(false);
            return;
          }
          isAvailableChannels = availableChannels.indexOf(String(currentChannel.id)) > -1;
        }
        if (isAvailableChannels) {
          if (pAppCode) {
            if (isActivePApp) {
              setIsActivePApp(true);
              setIsLoading(false);
            } else {
              setIsActivePApp(false);
              setIsLoading(false);
              setIsDefault(true);
            }
          } else {
            setIsActivePApp(true);
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      }
    } else {
      history.push(buildChannelsDeactivate(channelID));
    }
  }, [currentChannel]);

  return (
    <S_ChannelCover>
      <CoverImageView imageUrl={currentChannel && currentChannel.iconImageSrc} />
      <CoverTitleView title={currentChannel && currentChannel.title} />
    </S_ChannelCover>
  );
}

const S_ChannelCover = styled.div`
  align-items: center;
  box-shadow: -30px 0 100px -100px #000, 30px 0 100px -100px #000;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  width: 100%;
`;

export default ChannelCoverContainer;
