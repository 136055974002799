import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon } from '@common/ui/components';

import { Image, Text } from '../../../components';

function UnPublishedScreen() {
  const { t } = useTranslation('translation');

  return (
    <>
      <S_PlayerField>
        <Image ratio={16 / 9} />
        <S_IconWrapper>
          <Icon iconName="ic_Unavailable" size={64} colorCode="grey400" />
        </S_IconWrapper>
      </S_PlayerField>
      <S_TextWrapper>
        <Text elipsis={false} fontSizes="fontSizeBody1" color="grey900">
          {t('str_959', '셀러에 의해 비공개된 콘텐츠입니다. 다른 콘텐츠를 선택해 주세요.')}
        </Text>
      </S_TextWrapper>
    </>
  );
}

const S_PlayerField = styled.div`
  height: auto;
  position: relative;
  top: 0;
  width: 100%;
`;

const S_IconWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  left: 50%;
  margin: -32px 0 0 -32px;
  position: absolute;
  top: 50%;
`;

const S_TextWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 16px 24px;
  text-align: left;
`;
export default UnPublishedScreen;
