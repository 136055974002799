import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeGetAllCounterpart, getChatError } from '@common/redux/modules/chat/selectors';

import { CounterpartBlock, NavBar } from './molecules';
import { NavView } from '../../components/templates';

const ChattingList = () => {
  const getAllCounterpart = useMemo(makeGetAllCounterpart, []);
  const allCounterpart = useSelector(getAllCounterpart);
  const error = useSelector(getChatError);

  return (
    <NavView nav={<NavBar title="Chat" />}>
      {allCounterpart.map((info) => (
        <CounterpartBlock key={info.id} info={info} />
      ))}
      {error}
    </NavView>
  );
};

export default ChattingList;
