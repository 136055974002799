import { Button } from '@common/ui/components';
import RECEIPT_BOTTOM_IMG from '@assets/img/booking-confirm-receipt.png';
import Icon from '@common/ui/components/Icon/Icon';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  titleIcon: JSX.Element;
  description: string;
  buttonTitle?: string;
  productName?: string;
  productPrice?: string;
  onClick: (e: MouseEvent) => void;
};

function PurchaseProduct({
  title,
  titleIcon,
  description,
  buttonTitle,
  productName,
  productPrice,
  onClick
}: Props) {
  const { t } = useTranslation('translation');
  return (
    <S_PurchaseProduct>
      <S_PurchaseReceipt>
        <S_MainBox>
          {titleIcon}
          <S_TItle>{title}</S_TItle>
          <S_ContentsBox>
            <S_Content>
              <S_ContentTitleBox>
                <Icon iconName="ic_PaymentGift" fill colorCode="grey900" size={24} />
                <S_ContentTitle>{t('str_1264', '$t(str_product) 명칭')}</S_ContentTitle>
              </S_ContentTitleBox>
              <S_ContentValue>{productName}</S_ContentValue>
            </S_Content>
            <S_Content>
              <S_ContentTitleBox>
                <Icon iconName="ic_Payment" fill colorCode="grey900" size={24} />
                <S_ContentTitle>{t('str_1265', '$t(str_product) 가격')}</S_ContentTitle>
              </S_ContentTitleBox>
              <S_ContentValue>{productPrice}</S_ContentValue>
            </S_Content>
          </S_ContentsBox>
        </S_MainBox>
        <S_ButtonBox>
          <S_Description>{description}</S_Description>
          {buttonTitle && <Button type="button" label={buttonTitle} onClick={onClick} />}
        </S_ButtonBox>
        <S_BottomImg src={RECEIPT_BOTTOM_IMG} />
      </S_PurchaseReceipt>
      <S_FooterWrapper>
        <S_Footer>
          {t(
            'str_footer_info_mobile',
            'publ\n대표자 : 배인식\n상호명 : (주)키클롭스 | 소재지 : 서울특별시 성동구 뚝섬로 317\n사업자등록번호 : 115-87-00415\n통신판매업번호 : 2019-서울성동-01388\n전화번호 : 02-6463-0777'
          )
            .split('\n')
            .map((text: string, index: number) =>
              index === 0 ? (
                <S_FooterTitle key={index}>{text}</S_FooterTitle>
              ) : (
                <div key={index}>{text}</div>
              )
            )}
        </S_Footer>
      </S_FooterWrapper>
    </S_PurchaseProduct>
  );
}

const S_PurchaseReceipt = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
  max-width: 480px;
  background-color: ${({ theme }) => theme.white};
  padding: 3rem 1.5rem;
  flex: 1;
`;

const S_Description = styled.div`
  color: ${({ theme }) => theme.grey500};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  line-height: ${({ theme }) => theme.fontLineHeight};
  margin-bottom: 24px;
  text-align: center;
  white-space: pre;
`;

const S_TItle = styled.h2`
  font-size: ${({ theme }) => theme.fontSizeH2};
`;

const S_Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const S_ContentTitle = styled.span`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody1};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  margin-left: 8px;
`;

const S_ContentTitleBox = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
`;

const S_ContentValue = styled.div`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightNormal};
`;

const S_BottomImg = styled.img`
  width: 100%;
  position: absolute;
  bottom: -10px;
  left: 0;
`;

const S_Footer = styled.div`
  color: ${({ theme }) => theme.grey500};
  font-size: ${({ theme }) => theme.fontSizeCaption1};
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  line-height: 1.67;
  white-space: pre;
`;

const S_FooterWrapper = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 32px;
  padding: 0 16px;
  text-align: initial;
`;

const S_PurchaseProduct = styled.div`
  &,
  * {
    box-sizing: border-box;
  }

  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.grey50};
  padding: 1.5rem;
`;

const S_ContentsBox = styled.div`
  & > div {
    margin-top: 24px;
  }
  margin-top: 38px;
`;

const S_MainBox = styled.div``;

const S_ButtonBox = styled.div`
  & > button {
    width: 100%;
  }
`;

const S_FooterTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeightBold};
  margin-bottom: 4px;
`;

export default PurchaseProduct;
