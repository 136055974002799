import { put, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { NoticePAppRepository } from '../../../../repositories';
import { fetchNoticePost, fetchNoticePosts } from '../reducers';
import { digestResponse } from '../../../middlewares/response';

export function* watchFetchNoticePosts(action: ReturnType<typeof fetchNoticePosts>) {
  const { type, payload } = action;
  const { channelId, ...rest } = payload;

  const response: AxiosResponse = yield call(() =>
    NoticePAppRepository.findAllPosts(rest, channelId)
  );

  yield put(digestResponse(response, type));
}

export function* watchFetchNoticePost(action: ReturnType<typeof fetchNoticePost>) {
  const { type, payload } = action;
  const { postId, channelId } = payload;

  const response: AxiosResponse = yield call(() =>
    NoticePAppRepository.findPost(postId, channelId)
  );

  yield put(digestResponse(response, type));
}
