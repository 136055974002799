import styled from 'styled-components';
import { Icon } from '@common/ui/components';

type Props = {
  count: number;
};

function CommentCount({ count }: Props) {
  return (
    <S_CommentCount>
      <Icon iconName="ic_Reply" colorCode="grey500" size={16} />
      <S_Count>{count >= 999 ? '999+' : count}</S_Count>
    </S_CommentCount>
  );
}

const S_CommentCount = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
`;

const S_Count = styled.span`
  color: ${({ theme }) => theme.grey500};
  font-size: ${({ theme }) => theme.fontSizeBody2};
`;

export default CommentCount;
