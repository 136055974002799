import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import { getSeries, getSeriesEpisodes } from '@common/redux/modules/VODPlusPApp';
import {
  cleanUpAllData,
  fetchSeriesById,
  fetchSeriesEpisodes,
  fetchAddBookmark,
  fetchDeleteBookmark
} from '@common/redux/modules/VODPlusPApp/reducers';
import { Button, Icon } from '@common/ui/components';
import { buildVodPlusPath } from '@routers/routes';

import { NavBar, StickyTop } from '../../components';

import { DetailContent } from './molecules';
import { EpisodeList } from './organisms';

function VodPlusSeriesDetail() {
  const dispatch = useDispatch();
  const location = useLocation<{ pathName?: string }>();
  const { t } = useTranslation('translation');
  const { seriesId }: { seriesId: string } = useParams();

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const [channelId, setChannelId] = useState<number>(0);
  const [invitationCode, setInvitationCode] = useState<string>('');
  const vodPlusPath = buildVodPlusPath(invitationCode);

  const series = useSelector(getSeries);
  const episodes = useSelector(getSeriesEpisodes);

  const { title, seriesAdditionalInfo, coverImageSrc, labels, hasUserBookmarked } = { ...series };
  const [isBookmarked, setIsBookmarked] = useState<boolean | null>(null);

  const pathBack = () => {
    if (!location.state) return `${vodPlusPath}/home`;
    const { pathName } = location.state;
    switch (pathName) {
      case 'home':
        return `${vodPlusPath}/home`;
      case 'serieses':
        return `${vodPlusPath}/serieses`;
      case 'wishlist':
        return `${vodPlusPath}/wishlist`;
      case 'episode':
        return `${vodPlusPath}/home`;
      default:
        return `${vodPlusPath}/home`;
    }
  };

  const ageLabel = labels?.map((label) => {
    if (label.type === 'AGE_RATING') {
      return label.name;
    }
    return '';
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!currentChannel) return;

    setInvitationCode(currentChannel.primitiveInvitationCode);
    setChannelId(currentChannel.id);
  }, [currentChannel]);

  useEffect(() => {
    if (!seriesId || !channelId) return;

    dispatch(fetchSeriesById({ seriesId, channelId }));
    dispatch(fetchSeriesEpisodes({ seriesId, channelId }));
    hasUserBookmarked && setIsBookmarked(hasUserBookmarked);
  }, [dispatch, seriesId, channelId, hasUserBookmarked]);

  useEffect(() => {
    dispatch(cleanUpAllData());
  }, [dispatch]);

  return (
    <S_TotalView>
      <StickyTop>
        <NavBar isBack backUrl={pathBack()} title={title} />
      </StickyTop>
      <DetailContent
        imageSrc={coverImageSrc || ''}
        title={title || ''}
        labels={labels || []}
        ageLimit={ageLabel || []}
        releaseYear={seriesAdditionalInfo?.productionDate.slice(0, 4) || ''}
        content={seriesAdditionalInfo?.synopsis || ''}
      />
      <S_ButtonWrapper>
        <Button
          icon={<Icon iconName={isBookmarked ? 'ic_Check' : 'ic_Plus'} size={16} />}
          label={isBookmarked ? t('찜 완료', '찜 완료') : t('str_908', '찜하기')}
          line
          full
          onClick={() => {
            isBookmarked
              ? dispatch(fetchDeleteBookmark({ seriesId, channelId }))
              : dispatch(fetchAddBookmark({ seriesId, channelId }));
            setIsBookmarked(!isBookmarked);
          }}
        />
      </S_ButtonWrapper>
      <EpisodeList
        episodeCounts={episodes.length}
        episodes={episodes}
        invitationCode={invitationCode}
      />
    </S_TotalView>
  );
}

const S_TotalView = styled.div`
  height: auto;
`;

const S_ButtonWrapper = styled.div`
  border-bottom: ${({ theme }) => `1px solid  ${theme.grey100}`};
  margin: 0 24px;
  padding-bottom: 24px;
`;
export default VodPlusSeriesDetail;
