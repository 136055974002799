import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { buildVodPlusPath } from '@routers/routes';
import { fetchSeriesesNewLabel } from '@common/redux/modules/VODPlusPApp/reducers';
import { VODPLUS_SERIES_DEFAULT_LIMIT } from '@common/constants/hardcoded';

import { CategoryBox, SearchBox } from '../molecules';

type Props = {
  invitationCode: string;
  category: string;
  channelId: number;
  labelId?: number;
  setSearchInputValue: (input: string) => void;
};

function CategorizeTab({
  invitationCode,
  category,
  channelId,
  labelId,
  setSearchInputValue
}: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const vodPlusPath = buildVodPlusPath(invitationCode);

  const [isCategorySelected] = useState(false);
  const [isSearchSelected, setIsSearchSelected] = useState(false);

  const isSelected = isCategorySelected || category.length > 0;

  const goToCategory = () => {
    history.push(`${vodPlusPath}/category`, { category, labelId });
  };
  const searchHandler = () => {
    setIsSearchSelected(!isSearchSelected);
    dispatch(
      fetchSeriesesNewLabel({
        limit: VODPLUS_SERIES_DEFAULT_LIMIT,
        channelId
      })
    );
  };

  return (
    <S_CategorizeTab isSearchSelected={isSearchSelected}>
      {!isSearchSelected && (
        <CategoryBox
          invitationCode={invitationCode}
          isCategorySelected={isCategorySelected}
          categoryHandler={goToCategory}
          category={category}
        />
      )}
      {!isSelected && (
        <SearchBox
          channelId={channelId}
          isSearchSelected={isSearchSelected}
          searchHandler={searchHandler}
          setSearchInputValue={setSearchInputValue}
        />
      )}
    </S_CategorizeTab>
  );
}

const S_CategorizeTab = styled.div<{ isSearchSelected: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  height: 56px;
  justify-content: space-between;
  padding: ${({ isSearchSelected }) =>
    isSearchSelected ? '16px 24px 8px 24px' : '12px 8px 12px 24px'};
`;

export default CategorizeTab;
