import IconType from '../IconType';

const ArrowDown = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M19.5,9.35 L12.589,16.261 C12.264,16.586 11.736,16.586 11.411,16.261 L4.5,9.35"
      />
    </svg>
  );
};

export default ArrowDown;
