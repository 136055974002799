import { useTranslation } from 'react-i18next';

import { myBookingType } from '@common/redux/modules/booking/types';
import styled from 'styled-components';

import { Title } from '../../../components/atoms';
import { BookingView, NavBar } from '../../../components/molcules';
import { BookingCard } from '../molcules';
import { MyBookingList } from '../organisms';

function MyBookingTemplate({ progressing, bookings, channelID }: myBookingType) {
  const { t } = useTranslation('translation');
  return (
    <S_MyBookingTemplateContainer>
      <BookingView
        nav={
          <NavBar
            isBack
            title={t('str_pg_title_papp_cbooking_my_booking', {
              defaultValue: '내 $t(str_papp_cbooking_rsv) 내역'
            })}
            backgroundColor="#f5f6f8"
            channelID={channelID}
            pageFrom="MyBooking"
            pathTo="calendar"
          />
        }
      >
        <>
          {/* 일정 시작 전/후의 시점에서 요청/응답 없는 경우 */}
          {progressing && progressing[0] && progressing[0].cancel === undefined && (
            <BookingCard
              nowProgressing
              title={0}
              body={0}
              time={progressing[0].startDate}
              duration={progressing[0].duration}
              id={progressing[0].id}
              channelID={channelID}
            />
          )}
          {/* 일정 시작 전/후의 시점에서 요청/응답 있는 경우 */}
          {progressing && progressing[0] && progressing[0].cancel && (
            <BookingCard
              nowProgressing
              title={progressing[0].cancel?.result}
              body={progressing[0].cancel?.status}
              time={progressing[0].startDate}
              duration={progressing[0].duration}
              id={progressing[0].id}
              channelID={channelID}
            />
          )}
          <Title text={t('str_16')} align="left" margin="32px 24px 24px 24px" />
          {bookings?.length !== 0 && <MyBookingList bookings={bookings} channelID={channelID} />}
          {bookings?.length === 0 && <S_NoBooking>예약 내역이 없습니다.</S_NoBooking>}
          <S_MyBookingFooter />
        </>
      </BookingView>
    </S_MyBookingTemplateContainer>
  );
}

const S_MyBookingTemplateContainer = styled.div`
  background-color: #f5f6f8;
`;

const S_MyBookingFooter = styled.div`
  height: 12px;
`;

const S_NoBooking = styled.div`
  position: absolute;
  top: 50%;
  width: 100vw;
`;

export default MyBookingTemplate;
