import IconType from '../IconType';

const PaymentGift = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M17.164443,1.60426069 L17.3654,1.6613 C18.9244,2.1613 19.7864,3.8373 19.2874,5.3963 C19.1608809,5.79054559 18.9542377,6.14545351 18.6883773,6.44521348 L18.7,6.4449 C20.633,6.4449 22.2,8.0119 22.2,9.9449 L22.2,9.9449 L22.2,18.5929 C22.2,20.5259 20.633,22.0929 18.7,22.0929 L18.7,22.0929 L5.3,22.0929 C3.367,22.0929 1.8,20.5259 1.8,18.5929 L1.8,18.5929 L1.8,9.9449 C1.8,8.0119 3.367,6.4449 5.3,6.4449 L5.3,6.4449 L5.31151345,6.44535792 C5.04606226,6.14559828 4.83941912,5.79057941 4.7129,5.396 C4.2129,3.837 5.0759,2.161 6.6349,1.661 C7.7099,1.318 8.8529,1.602 9.6309,2.386 L10.0719,2.927 L11.999,5.30121777 L13.9274,2.9263 L14.3704,2.3883 C15.111025,1.647675 16.1566305,1.35529219 17.164443,1.60426069 Z M11.249,13.3742178 L3.3,13.3742178 L3.3,18.5929 C3.3,19.6448091 4.11730785,20.5108421 5.15093266,20.5873991 L5.3,20.5929 L11.249,20.5922178 L11.249,13.3742178 Z M20.7,13.3742178 L12.749,13.3742178 L12.749,20.5922178 L18.7,20.5929 C19.7528636,20.5929 20.6180289,19.774681 20.694505,18.7418431 L20.7,18.5929 L20.7,13.3742178 Z M6.3649,16.1511 C7.1769,16.1511 7.8349,16.8091 7.8349,17.6211 C7.8349,18.4331 7.1769,19.0911 6.3649,19.0911 C5.5529,19.0911 4.8949,18.4331 4.8949,17.6211 C4.8949,16.8091 5.5529,16.1511 6.3649,16.1511 Z M11.249,7.94421777 L5.3,7.9449 C4.197,7.9449 3.3,8.8419 3.3,9.9449 L3.3,9.9449 L3.3,10.1732178 L11.249,10.1732178 L11.249,7.94421777 Z M18.7,7.9449 L12.749,7.94421777 L12.749,10.1732178 L20.7,10.1732178 L20.7,9.9449 C20.7,8.89203636 19.8826921,8.02687107 18.8490673,7.95039502 L18.7,7.9449 Z M15.4324,3.4443 L13.2774,6.1383 L16.4844,5.9573 C17.1234,5.9313 17.6714,5.5223 17.8594,4.9383 C18.1064,4.1663 17.6794,3.3373 16.9074,3.0903 C16.3774,2.9203 15.8154,3.0593 15.4324,3.4443 Z M7.5479,3.018 C7.3969,3.018 7.2439,3.041 7.0929,3.09 C6.3209,3.337 5.8939,4.166 6.1419,4.938 C6.3289,5.522 6.8759,5.932 7.5049,5.956 L10.7229,6.139 L8.5549,3.428 C8.2899,3.164 7.9269,3.018 7.5479,3.018 Z"
      />
    </svg>
  );
};

export default PaymentGift;
