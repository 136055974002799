import IconType from '../IconType';

const MicOn = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M18.5482,8.679 C18.9622,8.679 19.2982,9.015 19.2982,9.429 L19.2982,9.429 L19.2982,12.458 C19.2982,16.229 16.4232,19.341 12.7502,19.718 L12.7502,19.718 L12.7502,22.114 L17.4282,22.114 C17.8422,22.114 18.1782,22.45 18.1782,22.864 C18.1782,23.278 17.8422,23.614 17.4282,23.614 L17.4282,23.614 L6.5712,23.614 C6.1572,23.614 5.8212,23.278 5.8212,22.864 C5.8212,22.45 6.1572,22.114 6.5712,22.114 L6.5712,22.114 L11.2502,22.114 L11.2502,19.718 C7.5762,19.341 4.7012,16.229 4.7012,12.458 L4.7012,12.458 L4.7012,9.429 C4.7012,9.015 5.0372,8.679 5.4512,8.679 C5.8652,8.679 6.2012,9.015 6.2012,9.429 L6.2012,9.429 L6.2012,12.458 C6.2012,15.655 8.8022,18.256 12.0002,18.256 C15.1972,18.256 17.7982,15.655 17.7982,12.458 L17.7982,12.458 L17.7982,9.429 C17.7982,9.015 18.1342,8.679 18.5482,8.679 Z M11.9999,1.135 C14.4279,1.135 16.3959,3.103 16.3959,5.531 L16.3959,5.531 L16.3959,12.394 C16.3959,14.822 14.4279,16.79 11.9999,16.79 C9.5719,16.79 7.6039,14.822 7.6039,12.394 L7.6039,12.394 L7.6039,5.531 C7.6039,3.103 9.5719,1.135 11.9999,1.135 Z"
      />
    </svg>
  );
};

export default MicOn;
