import { createReducer, createAction } from '@reduxjs/toolkit';
import { builderFactory } from '../../../utils/reduxHelper';

import { InitialState, SignInPayload, SignUpPayload } from './types';

const prefix = '@session';

export const autoSignIn = createAction(`${prefix}/AUTO_SIGNIN`);
export const signIn = createAction<SignInPayload>(`${prefix}/SIGNIN`);
export const signInAuthenticating = createAction(`${signIn}_AUTHENTICATING`);
export const signOut = createAction(`${prefix}/SIGNOUT`);
export const signOutCompleted = createAction(`${signOut}_COMPLETED`);
export const signUp = createAction<SignUpPayload>(`${prefix}/SIGNUP`);
export const signUpAuthenticating = createAction(`${signUp}_AUTHENTICATING`);
export const refresh = createAction(`${prefix}/REFRESH`);

const initialState: InitialState = {
  isLoading: true,
  isAuthenticated: false,
  isAuthenticating: false,
  error: null
};

const reducer = createReducer(initialState, (builder) => {
  builderFactory(builder, [autoSignIn, signIn, signOut, refresh])
    .addCase(`${autoSignIn}_COMPLETED`, (state) => {
      state.isAuthenticated = true;
    })
    .addCase(`${signIn}_COMPLETED`, (state) => {
      state.isAuthenticated = true;
      state.error = null;
    })
    .addCase(`${signIn}_FAILED`, (state, action: any) => {
      state.error = action.payload.data.error;
    })
    .addCase(`${signOut}_COMPLETED`, (state) => {
      state.isAuthenticated = false;
    })
    .addCase(`${signUp}_COMPLETED`, (state) => {
      state.isAuthenticated = true;
    })
    .addCase(`${signUp}_FAILED`, (state, action: any) => {
      state.isAuthenticating = false;
      state.isLoading = false;
      state.error = action.payload.data.error;
    })
    .addCase(`${refresh}_COMPLETED`, (state) => {
      state.isAuthenticated = true;
    })
    .addCase(`${signUp}_AUTHENTICATING`, (state) => {
      state.isAuthenticating = true;
    })
    .addCase(`${signIn}_AUTHENTICATING`, (state) => {
      state.isAuthenticating = true;
      state.error = null;
    })
    .addMatcher(
      (action) => action.type.startsWith(prefix) && action.type.endsWith('_COMPLETED'),
      (state) => {
        state.isLoading = false;
        state.isAuthenticating = false;
        state.error = null;
      }
    )
    .addMatcher(
      (action) => action.type.startsWith(prefix) && action.type.endsWith('_FAILED'),
      (state) => {
        state.isLoading = false;
      }
    );
});

export default reducer;
