import { createReducer, createAction } from '@reduxjs/toolkit';
import DateHelper from '../../../utils/dateHelper';

import { makeCounterpartInterface, formatDateWithDms } from './service';
import { builderFactory } from '../../../utils/reduxHelper';
import { last, head, drop, dropLast, pipe } from '../../../utils/fpHelper';
import {
  CounterpartFormat,
  DateWithDms,
  InitialState,
  Profile,
  Counterpart,
  FetchPreviousDmsPayload,
  SendingNewDmPayload,
  AddRecentDmsPayload,
  AddPreviousDmsPayload,
  ResendDmPayload,
  RemoveDmPayload,
  ConnectedNewClientPayload,
  DisconnectedClientPayload,
  ReadDmPayload
} from './types';

export const EVENT = {
  NEW_CLIENT_IN_CHANNEL: 'NEW_CLIENT_IN_CHANNEL',
  CLIENT_OUT_IN_CHANNEL: 'CLIENT_OUT_IN_CHANNEL',
  SYSTEM_MESSAGE: 'SYSTEM_MESSAGE',
  CREATED_NEW_DM: 'CREATED_NEW_DM',
  AUTH_USER: 'AUTH_USER',
  AUTH_PASS: 'AUTH_PASS',
  RECONNECT: 'reconnect'
};

const prefix = '@chat';

export const fetchCounterpart = createAction(`${prefix}/FETCH_COUNTERPARTS`);
export const fetchCounterpartProfile = createAction(`${prefix}/FETCH_COUNTERPART_PROFILE`);
export const fetchPreviousDms = createAction<FetchPreviousDmsPayload>(
  `${prefix}/FETCH_PREVIOUS_DMS`
);
export const sendingNewDm = createAction<SendingNewDmPayload>(`${prefix}/SENDING_NEW_DM`);

export const addRecentDms = createAction<AddRecentDmsPayload>(`${prefix}/ADD_RECENT_DMS`);
export const addPreviousDms = createAction<AddPreviousDmsPayload>(`${prefix}/ADD_PREVIOUS_DMS`);
export const resendDm = createAction<ResendDmPayload>(`${prefix}/RESEND_DM`);
export const removeDm = createAction<RemoveDmPayload>(`${prefix}/REMOVE_DM`);
export const readDm = createAction<ReadDmPayload>(`${prefix}/READ_DM`);

export const setCurrentCounterpartProfileId = createAction<number>(
  `${prefix}/SET_CURRENT_COUNTERPART`
);
export const unsetCurrentCounterpartProfileId = createAction(`${prefix}/UNSET_CURRENT_COUNTERPART`);

export const connectSocket = createAction(`${prefix}/CONNECT_SOCKET`);
export const disconnectSocket = createAction(`${prefix}/DISCONNECT_SOCKET`);
export const connectedNewClient = createAction<ConnectedNewClientPayload>(
  `${prefix}/${EVENT.NEW_CLIENT_IN_CHANNEL}`
);
export const disconnectedClient = createAction<DisconnectedClientPayload>(
  `${prefix}/${EVENT.CLIENT_OUT_IN_CHANNEL}`
);

const initialState: InitialState = {
  isLoading: true,
  error: null,
  counterpart: {},
  currentCounterpartProfileId: null
};

const reducer = createReducer(initialState, (builder) => {
  builderFactory(builder, [
    fetchCounterpart,
    fetchCounterpartProfile,
    fetchPreviousDms,
    sendingNewDm
  ])
    .addCase(addRecentDms, (state, action) => {
      const { counterpartProfileId, messages, myProfileId } = action.payload;
      const currentCounterpart = state.counterpart[counterpartProfileId];

      const newDateWithDms = pipe<DateWithDms[]>(
        formatDateWithDms,
        (dateWithDms: DateWithDms[]) => {
          return dateWithDms.reduce((acc, cur) => {
            if (
              last(cur.dms).senderProfileId ===
              head(head(currentCounterpart.dateWithDms).dms).senderProfileId
            ) {
              head(head(currentCounterpart.dateWithDms).dms).hasTail = false;
            }

            if (cur.date === head(currentCounterpart.dateWithDms).date) {
              head(currentCounterpart.dateWithDms).dms.unshift(...cur.dms);
              return dropLast(1, acc);
            }

            return acc;
          }, dateWithDms);
        }
      )(messages, myProfileId);

      Object.assign(currentCounterpart, {
        dateWithDms: newDateWithDms.concat(currentCounterpart.dateWithDms),
        latestMessageTxid: head(messages)?.txid || currentCounterpart.latestMessageTxid,
        latestMessageText: head(messages)?.messageText || currentCounterpart.latestMessageText,
        latestMessageTime: head(messages)?.createdAt || currentCounterpart.latestMessageTime,
        formatDate: head(messages)
          ? DateHelper.getInstance(head(messages).createdAt).fromNow()
          : currentCounterpart.formatDate
      });
    })
    .addCase(addPreviousDms, (state, action) => {
      const { counterpartProfileId, messages, initialFetch, myProfileId } = action.payload;
      const MAX_DMS_LENGTH_EACH_CALL = 20;

      const currentCounterpart = state.counterpart[counterpartProfileId];
      const newDateWithDms = pipe<DateWithDms[]>(
        formatDateWithDms,
        (dateWithDms: DateWithDms[]) => {
          return dateWithDms.reduce((acc, cur) => {
            if (
              head(cur.dms).senderProfileId ===
              last(last(currentCounterpart.dateWithDms).dms).senderProfileId
            ) {
              head(cur.dms).hasTail = false;
            }

            if (cur.date === last(currentCounterpart.dateWithDms).date) {
              last(currentCounterpart.dateWithDms).dms.push(...cur.dms);
              return drop(1, acc);
            }

            return acc;
          }, dateWithDms);
        }
      )(messages, myProfileId);

      Object.assign(currentCounterpart, {
        dateWithDms: currentCounterpart.dateWithDms.concat(newDateWithDms),
        oldestMessageTxid: last(messages)?.txid || currentCounterpart.oldestMessageTxid,
        hasOldestMessage: messages.length < MAX_DMS_LENGTH_EACH_CALL,
        hasInitializedDms: initialFetch ? true : currentCounterpart.hasInitializedDms
      });
    })
    .addCase(removeDm, (state, action) => {
      const { counterpartProfileId, messageId } = action.payload;
      const currentCounterpart = state.counterpart[counterpartProfileId];

      currentCounterpart.dateWithDms = currentCounterpart.dateWithDms.reduce((acc, cur) => {
        if (cur.dms.find((dm) => dm.id === messageId)) {
          cur.dms = cur.dms.filter((dm) => dm.id !== messageId);
        }

        acc.push(cur);

        return acc;
      }, [] as DateWithDms[]);
    })
    .addCase(connectedNewClient, (state, action) => {
      const { data, myProfileId } = action.payload;

      data.forEach((id) => {
        if (id === myProfileId || !state.counterpart[id]) return;
        state.counterpart[id].isOnline = true;
      });
    })
    .addCase(disconnectedClient, (state, action) => {
      const { data, myProfileId } = action.payload;

      data.forEach((id) => {
        if (id === myProfileId || !state.counterpart[id]) return;
        state.counterpart[id].isOnline = false;
      });
    })
    .addCase(setCurrentCounterpartProfileId, (state, action) => {
      state.currentCounterpartProfileId = action.payload;
    })
    .addCase(unsetCurrentCounterpartProfileId, (state) => {
      state.currentCounterpartProfileId = null;
    })
    .addCase(`${fetchCounterpart}_COMPLETED`, (state, action: any) => {
      const { recentDms }: { recentDms: Counterpart[] } = action.payload.data.data;
      const { myProfileId }: { myProfileId: number } = action.payload;

      Object.assign(
        state.counterpart,
        recentDms.reduce((acc, cur) => {
          acc[cur.counterpartProfileId] = makeCounterpartInterface(cur, myProfileId);
          return acc;
        }, {} as { [key: string]: CounterpartFormat })
      );
    })
    .addCase(`${fetchCounterpartProfile}_COMPLETED`, (state, action: any) => {
      const { profiles }: { profiles: Profile[] } = action.payload.data.data;

      profiles.forEach((profile) => {
        state.counterpart[profile.id].imageSrc = profile.imageSrc;
        state.counterpart[profile.id].nickname = profile.nickname;
      });
    })
    .addMatcher(
      (action) => action.type.startsWith(prefix) && action.type.endsWith('_COMPLETED'),
      (state) => {
        state.isLoading = false;
      }
    )
    .addMatcher(
      (action) => action.type.startsWith(prefix) && action.type.endsWith('_FAILED'),
      (state, action) => {
        state.isLoading = false;
        state.error =
          typeof action.payload.data === 'string'
            ? action.payload.data
            : action.payload.data.message;
      }
    );
});

export default reducer;
