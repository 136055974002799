import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { MainBannerSlidesSectionItems } from '@common/redux/modules/VODPlusPApp/types';

import { buildVodPlusPath } from '@routers/routes';

import { Image, Text, GradientCover } from '../../../components';

type Props = {
  carouselItem: MainBannerSlidesSectionItems;
  invitationCode: string;
};

function HomeCarouselContents({ carouselItem, invitationCode }: Props) {
  const history = useHistory();
  const vodPlusPath = buildVodPlusPath(invitationCode);

  const {
    alternativeLogoSrc,
    backgroundMobileImgSrc,
    description,
    series,
    title,
    webLinkSrc
  } = carouselItem;

  if (webLinkSrc && webLinkSrc?.length > 0) {
    return (
      <a href={webLinkSrc} target="_blank" rel="noreferrer">
        <GradientCover height="230px" z-index={2} />
        <S_TextBox>
          <S_TitleLogoWrapper height="72px">
            {alternativeLogoSrc ? (
              <Image
                src={alternativeLogoSrc}
                ratio={16 / 9}
                backgroundSize="contain"
                noBackground
              />
            ) : (
              <Text lineLimit={2} fontSizes="fontSizeH2" elipsis>
                {title}
              </Text>
            )}
          </S_TitleLogoWrapper>
          <S_TitleLogoWrapper height="40px">
            <Text lineLimit={2} fontSizes="fontSizeBody2" elipsis>
              {description}
            </Text>
          </S_TitleLogoWrapper>
        </S_TextBox>

        <S_HomeSlideImage src={backgroundMobileImgSrc} ratio={0.94} />
      </a>
    );
  }
  return (
    <div
      onClick={() => {
        if (series?.id) history.push(`${vodPlusPath}/serieses/${series.id}`, { pathName: 'home' });
      }}
    >
      <GradientCover height="230px" z-index={2} />
      <S_TextBox>
        <S_TitleLogoWrapper height="72px">
          {alternativeLogoSrc ? (
            <Image src={alternativeLogoSrc} ratio={16 / 9} noBackground backgroundSize="contain" />
          ) : (
            <Text lineLimit={2} fontSizes="fontSizeH2" elipsis>
              {title}
            </Text>
          )}
        </S_TitleLogoWrapper>
        <S_TitleLogoWrapper height="40px">
          <Text lineLimit={2} fontSizes="fontSizeBody2" elipsis>
            {description}
          </Text>
        </S_TitleLogoWrapper>
      </S_TextBox>

      <S_HomeSlideImage src={backgroundMobileImgSrc} ratio={0.94} />
    </div>
  );
}

const S_TextBox = styled.div`
  bottom: 24px;
  left: 24px;
  position: absolute;
  z-index: 3;
`;

const S_TitleLogoWrapper = styled.div<{ height: string }>`
  height: auto;
  text-align: left;
  & div {
    height: ${({ height }) => height};
    line-height: 20px;
    width: 252px;
  }
`;

const S_HomeSlideImage = styled.span<{
  ratio: number;
  src?: string;
}>`
  background-image: ${({ src }) => src && `url(${src})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  padding-top: ${({ ratio }) => `${Math.floor(100 / ratio)}%`};
  position: relative;
  width: 100%;
`;

export default HomeCarouselContents;
