import IconType from '../IconType';

const Photo = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M18,4 C19.657,4 21,5.324 21,6.954 L21,6.954 L21,16.8 C21,18.432 19.657,19.754 18,19.754 L18,19.754 L6,19.754 C4.344,19.754 3,18.432 3,16.8 L3,16.8 L3,6.954 C3,5.324 4.344,4 6,4 L6,4 Z M9.567,11.556 C9.29,11.315 8.864,11.307 8.577,11.539 L8.577,11.539 L4.488,14.856 L4.488,16.8 C4.488,17.609 5.167,18.266 6,18.266 L6,18.266 L18,18.266 C18.834,18.266 19.512,17.609 19.512,16.8 L19.512,16.8 L19.512,14.936 L17.61,13.479 C17.347,13.277 16.973,13.267 16.7,13.458 L16.7,13.458 L15.074,14.595 C14.412,15.057 13.525,15.009 12.917,14.479 L12.917,14.479 Z M18,5.489 L6,5.489 C5.167,5.489 4.488,6.147 4.488,6.954 L4.488,6.954 L4.488,12.939 L7.64,10.383 C8.495,9.691 9.718,9.711 10.546,10.435 L10.546,10.435 L13.896,13.358 C13.984,13.436 14.122,13.443 14.222,13.374 L14.222,13.374 L15.848,12.239 C16.66,11.673 17.731,11.696 18.516,12.297 L18.516,12.297 L19.512,13.06 L19.512,6.954 C19.512,6.147 18.834,5.489 18,5.489 L18,5.489 Z M15.5,6.9545 C16.328,6.9545 17,7.6155 17,8.4305 C17,9.2475 16.328,9.9085 15.5,9.9085 C14.672,9.9085 14,9.2475 14,8.4305 C14,7.6155 14.672,6.9545 15.5,6.9545 Z"
      />
    </svg>
  );
};

export default Photo;
