import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { Icon } from '@common/ui/components';

import SubmitButton from './SubmitButton';
import { TextFieldButton } from '../TextFieldButton';
import { Button } from '../Button';

export type InputFieldButtonProps = {
  name: string;
  multiLine?: boolean;
  textArea?: boolean;
  placeholder?: string;
  defaultValue?: string;
  readOnly?: boolean;
  maxLength?: number;
  disabled?: boolean;
  opacity?: boolean;
  cancel?: boolean;
  value?: string;
  hasValue?: boolean;
  validation?: { [key: string]: any };
  type?: string;
  rows?: number;
  height?: number;
  onIcon?: (el: boolean) => void;
  onTarget?: () => void;
  onChange?: (e: any) => void;
};

type InputStyleProps = {
  isFocused?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  isError?: boolean;
  rows?: number;
  height?: number;
  backgroundOpacity?: boolean;
  borderOpacity?: boolean;
  multiLine?: boolean;
  textArea?: boolean;
};

type CancelButtonStyleProps = {
  $cursor: boolean;
};

function InputFieldButton({
  name,
  multiLine,
  textArea,
  readOnly,
  disabled,
  maxLength = 30,
  opacity,
  cancel,
  hasValue,
  validation,
  onIcon,
  onTarget,
  rows,
  height,
  ...rest
}: InputFieldButtonProps) {
  const { t } = useTranslation('translation');
  const [isFocused, setIsFocused] = useState(false);

  const {
    trigger,
    setValue,
    formState: { errors }
  } = useFormContext();

  const isError = Object.keys(errors).some((error) => error === name);

  function handleCancel(target: string) {
    setIsFocused(false);

    setValue(target as string, '');
  }

  function handleFocus() {
    if (!readOnly) {
      if (onIcon) onIcon(true);
      setIsFocused(true);
    }
  }

  function handleBlur(
    e: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>
  ) {
    if (!readOnly) {
      setIsFocused(false);
    }

    setValue(name, e.target.value.replace(/^\s+|\s+$/gm, ''));
    trigger(name);
  }

  function handleTarget() {
    if (onTarget) {
      onTarget();
    }
  }

  return (
    <>
      <S_Container multiLine={multiLine} textArea={textArea} height={height}>
        <S_InputWrapper
          isFocused={isFocused}
          readOnly={readOnly}
          disabled={disabled}
          isError={isError}
          backgroundOpacity={opacity}
          borderOpacity={opacity}
          multiLine={multiLine}
          textArea={textArea}
          height={height}
        >
          <TextFieldButton
            fieldName={name}
            multiLine={multiLine}
            textArea={textArea}
            readOnly={readOnly}
            disabled={disabled}
            maxLength={maxLength}
            onBlur={(
              e: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>
            ) => handleBlur(e)}
            onFocus={handleFocus}
            onIcon={onIcon}
            onTarget={handleTarget}
            opacity={opacity}
            validation={validation}
            rows={rows}
            {...rest}
          />
          {isFocused && (
            <>
              {cancel && (
                <S_CancelButtonBox $cursor onMouseDown={() => handleCancel(name)}>
                  <Icon iconName="ic_Delete" colorCode="grey500" fill />
                </S_CancelButtonBox>
              )}
              {opacity && hasValue && (
                <S_ButtonBox>
                  <SubmitButton
                    fill={false}
                    inputIcon
                    icon={<Icon iconName="ic_PaperPlane" colorCode="grey100" fill />}
                  />
                </S_ButtonBox>
              )}
              {opacity && !hasValue && (
                <S_ButtonBox>
                  <Button
                    fill={false}
                    disabled
                    inputIcon
                    icon={<Icon iconName="ic_PaperPlane" colorCode="grey500" fill />}
                  />
                </S_ButtonBox>
              )}
            </>
          )}
        </S_InputWrapper>
      </S_Container>
      {errors[name] && <S_ErrorField>{t(errors[name].message)}</S_ErrorField>}
    </>
  );
}

const disabledStyle = css`
  background-color: ${({ theme }) => theme.grey50};
  color: ${({ theme }) => theme.black};
`;

const readOnlyStyle = css`
  background-color: ${({ theme }) => theme.grey50};
  color: ${({ theme }) => theme.grey400};
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  user-select: none;
`;

const S_Container = styled.div<InputStyleProps>`
  width: 100%;
  ${({ multiLine, textArea }) =>
    textArea || multiLine ? textAreaWrapperStyles : inputWrapperStyles};
`;

const inputWrapperStyles = css`
  height: 48px;
`;

const textAreaWrapperStyles = css<InputStyleProps>`
  ${({ height }) => (height ? `height: ${height}px;` : '')}
`;

const S_InputWrapper = styled.div<InputStyleProps>`
  align-items: center;
  background-color: ${({ backgroundOpacity, theme }) => {
    if (backgroundOpacity) return 'rgba(245, 246, 248, 0.2)';
    return theme.white;
  }};
  border: solid 1px
    ${({ theme, isError, isFocused, borderOpacity }) => {
      if (isError) return theme.red500;
      if (isFocused && !borderOpacity) return theme.blue500;
      if (borderOpacity) return 'rgba(245, 246, 248, 0.5)';
      return theme.grey100;
    }};
  border-radius: 8px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.black};
  display: inline-flex;
  min-height: 48px;
  outline: 0;
  overflow: hidden;
  width: 100%;

  ${({ multiLine, textArea }) =>
    textArea || multiLine ? textAreaWrapperStyles : inputWrapperStyles};
  ${({ disabled }) => disabled && disabledStyle}
  ${({ readOnly }) => readOnly && readOnlyStyle}
`;

const S_ErrorField = styled.div`
  color: ${({ theme }) => theme.red500};
  font-size: ${({ theme }) => theme.fontSizeCaption2};
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  line-height: ${({ theme }) => theme.fontLineHeight};
  margin-top: 4px;
  text-align: left;
`;

const S_CancelButtonBox = styled.div<CancelButtonStyleProps>`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 40px;

  ${({ $cursor }) =>
    $cursor &&
    css`
      cursor: pointer;
    `};
`;

const S_ButtonBox = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 40px;
`;

export default InputFieldButton;
