import { Icon } from '@common/ui/components';
import React from 'react';

import styled from 'styled-components';

type ContentBarProps = {
  title: string;
  content?: string;
  iconName?: string;
};

function ContentBar({ title, content, iconName }: ContentBarProps) {
  return (
    <>
      <S_ContentBarContainer>
        <S_Box>
          {!!iconName && <S_IconWapper iconName={iconName} />}
          <S_Title>{title}</S_Title>
        </S_Box>
        <S_Content>{content}</S_Content>
      </S_ContentBarContainer>
    </>
  );
}

const S_ContentBarContainer = styled.div`
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: space-between;
  margin: 0;
  padding: 0 24px;
`;

const S_Box = styled.div`
  display: flex;
  align-items: center;
`;

const S_IconWapper = styled(Icon)`
  margin-right: 8px;
`;

const S_Title = styled.div`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody1};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  line-height: normal;
`;

const S_Content = styled.div`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  line-height: normal;
`;

export default ContentBar;
