import JWT from '@common/utils/jwt';
import { AxiosResponse } from 'axios';

import api, { getEndpointSet } from '@common/services/api';
import { ResponseOK } from '@common/types/response';

import {
  PostsQueryParams,
  PostsWithField,
  PostWithField,
  PaginationPayload,
  CreateCommentPayload,
  CommentsWithField,
  CommentWithField
} from './socialCommunityPApp.types';

const { FIND_ALL_POSTS, FIND_POST_BY_ID, CREATE_POST, UPDATE_POST, DELETE_POST } = getEndpointSet(
  'SOCIAL_COMMUNITY_PAPP',
  'postable'
);

const { FIND_ALL_COMMENTS, CREATE_COMMENT, DELETE_COMMENT } = getEndpointSet(
  'SOCIAL_COMMUNITY_PAPP',
  'comments'
);

const { DO_LIKE_POST, DO_DISLIKE_POST, UNDO_DISLIKE_POST, UNDO_LIKE_POST } = getEndpointSet(
  'SOCIAL_COMMUNITY_PAPP',
  'like'
);

const { COUNT_HITS } = getEndpointSet('STATISTICS', 'viewcount');

function getHeaderOptions(id: number) {
  return {
    headers: {
      'x-publ-consumer-id': JWT.getChannelHeader(id, 'A00001')
    }
  };
}

class PostsRepository {
  static findAllPosts(
    queryParams: PostsQueryParams,
    channelId: number
  ): Promise<AxiosResponse<ResponseOK<PostsWithField>>> {
    return api.get(FIND_ALL_POSTS, {}, queryParams, getHeaderOptions(channelId));
  }

  static findPostById(
    pathVariable: { postId: string },
    channelId: number
  ): Promise<AxiosResponse<ResponseOK<PostWithField>>> {
    return api.get(FIND_POST_BY_ID, pathVariable, {}, getHeaderOptions(channelId));
  }

  static createPost(
    body: any,
    channelId: number
  ): Promise<AxiosResponse<ResponseOK<PostWithField>>> | undefined {
    const channelProfile = JWT.getChannelProfile(channelId);
    if (!channelProfile) return;
    const { nickname, imageSrc } = channelProfile;

    const postBody = {
      author: nickname,
      authorThumbnailSrc: imageSrc,
      type: 'STORY',
      isPublished: true,
      ...body
    };

    return api.post(CREATE_POST, {}, postBody, getHeaderOptions(channelId));
  }

  static updatePost(
    postId: string | number,
    body: any,
    channelId: number
  ): Promise<AxiosResponse<ResponseOK<PostWithField>>> | undefined {
    const channelProfile = JWT.getChannelProfile(channelId);
    if (!channelProfile) return;
    const { nickname, imageSrc } = channelProfile;

    const postBody = {
      author: nickname,
      authorThumbnailSrc: imageSrc,
      type: 'STORY',
      isPublished: true,
      ...body
    };

    return api.put(UPDATE_POST, { postId }, postBody, getHeaderOptions(channelId));
  }

  static removePost(
    postId: string | number,
    channelId: number
  ): Promise<AxiosResponse<ResponseOK<undefined>>> {
    return api.remove(DELETE_POST, { postId }, getHeaderOptions(channelId));
  }

  static findAllComments(
    postId: string,
    queryParams: PaginationPayload,
    channelId: number
  ): Promise<AxiosResponse<ResponseOK<CommentsWithField>>> {
    return api.get(FIND_ALL_COMMENTS, { postId }, queryParams, getHeaderOptions(channelId));
  }

  static createComment(
    postId: string,
    body: CreateCommentPayload,
    channelId: number
  ): Promise<AxiosResponse<ResponseOK<CommentWithField>>> | undefined {
    const channelProfile = JWT.getChannelProfile(channelId);
    if (!channelProfile) return;
    const { nickname, imageSrc } = channelProfile;

    const commentBody = {
      author: nickname,
      authorThumbnailSrc: imageSrc,
      ...body
    };

    return api.post(CREATE_COMMENT, { postId }, commentBody, getHeaderOptions(channelId));
  }

  static removeComment(
    pathVariable: { postId: string; commentId: number },
    channelId: number
  ): Promise<AxiosResponse<ResponseOK<undefined>>> {
    return api.post(DELETE_COMMENT, pathVariable, {}, getHeaderOptions(channelId));
  }

  static likePost(
    channelId: number,
    postId: string | number,
    isLiked: boolean
  ): Promise<AxiosResponse<ResponseOK<PostWithField>>> {
    return isLiked
      ? api.post(DO_LIKE_POST, { postId }, {}, getHeaderOptions(channelId))
      : api.remove(UNDO_LIKE_POST, { postId }, getHeaderOptions(channelId));
  }

  static dislikePost(
    channelId: number,
    postId: string | number,
    isDisliked: boolean
  ): Promise<AxiosResponse<ResponseOK<PostWithField>>> {
    return isDisliked
      ? api.post(DO_DISLIKE_POST, { postId }, {}, getHeaderOptions(channelId))
      : api.remove(UNDO_DISLIKE_POST, { postId }, getHeaderOptions(channelId));
  }

  static countHits(
    channelId: number,
    resourceId: number
  ): Promise<AxiosResponse<ResponseOK<PostWithField>>> {
    return api.post(
      COUNT_HITS,
      { 'resource-type': 'post', 'resource-id': resourceId },
      {},
      getHeaderOptions(channelId)
    );
  }
}

export default PostsRepository;
