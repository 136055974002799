import IconType from '../IconType';

const ArrowLeft = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M12.83675,20.36275 C12.64475,20.36275 12.45275,20.28975 12.30675,20.14275 L5.45975,13.29575 C4.84675,12.68075 4.84675,11.68175 5.45975,11.06775 L12.30675,4.21975 C12.59975,3.92675 13.07375,3.92675 13.36675,4.21975 C13.65975,4.51275 13.65975,4.98775 13.36675,5.28075 L6.52075,12.12775 C6.49075,12.15675 6.49075,12.20575 6.52075,12.23575 L13.36675,19.08275 C13.65975,19.37575 13.65975,19.84975 13.36675,20.14275 C13.22075,20.28975 13.02875,20.36275 12.83675,20.36275"
      />
    </svg>
  );
};

export default ArrowLeft;
