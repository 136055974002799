import { createReducer, createAction } from '@reduxjs/toolkit';

import { builderFactory } from '@common/utils/reduxHelper';

import {
  InitialStateType,
  FetchSeriesPayload,
  FetchViewCounterPayload,
  FetchSeriesChannelPayload
} from './types';

const prefix = '@VODPlusPApp';

export const fetchVODPlusHome = createAction<{ channelId: number }>(
  `${prefix}/FETCH_VOD_PLUS_HOME`
);
export const fetchVODPlusPostById = createAction<{ postId: string; channelId: number }>(
  `${prefix}/FETCH_VOD_PLUS_POST_BY_ID`
);
export const fetchSeriesesLoadMore = createAction<FetchSeriesPayload>(
  `${prefix}/FETCH_SERIESES_LOAD_MORE`
);
export const fetchSeriesesNewLabel = createAction<FetchSeriesPayload>(
  `${prefix}/FETCH_SERIESES_NEW_LABEL`
);
export const fetchSeriesesSearchInput = createAction<FetchSeriesPayload>(
  `${prefix}/FETCH_SERIESES_SEARCH`
);
export const fetchSeriesById = createAction<FetchSeriesChannelPayload>(
  `${prefix}/FETCH_SERIES_BY_ID`
);
export const fetchAddBookmark = createAction<FetchSeriesChannelPayload>(
  `${prefix}/FETCH_ADD_BOOKMARK`
);
export const fetchDeleteBookmark = createAction<FetchSeriesChannelPayload>(
  `${prefix}/FETCH_DELETE_BOOKMARK`
);
export const fetchSeriesByBookmark = createAction<{ channelId: number }>(
  `${prefix}/FETCH_SERIES_BY_BOOKMARK`
);
export const fetchLabels = createAction<{ useCase: string; channelId: number }>(
  `${prefix}/FETCH_LABELS`
);
export const fetchSeriesEpisodes = createAction<FetchSeriesChannelPayload>(
  `${prefix}/FETCH_SERIES_EPISODES`
);
export const fetchVODPlusViewCounter = createAction<FetchViewCounterPayload>(
  `${prefix}/FETCH_VOD_PLUS_VIEW_COUNTER`
);
export const fetchVODPlusToken = createAction<{ channelId: number; vid: number }>(
  `${prefix}/FETCH_VOD_PLUS_TOKEN`
);
export const cleanUpAllData = createAction(`${prefix}/CLEAN_UP_LISTS`);

const initialState: InitialStateType = {
  isLoading: true,
  error: null,
  VODPlusHome: [],
  VODPlusPost: null,
  serieses: [],
  bookmarks: [],
  labels: [],
  series: null,
  episodes: [],
  VODPlusToken: ''
};

const reducer = createReducer(initialState, (builder) => {
  builderFactory(builder, [
    fetchVODPlusHome,
    fetchVODPlusPostById,
    fetchSeriesesLoadMore,
    fetchSeriesesNewLabel,
    fetchSeriesesSearchInput,
    fetchSeriesById,
    fetchSeriesByBookmark,
    fetchLabels,
    fetchSeriesEpisodes,
    fetchVODPlusToken
  ])
    .addCase(`${fetchVODPlusHome}_COMPLETED`, (state, action: any) => {
      state.VODPlusHome = action.payload.data.data.vodPlusHome.sections;
    })
    .addCase(`${fetchVODPlusPostById}_COMPLETED`, (state, action: any) => {
      state.VODPlusPost = action.payload.data.data.post;
    })
    .addCase(`${fetchSeriesesLoadMore}_COMPLETED`, (state, action: any) => {
      state.serieses = [...state.serieses, ...action.payload.data.data.serieses];
    })
    .addCase(`${fetchSeriesesNewLabel}_COMPLETED`, (state, action: any) => {
      state.serieses = [...action.payload.data.data.serieses];
    })
    .addCase(`${fetchSeriesesSearchInput}_COMPLETED`, (state, action: any) => {
      state.serieses = [...action.payload.data.data.serieses];
    })
    .addCase(`${fetchSeriesById}_COMPLETED`, (state, action: any) => {
      state.series = action.payload.data.data.series;
    })
    .addCase(`${fetchSeriesByBookmark}_COMPLETED`, (state, action: any) => {
      state.bookmarks = action.payload.data.data.bookmarks;
    })
    .addCase(`${fetchLabels}_COMPLETED`, (state, action: any) => {
      state.labels = action.payload.data.data.labels;
    })
    .addCase(`${fetchSeriesEpisodes}_COMPLETED`, (state, action: any) => {
      state.episodes = action.payload.data.data.episodes;
    })
    .addCase(`${fetchVODPlusToken}_COMPLETED`, (state, action: any) => {
      const token = action.payload.data;
      state.VODPlusToken = token;
    })
    .addCase(`${cleanUpAllData}`, (state) => {
      state.VODPlusHome = [];
      state.VODPlusPost = null;
      state.serieses = [];
      state.bookmarks = [];
      state.labels = [];
      state.series = null;
      state.episodes = [];
      state.VODPlusToken = '';
    })
    .addMatcher(
      (action) => action.type.startsWith(prefix) && action.type.endsWith('_COMPLETED'),
      (state) => {
        state.isLoading = false;
      }
    )
    .addMatcher(
      (action) => action.type.startsWith(prefix) && action.type.endsWith('_FAILED'),
      (state, action) => {
        state.isLoading = false;
        state.error = action.payload.data.message;
      }
    );
});

export default reducer;
