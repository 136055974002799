import SessionRepository from '@common/repositories/session';

async function checkAccoountAsync(email: string) {
  const { status } = await SessionRepository.checkAccount({ email });

  switch (status) {
    case 204:
      return {
        isValidAccount: true,
        isError: false
      };
    case 404:
      return {
        isValidAccount: false,
        isError: false
      };
    default:
      return {
        isValidAccount: null,
        isError: true
      };
  }
}

export default checkAccoountAsync;
