import { useEffect, useRef } from 'react';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-contrib-quality-levels';
import 'videojs-hls-quality-selector';

import styled from 'styled-components';

declare module 'video.js' {
  interface VideoJsPlayer {
    hlsQualitySelector(options: { displayCurrentQuality: boolean }): void;
  }

  interface HlsQualitySelector {
    hlaQualitySelector?: { displayCurrentQuality: boolean };
  }
}

type Props = {
  src: string;
  token: string;
};

const initialOptions: VideoJsPlayerOptions = {
  controls: true,
  autoplay: true,
  muted: true,
  playbackRates: [0.5, 1, 1.5, 2],
  aspectRatio: '16:9',
  html5: {
    vhs: {
      enableLowInitialPlaylist: true,
      overrideNative: true
    },
    nativeAudioTracks: false,
    nativeVideoTracks: false,
    nativeTextTracks: false
  }
};

const usePlayer = ({ src, token }: Props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const playerRef = useRef<VideoJsPlayer | null>(null);

  useEffect(() => {
    const vjsPlayer = videojs(videoRef.current, initialOptions);

    playerRef.current = vjsPlayer;

    return () => playerRef.current?.dispose();
  }, []);

  useEffect(() => {
    if (!src || !token) return;
    if (!playerRef || !playerRef.current) return;

    playerRef.current.ready(() => {
      playerRef.current?.src({
        src: `${src}?token=${token}`,
        type: 'application/x-mpegURL'
      });

      // TODO: check hls quality selector bug
      // playerRef.current?.hlsQualitySelector({
      //   displayCurrentQuality: true
      // });
    });
  }, [src, token]);

  return videoRef;
};

const HLSPlayer = ({ src, token }: Props) => {
  const playerRef = usePlayer({ src, token });

  return (
    <div>
      <div data-vjs-player>
        <S_Player
          ref={playerRef}
          className="video-js vjs-default-skin vjs-big-play-centered"
          height="auto"
          width="auto"
          playsInline
        />
      </div>
    </div>
  );
};

const S_Player = styled.video`
  border: 0;
  width: 100%;

  &.vjs-caption-settings {
    display: none;
  }

  &::-webkit-media-controls-timeline {
    display: none;
  }

  &::-webkit-media-controls-current-time-display {
    display: none;
  }

  &::-webkit-media-controls-time-remaining-display {
    display: none;
  }
`;

export default HLSPlayer;
