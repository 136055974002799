import { useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import { makeGetCurrentChannel, makeGetChannelDescParams } from '@common/redux/modules/channel';
import { getSessionError, makeGetIsAuthenticated } from '@common/redux/modules/session';
import { signIn, signInAuthenticating } from '@common/redux/modules/session/reducers';
import { accountPath } from '@routers/routes';

import useModalDialog from '../../../../common/hooks/useModalDialog';

import { SignInView } from './templates';

type CallbackType = (...args: string[]) => void;

function SignInContainer() {
  const dispatch = useDispatch();
  const history = useHistory();

  const getIsAuthenticated = useMemo(makeGetIsAuthenticated, []);
  const isAuthenticated = useSelector(getIsAuthenticated);

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const getChannelDescParams = useMemo(makeGetChannelDescParams, []);
  const channelDescParams = useSelector(getChannelDescParams);

  const sessionError = useSelector(getSessionError);

  const { t } = useTranslation('translation');
  const { ModalDialog, setIsModalDialogOpen, setModalDialogMessage } = useModalDialog();

  useEffect(() => {
    if (sessionError) {
      setIsModalDialogOpen(true);
      setModalDialogMessage(
        t('str_err_999', {
          errCode: sessionError.code,
          defaultValue: '이메일과 비밀번호를 다시 확인해 주세요. ({{errCode}})'
        })
      );

      return;
    }

    setIsModalDialogOpen(false);
  }, [sessionError]);

  const handleClickSignIn = useCallback<CallbackType>(
    (values: any) => {
      dispatch(signInAuthenticating());
      dispatch(
        signIn({
          email: values.email,
          password: values.password
        })
      );
    },
    [dispatch]
  );

  if (isAuthenticated) {
    if (channelDescParams) {
      return <Redirect to={`/channels/${channelDescParams.invitationCode}`} />;
    }
    return <Redirect to={accountPath} />;
  }

  return (
    <>
      <SignInView
        currentChannel={currentChannel}
        onSignIn={handleClickSignIn}
        onResetPw={() => history.push('/resetpw')}
        onSignUp={() => history.push('/signup')}
      />
      {sessionError && <ModalDialog />}
    </>
  );
}

export default SignInContainer;
