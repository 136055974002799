import React from 'react';
import styled from 'styled-components';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { Form, SubmitButton, InputFieldButton, Icon } from '@common/ui/components';
import { useTranslation } from 'react-i18next';
import { Select } from '../atoms';

type SignUpAddInfoFormProps = {
  onSignUpAddInfo: Function;
};

interface IFormInput {
  nickname: string;
  birthYear: number;
  gender: string;
}

/**
 * 추가 정보 폼
 */
function SignUpAddInfoForm({ onSignUpAddInfo }: SignUpAddInfoFormProps) {
  const { t: translate } = useTranslation('translation');

  const methods = useForm<IFormInput>({ mode: 'onChange' });
  const {
    handleSubmit,
    register,
    formState: { isValid, errors }
  } = methods;

  const handleSignUpAddInfo: SubmitHandler<IFormInput> = (data) => onSignUpAddInfo(data);
  const t = (key: string, defaultValue: string) => translate(key, defaultValue) as string;

  /**
   * 출생년도 객체 생성 함수
   */
  const generateYearItems = () => {
    const yearItems = [];
    const startYear = 1900;
    const lastYear = new Date().getFullYear();

    for (let i = lastYear; i >= startYear; i -= 1) {
      yearItems.push(i);
    }

    return yearItems;
  };

  const nicknameValidation = {
    minLength: {
      value: 2,
      message: t('str_too_short', '너무 짧습니다.')
    },
    maxLength: {
      value: 12,
      message: t('str_934', '최소 2자, 최대 12자까지 입력 가능합니다.')
    },
    pattern: {
      value: /^[ㄱ-ㅎㅏ-ㅣ가-힣A-Za-z0-9]+$/,
      message: t('str_err_1016', '올바르지 않은 닉네임입니다.')
    }
  };

  return (
    <S_Form>
      <S_Title>{t('str_input_profile_info', '프로필 정보 입력')}</S_Title>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(handleSignUpAddInfo)}>
          <S_ProfileImgWrapper>
            <Icon iconName="ic_PostSocial" size={64} colorCode="blue500" fill />
            <S_CameraIconWrapper>
              <Icon iconName="ic_Camera" size={16} colorCode="grey500" fill />
            </S_CameraIconWrapper>
          </S_ProfileImgWrapper>
          <S_InputWrapper>
            <S_InputFieldWrapper hasError={!!errors?.nickname}>
              <InputFieldButton
                name="nickname"
                placeholder={t('str_nickname_hint', '닉네임')}
                maxLength={12}
                validation={nicknameValidation}
              />
            </S_InputFieldWrapper>
            <Select
              name="gender"
              isRequired
              register={register}
              placeholder={t('str_gender', '성별')}
              options={[
                t('str_gender_man', '남성'),
                t('str_gender_woman', '여성'),
                t('str_gender_others', '선택하지 않음')
              ]}
              validation={{ required: true }}
            />
            <Select
              name="birthYear"
              isRequired
              register={register}
              placeholder={t('str_birth_year', '출생 연도')}
              options={generateYearItems()}
              validation={{ required: true }}
            />
          </S_InputWrapper>
          <S_SubmitButton>
            <SubmitButton full disabled={!isValid} label={t('str_do_signup', '가입하기')} />
          </S_SubmitButton>
        </Form>
      </FormProvider>
    </S_Form>
  );
}

const S_Form = styled.div`
  height: 100%;
  padding-left: 24px;
  padding-right: 24px;
  text-align: left;
  user-select: none;
`;

const S_Title = styled.div`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeH2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  margin-bottom: 24px;
  margin-top: 24px;
  text-align: left;
`;

const S_SubmitButton = styled.div`
  margin-top: 24px;
`;

const S_InputWrapper = styled.div`
  & > div {
    margin-top: 16px;
  }
`;

const S_ProfileImgWrapper = styled.div`
  background-color: ${({ theme }) => theme.grey50};
  border-radius: 50%;
  display: flex;
  height: 96px;
  margin: auto;
  position: relative;
  width: 96px;

  & > svg {
    margin: auto;
  }
`;

const S_CameraIconWrapper = styled.div`
  background-color: ${({ theme }) => theme.white};
  border: solid 1px ${({ theme }) => theme.grey100};
  border-radius: 50%;
  bottom: 0;
  display: flex;
  height: 32px;
  position: absolute;
  right: -10px;
  width: 32px;

  & > svg {
    margin: auto;
  }
`;

const S_InputFieldWrapper = styled.div<{ hasError: boolean }>`
  & > div {
    margin-bottom: ${({ hasError }) => hasError && '36px'};
  }
`;

export default SignUpAddInfoForm;
