import { put, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { SocialCommunityPAppRepository } from '../../../../repositories';
import {
  fetchDetail,
  fetchList,
  addPost,
  removePost,
  editPost,
  likePost,
  dislikePost,
  increaseViewCount
} from '../reducers';
import { digestResponse } from '../../../middlewares/response';

export function* watchFetchList(action: ReturnType<typeof fetchList>) {
  const { type, payload } = action;
  const { limit, page, channelId } = payload;

  const response: AxiosResponse = yield call(() =>
    SocialCommunityPAppRepository.findAllPosts({ limit, page }, channelId)
  );

  yield put(digestResponse(response, type));
}

export function* watchFetchDetail(action: ReturnType<typeof fetchDetail>) {
  const { type, payload } = action;
  const { postId, channelId } = payload;

  const response: AxiosResponse = yield call(() =>
    SocialCommunityPAppRepository.findPostById({ postId }, channelId)
  );

  yield put(digestResponse(response, type));
}

export function* watchAddPost(action: ReturnType<typeof addPost>) {
  const { type, payload } = action;
  const { channelId, ...rest } = payload;

  const response: AxiosResponse = yield call(() =>
    SocialCommunityPAppRepository.createPost(rest, channelId)
  );

  yield put(digestResponse(response, type));
}

export function* watchEditPost(action: ReturnType<typeof editPost>) {
  const { type, payload } = action;
  const { channelId, postId, ...rest } = payload;

  const response: AxiosResponse = yield call(() =>
    SocialCommunityPAppRepository.updatePost(postId, rest, channelId)
  );

  yield put(digestResponse(response, type));
}

export function* watchDeletePost(action: ReturnType<typeof removePost>) {
  const { type, payload } = action;
  const { postId, channelId } = payload;

  const response: AxiosResponse = yield call(() =>
    SocialCommunityPAppRepository.removePost(postId, channelId)
  );

  yield put(digestResponse(response, type));
}

export function* watchLikePost(action: ReturnType<typeof likePost>) {
  const { type, payload } = action;
  const { channelId, postId, isLiked } = payload;

  const response: AxiosResponse = yield call(() =>
    SocialCommunityPAppRepository.likePost(channelId, postId, isLiked)
  );

  yield put(digestResponse(response, type));
}

export function* watchDislikePost(action: ReturnType<typeof dislikePost>) {
  const { type, payload } = action;
  const { channelId, postId, isDisliked } = payload;

  const response: AxiosResponse = yield call(() =>
    SocialCommunityPAppRepository.dislikePost(channelId, postId, isDisliked)
  );

  yield put(digestResponse(response, type));
}

export function* watchCountHits(action: ReturnType<typeof increaseViewCount>) {
  const { type, payload } = action;
  const { channelId, resourceId } = payload;

  const response: AxiosResponse = yield call(() =>
    SocialCommunityPAppRepository.countHits(channelId, resourceId)
  );

  yield put(digestResponse(response, type));
}
