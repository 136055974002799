import React from 'react';

import styled from 'styled-components';

type BookingViewProps = {
  nav: React.ReactNode;
  children: React.ReactNode;
};

function BookingView({ nav, children }: BookingViewProps) {
  return (
    <S_BookingViewContainer>
      {nav}
      {children}
    </S_BookingViewContainer>
  );
}

const S_BookingViewContainer = styled.div`
  min-height: 100vh;
  position: relative;
`;

export default BookingView;
