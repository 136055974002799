import styled, { css } from 'styled-components';

import CancelButton from './CancelButton';

type Props = {
  src: string;
  hasDeleteButton?: boolean;
  onDelete?: (id?: string) => void;
  thumbnailSrc: string;
};

function Video({ src, hasDeleteButton, onDelete, thumbnailSrc }: Props) {
  return (
    <S_VideoWrapper hasDeleteButton={hasDeleteButton}>
      <S_Video src={src} controls poster={thumbnailSrc} />
      {hasDeleteButton && onDelete && <CancelButton onDelete={onDelete} />}
    </S_VideoWrapper>
  );
}

const S_VideoWrapper = styled.div<{
  hasDeleteButton?: boolean;
}>`
  padding-bottom: 56.25%;
  position: relative;

  ${({ hasDeleteButton }) =>
    hasDeleteButton &&
    css`
      border-radius: 24px;
      overflow: hidden;
    `}
`;

const S_Video = styled.video`
  border: none;
  height: auto;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
`;

export default Video;
