import jwtDecode from 'jwt-decode';
import Base64 from 'crypto-js/enc-base64';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import { UserToken } from '@common/types/token';

const JOSE_HEADER = {
  alg: 'HS256',
  typ: 'JWT'
};
const SAMPLE_JWT_SIGNATURE = 'your-256-bit-secret';

class JWT {
  private static safeJwtDecode(token: string): { jwtData?: any; jwtError?: any } {
    try {
      const jwtData = jwtDecode(token);

      return { jwtData };
    } catch (error) {
      return { jwtError: error };
    }
  }

  private static isJwtTokenExpired(jwtData: UserToken) {
    const { exp } = jwtData;

    return Date.now() / 1000 + 15 > exp;
  }

  static willJwtTokenBeExpired(jwtData: UserToken, threshold: number) {
    const { exp } = jwtData;

    return Date.now() / 1000 + threshold > exp;
  }

  /**
   * 채널 사용 가능 여부
   */
  static isAvailableChannels() {
    const [tokenStatus, jwtData] = this.validateJwtToken(
      localStorage.getItem(process.env.REACT_APP_KEY_ACCESS_TOKEN || '')
    );

    if (tokenStatus === 'ERROR') {
      return false;
    }
    return Object.keys(jwtData.user.availableChannels).length > 0;
  }

  /**
   * 사용 가능 채널 가져오기
   *
   * @returns [ channelId: { activePApps{...}, sellerMeta{...}, status: "NORMAL" }, ... ]
   */
  static getAvailableChannels() {
    const [tokenStatus, token, jwtData] = this.validateJwtToken(
      localStorage.getItem(process.env.REACT_APP_KEY_ACCESS_TOKEN || '')
    );

    if (tokenStatus === 'ERROR') {
      return token;
    }
    return jwtData.user.availableChannels;
  }

  /**
   * 사용 가능 채널 키만 가져오기
   *
   * @returns [ "key", ... ]
   */
  static getAvailableChannelKeys() {
    const [tokenStatus, token, jwtData] = this.validateJwtToken(
      localStorage.getItem(process.env.REACT_APP_KEY_ACCESS_TOKEN || '')
    );

    if (tokenStatus === 'ERROR') {
      return token;
    }
    return Object.keys(jwtData.user.availableChannels);
  }

  /**
   * 해당 팹 정보 가져오기
   *
   * @param channelId 채널 아이디
   * @param pAppCode pApp 코드
   *
   * @returns [ alias, grantedAbilities[], id, name ]
  }
   */
  static getPAppInfo(channelId: string | number, pAppCode: string) {
    const [tokenStatus, token, jwtData] = this.validateJwtToken(
      localStorage.getItem(process.env.REACT_APP_KEY_ACCESS_TOKEN || '')
    );
    if (tokenStatus === 'ERROR') {
      return token;
    }

    if (!jwtData.user.availableChannels[channelId]) {
      return false;
    }
    return jwtData.user.availableChannels[channelId].activePApps[pAppCode];
  }

  /**
   * 해당 채널 헤더 코드 가져오기
   *
   * @param channelId 채널 아이디
   * @param pAppCode pApp 코드
   *
   * @returns PUBL-{channelId}-{pAppCode}-{pAppId}
   */
  static getChannelHeader(channelId: string | number, pAppCode: string) {
    const [tokenStatus, token] = this.validateJwtToken(
      localStorage.getItem(process.env.REACT_APP_KEY_ACCESS_TOKEN || '')
    );

    if (tokenStatus === 'ERROR') {
      return token;
    }

    const pAppInfo = this.getPAppInfo(channelId, pAppCode);

    let pAppId = 1;
    if (pAppInfo) {
      pAppId = pAppInfo.id;
    }

    return `PUBL-${channelId}-${pAppCode}-${pAppId}`;
  }

  /**
   * 해당 채널의 pApp 정보
   */
  static getChannelInfo(channelId: number) {
    const [tokenStatus, , jwtData] = this.validateJwtToken(
      localStorage.getItem(process.env.REACT_APP_KEY_ACCESS_TOKEN || '')
    );
    if (tokenStatus === 'ERROR') {
      return false;
    }
    return {
      activePApps: jwtData.user.availableChannels[channelId]?.activePApps,
      status: jwtData.user.availableChannels[channelId]?.status
    };
  }

  /**
   * 채널에서의 유저 프로필 정보
   */
  static getUserProfileInChannel(channelId: number) {
    const [tokenStatus, , jwtData] = this.validateJwtToken(
      localStorage.getItem(process.env.REACT_APP_KEY_ACCESS_TOKEN || '')
    );
    if (tokenStatus === 'ERROR') {
      return false;
    }
    return jwtData.user.availableChannels[channelId].profile;
  }

  /**
   * 채널에서의 유저 메타 정보
   */
  static getUserMetaInChannel(channelId: number) {
    const [tokenStatus, , jwtData] = this.validateJwtToken(
      localStorage.getItem(process.env.REACT_APP_KEY_ACCESS_TOKEN || '')
    );
    if (tokenStatus === 'ERROR') {
      return null;
    }
    return {
      profileId: jwtData.user.availableChannels[channelId].profile.id,
      status: 'NORMAL'
    };
  }

  /**
   * 퍼블 서비스에 대한 사용자 프로필
   */
  static getPublUserInfo() {
    const [tokenStatus, token, jwtData] = this.validateJwtToken(
      localStorage.getItem(process.env.REACT_APP_KEY_ACCESS_TOKEN || '')
    );
    if (tokenStatus === 'ERROR') {
      return token;
    }
    return {
      email: jwtData.user.email,
      userId: jwtData.user.id,
      mainProfile: {
        id: jwtData.user.mainProfile.id
      },
      status: jwtData.user.status
    };
  }

  /**
   * channel Profile
   */
  static getChannelProfile(channelId: number) {
    const [tokenStatus, , jwtData] = this.validateJwtToken(
      localStorage.getItem(process.env.REACT_APP_KEY_ACCESS_TOKEN || '')
    );
    if (tokenStatus === 'ERROR') {
      return false;
    }
    return {
      id: jwtData.user.availableChannels[channelId].profile.id,
      age: jwtData.user.availableChannels[channelId].profile.age,
      birthYear: jwtData.user.availableChannels[channelId].profile.birthYear,
      gender: jwtData.user.availableChannels[channelId].profile.gender,
      nickname: jwtData.user.availableChannels[channelId].profile.nickname,
      imageSrc: jwtData.user.availableChannels[channelId].profile.imageSrc
    };
  }

  static validateJwtToken(token: string | null): any[] {
    if (!token) {
      return ['ERROR', 'TOKEN_NOT_EXIST'];
    }

    const { jwtError, jwtData } = this.safeJwtDecode(token);

    if (jwtError) {
      return ['ERROR', 'INVALID_TOKEN'];
    }

    if (this.isJwtTokenExpired(jwtData as UserToken)) {
      return ['ERROR', 'TOKEN_EXPIRED'];
    }

    return ['OK', token, jwtData];
  }

  static genLocalJwtToken(payload: any) {
    const base64UrlEncodedHeader = Buffer.from(JSON.stringify(JOSE_HEADER))
      .toString('base64')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');

    const base64UrlEncodedPayload = Buffer.from(JSON.stringify(payload))
      .toString('base64')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');

    const headerAndClaimSet = `${base64UrlEncodedHeader}.${base64UrlEncodedPayload}`;

    const verifySignature = Base64.stringify(hmacSHA256(headerAndClaimSet, SAMPLE_JWT_SIGNATURE))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');

    return `${headerAndClaimSet}.${verifySignature}`;
  }
}

export default JWT;
