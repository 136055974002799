/* eslint-disable */
import { TimestampUUID } from '../utils/index.js';

class SystemReceipt {
  constructor(systemReceipt) {
    const {
      id,
      srid,
      siid,
      action,
      status,
      name,
      price,
      balance,
      email,
      issuer,
      recipient,
      issuedAt,
      systemInvoice
    } = systemReceipt;

    this.id = id;
    this.srid = srid;
    this.siid = siid;
    this.action = action;
    this.status = status;
    this.name = name;
    this.price = price;
    this.balance = balance;
    this.email = email;
    this.issuer = issuer;
    this.recipient = recipient;
    this.issuedAt = issuedAt;

    this.systemInvoice = systemInvoice;
  }

  setId(id) {
    this.id = id;
  }

  static fromOrder(order, action) {
    const now = new Date();
    const srid = TimestampUUID.new('SR', now);
    const siid = order.siid;
    const status =
      action === 'ON_CREATION' ? 'CREATED' : order.isSubscription ? 'RECURRING_DELEGATED' : 'PAID';
    const name = order.systemInvoice.name;
    const price = {
      currency: 'KRW', // fixed
      amount: order.amount
    };
    const balance = {
      currency: 'KRW',
      amount: action === 'ON_CREATION' ? 0 : order.amount
    };
    const email = order.email;

    return new SystemReceipt({
      srid,
      siid,
      action,
      status,
      name,
      price,
      balance,
      email,
      issuer: order.systemInvoice.issuer,
      recipient: order.systemInvoice.recipient,
      issuedAt: now.toISOString(),
      systemInvoice: order.systemInvoice
    });
  }

  toJSON() {
    return {
      id: this.id,
      srid: this.srid,
      siid: this.siid,
      action: this.action,
      status: this.status,
      name: this.name,
      price: this.price,
      balance: this.balance,
      email: this.email,
      issuer: this.issuer,
      recipient: this.recipient,
      issuedAt: this.issuedAt,
      systemInvoice: this.systemInvoice
    };
  }
}

export default SystemReceipt;
