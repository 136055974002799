import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StyledNamespace } from '@common/types/styledNamespace';
import { ScrollView } from '@common/ui/components/ScrollView';
import { TitleBlock, BodyBlock, FooterBlock } from './molecules';
import Modal from '../Modal';

const S: StyledNamespace = {};

type ModalDialogProps = {
  title?: string;
  showCloseButton?: boolean;
  isOpen: boolean;
  disableBackdropClick?: boolean;
  isLine?: boolean;
  onClose?: Function;
  footer?: React.ReactNode;
  children?: React.ReactNode;
};
/**
 * 모달 다이얼로그
 *
 * @param {Object} props
 * @param {string} props.title 다이얼로그 제목
 * @param {boolean} props.showCloseButton 닫기 버튼 보이기 여부
 * @param {boolean} props.isOpen 모달 열기 여부
 * @param {boolean} props.disableBackdropClick 모달 바깥 클릭 종료 여부
 * @param {boolean} props.isLine 하단 라이 표시 여부
 * @param {function} props.onClose 모달 닫기 콜백
 * @param {any} props.footer 모달 footer
 */
function ModalDialog({
  title,
  showCloseButton = true,
  isOpen = false,
  disableBackdropClick = false,
  isLine = false,
  onClose,
  footer,
  children
}: ModalDialogProps) {
  return (
    <S.ModalDialog isOpen={isOpen} onClose={onClose} disableBackdropClick={disableBackdropClick}>
      {!!title && <TitleBlock title={title} showCloseButton={showCloseButton} onClose={onClose} />}
      <ScrollView style={{ padding: 24 }}>
        <BodyBlock>{children}</BodyBlock>
      </ScrollView>
      {!!footer && <FooterBlock isLine={isLine}>{footer}</FooterBlock>}
    </S.ModalDialog>
  );
}

ModalDialog.defaultProps = {
  title: '',
  isOpen: false,
  disableBackdropClick: false,
  isLine: false,
  footer: null,
  showCloseButton: true
};

ModalDialog.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  isLine: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  footer: PropTypes.node,
  children: PropTypes.node.isRequired
};

S.ModalDialog = styled(Modal)`
  background-color: ${({ theme }) => theme.white};
  border-radius: 0.5rem;
  border-width: 1px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 48px;
  max-height: calc(100% - 96px);
  width: 100%;
`;

S.Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizeBody1};
  font-weight: ${({ theme }) => theme.fontWeightBold};
`;

S.CloseButton = styled.div`
  background-color: ${({ theme }) => theme.white};
  border: 0;
  font-size: ${({ theme }) => theme.fontSizeBody1};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  outline: 0;
  padding: 0;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }
`;

export default ModalDialog;
