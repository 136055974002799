import IconType from '../IconType';

const PostCreate = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M12.683699,3.5 L11.1214619,5.03461407 L6.33653518,5.03461407 L6.17900678,5.03992794 C4.98172673,5.12096809 4.03461407,6.11881892 4.03461407,7.33653518 L4.03461407,7.33653518 L4.03461407,17.6634648 L4.03992794,17.8211107 C4.12096809,19.0192129 5.11881892,19.9653859 6.33653518,19.9653859 L6.33653518,19.9653859 L16.6634648,19.9653859 L16.8211107,19.9600772 C18.0192129,19.8791136 18.9653859,18.8821615 18.9653859,17.6634648 L18.9653859,17.6634648 L18.9653859,12.5879845 L20.5,11.0809935 L20.5,17.6634648 C20.5,19.7822553 18.7822553,21.5 16.6634648,21.5 L16.6634648,21.5 L6.33653518,21.5 C4.21774469,21.5 2.5,19.7822553 2.5,17.6634648 L2.5,17.6634648 L2.5,7.33653518 C2.5,5.21774469 4.21774469,3.5 6.33653518,3.5 L6.33653518,3.5 L12.683699,3.5 Z M16.3616685,2.87668073 C17.1397476,2.11241598 18.3866865,2.11244892 19.1647252,2.87675478 L19.1647252,2.87675478 L21.0478155,4.72660746 C21.0562844,4.73492687 21.0646791,4.74332153 21.0729985,4.75179043 C21.847062,5.53976214 21.8357872,6.80604191 21.0478155,7.58010544 L21.0478155,7.58010544 L13.853732,14.6472091 C13.7015439,14.7967109 13.5047019,14.8933947 13.2923677,14.9238322 L13.2923677,14.9238322 L9.26804197,15.4931925 C8.84063963,15.5531723 8.46700431,15.2085124 8.50254523,14.7859683 L8.50254523,14.7859683 L8.85339791,10.5811173 C8.87162402,10.3501504 8.97369025,10.133507 9.14045918,9.96968168 L9.14045918,9.96968168 Z"
      />
    </svg>
  );
};

export default PostCreate;
