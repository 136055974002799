import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';

import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import { getPlaylists } from '@common/redux/modules/VODPApp';
import { fetchPlaylists, cleanUpAllData } from '@common/redux/modules/VODPApp/reducers';
import { SmartLink } from '@common/ui/components';
import { PLAYLIST_DEFAULT_LIMIT, PLAYLIST_NEXT_LIMIT } from '@common/constants/index';
import styled from 'styled-components';

import { buildVodPlaylistDetailPath, buildChannelsPath } from '@routers/routes';
import { TopBar } from '../../components/organisms';

import { PlaylistCardItem } from './molecules';

function Playlists() {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const [channelId, setChannelId] = useState(0);
  const [channelCode, setChannelCode] = useState('');
  const [lastPlaylistId, setLastPlaylistId] = useState(0);

  const playlists = useSelector(getPlaylists);

  useEffect(() => {
    if (!currentChannel) return;

    setChannelCode(currentChannel.primitiveInvitationCode);
    setChannelId(currentChannel.id);
  }, [currentChannel]);

  useEffect(() => {
    if (!channelId) return;

    lastPlaylistId
      ? dispatch(
          fetchPlaylists({
            limit: PLAYLIST_NEXT_LIMIT,
            cursor: lastPlaylistId,
            channelId
          })
        )
      : dispatch(fetchPlaylists({ limit: PLAYLIST_DEFAULT_LIMIT, channelId }));
  }, [dispatch, lastPlaylistId, channelId]);

  useEffect(
    () => () => {
      dispatch(cleanUpAllData());
    },
    [dispatch]
  );

  const handleScrollDown = () => {
    setLastPlaylistId(playlists[playlists.length - 1].id);
  };

  return (
    <>
      <TopBar
        isMenu
        title={t('str_papp_vod_board_post', 'VOD 게시글')}
        backUrl={buildChannelsPath(channelCode)}
        channelCode={channelCode}
        hasTabBar
      />
      <S_EmptyDiv />
      {playlists.length === 0 ? (
        <S_NoItem>{t('str_427', '아직 업로드한 재생목록이 없습니다.')}</S_NoItem>
      ) : (
        <S_ScrollField
          dataLength={playlists.length}
          next={handleScrollDown}
          hasMore={playlists[playlists.length - 1].id !== lastPlaylistId}
        >
          {playlists.map(({ id, coverImageSrc, insertedAt, title, totalEpisodes }) => (
            <SmartLink key={id} to={buildVodPlaylistDetailPath(channelCode, id)}>
              <PlaylistCardItem
                thumbnailSrc={coverImageSrc}
                createdAt={insertedAt}
                totalCount={totalEpisodes}
                title={title}
              />
            </SmartLink>
          ))}
        </S_ScrollField>
      )}
    </>
  );
}

const S_EmptyDiv = styled.div`
  height: 104px;
`;

const S_ScrollField = styled(InfiniteScroll as any)`
  margin-bottom: 24px;
`;

const S_NoItem = styled.div`
  color: ${({ theme }) => theme.grey400};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  margin-top: 96px;
`;

export default Playlists;
