import styled from 'styled-components';

import { SquareImage, Title } from '../atoms';

type ChannelProfileProp = {
  channelInfo: {
    imgSrc: string;
    title: string;
  };
};

export default function ChannelProfile({ channelInfo }: ChannelProfileProp) {
  const { imgSrc, title } = channelInfo;
  return (
    <S_ChannelProfile>
      <SquareImage src={imgSrc} alt={title} />
      <S_TitleWrapper>
        <Title text={title} />
      </S_TitleWrapper>
    </S_ChannelProfile>
  );
}

const S_ChannelProfile = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const S_TitleWrapper = styled.div`
  margin-top: 24px;
  max-width: 65%;
`;
