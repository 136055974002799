import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { InputFieldButton, InputFieldContainer, Form, Button, Icon } from '@common/ui/components';
import { COMMENT_MAX_LENGTH } from '@common/constants/index';

type FormField = {
  noticeComment: string;
};

type Props = {
  handleAddComment: (data: any) => void;
};

function CommentInputControl({ handleAddComment }: Props) {
  const { t } = useTranslation('translation');

  const [showIcon, setIcon] = useState(false);
  const [hasTarget, setTarget] = useState(false);

  const methods = useForm<FormField>();
  const { handleSubmit, watch, setValue } = methods;

  const inputValue = watch('noticeComment');

  const validationOptions = {
    maxLength: {
      value: COMMENT_MAX_LENGTH,
      message: '댓글은 최대 800자 까지 가능합니다.'
    }
  };

  const onSubmit = (data: FormField) => {
    if (hasTarget) {
      setIcon(false);
      setTarget(false);

      handleAddComment({ content: data.noticeComment });
      setValue('noticeComment', '');
      return;
    }

    setIcon(true);
    handleAddComment({ content: data.noticeComment });
    setValue('noticeComment', '');
  };

  const handleIcon = (isFocused: boolean) => {
    isFocused ? setIcon(true) : setIcon(false);
  };

  const handleTarget = () => {
    setTarget(true);
  };

  return (
    <S_CommentInputControl hasIcon={showIcon}>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)} displayButton={showIcon}>
          <InputFieldContainer comment rightIcon={showIcon}>
            <InputFieldButton
              name="noticeComment"
              onIcon={handleIcon}
              onTarget={handleTarget}
              placeholder={t('str_73', '댓글 입력..')}
              maxLength={COMMENT_MAX_LENGTH}
              validation={validationOptions}
            />
            {showIcon && (
              <S_Button
                line
                type={inputValue ? 'submit' : 'button'}
                disabled={!inputValue}
                inputIcon
                icon={
                  <Icon iconName="ic_PaperPlane" colorCode={inputValue ? 'blue500' : 'grey400'} />
                }
              />
            )}
          </InputFieldContainer>
        </Form>
      </FormProvider>
    </S_CommentInputControl>
  );
}

const S_Button = styled(Button)`
  margin-left: 8px;
`;

const S_CommentInputControl = styled.div<{
  hasIcon: boolean;
}>`
  margin-top: 16px;
`;

export default CommentInputControl;
