import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

type DescBlockProps = {
  description?: string;
};

function DescBlock({ description }: DescBlockProps) {
  const { t } = useTranslation('translation');

  return (
    <S_DescBlock>
      <S_Bar />
      <S_Title>{t('str_channel_info', '채널 정보')}</S_Title>
      <S_Description>{!description ? '채널 정보가 없습니다.' : description}</S_Description>
    </S_DescBlock>
  );
}

const S_DescBlock = styled.div`
  background-color: ${({ theme }) => theme.white};
  height: 100%;
  min-height: inherit;
  padding: 24px;
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`;

const S_Bar = styled.div`
  background-color: ${({ theme }) => theme.grey100};
  height: 1px;
  margin-bottom: 24px;
  width: 100%;
`;

const S_Title = styled.div`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  line-height: ${({ theme }) => theme.fontLineHeight};
  margin-bottom: 16px;
  text-align: left;
`;

const S_Description = styled.div`
  color: ${({ theme }) => theme.grey500};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  line-height: ${({ theme }) => theme.fontLineHeight};
  padding-bottom: 300px;
  text-align: left;
  white-space: pre-line;
`;

export default DescBlock;
