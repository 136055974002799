import React from 'react';
import styled from 'styled-components';

type titleProps = {
  text: string;
};

export default function Title({ text }: titleProps) {
  return <S_Title>{text}</S_Title>;
}

const S_Title = styled.div`
  color: ${({ theme }) => theme.black};
  font-size: ${({ theme }) => theme.fontSizeH2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  line-height: ${({ theme }) => theme.fontLineHeight};
  word-break: keep-all;
  text-align: center;
`;
