import React from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { openSideMenu } from '@common/redux/modules/channel/reducers';

import { SmartLink, Icon } from '@common/ui/components';

type Props = {
  title?: string;
  isBack?: boolean;
  isMenu?: boolean;
  backUrl?: string;
  iconLinkUrl?: string;
  icon?: React.ReactElement;
};

function NavBar({ title, isBack, backUrl, iconLinkUrl, icon, isMenu }: Props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleBack = () => {
    if (backUrl) {
      history.push(backUrl);
      return;
    }

    history.goBack();
  };

  function openMenu() {
    dispatch(openSideMenu());
  }

  return (
    <S_NavBar isBack={isBack}>
      {isMenu && (
        <S_Button
          onClick={() => {
            openMenu();
          }}
        >
          <Icon iconName="ic_Menu" size={24} />
        </S_Button>
      )}
      {isBack && (
        <S_Button onClick={handleBack}>
          <Icon iconName="ic_ArrowLeft" />
        </S_Button>
      )}
      {!isBack && !isMenu && <S_Empty />}

      {title && <S_Title>{title}</S_Title>}

      {iconLinkUrl ? (
        <SmartLink to={iconLinkUrl}>
          <S_Button>{icon}</S_Button>
        </SmartLink>
      ) : (
        <S_Empty />
      )}
    </S_NavBar>
  );
}

const S_NavBar = styled.div<{ isBack?: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  height: 56px;
  justify-content: space-between;
  left: 0;
  padding-left: 8px;
  padding-right: 8px;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  transition: transform 0.4s;
  z-index: 4;
`;

const S_Button = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  width: 48px;

  &:hover {
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
`;

const S_Title = styled.div`
  color: ${({ theme }) => theme.black};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  line-height: ${({ theme }) => theme.fontLineHeight};
  text-align: center;
`;

const S_Empty = styled.div`
  height: 48px;
  width: 48px;
`;

export default NavBar;
