import { useTranslation } from 'react-i18next';

import DateHelper from '@common/utils/dateHelper';
import styled from 'styled-components';

export type Props = {
  id: number;
  isAuthor: boolean;
  content: string;
  avatarUrl: string;
  nickname: string;
  createdAt: string;
  handleClickDeleteButton: (id: number) => void;
};

function PostDetailCommentItem({
  id,
  isAuthor,
  content,
  createdAt,
  avatarUrl,
  nickname,
  handleClickDeleteButton
}: Props) {
  const { t } = useTranslation('translation');

  return (
    <S_PostDetailCommentItem>
      <S_ImageWrapper>
        <S_Image src={avatarUrl} alt="Publishing Kit" />
      </S_ImageWrapper>

      <S_Comment>
        <S_CommentHeader>
          <S_InfoBox>
            <S_Name>{nickname}</S_Name>
            <S_Date>{`· ${DateHelper.fromNow(createdAt)}`}</S_Date>
          </S_InfoBox>
          {isAuthor && (
            <S_Delete onClick={() => handleClickDeleteButton(id)}>
              {t('str_delete', '삭제')}
            </S_Delete>
          )}
        </S_CommentHeader>

        <S_Contents>{content}</S_Contents>
      </S_Comment>
    </S_PostDetailCommentItem>
  );
}

const S_PostDetailCommentItem = styled.div`
  display: flex;
  margin-bottom: 24px;
  padding: 0 24px;
  text-align: left;

  &:first-child {
    margin-top: 16px;
  }
`;

const S_ImageWrapper = styled.div`
  margin-right: 12px;
  width: 40px;
`;

const S_Image = styled.img`
  border-radius: 20px;
  height: 40px;
  width: 40px;
`;

const S_Comment = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`;

const S_CommentHeader = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  position: relative;
`;

const S_InfoBox = styled.span`
  height: 100%;
  position: relative;
`;

const S_Date = styled.span`
  color: ${({ theme }) => theme.grey400};
  font-size: ${({ theme }) => theme.fontSizeCaption1};
`;

const S_Name = styled(S_Date)`
  color: ${({ theme }) => theme.grey900};
  margin: 0 4px 0 0;
`;

const S_Delete = styled(S_Date)`
  color: ${({ theme }) => theme.grey500};
  text-align: right;
`;

const S_Contents = styled.div`
  background: ${({ theme }) => theme.grey500};
  background-color: ${({ theme }) => theme.grey50};
  border-radius: 8px;
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  line-height: ${({ theme }) => theme.fontLineHeight};
  margin: 8px 0;
  padding: 8px 12px;
`;

export default PostDetailCommentItem;
