import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { getCheckBookingAvailable } from '@common/redux/modules/booking';
import { responseStatusReset } from '@common/redux/modules/booking/reducers';

import { buildBookingDescPath } from '@routers/routes';
import { Splash } from '../../../Splash';

type StationProps = {
  channelID: string;
};

function Station({ channelID }: StationProps) {
  const location = useLocation<{ id: string; duration: number }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const responseStatus = useSelector(getCheckBookingAvailable);

  const { id, duration } = location.state;

  useEffect(() => {
    if (responseStatus === 200) {
      history.push(buildBookingDescPath(channelID), { id, duration });
    }
    if (responseStatus === 500) {
      // eslint-disable-next-line no-alert
      alert('예약에 실패하였습니다. 다른 일정을 선택해 주세요.');
      history.goBack();
    }
    if (responseStatus === 404) {
      // eslint-disable-next-line no-alert
      alert('예약에 실패하였습니다. 다른 일정을 선택해 주세요.');
      history.goBack();
    }
    dispatch(responseStatusReset());
  }, [channelID, dispatch, duration, history, id, responseStatus]);

  return <Splash />;
}

export default Station;
