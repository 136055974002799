import styled from 'styled-components';

import DateHelper from '@common/utils/dateHelper';
import { DATE_FORMAT, TIME_FORMAT } from '@common/constants/index';

type Props = {
  title: string;
  author: string;
  createdAt: string;
  viewCount: number;
};

function PostInfo({ title, author, createdAt, viewCount }: Props) {
  return (
    <S_PostInfo>
      <S_Title>{title}</S_Title>
      <S_InfoBox>
        <S_Author>{author}</S_Author>
        <S_Text>
          {DateHelper.isToday(createdAt)
            ? DateHelper.formatUTCtoKST(createdAt, TIME_FORMAT)
            : DateHelper.formatUTCtoKST(createdAt, DATE_FORMAT)}
        </S_Text>
        <S_Text>{new Intl.NumberFormat().format(viewCount)}</S_Text>
      </S_InfoBox>
    </S_PostInfo>
  );
}

const S_PostInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 12px;
`;

const S_Title = styled.span`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  margin-bottom: 4px;
`;

const S_InfoBox = styled.div`
  color: ${({ theme }) => theme.grey400};
  font-size: ${({ theme }) => theme.fontSizeCaption1};
`;

const S_Text = styled.span`
  color: ${({ theme }) => theme.grey400};
  font-size: ${({ theme }) => theme.fontSizeCaption1};
  margin-right: 8px;
`;

const S_Author = styled(S_Text)`
  color: ${({ theme }) => theme.grey500};
`;

export default PostInfo;
