/* eslint-disable import/order */
import locale1 from './ko.json';
import locale2 from './en.json';
import locale3 from './jp.json';
import locale4 from './es.json';

const locale = {
  ko: locale1,
  en: locale2,
  jp: locale3,
  es: locale4
} as const;

export default locale;
