import { JWT } from '@common/utils/index';

import ObjectHelper from '../../../../utils/objectHelper';
import DateHelper from '../../../../utils/dateHelper';
import { Dm, DmFormat, Counterpart, CounterpartFormat, DateWithDms } from '../types';

export function isOK(response: { status: number }) {
  return response.status < 300;
}

export function getMyProfileIdByToken() {
  const { REACT_APP_KEY_ACCESS_TOKEN } = process.env;
  const [, , jwtData] = JWT.validateJwtToken(
    localStorage.getItem(REACT_APP_KEY_ACCESS_TOKEN || '')
  );

  return jwtData.user.mainProfile.id;
}

export function randomNumber() {
  return Math.floor(Math.random() * new Date().getTime());
}

export function randomString() {
  return String(randomNumber());
}

export function makeDefaultDm(text: string, profileId: number, myProfileId: number): Dm {
  return {
    id: randomNumber() * -1,
    messageText: text,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    ownerProfileId: myProfileId,
    senderProfileId: myProfileId,
    receiverProfileId: Number(profileId),
    counterpartProfileId: Number(profileId),
    txid: randomString(),
    isCounterpartRead: false,
    isOwnerRead: false,
    sendOK: false
  };
}

export function makeDmInterface(message: Dm, myProfileId: number): DmFormat {
  return {
    ...message,
    time: DateHelper.format(message.createdAt, 'HH:mm'),
    date: DateHelper.format(message.createdAt, 'MM월 DD일 (dd)'),
    isMyMessage: myProfileId === message.senderProfileId,
    hasTail: true,
    sendOK: message.sendOK !== false
  };
}

export function makeCounterpartInterface(
  data: Counterpart,
  myProfileId: number
): CounterpartFormat {
  return {
    id: data.counterpartProfileId,
    isOnline: false,
    latestMessageText: data.messageText,
    latestMessageTime: data.createdAt,
    latestMessageTxid: data.txid,
    oldestMessageTxid: data.txid,
    imageSrc: '',
    nickname: '',
    dateWithDms: [
      {
        date: DateHelper.format(data.createdAt, 'MM월 DD일 (dd)'),
        dms: [makeDmInterface(ObjectHelper.omit(data, ['unreadCount']), myProfileId)]
      }
    ],
    hasOldestMessage: false,
    hasInitializedDms: false,
    formatDate: DateHelper.fromNow(data.createdAt)
  };
}

export function formatDateWithDms(messages: Dm[], myProfileId: number) {
  return addDateInfo(
    messages.map((message) => makeDmInterface(message, myProfileId)).map(makeInterface)
  );

  function makeInterface(message: DmFormat, i: number, arr: DmFormat[]) {
    return {
      ...message,
      hasTail:
        i === 0 ||
        arr[i].senderProfileId !== arr[i - 1].senderProfileId ||
        arr[i].date !== arr[i - 1].date
    };
  }

  function addDateInfo(dms: DmFormat[]) {
    return dms.reduce((acc, dm) => {
      const prevDate = acc[acc.length - 1]?.date;

      if (prevDate !== dm.date) {
        acc.push({
          date: dm.date,
          dms: []
        });
      }

      acc[acc.length - 1].dms.push(dm);

      return acc;
    }, [] as DateWithDms[]);
  }
}
