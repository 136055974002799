import styled from 'styled-components';

import { PostInfo } from '../../../components/molcules';

type Props = {
  title: string;
  author: string;
  createdAt: string;
  viewCount: number;
};

function NoticeDetailInfo({ title, author, createdAt, viewCount }: Props) {
  return (
    <S_NoticeDetailInfo>
      <S_Title>{title}</S_Title>
      <PostInfo author={author} createdAt={createdAt} viewCount={viewCount} />
    </S_NoticeDetailInfo>
  );
}

const S_NoticeDetailInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0;
  padding: 0 24px;
  text-align: left;
  margin-top: 56px;
`;

const S_Title = styled.span`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeSubtitle};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  margin-bottom: 8px;
`;

export default NoticeDetailInfo;
