import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Redirect, useLocation } from 'react-router-dom';
import activePAppList from '@common/utils/activePAppList';
import { makeGetIsAuthenticated } from '@common/redux/modules/session';
import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import { fetchSubscriptionProduct } from '@common/redux/modules/subscriptionProduct/reducers';
import {
  setChannelDescParams,
  resetChannelError,
  fetchChannelByCode,
  fetchChannelSellerInfo
} from '@common/redux/modules/channel/reducers';
import { PublicRoute } from '@common/overrides/index';
import {
  rootPath,
  channelBasicPath,
  channelsAuthPath,
  channelDeactivatePath
} from '@routers/routes';
import { ChannelDeactivate, ChannelAuth } from './pages';
import api from '@common/services/api';
import { ChannelCover } from './pages/ChannelCover';
import { ChannelDesc } from './pages/ChannelDesc';
import { PAppContainer } from '../../containers/PAppContainer';

type MatchProps = {
  match: {
    isExact: boolean;
    params: {
      channelID: string;
      pAppCode: string;
    };
    path: string;
    url: string;
  };
};

function ChannelsContainer({ match }: MatchProps) {
  const location = useLocation();
  const params = new URLSearchParams(location.search).get('from');

  const dispatch = useDispatch();
  const [channelID, setChannelID] = useState('');
  const [keyword, setKeyword] = useState('');
  const [defaultPath, setDefaultPath] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isActivePApp, setIsActivePApp] = useState(false);
  const [isDefault, setIsDefault] = useState(false);

  const getIsAuthenticated = useMemo(makeGetIsAuthenticated, []);
  const isAuthenticated = useSelector(getIsAuthenticated);

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  useEffect(() => {
    setIsLoading(true);
    getChannelInfo();
  }, []);

  async function getChannelInfo() {
    dispatch(resetChannelError());
    dispatch(fetchChannelByCode({ channelId: match.params.channelID }));
    dispatch(fetchChannelSellerInfo({ invitation_code: match.params.channelID }));
    await api.refetchToken();
  }

  useEffect(() => {
    if (keyword === '' && params) {
      setKeyword(params);
    }
  }, [keyword, params]);

  useEffect(() => {
    if (currentChannel !== undefined) {
      setChannelID(currentChannel.primitiveInvitationCode);
    }
  }, [currentChannel]);

  useEffect(() => {
    if (currentChannel && channelID) {
      dispatch(setChannelDescParams({ channelID, keyword }));

      dispatch(
        fetchSubscriptionProduct({
          invitationCode: channelID,
          conversionLinkKeyword: keyword
        })
      );
      const pAppKeys = Object.entries(currentChannel.activePApps);
      const pApps = activePAppList(channelID, pAppKeys);

      const defaultIndex = pApps.findIndex((pApp) => pApp.isDefault);
      if (pApps && pApps[defaultIndex]) {
        setDefaultPath(pApps[defaultIndex].path);
      }
    }
  }, [currentChannel, keyword, channelID]);

  if (isLoading) {
    return (
      <ChannelCover
        pAppCode={match.params.pAppCode}
        channelID={match.params.channelID}
        setIsLoading={setIsLoading}
        setIsActivePApp={setIsActivePApp}
        setIsDefault={setIsDefault}
      />
    );
  }

  if (!isLoading && isAuthenticated && isActivePApp) {
    return <PAppContainer defaultPath={defaultPath} channelID={match.params.channelID} />;
  }

  if (isDefault) {
    return <Redirect to={rootPath} />;
  }

  return (
    <Switch>
      <PublicRoute exact path={channelsAuthPath} component={ChannelAuth} />
      <PublicRoute exact path={channelDeactivatePath} component={ChannelDeactivate} />
      <PublicRoute exact path={channelBasicPath} component={ChannelDesc} />
      <Redirect to={channelBasicPath} />
    </Switch>
  );
}

export default ChannelsContainer;
