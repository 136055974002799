import IconType from '../IconType';

const ChatCounseling = ({ color, size, ...rest }: IconType) => (
  <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M16.44595,9.1574 L7.55395,9.1574 C7.13995,9.1574 6.80395,8.8219 6.80395,8.4074 C6.80395,7.9929 7.13995,7.6574 7.55395,7.6574 L16.44595,7.6574 C16.86045,7.6574 17.19595,7.9929 17.19595,8.4074 C17.19595,8.8219 16.86045,9.1574 16.44595,9.1574 M12.49895,12.7499 L7.55395,12.7499 C7.13995,12.7499 6.80395,12.4144 6.80395,11.9999 C6.80395,11.5854 7.13995,11.2499 7.55395,11.2499 L12.49895,11.2499 C12.91345,11.2499 13.24895,11.5854 13.24895,11.9999 C13.24895,12.4144 12.91345,12.7499 12.49895,12.7499 M18.16595,2.5629 L5.83395,2.5629 C3.90095,2.5629 2.33395,4.1299 2.33395,6.0629 L2.33395,14.2224 C2.33395,16.1554 3.90095,17.7224 5.83395,17.7224 L10.97695,17.7224 L14.23495,20.6839 C15.19845,21.5599 16.74395,20.8759 16.74395,19.5739 L16.74395,17.7224 L18.16595,17.7224 C20.09895,17.7224 21.66595,16.1554 21.66595,14.2224 L21.66595,6.0629 C21.66595,4.1299 20.09895,2.5629 18.16595,2.5629"
    />
  </svg>
);

export default ChatCounseling;
