import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@common/ui/components';

import { StyledNamespace } from '@common/types/styledNamespace';

const S: StyledNamespace = {};

type TitleBlockProps = {
  title: string;
  showCloseButton?: boolean;
  onClose?: Function;
};

/**
 * 모달 다이얼로그 Title
 *
 * @param {Object} props
 * @param {string} props.title 다이얼로그 제목
 * @param {boolean} props.showCloseButton 닫기 버튼 보이기 여부
 * @param {function} props.onClose 모달 닫기 콜백
 */
function TitleBlock({ title, showCloseButton = true, onClose }: TitleBlockProps) {
  return (
    <S.TitleBlock>
      <S.Title>{title}</S.Title>

      {showCloseButton && (
        <S.CloseButton onClick={onClose}>
          <Icon iconName="ic_Xmark" />
        </S.CloseButton>
      )}
    </S.TitleBlock>
  );
}

TitleBlock.defaultProps = {
  title: '',
  showCloseButton: true
};

TitleBlock.propTypes = {
  title: PropTypes.string,
  showCloseButton: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

S.TitleBlock = styled.div`
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.grey100};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 48px;
`;

S.Title = styled.div`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody1};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  line-height: ${({ theme }) => theme.fontLineHeight};
  margin-left: 24px;
`;

S.CloseButton = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  border: 0;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
  outline: 0;
  width: 48px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }
`;

export default TitleBlock;
