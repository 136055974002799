import { createReducer, createAction } from '@reduxjs/toolkit';

import { builderFactory } from '@common/utils/reduxHelper';

import {
  InitialStateType,
  NoticePostsPayload,
  CommentPayload,
  CreateCommentPayload,
  PostDetailPayload,
  DeleteCommentPayload
} from './types';

const prefix = '@noticePApp';

export const fetchNoticePosts = createAction<NoticePostsPayload>(`${prefix}/FETCH_VOD_LIST`);
export const fetchNoticePost = createAction<PostDetailPayload>(`${prefix}/FETCH_NOTICE_POST`);

export const fetchComments = createAction<CommentPayload>(`${prefix}/FETCH_COMMENTS`);
export const createComment = createAction<CreateCommentPayload>(`${prefix}/CREATE_COMMENT`);
export const deleteComment = createAction<DeleteCommentPayload>(`${prefix}/DELETE_COMMENT`);

export const resetComments = createAction(`${prefix}/RESET_COMMENTS`);

const initialState: InitialStateType = {
  isLoading: true,
  error: null,
  noticePosts: [],
  totalPostsCount: 0,
  noticePost: null,
  comments: [],
  totalCommentsCount: 0,
  isCommentCreated: false,
  isCommentDeleted: false
};

const reducer = createReducer(initialState, (builder) => {
  builderFactory(builder, [fetchNoticePosts, createComment, deleteComment])
    .addCase(`${fetchNoticePosts}_COMPLETED`, (state, action: any) => {
      const { posts, pagination } = action.payload.data.data;

      state.noticePosts = posts;
      state.totalPostsCount = pagination.total;
    })
    .addCase(`${fetchNoticePost}_COMPLETED`, (state, action: any) => {
      const { post } = action.payload.data.data;

      state.noticePost = post;
    })
    .addCase(`${fetchComments}_COMPLETED`, (state, action: any) => {
      const { comments, pagination } = action.payload.data.data;

      state.totalCommentsCount = pagination.total;

      if (state.isCommentCreated) {
        state.comments = comments;
        state.isCommentCreated = false;
        return;
      }

      if (state.isCommentDeleted) {
        state.isCommentDeleted = false;
        return;
      }

      state.comments = [...state.comments, ...comments];
    })
    .addCase(`${createComment}_COMPLETED`, (state) => {
      state.isCommentCreated = true;
    })
    .addCase(`${deleteComment}_COMPLETED`, (state, action: any) => {
      const targetId = action.payload.config.pathVariables.commentId;
      const filteredComments = state.comments.filter((comment) => comment.id !== targetId);

      state.comments = filteredComments;
      state.isCommentDeleted = true;
    })
    .addCase(resetComments, (state) => {
      state.comments = [];
      state.totalCommentsCount = 0;
    })
    .addMatcher(
      (action) => action.type.startsWith(prefix) && action.type.endsWith('_COMPLETED'),
      (state) => {
        state.isLoading = false;
      }
    )
    .addMatcher(
      (action) => action.type.startsWith(prefix) && action.type.endsWith('_FAILED'),
      (state, action) => {
        state.isLoading = false;
        state.error = action.payload.data.message;
      }
    );
});

export default reducer;
