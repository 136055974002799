import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button } from '@common/ui/components';
import styled from 'styled-components';

import { buildBookingMyTicketPath } from '@routers/routes';

type CaptionProps = {
  ticketLeft: number;
  channelId: string;
};

function Caption({ ticketLeft, channelId }: CaptionProps) {
  const { t } = useTranslation('translation');
  const history = useHistory();

  const handleOnclickToMyTicket = () => {
    history.push(buildBookingMyTicketPath(channelId));
  };

  return (
    <S_CaptionContainer>
      <S_CaptionText>
        {t('str_17', {
          ticketAmount: ticketLeft,
          defaultValue: '$t(str_papp_cbooking_ticket) {{ticketAmount}}장 보유'
        })}
      </S_CaptionText>
      <Button
        line
        size="small"
        label={t('str_papp_cbooking_ticket_purchase', '구매')}
        onClick={handleOnclickToMyTicket}
      />
    </S_CaptionContainer>
  );
}

const S_CaptionContainer = styled.div`
  color: ${({ theme }) => theme.grey400};
  font-size: ${({ theme }) => theme.fontSizeCaption1};
  height: 24px;
  line-height: ${({ theme }) => theme.fontLineHeight};
  margin-bottom: 8px;
  margin-right: 24px;
  text-align: right;
`;

const S_CaptionText = styled.span`
  margin-right: 8px;
`;

export default Caption;
