import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { BookingView, NavBar } from '../../../components/molcules';
import { TicketInfo } from '../molcules';
import { HistoryArea } from '../organisms';

type MyTicketTemplateProps = {
  ticketLeft: number;
  handlePurchaseTicketModalOpen: () => void;
};

function MyTicketTemplate({ ticketLeft, handlePurchaseTicketModalOpen }: MyTicketTemplateProps) {
  const { t } = useTranslation('translation');
  return (
    <S_MyTicketTemplateContainer>
      <BookingView nav={<NavBar isBack title={t('str_papp_cbooking_ticket', '예약권')} />}>
        <TicketInfo
          ticketLeft={ticketLeft}
          handlePurchaseTicketModalOpen={handlePurchaseTicketModalOpen}
        />
        <HistoryArea />
      </BookingView>
    </S_MyTicketTemplateContainer>
  );
}

const S_MyTicketTemplateContainer = styled.div`
  background-color: 'white';
`;

export default MyTicketTemplate;
