import { useState } from 'react';
import styled from 'styled-components';

import { LabelType } from '@common/redux/modules/VODPlusPApp/types';

import { Image, Text } from '../../../components';
import { SeeMore } from '../atoms';

type Props = {
  imageSrc: string;
  title: string;
  labels: LabelType[];
  ageLimit: string[];
  releaseYear: string;
  content: string;
};

function DetailContent({ imageSrc, title, labels, ageLimit, releaseYear, content }: Props) {
  const [isLabelOpen, setIsLabelOpen] = useState(false);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);

  const labelSeeMoreHandler = () => setIsLabelOpen(!isLabelOpen);
  const descriptionSeeMoreHandler = () => setIsDescriptionOpen(!isDescriptionOpen);

  const labelList: string[] = [];
  labels &&
    labels.map((item) => {
      if (item.type === 'COUNTRY') labelList.unshift(item.name);
      if (item.type === 'GENRE') labelList.push(item.name);
      return;
    });

  return (
    <>
      <Image src={imageSrc} ratio={16 / 9} />
      <S_ContentBox>
        <Text fontSizes="fontSizeH2" fontWeights="fontWeightBold" lineLimit={1} elipsis>
          {title}
        </Text>
        {labelList.length > 0 && (
          <SeeMore
            isOpen={isLabelOpen}
            handler={labelSeeMoreHandler}
            text={labelList.length > 0 ? labelList.toString().replace(/,/g, ', ') : ''}
            lineLimit={1}
            color="grey500"
            fontSizes="fontSizeCaption1"
          />
        )}
        <S_CaptionBox>
          {ageLimit.length > 0 && (
            <S_TextBorderWrapper>
              <Text fontSizes="fontSizeCaption1" color="grey400">
                {ageLimit}
              </Text>
            </S_TextBorderWrapper>
          )}
          <Text fontSizes="fontSizeCaption1" color="grey400">
            {releaseYear}
          </Text>
        </S_CaptionBox>
        {content.length > 0 && (
          <SeeMore
            isOpen={isDescriptionOpen}
            handler={descriptionSeeMoreHandler}
            text={content}
            lineLimit={3}
            noCaption
          />
        )}
      </S_ContentBox>
    </>
  );
}

const S_ContentBox = styled.div`
  margin: 0 24px;
  padding: 24px 0 14px 0;
  text-align: left;
`;

const S_CaptionBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: -2px 0 6px;
`;

const S_TextBorderWrapper = styled.div`
  border: ${({ theme }) => `1px solid  ${theme.grey400}`};
  border-radius: 20px;
  margin-right: 8px;
  padding: 1px 8px;
`;

export default DetailContent;
