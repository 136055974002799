import JWT from '../jwt';
import ObjectHelper from '../objectHelper';

import { RESP_AXIOS_UNAUTHENTICATED, RESP_AXIOS_FORBIDDEN } from './responses.json';

const AUTH_ERROR_RESPONSES = {
  unauthenticated: RESP_AXIOS_UNAUTHENTICATED,
  forbidden: RESP_AXIOS_FORBIDDEN
};

class MockApiHelper {
  static buildSuccessResponseBody(data) {
    return {
      msg: 'ok',
      data
    };
  }

  static buildErrorResponseBody(customStatusCode, customMsg, additionalData) {
    return {
      msg: 'error',
      error: {
        code: customStatusCode,
        msg: customMsg,
        ...(additionalData != null && { data: additionalData })
      }
    };
  }

  static buildResponse(statusCode, responseBody) {
    return [statusCode, responseBody];
  }

  static buildResponseFromPreset(preset) {
    const responseBody = preset.data.error
      ? this.buildErrorResponseBody(preset.data.error.code, preset.data.error.msg)
      : this.buildSuccessResponseBody(preset.data);

    return this.buildResponse(preset.status, responseBody);
  }

  static buildResponseFromChangesetError(changesetError) {
    const { code, msg, additionalData } = changesetError;

    return this.buildResponse(400, this.buildErrorResponseBody(code, msg, additionalData));
  }

  static censor(modelObj, securedFields) {
    return ObjectHelper.omit(modelObj, securedFields);
  }

  static extract(modelObj, allowedFields) {
    return ObjectHelper.pick(modelObj, allowedFields);
  }

  static filter(tableRows, opts) {
    return tableRows.filter((row) => {
      return Object.entries(opts).reduce((acc, [field, value]) => {
        if (value == null) {
          return acc;
        }

        return acc && row[field] === value;
      }, true);
    });
  }

  static paginator(tableRows, resourceName, page = 1, size = 20) {
    const start = (+page - 1) * +size;
    const end = +page * +size;

    return {
      [resourceName]: tableRows.slice(start, end),
      totalItems: tableRows.length,
      totalPages: Math.ceil(tableRows.length / size) || 1,
      currentPage: +page,
      size: +size
    };
  }

  static genId(tableRows) {
    const lastIndex = tableRows.length - 1;

    return tableRows[lastIndex].id + 1;
  }

  static ensureAuthenticated(headers, opts = {}) {
    const { role, errorResponses = AUTH_ERROR_RESPONSES } = opts;

    const [tokenStatus, result] = this.validateToken(headers);

    if (tokenStatus === 'error') {
      return { errorResponse: this.buildResponseFromPreset(errorResponses.unauthenticated) };
    }

    if (role && result.role !== role) {
      return { errorResponse: this.buildResponseFromPreset(errorResponses.forbidden) };
    }

    return { jwtData: result };
  }

  static validateToken(headers) {
    const tokenInHeader = headers.Authorization?.replace('Bearer ', '');
    const [tokenStatus, result, jwtData] = JWT.validateJwtToken(tokenInHeader);

    return tokenStatus === 'OK' ? [tokenStatus, jwtData] : [tokenStatus, result];
  }

  static changeset(params) {
    return { data: params };
  }

  static cast(changeset, fields = []) {
    const { error, data } = changeset;

    if (error) {
      return changeset;
    }

    return {
      data: fields.reduce((acc, field) => {
        acc[field] = data[field];

        return acc;
      }, {})
    };
  }

  static validateRequired(changeset, fields = []) {
    const { error, data } = changeset;

    if (error) {
      return changeset;
    }

    const isValid = fields.reduce((acc, field) => {
      return acc && data[field] != null;
    }, true);

    if (!isValid) {
      return { error: { code: 400, msg: 'MISSING_ONE_OR_MORE_REQUIRED_FIELD' } };
    }

    return { data };
  }

  static validate(changeset, validatorName, validatorFunc) {
    const { error, data } = changeset;

    if (error) {
      return changeset;
    }

    const { validationError } = validatorFunc(data);

    if (validationError) {
      return {
        error: {
          ...validationError,
          msg: `${validatorName}: ${validationError.msg}`
        }
      };
    }

    return { data };
  }
}

export default MockApiHelper;
