import IconType from '../IconType';

const Search = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17.4018,10.15215 C17.4018,14.15565 14.1558,17.40165 10.1523,17.40165 C6.1488,17.40165 2.9028,14.15565 2.9028,10.15215 C2.9028,6.14865 6.1488,2.90265 10.1523,2.90265 C14.1558,2.90265 17.4018,6.14865 17.4018,10.15215 Z M15.41685,15.4167 L21.09735,21.0972"
      />
    </svg>
  );
};

export default Search;
