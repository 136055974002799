import { useEffect, useRef, useState } from 'react';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-contrib-quality-levels';
import 'videojs-hls-quality-selector';
import styled from 'styled-components';

import { PlayerSettingModal, VodPlusPlayerController } from '../templates';

declare module 'video.js' {
  interface VideoJsPlayer {
    hlsQualitySelector(options: { displayCurrentQuality: boolean }): void;
  }

  interface HlsQualitySelector {
    hlaQualitySelector?: { displayCurrentQuality: boolean };
  }
}

type Props = {
  src: string;
  token: string;
};
type Codes = {
  seriesId: string;
  invitationCode: string;
  isPlayerSettingModalOpen: boolean;
  playerSettingHandler: (value: boolean) => void;
};

const initialOptions: VideoJsPlayerOptions = {
  controls: false,
  muted: true,
  playbackRates: [0.5, 1, 1.5, 2],
  aspectRatio: '16:9',
  html5: {
    vhs: {
      enableLowInitialPlaylist: true,
      overrideNative: true,
      cacheEncryptKeys: true
    },
    nativeAudioTracks: false,
    nativeVideoTracks: false,
    nativeTextTracks: true
  }
};

const usePlayer = ({ src, token }: Props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const playerRef = useRef<VideoJsPlayer | null>(null);

  useEffect(() => {
    const vjsPlayer = videojs(videoRef.current, initialOptions);

    playerRef.current = vjsPlayer;

    return () => playerRef.current?.dispose();
  }, []);

  useEffect(() => {
    if (!src || !token) return;
    if (!playerRef || !playerRef.current) return;

    playerRef.current.ready(() => {
      playerRef.current?.src({
        src: `${src}?token=${token}`,
        type: 'application/x-mpegURL'
      });

      // TODO: check hls quality selector bug
      // playerRef.current?.hlsQualitySelector({
      //   displayCurrentQuality: true
      // });
    });
  }, [src, token]);

  return videoRef;
};

const HLSPlayer = ({
  src,
  token,
  seriesId,
  invitationCode,
  isPlayerSettingModalOpen,
  playerSettingHandler
}: Props & Codes) => {
  const playerRef = usePlayer({ src, token });
  const video = document.querySelector('video');

  const [isControllerOpen, setIsControllerOpen] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(true);

  video?.addEventListener('play', () => {
    setIsPlaying(true);
  });
  video?.addEventListener('pause', () => {
    setIsPlaying(false);
  });

  const playBarHandler = (value: any) => {
    if (video) video.currentTime = value;
  };

  const fullscreenToggle = () => {
    if (video?.requestFullscreen) video.requestFullscreen();
    else {
      if (!playerRef?.current?.id) return;
      videojs(playerRef.current.id).requestFullscreen();
      return;
    }
  };

  const muteHandler = () => {
    setIsMuted(!isMuted);
    if (video) video.muted = !isMuted;
  };

  const controllerHandler = () => {
    setIsControllerOpen(!isControllerOpen);
  };

  return (
    <>
      <S_PlayArea onClick={controllerHandler}>
        <S_Player ref={playerRef} className="video-js" muted={isMuted} autoPlay playsInline />
      </S_PlayArea>

      {isControllerOpen && (
        <VodPlusPlayerController
          video={video}
          currentTime={video?.currentTime || 1}
          playerWidth={video?.clientWidth || 1}
          invitationCode={invitationCode}
          seriesId={seriesId}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          isMuted={isMuted}
          muteHandler={muteHandler}
          controllerHandler={controllerHandler}
          isControllerOpen={isControllerOpen}
          playBarHandler={playBarHandler}
          playerSettingHandler={playerSettingHandler}
          fullscreenToggle={fullscreenToggle}
        />
      )}
      {isPlayerSettingModalOpen && (
        <>
          <PlayerSettingModal
            video={video}
            isPlayerSettingModalOpen={isPlayerSettingModalOpen}
            playerSettingHandler={playerSettingHandler}
          />
        </>
      )}
    </>
  );
};

const S_PlayArea = styled.div`
  border: none;
`;

const S_Player = styled.video`
  pointer-events: none;
`;

export default HLSPlayer;
