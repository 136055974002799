import IconType from '../IconType';

const Speaker = ({ color, size, ...rest }: IconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5239,2.832 C12.0294,2.832 12.4404,3.2415 12.4404,3.7485 L12.4404,3.7485 L12.4404,20.25 C12.4404,20.433 12.3864,20.6115 12.2859,20.7615 C12.1089,21.0255 11.8179,21.168 11.5239,21.168 C11.3469,21.168 11.1699,21.117 11.0124,21.012 L11.0124,21.012 L4.3314,15.195 L2.2074,15.195 C1.6149,15.195 1.1334,14.7135 1.1334,14.121 L1.1334,14.121 L1.1334,9.8745 C1.1334,9.2805 1.6149,8.8005 2.2074,8.8005 L2.2074,8.8005 L4.3314,8.8005 L11.0124,2.988 C11.1639,2.886 11.3409,2.832 11.5239,2.832 Z M10.9404,5.0385 L5.3154,9.9315 L4.8924,10.3005 L2.6334,10.3005 L2.6334,13.695 L4.8924,13.695 L5.3169,14.064 L10.9404,18.96 L10.9404,5.0385 Z M19.9797,4.7466 C23.8287,8.5971 23.8287,14.8641 19.9797,18.7176 C19.8327,18.8631 19.6407,18.9366 19.4487,18.9366 C19.2567,18.9366 19.0647,18.8631 18.9177,18.7176 C18.6252,18.4236 18.6252,17.9496 18.9177,17.6556 C22.1832,14.3886 22.1832,9.0741 18.9177,5.8071 C18.6252,5.5146 18.6252,5.0391 18.9177,4.7466 C19.2117,4.4541 19.6857,4.4541 19.9797,4.7466 Z M17.32095,7.40385 C18.47595,8.56035 19.11345,10.09785 19.11345,11.73135 C19.11345,13.36635 18.47595,14.90235 17.32095,16.05885 C17.17395,16.20435 16.98195,16.27785 16.78995,16.27785 C16.59795,16.27785 16.40595,16.20435 16.25895,16.05885 C15.96645,15.76485 15.96645,15.29085 16.25895,14.99685 C17.13345,14.12535 17.61345,12.96585 17.61345,11.73135 C17.61345,10.49685 17.13345,9.33735 16.25895,8.46585 C15.96645,8.17185 15.96645,7.69785 16.25895,7.40385 C16.55295,7.11135 17.02695,7.11135 17.32095,7.40385 Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Speaker;
