import IconType from '../IconType';

const Face = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M12.0002,18.3011 C8.8682,18.3011 6.3292,15.9181 6.3292,12.9791 L17.6702,12.9791 C17.6702,15.9181 15.1322,18.3011 12.0002,18.3011 M8.4692,7.7321 C9.2262,7.7321 9.8392,8.3451 9.8392,9.1021 C9.8392,9.8591 9.2262,10.4721 8.4692,10.4721 C7.7122,10.4721 7.0992,9.8591 7.0992,9.1021 C7.0992,8.3451 7.7122,7.7321 8.4692,7.7321 M15.5312,7.7321 C16.2882,7.7321 16.9012,8.3451 16.9012,9.1021 C16.9012,9.8591 16.2882,10.4721 15.5312,10.4721 C14.7742,10.4721 14.1612,9.8591 14.1612,9.1021 C14.1612,8.3451 14.7742,7.7321 15.5312,7.7321 M12.0002,2.0631 C6.5122,2.0631 2.0632,6.5121 2.0632,12.0001 C2.0632,17.4881 6.5122,21.9371 12.0002,21.9371 C17.4882,21.9371 21.9372,17.4881 21.9372,12.0001 C21.9372,6.5121 17.4882,2.0631 12.0002,2.0631"
      />
    </svg>
  );
};

export default Face;
