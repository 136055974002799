import IconType from '../IconType';

const Mute = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M11.5458,2.8317 C12.0151929,2.8317 12.4031036,3.18478929 12.4561245,3.64116319 L12.4623,3.7482 L12.4623,20.2512 C12.4623,20.4327 12.4083,20.6112 12.3063,20.7627 C12.1308,21.0252 11.8398,21.1677 11.5458,21.1677 C11.4042,21.1677 11.2626,21.13506 11.130984,21.068244 L11.0343,21.0117 L4.3533,15.1947 L2.2293,15.1947 C1.6763,15.1947 1.21999333,14.7765667 1.16161022,14.2378716 L1.1553,14.1207 L1.1553,9.8742 C1.1553,9.3212 1.57474,8.86489333 2.11238978,8.80651022 L2.2293,8.8002 L4.3533,8.8002 L11.0343,2.9877 C11.1843,2.8872 11.3628,2.8317 11.5458,2.8317 Z M15.9456,9.39585 C16.2396,9.10335 16.7136,9.10335 17.0061,9.39585 L17.0061,9.39585 L19.2861,11.67585 L21.5646,9.39585 C21.8571,9.10335 22.3326,9.10335 22.6251,9.39585 C22.9176,9.68985 22.9176,10.16385 22.6251,10.45635 L22.6251,10.45635 L20.3466,12.73635 L22.6251,15.01485 C22.9176,15.30735 22.9176,15.78285 22.6251,16.07535 C22.4781,16.22235 22.2861,16.29585 22.0956,16.29585 C21.9036,16.29585 21.7116,16.22235 21.5646,16.07535 L21.5646,16.07535 L19.2861,13.79685 L17.0076,16.07535 C16.8606,16.22235 16.6686,16.29585 16.4766,16.29585 C16.2846,16.29585 16.0941,16.22235 15.9471,16.07535 C15.6546,15.78285 15.6546,15.30735 15.9471,15.01485 L15.9471,15.01485 L18.2256,12.73635 L15.9456,10.45635 C15.6531,10.16385 15.6531,9.68985 15.9456,9.39585 Z"
      />
    </svg>
  );
};

export default Mute;
