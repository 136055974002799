class YoutubeLinkParser {
  static getId(src: string) {
    const embedUrl = src.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/);
    const shareUrl = src.match(/youtu.be\/(.{11})/);

    return (embedUrl || shareUrl)?.pop();
  }

  static getThumbnailUrl(src: string) {
    return `https://img.youtube.com/vi/${YoutubeLinkParser.getId(src)}/0.jpg`;
  }

  static getEmbedUrl(src: string) {
    return `https://www.youtube.com/embed/${YoutubeLinkParser.getId(src)}`;
  }
}

export default YoutubeLinkParser;
