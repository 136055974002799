import { Route, Redirect } from 'react-router-dom';

function PrivateRoute({
  isAuthenticated,
  ...rest
}: {
  isAuthenticated: boolean;
  path: string;
  exact?: boolean;
  component: any;
}) {
  return !isAuthenticated ? <Redirect to="/" /> : <Route {...rest} />;
}

export default PrivateRoute;
