import { colors } from 'pds-dev-kit-web';
import React from 'react';
import fillIcons from '@assets/icons/fill';
import lineIcons from '@assets/icons/line';
import styled, { css } from 'styled-components';

import { Icon } from '@common/ui/components';

export type Colors = keyof typeof colors;
export type FillIconNameKeys = keyof typeof fillIcons;
export type LineIconNameKeys = keyof typeof lineIcons;

export type Props = {
  text?: string;
  shapeType?: 'round' | 'circular' | 'rectangle';
  fillType?: 'fill' | 'line';
  size?: 'large' | 'medium' | 'small' | 'xsmall' | 'rlarge';
  fontWeight?: 'bold' | 'regular';
  iconMode?: 'none' | 'left' | 'right';
  iconName?: FillIconNameKeys | LineIconNameKeys | string;
  colorTheme?: 'none' | 'line1' | 'line2';
  disabled?: boolean;
  handleAction?: () => any;
};

// TODO : 아직 사용하지 않는 props

// "textColorKey"
// "baseColorKey"
// "borderColorKey"
// "shadow"
// shapeType = 'round',
// fontWeight = 'bold',

function MainButton({
  text,
  fillType = 'fill',
  size = 'large',
  iconMode,
  iconName,
  colorTheme = 'none',
  disabled,
  handleAction,
  ...rest
}: Props) {
  // TODO : icon 컬러값들 PDS적용되면 수정하기.
  const iconColorObj: { none: 'black'; line1: 'red500'; line2: 'blue500' } = {
    none: 'black',
    line1: 'red500',
    line2: 'blue500'
  };

  return (
    <S_Button
      {...rest}
      size={size}
      colorTheme={colorTheme}
      fillType={fillType}
      disabled={disabled}
      onClick={handleAction}
    >
      {iconMode === 'left' && (
        <Icon
          iconName={iconName}
          colorCode={iconColorObj[colorTheme]}
          size={size === 'large' ? 20 : 16}
        />
      )}
      {text}
      {iconMode === 'right' && <Icon iconName={iconName} colorCode={iconColorObj[colorTheme]} />}
    </S_Button>
  );
}

// TODO: font-size pds버전으로 바꾸기

const large = css`
  font-size: ${({ theme }) => theme.fontSizeBody1};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  border-radius: 24px;
  height: 48px;
  padding: 0 24px;
  min-width: 96px;
`;

const medium = css`
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  height: 40px;
  border-radius: 20px;
  padding: 0 24px;
`;

const small = css`
  font-size: ${({ theme }) => theme.fontSizeCaption1};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  height: 32px;
  border-radius: 16px;
  padding: 0 16px;
`;

const xsmall = css`
  font-size: ${({ theme }) => theme.fontSizeCaption1};
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  height: 24px;
  border-radius: 12px;
  padding: 0 16px;
`;

const rlarge = css`
  font-size: ${({ theme }) => theme.fontSizeBody1};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  width: 100%;
  height: 48px;
  border-radius: 24px;
  padding: 0 24px;
`;

// TODO : usr 관련된 컬러값들 PDS적용되면 수정하기.
const fillDisabled = css`
  color: ${({ theme }) => theme.grey400};
  background-color: ${({ theme }) => theme.ui_cpnt_button_fill_base_disabled};
`;

// TODO : usr 관련된 컬러값들 PDS적용되면 수정하기.
const fill = css<{ disabled?: boolean }>`
  border: none;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.ui_cpnt_button_fill_base_primary};

  &:hover {
    background-color: ${({ theme }) => theme.ui_cpnt_button_fill_on_base_hover};
  }

  &:active {
    background-color: ${({ theme }) => theme.ui_cpnt_button_fill_on_base_pressed};
  }

  ${({ disabled }) => disabled && fillDisabled}
`;

// TODO : sys_ 관련된 컬러값들 PDS적용되면 수정하기.
const none = css`
  border: solid 1px ${({ theme }) => theme.ui_cpnt_button_line_border_enabled};
  color: ${({ theme }) => theme.black};
`;

const line1 = css`
  border: solid 1px ${({ theme }) => theme.red500};
  color: ${({ theme }) => theme.red500};
`;

const line2 = css`
  border: solid 1px ${({ theme }) => theme.ui_cpnt_button_line_border_primary};
  color: ${({ theme }) => theme.ui_cpnt_button_text_primary};
`;

const line = css<{ colorTheme: 'none' | 'line1' | 'line2' }>`
  ${({ colorTheme }) =>
    colorTheme &&
    {
      none,
      line1,
      line2
    }[colorTheme]};
  background-color: white;
`;

const S_Button = styled.button<{
  size: 'large' | 'medium' | 'small' | 'xsmall' | 'rlarge';
  colorTheme: 'none' | 'line1' | 'line2';
  fillType: 'fill' | 'line';
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ fillType }) => fillType && { fill, line }[fillType]}
  ${({ size }) => size && { large, medium, small, xsmall, rlarge }[size]}
  ${({ size }) => (size === 'large' ? 'gap: 8px' : 'gap:4px')};
`;

export default MainButton;
