import { put, takeEvery, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { VODPlusPAppRepository } from '../../../repositories';
import {
  fetchVODPlusHome,
  fetchVODPlusPostById,
  fetchSeriesesLoadMore,
  fetchSeriesesNewLabel,
  fetchSeriesesSearchInput,
  fetchLabels,
  fetchSeriesById,
  fetchAddBookmark,
  fetchDeleteBookmark,
  fetchSeriesByBookmark,
  fetchVODPlusViewCounter,
  fetchVODPlusToken,
  fetchSeriesEpisodes
} from './reducers';
import { digestResponse } from '../../middlewares/response';

function* watchFetchVODPlusHome(action: ReturnType<typeof fetchVODPlusHome>) {
  const { type, payload } = action;
  const { channelId } = payload;

  const response: AxiosResponse = yield call(() =>
    VODPlusPAppRepository.findVODPlusHome(channelId)
  );

  yield put(digestResponse(response, type));
}

// 영상 하나 가져오기
function* watchFetchVODPlusPost(action: ReturnType<typeof fetchVODPlusPostById>) {
  const { type, payload } = action;
  const { postId, channelId } = payload;

  const response: AxiosResponse = yield call(() =>
    VODPlusPAppRepository.findPost(postId, channelId)
  );

  yield put(digestResponse(response, type));
}

// 같은 라벨의 시리즈 (더) 가져오기
function* watchFetchSeriesesLoadMore(action: ReturnType<typeof fetchSeriesesLoadMore>) {
  const { type, payload } = action;
  const { channelId, ...restParams } = payload;
  const response: AxiosResponse = yield call(() =>
    VODPlusPAppRepository.findAllSeries(restParams, channelId)
  );
  yield put(digestResponse(response, type));
}

// 새로운 라벨 시리즈 가져오기
function* watchFetchSeriesesNewLabel(action: ReturnType<typeof fetchSeriesesNewLabel>) {
  const { type, payload } = action;
  const { channelId, ...restParams } = payload;
  const response: AxiosResponse = yield call(() =>
    VODPlusPAppRepository.findAllSeries(restParams, channelId)
  );
  yield put(digestResponse(response, type));
}

// 새로운 검색 시리즈 가져오기
function* watchFetchSeriesesSearchInput(action: ReturnType<typeof fetchSeriesesSearchInput>) {
  const { type, payload } = action;
  const { channelId, ...restParams } = payload;
  const response: AxiosResponse = yield call(() =>
    VODPlusPAppRepository.findAllSeries(restParams, channelId)
  );
  yield put(digestResponse(response, type));
}

// 라벨 가져오기
function* watchFetchLabels(action: ReturnType<typeof fetchLabels>) {
  const { type, payload } = action;
  const { useCase, channelId } = payload;

  const response: AxiosResponse = yield call(() =>
    VODPlusPAppRepository.findLabels(useCase, channelId)
  );
  yield put(digestResponse(response, type));
}

// 시리즈 하나 가져오기
function* watchFetchSeries(action: ReturnType<typeof fetchSeriesById>) {
  const { type, payload } = action;
  const { seriesId, channelId } = payload;

  const response: AxiosResponse = yield call(() =>
    VODPlusPAppRepository.findSeries(seriesId, channelId)
  );
  yield put(digestResponse(response, type));
}

// 북마크 시리즈 전부 가져오기
function* watchFetchSeriesByBookmark(action: ReturnType<typeof fetchSeriesByBookmark>) {
  const { type, payload } = action;
  const { channelId } = payload;

  const response: AxiosResponse = yield call(() =>
    VODPlusPAppRepository.findSeriesesBookmark(channelId)
  );
  yield put(digestResponse(response, type));
}

// 북마크 추가
function* watchFetchAddBookmark(action: any) {
  const { type, payload } = action;
  const { seriesId, channelId } = payload;
  const response: AxiosResponse = yield call(() =>
    VODPlusPAppRepository.addBookmark(seriesId, channelId)
  );
  yield put(digestResponse(response, type));
}

// 북마크 삭제
function* watchFetchDeleteBookmark(action: any) {
  const { type, payload } = action;
  const { seriesId, channelId } = payload;

  const response: AxiosResponse = yield call(() =>
    VODPlusPAppRepository.deleteBookmark(seriesId, channelId)
  );
  yield put(digestResponse(response, type));
}

// 작품 내 에피소드 목록 보기
function* watchFetchSeriesEpisodes(action: ReturnType<typeof fetchSeriesEpisodes>) {
  const { type, payload } = action;
  const { seriesId, channelId } = payload;

  const response: AxiosResponse = yield call(() =>
    VODPlusPAppRepository.findAllEpisodes(seriesId, channelId)
  );
  yield put(digestResponse(response, type));
}

// 에피소드 뷰카운터 POST
function* watchFetchVODPlusViewCounter(action: ReturnType<typeof fetchVODPlusViewCounter>) {
  const { type, payload } = action;
  const response: AxiosResponse = yield call(() =>
    VODPlusPAppRepository.vodPlusViewCounter('post', payload.resourceId)
  );
  yield put(digestResponse(response, type));
}

function* watchFetchVODPlusToken(action: ReturnType<typeof fetchVODPlusToken>) {
  const { type, payload } = action;

  const response: AxiosResponse = yield call(() =>
    VODPlusPAppRepository.vodPlusToken(payload.channelId, payload.vid)
  );
  yield put(digestResponse(response, type));
}

function* watch() {
  yield takeEvery(fetchVODPlusHome, watchFetchVODPlusHome);
  yield takeEvery(fetchVODPlusPostById, watchFetchVODPlusPost);
  yield takeEvery(fetchSeriesesLoadMore, watchFetchSeriesesLoadMore);
  yield takeEvery(fetchSeriesesNewLabel, watchFetchSeriesesNewLabel);
  yield takeEvery(fetchSeriesesSearchInput, watchFetchSeriesesSearchInput);
  yield takeEvery(fetchLabels, watchFetchLabels);
  yield takeEvery(fetchSeriesById, watchFetchSeries);
  yield takeEvery(fetchAddBookmark, watchFetchAddBookmark);
  yield takeEvery(fetchDeleteBookmark, watchFetchDeleteBookmark);
  yield takeEvery(fetchSeriesByBookmark, watchFetchSeriesByBookmark);
  yield takeEvery(fetchVODPlusViewCounter, watchFetchVODPlusViewCounter);
  yield takeEvery(fetchVODPlusToken, watchFetchVODPlusToken);
  yield takeEvery(fetchSeriesEpisodes, watchFetchSeriesEpisodes);
}

export default watch;
