import InfiniteScroll from 'react-infinite-scroll-component';

import { BookingDescHistoryType } from '@common/redux/modules/booking/types';
import styled from 'styled-components';

import { HistoryCard } from '../molcules';

type HistoryListProps = {
  bookingHistoryList: BookingDescHistoryType[];
  onScrollUp: () => void;
  hasMore: boolean;
  channelID: string;
};

function HistoryList({ bookingHistoryList, onScrollUp, hasMore, channelID }: HistoryListProps) {
  return (
    <InfiniteScroll
      dataLength={bookingHistoryList.length}
      next={onScrollUp}
      loader={!hasMore || bookingHistoryList.length > 4 ? <S_Loading>Loading...</S_Loading> : <></>}
      hasMore={hasMore}
    >
      <S_HistoryListContainer>
        {bookingHistoryList.map((historyCard, i) => (
          <HistoryCard
            id={historyCard.id}
            startDate={historyCard.startDate}
            duration={historyCard.duration}
            cancel={historyCard.cancel}
            key={i.toString()}
            channelID={channelID}
          />
        ))}
      </S_HistoryListContainer>
    </InfiniteScroll>
  );
}

const S_HistoryListContainer = styled.div`
  border: 0;
`;

const S_Loading = styled.div`
  background-color: ${({ theme }) => theme.blue500};
  border-radius: 30px;
  color: ${({ theme }) => theme.white};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  margin: 24px;
  padding: 10px 20px;
  text-align: center;
  transition: 0.2 ease-in-out;
`;

export default HistoryList;
