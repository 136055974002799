import IconType from '../IconType';

const VideoPlaylist = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M18.9454466,15.6988365 L22.0599034,17.592896 C22.5216989,17.8737541 22.5216989,18.5760644 22.0599034,18.8569225 L20.5028316,19.8041173 L18.9454466,20.750982 C18.4836511,21.0321701 17.90625,20.681015 17.90625,20.1189688 L17.90625,16.3308497 C17.90625,15.7691335 18.4836511,15.4179784 18.9454466,15.6988365 Z M13.5,16.5 C14.3284271,16.5 15,17.1715729 15,18 C15,18.8284271 14.3284271,19.5 13.5,19.5 L2.25,19.5 C1.42157288,19.5 0.75,18.8284271 0.75,18 C0.75,17.1715729 1.42157288,16.5 2.25,16.5 L13.5,16.5 Z M21.75,10.5 C22.5784271,10.5 23.25,11.1715729 23.25,12 C23.25,12.8284271 22.5784271,13.5 21.75,13.5 L2.25,13.5 C1.42157288,13.5 0.75,12.8284271 0.75,12 C0.75,11.1715729 1.42157288,10.5 2.25,10.5 L21.75,10.5 Z M21.75,4.5 C22.5784271,4.5 23.25,5.17157288 23.25,6 C23.25,6.82842712 22.5784271,7.5 21.75,7.5 L2.25,7.5 C1.42157288,7.5 0.75,6.82842712 0.75,6 C0.75,5.17157288 1.42157288,4.5 2.25,4.5 L21.75,4.5 Z"
      />
    </svg>
  );
};

export default VideoPlaylist;
