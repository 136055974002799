import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StyledNamespace } from '@common/types/styledNamespace';

const S: StyledNamespace = {};

type NavViewProps = {
  nav: React.ReactNode;
  children: React.ReactNode;
};

/**
 * 모바일 네비게이션 뷰
 *
 * @param {Object} props
 * @param {Object} props.nav 네비게이션 컴포넌트
 */
function NavView({ nav, children }: NavViewProps) {
  return (
    <S.NavView>
      {nav}
      <S.Form>{children}</S.Form>
    </S.NavView>
  );
}

NavView.defaultProps = {
  nav: null
};

NavView.propTypes = {
  nav: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

S.NavView = styled.div`
  box-shadow: 0 100px 0 0 white, 0 -100px 0 0 white, 0 0 50px -30px rgba(0, 0, 0, 0.5),
    0 0 50px -30px rgba(0, 0, 0, 0.5);
  height: 100%;
  position: relative;
  user-select: none;
`;

S.Form = styled.div`
  position: relative;
  width: 100%;
`;

export default NavView;
