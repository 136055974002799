import { TextContent } from '../../../components/molecules';

import { Thumbnail } from '../atoms';

export type Props = {
  content: string;
  coverImageSrc: string[] | string | null;
};

function Contents({ content, coverImageSrc }: Props) {
  return (
    <>
      {coverImageSrc && <Thumbnail src={coverImageSrc} />}
      <TextContent content={content} isFoldable />
    </>
  );
}

export default Contents;
