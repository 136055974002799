import { useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import { getVODPost, getVODToken } from '@common/redux/modules/VODPApp';
import {
  cleanUpAllData,
  fetchVODPostById,
  fetchVODToken
} from '@common/redux/modules/VODPApp/reducers';
import styled from 'styled-components';

import { HLSPlayer } from './atoms';
import { VODInfo } from './molcules';
import { BottomSheet } from './organisms';

function VODPostDetail() {
  const dispatch = useDispatch();
  const { playlistId, postId } = useParams<{ playlistId: string; postId: string }>();

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const [channelCode, setChannelCode] = useState('');
  const [channelId, setChannelId] = useState(0);

  const [playerHeight, setPlayerHeight] = useState(0);
  const playerBottomRef = useRef<HTMLDivElement | null>(null);

  const VODPost = useSelector(getVODPost);
  const VODToken = useSelector(getVODToken);

  useEffect(() => {
    if (!currentChannel) return;

    setChannelCode(currentChannel.primitiveInvitationCode);
    setChannelId(currentChannel.id);
  }, [currentChannel]);

  useEffect(() => {
    if (!playerBottomRef || !playerBottomRef.current) return;

    if (VODPost && VODToken) {
      const { offsetTop, clientHeight } = playerBottomRef.current;

      setPlayerHeight(offsetTop + clientHeight);
    }
  }, [VODPost, VODToken]);

  useEffect(() => {
    if (!postId || !channelId) return;

    dispatch(fetchVODPostById({ postId, channelId }));
  }, [dispatch, postId, channelId]);

  useEffect(() => {
    if (!VODPost || !channelId) return;

    dispatch(fetchVODToken({ channelId, vid: VODPost.vodResource.vid }));
  }, [dispatch, VODPost, channelId]);

  useEffect(() => {
    dispatch(cleanUpAllData());
  }, [dispatch]);

  return (
    <>
      {VODPost && VODToken && (
        <>
          <S_PlayerField ref={playerBottomRef}>
            <HLSPlayer src={VODPost.vodResource.playlistSrc} token={VODToken} />
          </S_PlayerField>

          <S_ContentsBox marginTop={playerHeight}>
            <VODInfo title={VODPost.title} createdAt={VODPost.insertedAt} />

            <S_Line />

            <S_Description>{VODPost.content}</S_Description>
          </S_ContentsBox>
        </>
      )}
      {playerHeight > 0 && playlistId && channelId && (
        <BottomSheet offsetBottom={playerHeight} channelCode={channelCode} channelId={channelId} />
      )}
    </>
  );
}

const S_PlayerField = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
`;

const S_ContentsBox = styled.div<{
  marginTop: number;
}>`
  overflow: auto;
  padding-bottom: 72px;
  padding-top: ${({ marginTop }) => marginTop && `${marginTop}px`};

  &::-webkit-scrollbar {
    display: none;
  }
`;

const S_Line = styled.div`
  background-color: ${({ theme }) => theme.grey100};
  height: 1px;
  margin: 0 24px;
`;

const S_Description = styled.div`
  color: ${({ theme }) => theme.grey500};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  line-height: ${({ theme }) => theme.fontLineHeight};
  padding: 24px 24px 0 24px;
  text-align: left;
  white-space: pre-wrap;
  word-break: break-word;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export default VODPostDetail;
