import { ActivePAppsState } from '@common/redux/modules/channel';
import {
  buildPostsPath,
  buildNoticePath,
  buildVodPath,
  buildChatPath,
  buildBookingPath,
  buildFreeBoardPath,
  buildChatCounselingPath,
  buildChannelsPath
} from '@routers/routes';

function activePAppList(channelID: string, list: [string, ActivePAppsState][]) {
  const activePApps: {
    id: number;
    isDefault: boolean;
    title: string;
    path: string;
    icon: string;
  }[] = [];
  if (list) {
    list.forEach(([key, value]: any) => {
      switch (key) {
        case 'E00001':
          activePApps.push({
            title: value.name,
            id: value.id,
            isDefault: value.isDefault,
            path: buildBookingPath(channelID),
            icon: 'ic_Booking'
          });
          break;
        case 'C00001':
          activePApps.push({
            title: value.name,
            id: value.id,
            isDefault: value.isDefault,
            path: buildVodPath(channelID),
            icon: 'ic_Video'
          });
          break;
        case 'C00004':
          activePApps.push({
            title: value.name,
            id: value.id,
            isDefault: value.isDefault,
            path: buildChatCounselingPath(channelID),
            icon: 'ic_ChatCounseling'
          });
          break;
        case 'D00001':
          activePApps.push({
            title: value.name,
            id: value.id,
            isDefault: value.isDefault,
            path: buildChatPath(channelID),
            icon: 'ic_Chat'
          });
          break;
        case 'A00002':
          activePApps.push({
            title: value.name,
            id: value.id,
            isDefault: value.isDefault,
            path: buildNoticePath(channelID),
            icon: 'ic_PostNotice'
          });
          break;
        case 'A00001':
          activePApps.push({
            title: value.name,
            id: value.id,
            isDefault: value.isDefault,
            path: buildPostsPath(channelID),
            icon: 'ic_PostSocial'
          });
          break;
        case 'A00003':
          activePApps.push({
            title: value.name,
            id: value.id,
            isDefault: value.isDefault,
            path: buildFreeBoardPath(channelID),
            icon: 'ic_PostFree'
          });
          break;
        default:
          activePApps.push({
            title: value.name,
            id: value.id,
            isDefault: value.isDefault,
            path: `${buildChannelsPath(channelID)}/${key}`,
            icon: 'ic_Face'
          });
          break;
      }
    });
  }
  return activePApps;
}

export default activePAppList;
