import styled from 'styled-components';

type PurchaseTypeProps = {
  children: string;
};

/**
 * 채널 소개 페이지 - 결제 타입
 *
 * @param {Object} props
 * @param {string} props.children 결제 타입
 */
function PurchaseType({ children }: PurchaseTypeProps) {
  return <S_PurchaseType>{children}</S_PurchaseType>;
}

const S_PurchaseType = styled.span`
  background-color: ${({ theme }) => theme.grey50};
  border-radius: 12px;
  color: ${({ theme }) => theme.grey500};
  font-size: ${({ theme }) => theme.fontSizeCaption1};
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  line-height: ${({ theme }) => theme.fontLineHeight};
  margin-right: 8px;
  padding: 3px 12px;
`;

export default PurchaseType;
