import React from 'react';

import styled from 'styled-components';

type Props = {
  textColorDark?: boolean;
  children: React.ReactNode;
};

function InfoText({ textColorDark, children }: Props) {
  return <S_Text textColorDark={textColorDark}>{children}</S_Text>;
}

const S_Text = styled.span<{
  textColorDark?: boolean;
}>`
  color: ${({ textColorDark, theme }) => (textColorDark ? theme.grey500 : theme.grey400)};
  font-size: ${({ theme }) => theme.fontSizeCaption1};
  margin-right: 8px;
`;

export default InfoText;
