import IconType from '../IconType';

const Video = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M14.8171,4.5072 C16.4741,4.5072 17.8171,5.8502 17.8171,7.5072 L17.8161,9.2682 L20.4064,7.2737 C21.3964,6.5107 22.8304,7.2227 22.8214422,8.4727 L22.7694,15.7337 C22.7604,16.9827 21.3184,17.6747 20.3394,16.8997 L17.8161,14.9032 L17.8171,16.4932 C17.8171,18.1502 16.4741,19.4932 14.8171,19.4932 L4.1781,19.4932 C2.5211,19.4932 1.1781,18.1502 1.1781,16.4932 L1.1781,7.5072 C1.1781,5.8502 2.5211,4.5072 4.1781,4.5072 L14.8171,4.5072 Z"
      />
    </svg>
  );
};

export default Video;
