import api, { getEndpointSet } from '../services/api';

const { SIGNIN, REFRESH, SIGNUP, SIGNOUT, CHECK_ACCOUNT, CHECK_DUPLICATE_EMAIL } = getEndpointSet(
  'AUTH',
  'session'
);

class SessionRepository {
  static signIn(params) {
    return api.post(SIGNIN, {}, params);
  }

  static refresh(params) {
    return api.post(REFRESH, {}, params);
  }

  static signUp(params) {
    return api.post(SIGNUP, {}, params);
  }

  static signOut(params) {
    return api.remove(SIGNOUT, {}, params);
  }

  static checkAccount(params) {
    return api.post(CHECK_ACCOUNT, {}, params);
  }

  static checkDuplicateEmail(params) {
    return api.post(CHECK_DUPLICATE_EMAIL, {}, params);
  }
}

export default SessionRepository;
