import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../Button';
import { ButtonProps } from '../Button/Button';

/**
 * Submit 버튼
 *
 * @param {any} props.component 래핑할 컴포넌트, 기본은 "Button"
 * @param {any} props.rest 나머지 props
 */

type SubmitButtonProps = ButtonProps & {
  children: React.ReactNode;
};

function SubmitButton({ ...rest }: SubmitButtonProps) {
  return <Button type="submit" {...rest} />;
}

SubmitButton.defaultProps = {
  children: null
};

SubmitButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default SubmitButton;
