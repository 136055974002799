/* eslint-disable */
import { TimestampUUID } from '../utils/index.js';

class Order {
  constructor(order) {
    const {
      id,
      orderId,
      siid,
      systemInvoiceId,
      status,
      pgcode,
      serviceName,
      userId,
      userName,
      productName,
      customParameter,
      amount,
      taxfreeAmount,
      taxAmount,
      balance,
      isSubscription,
      isAllowedPgMail,
      email,
      tid,
      cid,
      paymentInfo,
      billkey,
      isForeignCreditcard,
      installmentMonths,
      cardNumber,
      paymentHash,
      sellerInfo1,
      sellerInfo2,
      createdAt,
      updatedAt,
      transactedAt,
      canceledAt,
      systemInvoice
    } = order;

    this.id = id;
    this.orderId = orderId;
    this.siid = siid || systemInvoiceId;
    this.systemInvoiceId = systemInvoiceId || siid;
    this.status = status;
    this.pgcode = pgcode;
    this.serviceName = serviceName;
    this.userId = userId;
    this.userName = userName;
    this.productName = productName;
    this.customParameter = customParameter;
    this.amount = amount;
    this.taxAmount = taxAmount;
    this.taxfreeAmount = taxfreeAmount;
    this.balance = balance;
    this.isSubscription = isSubscription;
    this.isAllowedPgMail = isAllowedPgMail;
    this.email = email;
    this.tid = tid;
    this.cid = cid;
    this.paymentInfo = paymentInfo;
    this.billkey = billkey;
    this.isForeignCreditcard = isForeignCreditcard;
    this.installmentMonths = installmentMonths;
    this.cardNumber = cardNumber;
    this.paymentHash = paymentHash;
    this.sellerInfo1 = sellerInfo1;
    this.sellerInfo2 = sellerInfo2;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.transactedAt = transactedAt;
    this.canceledAt = canceledAt;

    this.systemInvoice = systemInvoice;
  }

  setId(id) {
    this.id = id;
  }

  static fromOrderSheet(orderSheet) {
    const now = new Date();
    const orderId = TimestampUUID.new('PUBL', now);
    const siid = orderSheet.systemInvoice.siid;
    const systemInvoiceId = orderSheet.systemInvoice.siid;
    const status = 'CREATED';
    const pgcode = orderSheet.pgcode;
    const serviceName = orderSheet.serviceName;
    const userId = orderSheet.userId;
    const userName = orderSheet.userName;
    const productName = orderSheet.productName;
    const customParameter = orderSheet.customParameter;
    const amount = orderSheet.amount;
    const balance = {
      currency: 'KRW',
      amount
    };
    const isSubscription = orderSheet.isSubscription;
    const sellerInfo1 = orderSheet.sellerInfo1;
    const sellerInfo2 = orderSheet.sellerInfo2;
    const systemInvoice = orderSheet.systemInvoice;

    return new Order({
      orderId,
      siid,
      systemInvoiceId,
      status,
      pgcode,
      serviceName,
      userId,
      userName,
      productName,
      customParameter,
      amount,
      balance,
      isSubscription,
      sellerInfo1,
      sellerInfo2,
      createdAt: now.toISOString(),
      updatedAt: now.toISOString(),
      systemInvoice
    });
  }

  toJSON() {
    return {
      id: this.id,
      orderId: this.orderId,
      siid: this.siid,
      systemInvoiceId: this.systemInvoiceId,
      status: this.status,
      pgcode: this.pgcode,
      serviceName: this.serviceName,
      userId: this.userId,
      userName: this.userName,
      productName: this.productName,
      customParameter: this.customParameter,
      amount: this.amount,
      taxfreeAmount: this.taxfreeAmount,
      taxAmount: this.taxAmount,
      balance: this.balance,
      isSubscription: this.isSubscription,
      isAllowedPgMail: this.isAllowedPgMail,
      email: this.email,
      tid: this.tid,
      cid: this.cid,
      paymentInfo: this.paymentInfo,
      billkey: this.billkey,
      isForeignCreditcard: this.isForeignCreditcard,
      installmentMonths: this.installmentMonths,
      cardNumber: this.cardNumber,
      paymentHash: this.paymentHash,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      transactedAt: this.transactedAt,
      canceledAt: this.canceledAt,
      sellerInfo1: this.sellerInfo1,
      sellerInfo2: this.sellerInfo2,
      systemInvoice: this.systemInvoice
    };
  }
}

export default Order;
