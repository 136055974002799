import { put, takeEvery, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { VODPAppRepository } from '../../../repositories';
import {
  fetchVODPosts,
  fetchVODPostById,
  fetchPlaylists,
  fetchPlaylistById,
  fetchVODToken,
  fetchPlaylistEpisodes
} from './reducers';
import { digestResponse } from '../../middlewares/response';

function* watchFetchVODPosts(action: ReturnType<typeof fetchVODPosts>) {
  const { type, payload } = action;
  const { channelId, ...restParams } = payload;

  const response: AxiosResponse = yield call(() =>
    VODPAppRepository.findAllVOD(restParams, channelId)
  );

  yield put(digestResponse(response, type));
}

function* watchFetchVODPost(action: ReturnType<typeof fetchVODPostById>) {
  const { type, payload } = action;
  const { postId, channelId } = payload;

  const response: AxiosResponse = yield call(() => VODPAppRepository.findVOD(postId, channelId));

  yield put(digestResponse(response, type));
}

function* watchFetchPlaylists(action: ReturnType<typeof fetchPlaylists>) {
  const { type, payload } = action;
  const { channelId, ...restParams } = payload;

  const response: AxiosResponse = yield call(() =>
    VODPAppRepository.findAllPlaylist(restParams, channelId)
  );
  yield put(digestResponse(response, type));
}

function* watchFetchPlaylist(action: ReturnType<typeof fetchPlaylistById>) {
  const { type, payload } = action;
  const { seriesId, channelId } = payload;

  const response: AxiosResponse = yield call(() =>
    VODPAppRepository.findPlaylist(seriesId, channelId)
  );
  yield put(digestResponse(response, type));
}

function* watchFetchPlaylistEpisodes(action: ReturnType<typeof fetchPlaylistEpisodes>) {
  const { type, payload } = action;
  const { seriesId, channelId } = payload;

  const response: AxiosResponse = yield call(() =>
    VODPAppRepository.findAllEpisodes(seriesId, channelId)
  );
  yield put(digestResponse(response, type));
}

function* watchFetchVODToken(action: ReturnType<typeof fetchVODToken>) {
  const { type, payload } = action;

  const response: AxiosResponse = yield call(() =>
    VODPAppRepository.vodToken(payload.channelId, payload.vid)
  );
  yield put(digestResponse(response, type));
}

function* watch() {
  yield takeEvery(fetchVODPosts, watchFetchVODPosts);
  yield takeEvery(fetchVODPostById, watchFetchVODPost);
  yield takeEvery(fetchPlaylists, watchFetchPlaylists);
  yield takeEvery(fetchPlaylistById, watchFetchPlaylist);
  yield takeEvery(fetchVODToken, watchFetchVODToken);
  yield takeEvery(fetchPlaylistEpisodes, watchFetchPlaylistEpisodes);
}

export default watch;
