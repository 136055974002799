import IconType from '../IconType';

const ArrowLeft2 = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        transform="rotate(-180 10.715 11.975)"
        d="M10.716375,4.0455 L17.533875,10.8645 C17.832375,11.16 17.995875,11.5545 17.995875,11.9745 C17.995875,12.396 17.832375,12.7905 17.532375,13.089 L10.716375,19.9065 C10.569375,20.052 10.377375,20.1255 10.185375,20.1255 C9.993375,20.1255 9.801375,20.052 9.654375,19.9065 C9.361875,19.6125 9.361875,19.1385 9.654375,18.8445 L16.473375,12.027 L9.654375,5.106 C9.361875,4.8135 9.361875,4.338 9.654375,4.0455 C9.948375,3.7515 10.422375,3.7515 10.716375,4.0455 Z M4.716375,4.0455 L11.533875,10.8645 C11.832375,11.16 11.995875,11.5545 11.995875,11.9745 C11.995875,12.396 11.832375,12.7905 11.532375,13.089 L4.716375,19.9065 C4.569375,20.052 4.377375,20.1255 4.185375,20.1255 C3.993375,20.1255 3.801375,20.052 3.654375,19.9065 C3.361875,19.6125 3.361875,19.1385 3.654375,18.8445 L10.473375,12.027 L3.654375,5.106 C3.361875,4.8135 3.361875,4.338 3.654375,4.0455 C3.948375,3.7515 4.422375,3.7515 4.716375,4.0455 Z"
      />
    </svg>
  );
};

export default ArrowLeft2;
