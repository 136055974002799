import styled from 'styled-components';

import { StyledNamespace } from '@common/types/styledNamespace';

const S: StyledNamespace = {};

S.ProfileImage = styled.div`
  border-radius: 20px;
  height: 40px;
  position: relative;
  width: 40px;

  .circle {
    background-color: ${(props) => props.theme.green500};
    border: 2px solid ${(props) => props.theme.white};
    border-radius: 6px;
    height: 8px;
    left: -1px;
    position: absolute;
    top: -1px;
    width: 8px;
  }

  .image {
    border-radius: inherit;
    height: inherit;
    width: inherit;
  }
`;

type ProfileImageProps = {
  isOnline: boolean;
  imageSrc: string;
};

const ProfileImage = ({ isOnline, imageSrc }: ProfileImageProps) => {
  return (
    <S.ProfileImage>
      {isOnline && <div className="circle" />}
      <img className="image" src={imageSrc} alt="profile" />
    </S.ProfileImage>
  );
};

export default ProfileImage;
