import styled from 'styled-components';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Form,
  SubmitButton,
  InputFieldContainer,
  InputFieldButton,
  Icon
} from '@common/ui/components';

type InputBarProps = {
  onSubmit: ({ message }: { message: string }) => void;
};

function InputBar({ onSubmit }: InputBarProps) {
  const methods = useForm({
    mode: 'onChange'
  });

  const { handleSubmit, reset, watch } = methods;
  const inputValue = watch('message');

  const handleSubmitAndReset = (formData: { message: string }) => {
    onSubmit(formData);
    reset();
  };

  return (
    <InputFieldContainer chat>
      <FormProvider {...methods}>
        <Form displayButton onSubmit={handleSubmit(handleSubmitAndReset)}>
          <InputFieldButton name="message" maxLength={Infinity} />
          <ButtonBox>
            <SubmitButton
              fill={false}
              inputIcon
              icon={
                inputValue ? (
                  <Icon iconName="ic_PaperPlane" colorCode="blue500" fill />
                ) : (
                  <Icon iconName="ic_PaperPlane" colorCode="grey400" fill />
                )
              }
            />
          </ButtonBox>
        </Form>
      </FormProvider>
    </InputFieldContainer>
  );
}

const ButtonBox = styled.div`
  align-items: center;
  display: flex;
  margin: 0 8px;
`;

export default InputBar;
