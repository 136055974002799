import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Icon } from '@common/ui/components';
import DateHelper from '@common/utils/dateHelper';
import { buildVodPlusPath } from '@routers/routes';

type Props = {
  video: HTMLVideoElement | null;
  currentTime: number;
  playerWidth: number;
  invitationCode: string;
  seriesId: string;
  isPlaying: boolean;
  setIsPlaying: (value: boolean) => void;
  isMuted: boolean;
  muteHandler: () => void;
  isControllerOpen: boolean;
  controllerHandler: () => void;
  playBarHandler: (e: any) => void;
  playerSettingHandler: (value: boolean) => void;
  fullscreenToggle: () => void;
};

function VodPlusPlayerController({
  video,
  currentTime,
  playerWidth,
  invitationCode,
  seriesId,
  isPlaying,
  setIsPlaying,
  isMuted,
  muteHandler,
  isControllerOpen,
  controllerHandler,
  playBarHandler,
  playerSettingHandler,
  fullscreenToggle
}: Props) {
  const playBarHandle = useRef(null);
  const history = useHistory();
  const vodPlusPath = buildVodPlusPath(invitationCode);

  const [playTime, setPlayTime] = useState<number>(currentTime);
  const [isPlayBarWatcher, setIsPlayBarWatcher] = useState<boolean>(false);
  const TempTotalPlayTime = video?.duration || 1;

  const [playTimeBarPer, setPlayTimeBarPer] = useState<number>((playTime / TempTotalPlayTime) * 75);
  const playerCalRate = (TempTotalPlayTime * 100) / (playerWidth * 0.75) / 100;

  const videoPlay = () => {
    if (isPlaying) {
      video?.pause();
      setIsPlaying(false);
    } else {
      video?.play();
    }
  };

  const videoHandlePositioner = (value: number) => {
    if (value - 24 < 0) {
      setPlayTimeBarPer(0);
      return;
    }
    if (value - 24 > playerWidth * 0.75) {
      setPlayTimeBarPer(75);
      return;
    }
    setPlayTimeBarPer(((value - 24) / (playerWidth * 0.75)) * 75);
  };

  useEffect(() => {
    if (video && !isPlayBarWatcher) {
      const playTimeShowInterval = setInterval(() => {
        setPlayTime(video.currentTime);
        setPlayTimeBarPer((video.currentTime / TempTotalPlayTime) * 75);
      }, 1000);

      return () => {
        clearInterval(playTimeShowInterval);
      };
    }
    if (video && isPlayBarWatcher) {
      const playTimeShowInterval = setInterval(() => {
        setPlayTime(video.currentTime);
      }, 1000);

      return () => {
        clearInterval(playTimeShowInterval);
      };
    }
  }, [video, TempTotalPlayTime, isPlayBarWatcher]);

  return (
    <S_Controller
      isControllerOpen={isControllerOpen}
      playerWidth={playerWidth}
      onClick={controllerHandler}
    >
      <S_TopLeft
        onClick={(e) => {
          e.stopPropagation();
          history.push(`${vodPlusPath}/serieses/${seriesId}`);
        }}
      >
        <S_IconWrapper>
          <Icon iconName="ic_ArrowLeft" colorCode="white" size={24} />
        </S_IconWrapper>
      </S_TopLeft>
      <S_TopRight>
        <S_IconWrapper
          onClick={(e) => {
            e.stopPropagation();
            muteHandler();
          }}
        >
          <Icon iconName={isMuted ? 'ic_Mute' : 'ic_Speaker'} colorCode="white" size={24} />
        </S_IconWrapper>
        <S_IconWrapper
          onClick={(e) => {
            e.stopPropagation();
            playerSettingHandler(true);
          }}
        >
          <Icon iconName="ic_More" colorCode="white" size={24} />
        </S_IconWrapper>
      </S_TopRight>
      <S_Center>
        <S_IconWrapper
          onClick={(e) => {
            e.stopPropagation();
            videoPlay();
          }}
        >
          <Icon
            iconName={isPlaying ? 'ic_VideoPause' : 'ic_VideoPlay'}
            colorCode="white"
            size={48}
          />
        </S_IconWrapper>
      </S_Center>
      <S_BottomLeft>
        <S_PlayBar />
        <S_PlayedRangeBar playedRange={playTimeBarPer} onClick={() => {}} />
        <S_PlayBarhandle
          ref={playBarHandle}
          handlePosition={playTimeBarPer}
          onClick={() => {}}
          onTouchStart={(e) => {
            e.stopPropagation();
            setIsPlayBarWatcher(true);
          }}
          onTouchMove={(e) => {
            e.stopPropagation();
            videoHandlePositioner(e.targetTouches[0].clientX);
          }}
          onTouchEnd={(e) => {
            e.stopPropagation();
            playBarHandler((e.changedTouches[0].clientX - 24) * playerCalRate);
            setIsPlayBarWatcher(false);
          }}
        />
        <S_Timer>
          {`${DateHelper.makeTimeFormatDate(playTime)} / ${DateHelper.makeTimeFormatDate(
            TempTotalPlayTime
          )}`}
        </S_Timer>
      </S_BottomLeft>
      <S_BottomRight>
        <S_IconWrapper
          onClick={(e) => {
            e.stopPropagation();
            fullscreenToggle();
          }}
        >
          <Icon iconName="ic_PageSizeExpand" colorCode="white" size={24} />
        </S_IconWrapper>
      </S_BottomRight>
    </S_Controller>
  );
}

const S_Controller = styled.div<{ playerWidth: number; isControllerOpen: boolean }>`
  background-color: rgba(0, 0, 0, 0.5);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  height: ${({ playerWidth }) => playerWidth * 0.5625}px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`;

const S_IconWrapper = styled.div`
  padding: 12px;
`;

const S_TopLeft = styled.div`
  align-items: center;
  display: flex;
  grid-column-end: 1;
  grid-column-start: 1;
  grid-row-end: 1;
  grid-row-start: 1;
  justify-content: flex-start;
  padding-left: 8px;
`;

const S_TopRight = styled.div`
  align-items: center;
  display: flex;
  grid-column-end: 6;
  grid-column-start: 4;
  grid-row-end: 1;
  grid-row-start: 1;
  justify-content: flex-end;
  padding-right: 8px;
`;

const S_Center = styled.div`
  align-items: center;
  display: flex;
  grid-column-end: 3;
  grid-column-start: 3;
  grid-row-end: 3;
  grid-row-start: 2;
  justify-content: center;
`;

const S_BottomLeft = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.white};
  display: flex;
  grid-column-end: 3;
  grid-column-start: 1;
  grid-row-end: 3;
  grid-row-start: 3;
  padding-left: 24px;
  text-align: left;
`;

const S_BottomRight = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.white};
  display: flex;
  grid-column-end: 6;
  grid-column-start: 4;
  grid-row-end: 3;
  grid-row-start: 3;
  justify-content: flex-end;
  padding-right: 8px;
`;

const S_PlayBar = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  bottom: 16px;
  cursor: pointer;
  height: 2px;
  margin-bottom: 24px;
  position: absolute;
  width: 75%;
`;

const S_PlayedRangeBar = styled.div<{ playedRange: number }>`
  background-color: ${({ theme }) => theme.white};
  bottom: 16px;
  height: 2px;
  margin-bottom: 24px;
  position: absolute;
  width: ${({ playedRange }) => `calc(${playedRange}%)`};
`;

const S_PlayBarhandle = styled.div<{ handlePosition: number }>`
  background-color: ${({ theme }) => theme.white};
  border-radius: 18px;
  bottom: 32px;
  height: 18px;
  left: 24px;
  margin-left: ${({ handlePosition }) => handlePosition}%;
  position: absolute;
  width: 18px;
`;

const S_Timer = styled.div`
  bottom: 8px;
  font-size: ${({ theme }) => theme.fontSizeBody2};
  position: absolute;
`;

export default VodPlusPlayerController;
