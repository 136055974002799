import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Icon } from '@common/ui/components';

type Props = {
  totalPageCount: number;
  currentPage: number;
  onNext: () => void;
  onPrev: () => void;
};

function PageNavigation({ totalPageCount, currentPage, onNext, onPrev }: Props) {
  const { t } = useTranslation('translation');

  return (
    <S_PageNavigation>
      <S_Cell onClick={onPrev}>
        <Icon iconName="ic_ArrowLeft" colorCode="grey500" size={16} />
      </S_Cell>
      <S_PageCount>
        {t('str_page_out_of_total', {
          currPage: currentPage,
          totalPage: totalPageCount,
          defaultValue: '{{currPage}} / {{totalPage}}'
        })}
      </S_PageCount>
      <S_Cell onClick={onNext}>
        <Icon iconName="ic_ArrowRight" colorCode="grey500" size={16} />
      </S_Cell>
    </S_PageNavigation>
  );
}

const S_PageNavigation = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  margin-top: 38px;
  width: 100%;
`;

const S_PageCount = styled.div`
  color: ${({ theme }) => theme.grey500};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  text-align: center;
  width: 100px;
`;

const S_Cell = styled.div`
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  width: 48px;
`;

export default PageNavigation;
