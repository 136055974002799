import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { buildVodPath } from '@routers/routes';

type Props = {
  channelCode: string;
};

function TabBar({ channelCode }: Props) {
  const { t } = useTranslation('translation');
  const history = useHistory();
  const { pathname } = history.location;

  const postsRoute = `${buildVodPath(channelCode)}/posts`;
  const playlistsRoute = `${buildVodPath(channelCode)}/playlists`;

  return (
    <S_TabBar>
      <S_TabCell isBorderColored={pathname === postsRoute} onClick={() => history.push(postsRoute)}>
        {t('str_papp_vod_video', '동영상')}
      </S_TabCell>

      <S_TabCell
        isBorderColored={pathname === playlistsRoute}
        onClick={() => history.push(playlistsRoute)}
      >
        {t('str_papp_vod_playlist', '재생목록')}
      </S_TabCell>
    </S_TabBar>
  );
}

const S_TabBar = styled.div`
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex: 1;
  width: 100%;
`;

const S_TabCell = styled.div<{
  isBorderColored: boolean;
}>`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.grey100};
  box-sizing: border-box;
  color: ${({ theme }) => theme.grey400};
  display: flex;
  flex: 1;
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  height: 48px;
  justify-content: center;
  text-align: center;
  width: 100%;

  ${({ isBorderColored }) =>
    isBorderColored &&
    css`
      border-bottom: 2px solid ${({ theme }) => theme.blue500};
      color: ${({ theme }) => theme.blue500};
    `};
`;

export default TabBar;
