import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import {
  getBookingAvailableDates,
  getEventsAvailable,
  getIsBookingLoading
} from '@common/redux/modules/booking';
import {
  eidSelect,
  fetchCalendar,
  fetchEvents,
  timeSelect
} from '@common/redux/modules/booking/reducers';
import DateHelper from '@common/utils/dateHelper';
import { CalendarTemplate, MonthSelectModal } from './templates';

type BookingCalendarProps = {
  handleGoToday: () => void;
  handleRefDate: (currentFormat: ReturnType<typeof DateHelper.getInstance>) => void;
  handleSelectDate: (currentFormat: ReturnType<typeof DateHelper.getInstance>) => void;
  handleSelectAnotherDay: (currentFormat: ReturnType<typeof DateHelper.getInstance>) => void;
  refDate: ReturnType<typeof DateHelper.getInstance>;
  selectDate: ReturnType<typeof DateHelper.getInstance>;
  channelID: string;
};

function BookingCalendar({
  handleGoToday,
  handleRefDate,
  handleSelectDate,
  handleSelectAnotherDay,
  refDate,
  selectDate,
  channelID
}: BookingCalendarProps) {
  const dispatch = useDispatch();

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const [monthCounter, setMonthCounter] = useState(0);
  const [monthSelect, setMonthSelect] = useState(DateHelper.getInstance());
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const endWeek = DateHelper.getInstance(refDate).endOf('month').week();
  const startWeek = DateHelper.getInstance(refDate).startOf('month').week();
  const isBookingLoading = useSelector(getIsBookingLoading);
  const bookingAvailableDates = useSelector(getBookingAvailableDates);

  const handleIsDropDownOpen = () => {
    setIsDropDownOpen(!isDropDownOpen);
  };

  const handleChangeMonthButton = () => {
    handleDropDownMonth(monthSelect);
    handleIsDropDownOpen();
  };

  const handleDropDownMonth = (newRefDate: ReturnType<typeof DateHelper.getInstance>) => {
    handleRefDate(newRefDate.startOf('month'));
    handleSelectDate(newRefDate.startOf('month'));
  };

  const eventsAvailable = useSelector(getEventsAvailable);

  const handleSelectTime = (current: { id: string; title: string }) => {
    dispatch(eidSelect(current.id));
    dispatch(timeSelect(current.title));
  };

  useEffect(() => {
    const end =
      refDate.format('M') === '12'
        ? refDate.week(53).endOf('week').add(1, 'day').format('YYYY-MM-DD')
        : refDate.week(endWeek).endOf('week').add(1, 'day').format('YYYY-MM-DD');
    const start = refDate.week(startWeek).startOf('week').add(0, 'day').format('YYYY-MM-DD');
    if (currentChannel) {
      dispatch(fetchCalendar({ date: { start, end }, channelId: currentChannel.id }));
      dispatch(
        fetchEvents({ date: selectDate.format('YYYY-MM-DD'), channelId: currentChannel.id })
      );
    }
  }, [dispatch, refDate, startWeek, endWeek, selectDate, currentChannel]);

  return (
    <S_CalendarPage>
      {isDropDownOpen && (
        <MonthSelectModal
          monthCounter={monthCounter}
          setMonthCounter={setMonthCounter}
          monthSelect={monthSelect}
          setMonthSelect={setMonthSelect}
          handleChangeMonthButton={handleChangeMonthButton}
          handleIsDropDownOpen={handleIsDropDownOpen}
        />
      )}
      <CalendarTemplate
        bookingAvailableDates={bookingAvailableDates}
        eventsAvailable={eventsAvailable}
        endWeek={endWeek}
        handleGoToday={handleGoToday}
        handleIsDropDownOpen={handleIsDropDownOpen}
        handleSelectAnotherDay={handleSelectAnotherDay}
        handleSelectTime={handleSelectTime}
        isBookingLoading={isBookingLoading}
        refDate={refDate}
        selectDate={selectDate}
        startWeek={startWeek}
        channelID={channelID}
      />
    </S_CalendarPage>
  );
}

const S_CalendarPage = styled.div`
  background-color: white;
`;

export default BookingCalendar;
