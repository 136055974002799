import React, { useState, useEffect, useCallback, useRef, createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';

const defaultScrollData = {
  parent: {
    ref: null,
    scrollTop: 0,
    scrollHeight: 0,
    height: 0
  },
  container: {
    ref: null,
    scrollTop: 0,
    scrollHeight: 0,
    height: 0,
    current: HTMLElement
  },
  checkScroll: (...args: any) => args,
  scrollToTop: (...args: any) => args,
  scrollToBottom: (...args: any) => args,
  scrollBy: {}
};
const ScrollContext = createContext(defaultScrollData);

/**
 * 스크롤 정보 프로바이더
 *
 * @param {React.Ref} containerRef 스크롤을 감지할 컨테이너 Ref
 * @param {number} debounce 디바운스 적용할 시간(밀리초 단위), 0이면 적용하지 않음.
 */
function ScrollProvider({
  containerRef,
  debounce = 0,
  children
}: {
  containerRef: any;
  debounce?: number;
  children?: React.ReactNode;
}) {
  const [container, setContainer] = useState<any>(defaultScrollData.container);
  const timer = useRef<any>(null);
  const { container: parentContainer } = useContext(ScrollContext);

  // 스크롤 체크 콜백
  const handleScroll = useCallback(() => {
    function cb() {
      const elem = containerRef.current;

      if (elem) {
        setContainer({
          ref: containerRef,
          scrollTop: elem.scrollTop,
          scrollHeight: elem.scrollHeight,
          height: elem.clientHeight
        });
      }

      timer.current = null;
    }

    // 디바운스 처리
    if (debounce) {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(cb, debounce);
    } else {
      // 디바운스 처리가 아닌 경우 중복 호출하지 않도록 방어
      if (timer.current) {
        return;
      }

      timer.current = true;

      window.requestAnimationFrame(cb);
    }
  }, [containerRef, debounce]);
  // 스크롤 상단으로 이동 콜백
  const scrollToTop = useCallback(() => {
    window.requestAnimationFrame(() => {
      if (containerRef.current) {
        const el = containerRef.current;

        if (el) {
          el.scrollTop = 0;
        }
      }
    });
  }, [containerRef]);
  // 스크롤 하단으로 이동 콜백
  const scrollToBottom = useCallback(() => {
    window.requestAnimationFrame(() => {
      if (containerRef.current) {
        const el = containerRef.current;

        if (el) {
          el.scrollTop = el.scrollHeight;
        }
      }
    });
  }, [containerRef]);
  // 상대적 수치로 스크롤 이동 콜백
  const scrollBy = useCallback(
    (dx, dy) => {
      if (containerRef.current) {
        const el = containerRef.current;

        if (el) {
          el.scrollLeft += dx;
          el.scrollTop += dy;
        }
      }
    },
    [containerRef]
  );

  useEffect(() => {
    const elem = containerRef.current;

    if (elem) {
      // window 글로벌 이벤트 등록을 피하기 위해 ResizeObserver 사용
      // TODO: ResizeObserver는 IE를 제외하고 다 지원하는 중이지만 아직 표준확립전임. 표준이 확정되면 이 구문은 지우기로.
      if (window.ResizeObserver) {
        const ro = new window.ResizeObserver(handleScroll);
        ro.observe(elem);
        elem.addEventListener('scroll', handleScroll);

        return () => {
          ro.disconnect();
          elem.removeEventListener('scroll', handleScroll);
        };
      }

      elem.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleScroll);

      return () => {
        elem.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleScroll);
      };
    }
    return (...args: any) => args;
  }, [containerRef, handleScroll]);

  return (
    <ScrollContext.Provider
      value={{
        parent: parentContainer,
        container,
        checkScroll: handleScroll,
        scrollToTop,
        scrollToBottom,
        scrollBy
      }}
    >
      {children}
    </ScrollContext.Provider>
  );
}

/**
 * 스크롤 갱신 이벤트 컴포넌트
 * ScrollProvider의 데이터를 이용해 원하는 이벤트를 호출하도록 한다.
 *
 * 각 콜백은 useCallback이나 클래스 메소드로 선언하여 렌더링 시마다 달라지지 않도록 주의!
 *
 * @param {Object} props
 * @param {function} props.onScroll 스크롤 이벤트 콜백
 * @param {function} props.onEndReached 스크롤 하단 접촉 이벤트 콜백
 * @param {number} props.bumperSize 하단 접촉 이벤트가 일어날 하단 영역 높이
 */
function ScrollUpdater({
  onScroll,
  onEndReached,
  bumperSize = 50
}: {
  onScroll?: Function;
  onEndReached?: Function;
  bumperSize: number;
}) {
  const { container, checkScroll } = useContext(ScrollContext);

  // 최초 컴포넌트가 로딩될 때 스크롤을 확인해야 함.
  useEffect(() => {
    checkScroll();
  }, [checkScroll]);

  // 스크롤 하단 영역에 스크롤이 닿았는지 여부 확인 후 onEndReached 콜백 호출.
  useEffect(() => {
    const endReached =
      container.scrollTop >= container.scrollHeight - container.height - bumperSize;
    const overflowed = container.scrollHeight > container.height + bumperSize;

    // WARNING: 아래처럼 컨테이너의 높이가 0인 경우 (로딩이 덜 된 경우) 콜백 호출을 막았는데
    // 맥에서는 첫번째 이벤트 후에 컨테이너 높이가 변경되더라도 이벤트가 다시 발생하지 않는 듯함.
    // 그래서 처음에 콜백이 여러번 불리는 현상이 있더라도 아예 안불리는 것보다는 나을듯.
    // ScrollUpdater를 쓰는 경우 여러번 콜백이 불릴 것이라는 것을 염두에 두고 작업해야 함.
    // if (container.height > bumperSize && endReached && onEndReached) {
    if (endReached && onEndReached) {
      const r = onEndReached(container);

      // 만약 onEndReached가 true를 반환하고 스크롤 영역이 없을 경우, 스크롤 영역이 생길 때 까지 checkScroll을 계속 호출함.
      if (!overflowed) {
        if (r instanceof Promise) {
          r.then((checkAgain) => {
            if (checkAgain) {
              checkScroll();
            }
          });
        } else if (r) {
          checkScroll();
        }
      }
    }
  }, [container, onEndReached, checkScroll, bumperSize]);

  // 스크롤 이벤트 발생시 마다 onScroll 콜백 호출.
  useEffect(() => {
    if (onScroll) {
      onScroll(container);
    }
  }, [container, onScroll]);

  return <></>;
}

/**
 * 스크롤 컨텍스트 안에서 스크롤을 맨 위로 이동시킨다.
 */
function ScrollToTop() {
  const { scrollToTop } = useContext(ScrollContext);
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [scrollToTop, pathname]);

  return <></>;
}

/**
 * 스크롤 컨텍스트 사용 훅
 */
function useScrollContext() {
  return useContext(ScrollContext);
}

/**
 * 스크롤 컨텍스트 HOC
 *
 * @param {React.Component} Component
 */
function withScrollContext(Component: any) {
  return function scrollContext(props: any) {
    <ScrollContext.Consumer>
      {(values) => <Component {...props} {...values} />}
    </ScrollContext.Consumer>;
  };
}

export default ScrollContext;

export { useScrollContext, withScrollContext, ScrollProvider, ScrollUpdater, ScrollToTop };
