export const appPath = /^(\/channels)/i;

// core Path
export const rootPath = '/';
export const splashPath = '/splash';
export const accountPath = '/account';
export const accountProfilePath = `${accountPath}/profile`;
export const subscriptionsPath = '/subscriptions';
export const subsDetailPath = `${subscriptionsPath}/detail`;
export const signInPath = '/signin';
export const signUpPath = '/signup';
export const signUpAddInfoPath = '/add-info';
export const signUpCountryInfoPath = '/country-info';
export const resetPwPath = '/resetpw';
export const certifyPath = '/certify';
export const notFoundPath = '/404';

export const purchaseProductBasicPath = `/purchase-product`;

export const purchaseProductPath = `/purchase-product/:siid`;
export const buildPurchaseProductPath = (siid: string, invitationCode: string) =>
  `/purchase-product/${siid}?ret=${invitationCode}`;

// pApp Basic Path
export const channelBasicPath = '/channels/:channelID';
export const channelBasicPAppPath = '/channels/:channelID/:pAppCode';
export const channelsAuthPath = `${channelBasicPath}/auth`;
export const channelDeactivatePath = `${channelBasicPath}/channel-deactivate`;
export const postsBasicPath = `${channelBasicPath}/A00001`;
export const noticeBasicPath = `${channelBasicPath}/A00002`;
export const freeboardBasicPath = `${channelBasicPath}/A00003`;
export const vodBasicPath = `${channelBasicPath}/C00001`;
export const vodPlusBasicPath = `${channelBasicPath}/C00002`;
export const chatBasicPath = `${channelBasicPath}/D00001`;
export const bookingBasicPath = `${channelBasicPath}/E00001`;

export const buildNoticePath = (channelID) => `/channels/${channelID}/A00002`;
export const buildSocialCommunityPostsPath = (channelID) => `/channels/${channelID}/A00001`;
export const buildVodPath = (channelID) => `/channels/${channelID}/C00001`;
export const buildVodPlusPath = (channelID) => `/channels/${channelID}/C00002`;
export const buildChatPath = (channelID) => `/channels/${channelID}/D00001`;
export const buildChatCounselingPath = (channelID) => `/channels/${channelID}/C00004`;
export const buildBookingPath = (channelID) => `/channels/${channelID}/E00001`;
export const buildFreeBoardPath = (channelID) => `/channels/${channelID}/A00003`;

// pApp - Channel path
export const buildChannelsPath = (channelID) => `/channels/${channelID}`;
export const buildChannelsAuthPath = (channelID) => `/channels/${channelID}/auth`;
export const buildChannelsDeactivate = (channelID) => `/channels/${channelID}/channel-deactivate`;
export const buildChannelsAddInfoPath = (channelID) => `/channels/${channelID}/add-info`;
export const buildChannelsSettingPath = (channelID) => `/channels/${channelID}/settings`;
export const buildChannelsSellerInfoPath = (channelID) =>
  `/channels/${channelID}/settings/seller-info`;
export const buildChannelsProfilePath = (channelID) => `/channels/${channelID}/settings/profile`;
export const buildChannelsPaymentPath = (channelID) => `/channels/${channelID}/settings/payment`;
export const buildChannelsReportPath = (channelID) => `/channels/${channelID}/settings/report`;
export const buildChannelsPublrInfoPath = (channelID) =>
  `/channels/${channelID}/settings/publr-info`;

// pApp - Post path
export const buildPostsPath = (channelID) => `${buildSocialCommunityPostsPath(channelID)}/posts`;
export const buildPostDetailPath = (channelID, postID) =>
  `${buildSocialCommunityPostsPath(channelID)}/posts/${postID}`;
export const buildCreatePostPath = (channelID) =>
  `${buildSocialCommunityPostsPath(channelID)}/create`;
export const buildEditPostPath = (channelID, postID) =>
  `${buildSocialCommunityPostsPath(channelID)}/posts/${postID}/edit`;

// pApp - Vod path
export const buildVodPostDetailPath = (channelID, vodID) =>
  `/channels/${channelID}/C00001/posts/${vodID}`;
export const buildVodPlaylistDetailPath = (channelID, playlistID) =>
  `/channels/${channelID}/C00001/playlists/${playlistID}`;
export const buildPlaylistEpisodePath = (channelID, playlistID, postID) =>
  `/channels/${channelID}/C00001/playlists/${playlistID}/posts/${postID}`;

// pApp - VOD+ path
export const buildSeriesEpisodePath = (channelID, seriesId, episodeId) =>
  `/channels/${channelID}/C00002/sereises/${seriesId}/episodes/${episodeId}`;

// pApp - Chat path
export const buildChatDetailPath = (channelID, profileId) =>
  `/channels/${channelID}/D00001/chat/${profileId}`;

// pApp - Booking path
export const buildBookingDescPath = (channelID) => `/channels/${channelID}/E00001/calendar/booking`;
export const buildBookingMyBookingPath = (channelID) =>
  `/channels/${channelID}/E00001/calendar/my-booking`;
export const buildBookingStationPath = (channelID) => `/channels/${channelID}/E00001/station`;
export const buildBookingMyBookingDescPath = (channelID) =>
  `/channels/${channelID}/E00001/my-booking-desc`;
export const buildBookingMyTicketPath = (channelID) =>
  `/channels/${channelID}/E00001/calendar/my-ticket`;
export const buildBookingConfirmPath = (channelID) =>
  `/channels/${channelID}/E00001/calendar/booking/confirm`;
export const buildBookingMyBookingHistoryPath = (channelID) =>
  `/channels/${channelID}/E00001/calendar/my-booking-history`;

// pApp - Notice path
export const buildNoticeDetailPath = (channelID, postID) =>
  `/channels/${channelID}/A00002/posts/${postID}`;

// pApp - Freeboard path
export const buildFreeBoardDetailPath = (channelID, postID) =>
  `/channels/${channelID}/A00003/posts/${postID}`;
export const buildCreateFreeBoardPath = (channelID) => `/channels/${channelID}/A00003/create`;
export const buildEditFreeBoardPath = (channelID, postID) =>
  `/channels/${channelID}/A00003/posts/${postID}/edit`;
