import { createSelector } from 'reselect';
import { RootState } from '../../index';

export const getIsSubscriptionProductLoading = (state: RootState) => {
  return state.subscriptionProduct.isLoading;
};

export const getSubscriptionProduct = (state: RootState) => {
  return state.subscriptionProduct.subscriptionProduct;
};

export const getSubscriptionProductError = (state: RootState) => {
  return state.subscriptionProduct.error;
};

export const makeGetIsSubscriptionProductLoading = () => {
  return createSelector([getIsSubscriptionProductLoading], (isLoading) => isLoading);
};

export const makeGetSubscriptionProduct = () => {
  return createSelector([getSubscriptionProduct], (subscriptionProduct) => subscriptionProduct);
};
