import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getMyBookingDesc, getCheckBookingAvailable } from '@common/redux/modules/booking';
import { fetchMyBookingDesc, responseStatusReset } from '@common/redux/modules/booking/reducers';
import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import { MyBookingDescTemplate } from './templates';

function MyBookingDesc() {
  const dispatch = useDispatch();
  const location = useLocation<{ id: string; fromHistory: boolean }>();

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const { id, fromHistory } = location.state;

  const myBookingDesc = useSelector(getMyBookingDesc);
  const responseStatus = useSelector(getCheckBookingAvailable);

  useEffect(() => {
    if (currentChannel) {
      dispatch(
        fetchMyBookingDesc({ id, pagination: { page: 1, limit: 10 }, channelId: currentChannel.id })
      );
      dispatch(responseStatusReset());
    }
  }, [currentChannel, dispatch, id, responseStatus]);

  return (
    <S_MyBookingDescContainer>
      <MyBookingDescTemplate myBookingDesc={myBookingDesc.booking} fromHistory={fromHistory} />
    </S_MyBookingDescContainer>
  );
}

const S_MyBookingDescContainer = styled.div`
  background-color: 'white';
`;

export default MyBookingDesc;
