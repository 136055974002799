import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import styled from 'styled-components';

import { DescImage } from '../atoms';
import { DescBlock } from '../molecules';
import { TitleBlock } from '../organisms';

function ChannelDescView() {
  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);

  const currentChannel = useSelector(getCurrentChannel);

  return (
    <S_ChannelsDescView>
      <S_Wrapper>
        <DescImage ratio={1 / 1} coverUrl={currentChannel && currentChannel.iconImageSrc} />
        <TitleBlock title={currentChannel && currentChannel.title} />
        <DescBlock description={currentChannel && currentChannel.channelDescription.body} />
      </S_Wrapper>
    </S_ChannelsDescView>
  );
}

const S_ChannelsDescView = styled.div`
  background-color: rgba(0, 0, 0, 0);
  border-radius: 24px 24px 0 0;
  position: relative;
  width: 100%;
`;

const S_Wrapper = styled.div`
  border-radius: 24px 24px 0 0;
  box-shadow: -30px 0 100px -100px #000, 30px 0 100px -100px #000;
  height: 100vh;
  margin-top: -48px;
  width: 100%;
`;

export default ChannelDescView;
