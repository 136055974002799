import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { StyledNamespace } from '@common/types/styledNamespace';

const S: StyledNamespace = {};

type BodyBlockProps = {
  children?: React.ReactNode;
};

/**
 * 모달 다이얼로그 내용 컴포넌트
 *
 * @param {Object} props
 */
function BodyBlock({ children }: BodyBlockProps) {
  return <S.BodyBlock>{children}</S.BodyBlock>;
}

BodyBlock.propTypes = {
  children: PropTypes.node.isRequired
};

S.BodyBlock = styled.div`
  display: inline-block;
  width: 100%;
`;

export default BodyBlock;
