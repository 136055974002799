import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import styled from 'styled-components';

import { getNoticePosts, getTotalPostsCount } from '@common/redux/modules/noticePApp';
import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import { fetchNoticePosts } from '@common/redux/modules/noticePApp/reducers';
import { SmartLink } from '@common/ui/components';
import { OFFSET_DEFAULT_LIMIT } from '@common/constants/index';

import { NavBar } from '../../components/molcules';
import { PageNavigation, NoticeCard } from './molecules';
import { buildChannelsPath, buildNoticeDetailPath, buildNoticePath } from '@routers/routes';

function NoticePosts() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const { t } = useTranslation('translation');

  const [currentPage, setCurrentPage] = useState(Number(params.get('page')) || 1);

  const [channelCode, setChannelCode] = useState('');
  const [channelId, setChannelId] = useState(0);

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const noticePosts = useSelector(getNoticePosts);
  const totalPostsCount = useSelector(getTotalPostsCount);

  useEffect(() => {
    if (currentChannel) {
      setChannelCode(currentChannel.primitiveInvitationCode);
      setChannelId(currentChannel.id);
    }
  }, [currentChannel]);

  useEffect(() => {
    if (!channelId) return;

    dispatch(
      fetchNoticePosts({
        limit: OFFSET_DEFAULT_LIMIT,
        page: currentPage,
        isPublished: true,
        channelId
      })
    );
  }, [dispatch, currentPage, channelId]);

  const handlePageDown = () => {
    if (currentPage === 1) return;

    setCurrentPage(currentPage - 1);
    history.push(
      `${buildNoticePath(channelCode)}/posts?page=${currentPage - 1}&limit=${OFFSET_DEFAULT_LIMIT}`
    );
  };

  const handlePageUp = () => {
    if (currentPage * OFFSET_DEFAULT_LIMIT >= totalPostsCount) return;

    setCurrentPage(currentPage + 1);
    history.push(
      `${buildNoticePath(channelCode)}/posts?page=${currentPage + 1}&limit=${OFFSET_DEFAULT_LIMIT}`
    );
  };

  return (
    <>
      <NavBar
        isMenu
        title={t('str_papp_notice_id', '공지사항')}
        backUrl={buildChannelsPath(channelCode)}
      />

      <S_EmptyDiv />

      {noticePosts.length === 0 ? (
        <S_NoItem>{t('str_374', '아직 업로드한 게시글이 없습니다.')}</S_NoItem>
      ) : (
        <>
          <S_List>
            {noticePosts.map(({ id, title, author, insertedAt, totalComments }) => (
              <SmartLink key={id} to={buildNoticeDetailPath(channelCode, id)}>
                <NoticeCard
                  key={id}
                  title={title}
                  author={author}
                  createdAt={insertedAt}
                  viewCount={0}
                  commentCount={totalComments}
                />
              </SmartLink>
            ))}
          </S_List>
          <PageNavigation
            currentPage={currentPage}
            totalPageCount={Math.ceil(totalPostsCount / OFFSET_DEFAULT_LIMIT)}
            onNext={handlePageUp}
            onPrev={handlePageDown}
          />
        </>
      )}
    </>
  );
}

const S_NoItem = styled.div`
  color: ${({ theme }) => theme.grey400};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  margin-top: 96px;
`;

const S_EmptyDiv = styled.div`
  height: 56px;
`;

const S_List = styled.div`
  min-height: 584px;
`;

export default NoticePosts;
