import { put, fork, takeEvery, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { SubscriptionRepository } from '../../../repositories';
import { watchRefreshAccessToken } from '../session/sagas';
import { fetchLightningCheckout, fetchRefresh } from './reducers';
import { digestResponse } from '../../middlewares/response';
import { isOK } from '../chat/service';

function* watchFetchRefreshToken(channelId: any) {
  /* @ts-ignore */
  yield fork(watchRefreshAccessToken, { channelId });
}

function* watchFetchLightningCheckout(action: ReturnType<typeof fetchLightningCheckout>) {
  const { type, payload } = action;

  const response: AxiosResponse = yield call(() => SubscriptionRepository.create(payload.id));

  yield put(digestResponse(response, type));
  if (isOK(response)) {
    /* @ts-ignore */
    yield call(watchFetchRefreshToken);
  }
}

function* watch() {
  yield takeEvery(fetchLightningCheckout, watchFetchLightningCheckout);
  yield takeEvery(fetchRefresh, watchFetchRefreshToken);
}

export default watch;
