import api, { getEndpointSet } from '../services/api';

const { CREATE } = getEndpointSet('CHANNEL', 'subscription');

class SubscriptionRepository {
  static create(id, params) {
    return api.post(CREATE, { id }, params);
  }
}

export default SubscriptionRepository;
