import IconType from '../IconType';

const Setting = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        d="M14.2204,2.8999 C14.5404,2.5739 15.0404,2.5059 15.4354,2.7339 L15.4354,2.7339 L18.3064,4.3919 C18.7014,4.6199 18.8924,5.0869 18.7694,5.5269 L18.7694,5.5269 L18.2514,7.3859 C18.4234,7.6189 19.0034,8.6239 19.1214,8.8939 L19.1214,8.8939 L20.9904,9.3739 C21.4334,9.4869 21.7424,9.8859 21.7424,10.3419 L21.7424,10.3419 L21.7424,13.6579 C21.7424,14.1139 21.4334,14.5129 20.9904,14.6259 L20.9904,14.6259 L19.1214,15.1059 C19.0044,15.3749 18.4244,16.3799 18.2514,16.6139 L18.2514,16.6139 L18.7694,18.4739 C18.8924,18.9129 18.7014,19.3799 18.3064,19.6079 L18.3064,19.6079 L15.4354,21.2659 C15.0404,21.4939 14.5404,21.4259 14.2204,21.0999 L14.2204,21.0999 L12.8704,19.7209 C12.5824,19.7529 11.4224,19.7539 11.1294,19.7209 L11.1294,19.7209 L9.7784,21.0999 C9.4594,21.4259 8.9594,21.4939 8.5644,21.2659 L8.5644,21.2659 L5.6934,19.6079 C5.2974,19.3799 5.1074,18.9129 5.2294,18.4739 L5.2294,18.4739 L5.7484,16.6139 C5.6804,16.5219 4.9954,15.3749 4.8784,15.1069 L4.8784,15.1069 L3.0084,14.6259 C2.5664,14.5129 2.2574,14.1139 2.2574,13.6579 L2.2574,13.6579 L2.2574,10.3419 C2.2574,9.8859 2.5664,9.4869 3.0084,9.3739 L3.0084,9.3739 L4.8784,8.8939 C4.9354,8.7619 5.5754,7.6209 5.7484,7.3859 L5.7484,7.3859 L5.2294,5.5259 C5.1074,5.0869 5.2974,4.6199 5.6934,4.3919 L5.6934,4.3919 L8.5644,2.7339 C8.9594,2.5059 9.4594,2.5739 9.7784,2.8999 L9.7784,2.8999 L11.1294,4.2789 C11.4174,4.2469 12.5774,4.2459 12.8704,4.2789 L12.8704,4.2789 Z M11.9994,8.1979 C9.9004,8.1979 8.1984,9.8999 8.1984,11.9999 C8.1984,14.0999 9.9004,15.8019 11.9994,15.8019 C14.0994,15.8019 15.8014,14.0999 15.8014,11.9999 C15.8014,9.8999 14.0994,8.1979 11.9994,8.1979 Z"
      />
    </svg>
  );
};

export default Setting;
