import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { StyledNamespace } from '@common/types/styledNamespace';

const S: StyledNamespace = {};

type FooterBlockProps = {
  isLine?: boolean;
  children?: React.ReactNode;
};

/**
 * 모달 다이얼로그 Footer
 *
 * @param {Object} props
 * @param {boolean} props.isLine 하단 라이 표시 여부
 */
function FooterBlock({ children, isLine }: FooterBlockProps) {
  return <S.FooterBlock isLine={isLine}>{children}</S.FooterBlock>;
}

FooterBlock.defaultProps = {
  isLine: false,
  children: null
};

FooterBlock.propTypes = {
  isLine: PropTypes.bool,
  children: PropTypes.node
};

const withLine = css`
  border-top: 1px solid ${(props) => props.theme.grey100};
  padding-top: 24px;
`;

S.FooterBlock = styled.div<{ isLine: boolean }>`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  text-align: center;
  ${({ isLine }) => isLine && withLine};
`;

export default FooterBlock;
