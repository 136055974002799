import IconType from '../IconType';

const PostFree = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M17.5,2.5005 C19.433,2.5005 21,4.0675 21,6.0005 L21,6.0005 L21,18.0005 C21,19.9335 19.433,21.5005 17.5,21.5005 L17.5,21.5005 L6.5,21.5005 C4.567,21.5005 3,19.9335 3,18.0005 L3,18.0005 L3,6.0005 C3,4.0675 4.567,2.5005 6.5,2.5005 L6.5,2.5005 Z M17.5,4.0005 L6.5,4.0005 C5.397,4.0005 4.5,4.8975 4.5,6.0005 L4.5,6.0005 L4.5,18.0005 C4.5,19.1035 5.397,20.0005 6.5,20.0005 L6.5,20.0005 L17.5,20.0005 C18.603,20.0005 19.5,19.1035 19.5,18.0005 L19.5,18.0005 L19.5,6.0005 C19.5,4.8975 18.603,4.0005 17.5,4.0005 L17.5,4.0005 Z M11.8652,9.4935 L14.5072,12.1345 L10.3732,16.2675 C10.3302,16.3105 10.2732,16.3375 10.2112,16.3425 L10.2112,16.3425 L7.7282,16.5505 C7.5692,16.5635 7.4362,16.4315 7.4492,16.2715 L7.4492,16.2715 L7.6572,13.7885 C7.6622,13.7275 7.6892,13.6695 7.7322,13.6265 L7.7322,13.6265 L11.8652,9.4935 Z M13.6627,7.6965 C13.9927,7.3665 14.5277,7.3665 14.8577,7.6965 L14.8577,7.6965 L16.3037,9.1425 C16.6337,9.4725 16.6337,10.0075 16.3037,10.3375 L16.3037,10.3375 L15.0837,11.5575 L12.4427,8.9155 Z"
      />
    </svg>
  );
};

export default PostFree;
