import IconType from '../IconType';

const Delete = ({ color, size, ...rest }: IconType) => (
  <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M13.0602,11.9997 L15.963601,9.15852165 C16.256601,8.86552165 16.256601,8.39152165 15.963601,8.09852165 C15.670601,7.80552165 15.195601,7.80552165 14.902601,8.09852165 L11.9992,10.9387 L9.08374786,8.09852165 C8.79074786,7.80552165 8.31574786,7.80552165 8.02274786,8.09852165 C7.72974786,8.39152165 7.72974786,8.86552165 8.02274786,9.15852165 L10.9392,11.9997 L8.02274786,14.9474514 C7.72974786,15.2404514 7.72974786,15.7144514 8.02274786,16.0074514 C8.16974786,16.1544514 8.36174786,16.2274514 8.55274786,16.2274514 C8.74474786,16.2274514 8.93674786,16.1544514 9.08374786,16.0074514 L11.9992,13.0607 L14.902601,16.0074514 C15.049601,16.1544514 15.241601,16.2274514 15.432601,16.2274514 C15.624601,16.2274514 15.816601,16.1544514 15.963601,16.0074514 C16.256601,15.7144514 16.256601,15.2404514 15.963601,14.9474514 L13.0602,11.9997 Z M11.9999,2.0626 C6.5119,2.0626 2.0629,6.5116 2.0629,11.9996 C2.0629,17.4876 6.5119,21.9366 11.9999,21.9366 C17.4879,21.9366 21.9369,17.4876 21.9369,11.9996 C21.9369,6.5116 17.4879,2.0626 11.9999,2.0626"
    />
  </svg>
);

export default Delete;
