import IconType from '../IconType';

const Check = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <polyline
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        points="5.761 11.488 10.608 17.11 18.239 7.11"
      />
    </svg>
  );
};

export default Check;
