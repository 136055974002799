import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import styled from 'styled-components';

import { getPosts, getTotalPostsCount } from '@common/redux/modules/freeBoardPApp';
import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import { fetchList } from '@common/redux/modules/freeBoardPApp/reducers';
import { Icon, SmartLink } from '@common/ui/components';
import { OFFSET_DEFAULT_LIMIT } from '@common/constants/index';

import { NavBar } from '../../components/molecules';
import { NoItem } from '../../components/templates';
import {
  buildChannelsPath,
  buildCreateFreeBoardPath,
  buildFreeBoardDetailPath,
  buildFreeBoardPath
} from '@routers/routes';

import { PageNavigation, PostCard } from './molecules';

function FreeBoardPosts() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const params = new URLSearchParams(location.search);
  const { channelID }: { channelID: string } = useParams();

  const { t } = useTranslation('translation');

  const [currentPage, setCurrentPage] = useState(Number(params.get('page')) || 1);

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const posts = useSelector(getPosts);
  const totalPostsCount = useSelector(getTotalPostsCount);

  useEffect(() => {
    if (!currentChannel) return;

    dispatch(
      fetchList({
        limit: OFFSET_DEFAULT_LIMIT,
        page: currentPage,
        isPublished: true,
        channelId: currentChannel.id
      })
    );
  }, [dispatch, currentPage, currentChannel]);

  const handlePageDown = () => {
    if (currentPage === 1) return;

    setCurrentPage(currentPage - 1);
    history.push(
      `${buildFreeBoardPath(channelID)}/posts?page=${currentPage - 1}&limit=${OFFSET_DEFAULT_LIMIT}`
    );
  };

  const handlePageUp = () => {
    if (currentPage * OFFSET_DEFAULT_LIMIT >= totalPostsCount) return;

    setCurrentPage(currentPage + 1);
    history.push(
      `${buildFreeBoardPath(channelID)}/posts?page=${currentPage + 1}&limit=${OFFSET_DEFAULT_LIMIT}`
    );
  };

  return (
    <>
      <NavBar
        isMenu
        title={t('str_papp_freeboard_id', '자유게시판')}
        icon={<Icon iconName="ic_Edit" />}
        iconLinkUrl={buildCreateFreeBoardPath(currentChannel?.primitiveInvitationCode)}
        backUrl={buildChannelsPath(currentChannel?.primitiveInvitationCode)}
      />

      <S_EmptyDiv />

      {posts.length === 0 ? (
        <NoItem message={t('str_374', '아직 업로드한 게시글이 없습니다.')} />
      ) : (
        <>
          <S_List>
            {posts.map(({ id, title, author, insertedAt, totalComments }) => (
              <SmartLink
                key={id}
                to={buildFreeBoardDetailPath(currentChannel?.primitiveInvitationCode, id)}
              >
                <PostCard
                  key={id}
                  title={title}
                  author={author}
                  createdAt={insertedAt}
                  viewCount={0}
                  commentCount={totalComments}
                />
              </SmartLink>
            ))}
          </S_List>
          <PageNavigation
            currentPage={currentPage}
            totalPageCount={Math.ceil(totalPostsCount / OFFSET_DEFAULT_LIMIT)}
            onNext={handlePageUp}
            onPrev={handlePageDown}
          />
        </>
      )}
    </>
  );
}

const S_EmptyDiv = styled.div`
  height: 56px;
`;

const S_List = styled.div`
  min-height: 584px;
`;

export default FreeBoardPosts;
