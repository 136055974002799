import DateHelper from '@common/utils/dateHelper';
import styled from 'styled-components';

type Props = {
  createdAt: string;
  title: string;
};

function VODInfo({ createdAt, title }: Props) {
  return (
    <S_VODInfo>
      <S_Title>{title}</S_Title>
      <S_Date>{DateHelper.fromNow(createdAt)}</S_Date>
    </S_VODInfo>
  );
}

const S_Title = styled.span`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody1};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  margin-bottom: 4px;
  word-break: break-word;
  word-wrap: break-word;
`;

const S_Date = styled.span`
  color: ${({ theme }) => theme.grey400};
  font-size: ${({ theme }) => theme.fontSizeCaption1};
`;

const S_VODInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  text-align: left;
`;

export default VODInfo;
