import { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { getSelectDate, getSelectTime } from '@common/redux/modules/booking';
import { dateSelect, fetchEvents } from '@common/redux/modules/booking/reducers';
import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import DateHelper from '@common/utils/dateHelper';

import { bookingBasicPath } from '@routers/routes';

import {
  BookingCalendar,
  BookingConfirm,
  BookingDesc,
  MyBooking,
  MyBookingDesc,
  MyBookingHistory,
  MyTicket,
  Station
} from './pages';

const bookingPath = `${bookingBasicPath}`;
const bookingDescPath = `${bookingBasicPath}/calendar/booking`;
const bookingConfirmPath = `${bookingBasicPath}/calendar/booking/confirm`;
const bookingMyTicketPath = `${bookingBasicPath}/calendar/my-ticket`;
const bookingMyBookingPath = `${bookingBasicPath}/calendar/my-booking`;
const bookingMyBookingHistoryPath = `${bookingBasicPath}/calendar/my-booking-history`;
const bookingMyBookingDescPath = `${bookingBasicPath}/my-booking-desc`;
const bookingStationPath = `${bookingBasicPath}/station`;

function BookingContainer() {
  const dispatch = useDispatch();

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);
  const [channelID, setChannelID] = useState<string>('');

  useEffect(() => {
    if (currentChannel) {
      setChannelID(currentChannel.primitiveInvitationCode);
    }
  }, [currentChannel]);

  const [refDate, setRefDate] = useState(DateHelper.getInstance());
  const selectDate = useSelector(getSelectDate);
  const selectTime = useSelector(getSelectTime);

  const fetchingEvents = (currentFormat: ReturnType<typeof DateHelper.getInstance>) => {
    if (currentChannel) {
      dispatch(
        fetchEvents({ date: currentFormat.format('YYYY-MM-DD'), channelId: currentChannel.id })
      );
    }
  };

  const handleRefDate = (newRefDate: ReturnType<typeof DateHelper.getInstance>) => {
    setRefDate(newRefDate);
  };

  const handleSelectDate = (currentFormat: ReturnType<typeof DateHelper.getInstance>) => {
    dispatch(dateSelect(currentFormat));
  };

  const handleSelectAnotherDay = (currentFormat: ReturnType<typeof DateHelper.getInstance>) => {
    setRefDate(currentFormat.startOf('month'));
    fetchingEvents(currentFormat);
    handleSelectDate(currentFormat);
  };

  const handleGoToday = () => {
    handleRefDate(DateHelper.getInstance());
    handleSelectDate(DateHelper.getInstance());
  };

  const [check, setCheck] = useState(true);
  const [comment, setComment] = useState('');

  // pApp item Ticket 구현 전까지 하드코딩
  const [ticketLeft, setTicketLeft] = useState(0);

  const purchaseTicket = () => {
    setTicketLeft(1);
  };
  const useTicket = () => {
    setTicketLeft(0);
  };

  return (
    <Switch>
      <Route exact path={bookingPath}>
        <BookingCalendar
          handleGoToday={handleGoToday}
          handleRefDate={handleRefDate}
          handleSelectDate={handleSelectDate}
          handleSelectAnotherDay={handleSelectAnotherDay}
          refDate={refDate}
          selectDate={selectDate}
          channelID={channelID}
        />
      </Route>
      <Route exact path={bookingDescPath}>
        <BookingDesc
          selectDate={selectDate}
          selectTime={selectTime}
          channelID={channelID}
          ticketLeft={ticketLeft}
          comment={comment}
          setComment={setComment}
          check={check}
          setCheck={setCheck}
        />
      </Route>
      <Route exact path={bookingConfirmPath}>
        <BookingConfirm
          selectDate={selectDate}
          selectTime={selectTime}
          channelID={channelID}
          useTicket={useTicket}
        />
      </Route>
      <Route exact path={bookingMyTicketPath}>
        <MyTicket ticketLeft={ticketLeft} purchaseTicket={purchaseTicket} />
      </Route>
      <Route exact path={bookingMyBookingPath}>
        <MyBooking channelID={channelID} />
      </Route>
      <Route exact path={bookingMyBookingHistoryPath}>
        <MyBookingHistory channelID={channelID} />
      </Route>
      <Route exact path={bookingMyBookingDescPath}>
        <MyBookingDesc />
      </Route>
      <Route exact path={bookingStationPath}>
        <Station channelID={channelID} />
      </Route>
    </Switch>
  );
}

export default BookingContainer;
