import { createReducer, createAction } from '@reduxjs/toolkit';

import { builderFactory } from '../../../utils/reduxHelper';

import { InitialState, UpdateProfilePayload, UploadImagePayload } from './types';

const prefix = '@profile';

export const fetchProfile = createAction<number>(`${prefix}/FETCH_PROFILE`);
export const fetchCurrentChannelProfile = createAction<number>(
  `${prefix}/FETCH_CURRENT_CHANNEL_PROFILE`
);
export const updateProfile = createAction<UpdateProfilePayload>(`${prefix}/UPDATE_PROFILE`);
export const updateCurrentChannelProfile = createAction<UpdateProfilePayload>(
  `${prefix}/UPDATE_FETCH_CURRENT_CHANNEL_PROFILE`
);
export const uploadImageToS3 = createAction<UploadImagePayload>(`${prefix}/UPLOAD_IMAGE_TO_S3`);

const initialState: InitialState = {
  isLoading: false,
  isUpdated: false,
  uploadedImageUrl: '',
  profile: {
    id: 0,
    nickname: '',
    imageSrc: '',
    gender: '',
    birthYear: 0,
    birthDate: 0,
    birthMonth: 0,
    hasCertifiedIdentity: false
  },
  currentChannelProfile: {
    id: 0,
    nickname: '',
    imageSrc: '',
    gender: '',
    birthYear: 0,
    birthDate: 0,
    birthMonth: 0,
    hasCertifiedIdentity: false
  },
  error: null
};

const reducer = createReducer(initialState, (builder) => {
  builderFactory(builder, [
    fetchProfile,
    fetchCurrentChannelProfile,
    updateProfile,
    updateCurrentChannelProfile,
    uploadImageToS3
  ])
    .addCase(`${fetchProfile}_COMPLETED`, (state, action: any) => {
      const { profile } = action.payload.data.data;

      state.isUpdated = false;
      state.profile = {
        ...profile,
        birthDate: profile.birthDay
      };
    })
    .addCase(`${fetchCurrentChannelProfile}_COMPLETED`, (state, action: any) => {
      const { profile } = action.payload.data.data;

      state.isUpdated = false;
      state.currentChannelProfile = {
        ...profile,
        birthDate: profile.birthDay
      };
    })
    .addCase(`${updateProfile}_COMPLETED`, (state, action: any) => {
      const { profile } = action.payload.data.data;

      state.isUpdated = true;
      state.profile = {
        ...profile,
        birthDate: profile.birthDay
      };
    })
    .addCase(`${updateCurrentChannelProfile}_COMPLETED`, (state, action: any) => {
      const { profile } = action.payload.data.data;

      state.isUpdated = true;
      state.currentChannelProfile = {
        ...profile,
        birthDate: profile.birthDay
      };
    })
    .addCase(`${uploadImageToS3}_COMPLETED`, (state, action: any) => {
      state.uploadedImageUrl = action.payload;
      state.isLoading = false;
    })
    .addMatcher(
      (action) => action.type.startsWith(prefix) && action.type.endsWith('_COMPLETED'),
      (state) => {
        state.isLoading = false;
        state.error = null;
      }
    )
    .addMatcher(
      (action) => action.type.startsWith(prefix) && action.type.endsWith('_FAILED'),
      (state, action) => {
        state.isLoading = false;
        state.error = action.payload.data.error;
      }
    );
});

export default reducer;
