/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { makeGetChannelDescParams, SubscriptionProductState } from '@common/redux/modules/channel';
import { makeGetIsAuthenticated } from '@common/redux/modules/session';
import { fetchLightningCheckout } from '@common/redux/modules/subscription/reducers';
import { makeGetSubscriptionProduct } from '@common/redux/modules/subscriptionProduct';
// TODO: 임시결제 삭제시 사용
// import { ProfitableClient } from '@common/profitable-js/src';
import useModalDialog from '@common/hooks/useModalDialog';
import { makeGetCurrentUser } from '@common/redux/modules/user';
import { fetchCurrentUser } from '@common/redux/modules/user/reducers';

import { DescNavBar } from './molecules';
import { PurchaseBar } from './organisms';
import { ChannelDescView, PurchaseModal, SubscriptionProductModal } from './templates';
import { useHistory } from 'react-router';
// TODO: 임시결제 삭제시 사용
// import { buildPurchaseProductPath } from '@routers/routes';

// TODO: 임시결제 삭제시 사용
// const {
//   REACT_APP_API_HOST_WITHOUT_API,
//   REACT_APP_PUBL_PAY_API_KEY,
//   REACT_APP_PUBL_PAY_API_HOST,
//   REACT_APP_KEY_ACCESS_TOKEN
// } = process.env;

// TODO: 임시결제 삭제시 사용
// const CONFIG = {
//   merchantHost: REACT_APP_API_HOST_WITHOUT_API, // 환경변수1
//   publPayHost: REACT_APP_PUBL_PAY_API_HOST, // 환경번수2
//   apiKey: REACT_APP_PUBL_PAY_API_KEY, // 환경변수3
//   merchantTokenKey: REACT_APP_KEY_ACCESS_TOKEN // 환경변수4
// };

function ChannelDescContainer() {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const history = useHistory();
  const [channelID, setChannelID] = useState('');
  const [isOpened, setIsOpened] = useState(false);
  const [isSubscriptionProductModalOpen, setIsSubscriptionProductModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<SubscriptionProductState>();

  const getSubscriptionProduct = useMemo(makeGetSubscriptionProduct, []);
  const subscriptionProduct = useSelector(getSubscriptionProduct);

  const getCurrentUser = useMemo(makeGetCurrentUser, []);
  const currentUser = useSelector(getCurrentUser);

  const getChannelDescParams = useMemo(makeGetChannelDescParams, []);
  const channelDescParams = useSelector(getChannelDescParams);

  const getIsAuthenticated = useMemo(makeGetIsAuthenticated, []);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const { ModalDialog, setModalDialogMessage, setIsModalDialogOpen } = useModalDialog();

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, []);

  useEffect(() => {
    setSelectedItem(subscriptionProduct[0]);
  }, [subscriptionProduct]);

  useEffect(() => {
    if (channelDescParams) {
      setChannelID(channelDescParams.invitationCode);
    }
  }, [channelDescParams]);

  /**
   * 임시 구독 결제 이벤트 핸들러
   */
  // NOTE: 테스트가 필요하다고 하여 임시결제를 사용합니다. 추후 다시 사용해야하는 코드입니다.
  // TODO: 임시결제 삭제시 사용
  const handleTempPermission = () => {
    // if (selectedItem && user) {
    //   const profitableClient = new ProfitableClient({ ...CONFIG });
    //   profitableClient
    //     .requestSystemInvoice('SubscriptionProduct', selectedItem.id, {
    //       targetUserId: user.id
    //     })
    //     .then((systemInvoice: any) => {
    //       history.push(buildPurchaseProductPath(systemInvoice.siid, invitationCode));
    //     })
    //     .catch(() => {
    //       // TODO: remove
    //       setModalDialogMessage(
    //         t(
    //           'str_err_1239',
    //           'Error : 알 수 없는 에러가 발생하였습니다. 관리자에게 문의하세요 (500)'
    //         )
    //       );
    //       setIsModalDialogOpen(true);
    //     });
    // }
    if (selectedItem) {
      dispatch(fetchLightningCheckout({ id: selectedItem.id }));
    }
  };

  /**
   * 결제 선택 모달 닫기 이벤트 핸들러
   */
  const handleSubscriptionProductModalOpen = () => {
    setIsSubscriptionProductModalOpen(true);
  };

  /**
   * 결제 선택 모달 닫기 이벤트 핸들러
   */
  const handleSubscriptionProductModalClose = () => {
    setIsSubscriptionProductModalOpen(false);
  };

  /**
   * 결제 선택 이벤트 핸들러
   */
  const handleSelectItem = (value: string) => {
    setSelectedItem(subscriptionProduct[parseInt(value, 10)]);
  };

  /**
   * 결제 모달 열기 이벤트 핸들러
   */
  const handlePaymentModalOpen = () => {
    setIsOpened(true);
  };

  /**
   * 결제 모달 닫기 이벤트 핸들러
   */
  const handlePaymentModalClose = () => {
    setIsOpened(false);
  };

  const getSubscriptionProductType = (type: string) => {
    switch (type) {
      case 'SUBSCRIPTION_30DAYS':
        return t('str_payment_option_subs_regularly', '정기 결제');
      case 'ONCE_INFINITE':
        return t('str_payment_option_one_time', '1회 결제');
      case 'FREE_INFINITE':
        return t('str_payment_option_subs_temporary_free', '기간 무료 결제');
      default:
        return '';
    }
  };

  return (
    <>
      <DescNavBar channelID={channelID} isAuthenticated={isAuthenticated} />
      <ModalDialog />
      <ChannelDescView />
      {selectedItem ? (
        <PurchaseBar
          selectedItem={selectedItem}
          isOpen={isOpened}
          channelID={channelID}
          getSubscriptionProductType={getSubscriptionProductType}
          isAuthenticated={isAuthenticated}
          onOpen={handlePaymentModalOpen}
          onSubscriptionProductModalOpen={handleSubscriptionProductModalOpen}
        />
      ) : (
        <div>Loading...</div>
      )}

      <PurchaseModal
        isOpen={isOpened}
        onTempPermission={handleTempPermission}
        onClose={handlePaymentModalClose}
      />

      {selectedItem && (
        <SubscriptionProductModal
          purchaseItems={subscriptionProduct.length > 0 && subscriptionProduct}
          isOpen={isSubscriptionProductModalOpen}
          selectedItem={selectedItem}
          onSelectItem={handleSelectItem}
          onClose={handleSubscriptionProductModalClose}
          getSubscriptionProductType={getSubscriptionProductType}
        />
      )}
    </>
  );
}

export default ChannelDescContainer;
