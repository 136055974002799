import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';

import { PrivateRoute } from '@common/overrides/index';
import { makeGetIsAuthenticated } from '@common/redux/modules/session';

import { vodBasicPath } from '@routers/routes';

import { VODPosts, VODPostDetail, Playlists, PlaylistDetail } from './pages';

const vodPostsPath = `${vodBasicPath}/posts`;
const vodPostDetailPath = `${vodBasicPath}/posts/:postId`;
const vodPlaylistsPath = `${vodBasicPath}/playlists`;
const vodPlaylistDetailPath = `${vodBasicPath}/playlists/:playlistId`;
const playlistEpisodePath = `${vodBasicPath}/playlists/:playlistId/posts/:postId`;

function VODContainer() {
  const getIsAuthenticated = useMemo(makeGetIsAuthenticated, []);
  const isAuthenticated = useSelector(getIsAuthenticated);

  return (
    <Switch>
      <PrivateRoute
        exact
        path={vodPostsPath}
        component={VODPosts}
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path={vodPostDetailPath}
        component={VODPostDetail}
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path={playlistEpisodePath}
        component={VODPostDetail}
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        exact
        path={vodPlaylistsPath}
        component={Playlists}
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path={vodPlaylistDetailPath}
        component={PlaylistDetail}
        isAuthenticated={isAuthenticated}
      />
      <Redirect from={vodBasicPath} to={vodPostsPath} />
    </Switch>
  );
}

export default VODContainer;
