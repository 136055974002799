export function last<T>(arr: T[]): T {
  if (!Array.isArray(arr)) {
    throw new Error(`Invalid Array ${arr}`);
  }

  return arr[arr.length - 1];
}

export function head<T>(arr: T[]): T {
  if (!Array.isArray(arr)) {
    throw new Error(`Invalid Array ${arr}`);
  }

  return arr[0];
}

export function drop<T>(count: number, arr: T[]) {
  return arr.slice(count);
}

export function dropLast<T>(count: number, arr: T[]) {
  return count >= arr.length ? [] : arr.slice(0, arr.length - count);
}

export function pipe<T>(...fn: Function[]) {
  let fnIndex = 0;

  return function recur(...args: any[]): T {
    const result = fn[fnIndex](...args);
    fnIndex += 1;
    return fnIndex === fn.length ? result : recur(result);
  };
}
