import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { SmartLink, Icon } from '@common/ui/components';
import {
  buildBookingDescPath,
  buildBookingMyBookingPath,
  buildBookingMyBookingHistoryPath,
  buildBookingMyTicketPath,
  buildBookingPath,
  buildChannelsPath
} from '@routers/routes';
import { useDispatch } from 'react-redux';
import { openSideMenu } from '@common/redux/modules/channel/reducers';

type NavBarProps = {
  isBack?: boolean;
  emptyButton?: boolean;
  title?: string;
  backgroundColor?: string;
  channelID?: string;
  pathTo?: string;
  pageFrom?: string;
  isMenu?: boolean;
};

type NavBarStyleProps = {
  backgroundColor?: string;
  isBack?: boolean;
};

function NavBar({
  title,
  isBack,
  backgroundColor,
  channelID,
  pathTo,
  emptyButton,
  pageFrom,
  isMenu
}: NavBarProps) {
  const history = useHistory();
  const dispatch = useDispatch();

  function handleBack() {
    history.goBack();
  }

  function handlePush() {
    if (pathTo === 'calendar') {
      history.push(buildBookingPath(channelID));
    }
    if (pathTo === 'desc') {
      history.push(buildBookingDescPath(channelID));
    }
    if (pathTo === 'channel') {
      history.push(buildChannelsPath(channelID));
    }
  }

  function openMenu() {
    dispatch(openSideMenu());
  }

  return (
    <S_Nav backgroundColor={backgroundColor} isBack={isBack}>
      {isMenu && (
        <S_Button
          onClick={() => {
            openMenu();
          }}
        >
          <Icon iconName="ic_Menu" size={24} />
        </S_Button>
      )}
      {isBack && (
        <S_Button onClick={handleBack}>
          <Icon iconName="ic_ArrowLeft" />
        </S_Button>
      )}
      {!isBack && !isMenu && (
        <S_Button onClick={handlePush}>
          <Icon iconName="ic_ArrowLeft" />
        </S_Button>
      )}
      {emptyButton && (
        <>
          <S_Title>{title}</S_Title>
          <S_EmptyButton />
        </>
      )}
      {pageFrom === 'Calendar' && (
        <>
          <S_TitleTwoIcon>{title}</S_TitleTwoIcon>
          <S_MyButtons>
            <S_MyButtonWrapperTwoIcon>
              <SmartLink to={buildBookingMyBookingPath(channelID)}>
                <Icon iconName="ic_BookingCompleted" />
              </SmartLink>
            </S_MyButtonWrapperTwoIcon>
            <S_MyButtonWrapperTwoIcon>
              <SmartLink to={buildBookingMyTicketPath(channelID)}>
                <Icon iconName="ic_BookingTicket" />
              </SmartLink>
            </S_MyButtonWrapperTwoIcon>
          </S_MyButtons>
        </>
      )}
      {pageFrom === 'MyBooking' && (
        <>
          <S_Title>{title}</S_Title>
          <S_MyButtonWrapper>
            <SmartLink to={buildBookingMyBookingHistoryPath(channelID)}>
              <Icon iconName="ic_BookingHistory" />
            </SmartLink>
          </S_MyButtonWrapper>
        </>
      )}
    </S_Nav>
  );
}

const S_Nav = styled.div<NavBarStyleProps>`
  align-items: center;
  background-color: ${({ theme, backgroundColor }) => {
    if (backgroundColor) {
      return backgroundColor;
    }
    return theme.white;
  }};
  display: flex;
  height: 56px;
  justify-content: space-between;
  left: 0;
  position: fixed;
  position: sticky;
  right: 0;
  text-align: center;
  top: 0;
  transition: transform 0.4s;
  z-index: ${({ isBack }) => (isBack ? 10 : 4)};
`;

const S_TitleTwoIcon = styled.h1`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-stretch: normal;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  letter-spacing: normal;
  line-height: ${({ theme }) => theme.fontLineHeight};
  margin-left: 48px;
  text-align: center;
`;
const S_Title = styled.h1`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-stretch: normal;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  letter-spacing: normal;
  line-height: ${({ theme }) => theme.fontLineHeight};
  text-align: center;
`;

const S_MyButtons = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-right: 8px;
`;

const S_MyButtonWrapperTwoIcon = styled.div`
  margin: 12px;
`;
const S_MyButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
`;

const S_Button = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-left: 8px;
  width: 48px;
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
`;

const S_EmptyButton = styled.div`
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-left: 8px;
  width: 48px;
`;

export default NavBar;
