import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import { getVODPlusHome, VODPlusHomeSectionType } from '@common/redux/modules/VODPlusPApp';
import { cleanUpAllData, fetchVODPlusHome } from '@common/redux/modules/VODPlusPApp/reducers';

import { StickyTop, NavBar, SubTab } from '../../components';

import { EmptyHome } from './molecules';
import { HomeCarousel, Section } from './organisms';

function VodPlusHome() {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const [channelId, setChannelId] = useState<number>(0);
  const [invitationCode, setInvitationCode] = useState<string>('');

  const allSectionList: VODPlusHomeSectionType[] = useSelector(getVODPlusHome);

  const activeSeriesListSections = allSectionList.slice(1, allSectionList.length);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!currentChannel) return;

    setInvitationCode(currentChannel.primitiveInvitationCode);
    setChannelId(currentChannel.id);
  }, [currentChannel]);

  useEffect(() => {
    dispatch(cleanUpAllData());
    if (!channelId) return;

    dispatch(fetchVODPlusHome({ channelId }));
  }, [dispatch, channelId]);

  return (
    <>
      <StickyTop>
        <NavBar isMenu title={t('str_papp_vodp_name', '시청하기')} />
        <SubTab invitationCode={invitationCode} />
      </StickyTop>
      {allSectionList.length > 0 && (
        <HomeCarousel sectionData={allSectionList[0]} invitationCode={invitationCode} />
      )}
      {activeSeriesListSections?.length > 1 ? (
        <>
          {activeSeriesListSections.map((section, i) => (
            <div key={i.toString()}>
              <Section sectionData={section} invitationCode={invitationCode} />
            </div>
          ))}
        </>
      ) : (
        <EmptyHome invitationCode={invitationCode} />
      )}
    </>
  );
}

export default VodPlusHome;
