import styled from 'styled-components';

import { VODPlusPostType } from '@common/redux/modules/VODPlusPApp/types';
import { Icon } from '@common/ui/components';

import { Image } from '../../../components';

import { DetailContent } from '../molecules';

type Props = {
  detailContentProps: VODPlusPostType | null;
};

function IsUserLegallyIneligibleScreen({ detailContentProps }: Props) {
  return (
    <>
      <S_PlayerField>
        <Image ratio={16 / 9} />
        <S_IconWrapper>
          <Icon iconName="ic_Unavailable" size={64} colorCode="grey400" />
        </S_IconWrapper>
      </S_PlayerField>
      <DetailContent isUserLegallyIneligible detailContentProps={detailContentProps} />
    </>
  );
}

const S_PlayerField = styled.div`
  height: auto;
  position: relative;
  top: 0;
  width: 100%;
`;

const S_IconWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  left: 50%;
  margin: -32px 0 0 -32px;
  position: absolute;
  top: 50%;
`;

export default IsUserLegallyIneligibleScreen;
