import IconType from '../IconType';

const Warning = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M11.9999,2.0626 C17.4879,2.0626 21.9369,6.5116 21.9369,11.9996 C21.9369,17.4876 17.4879,21.9366 11.9999,21.9366 C6.5119,21.9366 2.0629,17.4876 2.0629,11.9996 C2.0629,6.5116 6.5119,2.0626 11.9999,2.0626 Z M12,6.0626 C11.5857864,6.0626 11.25,6.39838644 11.25,6.8126 L11.25,6.8126 L11.25,13.3126 C11.25,13.7268136 11.5857864,14.0626 12,14.0626 C12.4142136,14.0626 12.75,13.7268136 12.75,13.3126 L12.75,13.3126 L12.75,6.8126 C12.75,6.39838644 12.4142136,6.0626 12,6.0626 Z M12,17.6 C12.5522847,17.6 13,17.1522847 13,16.6 C13,16.0477153 12.5522847,15.6 12,15.6 C11.4477153,15.6 11,16.0477153 11,16.6 C11,17.1522847 11.4477153,17.6 12,17.6 Z"
      />
    </svg>
  );
};

export default Warning;
