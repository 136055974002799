class Repo {
  static findAll(repo, tableName, options, sortOptions) {
    const resources = repo[tableName];

    const result = resources.filter((r) => {
      return Object.entries(options).reduce((acc, [key, value]) => {
        return acc && r[key] === value;
      }, true);
    });

    return sortOptions
      ? result.sort((a, b) => {
          const { key, direction } = sortOptions;

          const digit = a[key] < b[key] ? 1 : a[key] > b[key] ? -1 : 0;

          return direction === 'DESC' ? digit : -1 * digit;
        })
      : result;
  }

  static findOne(repo, tableName, options) {
    const resources = repo[tableName];

    return resources.find((r) => {
      return Object.entries(options).reduce((acc, [key, value]) => {
        return acc && r[key] === value;
      }, true);
    });
  }

  static insert(repo, tableName, entity) {
    const resources = repo[tableName];

    const lastOne = resources[resources.length - 1];
    const id = lastOne ? lastOne.id + 1 : 1;

    entity.setId(id);

    resources.push(entity.toJSON());

    return resources.find((r) => r.id === id);
  }

  static update(repo, tableName, id, data) {
    const resources = repo[tableName];

    const resource = resources.find((r) => r.id === id);

    Object.entries(data).forEach(([key, value]) => {
      resource[key] = value;
    });

    return resource;
  }
}

export default Repo;
