import PropTypes from 'prop-types';

import styled, { keyframes } from 'styled-components';

type CoverImageViewProps = {
  imageUrl: string;
};

/**
 * 채널 커버 - 이미지
 */
function CoverImageView({ imageUrl }: CoverImageViewProps) {
  return (
    <S_CoverImageView>
      <S_Wrapper>
        {imageUrl ? (
          <S_Image
            style={{
              backgroundImage: `url(${imageUrl})`
            }}
          />
        ) : (
          <S_Placeholder />
        )}
      </S_Wrapper>
    </S_CoverImageView>
  );
}

CoverImageView.defaultProps = {
  imageUrl: ''
};

CoverImageView.propTypes = {
  imageUrl: PropTypes.string
};

const S_CoverImageView = styled.div`
  align-items: center;
  background-image: linear-gradient(to bottom, #1eebb4, #455eef);
  border-radius: 999px;
  display: flex;
  height: 180px;
  justify-content: center;
  width: 180px;
`;

const S_Wrapper = styled.span`
  align-items: center;
  background-color: white;
  border-radius: 999px;
  display: flex;
  height: 170px;
  justify-content: center;
  position: relative;
  width: 170px;
`;

const S_Image = styled.span`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 999px;
  height: 160px;
  position: absolute;
  width: 160px;
`;

const shimmer = keyframes`
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
`;

const S_Placeholder = styled.div`
  border-radius: 999px;
  height: 170px;
  overflow: hidden;
  position: relative;
  width: 170px;
  &::before {
    animation: ${shimmer} 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 170px;
  }
`;

export default CoverImageView;
