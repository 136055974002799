import { takeEvery } from 'redux-saga/effects';
import {
  fetchDetail,
  fetchList,
  addPost,
  fetchComments,
  addComment,
  deleteComment,
  removePost,
  updatePost
} from '../reducers';

import {
  watchFetchList,
  watchFetchDetail,
  watchAddPost,
  watchUpdatePost,
  watchDeletePost
} from './postSaga';
import { watchFetchComments, watchAddComment, watchDeleteComment } from './commentSaga';

function* watch() {
  yield takeEvery(fetchList, watchFetchList);
  yield takeEvery(fetchDetail, watchFetchDetail);
  yield takeEvery(addPost, watchAddPost);
  yield takeEvery(updatePost, watchUpdatePost);
  yield takeEvery(removePost, watchDeletePost);
  yield takeEvery(fetchComments, watchFetchComments);
  yield takeEvery(addComment, watchAddComment);
  yield takeEvery(deleteComment, watchDeleteComment);
}

export default watch;
