import styled from 'styled-components';

type Props = {
  displayType?: 'line' | 'area';
  direction?: 'vertical' | 'horizontal';
  height?: number;
};

/**
 * Divider 컴포넌트
 */

function Divider({ displayType = 'line', direction = 'horizontal', height = 1 }: Props) {
  return (
    <S_DividerBlock displayType={displayType} dividerHeight={height} direction={direction}>
      <S_Divider displayType={displayType} dividerHeight={height} direction={direction} />
    </S_DividerBlock>
  );
}

export default Divider;

const S_DividerBlock = styled.span<{
  displayType: 'line' | 'area';
  direction: 'vertical' | 'horizontal';
  dividerHeight: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${({ direction, dividerHeight }) =>
    direction === 'vertical'
      ? `
      flex-direction : column;
      width:1px;
      height: ${dividerHeight}px;
  `
      : `
      flex-direction : row;
      height:1px`}

  ${({ displayType, direction }) =>
    displayType === 'area' && direction === 'horizontal' && 'height: 16px;'}
`;

const S_Divider = styled.span<{
  displayType: 'line' | 'area';
  direction: 'vertical' | 'horizontal';
  dividerHeight: number;
}>`
  background-color: ${({ theme }) => theme.grey100};

  ${({ direction, dividerHeight }) =>
    direction === 'vertical'
      ? `
      height: ${dividerHeight}px;
      width: 1px;`
      : `
      height: 1px;
      width:100%;`}

  ${({ displayType, direction }) =>
    displayType === 'area' && direction === 'horizontal' && 'height: 16px;'}
`;
