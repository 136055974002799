import { useTranslation } from 'react-i18next';

import { BookingHistoryType } from '@common/redux/modules/booking/types';
import DateHelper from '@common/utils/dateHelper';
import styled from 'styled-components';

type MyBookingHistoryProps = {
  history: BookingHistoryType;
  isFirstChild?: boolean;
};

type MyBookingHistoryStyleProps = {
  lastChild?: boolean;
  isFirstChild?: boolean;
};

function MyBookingHistory({ history, isFirstChild }: MyBookingHistoryProps) {
  const { t } = useTranslation('translation');
  const { status, time } = history;
  const startDate = DateHelper.getInstance(time).format('YYYY-MM-DD');
  const startTime = DateHelper.getInstance(time).format('HH:mm');

  return (
    <S_MyBookingHistoryContainer>
      <S_HistoryTime isFirstChild={isFirstChild}>
        {t('str_26', { startDate, startTime, defaultValue: '{{startDate}} {{startTime}}' })}
      </S_HistoryTime>
      <S_HistoryStatus>
        {status === 0 &&
          `${t('str_papp_cbooking_history_rsv_complete', {
            defaultValue: '$t(str_papp_cbooking_rsv) 완료'
          })}`}
        {status === 1 &&
          `${t('str_papp_cbooking_subs_history_rsv_cancel_rq', {
            defaultValue: '$t(str_papp_cbooking_rsv_cancellation) 요청'
          })}`}
        {status === 2 &&
          `${t('str_papp_cbooking_subs_history_rsv_cancel_rq_from_seller', {
            defaultValue: '$t(str_seller)의 $t(str_papp_cbooking_rsv_cancellation) 요청'
          })}`}
        {status === 3 &&
          `${t('str_papp_cbooking_subs_history_rsv_cancel_acpt', {
            defaultValue: '$t(str_papp_cbooking_rsv_cancellation) 수락'
          })}`}
        {status === 4 &&
          `${t('str_papp_cbooking_subs_history_rsv_cancel_acpt_from_seller', {
            defaultValue: '$t(str_seller)의 $t(str_papp_cbooking_rsv_cancellation) 수락'
          })}`}
        {status === 5 &&
          `${t('str_papp_cbooking_history_rsv_cancel_complete', {
            defaultValue: '$t(str_papp_cbooking_rsv_cancellation) 완료'
          })}`}
        {status === 6 &&
          `${t('str_papp_cbooking_history_rsv_cancel_complete', {
            defaultValue: '$t(str_papp_cbooking_rsv_cancellation) 완료'
          })}`}
        {status === 7 &&
          `${t('str_papp_cbooking_subs_history_rsv_cancel_deny', {
            defaultValue: '$t(str_papp_cbooking_rsv_cancellation) 거절'
          })}`}
        {status === 8 &&
          `${t('str_papp_cbooking_subs_history_rsv_cancel_deny_from_seller', {
            defaultValue: '$t(str_seller)의 $t(str_papp_cbooking_rsv_cancellation) 거절'
          })}`}
        {status === 9 && `${t('str_papp_cbooking_history_evt_start', '상담 시작')}`}
        {status === 10 && `${t('str_papp_cbooking_history_evt_refusal_rq', '상담 거부 요청')}`}
        {status === 11 &&
          `${t('str_papp_cbooking_subs_history_evt_refusal_acpt', {
            defaultValue: '$t(str_seller)의 상담 거부 수락'
          })}`}
        {status === 12 &&
          `${t('str_papp_cbooking_subs_history_evt_refusal_deny', {
            defaultValue: '$t(str_seller)의 상담 거부 거절'
          })}`}
        {status === 13 && `${t('str_papp_cbooking_history_evt_complete', '상담 종료')}`}
      </S_HistoryStatus>
    </S_MyBookingHistoryContainer>
  );
}

const S_MyBookingHistoryContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.grey100};
  display: flex;
  flex-direction: column;
  height: 64px;
  justify-content: center;
`;

const S_HistoryTime = styled.div<MyBookingHistoryStyleProps>`
  color: ${({ theme }) => theme.textSecondary};
  display: flex;
  font-size: ${({ theme }) => theme.fontSizeCaption1};
  &::before {
    background: ${({ theme, isFirstChild }) => {
      if (isFirstChild) {
        return theme.blue500;
      }
      return theme.grey400;
    }};
    border-radius: 8px;
    content: '';
    display: block;
    height: 8px;
    margin: 2px 8px 2px 0;
    position: relative;
    top: 4px;
    width: 8px;
  }
`;

const S_HistoryStatus = styled.div`
  color: ${({ theme }) => theme.textPrimary};
  display: flex;
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  margin-left: 16px;
`;

export default MyBookingHistory;
