import { Button, Icon } from '@common/ui/components';
import styled from 'styled-components';

type PurchaseTicketModalProps = {
  ticketCounter: number;
  plusTicketQuantity: (ticketCounter: number) => void;
  minusTicketQuantity: (ticketCounter: number) => void;
  handlePurchaseTicketModalOpen: () => void;
  purchaseTicket: () => void;
};

function PurchaseTicketModal({
  ticketCounter,
  plusTicketQuantity,
  minusTicketQuantity,
  handlePurchaseTicketModalOpen,
  purchaseTicket
}: PurchaseTicketModalProps) {
  const handleChangeMonthButton = () => {
    purchaseTicket();
    handlePurchaseTicketModalOpen();
  };
  return (
    <S_PurchaseTicketModalContainer>
      <S_ModalContainer>
        <S_TitleBar>
          <S_Title>충전하기</S_Title>
          <Icon iconName="ic_Xmark" onClick={() => handlePurchaseTicketModalOpen()} />
        </S_TitleBar>
        <S_Divider />
        <S_ContentArea>
          <S_CountSide>
            {ticketCounter >= 1 && ticketCounter < 100 && (
              <S_ArrowButton
                onClick={() => {
                  plusTicketQuantity(ticketCounter);
                }}
              >
                <Icon iconName="ic_ArrowUp" />
              </S_ArrowButton>
            )}
            {ticketCounter === 100 && <Icon iconName="ic_ArrowUp" colorCode="grey400" />}
            <S_QuantitySide>{ticketCounter}</S_QuantitySide>
            {ticketCounter > 1 && ticketCounter <= 100 && (
              <S_ArrowButton
                onClick={() => {
                  minusTicketQuantity(ticketCounter);
                }}
              >
                <Icon iconName="ic_ArrowDown" />
              </S_ArrowButton>
            )}
            {ticketCounter === 1 && <Icon iconName="ic_ArrowDown" colorCode="grey400" />}
          </S_CountSide>
          <S_TextSide>장 구매</S_TextSide>
        </S_ContentArea>
        <Button label="구매" onClick={handleChangeMonthButton} />
      </S_ModalContainer>
    </S_PurchaseTicketModalContainer>
  );
}

const S_PurchaseTicketModalContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100vh;
  position: absolute;
  width: 100vw;
  z-index: 11;
`;

const S_ModalContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  height: 316px;
  margin: auto;
  width: 327px;
`;

const S_TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 12px 24px;
`;

const S_Title = styled.div`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody1};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  line-height: ${({ theme }) => theme.fontLineHeight};
`;

const S_Divider = styled.div`
  background: ${({ theme }) => theme.grey100};
  height: 1px;
  margin: 0;
  width: 100%;
`;

const S_ContentArea = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 148px;
  justify-content: center;
  margin: 24px 80px;
  width: 157px;
`;

const S_CountSide = styled.div`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeH2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  min-width: 60px;
`;

const S_TextSide = styled.div`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeH2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  margin-left: 20px;
`;

const S_QuantitySide = styled.div`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeH2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  height: 36px;
  margin: 20px 0;
`;

const S_ArrowButton = styled.div`
  background: none;
`;

export default PurchaseTicketModal;
