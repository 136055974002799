import styled from 'styled-components';

type Props = {
  height?: string;
  width?: string;
  gradientStyle?: string;
  direction?: string;
  startColor?: string;
  endColor?: string;
  borderRadius?: string;
  position?: 'bottom' | 'top';
  zIndex?: number;
};

function GradientCover({
  height = '100%',
  width = '100%',
  gradientStyle = 'linear-gradient',
  direction = 'to bottom',
  startColor = 'rgba(255, 255, 255, 0) 0%',
  endColor = 'rgba(255, 255, 255, 1) 100%',
  borderRadius = 'none',
  position = 'bottom',
  zIndex = 1
}: Props) {
  return (
    <S_GradientCover
      height={height}
      width={width}
      gradientStyle={gradientStyle}
      direction={direction}
      startColor={startColor}
      endColor={endColor}
      borderRadius={borderRadius}
      position={position}
      zIndex={zIndex}
    />
  );
}

const S_GradientCover = styled.div<Props>`
  background-image: ${({ gradientStyle, direction, startColor, endColor }) =>
    `${gradientStyle}(${direction}, ${startColor},${endColor})`};
  border-radius: ${({ borderRadius }) => borderRadius};
  height: ${({ height }) => height};
  position: absolute;
  width: 100%;
  z-index: ${({ zIndex }) => zIndex};

  ${({ position }) => position && `${position}:0`}
`;
export default GradientCover;
