import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@common/ui/components';
import { colors, fontSize } from '@common/ui/styles/theme';
import styled, { css } from 'styled-components';

import { Text } from '../../../components';
import { SYNOPSIS_SEEMORE_HEIGHT, LABEL_SEEMORE_HEIGHT } from '@common/constants/hardcoded';

export type Colors = keyof typeof colors;
export type FontSize = keyof typeof fontSize;

type Props = {
  noCaption?: boolean;
  isOpen: boolean;
  lineLimit: number;
  text: string | string[];
  color?: Colors;
  fontSizes?: FontSize;
  handler: () => void;
};

function SeeMore({
  noCaption = false,
  color = 'grey900',
  fontSizes = 'fontSizeBody2',
  isOpen,
  lineLimit,
  text,
  handler
}: Props) {
  const { t } = useTranslation('translation');
  const content = useRef<HTMLDivElement | null>(null);
  const label = useRef<HTMLDivElement | null>(null);

  const contentHeight = content.current?.clientHeight;
  const labelHeight = label.current?.clientHeight;

  return noCaption ? (
    <>
      <S_SeeMoreWithCaption>
        <S_SeeMoreText
          ref={content}
          isOpen={isOpen}
          lineLimit={lineLimit}
          color={color}
          fontSizes={fontSizes}
        >
          {text}
        </S_SeeMoreText>
        {contentHeight && contentHeight >= SYNOPSIS_SEEMORE_HEIGHT && (
          <S_CaptionWrapper onClick={handler}>
            <Text fontSizes="fontSizeCaption1" color="grey500">
              {isOpen ? t('str_78', '간략히 보기') : t('str_77', '자세히 보기')}
            </Text>
          </S_CaptionWrapper>
        )}
      </S_SeeMoreWithCaption>
    </>
  ) : (
    <>
      <S_SeeMoreNoCaption>
        <S_TextWrapper ref={label} isOpen={isOpen} color={color} fontSizes={fontSizes}>
          {text}
        </S_TextWrapper>
        {labelHeight && labelHeight >= LABEL_SEEMORE_HEIGHT && (
          <S_IconWrapper onClick={handler}>
            <Icon iconName={isOpen ? 'ic_ArrowUp' : 'ic_ArrowDown'} size={16} />
          </S_IconWrapper>
        )}
      </S_SeeMoreNoCaption>
    </>
  );
}

const S_SeeMoreWithCaption = styled.div`
  padding: 10px 0;
`;

const S_SeeMoreText = styled.div<{
  isOpen: boolean;
  lineLimit: number;
  color: Colors;
  fontSizes: FontSize;
}>`
  white-space: pre-wrap;
  ${({ isOpen }) => (isOpen ? IsOpen : IsClose)}
`;

const IsOpen = css<{ color: Colors; fontSizes: FontSize }>`
  color: ${({ theme, color }) => theme[color]};
  font-size: ${({ fontSizes, theme }) => fontSizes && theme[fontSizes]};
  height: auto;
`;
const IsClose = css<{ lineLimit: number; color: Colors; fontSizes: FontSize }>`
  -webkit-box-orient: vertical;
  color: ${({ theme, color }) => theme[color]};
  display: -webkit-box;
  font-size: ${({ fontSizes, theme }) => fontSizes && theme[fontSizes]};
  -webkit-line-clamp: ${({ lineLimit }) => lineLimit};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const S_CaptionWrapper = styled.div`
  margin-top: 8px;
`;

const S_SeeMoreNoCaption = styled.div`
  align-items: flex-start;
  display: flex;
  padding: 10px 0;
`;

const S_TextWrapper = styled.div<{ isOpen: boolean; color: Colors; fontSizes: FontSize }>`
  ${({ isOpen }) => (isOpen ? IsOpenNoCaption : IsCloseNoCaption)}
`;

const IsOpenNoCaption = css<{ color: Colors; fontSizes: FontSize }>`
  color: ${({ theme, color }) => theme[color]};
  font-size: ${({ fontSizes, theme }) => fontSizes && theme[fontSizes]};
  height: auto;
  padding-right: 16px;
`;
const IsCloseNoCaption = css<{ color: Colors; fontSizes: FontSize }>`
  color: ${({ theme, color }) => theme[color]};
  font-size: ${({ fontSizes, theme }) => fontSizes && theme[fontSizes]};
  overflow: hidden;
  padding-right: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const S_IconWrapper = styled.div`
  width: 36px;
`;

export default SeeMore;
