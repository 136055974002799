/* eslint-disable react-hooks/rules-of-hooks */
import { put, takeEvery, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { BookingRepository } from '../../../repositories';
import {
  fetchSampleTicket,
  booking,
  CheckBookingAvailable,
  fetchMyBookings,
  fetchCalendar,
  fetchEvents,
  fetchMyBookingDesc,
  fetchMyBookingDescEdit,
  fetchMyBookingHistory,
  fetchCancelListFromSeller,
  askCancelFromUser,
  confirmCancelFromSeller
} from './reducers';
import { digestResponse } from '../../middlewares/response';

function* watchFetchSampleTicket(action: ReturnType<typeof fetchSampleTicket>) {
  const { type } = action;
  const response: AxiosResponse = yield call(() => BookingRepository.getSampleTicket());
  yield put(digestResponse(response, type));
}

function* watchBooking(action: ReturnType<typeof booking>) {
  const {
    type,
    payload: { eid, ticket, description, channelId }
  } = action;
  const response: AxiosResponse = yield call(() =>
    BookingRepository.booking(eid, ticket, description, channelId)
  );
  yield put(digestResponse(response, type));
}

function* watchCheckBookingAvailable(action: ReturnType<typeof CheckBookingAvailable>) {
  const {
    type,
    payload: { id, channelId }
  } = action;
  const response: AxiosResponse = yield call(() =>
    BookingRepository.bookingAvailable(id, channelId)
  );
  yield put(digestResponse(response, type));
}

function* watchFetchMyBookings(action: ReturnType<typeof fetchMyBookings>) {
  const {
    type,
    payload: { channelId }
  } = action;
  const response: AxiosResponse = yield call(() => BookingRepository.findMyBookings(channelId));
  yield put(digestResponse(response, type));
}

function* watchFetchMyBookingDesc(action: ReturnType<typeof fetchMyBookingDesc>) {
  const {
    type,
    payload: { id, pagination, channelId }
  } = action;
  const response: AxiosResponse = yield call(() =>
    BookingRepository.findMyBookingDesc(id, pagination.page, pagination.limit, channelId)
  );
  yield put(digestResponse(response, type));
}

function* watchFetchMyBookingDescEdit(action: ReturnType<typeof fetchMyBookingDescEdit>) {
  const {
    type,
    payload: {
      eventData: { id, description },
      channelId
    }
  } = action;
  const response: AxiosResponse = yield call(() =>
    BookingRepository.findMyBookingDescEdit(id, description, channelId)
  );
  yield put(digestResponse(response, type));
}

function* watchFetchMyBookingHistory(action: ReturnType<typeof fetchMyBookingHistory>) {
  const {
    type,
    payload: {
      pagenation: { page, limit },
      channelId
    }
  } = action;
  const response: AxiosResponse = yield call(() =>
    BookingRepository.findMyBookingHistory(page, limit, channelId)
  );
  yield put(digestResponse(response, type));
}

function* watchFetchBookingTime(action: ReturnType<typeof fetchEvents>) {
  const {
    type,
    payload: { date, channelId }
  } = action;
  const response: AxiosResponse = yield call(() =>
    BookingRepository.findDailyEvents(date, channelId)
  );
  yield put(digestResponse(response, type));
}

function* watchFetchCalendar(action: ReturnType<typeof fetchCalendar>) {
  const {
    type,
    payload: {
      date: { start, end },
      channelId
    }
  } = action;
  const response: AxiosResponse = yield call(() =>
    BookingRepository.findMonthlyEvents(start, end, channelId)
  );
  yield put(digestResponse(response, type));
}

function* watchFetchCancelListFromSeller(action: ReturnType<typeof fetchCancelListFromSeller>) {
  const {
    type,
    payload: { channelId }
  } = action;
  const response: AxiosResponse = yield call(() =>
    BookingRepository.getCancelListFromSeller(channelId)
  );
  yield put(digestResponse(response, type));
}

function* watchAskCancelFromUser(action: ReturnType<typeof askCancelFromUser>) {
  const {
    type,
    payload: {
      requestType: { bid, status },
      channelId
    }
  } = action;
  const response: AxiosResponse = yield call(() =>
    BookingRepository.askCancelFromUser(bid, status, channelId)
  );
  yield put(digestResponse(response, type));
}

function* watchConfirmCancelFromSeller(action: ReturnType<typeof confirmCancelFromSeller>) {
  const {
    type,
    payload: {
      requestType: { cid, confirm },
      channelId
    }
  } = action;
  const response: AxiosResponse = yield call(() =>
    BookingRepository.confirmCancelFromSeller(cid, confirm, channelId)
  );
  yield put(digestResponse(response, type));
}

function* watch() {
  yield takeEvery(fetchSampleTicket, watchFetchSampleTicket);
  yield takeEvery(booking, watchBooking);
  yield takeEvery(CheckBookingAvailable, watchCheckBookingAvailable);
  yield takeEvery(fetchMyBookings, watchFetchMyBookings);
  yield takeEvery(fetchMyBookingDesc, watchFetchMyBookingDesc);
  yield takeEvery(fetchMyBookingDescEdit, watchFetchMyBookingDescEdit);
  yield takeEvery(fetchMyBookingHistory, watchFetchMyBookingHistory);
  yield takeEvery(fetchEvents, watchFetchBookingTime);
  yield takeEvery(fetchCalendar, watchFetchCalendar);
  yield takeEvery(fetchCancelListFromSeller, watchFetchCancelListFromSeller);
  yield takeEvery(askCancelFromUser, watchAskCancelFromUser);
  yield takeEvery(confirmCancelFromSeller, watchConfirmCancelFromSeller);
}

export default watch;
