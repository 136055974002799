import IconType from '../IconType';

const CheckboxOff = ({ color, size }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <path
        fill={color}
        fillRule="evenodd"
        d="M18.0002,0.0002 L6.0002,0.0002 C2.6867,0.0002 0.0002,2.6867 0.0002,6.0002 L0.0002,18.0002 C0.0002,21.3137 2.6867,24.0002 6.0002,24.0002 L18.0002,24.0002 C21.3137,24.0002 24.0002,21.3137 24.0002,18.0002 L24.0002,6.0002 C24.0002,2.6867 21.3137,0.0002 18.0002,0.0002 M18.0002,1.5002 C20.4812,1.5002 22.5002,3.5192 22.5002,6.0002 L22.5002,18.0002 C22.5002,20.4812 20.4812,22.5002 18.0002,22.5002 L6.0002,22.5002 C3.5192,22.5002 1.5002,20.4812 1.5002,18.0002 L1.5002,6.0002 C1.5002,3.5192 3.5192,1.5002 6.0002,1.5002 L18.0002,1.5002"
      />
    </svg>
  );
};

export default CheckboxOff;
