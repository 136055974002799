import IconType from '../IconType';

const Chat = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M18.1661,2.563 C20.0991,2.563 21.6661,4.13 21.6661,6.063 L21.6661,6.063 L21.6661,14.223 C21.6661,16.156 20.0991,17.723 18.1661,17.723 L18.1661,17.723 L16.7441,17.723 L16.7441,19.574 C16.7441,20.473 16.0081,21.077 15.2351,21.077 C14.8881,21.077 14.5331,20.955 14.2351,20.684 L14.2351,20.684 L10.9771,17.723 L5.8341,17.723 C3.9011,17.723 2.3341,16.156 2.3341,14.223 L2.3341,14.223 L2.3341,6.063 C2.3341,4.13 3.9011,2.563 5.8341,2.563 L5.8341,2.563 Z M18.1661,4.063 L5.8341,4.063 C4.7311,4.063 3.8341,4.96 3.8341,6.063 L3.8341,6.063 L3.8341,14.223 C3.8341,15.325 4.7311,16.223 5.8341,16.223 L5.8341,16.223 L11.5571,16.223 L11.9861,16.613 L15.2441,19.574 L15.2441,16.223 L18.1661,16.223 C19.2691,16.223 20.1661,15.325 20.1661,14.223 L20.1661,14.223 L20.1661,6.063 C20.1661,4.96 19.2691,4.063 18.1661,4.063 L18.1661,4.063 Z M7.79948242,9 C8.51714368,9 9.09814652,9.58182116 9.09814652,10.2994824 C9.09814652,11.0163254 8.51714368,11.5981465 7.79948242,11.5981465 C7.08182116,11.5981465 6.5,11.0163254 6.5,10.2994824 C6.5,9.58182116 7.08182116,9 7.79948242,9 Z M11.9976289,9 C12.7152902,9 13.296293,9.58182116 13.296293,10.2994824 C13.296293,11.0163254 12.7152902,11.5981465 11.9976289,11.5981465 C11.2799677,11.5981465 10.6981465,11.0163254 10.6981465,10.2994824 C10.6981465,9.58182116 11.2799677,9 11.9976289,9 Z M16.1957755,9 C16.9134367,9 17.4944396,9.58182116 17.4944396,10.2994824 C17.4944396,11.0163254 16.9134367,11.5981465 16.1957755,11.5981465 C15.4781142,11.5981465 14.896293,11.0163254 14.896293,10.2994824 C14.896293,9.58182116 15.4781142,9 16.1957755,9 Z"
      />
    </svg>
  );
};

export default Chat;
