import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import DateHelper from '@common/utils/dateHelper';
import { Icon } from '@common/ui/components';

type DropDownProps = {
  refDate: ReturnType<typeof DateHelper.getInstance>;
  handleIsDropDownOpen: () => void;
};

function DropDown({ refDate, handleIsDropDownOpen }: DropDownProps) {
  const { t } = useTranslation('translation');
  return (
    <>
      <S_Button onClick={() => handleIsDropDownOpen()}>
        <S_DropDownText>
          {t('str_35', {
            year: refDate.year(),
            month: refDate.month() + 1,
            defaultValue: '{{year}}년 {{month}}월'
          })}
        </S_DropDownText>
        <Icon iconName="ic_ArrowDown" />
      </S_Button>
    </>
  );
}

const S_DropDownText = styled.div`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeH2};
  font-stretch: normal;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  margin-right: 8px;
`;

const S_Button = styled.div`
  align-items: center;
  background-color: white;
  cursor: pointer;
  display: flex;
  z-index: 2;
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
`;

export default DropDown;
