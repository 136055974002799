import IconType from '../IconType';

const More = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M6.2554,10.4116 C7.1324,10.4116 7.8424,11.1226 7.8424,11.9996 C7.8424,12.8756 7.1324,13.5866 6.2554,13.5866 C5.3784,13.5866 4.6674,12.8756 4.6674,11.9996 C4.6674,11.1226 5.3784,10.4116 6.2554,10.4116 Z M12.0001,10.4116 C12.8771,10.4116 13.5871,11.1226 13.5871,11.9996 C13.5871,12.8756 12.8771,13.5866 12.0001,13.5866 C11.1231,13.5866 10.4121,12.8756 10.4121,11.9996 C10.4121,11.1226 11.1231,10.4116 12.0001,10.4116 Z M17.7447,10.4116 C18.6217,10.4116 19.3317,11.1226 19.3317,11.9996 C19.3317,12.8756 18.6217,13.5866 17.7447,13.5866 C16.8677,13.5866 16.1567,12.8756 16.1567,11.9996 C16.1567,11.1226 16.8677,10.4116 17.7447,10.4116 Z"
      />
    </svg>
  );
};

export default More;
