import { Icon, ImageView } from '@common/ui/components';
import styled, { css } from 'styled-components';
import fillIcons from '@assets/icons/fill';
import lineIcons from '@assets/icons/line';

export type FillIconNameKeys = keyof typeof fillIcons;
export type LineIconNameKeys = keyof typeof lineIcons;

type HeaderBarProps = {
  titleMode?: 'text' | 'profile';
  titleText?: string;
  leftBtnMode?: 'back' | 'none' | 'menu' | 'close';
  displayType?: 'none' | 'ibtn_amount1' | 'ibtn_amount2';
  iBtn1IconName?: FillIconNameKeys | LineIconNameKeys | string;
  iBtn2IconName?: FillIconNameKeys | LineIconNameKeys | string;
  dividerType?: 'no' | 'solid';
  profileInfo?: { nickname?: string; imageSrc?: string };
  handleAction?: () => any;
};

function HeaderBar({
  titleMode = 'text',
  titleText,
  leftBtnMode = 'back',
  displayType = 'none',
  iBtn1IconName,
  iBtn2IconName,
  dividerType = 'no',
  profileInfo,
  handleAction
}: HeaderBarProps) {
  const leftBtnModeObj = {
    none: '',
    back: 'ic_ArrowLeft',
    menu: 'ic_Menu',
    close: 'ic_Xmark'
  };

  if (titleMode === 'profile') {
    return (
      <HeaderBarContainer titleMode={titleMode} dividerType={dividerType}>
        <S_LeftIconBox displayType={displayType}>
          <S_LeftIconWrapper onClick={handleAction}>
            {leftBtnMode !== 'none' && <S_LeftIcon iconName={leftBtnModeObj[leftBtnMode]} />}
          </S_LeftIconWrapper>
        </S_LeftIconBox>

        <S_ProfileBox>
          <ImageView src={profileInfo?.imageSrc} ratio="1_1" width={40} height={40} />
          <S_ProfileTitle>{profileInfo?.nickname}</S_ProfileTitle>
        </S_ProfileBox>

        <S_RightIconBox displayType={displayType}>
          {displayType === 'ibtn_amount1' && <Icon iconName={iBtn1IconName} />}
        </S_RightIconBox>
      </HeaderBarContainer>
    );
  }

  return (
    <HeaderBarContainer titleMode={titleMode} dividerType={dividerType}>
      <S_LeftIconBox displayType={displayType}>
        <S_LeftIconWrapper onClick={handleAction}>
          {leftBtnMode !== 'none' && <S_LeftIcon iconName={leftBtnModeObj[leftBtnMode]} />}
        </S_LeftIconWrapper>
      </S_LeftIconBox>

      <Title>{titleText}</Title>
      <S_RightIconBox displayType={displayType}>
        {(displayType === 'ibtn_amount1' || displayType === 'ibtn_amount2') && (
          <Icon iconName={iBtn1IconName} />
        )}
        {displayType === 'ibtn_amount2' && <Icon iconName={iBtn2IconName} />}
      </S_RightIconBox>
    </HeaderBarContainer>
  );
}

const S_ProfileBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 8px;
`;

const S_ProfileTitle = styled.span`
  margin-top: 4px;
  margin-bottom: 16px;
  font-size: ${({ theme }) => theme.fontSizeCaption2};
`;

const HeaderBarContainer = styled.div<{
  titleMode: 'text' | 'profile';
  dividerType: 'no' | 'solid';
}>`
  align-items: center;
  display: flex;
  height: ${({ titleMode }) => (titleMode === 'profile' ? 'auto' : '56px')};
  justify-content: space-between;
  text-align: center;
  background-color: ${({ theme }) => theme.ui_cpnt_headerbar_base_area};
  border-bottom: ${({ dividerType, theme }) =>
    dividerType === 'solid' ? `solid 1px ${theme.grey100};` : ''};
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-stretch: normal;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  margin: 0;
`;

const S_LeftIconWrapper = styled.div``;

const S_LeftIcon = styled(Icon)`
  cursor: pointer;
  margin-right: 8px;
`;

const S_LeftIconBox = styled.div<{
  displayType: 'none' | 'ibtn_amount1' | 'ibtn_amount2';
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  margin-left: 8px;
  margin-right: ${({ displayType }) => (displayType === 'ibtn_amount2' ? '64px' : '16px')};
`;

const none = css`
  height: 48px;
  width: 48px;
  margin-right: 8px;
  margin-left: 16px;
`;

const ibtn_amount1 = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  margin-right: 8px;
  margin-left: 16px;
`;

const ibtn_amount2 = css`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 48px;
  width: 96px;
  margin-right: 8px;
  margin-left: 16px;
`;

const S_RightIconBox = styled.div<{
  displayType: 'none' | 'ibtn_amount1' | 'ibtn_amount2';
}>`
  ${({ displayType }) => displayType && { none, ibtn_amount1, ibtn_amount2 }[displayType]}
`;

export default HeaderBar;
