import { takeEvery } from 'redux-saga/effects';
import {
  createComment,
  deleteComment,
  fetchComments,
  fetchNoticePost,
  fetchNoticePosts
} from '../reducers';

import { watchFetchNoticePosts, watchFetchNoticePost } from './postSaga';
import { watchFetchComments, watchCreateComment, watchDeleteComment } from './commentSaga';

function* watch() {
  yield takeEvery(fetchNoticePosts, watchFetchNoticePosts);
  yield takeEvery(fetchNoticePost, watchFetchNoticePost);
  yield takeEvery(fetchComments, watchFetchComments);
  yield takeEvery(createComment, watchCreateComment);
  yield takeEvery(deleteComment, watchDeleteComment);
}

export default watch;
