import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { SubscriptionProductState } from '@common/redux/modules/channel';
import { Icon, Button } from '@common/ui/components';
import formatCurrency from '@common/utils/formatCurrency';
import styled from 'styled-components';

import { buildChannelsAuthPath } from '@routers/routes';
import { PurchaseTitle, PurchaseType } from '../atoms';

export type Props = {
  channelID: string;
  selectedItem: SubscriptionProductState;
  isOpen: boolean;
  isAuthenticated?: boolean;
  onOpen?: Function;
  onSubscriptionProductModalOpen?: Function;
  getSubscriptionProductType: Function;
};

function PurchaseBar({
  selectedItem,
  isAuthenticated,
  channelID,
  isOpen,
  onOpen,
  getSubscriptionProductType,
  onSubscriptionProductModalOpen
}: Props) {
  const { t } = useTranslation('translation');

  const history = useHistory();

  function handlePurchase() {
    if (!isAuthenticated) {
      history.push(buildChannelsAuthPath(channelID));
    } else if (onOpen) {
      onOpen();
    }
  }

  function handleSelectModalOpen() {
    if (onSubscriptionProductModalOpen) {
      onSubscriptionProductModalOpen();
    }
  }

  return (
    <S_PurchaseBar isOpen={isOpen}>
      <S_Wrapper>
        <S_PurchaseInfoBlock onClick={handleSelectModalOpen}>
          <PurchaseType>{getSubscriptionProductType(selectedItem.type)}</PurchaseType>
          <PurchaseTitle>{selectedItem.alias}</PurchaseTitle>
          <Icon iconName="ic_ArrowDown" colorCode="grey500" />
        </S_PurchaseInfoBlock>
        <Button
          full
          onClick={handlePurchase}
          label={t('str_362', { amount: formatCurrency(selectedItem.fee.amount) })}
        />
      </S_Wrapper>
    </S_PurchaseBar>
  );
}

const S_PurchaseBar = styled.div<{ isOpen: boolean }>`
  background-color: ${({ theme }) => theme.white};
  border-radius: 24px 24px 0 0;
  bottom: 0;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  display: ${(props) => props.isOpen && 'none'};
  height: 136px;
  left: 0;
  position: fixed;
  right: 0;
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  z-index: 10;
  @media only screen and (min-width: 800px) {
    box-shadow: 0 -3px 5px 0 rgba(0, 0, 0, 0.05);
    margin: 0 0 0 50%;
    max-width: 420px;
  }
`;

const S_Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 8px;
`;

const S_PurchaseInfoBlock = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 48px;
  margin-bottom: 16px;
  width: 100%;
`;

export default PurchaseBar;
