import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import DateHelper from '@common/utils/dateHelper';
import { Button, Icon } from '@common/ui/components';

type MonthSelectModalProps = {
  monthCounter: number;
  monthSelect: ReturnType<typeof DateHelper.getInstance>;
  setMonthCounter: (changeMonth: number) => void;
  setMonthSelect: (changeMonth: ReturnType<typeof DateHelper.getInstance>) => void;
  handleChangeMonthButton: () => void;
  handleIsDropDownOpen: () => void;
};

function MonthSelectModal({
  monthCounter,
  monthSelect,
  setMonthCounter,
  setMonthSelect,
  handleChangeMonthButton,
  handleIsDropDownOpen
}: MonthSelectModalProps) {
  const { t } = useTranslation('translation');

  return (
    <S_MonthSelectModalContainer>
      <S_ModalContainer>
        <S_TitleBar>
          <S_Title>{t('str_365')}</S_Title>
          <Icon iconName="ic_Xmark" onClick={() => handleIsDropDownOpen()} />
        </S_TitleBar>
        <S_Divider />
        <S_ContentArea>
          {monthCounter > 0 ? (
            <S_ArrowUpButton
              onClick={() => {
                setMonthSelect(monthSelect.add(-1, 'month'));
                setMonthCounter(monthCounter - 1);
              }}
            >
              <Icon iconName="ic_ArrowUp" />
            </S_ArrowUpButton>
          ) : (
            <S_ArrowUpButton>
              <Icon iconName="ic_ArrowUp" colorCode="grey400" />
            </S_ArrowUpButton>
          )}
          <S_Text>
            {t('str_35', { year: monthSelect.year(), month: monthSelect.month() + 1 })}
          </S_Text>
          {monthCounter < 12 ? (
            <S_ArrowDownButton
              onClick={() => {
                setMonthSelect(monthSelect.add(1, 'month'));
                setMonthCounter(monthCounter + 1);
              }}
            >
              <Icon iconName="ic_ArrowDown" />
            </S_ArrowDownButton>
          ) : (
            <S_ArrowDownButton>
              <Icon iconName="ic_ArrowDown" colorCode="grey400" />
            </S_ArrowDownButton>
          )}
        </S_ContentArea>
        <Button label={t('str_ok')} onClick={handleChangeMonthButton} />
      </S_ModalContainer>
    </S_MonthSelectModalContainer>
  );
}

const S_MonthSelectModalContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 11;
`;

const S_ModalContainer = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 8px;
  height: 316px;
  margin: auto;
  width: 327px;
`;

const S_TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 12px 24px;
`;

const S_Title = styled.div`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody1};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  line-height: ${({ theme }) => theme.fontLineHeight};
`;

const S_Divider = styled.div`
  background: ${({ theme }) => theme.grey100};
  height: 1px;
  margin: 0;
  width: 100%;
`;

const S_ContentArea = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 36px;
`;

const S_Text = styled.div`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeH2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  height: 36px;
  margin: 20px;
`;

const S_ArrowUpButton = styled.div`
  background: none;
`;

const S_ArrowDownButton = styled.div`
  background: none;
`;

export default MonthSelectModal;
