import IconType from '../IconType';

const Payment = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M18.69996,4.17648 C20.63316,4.17648 22.20036,5.74368 22.20036,7.67688 L22.20036,7.67688 L22.20036,16.32408 C22.20036,18.25608 20.63316,19.82448 18.69996,19.82448 L18.69996,19.82448 L5.29956,19.82448 C3.36756,19.82448 1.80036,18.25608 1.80036,16.32408 L1.80036,16.32408 L1.80036,7.67688 C1.80036,5.74368 3.36756,4.17648 5.29956,4.17648 L5.29956,4.17648 Z M20.70036,11.10648 L3.30036,11.10648 L3.30036,16.32408 C3.30036,17.3767527 4.11821455,18.242456 5.15067142,18.3189814 L5.29956,18.32448 L18.69996,18.32448 C19.80276,18.32448 20.70036,17.42688 20.70036,16.32408 L20.70036,16.32408 L20.70036,11.10648 Z M6.36468,13.88268 C7.17708,13.88268 7.83468,14.54028 7.83468,15.35268 C7.83468,16.16388 7.17708,16.82268 6.36468,16.82268 C5.55348,16.82268 4.89468,16.16388 4.89468,15.35268 C4.89468,14.54028 5.55348,13.88268 6.36468,13.88268 Z M18.69996,5.67648 L5.29956,5.67648 C4.19796,5.67648 3.30036,6.57408 3.30036,7.67688 L3.30036,7.67688 L3.30036,7.90648 L20.70036,7.90648 L20.70036,7.67688 C20.70036,6.62420727 19.8825055,5.75850397 18.8490049,5.68197861 L18.69996,5.67648 Z"
      />
    </svg>
  );
};

export default Payment;
