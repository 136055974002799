import styled from 'styled-components';

type Props = {
  src?: string;
  shapeType?: 'round' | 'circular' | 'rectangle';
  status?: 'default' | 'no_image' | 'restrict' | 'no_profile';
  width?: number | 'responsive';
  ratio?: '16_9' | '9_16' | '16_10' | '10_16' | '4_3' | '3_4' | '1_1' | 'auto';
  scaleType?: 'fill' | 'contain' | 'cover' | 'none';
  height?: number | 'responsive';
};

function ImageView({
  src,
  shapeType = 'round',
  status = 'default',
  width = 'responsive',
  ratio = 'auto',
  scaleType = 'none',
  height = 'responsive'
}: Props) {
  return (
    <>
      {status === 'default' && (
        <S_ImageView
          src={src}
          scaleType={scaleType}
          ratio={ratio}
          shapeType={shapeType}
          $width={width}
          $height={height}
        />
      )}
    </>
  );
}

const S_ImageView = styled.div<{
  src?: string;
  scaleType: 'fill' | 'contain' | 'cover' | 'none';
  ratio: '16_9' | '9_16' | '16_10' | '10_16' | '4_3' | '3_4' | '1_1' | 'auto';
  shapeType: 'round' | 'circular' | 'rectangle';
  $width: number | 'responsive';
  $height: number | 'responsive';
}>`
  aspect-ratio: ${({ ratio }) => {
    if (ratio !== 'auto') {
      const [hor, ver] = ratio.split('_');
      return Number(hor) / Number(ver);
    }
  }};
  background-color: ${({ theme, src }) => !src && theme.grey100};
  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${({ scaleType }) => scaleType};
  border-radius: ${({ shapeType }) => {
    if (shapeType === 'round') {
      return '24px';
    }

    if (shapeType === 'circular') {
      return '50%';
    }

    return '0px';
  }};
  display: block;
  height: ${({ $height }) => ($height === 'responsive' ? 'auto' : `${$height}px`)};
  min-height: ${({ $height }) => ($height === 'responsive' ? 'auto' : `${$height}px`)};
  min-width: ${({ $width }) => ($width === 'responsive' ? '100%' : `${$width}px`)};
  overflow: hidden;
  width: ${({ $width }) => ($width === 'responsive' ? '100%' : `${$width}px`)};
`;

export default ImageView;
