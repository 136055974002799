import { Icon, RatioImage } from '@common/ui/components';
import DateHelper from '@common/utils/dateHelper';
import styled from 'styled-components';

type Props = {
  thumbnailSrc: string;
  createdAt: string;
  runningTime: number;
  title: string;
};

function VODPostListItem({ thumbnailSrc, createdAt, title, runningTime }: Props) {
  return (
    <S_VODPostListItem>
      <S_ThumbnailBox>
        <RatioImage ratio={16 / 9} src={thumbnailSrc} />
        <S_Icon iconName="ic_VideoPlayCircle" colorCode="white" size={48} fill />
        <S_Chip>{DateHelper.makeTimeFormatDate(runningTime)}</S_Chip>
      </S_ThumbnailBox>
      <S_Contents>
        <span className="VODTitle">{title}</span>
        <span className="createdDate">{DateHelper.fromNow(createdAt)}</span>
      </S_Contents>
    </S_VODPostListItem>
  );
}

const S_ThumbnailBox = styled.div`
  position: relative;
  width: 100%;
`;

const S_VODPostListItem = styled.div`
  margin-bottom: 48px;
`;

const S_Icon = styled(Icon)`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const S_Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 16px;
  text-align: left;
  width: 100%;

  .VODTitle {
    color: ${({ theme }) => theme.grey900};
    font-size: ${({ theme }) => theme.fontSizeBody1};
    font-weight: ${({ theme }) => theme.fontWeightBold};
    line-height: ${({ theme }) => theme.fontLineHeight};
    word-break: keep-all;
  }

  .createdDate {
    color: ${({ theme }) => theme.grey400};
    font-size: ${({ theme }) => theme.fontSizeCaption1};
  }
`;

const S_Chip = styled.div`
  background: rgba(0, 0, 0, 0.6);
  border-radius: 12px;
  bottom: 0;
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.fontSizeCaption1};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  margin: 8px;
  padding: 3px 8px;
  position: absolute;
  right: 0;
  text-align: center;
`;

export default VODPostListItem;
