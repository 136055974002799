import PropTypes from 'prop-types';
import React from 'react';

import { StyledNamespace } from '@common/types/styledNamespace';
import { SmartLink, Icon } from '@common/ui/components';
import styled from 'styled-components';

const S: StyledNamespace = {};

type MenuItemProps = {
  icon: React.ReactNode;
  iconLink: string;
  children?: React.ReactNode;
  iconName?: string;
};

/**
 * Account 메뉴 항목
 *
 * @param {Object} props
 * @param {any} props.component
 */
function MenuItem({ children, icon, iconLink, iconName = 'ic_ArrowRight' }: MenuItemProps) {
  return (
    <SmartLink to={iconLink}>
      <S.MenuItem>
        <S.MenuItemTitle>{children}</S.MenuItemTitle>
        {icon && <Icon iconName={iconName} colorCode="grey500" size={16} />}
      </S.MenuItem>
    </SmartLink>
  );
}

MenuItem.defaultProps = {
  icon: false,
  iconLink: '/'
};

MenuItem.propTypes = {
  icon: PropTypes.bool,
  iconLink: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

S.MenuItem = styled.div`
  align-items: center;
  display: flex;
  height: 56px;
  justify-content: space-between;
  padding: 0 24px;
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
`;

S.MenuItemTitle = styled.span`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody1};
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  line-height: ${({ theme }) => theme.fontLineHeight};
`;

export default MenuItem;
