import IconType from '../IconType';

const VideoOff = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M0.9399,2.5817 C1.2329,2.2887 1.7079,2.2887 2.0009,2.5817 L2.0009,2.5817 L19.7779,20.3587 C20.0709,20.6517 20.0709,21.1257 19.7779,21.4187 C19.6309,21.5657 19.4389,21.6387 19.2479,21.6387 C19.0559,21.6387 18.8639,21.5657 18.7169,21.4187 L18.7169,21.4187 L0.9399,3.6427 C0.6469,3.3497 0.6469,2.8747 0.9399,2.5817 Z M1.8611,5.6235 L15.6111,19.3735 C15.3571,19.4445 15.0941,19.4935 14.8181,19.4935 L14.8181,19.4935 L4.1781,19.4935 C2.5211,19.4935 1.1781,18.1505 1.1781,16.4935 L1.1781,16.4935 L1.1781,7.5075 C1.1781,6.7895 1.4411,6.1395 1.8611,5.6235 L1.8611,5.6235 Z M14.8176,4.5076 C16.4746,4.5076 17.8176,5.8506 17.8176,7.5076 L17.8176,7.5076 L17.8176,9.2676 L20.4066,7.2736 C21.3966,6.5106 22.8306,7.2226 22.8216422,8.4726 L22.8216422,8.4726 L22.7696,15.7336 C22.7606,16.9826 21.3186,17.6746 20.3396,16.8996 L20.3396,16.8996 L17.8176,14.9046 L17.8176,16.4936 C17.8176,16.7506 17.7756,16.9966 17.7146,17.2346 L17.7146,17.2346 L4.9876,4.5076 Z"
      />
    </svg>
  );
};

export default VideoOff;
