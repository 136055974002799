import React, { useState, useEffect, useMemo } from 'react';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import {
  getIsFetched,
  getIsLoading,
  getPost,
  getResponseImageUrls,
  getResponseVideoUrl
} from '@common/redux/modules/socialCommunityPApp';
import {
  editPost,
  fetchDetail,
  resetUrls,
  uploadImageToS3,
  uploadVideoToS3,
  cleanUpPost,
  makeIsFetchedStatus
} from '@common/redux/modules/socialCommunityPApp/reducers';
import {
  dataURItoBlob,
  extractVideoMetaInfo
} from '@common/redux/modules/socialCommunityPApp/service';
import { Form, Icon } from '@common/ui/components';
import YoutubeLinkParser from '@common/utils/youtubeLinkParser';
import { SOCIAL_POST_MAX_LENGTH, STORY_TYPES, MEDIA_LIMIT } from '@common/constants/index';
import styled from 'styled-components';

import { buildPostsPath } from '@routers/routes';
import { Iframe, Video } from '../../components/atoms';
import { SubmitNavBar, PreviewCard, SelectBar } from '../../components/molecules';
import { PreviewList } from '../../components/organisms';
import {
  TextContentArea,
  CancelButtonModal,
  ConfirmModal,
  YoutubeLinkModal
} from '../../components/templates';
import { Loading } from '@common/ui/components/Loading';

type SelectedFileType = {
  id: string;
  previewSrc: string;
  file?: File | Blob;
};

type FormField = {
  content: string;
};

function EditPost() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('translation');
  const { postId }: { postId: string } = useParams();

  const [channelId, setChannelId] = useState(0);
  const [channelCode, setChannelCode] = useState('');

  const [contents, setContents] = useState('');
  const [defaultImages, setDefaultImages] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<SelectedFileType[]>([]);
  const [videoSrc, setVideoSrc] = useState('');

  const [isModalOpen, setModalOpen] = useState(false);
  const [isAlertModalOpen, setAlertModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isCloseModalOpen, setCloseModalOpen] = useState(false);

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const post = useSelector(getPost);
  const s3ImageUrls = useSelector(getResponseImageUrls);
  const s3VideoUrl = useSelector(getResponseVideoUrl);

  const isFetched = useSelector(getIsFetched);
  const isLoading = useSelector(getIsLoading);

  const methods = useForm<FormField>({
    mode: 'onChange',
    defaultValues: {
      content: contents
    }
  });
  const { handleSubmit, watch, getValues, reset } = methods;
  const contentValue = watch('content');

  useEffect(
    () => () => {
      dispatch(cleanUpPost());
      dispatch(resetUrls());
      dispatch(makeIsFetchedStatus(false));
    },
    []
  );

  useEffect(() => {
    if (isFetched && channelCode) {
      history.push(buildPostsPath(channelCode));
    }
  }, [history, channelCode, isFetched]);

  useEffect(() => {
    if (!currentChannel) return;

    setChannelId(currentChannel.id);
    setChannelCode(currentChannel.primitiveInvitationCode);
  }, [currentChannel]);

  useEffect(() => {
    if (!postId || !channelId) return;

    dispatch(fetchDetail({ postId, channelId }));
  }, [dispatch, postId, channelId]);

  useEffect(() => {
    if (!post) return;
    const { content, storyResource } = post;

    setContents(content);
    reset(post);
    setSelectedOption(storyResource.type);

    switch (storyResource.type) {
      case STORY_TYPES.IMAGE: {
        setDefaultImages(storyResource.imageSrcs);
        break;
      }
      case STORY_TYPES.VIDEO: {
        if (!storyResource.videoSrc) return;
        setVideoSrc(storyResource.videoSrc);
        break;
      }
      case STORY_TYPES.YOUTUBE: {
        if (!storyResource.youtubeSrc) return;
        setSelectedFiles([
          {
            id: storyResource.youtubeSrc,
            previewSrc: YoutubeLinkParser.getEmbedUrl(storyResource.youtubeSrc)
          }
        ]);
        break;
      }
    }
  }, [post, reset]);

  useEffect(() => {
    const description = getValues('content');

    if (selectedOption === STORY_TYPES.IMAGE && s3ImageUrls.length) {
      dispatch(
        editPost({
          postId,
          channelId,
          content: description,
          storyResource: {
            type: selectedOption,
            imageSrcs: [...defaultImages, ...s3ImageUrls]
          }
        })
      );
      return;
    }

    if (selectedOption === STORY_TYPES.VIDEO && s3VideoUrl && s3ImageUrls.length) {
      dispatch(
        editPost({
          postId,
          channelId,
          content: description,
          storyResource: {
            type: selectedOption,
            imageSrcs: s3ImageUrls,
            videoSrc: s3VideoUrl
          }
        })
      );
    }
  }, [dispatch, s3ImageUrls, s3VideoUrl]);

  const handleUploadPost: SubmitHandler<FormField> = ({ content }) => {
    switch (selectedOption) {
      case STORY_TYPES.VIDEO: {
        if (!selectedFiles.length) {
          dispatch(
            editPost({
              content,
              channelId,
              postId,
              storyResource: {
                type: selectedOption,
                imageSrcs: post?.storyResource.imageSrcs,
                videoSrc: post?.storyResource.videoSrc
              }
            })
          );
          return;
        }

        const [videoFile] = selectedFiles;
        if (!videoFile.file) return;

        dispatch(uploadImageToS3({ name: uuid(), body: dataURItoBlob(videoFile.previewSrc) }));
        dispatch(
          uploadVideoToS3({
            name: uuid(),
            body: videoFile.file as File,
            format: (videoFile.file as File).name.slice(-3)
          })
        );
        break;
      }
      case STORY_TYPES.IMAGE: {
        if (!selectedFiles.length) {
          dispatch(
            editPost({
              content,
              channelId,
              postId,
              storyResource: {
                type: selectedOption,
                imageSrcs: defaultImages
              }
            })
          );
          return;
        }

        dispatch(
          uploadImageToS3(
            selectedFiles.map((el: SelectedFileType) => ({
              name: uuid(),
              body: el.file
            }))
          )
        );

        break;
      }
      case STORY_TYPES.YOUTUBE: {
        if (!selectedFiles.length) {
          dispatch(
            editPost({
              content,
              channelId,
              postId,
              storyResource: {
                type: selectedOption,
                youtubeSrc: post?.storyResource.youtubeSrc
              }
            })
          );
          return;
        }

        dispatch(
          editPost({
            channelId,
            content,
            postId,
            storyResource: {
              type: selectedOption,
              youtubeSrc: selectedFiles[0].id
            }
          })
        );
        break;
      }
      default: {
        dispatch(
          editPost({ content, channelId, postId, storyResource: { type: STORY_TYPES.PLAIN } })
        );
      }
    }
  };

  const handleDeleteResource = (id?: string) => {
    if (selectedOption === STORY_TYPES.VIDEO || selectedOption === STORY_TYPES.YOUTUBE) {
      setSelectedFiles([]);
      setVideoSrc('');
      setSelectedOption('');
      return;
    }

    const filteredFiles = selectedFiles.filter((file) => file.id !== id);
    if (!filteredFiles.length && !defaultImages.length) setSelectedOption('');

    setSelectedFiles(filteredFiles);
  };

  const handleDeleteDefaultImage = (id?: string) => {
    const filteredDefaultImages = defaultImages.filter((src) => src !== id);
    if (!filteredDefaultImages.length && !selectedFiles.length) setSelectedOption('');

    setDefaultImages(filteredDefaultImages);
  };

  const handleYoutubeLink = ({ youtubeLink }: any) => {
    if (!YoutubeLinkParser.getId(youtubeLink)) {
      setAlertMessage(t('str_err_993', '유효하지 않은 youTube 주소입니다.'));
      setAlertModalOpen(true);
      return;
    }

    setSelectedFiles([
      {
        id: youtubeLink,
        previewSrc: YoutubeLinkParser.getEmbedUrl(youtubeLink)
      }
    ]);

    setSelectedOption(STORY_TYPES.YOUTUBE);
    setModalOpen(false);
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const { files } = e.target;

    if (!files?.length) return;

    if (type === STORY_TYPES.VIDEO) {
      const [file] = files;
      const { duration, dataUrl } = await extractVideoMetaInfo(file);

      if (duration > MEDIA_LIMIT.VIDEO_MAX_DURATION) {
        setAlertMessage(
          t('str_err_991', {
            defaultValue: '영상은 최대 1분 분량까지 업로드할 수 있습니다.({{errCode}})',
            errCode: 'err'
          })
        );
        setAlertModalOpen(true);
        return;
      }

      if (file.size >= MEDIA_LIMIT.VIDEO_MAX_SIZE) {
        setAlertMessage(
          t('str_err_992', {
            defaultValue: '영상은 최대 30MB까지 업로드할 수 있습니다.({{errCode}})',
            errCode: 'err'
          })
        );
        setAlertModalOpen(true);
        return;
      }

      setSelectedFiles([{ id: uuid(), previewSrc: dataUrl, file }]);
      setSelectedOption(type);
      setVideoSrc(URL.createObjectURL(files[0]));
      return;
    }

    if (type === STORY_TYPES.IMAGE) {
      if ([...defaultImages, ...selectedFiles, ...files].length > MEDIA_LIMIT.IMAGE_MAX_COUNT) {
        setAlertMessage(
          t('str_err_989', {
            defaultValue: '이미지는 최대 10개까지 업로드할 수 있습니다.({{errCode}})',
            errCode: 'err'
          })
        );
        setAlertModalOpen(true);
        return;
      }

      const isImageSizeOver = [...files].some((file) => file.size >= MEDIA_LIMIT.IMAGE_MAX_SIZE);
      if (isImageSizeOver) {
        setAlertMessage(
          t('str_err_990', {
            defaultValue: '이미지는 최대 10MB까지 업로드 할 수 있습니다.({{errCode}})',
            errCode: 'err'
          })
        );
        setAlertModalOpen(true);
        return;
      }

      const extractedImages = [...files].map((file) => ({
        id: uuid(),
        previewSrc: URL.createObjectURL(file),
        file
      }));

      setSelectedFiles([...selectedFiles, ...extractedImages]);
      setSelectedOption(type);
    }
  };

  const handleCancelPost = () => {
    setCloseModalOpen(false);
    history.goBack();
  };

  const handleBackToPostsPage = () => {
    if (!getValues('content')) {
      history.goBack();
      return;
    }

    setCloseModalOpen(true);
  };

  return (
    <>
      {isLoading && <Loading />}
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(handleUploadPost)}>
          {/** TODO: 와이어프레임 생성 후 스트링 키 값 변경 */}
          <SubmitNavBar
            title="게시글 수정"
            icon={
              <Icon iconName="ic_PaperPlane" colorCode={contentValue ? 'blue500' : 'grey400'} />
            }
            disabled={!contentValue.length}
            onClose={handleBackToPostsPage}
          />

          <S_EmptyNavBar />

          <TextContentArea
            name="content"
            placeholder={t('str_86', '내용을 입력해주세요 ....')}
            maxLength={SOCIAL_POST_MAX_LENGTH}
          />
        </Form>
      </FormProvider>

      <SelectBar handleModal={() => setModalOpen(true)} handleFileChange={handleFileChange} />

      <S_ContentsBox>
        {post && selectedOption === STORY_TYPES.IMAGE && (
          <>
            {defaultImages.map((src) => (
              <PreviewCard key={src} src={src} id={src} handleDelete={handleDeleteDefaultImage} />
            ))}
            <PreviewList images={selectedFiles} handleDelete={handleDeleteResource} />
          </>
        )}
        {post && selectedOption === STORY_TYPES.VIDEO && videoSrc && (
          <Video
            src={videoSrc}
            hasDeleteButton
            onDelete={handleDeleteResource}
            thumbnailSrc={s3ImageUrls.length ? s3ImageUrls[0] : post.storyResource.imageSrcs[0]}
          />
        )}
        {post && selectedOption === STORY_TYPES.YOUTUBE && selectedFiles.length > 0 && (
          <Iframe
            src={selectedFiles[0].previewSrc}
            hasDeleteButton
            onDelete={handleDeleteResource}
          />
        )}
      </S_ContentsBox>

      {isModalOpen && (
        <YoutubeLinkModal isOpen onSubmit={handleYoutubeLink} onClose={() => setModalOpen(false)} />
      )}

      <ConfirmModal
        isOpen={isAlertModalOpen}
        onClick={() => setAlertModalOpen(false)}
        onClose={() => setAlertModalOpen(false)}
        message={alertMessage}
      />

      <CancelButtonModal
        isOpen={isCloseModalOpen}
        onClick={handleCancelPost}
        onClose={() => setCloseModalOpen(false)}
        message={t(
          'str_85',
          '이전 화면으로 돌아가시겠습니까?\n(입력 중인 내용은 보존되지 않습니다.)'
        )}
      />
    </>
  );
}

const S_EmptyNavBar = styled.div`
  height: 56px;
`;

const S_ContentsBox = styled.div`
  padding: 0 24px;
  padding-bottom: 24px;
`;

export default EditPost;
