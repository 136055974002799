import styled from 'styled-components';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import { makeGetCurrentChannelProfile } from '@common/redux/modules/profile';
import { fetchCurrentUser } from '@common/redux/modules/user/reducers';
import { ImageView } from '@common/ui/components';

function InfoItem() {
  const dispatch = useDispatch();
  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);

  const getCurrentChannelProfile = useMemo(makeGetCurrentChannelProfile, []);

  const currentChannel = useSelector(getCurrentChannel);
  const currentChannelProfile = useSelector(getCurrentChannelProfile);
  const [title, setTitle] = useState<string>('');
  const { imageSrc } = currentChannelProfile;

  useEffect(() => {
    dispatch(fetchCurrentUser());
    if (currentChannel) {
      setTitle(currentChannel.title);
    }
  }, [currentChannel]);

  return (
    <S_InfoItem>
      <S_Profile>
        <ImageView src={imageSrc} width={96} ratio="1_1" scaleType="cover" />
      </S_Profile>
      <S_TextLabel>{title}</S_TextLabel>
    </S_InfoItem>
  );
}

const S_TextLabel = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
`;

const S_InfoItem = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 24px;
`;

const S_Profile = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 64px;
  margin-bottom: 16px;
`;

export default InfoItem;
