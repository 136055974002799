import { createReducer, createAction } from '@reduxjs/toolkit';

import { builderFactory } from '@common/utils/reduxHelper';

import {
  InitialStateType,
  FetchListQueryParamTypes,
  PostIdQueryParamTypes,
  CreatePostPayload,
  UpdatePostPayload,
  FetchCommentsQueryParamTypes,
  CreateCommentPayload,
  DeleteCommentPathVariables
} from './types';

const prefix = '@freeBoard';

export const fetchList = createAction<FetchListQueryParamTypes>(`${prefix}/FETCH_LIST`);
export const fetchDetail = createAction<PostIdQueryParamTypes>(`${prefix}/FETCH_DETAIL`);
export const addPost = createAction<CreatePostPayload>(`${prefix}/ADD_POST`);
export const updatePost = createAction<UpdatePostPayload>(`${prefix}/UPDATE_POST`);
export const removePost = createAction<{ postId: string; channelId: number }>(
  `${prefix}/REMOVE_POST`
);

export const fetchComments = createAction<FetchCommentsQueryParamTypes>(`${prefix}/FETCH_COMMENTS`);
export const addComment = createAction<CreateCommentPayload>(`${prefix}/ADD_COMMENT`);
export const deleteComment = createAction<DeleteCommentPathVariables>(`${prefix}/DELETE_COMMENT`);

export const cleanUpPosts = createAction(`${prefix}/CLEAN_UP_POSTS`);
export const cleanUpPost = createAction(`${prefix}/CLEAN_UP_POST`);
export const resetResponseStatus = createAction(`${prefix}/RESET_RESPONSE_STATUS`);

const initialState: InitialStateType = {
  isLoading: true,
  error: null,
  posts: [],
  post: null,
  totalPostsCount: 0,
  comments: [],
  totalCommentsCount: 0,
  responseStatus: 0,
  isCommentCreated: false,
  isCommentDeleted: false
};

const reducer = createReducer(initialState, (builder) => {
  builderFactory(builder, [
    fetchDetail,
    fetchList,
    addPost,
    updatePost,
    removePost,
    fetchComments,
    addComment,
    deleteComment
  ])
    .addCase(`${fetchList}_COMPLETED`, (state, action: any) => {
      const { posts, pagination } = action.payload.data.data;

      state.posts = posts;
      state.totalPostsCount = pagination.total;
    })
    .addCase(`${fetchDetail}_COMPLETED`, (state, action: any) => {
      const { post } = action.payload.data.data;

      state.post = post;
    })
    .addCase(`${addPost}_COMPLETED`, (state, action: any) => {
      state.responseStatus = action.payload.status;
    })
    .addCase(`${updatePost}_COMPLETED`, (state, action: any) => {
      state.responseStatus = action.payload.status;
    })
    .addCase(`${fetchComments}_COMPLETED`, (state, action: any) => {
      const { comments, pagination } = action.payload.data.data;

      state.totalCommentsCount = pagination.total;

      if (state.isCommentCreated) {
        state.comments = comments;
        state.isCommentCreated = false;
        return;
      }

      if (state.isCommentDeleted) {
        state.isCommentDeleted = false;
      }

      state.comments = [...state.comments, ...comments];
    })
    .addCase(`${addComment}_COMPLETED`, (state, action: any) => {
      state.responseStatus = action.payload.status;
      state.isCommentCreated = true;
    })
    .addCase(`${deleteComment}_COMPLETED`, (state, action: any) => {
      const targetId = action.payload.config.pathVariables.commentId;
      const filteredComments = state.comments.filter((comment) => comment.id !== targetId);

      state.comments = filteredComments;
      state.isCommentDeleted = true;
    })
    .addCase(cleanUpPosts, (state) => {
      state.posts = [];
    })
    .addCase(cleanUpPost, (state) => {
      state.post = null;
      state.comments = [];
    })
    .addCase(resetResponseStatus, (state) => {
      state.responseStatus = 0;
    })
    .addMatcher(
      (action) => action.type.startsWith(prefix) && action.type.endsWith('_COMPLETED'),
      (state) => {
        state.isLoading = false;
      }
    )
    .addMatcher(
      (action) => action.type.startsWith(prefix) && action.type.endsWith('_FAILED'),
      (state, action) => {
        state.isLoading = false;
        state.error = action.payload.data.message;
      }
    );
});

export default reducer;
