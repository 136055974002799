import IconType from '../IconType';

const RadioOn = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M11.9999,0 C5.3729,0 -0.0001,5.373 -0.0001,12 C-0.0001,18.627 5.3729,24 11.9999,24 C18.6269,24 23.9999,18.627 23.9999,12 C23.9999,5.373 18.6269,0 11.9999,0 M11.9999,1.5 C17.7899,1.5 22.4999,6.21 22.4999,12 C22.4999,17.79 17.7899,22.5 11.9999,22.5 C6.2099,22.5 1.4999,17.79 1.4999,12 C1.4999,6.21 6.2099,1.5 11.9999,1.5 Z M12.00005,6 C8.6863415,6 6.00005,8.6862915 6.00005,12 C6.00005,15.3137085 8.6863415,18 12.00005,18 C15.3137585,18 18.00005,15.3137085 18.00005,12 C18.00005,8.6862915 15.3137585,6 12.00005,6 Z"
      />
    </svg>
  );
};

export default RadioOn;
