import React from 'react';

import { colors, fontSize, fontWeight } from '@common/ui/styles/theme';
import styled, { css } from 'styled-components';

export type Colors = keyof typeof colors;
export type FontSize = keyof typeof fontSize;
export type FontWeight = keyof typeof fontWeight;

type TextStyleProps = {
  color?: Colors;
  fontSizes?: FontSize;
  fontWeights?: FontWeight;
  newline?: boolean;
  elipsis?: boolean;
  lineLimit?: number;
};

type Props = {
  color?: Colors;
  fontSizes?: FontSize;
  fontWeights?: FontWeight;
  children: React.ReactNode | string;
  newline?: boolean;
  elipsis?: boolean;
  lineLimit?: number;
};

function Text({
  color = 'grey900',
  fontSizes = 'fontSizeBody1',
  fontWeights = 'fontWeightNormal',
  children,
  newline,
  elipsis = true,
  lineLimit = 1
}: Props) {
  return (
    <S_Text
      fontSizes={fontSizes}
      color={color}
      fontWeights={fontWeights}
      newline={newline}
      elipsis={elipsis}
      lineLimit={lineLimit}
    >
      {children}
    </S_Text>
  );
}

const S_Text = styled.div<TextStyleProps>`
  color: ${({ color }) => color && colors[color]};
  font-size: ${({ fontSizes, theme }) => fontSizes && theme[fontSizes]};
  font-weight: ${({ fontWeights, theme }) => fontWeights && theme[fontWeights]};
  line-height: ${({ theme }) => theme.fontLineHeight};
  ${({ newline }) => newline && `white-space: pre;`};
  ${({ elipsis, lineLimit }) => {
    if (elipsis && lineLimit && lineLimit === 1) return OnlyLineElipsis;
    if (elipsis && lineLimit && lineLimit > 1) return LineLimitElipsis;
    return NoElipsis;
  }};
`;

const NoElipsis = css`
  height: auto;
`;

const OnlyLineElipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LineLimitElipsis = css<{ lineLimit?: number }>`
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: ${({ lineLimit }) => lineLimit};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default Text;
