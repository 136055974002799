import IconType from '../IconType';

const BookingTime = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M12.0003,2.063 C17.4873,2.063 21.9373,6.512 21.9373,12 C21.9373,17.488 17.4873,21.937 12.0003,21.937 C6.5123,21.937 2.0623,17.488 2.0623,12 C2.0623,6.512 6.5123,2.063 12.0003,2.063 Z M12.0003,3.563 C7.3483,3.563 3.5623,7.348 3.5623,12 C3.5623,16.652 7.3483,20.437 12.0003,20.437 C16.6513,20.437 20.4373,16.652 20.4373,12 C20.4373,7.348 16.6513,3.563 12.0003,3.563 Z M12.0006,5.2521 C12.4148136,5.2521 12.7506,5.58788644 12.7506,6.0021 L12.7506,11.6101 L16.3640983,14.1516024 C16.6721222,14.3682466 16.7674679,14.7746029 16.6032131,15.1012607 L16.5461,15.1966 L16.5461,15.1966 C16.307779,15.5354411 15.8399131,15.6169608 15.5010375,15.3786889 L11.2506,12.3901 L11.2506,6.0021 C11.2506,5.58788644 11.5863864,5.2521 12.0006,5.2521 Z"
      />
    </svg>
  );
};

export default BookingTime;
