import React from 'react';

import { Icon } from '@common/ui/components';
import styled from 'styled-components';

type Props = {
  icon?: boolean;
  share?: boolean;
  logout?: boolean;
  children?: React.ReactNode;
  onClick?: (...args: any) => any;
};

function Item({ children, icon, share, logout, onClick }: Props) {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <S_Item logout={logout}>
      <S_Title onClick={handleClick} logout={logout}>
        {children}
      </S_Title>
      {icon && <Icon iconName="ic_ArrowRight" colorCode="grey500" size={16} />}
      {share && <Icon iconName="ic_OpenPage" colorCode="grey500" size={16} />}
    </S_Item>
  );
}

const S_Item = styled.div<{ logout?: boolean }>`
  align-items: center;
  cursor: ${({ logout }) => logout && 'pointer'};
  display: flex;
  height: 56px;
  justify-content: space-between;
  padding: 0 24px;
  &:focus {
    outline: none;
  }
  &:hover {
    opacity: ${({ logout }) => logout && 0.8};
  }
`;

const S_Title = styled.span<{ logout?: boolean }>`
  color: ${({ theme, logout }) => (logout ? theme.red500 : theme.grey900)};
  font-size: ${({ theme }) => theme.fontSizeBody1};
`;

export default Item;
