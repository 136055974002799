import PropTypes from 'prop-types';
import React from 'react';

import styled from 'styled-components';

type NavViewProps = {
  nav: React.ReactNode;
  children?: React.ReactNode;
};

/**
 * 모바일 네비게이션 뷰
 *
 * @param {Object} props
 * @param {Object} props.nav 네비게이션 컴포넌트
 */
function NavView({ nav, children }: NavViewProps) {
  return (
    <NavViewContainer>
      {nav}
      <Item>{children}</Item>
    </NavViewContainer>
  );
}

NavView.defaultProps = {
  nav: null
};

NavView.propTypes = {
  nav: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

const NavViewContainer = styled.div`
  box-shadow: -30px 0 100px -100px #000, 30px 0 100px -100px #000;
  height: 100%;
  position: relative;
`;

const Item = styled.div`
  position: relative;
  width: 100%;
`;

export default NavView;
