import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { HistoryBar } from '../molcules';

function HistoryArea() {
  const { t } = useTranslation('translation');

  const titleA = '예약권 차감되었습니다.';
  const titleB = '예약이 취소되었습니다.(예약권 복구)';
  const timeA = '2021-03-23 11:00';
  const timeB = '2021-03-23 11:00';
  const ticketCount = 1;

  return (
    <S_HistoryAreaContainer>
      <S_Text>
        {t('str_papp_cbooking_ticket_history', {
          defaultValue: '$t(str_papp_cbooking_ticket) 이력'
        })}
      </S_Text>
      <HistoryBar title={titleA} time={timeA} ticketCount={ticketCount} minus />
      <HistoryBar title={titleB} time={timeB} ticketCount={ticketCount} />
    </S_HistoryAreaContainer>
  );
}

const S_HistoryAreaContainer = styled.div`
  background-color: 'white';
  padding-top: 8px;
`;

const S_Text = styled.div`
  color: ${({ theme }) => theme.grey500};
  font-size: ${({ theme }) => theme.fontSizeBody1};
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  line-height: ${({ theme }) => theme.fontLineHeight};
  margin-bottom: 16px;
  padding: 0 24px;
  text-align: left;
`;

export default HistoryArea;
