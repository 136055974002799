import { useLocation } from 'react-router-dom';

import DateHelper from '@common/utils/dateHelper';
import styled from 'styled-components';

import { BookingConfirmTemplate } from './templates';

type BookingConfirmProps = {
  selectDate: ReturnType<typeof DateHelper.getInstance>;
  selectTime: string;
  channelID: string;
  useTicket: () => void;
};

function BookingConfirm({ selectDate, selectTime, channelID, useTicket }: BookingConfirmProps) {
  const location = useLocation<{ request: string; duration: number }>();

  const { request, duration } = location.state;

  useTicket();

  return (
    <S_BookingConfirmPage>
      <BookingConfirmTemplate
        selectDate={selectDate}
        selectTime={selectTime}
        duration={duration}
        requestComment={request}
        channelID={channelID}
      />
    </S_BookingConfirmPage>
  );
}

const S_BookingConfirmPage = styled.div`
  background-color: ${({ theme }) => theme.grey50};
  height: 100%;
`;

export default BookingConfirm;
