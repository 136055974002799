import { SmartLink, Icon } from '@common/ui/components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ChannelProfile } from '../molecules';

export type ChannelAuthProps = {
  channelInfo: {
    imgSrc: string;
    title: string;
  };
};

export default function ChannelAuth({ channelInfo }: ChannelAuthProps) {
  const { t } = useTranslation('translation');
  return (
    <S_ChannelAuth>
      <S_ChannelProfileWrapper>
        <ChannelProfile channelInfo={channelInfo} />
        <S_LinkWrapper>
          <SmartLink to="/signin">
            <S_LinkTitle>
              {t('str_259', '시작하기')}
              <Icon iconName="ic_ArrowRight2" colorCode="blue500" size={24} />
            </S_LinkTitle>
          </SmartLink>
          <S_Description to="https://publ.biz/">
            {t('str_1319', '이 사이트의 계정 및 결제, IT 시스템은 publ팀이 관리하고 있습니다.')}
          </S_Description>
        </S_LinkWrapper>
      </S_ChannelProfileWrapper>
    </S_ChannelAuth>
  );
}

const S_ChannelAuth = styled.div`
  box-shadow: 0 100px 0 0 white, 0 -100px 0 0 white, 0 0 50px -30px rgb(0 0 0 / 50%),
    0 0 50px -30px rgb(0 0 0 / 50%);
  height: 100%;
`;

const S_ChannelProfileWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const S_LinkWrapper = styled.div`
  margin-top: 50%;
`;

const S_LinkTitle = styled.div`
  color: ${({ theme }) => theme.blue500};
  font-size: 1.25rem;
  text-align: center;

  & > svg {
    vertical-align: sub;
  }
`;

const S_Description = styled(SmartLink)`
  white-space: pre;
  margin-top: 64px;
  display: inline-block;
  letter-spacing: 1.2;
  line-height: 1.2em;
  color: ${({ theme }) => theme.grey400};
  font-size: ${({ theme }) => theme.fontSizeCaption2};
`;
