import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';

import { ModalDialog, InputFieldButton, Button, Form } from '@common/ui/components';

import { YOUTUBE_LINK_MAX_LENGTH } from '@common/constants/index';
import styled from 'styled-components';

type Props = {
  isOpen: boolean;
  onSubmit: (data: any) => void;
  onClose: () => void;
};

function YoutubeLinkModal({ isOpen, onSubmit, onClose }: Props) {
  const { t } = useTranslation('translation');
  const methods = useForm();
  const { handleSubmit, reset } = methods;

  useEffect(() => {
    return () => {
      reset({ youtubeLink: '' });
    };
  }, []);

  return (
    <ModalDialog onClose={onClose} title={t('str_youtube_url', 'YouTube URL')} isOpen={isOpen}>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <S_FormBox>
            <InputFieldButton
              name="youtubeLink"
              placeholder={t('str_89', 'YouTube URL을 입력해주세요 ...')}
              maxLength={YOUTUBE_LINK_MAX_LENGTH}
            />
            <Button type="submit" label={t('str_confirm', '확인')} />
          </S_FormBox>
        </Form>
      </FormProvider>
    </ModalDialog>
  );
}

const S_FormBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`;

export default YoutubeLinkModal;
