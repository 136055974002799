import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * 스마트 폼 컴포넌트
 * @param {boolean} props.autoComplete 자동완성 기능 설정
 * @param {function} props.onSubmit 제출 이벤트 콜백
 * @param {boolean} props.displayButton 외부 버튼 생성 여부
 */

type FormProps = {
  autoComplete?: boolean;
  children?: React.ReactNode;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  displayButton?: boolean;
};

type FormStyleType = {
  displayButton?: boolean;
};

function Form({ autoComplete, children, onSubmit, displayButton }: FormProps) {
  return (
    <StyledForm
      onSubmit={onSubmit}
      displayButton={displayButton}
      autoComplete={autoComplete ? 'on' : 'off'}
    >
      {children}
    </StyledForm>
  );
}

Form.defaultProps = {
  autoComplete: false,
  children: [],
  displayButton: false,
  onSubmit: null
};

Form.propTypes = {
  autoComplete: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onSubmit: PropTypes.func,
  displayButton: PropTypes.bool
};

const StyledForm = styled.form<FormStyleType>`
  width: 100%;
`;

export default Form;
