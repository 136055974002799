import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';

import { PrivateRoute } from '@common/overrides/index';
import { makeGetIsAuthenticated } from '@common/redux/modules/session';

import { freeboardBasicPath } from '@routers/routes';

import {
  FreeBoardPosts,
  FreeBoardPostDetail,
  CreateFreeBoardPost,
  EditFreeBoardPost
} from './pages';

const postsPath = `${freeboardBasicPath}/posts`;
const postPath = `${freeboardBasicPath}/posts/:postId`;
const createPostPath = `${freeboardBasicPath}/create`;
const editPostPath = `${freeboardBasicPath}/posts/:postId/edit`;

function FreeBoardPAppContainer() {
  const getIsAuthenticated = useMemo(makeGetIsAuthenticated, []);
  const isAuthenticated = useSelector(getIsAuthenticated);

  return (
    <Switch>
      <PrivateRoute
        exact
        path={postsPath}
        component={FreeBoardPosts}
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        exact
        path={editPostPath}
        component={EditFreeBoardPost}
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        exact
        path={postPath}
        component={FreeBoardPostDetail}
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        exact
        path={createPostPath}
        component={CreateFreeBoardPost}
        isAuthenticated={isAuthenticated}
      />
      <Redirect from={freeboardBasicPath} to={`${postsPath}?page=1&limit=8`} />
    </Switch>
  );
}

export default FreeBoardPAppContainer;
