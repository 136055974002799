import { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { signOut } from '@common/redux/modules/session/reducers';
import { accountPath, buildChannelsSellerInfoPath } from '@routers/routes';
import { makeGetChannelDescParams } from '@common/redux/modules/channel';
import { NavBar, Item } from './molecules';
import { InfoItem } from './organisms';
import { NavView } from './templates';
import { SmartLink } from '@common/ui/components';
import { Link } from 'react-router-dom';

function ChannelSetting() {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const [channelID, setChannelID] = useState('');
  const getChannelDescParams = useMemo(makeGetChannelDescParams, []);
  const channelDescParams = useSelector(getChannelDescParams);

  useEffect(() => {
    if (channelDescParams) {
      setChannelID(channelDescParams.invitationCode);
    }
  }, [channelDescParams]);

  const handleClickSignOut = useCallback(() => {
    dispatch(signOut());
  }, [dispatch]);

  return (
    <NavView nav={<NavBar isBack title={t('str_settings', '설정')} />}>
      <InfoItem />
      <Item share>{t('str_channel_payment_setting', '채널 결제 기록')}</Item>
      <SmartLink to={buildChannelsSellerInfoPath(channelID)}>
        <Item icon>{t('str_channel_seller_info', '판매자 정보')}</Item>
      </SmartLink>
      <Link to={accountPath} target="_blank">
        <Item share>{t('str_my_account', '내 계정')}</Item>
      </Link>
      <Item logout onClick={handleClickSignOut}>
        {t('str_logout', '로그아웃')}
      </Item>
    </NavView>
  );
}

export default ChannelSetting;
