import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Form, InputFieldButton, SubmitButton, Button } from '@common/ui/components';
import styled from 'styled-components';

import { Title } from '../../../components/atoms';

type MyBookingDescRequestProps = {
  description: string | null;
  handleEdit: (data: FormValues) => void;
  isEditModeOpen: boolean;
  setIsEditModeOpen: (state: boolean) => void;
  fromHistory: boolean;
};

type FormValues = {
  requestEdit: string;
};

function MyBookingDescRequest({
  description,
  handleEdit,
  isEditModeOpen,
  setIsEditModeOpen,
  fromHistory
}: MyBookingDescRequestProps) {
  const { t } = useTranslation('translation');
  const methods = useForm<FormValues>({ mode: 'onChange' });

  const { handleSubmit } = methods;

  const requestValidation = {
    maxLength: {
      value: 1024,
      message: '더 이상 작성할 수 없습니다.'
    }
  };

  if (isEditModeOpen) {
    return (
      <S_MyBookingDescRequestContainer>
        <Title text={t('str_47', '요청 사항')} align="left" />
        <S_RequestBox>
          <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(handleEdit)}>
              <S_InputFieldButton
                name="requestEdit"
                maxLength={1024}
                defaultValue={description || ''}
                validation={requestValidation}
                multiLine
              />
              <S_ButtonBox>
                <SubmitButton line size="regular" label={t('str_48', '수정 완료')} />
              </S_ButtonBox>
            </Form>
          </FormProvider>
        </S_RequestBox>
      </S_MyBookingDescRequestContainer>
    );
  }

  return (
    <S_MyBookingDescRequestContainer>
      <Title text={t('str_47', '요청 사항')} align="left" />
      <S_ButtonBox
        onClick={() => {
          setIsEditModeOpen(!isEditModeOpen);
        }}
      >
        {!fromHistory && <Button label={t('str_39', '수정')} line size="regular" />}
      </S_ButtonBox>
      <S_RequestBox>{description}</S_RequestBox>
    </S_MyBookingDescRequestContainer>
  );
}

const S_MyBookingDescRequestContainer = styled.div`
  margin: 0;
  position: relative;
`;

const S_ButtonBox = styled.div`
  background-color: white;
  position: absolute;
  right: 24px;
  top: 0;
`;

const S_RequestBox = styled.div`
  background-color: white;
  border-bottom: 1px solid ${({ theme }) => theme.grey100};
  margin-bottom: 16px;
  padding: 0 24px 24px 24px;
  text-align: left;
`;

const S_InputFieldButton = styled(InputFieldButton)`
  max-height: 160px;
`;

export default MyBookingDescRequest;
