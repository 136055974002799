import api, { getEndpointSet } from '../services/api';

import { ProfilePayload } from './profile.types';

const { FIND_ONE, UPDATE } = getEndpointSet('AUTH', 'profile');

class ProfileRepository {
  static findOne(id: number) {
    return api.get(FIND_ONE, { id }, {});
  }

  static update(id: number, params: ProfilePayload) {
    return api.put(UPDATE, { id }, params);
  }
}

export default ProfileRepository;
