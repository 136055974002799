import dayjs, { Dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezonePlugin from 'dayjs/plugin/timezone';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import updateLocale from 'dayjs/plugin/updateLocale';
import duration from 'dayjs/plugin/duration';
import 'dayjs/locale/ko';

import { HARDCODED_DAY_KO } from '../constants/hardcoded';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(timezonePlugin);
dayjs.extend(weekOfYear);
dayjs.extend(updateLocale);
dayjs.extend(duration);

const DEFAULT_LOCALE = 'ko';
const DEFAULT_TIMEZONE = dayjs.tz.guess();

dayjs.updateLocale(DEFAULT_LOCALE, {
  weekdays: HARDCODED_DAY_KO,
  meridiem: (hour: number) => (hour > 12 ? '오후' : '오전')
});

type GivenDate = string | number | Dayjs;

class DateHelper {
  static locale = '';

  static timezone = '';

  static setConfiguration(config?: { locale: string; timezone: string }) {
    DateHelper.locale = config?.locale || DEFAULT_LOCALE;
    DateHelper.timezone = config?.timezone || DEFAULT_TIMEZONE;
  }

  static getInstance(givenDate?: GivenDate) {
    if (typeof givenDate === 'string' || typeof givenDate === 'number' || !givenDate) {
      // console.log(DateHelper.locale, DateHelper.timezone);
      return dayjs(givenDate).tz(DateHelper.timezone).locale(DateHelper.locale);
    }

    return givenDate;
  }

  static getMappedDate(givenDate: GivenDate) {
    const instance = DateHelper.getInstance(givenDate);

    return {
      year: instance.year(),
      month: instance.month(),
      date: instance.date()
    };
  }

  static getMappedTime(givenDate: GivenDate) {
    const instance = DateHelper.getInstance(givenDate);

    return {
      hours: instance.hour(),
      minutes: instance.minute(),
      seconds: instance.second(),
      milliseconds: instance.millisecond()
    };
  }

  static sortByNewest(a: GivenDate, b: GivenDate) {
    const timeA = DateHelper.getInstance(a).valueOf();
    const timeB = DateHelper.getInstance(b).valueOf();

    if (timeA > timeB) return -1;
    if (timeA < timeB) return 1;

    return 0;
  }

  static sortByOldest(a: GivenDate, b: GivenDate) {
    return DateHelper.sortByNewest(a, b) * -1;
  }

  static isAM(givenDate: GivenDate) {
    const instance = DateHelper.getInstance(givenDate);

    return instance.hour() < 12;
  }

  static isPM(givenDate: GivenDate) {
    return !DateHelper.isAM(givenDate);
  }

  static isToday(givenDate: GivenDate) {
    const instance = DateHelper.getInstance(givenDate);

    return instance.format('YYYYMMDD') === DateHelper.getInstance().format('YYYYMMDD');
  }

  static isYesterday(givenDate: GivenDate) {
    const instance = DateHelper.getInstance(givenDate);

    return instance.isSame(DateHelper.getInstance().date(-1));
  }

  static isSameDate(givenDateA: GivenDate, givenDateB: GivenDate) {
    const instanceA = DateHelper.getInstance(givenDateA);
    const instanceB = DateHelper.getInstance(givenDateB);

    return instanceA.format('YYYYMMDD') === instanceB.format('YYYYMMDD');
  }

  static fromNow(givenDate: GivenDate) {
    return DateHelper.getInstance(givenDate).utc(true).fromNow();
  }

  static format(givenDate: GivenDate, format: string) {
    return DateHelper.getInstance(givenDate).format(format);
  }

  // TODO: UTC 시간 각 pApp에서 확인하기 전 임시 메서드입니다.
  static formatUTCtoKST(givenDate: GivenDate, format: string) {
    return dayjs.utc(givenDate).tz(DateHelper.timezone).locale(DateHelper.locale).format(format);
  }

  static startWeekOfCurrentMonth(givenDate: GivenDate) {
    return DateHelper.getInstance(givenDate).startOf('month').week();
  }

  static endWeekOfCurrentMonth(givenDate: GivenDate) {
    return DateHelper.getInstance(givenDate).endOf('month').week() === 1
      ? 53
      : DateHelper.getInstance(givenDate).endOf('month').week();
  }

  static makeTimeFormatDate(secondsValue: number) {
    const ONE_HOUR = 3600;
    const secondsToMilliseconds = dayjs.duration(secondsValue * 1000);

    const hours = secondsToMilliseconds.hours();
    const minutes = secondsToMilliseconds.minutes();
    const seconds = secondsToMilliseconds.seconds();

    if (secondsValue > ONE_HOUR) {
      return `${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}:${
        seconds > 9 ? seconds : `0${seconds}`
      }`;
    }

    return `${minutes > 9 ? minutes : `0${minutes}`}:${seconds > 9 ? seconds : `0${seconds}`}`;
  }
}

DateHelper.setConfiguration();

export default DateHelper;
