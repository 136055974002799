import JWT from '@common/utils/jwt';
import { AxiosResponse } from 'axios';

import api, { getEndpointSet } from '@common/services/api';
import { ResponseOK } from '@common/types/response';

import { PostsQueryParams, PaginationPayload } from './freeBoardPApp.types';

const { FIND_ALL_POSTS, FIND_POST_BY_ID, CREATE_POST, UPDATE_POST, DELETE_POST } = getEndpointSet(
  'FREE_BOARD_PAPP',
  'postable'
);

const { FIND_ALL_COMMENTS, CREATE_COMMENT, DELETE_COMMENT } = getEndpointSet(
  'FREE_BOARD_PAPP',
  'comments'
);

function getHeaderOptions(id: number) {
  return {
    headers: {
      'x-publ-consumer-id': JWT.getChannelHeader(id, 'A00003')
    }
  };
}

class FreeBoardPAppRepository {
  static findAllPosts(queryParams: PostsQueryParams, channelId: number) {
    return api.get(FIND_ALL_POSTS, {}, queryParams, getHeaderOptions(channelId));
  }

  static findPost(postId: string, channelId: number) {
    return api.get(FIND_POST_BY_ID, { postId }, {}, getHeaderOptions(channelId));
  }

  static createPost(body: any, channelId: number) {
    const channelProfile = JWT.getChannelProfile(channelId);
    if (!channelProfile) return;
    const { nickname, imageSrc } = channelProfile;

    const postBody = {
      author: nickname,
      authorThumbnailSrc: imageSrc,
      isPublished: true,
      ...body
    };

    return api.get(CREATE_POST, {}, postBody, getHeaderOptions(channelId));
  }

  static updatePost(postId: string, body: any, channelId: number) {
    return api.get(UPDATE_POST, { postId }, body, getHeaderOptions(channelId));
  }

  static removePost(
    postId: string,
    channelId: number
  ): Promise<AxiosResponse<ResponseOK<undefined>>> {
    return api.remove(DELETE_POST, { postId }, getHeaderOptions(channelId));
  }

  static findAllComments(postId: string, queryParams: PaginationPayload, channelId: number) {
    return api.get(FIND_ALL_COMMENTS, { postId }, queryParams, getHeaderOptions(channelId));
  }

  static createComment(postId: string, body: { content: string }, channelId: number) {
    const channelProfile = JWT.getChannelProfile(channelId);
    if (!channelProfile) return;
    const { nickname, imageSrc } = channelProfile;

    const postBody = {
      author: nickname,
      authorThumbnailSrc: imageSrc,
      ...body
    };

    return api.post(CREATE_COMMENT, { postId }, postBody, getHeaderOptions(channelId));
  }

  static deleteComment(queryParams: { postId: string; commentId: number }, channelId: number) {
    return api.remove(DELETE_COMMENT, queryParams, getHeaderOptions(channelId));
  }
}

export default FreeBoardPAppRepository;
