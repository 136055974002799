import ObjectHelper from './objectHelper';

type Enumerable<T> = {
  [P in keyof T]: T[P];
};

class EnumOfObjects {
  static assignAndExtendAll<
    E extends Enumerable<E>,
    S extends Enumerable<S>,
    L extends Enumerable<L>
  >(enumerable: E, source: S, lambdas: L) {
    return EnumOfObjects.extendAll(EnumOfObjects.assignAll(enumerable, source), lambdas);
  }

  static assignAll<E extends Enumerable<E>, S extends Enumerable<S>>(enumerable: E, source: S) {
    return (Object.entries(enumerable) as [keyof E, E[keyof E]][]).reduce((acc, [k, v]) => {
      acc[k] = {
        ...v,
        ...source
      };

      return acc;
    }, {} as { [P in keyof E]: E[P] & S });
  }

  static extendAll<E extends Enumerable<E>, L extends Enumerable<L>>(enumerable: E, lambdas: L) {
    return (Object.entries(enumerable) as [keyof E, E[keyof E]][]).reduce((acc, [k, v]) => {
      acc[k] = ObjectHelper.extend(v, lambdas);

      return acc;
    }, {} as { [P in keyof E]: E[P] & { [P2 in keyof L]: ReturnType<L[P2]> } });
  }
}

export default EnumOfObjects;
