import { createSelector } from 'reselect';

import DateHelper from '../../../utils/dateHelper';
import { RootState } from '../../index';
import { CounterpartFormat } from './types';

export const getIsChatLoading = (state: RootState) => {
  return state.chat.isLoading;
};

export const getAllCounterpart = (state: RootState) => {
  return Object.values(state.chat.counterpart) || [];
};

export const getCounterpart = (id: number) => (state: RootState) => {
  return state.chat.counterpart[id] || {};
};

export const getCurrentCounterpartProfileId = (state: RootState) => {
  return state.chat.currentCounterpartProfileId;
};

export const getCurrentCounterpartLatestMessageTxid = (id: number) => (state: RootState) => {
  return state.chat.counterpart[id].latestMessageTxid;
};

export const getCounterpartDateWithDms = (id: number) => (state: RootState) => {
  return state.chat.counterpart[id]?.dateWithDms || [];
};

export const getAllDmsLength = (id: number) => (state: RootState) => {
  return (
    state.chat.counterpart[id]?.dateWithDms.reduce((acc, cur) => {
      return acc + cur.dms.length;
    }, 0) || 0
  );
};

export const getHasOldestMessage = (id: number) => (state: RootState) => {
  return state.chat.counterpart[id]?.hasOldestMessage || false;
};

export const getChatError = (state: RootState) => {
  return state.chat.error;
};

export const makeGetAllCounterpart = () => {
  return createSelector([getAllCounterpart], (counterparts) => {
    return counterparts.sort(byNewestMessage);

    function byNewestMessage(a: CounterpartFormat, b: CounterpartFormat) {
      return DateHelper.sortByNewest(a.latestMessageTime, b.latestMessageTime);
    }
  });
};
