import { put, takeEvery, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { SubscriptionProductsRepository } from '../../../repositories';
import { fetchSubscriptionProduct } from './reducers';
import { digestResponse } from '../../middlewares/response';

function* watchFetchSubscriptionProduct(action: ReturnType<typeof fetchSubscriptionProduct>) {
  const { type, payload } = action;
  const { ...rest } = payload;

  const response: AxiosResponse = yield call(() => SubscriptionProductsRepository.findOne(rest));

  yield put(digestResponse(response, type));
}

function* watch() {
  yield takeEvery(fetchSubscriptionProduct, watchFetchSubscriptionProduct);
}

export default watch;
