import IconType from '../IconType';

const Bell = ({ color, size, ...rest }: IconType) => (
  <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M14.5567,19.8493 C14.5567,21.2613 13.4117,22.4063 11.9997,22.4063 C10.5877,22.4063 9.4427,21.2613 9.4427,19.8493 L9.4427,19.8493 Z M11.9996,1.9861 C13.0956,1.9861 13.9826,2.8381 14.0596,3.9141 C17.1476,4.8351 18.9776,7.7771 18.9776,10.7231 L18.9776,10.7231 L18.9776,15.7421 C18.9776,15.7601 18.9846,15.7771 18.9976,15.7891 L18.9976,15.7891 L19.9836,16.7671 C20.9176,17.6921 20.2316,19.2361 18.8866,19.2361 L18.8866,19.2361 L5.1126,19.2361 C3.7686,19.2361 3.0826,17.6921 4.0156,16.7671 L4.0156,16.7671 L5.0026,15.7891 C5.0146,15.7771 5.0226,15.7601 5.0226,15.7421 L5.0226,15.7421 L5.0226,10.7231 C5.0226,7.7481 6.7096,4.7811 9.9416,3.8901 C10.0306,2.8251 10.9126,1.9861 11.9996,1.9861 Z"
    />
  </svg>
);

export default Bell;
