import IconType from '../IconType';

const PostNotice = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M7.3808,2.6413 C7.0718,3.2293 6.8808,3.8873 6.8808,4.5963 C6.8808,5.5523 7.6588,6.3303 8.6158,6.3303 L8.6158,6.3303 L15.3848,6.3303 C16.3408,6.3303 17.1198,5.5523 17.1198,4.5963 C17.1198,3.8873 16.9288,3.2293 16.6198,2.6413 L16.6198,2.6413 L17.4998,2.6413 C19.4328,2.6413 20.9998,4.2083 20.9998,6.1413 L20.9998,6.1413 L20.9998,18.1413 C20.9998,20.0743 19.4328,21.6413 17.4998,21.6413 L17.4998,21.6413 L6.4998,21.6413 C4.5668,21.6413 2.9998,20.0743 2.9998,18.1413 L2.9998,18.1413 L2.9998,6.1413 C2.9998,4.2083 4.5668,2.6413 6.4998,2.6413 L6.4998,2.6413 Z M12.7528,15.9253 L7.7798,15.9253 C7.3658,15.9253 7.0298,16.2613 7.0298,16.6753 C7.0298,17.0893 7.3658,17.4253 7.7798,17.4253 L7.7798,17.4253 L12.7528,17.4253 C13.1668,17.4253 13.5028,17.0893 13.5028,16.6753 C13.5028,16.2613 13.1668,15.9253 12.7528,15.9253 L12.7528,15.9253 Z M16.2208,12.3283 L7.7798,12.3283 C7.3658,12.3283 7.0298,12.6643 7.0298,13.0783 C7.0298,13.4923 7.3658,13.8283 7.7798,13.8283 L7.7798,13.8283 L16.2208,13.8283 C16.6348,13.8283 16.9708,13.4923 16.9708,13.0783 C16.9708,12.6643 16.6348,12.3283 16.2208,12.3283 L16.2208,12.3283 Z M16.2208,8.7313 L7.7798,8.7313 C7.3658,8.7313 7.0298,9.0673 7.0298,9.4813 C7.0298,9.8953 7.3658,10.2313 7.7798,10.2313 L7.7798,10.2313 L16.2208,10.2313 C16.6348,10.2313 16.9708,9.8953 16.9708,9.4813 C16.9708,9.0673 16.6348,8.7313 16.2208,8.7313 L16.2208,8.7313 Z M12.8797,1.3566 C14.6070103,1.3566 16.0192291,2.70832414 16.1145697,4.41178586 L16.1197,4.5956 C16.1197,5.0016 15.7907,5.3306 15.3847,5.3306 L8.6157,5.3306 C8.24353333,5.3306 7.93606806,5.05414861 7.88740833,4.69535 L7.8807,4.5956 C7.8807,2.8066 9.3307,1.3566 11.1207,1.3566 L12.8797,1.3566 Z"
      />
    </svg>
  );
};

export default PostNotice;
