import { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';

const appPath = /^(\/.*)/i;
const externalLinkRe = /^https?:\/\//i;

/**
 * 스마트 링크
 * 미리 정의된 패턴에 따라 내부 링크로 연결할지 새 페이지로 이동할지 결정하여 렌더링함.
 *
 * @param {Object} props
 * @param {string} props.to 링크 URL
 * @param {boolean} props.openNewTab 외부 링크인 경우 새 탭으로 이동할지 여부
 * @param {boolean} props.openPopup 외부 링크인 경우 팝업으로 열지 여부
 * @param {function|RegExp} props.test 내부 링크로 정의된 패턴
 * @param {JSX.Element} props.children 링크로 감쌀 컴포넌트
 */
function SmartLink({
  to,
  test = appPath,
  openNewTab = true,
  openPopup = false,
  children,
  ...rest
}: {
  to: string;
  children: JSX.Element;
  test?: RegExp | Function;
  openNewTab?: boolean;
  openPopup?: boolean;
}) {
  const location = useLocation();
  const isSameAppPath = !externalLinkRe.test(to) && ('test' in test ? test.test(to) : test(to));

  if (isSameAppPath) {
    return (
      <Link style={{ textDecoration: 'none' }} to={to} {...rest}>
        {children}
      </Link>
    );
  }

  if (openPopup) {
    const { pathname } = location;
    return (
      <Link
        style={{ textDecoration: 'none' }}
        to={{
          pathname,
          search: `?ext=${to}`
        }}
        {...rest}
      >
        {children}
      </Link>
    );
  }

  if (openNewTab) {
    return (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        {...rest}
        style={{ textDecoration: 'none' }}
      >
        {children}
      </a>
    );
  }

  return (
    <a href={to} {...rest} style={{ textDecoration: 'none' }}>
      {children}
    </a>
  );
}

export default memo(SmartLink);
