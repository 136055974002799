import { Icon, RatioImage } from '@common/ui/components';
import DateHelper from '@common/utils/dateHelper';
import styled from 'styled-components';

type Props = {
  thumbnailSrc: string;
  createdAt: string;
  title: string;
  runningTime: number;
  isCurrentVOD?: boolean;
};

function EpisodeItem({ thumbnailSrc, createdAt, title, runningTime, isCurrentVOD = false }: Props) {
  return (
    <S_EpisodeItem isCurrentVOD={isCurrentVOD}>
      <S_ThumbnailBox>
        <RatioImage ratio={16 / 9} src={thumbnailSrc} />
        {isCurrentVOD ? (
          <S_GradientBackground>
            <S_PlayingIcon iconName="ic_VideoPlay" colorCode="white" />
          </S_GradientBackground>
        ) : (
          <>
            <S_Icon iconName="ic_VideoPlayCircle" colorCode="white" />
            <S_Chip>{DateHelper.makeTimeFormatDate(runningTime)}</S_Chip>
          </>
        )}
      </S_ThumbnailBox>

      <S_Contents>
        <span className="episodeTitle">{title}</span>
        <span className="createdDate">{DateHelper.fromNow(createdAt)}</span>
      </S_Contents>
    </S_EpisodeItem>
  );
}

const S_Icon = styled(Icon)`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const S_ThumbnailBox = styled.div`
  margin-right: 12px;
  position: relative;
  width: 100%;
`;

const S_EpisodeItem = styled.div<{
  isCurrentVOD: boolean;
}>`
  background-color: ${({ theme, isCurrentVOD }) => isCurrentVOD && theme.grey50};
  display: flex;
  flex: 1;
  padding: 16px 24px;
`;

const S_Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  letter-spacing: normal;
  min-width: 155px;
  text-align: left;
  width: 100%;

  .episodeTitle {
    color: ${({ theme }) => theme.grey900};
    font-size: ${({ theme }) => theme.fontSizeBody2};
    font-weight: ${({ theme }) => theme.fontWeightBold};
    line-height: ${({ theme }) => theme.fontLineHeight};
    overflow-wrap: break-word;
    word-break: break-word;
  }

  .createdDate {
    color: ${({ theme }) => theme.grey400};
    font-size: ${({ theme }) => theme.fontSizeCaption1};
  }
`;

const S_Chip = styled.div`
  background: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  bottom: 0;
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.fontSizeCaption2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  margin: 4px;
  padding: 1px 6px;
  position: absolute;
  right: 0;
  text-align: center;
`;

const S_GradientBackground = styled.div`
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8) 98%);
  border-radius: 0 0 12px 12px;
  bottom: 0;
  height: 24px;
  margin: 4px 0 0;
  position: absolute;
  width: 100%;
`;

const S_PlayingIcon = styled(Icon)`
  bottom: 0;
  margin: 4px;
  position: absolute;
  right: 0;
`;

export default EpisodeItem;
