import IconType from '../IconType';

const ArrowUp = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        transform="rotate(90 12.001 11.293)"
        d="M15.544875,19.474625 C15.352875,19.474625 15.160875,19.401625 15.014875,19.254625 L8.167875,12.407625 C7.554875,11.792625 7.554875,10.793625 8.167875,10.179625 L15.014875,3.331625 C15.307875,3.038625 15.781875,3.038625 16.074875,3.331625 C16.367875,3.624625 16.367875,4.099625 16.074875,4.392625 L9.228875,11.239625 C9.198875,11.268625 9.198875,11.317625 9.228875,11.347625 L16.074875,18.194625 C16.367875,18.487625 16.367875,18.961625 16.074875,19.254625 C15.928875,19.401625 15.736875,19.474625 15.544875,19.474625"
      />
    </svg>
  );
};

export default ArrowUp;
