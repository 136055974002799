import {
  CounterpartProfileResponse,
  CounterpartProfilesResponse,
  CounterpartsResponse,
  ReadDmResponse,
  RecentDmsResponse,
  SendingNewDmResponse
} from '@common/redux/modules/chat/types';
import api, { getEndpointSet } from '../services/api';
import { PathVariables, QueryParams } from '../services/api/api';

const {
  FIND_ALL_RECENT_COUNTERPARTS_DMS,
  FIND_RECENT_DMS,
  SENDING_NEW_DM,
  READ_DM
} = getEndpointSet('CHAT', 'dms');
const { PROFILE_BY_ID, PROFILE_BY_LIST } = getEndpointSet('CHANNEL', 'profiles');

class ChatRepository {
  static counterparts(): Promise<CounterpartsResponse> {
    return api.get(
      FIND_ALL_RECENT_COUNTERPARTS_DMS,
      {},
      {},
      { headers: { 'X-Publ-Channel-Id': 1 } }
    );
  }

  static counterpartProfile(pathVariables: PathVariables): Promise<CounterpartProfileResponse> {
    return api.get(PROFILE_BY_ID, pathVariables, {}, { headers: { 'X-Publ-Channel-Id': 1 } });
  }

  static counterpartProfiles(params: QueryParams): Promise<CounterpartProfilesResponse> {
    return api.get(PROFILE_BY_LIST, {}, params, { headers: { 'X-Publ-Channel-Id': 1 } });
  }

  static recentDms(pathVariables: PathVariables, queryParams = {}): Promise<RecentDmsResponse> {
    return api.get(FIND_RECENT_DMS, pathVariables, queryParams, {
      headers: { 'X-Publ-Channel-Id': 1 }
    });
  }

  static sendingNewDm({
    profileId,
    message
  }: {
    profileId: number;
    message: string;
  }): Promise<SendingNewDmResponse> {
    return api.post(SENDING_NEW_DM, { profileId }, JSON.stringify({ messageText: message }), {
      headers: {
        'Content-Type': 'application/json',
        'X-Publ-Channel-Id': 1
      }
    });
  }

  static readDm({ profileId, txid }: { profileId: number; txid: string }): Promise<ReadDmResponse> {
    return api.post(READ_DM, { profileId, txid }, null, {
      headers: {
        'X-Publ-Channel-Id': 1
      }
    });
  }
}

export default ChatRepository;
