import { YoutubeLinkParser } from '@common/utils/index';
import { STORY_TYPES } from '@common/constants/index';

import { TextContent } from '../../../components/molecules';
import { Image, Video, Iframe } from '../../../components/atoms';
import styled from 'styled-components';

type ResourceType = {
  type: string;
  imageSrcs: string[] | null;
  videoSrc: string | null;
  youtubeSrc: string | null;
};

export type Props = {
  content: string;
  resource: ResourceType;
  isFoldable?: boolean;
};

function Contents({ content, resource, isFoldable }: Props) {
  return (
    <>
      {resource.type === STORY_TYPES.IMAGE && resource.imageSrcs && (
        <Image imageSrcs={resource.imageSrcs} />
      )}

      <S_MediaWrapper>
        {resource.type === STORY_TYPES.VIDEO && resource.videoSrc && resource.imageSrcs && (
          <Video src={resource.videoSrc} thumbnailSrc={resource.imageSrcs[0]} />
        )}

        {resource.type === STORY_TYPES.YOUTUBE && resource.youtubeSrc && (
          <Iframe src={YoutubeLinkParser.getEmbedUrl(resource.youtubeSrc)} />
        )}
      </S_MediaWrapper>

      <TextContent content={content} isFoldable={isFoldable} />
    </>
  );
}

const S_MediaWrapper = styled.div`
  padding: 0 24px;
`;

export default Contents;
