import { forwardRef } from 'react';
import styled from 'styled-components';
import { StyledNamespace } from '@common/types/styledNamespace';

const S: StyledNamespace = {};

S.DateBar = styled.div`
  color: ${(props) => props.theme.grey400};
  font-size: ${(props) => props.theme.fontSizeCaption1};
  font-weight: ${(props) => props.theme.fontWeightBold};
  margin: 16px 0;
`;

type DateBarProps = {
  date: string;
};

const DateBar = forwardRef(({ date }: DateBarProps, ref) => {
  return <S.DateBar ref={ref}>{date}</S.DateBar>;
});

DateBar.displayName = 'DateBar';

export default DateBar;
