import styled from 'styled-components';

type HistoryBarProps = {
  title: string;
  time: string;
  ticketCount: number;
  minus?: boolean;
};

function HistoryBar({ title, time, ticketCount, minus }: HistoryBarProps) {
  return (
    <S_HistoryBarContainer>
      <S_TextSide>
        <S_Title>{title}</S_Title>
        <S_Time>{time}</S_Time>
      </S_TextSide>
      {minus && <S_Plus> -{ticketCount}장</S_Plus>}
      {!minus && <S_Minus> +{ticketCount}장</S_Minus>}
    </S_HistoryBarContainer>
  );
}

const S_HistoryBarContainer = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.grey100};
  display: flex;
  height: 72px;
  justify-content: space-between;
  margin: 0 24px;
`;

const S_TextSide = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const S_Title = styled.span`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  height: 21px;
  margin: 2px 0;
`;

const S_Time = styled.span`
  color: ${({ theme }) => theme.grey400};
  font-size: ${({ theme }) => theme.fontSizeCaption1};
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  height: 18px;
  margin: 2px 0;
`;

const S_Plus = styled.span`
  color: #00b884;
  font-size: ${({ theme }) => theme.fontSizeBody2};
`;

const S_Minus = styled.span`
  color: #ff463a;
  font-size: ${({ theme }) => theme.fontSizeBody2};
`;

export default HistoryBar;
