import { ResetPw } from './pages';

/**
 * 비밀번호 재설정 컨테이너
 */
function ResetPwContainer() {
  return <ResetPw />;
}

export default ResetPwContainer;
