import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LOGO_IMG from '@assets/img/logo.png';
import { Button } from '@common/ui/components';
import { SignInForm } from '../organisms';
import { ChannelProfile } from '../../../../Channels/pages/ChannelAuth/molecules';

type SignInViewProps = {
  currentChannel?: {
    iconImageSrc: string;
  };
  onSignIn: (values: any) => void;
  onResetPw: Function;
  onSignUp: Function;
};

/**
 * SignIn 뷰
 *
 * @param {function} props.onSignIn 로그인 폼 입력 완료 콜백
 */
function SignInView({ currentChannel, onSignIn, onResetPw, onSignUp }: SignInViewProps) {
  return (
    <SignInViewContainer>
      <FormWrapper>
        <LogoWrapper>
          {currentChannel ? (
            <ChannelProfile channelInfo={{ imgSrc: currentChannel.iconImageSrc, title: '' }} />
          ) : (
            <Logo src={LOGO_IMG} alt="Publ" />
          )}
        </LogoWrapper>
        <SignInForm onSignIn={onSignIn} />
        <SubWrapper>
          <SubButton onClick={() => onResetPw()} text label="비밀번호 재설정" />
          <BarWrapper>
            <Bar />
          </BarWrapper>
          <SubButton onClick={() => onSignUp()} text label="회원가입" />
        </SubWrapper>
      </FormWrapper>
    </SignInViewContainer>
  );
}

SignInView.defaultProps = {
  onSignIn: null
};

SignInView.propTypes = {
  onSignIn: PropTypes.func,
  onResetPw: PropTypes.func.isRequired,
  onSignUp: PropTypes.func.isRequired
};

const SignInViewContainer = styled.div`
  align-items: center;
  background-color: white;
  box-shadow: 0 100px 0 0 white, 0 -100px 0 0 white, 0 0 50px -30px rgba(0, 0, 0, 0.5),
    0 0 50px -30px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
`;

const FormWrapper = styled.div`
  margin-top: 120px;
  width: 100%;
`;

const LogoWrapper = styled.div`
  margin-bottom: 48px;
`;

const Logo = styled.img`
  max-width: 160px;
  pointer-events: none;
`;

const SubWrapper = styled.div`
  margin-top: 24px;
`;

const SubButton = styled(Button)`
  color: ${({ theme }) => theme.grey500};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  line-height: ${({ theme }) => theme.fontLineHeight};
`;

const BarWrapper = styled.span`
  align-items: center;
  background-color: white;
  display: inline-flex;
  height: 20px;
  justify-content: center;
`;

const Bar = styled.span`
  background-color: ${({ theme }) => theme.grey100};
  height: 12px;
  width: 1px;
`;

export default SignInView;
