import React from 'react';

import { Icon } from '@common/ui/components';
import styled from 'styled-components';

import fillIcons from '@assets/icons/fill';
import lineIcons from '@assets/icons/line';

export type FillIconNameKeys = keyof typeof fillIcons;
export type LineIconNameKeys = keyof typeof lineIcons;

type Props = {
  iconMode?: 'none' | 'use';
  iconName?: FillIconNameKeys | LineIconNameKeys | string;
  titleText?: string;
  descText?: string;
};

function InformationListItem({
  iconMode = 'use',
  iconName = 'ic_xxx',
  titleText,
  descText
}: Props) {
  return (
    <S_Item>
      <S_LeftBlock>
        {iconMode === 'none' || <Icon iconName={iconName} colorCode="grey500" size={24} />}
        <S_titleText>{titleText}</S_titleText>
      </S_LeftBlock>
      <S_descText>{descText}</S_descText>
    </S_Item>
  );
}

const S_Item = styled.div`
  align-items: center;
  display: flex;
  height: 56px;
  justify-content: space-between;
  padding: 0 24px;
  &:focus {
    outline: none;
  }
`;

const S_LeftBlock = styled.div`
  display: flex;
`;

const S_titleText = styled.span`
  font-size: ${({ theme }) => theme.fontSizeBody1};
  font-weight: ${({ theme }) => theme.fontWeightBold};
`;

const S_descText = styled.span`
  font-size: ${({ theme }) => theme.fontSizeBody2};
  text-align: right;
`;

export default InformationListItem;
