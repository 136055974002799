import styled from 'styled-components';

import DateHelper from '@common/utils/dateHelper';

type Props = {
  authorThumbnailSrc: string;
  author: string;
  createdAt: string;
};

function ProfileInfo({ authorThumbnailSrc, author, createdAt }: Props) {
  return (
    <S_ProfileInfo>
      <S_Image src={authorThumbnailSrc} alt="authorThumbnailSrc" />
      <S_InfoBlock>
        <S_Name>{author}</S_Name>
        <S_Date>{DateHelper.fromNow(createdAt)}</S_Date>
      </S_InfoBlock>
    </S_ProfileInfo>
  );
}

const S_ProfileInfo = styled.span`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-start;
`;

const S_Image = styled.img`
  border-radius: 20px;
  height: 40px;
  margin-right: 12px;
  width: 40px;
`;

const S_InfoBlock = styled.span`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const S_Name = styled.div`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeCaption1};
`;

const S_Date = styled(S_Name)`
  color: ${({ theme }) => theme.grey400};
`;

export default ProfileInfo;
