import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@common/ui/components';
import { BookingHistoryType } from '@common/redux/modules/booking/types';
import DateHelper from '@common/utils/dateHelper';

type StatusCardProps = {
  startDate: string;
  duration: number;
  history: BookingHistoryType[];
  handleButtonModal: (buttonLabel: string, ContentsText: string) => void;
};

function StatusCard({ startDate, duration, history, handleButtonModal }: StatusCardProps) {
  const { t } = useTranslation('translation');
  const { status } = history[0];

  const endTime = DateHelper.getInstance(startDate).add(duration, 'minute');
  const bumperTime = DateHelper.getInstance() > endTime.add(-3, 'minute');

  return (
    <S_StatusCardContainer>
      <S_ContentContainer>
        <S_StatusBox>
          {
            // Case 1-1)
            status === 9 && (
              <>
                {t('str_papp_cbooking_subs_card_status_a', '상담 진행 중')}
                <span />
              </>
            )
          }
          {
            // Case 1-2)
            status === 10 && (
              <>
                {t('str_papp_cbooking_subs_card_status_a', '상담 진행 중')}
                <span>
                  {t('str_papp_cbooking_history_evt_refusal_rq', {
                    defaultValue: '상담 거부 요청'
                  })}
                </span>
              </>
            )
          }
          {
            // Case 1-3)
            status === 12 && (
              <>
                {t('str_papp_cbooking_subs_card_status_a', '상담 진행 중')}
                <span>
                  {t('str_papp_cbooking_subs_history_evt_refusal_deny', {
                    defaultValue: '$t(str_seller)의 상담 거부 거절'
                  })}
                </span>
              </>
            )
          }
          {
            // Case 2-1)
            status === 0 && (
              <>
                {t('str_papp_cbooking_subs_card_status_b', '상담 예정')}
                <span />
              </>
            )
          }
          {
            // Case 2-2)
            status === 8 && (
              <>
                {t('str_papp_cbooking_subs_card_status_b', '상담 예정')}
                <span>
                  {t('str_papp_cbooking_subs_history_rsv_cancel_deny_from_seller', {
                    defaultValue: '$t(str_seller)의 $t(str_papp_cbooking_rsv_cancellation) 거절'
                  })}
                </span>
              </>
            )
          }
          {
            // Case 2-3)
            status === 7 && (
              <>
                {t('str_papp_cbooking_subs_card_status_b', '상담 예정')}
                <span>
                  {t('str_papp_cbooking_subs_history_rsv_cancel_deny', {
                    defaultValue: '$t(str_papp_cbooking_rsv_cancellation) 거절'
                  })}
                </span>
              </>
            )
          }

          {
            // Case 3-1)
            status === 1 && (
              <>
                {t('str_papp_cbooking_subs_card_status_c', '예약 취소 대기')}
                <span>
                  {t('str_papp_cbooking_subs_history_rsv_cancel_rq', {
                    defaultValue: '$t(str_papp_cbooking_rsv_cancellation) 요청'
                  })}
                </span>
              </>
            )
          }
          {
            // Case 3-2)
            status === 2 && (
              <>
                {t('str_papp_cbooking_subs_card_status_c', '예약 취소 대기')}
                <span>
                  {t('str_papp_cbooking_subs_history_rsv_cancel_rq_from_seller', {
                    defaultValue: '$t(str_seller)의 $t(str_papp_cbooking_rsv_cancellation) 요청'
                  })}
                </span>
              </>
            )
          }

          {
            // Case 4-1)
            (status === 3 || status === 4 || status === 5 || status === 6) && (
              <>
                {t('str_papp_cbooking_subs_card_status_e', '예약 취소')}
                <span />
              </>
            )
          }
          {
            // Case 5-1)
            (status === 11 || status === 14) && (
              <>
                {t('str_papp_cbooking_subs_card_status_f', '상담 거부')}
                <span />
              </>
            )
          }
          {
            // Case 6-1)
            status === 13 && (
              <>
                {t('str_papp_cbooking_subs_card_status_d', '상담 완료')}
                <span />
              </>
            )
          }
        </S_StatusBox>

        <S_ButtonWrapper>
          {(status === 9 || status === 12) && !bumperTime && (
            <Button
              label={t('str_papp_cbooking_evt_refusal', '진행 거부')}
              line
              size="regular"
              onClick={() =>
                handleButtonModal(
                  t('str_papp_cbooking_evt_refusal', '진행 거부'),
                  '셀러에게 진행 취소를 요청하시나요?'
                )
              }
            />
          )}
          {(status === 0 || status === 7 || status === 8) && (
            <Button
              label={t('str_papp_cbooking_rsv_cancellation', '예약 취소')}
              line
              size="regular"
              onClick={() =>
                handleButtonModal(
                  t('str_papp_cbooking_rsv_cancellation', '예약 취소'),
                  '셀러에게 예약 취소를 요청하시나요?'
                )
              }
            />
          )}
          {status === 2 && (
            <>
              <Button
                label={t('str_papp_cbooking_request_accept', '요청 수락')}
                line
                size="regular"
                onClick={() =>
                  handleButtonModal(
                    t('str_papp_cbooking_request_accept', '요청 수락'),
                    '셀러의 예약 취소 요청을 수락하시나요?'
                  )
                }
              />
              <Button
                label={t('str_papp_cbooking_request_refuse', '요청 거절')}
                line
                size="regular"
                onClick={() =>
                  handleButtonModal(
                    t('str_papp_cbooking_request_refuse', '요청 거절'),
                    '셀러의 예약 취소 요청을 거절하시나요?'
                  )
                }
              />
            </>
          )}
        </S_ButtonWrapper>
      </S_ContentContainer>
    </S_StatusCardContainer>
  );
}

const S_StatusCardContainer = styled.div`
  background-color: ${({ theme }) => theme.grey50};
  border-radius: 16px;
  margin: 24px 24px 32px 24px;
  padding: 24px;
`;

const S_ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const S_StatusBox = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.fontSizeH2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  min-height: 32px;
  & span {
    color: ${({ theme }) => theme.red500};
    font-size: ${({ theme }) => theme.fontSizeCaption1};
    font-weight: ${({ theme }) => theme.fontWeightNormal};
    height: 21px;
    margin: 8px 0 16px 0;
  }
`;

const S_ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  height: 32px;
  justify-content: center;
`;

export default StatusCard;
