class QueryString {
  static fromQueryParams(queryParams) {
    const pairList = Object.entries(queryParams).reduce((acc, [key, value]) => {
      return [...acc, `${key}=${value}`];
    }, []);

    const queryStringPart = pairList.join('&');

    return queryStringPart ? `?${queryStringPart}` : '';
  }

  static toQueryParams(queryString) {
    if (!queryString) {
      return {};
    }

    const [queryStringPart] = queryString.split('?');

    if (!queryStringPart) {
      return {};
    }

    const pairList = queryStringPart.split('&');

    return pairList.reduce((acc, pair) => {
      if (!pair) {
        return acc;
      }

      const [key, value] = pair.split('=');

      if (value === undefined) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});
  }
}

export default QueryString;
