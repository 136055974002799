const prefix = '@middlewares/error';

const DIGEST_ERROR = `${prefix}/DIGEST_ERROR`;

export const digestError = (action, originalType) => ({
  ...action,
  type: DIGEST_ERROR,
  meta: { originalType }
});

const createErrorMiddleware = ({ genToast }) => () => (next) => (action) => {
  const { type, payload: error } = action;

  if (type !== DIGEST_ERROR) {
    return next(action);
  }

  console.log('ERROR CATCH:', action);

  return genToast ? next(genToast(error, action.meta.originalType)) : next(action);
};

export default createErrorMiddleware;
