import debug from 'debug';

const COLORS = {
  trace: 'lightblue',
  info: 'blue',
  warn: 'pink',
  error: 'red'
};

class Logger {
  constructor() {
    debug.disable();
    if (process.env.REACT_APP_DEBUG_MODE === 'true') {
      debug.enable(`${process.env.REACT_APP_NAME}:*`);
    }
  }

  trace(message: string, source: string) {
    return this.generateMessage('trace', message, source);
  }

  info(message: string, source: string) {
    return this.generateMessage('info', message, source);
  }

  warn(message: string, source: string) {
    return this.generateMessage('warn', message, source);
  }

  error(message: string, source: string) {
    return this.generateMessage('error', message, source);
  }

  generateMessage(level: keyof typeof COLORS, message: string, source: string) {
    const namespace = `${process.env.REACT_APP_NAME}:${level}`;
    const createDebug = debug(namespace);

    createDebug.color = COLORS[level];

    if (source) {
      createDebug(source, message);
    } else {
      createDebug(message);
    }
  }
}

export default new Logger();
