/* eslint-disable */
import { VirtualProduct, SystemInvoice } from '../models/index.js';
import { Repo } from '../utils/index.js';

class Profitable {
  static genSystemInvoice(repo, ownDefinedProduct, issuer, recipient) {
    const virtualProduct = VirtualProduct.fromOwnDefinedProduct(ownDefinedProduct);
    const createdVirtualProductRow = Repo.insert(repo, 'virtualProducts', virtualProduct);

    const systemInvoice = SystemInvoice.fromComponents(createdVirtualProductRow, issuer, recipient);
    const createdSystemInvoiceRow = Repo.insert(repo, 'systemInvoices', systemInvoice);

    return { systemInvoice: createdSystemInvoiceRow };
  }

  static findLatestSystemReceipt(repo, siid) {
    return Repo.findAll(
      repo,
      'systemReceipts',
      { siid },
      { key: 'issuedAt', direction: 'DESC' }
    )[0];
  }

  static findSystemInvoice(repo, siid) {
    return Repo.findOne(repo, 'systemInvoices', { siid });
  }

  static findEntityByProductModel(repo, id, productModel) {
    const schema = Profitable._findMatchingSchema(productModel);

    return Repo.findOne(repo, schema, { id, isDeactivated: false, isDeleted: false });
  }

  static _findMatchingSchema(productModel) {
    switch (productModel) {
      case 'ChannelPlanProduct':
        return 'channelPlanProducts';

      default:
        return;
    }
  }
}

export default Profitable;
