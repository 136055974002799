import { forwardRef, Fragment } from 'react';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DateWithDms } from '@common/redux/modules/chat/types';
import { StyledNamespace } from '@common/types/styledNamespace';

import { Bubble, DateBar } from '../atoms';

const S: StyledNamespace = {};

S.ChatView = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  -ms-overflow-style: none;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

S.InfiniteScroll = styled(InfiniteScroll as any)`
  display: flex;
  flex-direction: column-reverse;
`;

S.LastMessageBox = styled.div`
  color: ${(props) => props.theme.grey500};
  font-size: ${(props) => props.theme.fontSizeCaption2};
  margin: 16px;
`;

type ChatViewProps = {
  dateWithDms: DateWithDms[];
  allDmsLength: number;
  onScrollUp: () => void;
  hasOldestMessage: boolean;
  resendDm: ({ message, messageId }: { message: string; messageId: number }) => void;
};

const ChatView = forwardRef(
  ({ dateWithDms, allDmsLength, onScrollUp, hasOldestMessage, resendDm }: ChatViewProps, ref) => {
    return (
      <S.ChatView ref={ref} id="scrollableDiv">
        <S.InfiniteScroll
          dataLength={allDmsLength}
          next={onScrollUp}
          inverse
          hasMore={!hasOldestMessage}
          loader={<div>Loading...</div>}
          scrollableTarget="scrollableDiv"
        >
          {dateWithDms.map(({ date, dms }) => {
            return (
              <Fragment key={date}>
                {dms.map((message) => {
                  return (
                    <Bubble
                      key={message.id}
                      id={message.id}
                      messageText={message.messageText}
                      time={message.time}
                      isMyMessage={message.isMyMessage}
                      hasTail={message.hasTail}
                      sendOK={message.sendOK}
                      resendDm={resendDm}
                    />
                  );
                })}
                <DateBar date={date} />
              </Fragment>
            );
          })}
          {hasOldestMessage && <S.LastMessageBox>마지막 메세지 입니다.</S.LastMessageBox>}
        </S.InfiniteScroll>
      </S.ChatView>
    );
  }
);

ChatView.displayName = 'ChatView';

export default ChatView;
