import PropTypes from 'prop-types';

import { StyledNamespace } from '@common/types/styledNamespace';
import styled from 'styled-components';

const S: StyledNamespace = {};

type SignOutProps = {
  onSignOut?: (...args: any) => any;
};

/**
 * 로그아웃 버튼
 *
 * @param {Object} props
 * @param {any} props.component
 */
function SignOut({ onSignOut }: SignOutProps) {
  const handleSignOut = () => {
    if (onSignOut) {
      onSignOut();
    }
  };

  return (
    <S.SignOut onClick={handleSignOut}>
      <S.Title>로그아웃</S.Title>
    </S.SignOut>
  );
}

SignOut.propTypes = {
  onSignOut: PropTypes.func.isRequired
};

S.SignOut = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 56px;
  justify-content: space-between;
  padding: 0 24px;
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
`;

S.Title = styled.span`
  color: ${({ theme }) => theme.red500};
  font-size: ${({ theme }) => theme.fontSizeBody1};
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  line-height: ${({ theme }) => theme.fontLineHeight};
`;

export default SignOut;
