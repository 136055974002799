import { useTranslation } from 'react-i18next';

import DateHelper from '@common/utils/dateHelper';
import styled from 'styled-components';

import { Title } from '../../../components/atoms';
import { ContentBar } from '../../../components/molcules';

type MyBookingDescInfoProps = {
  startDate: string;
  duration: number;
};

function MyBookingDescInfo({ startDate, duration }: MyBookingDescInfoProps) {
  const { t } = useTranslation('translation');
  const startDateFormat = DateHelper.getInstance(startDate).format('YYYY-MM-DD');
  const startTime = DateHelper.getInstance(startDate).format('HH:mm');

  return (
    <S_MyBookingDescInfoContainer>
      <Title text={t('str_8', '예약 정보')} align="left" />
      <ContentBar
        iconName="ic_Booking"
        title={t('str_14', '$t(str_papp_cbooking_rsv) 일시')}
        content={t('str_26', { startDate: startDateFormat, startTime })}
      />
      <ContentBar
        iconName="ic_BookingTime"
        title={t('str_46', '상담 시간')}
        content={t('str_42', { duration, defaultValue: '{{duration}}분' })}
      />
      <ContentBar
        iconName="ic_BookingTicket"
        title={t('str_papp_cbooking_ticket', '예약권')}
        content={t('str_15', {
          ticketAmount: 1,
          defaultValue: '$t(str_papp_cbooking_ticket) {{ticketAmount}}장 차감'
        })}
      />
    </S_MyBookingDescInfoContainer>
  );
}

const S_MyBookingDescInfoContainer = styled.div`
  margin-bottom: 8px;
`;

export default MyBookingDescInfo;
