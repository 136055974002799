import { put, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { NoticePAppRepository } from '../../../../repositories';
import { createComment, deleteComment, fetchComments } from '../reducers';
import { digestResponse } from '../../../middlewares/response';

export function* watchFetchComments(action: ReturnType<typeof fetchComments>) {
  const { type, payload } = action;
  const { postId, channelId, ...params } = payload;

  const response: AxiosResponse = yield call(() =>
    NoticePAppRepository.findAllComments(postId, params, channelId)
  );

  yield put(digestResponse(response, type));
}

export function* watchCreateComment(action: ReturnType<typeof createComment>) {
  const { type, payload } = action;
  const { postId, channelId, ...body } = payload;

  const response: AxiosResponse = yield call(() =>
    NoticePAppRepository.createComment(postId, body, channelId)
  );

  yield put(digestResponse(response, type));
}

export function* watchDeleteComment(action: ReturnType<typeof deleteComment>) {
  const { type, payload } = action;
  const { postId, commentId, channelId } = payload;

  const response: AxiosResponse = yield call(() =>
    NoticePAppRepository.deleteComment({ postId, commentId }, channelId)
  );

  yield put(digestResponse(response, type));
}
