import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Form, InputFieldButton, SubmitButton, CheckBox } from '@common/ui/components';
import styled from 'styled-components';

type RequestCheckProps = {
  onBooking: (data: { request: string }) => void;
  ticketLeft: number;
  comment: string;
  setComment: (currentComment: string) => void;
  check: boolean;
  setCheck: (currentCheck: boolean) => void;
};

type FormValues = {
  request: string;
};

function RequestCheck({
  onBooking,
  ticketLeft,
  comment,
  setComment,
  check,
  setCheck
}: RequestCheckProps) {
  const { t } = useTranslation('translation');
  const methods = useForm<FormValues>({ mode: 'onChange' });

  const { watch, handleSubmit } = methods;

  const isChecked = watch('agreement' as any);
  const commentValue = watch('request' as any);

  const handleBooking = (data: { request: string }) => {
    onBooking(data);
  };

  const requestValidation = {
    maxLength: {
      value: 1024,
      message: '더 이상 작성할 수 없습니다.'
    }
  };

  useEffect(() => {
    setComment(commentValue);
    setCheck(isChecked);
  }, [check, commentValue, isChecked, setCheck, setComment]);

  return (
    <S_Form>
      <S_Title>{t('str_47')}</S_Title>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(handleBooking)}>
          <S_InputFieldButton
            name="request"
            maxLength={1024}
            placeholder={t('str_18', '요청 사항을 입력해 주세요.')}
            validation={requestValidation}
            defaultValue={comment === '' ? undefined : comment}
            multiLine
          />
          <S_Divider />
          <CheckBox
            name="agreement"
            label={t('str_25', '주문정보 확인하였으며, 이에 동의합니다. (필수)')}
          />
          <S_EmptyBottom />
          <S_SubmitButtonBox>
            <S_SubmitButton>
              <SubmitButton
                full
                disabled={!check || ticketLeft === 0}
                label={t('str_papp_cbooking_rsv_do', '예약하기')}
              />
            </S_SubmitButton>
          </S_SubmitButtonBox>
        </Form>
      </FormProvider>
    </S_Form>
  );
}

const S_Form = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  text-align: left;
  user-select: none;
`;

const S_Title = styled.div`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeH2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  line-height: ${({ theme }) => theme.fontLineHeight};
  margin-bottom: 24px;
  margin-top: 24px;
  text-align: left;
`;

const S_SubmitButton = styled.div`
  margin: 16px 24px 0 24px;
`;

const S_SubmitButtonBox = styled.div`
  background-color: white;
  bottom: 0;
  box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.1);
  height: 114px;
  left: 0;
  position: absolute;
  right: 0;
`;

const S_Divider = styled.div`
  background: ${({ theme }) => theme.grey100};
  height: 1px;
  margin: 24px 0;
  width: 100%;
`;

const S_EmptyBottom = styled.div`
  height: 15px;
`;

const S_InputFieldButton = styled(InputFieldButton)`
  max-height: 160px;
`;

export default RequestCheck;
