import { useTranslation } from 'react-i18next';

import { ReactComponent as ReceiptBottom } from '@assets/img/im-booking-confirm-receipt.svg';
import { SmartLink, Button } from '@common/ui/components';
import DateHelper from '@common/utils/dateHelper';
import styled from 'styled-components';

import { buildBookingMyBookingPath } from '@routers/routes';
import { ConfirmCheck } from '../molcules';
import { ConfirmInfoArea } from '../organisms';

type BookingConfirmTemplateProps = {
  selectDate: ReturnType<typeof DateHelper.getInstance>;
  selectTime: string;
  duration: number;
  requestComment?: string;
  channelID: string;
};

function BookingConfirmTemplate({
  selectDate,
  selectTime,
  duration,
  requestComment,
  channelID
}: BookingConfirmTemplateProps) {
  const { t } = useTranslation('translation');

  return (
    <S_BookingConfirmTemplateContainer>
      <S_BookingConfirmTemplateBox>
        <ConfirmCheck />
        <ConfirmInfoArea
          selectDate={selectDate}
          selectTime={selectTime}
          duration={duration}
          requestComment={requestComment}
        />
      </S_BookingConfirmTemplateBox>
      <ReceiptBottom />
      <S_ButtonBoxContainer>
        <SmartLink to={buildBookingMyBookingPath(channelID)}>
          <Button label={t('str_ok', '확인')} full />
        </SmartLink>
      </S_ButtonBoxContainer>
    </S_BookingConfirmTemplateContainer>
  );
}
const S_BookingConfirmTemplateContainer = styled.div`
  margin: 0 auto;
  padding: 48px 24px 116px 24px;
  width: 327px;
`;

const S_BookingConfirmTemplateBox = styled.div`
  background-color: white;
`;

const S_ButtonBoxContainer = styled.div`
  margin: 24px;
`;

export default BookingConfirmTemplate;
