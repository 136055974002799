import { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import DateHelper from '@common/utils/dateHelper';

import { Icon, Popup, PopupProvider, useModalOpenCount } from '@common/ui/components';

import { InfoText } from '../../../components/atoms';

type Props = {
  title?: string;
  author?: string;
  createdAt?: string;
  viewCount?: number;
  isAuthor: boolean;
  onClickPopUpMenu: (value: string) => void;
};

export const profileBarMenu = [
  {
    key: 'str_edit',
    defaultValue: '수정'
  },
  {
    key: 'str_delete',
    defaultValue: '삭제'
  }
];

function PostInfo({ title, author, createdAt, viewCount, isAuthor, onClickPopUpMenu }: Props) {
  const location = useLocation();
  const { t } = useTranslation('translation');
  const modalOpenCount = useModalOpenCount();

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<any>();

  useEffect(() => {
    setIsOpen(false);
  }, [modalOpenCount, location.pathname]);

  function handleToggle() {
    setIsOpen(!isOpen);
  }

  function handleClickOutside() {
    setIsOpen(false);
  }

  const PopUpComponent = (
    <Popup
      targetRef={ref}
      placement="bottom-end"
      isOpen={isOpen}
      onClickOutside={handleClickOutside}
      onClick={handleToggle}
    >
      <S_MenuBlock>
        {profileBarMenu.map(({ key, defaultValue }) => (
          <S_Menu key={key} onClick={() => onClickPopUpMenu(defaultValue)}>
            {t(key, defaultValue)}
          </S_Menu>
        ))}
      </S_MenuBlock>
    </Popup>
  );

  return (
    <PopupProvider>
      <S_PostInfo>
        <S_Header>
          <S_Title>{title}</S_Title>
          {isAuthor && (
            <S_IconWrapper ref={ref} onClick={handleToggle}>
              <Icon iconName="ic_More" colorCode="grey500" fill />
            </S_IconWrapper>
          )}
        </S_Header>
        <S_InfoBox>
          <InfoText textColorDark>{author}</InfoText>
          <InfoText>
            {createdAt &&
              (DateHelper.isToday(createdAt)
                ? DateHelper.formatUTCtoKST(createdAt, 'hh:mm')
                : DateHelper.formatUTCtoKST(createdAt, 'YYYY-MM-DD'))}
          </InfoText>
          <InfoText>{viewCount && new Intl.NumberFormat().format(viewCount)}</InfoText>
        </S_InfoBox>
      </S_PostInfo>
      {PopUpComponent}
    </PopupProvider>
  );
}

const S_PostInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0;
  padding: 0 24px;
  text-align: left;
`;

const S_Header = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: space-between;
`;

const S_Title = styled.div`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeSubtitle};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  margin-bottom: 8px;
  word-break: break-all;
`;

const S_IconWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  padding: 12px 0 12px 24px;
  -webkit-tap-highlight-color: transparent;
`;

const S_InfoBox = styled.div`
  font-size: ${({ theme }) => theme.fontSizeCaption1};
`;

const S_MenuBlock = styled.div`
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.grey100};
  border-radius: 8px;
  width: 128px;
`;

const S_Menu = styled.div`
  align-items: center;
  border-radius: 8px;
  color: ${({ theme }) => theme.grey900};
  cursor: pointer;
  display: flex;
  font-size: ${({ theme }) => theme.fontSizeBody2};
  height: 48px;
  justify-content: flex-start;
  padding-left: 16px;

  &:hover {
    background-color: ${({ theme }) => theme.grey100};
  }
`;

export default PostInfo;
