import { takeEvery } from 'redux-saga/effects';

import {
  fetchCounterpart,
  connectSocket,
  fetchPreviousDms,
  sendingNewDm,
  resendDm,
  readDm
} from '../reducers';

import {
  watchFetchCounterparts,
  watchFetchPreviousDms,
  watchSendingNewDm,
  watchResendDm,
  watchReadDm
} from './watch';
import watchConnectSocket from './listen';

function* watch() {
  yield takeEvery(fetchCounterpart, watchFetchCounterparts);
  yield takeEvery(fetchPreviousDms, watchFetchPreviousDms);
  yield takeEvery(sendingNewDm, watchSendingNewDm);
  yield takeEvery(resendDm, watchResendDm);
  yield takeEvery(readDm, watchReadDm);
  yield takeEvery(connectSocket, watchConnectSocket);
}

export default watch;
