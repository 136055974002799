import { BookingHistoryType } from '@common/redux/modules/booking/types';
import styled from 'styled-components';

import { MyBookingHistory } from '../molcules';

type MyBookingDescHistoryProps = {
  history: BookingHistoryType[];
};

function MyBookingDescHistory({ history }: MyBookingDescHistoryProps) {
  return (
    <S_MyBookingDescHistoryContainer>
      {history.map((action: BookingHistoryType, i: number) => (
        <S_HistoryChildContainer key={i.toString()}>
          {i === 0 && <MyBookingHistory history={action} key={i.toString()} isFirstChild />}
          {i !== 0 && <MyBookingHistory history={action} key={i.toString()} />}
        </S_HistoryChildContainer>
      ))}
    </S_MyBookingDescHistoryContainer>
  );
}

const S_MyBookingDescHistoryContainer = styled.div`
  margin: 0 24px;
`;

const S_HistoryChildContainer = styled.div`
  border: none;
`;

export default MyBookingDescHistory;
