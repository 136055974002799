import { useTranslation } from 'react-i18next';

import { NavBar } from './molecules';
import { ChannelSettingProfileForm } from './organisms';
import { NavView } from './templates';

/**
 * 채널 프로필 컨테이너
 */

function ChannelSettingProfileContainer() {
  const { t } = useTranslation('translation');

  return (
    <NavView nav={<NavBar isBack title={t('str_update_profile_info', '프로필 정보 수정')} />}>
      <ChannelSettingProfileForm />
    </NavView>
  );
}

export default ChannelSettingProfileContainer;
