import { useTranslation } from 'react-i18next';

import { Button } from '@common/ui/components';
import DateHelper from '@common/utils/dateHelper';
import styled from 'styled-components';

import { DropDown } from '../atoms';

type CalendarTitleProps = {
  handleIsDropDownOpen: () => void;
  handleGoToday: () => void;
  refDate: ReturnType<typeof DateHelper.getInstance>;
};
function CalendarTitle({ handleIsDropDownOpen, handleGoToday, refDate }: CalendarTitleProps) {
  const { t } = useTranslation('translation');
  return (
    <S_CalendarSelectBar>
      <DropDown handleIsDropDownOpen={handleIsDropDownOpen} refDate={refDate} />
      <Button
        label={t('str_31', '오늘')}
        size="regular"
        line
        onClick={() => {
          handleGoToday();
        }}
      />
    </S_CalendarSelectBar>
  );
}

const S_CalendarSelectBar = styled.div`
  align-items: center;
  display: flex;
  height: 36px;
  justify-content: space-between;
  margin: 26px 14px;
`;

export default CalendarTitle;
