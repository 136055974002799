import PropTypes from 'prop-types';
import React from 'react';
import { Switch } from 'react-router-dom';

function EnsuredSwitch({ ensuringCondition, renderAlternativeScreen, children }) {
  return ensuringCondition ? <Switch>{children}</Switch> : <>{renderAlternativeScreen}</>;
}

EnsuredSwitch.propTypes = {
  ensuringCondition: PropTypes.bool.isRequired,
  renderAlternativeScreen: PropTypes.node.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default EnsuredSwitch;
