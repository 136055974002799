import { useTranslation } from 'react-i18next';

import { BookingAvailableEventsType } from '@common/redux/modules/booking/types';
import DateHelper from '@common/utils/dateHelper';
import styled from 'styled-components';

import { Splash } from '../../../../Splash';
import { NavBar } from '../../../components/molcules';
import { EventNonAvailable } from '../atoms';
import { EventList, CalendarGenerator } from '../organisms';

type CalendarTemplateProps = {
  bookingAvailableDates: { [key: string]: boolean };
  eventsAvailable: BookingAvailableEventsType[];
  endWeek: number;
  handleGoToday: () => void;
  handleSelectAnotherDay: (currentFormat: ReturnType<typeof DateHelper.getInstance>) => void;
  handleSelectTime: (current: { id: string; title: string }) => void;
  isBookingLoading: boolean;
  refDate: ReturnType<typeof DateHelper.getInstance>;
  selectDate: ReturnType<typeof DateHelper.getInstance>;
  handleIsDropDownOpen: () => void;
  startWeek: number;
  channelID: string;
};
function CalendarTemplate({
  bookingAvailableDates,
  eventsAvailable,
  endWeek,
  handleGoToday,
  handleSelectAnotherDay,
  handleSelectTime,
  isBookingLoading,
  refDate,
  selectDate,
  handleIsDropDownOpen,
  startWeek,
  channelID
}: CalendarTemplateProps) {
  const { t } = useTranslation('translation');
  return (
    <>
      {!isBookingLoading && (
        <>
          <S_CalendarContainerWrapper className="calendarArea">
            <NavBar
              isMenu
              pathTo="channel"
              title={t('str_pg_title_papp_cbooking_rsv')}
              channelID={channelID}
              pageFrom="Calendar"
            />
            <S_CalendarContainer>
              <CalendarGenerator
                bookingAvailableDates={bookingAvailableDates}
                startWeek={startWeek}
                endWeek={endWeek}
                refDate={refDate}
                selectDate={selectDate}
                handleSelectAnotherDay={handleSelectAnotherDay}
                handleIsDropDownOpen={handleIsDropDownOpen}
                handleGoToday={handleGoToday}
              />
            </S_CalendarContainer>
            <S_Divider />
          </S_CalendarContainerWrapper>
          <S_EventContainer>
            {Object.keys(bookingAvailableDates).length === 0 && <EventNonAvailable />}
            {Object.keys(bookingAvailableDates).length !== 0 && (
              <EventList
                eventsAvailable={eventsAvailable}
                handleSelectTime={handleSelectTime}
                channelID={channelID}
              />
            )}
          </S_EventContainer>
        </>
      )}
      {isBookingLoading && <Splash />}
    </>
  );
}

const S_CalendarContainerWrapper = styled.div`
  background-color: white;
  min-height: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const S_CalendarContainer = styled.div`
  padding: 0 10px;
`;

const S_EventContainer = styled.div`
  margin: ${({ theme }) => theme.marginSideNormal};
`;

const S_Divider = styled.div`
  background: ${({ theme }) => theme.grey100};
  height: 1px;
  margin: 16px 0;
  width: 100vw;
`;

export default CalendarTemplate;
