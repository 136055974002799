import React from 'react';
import styled, { css } from 'styled-components';

/**
 * 입력 필드 스타일 래핑 컴포넌트
 *
 * @param {object} props
 * @param {boolean} props.normal 일반 입력 필드
 * @param {boolean} props.chat 채팅 입력 필드
 * @param {boolean} props.comment 댓글 입력 필드
 * @param {boolean} props.maxLength 라이브채팅 입력 필드
 */

type InputFieldContainerProps = {
  children?: React.ReactNode;
  chat?: boolean;
  comment?: boolean;
  liveChat?: boolean;
  rightIcon?: boolean;
};

type ContainerStyleType = {
  chat?: boolean;
  comment?: boolean;
  liveChat?: boolean;
  rightIcon?: boolean;
};

function InputFieldContainer({
  children,
  chat,
  comment,
  liveChat,
  rightIcon
}: InputFieldContainerProps) {
  return (
    <S_InputContainer chat={chat} comment={comment} liveChat={liveChat} rightIcon={rightIcon}>
      {children}
    </S_InputContainer>
  );
}

const commentInputStyles = css<ContainerStyleType>`
  align-items: center;
  display: flex;
  flex-direction: row;
  ${({ rightIcon }) => !rightIcon && 'padding-right: 24px;'}
`;

const chatInputStyles = css<ContainerStyleType>`
  bottom: 0;
  position: sticky;
  z-index: 10;
`;

const S_InputContainer = styled.div<ContainerStyleType>`
  align-items: center;
  display: flex;
  height: 56px;
  padding-left: 24px;

  ${({ comment }) => comment && commentInputStyles}
  ${({ chat }) => chat && chatInputStyles}
`;

export default InputFieldContainer;
