import styled from 'styled-components';

type Props = {
  ratio?: number;
  src?: string | null;
  borderRadius?: string;
  noBackground?: boolean;
  backgroundSize?: string;
};

function RatioImage({
  ratio = 1,
  src = null,
  borderRadius = 'none',
  backgroundSize = 'cover',
  noBackground
}: Props) {
  return (
    <S_RatioImage
      ratio={ratio}
      src={src}
      borderRadius={borderRadius}
      noBackground={noBackground}
      backgroundSize={backgroundSize}
    />
  );
}

const S_RatioImage = styled.span<Props>`
  background-color: ${({ theme, noBackground }) => (noBackground ? 'none' : theme.grey100)};
  background-image: ${({ src }) => src && `url(${src})`};
  background-position: ${({ backgroundSize }) =>
    backgroundSize === 'contain' ? 'center left' : 'center'};
  background-repeat: no-repeat;
  background-size: ${({ backgroundSize }) => backgroundSize};
  border-radius: ${({ borderRadius }) => borderRadius};
  display: block;
  position: relative;
  width: 100%;
  ${({ ratio }) => ratio && `padding-top: ${Math.floor(100 / ratio)}%`};
`;

export default RatioImage;
