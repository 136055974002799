import { Redirect, Route } from 'react-router-dom';

import { EnsuredSwitch, PublicRoute, PrivateRoute } from './common/overrides';
import {
  rootPath,
  accountPath,
  signInPath,
  signUpPath,
  resetPwPath,
  channelBasicPath,
  channelBasicPAppPath,
  notFoundPath,
  certifyPath,
  purchaseProductPath,
  subscriptionsPath
} from '@routers/routes';
import { CertifyPage } from './screens/Account/pages/Certify';
import { Channels } from './screens/Channels';
import { Splash, Account, SignIn, SignUp, ResetPw, NotFound } from './screens';
import { PurchaseProduct } from './screens/PurchaseProduct';
import { Subscriptions } from './screens/Subscriptions';

function AppRouter({
  isSessionLoading,
  isAuthenticated
}: {
  isSessionLoading: boolean;
  isAuthenticated: boolean;
}) {
  return (
    <EnsuredSwitch ensuringCondition={!isSessionLoading} renderAlternativeScreen={<Splash />}>
      <PublicRoute exact path={rootPath} component={SignIn} />
      <PrivateRoute path={accountPath} component={Account} isAuthenticated={isAuthenticated} />
      <PrivateRoute
        path={subscriptionsPath}
        component={Subscriptions}
        isAuthenticated={isAuthenticated}
      />
      <PublicRoute exact path={signInPath} component={SignIn} />
      <PublicRoute exact path={signUpPath} component={SignUp} />
      <PublicRoute exact path={resetPwPath} component={ResetPw} />
      <PublicRoute exact path={certifyPath} component={CertifyPage} />
      <PublicRoute exact path={purchaseProductPath} component={PurchaseProduct} />

      <PrivateRoute
        exact
        path={certifyPath}
        component={CertifyPage}
        isAuthenticated={isAuthenticated}
      />

      <Route path={channelBasicPAppPath} component={Channels} />
      <Route path={channelBasicPath} component={Channels} />
      <PublicRoute exact path={notFoundPath} component={NotFound} />

      <Route>
        <Redirect to={notFoundPath} />
      </Route>
    </EnsuredSwitch>
  );
}

export default AppRouter;
