import { useEffect, useState, useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeGetIsMenuOpened, makeGetCurrentChannel } from '@common/redux/modules/channel';
import activePAppList from '@common/utils/activePAppList';
import Item from './Item';

import { SmartLink, Icon } from '@common/ui/components';
import styled, { css } from 'styled-components';

import { buildChannelsSettingPath } from '@routers/routes';
import { closeSideMenu } from '@common/redux/modules/channel/reducers';

function Sidebar({ channelID }: { channelID: string }) {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [pApps, setPApps] = useState<
    {
      id: number;
      title: string;
      path: string;
      icon: string;
      isDefault: boolean;
    }[]
  >();

  const getIsMenuOpened = useMemo(makeGetIsMenuOpened, []);
  const isMenuOpened = useSelector(getIsMenuOpened);

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  function closeMenu() {
    dispatch(closeSideMenu());
  }

  useEffect(() => {
    if (currentChannel) {
      setTitle(currentChannel.title);
      const pAppKeys = Object.entries(currentChannel.activePApps);
      setPApps(activePAppList(channelID, pAppKeys));
    }
  }, [currentChannel]);

  return (
    <S_MainMenu>
      <S_Overlay open={isMenuOpened} onClick={() => closeMenu()} />
      <S_StyledMenu open={isMenuOpened} aria-hidden={!isMenuOpened} hidden={!isMenuOpened}>
        <S_List>
          <S_NavBar>
            <SmartLink to={buildChannelsSettingPath(channelID)}>
              <S_Button onClick={closeMenu}>
                <Icon iconName="ic_Setting" fill />
              </S_Button>
            </SmartLink>
          </S_NavBar>
          <S_Title>{title}</S_Title>
          {pApps &&
            pApps.map((item) => (
              <Item
                key={item.id}
                onClick={closeMenu}
                icon={<Icon iconName={item.icon} colorCode="grey900" />}
                link={item.path}
              >
                {item.title}
              </Item>
            ))}
        </S_List>
        <S_Footer>
          {t(
            'str_footer_info_mobile',
            'publ\n대표자 : 배인식\n상호명 : (주)키클롭스 | 소재지 : 서울특별시 성동구 뚝섬로 317\n사업자등록번호 : 115-87-00415 | 통신판매업번호 : 2019-서울성동-01388\n전화번호 : 02-6463-0777'
          )}
        </S_Footer>
      </S_StyledMenu>
    </S_MainMenu>
  );
}

const overlayOpen = css`
  background: rgba(0, 0, 0, 0.3);
  height: 100%;
  opacity: 1;
  position: fixed;
  transition: opacity 0.3s ease 0s;
  width: 100%;
`;

const overlayClose = css`
  background: rgba(0, 0, 0, 0.3);
  height: 100%;
  opacity: 0;
  position: fixed;
  transform: translate3d(100%, 0, 0);
  transition: opacity 0.3s ease 0s, transform 0s ease 0.3s;
  width: 100%;
`;

const S_Overlay = styled.div<{ open: boolean }>`
  ${({ open }) => (open ? overlayOpen : overlayClose)};

  box-shadow: -30px 0 100px -100px #000, 30px 0 100px -100px #000;
  z-index: 1100;
  @media only screen and (min-width: 800px) {
    max-width: 420px;
  }
`;

const S_MainMenu = styled.div`
  display: block;
`;

const S_List = styled.div`
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
`;

const S_Footer = styled.div`
  border-top: 1px solid ${(props) => props.theme.grey100};
  color: ${({ theme }) => theme.grey400};
  font-size: ${({ theme }) => theme.fontSizeCaption2};
  padding: 24px;
  white-space: pre-wrap;
`;

const S_NavBar = styled.div`
  align-items: flex-end;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: transform 0.4s;
  width: 100%;
  z-index: 10;
  height: 56px;
`;

const S_Button = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  width: 48px;
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
`;

const S_Title = styled.div`
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeH2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  line-height: ${({ theme }) => theme.fontLineHeight};
  margin-bottom: 24px;
  padding: 0 24px;
`;

const menuOpen = css`
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
`;

const menuClose = css`
  transform: translateX(-100%);
  transition: all 0.3s;
  visibility: hidden;
`;

const S_StyledMenu = styled.nav<{ open: boolean }>`
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: absolute;
  text-align: left;
  top: 0;
  width: 320px;
  z-index: 1100;
  ${({ open }) => (open ? menuOpen : menuClose)};
`;

export default Sidebar;
