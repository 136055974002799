import styled from 'styled-components';
import { Icon } from '@common/ui/components';

type SelectProps = {
  name: string;
  options: string[] | number[];
  register: Function;
  validation: object;
  placeholder: string;
  isRequired: boolean;
  defaultValue?: string | number;
  disabled?: boolean;
  suffix?: string;
};

export default function Select({
  name,
  options,
  register,
  validation,
  placeholder,
  isRequired,
  defaultValue,
  disabled,
  suffix
}: SelectProps) {
  return (
    <S_Box>
      <S_IconWrapper iconName="ic_ArrowDown" colorCode="grey500" size={16} />
      <S_Select
        disabled={disabled}
        defaultValue={defaultValue}
        required={isRequired}
        {...register(name, validation)}
      >
        <option hidden value="">
          {placeholder}
        </option>
        {options.map((value) => (
          <option key={value} value={value}>
            {value}
            {suffix}
          </option>
        ))}
      </S_Select>
    </S_Box>
  );
}

const S_Box = styled.div`
  height: 48px;
  position: relative;
  z-index: 0;
`;

const S_IconWrapper = styled(Icon)`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
`;

const S_Select = styled.select`
  appearance: none;
  background-color: transparent;
  border: 1px solid transparent;
  border: solid 1px ${({ theme }) => theme.grey100};
  border-radius: 8px;
  color: ${({ theme }) => theme.grey900};
  font-family: inherit;
  font-size: ${({ theme }) => theme.fontSizeForm2};
  height: 100%;
  padding-left: 16px;
  width: 100%;

  :invalid {
    color: ${({ theme }) => theme.grey500};
  }

  :disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.grey50};
    color: ${({ theme }) => theme.grey900};
  }

  :focus {
    border: 0.5px solid ${({ theme }) => theme.blue500} !important;
    outline: 0 !important;
    outline-offset: 0 !important;
  }
`;
