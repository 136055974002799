import styled from 'styled-components';

import { PostInfo } from './index';
import { CommentCount } from '../atoms';

type Props = {
  title: string;
  author: string;
  createdAt: string;
  viewCount: number;
  commentCount: number;
};

function PostCard({ title, author, createdAt, viewCount, commentCount }: Props) {
  return (
    <>
      <S_PostCard>
        <PostInfo author={author} createdAt={createdAt} viewCount={viewCount} title={title} />
        <CommentCount count={commentCount} />
      </S_PostCard>
      <S_Line />
    </>
  );
}

const S_PostCard = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  text-align: left;
`;

const S_Line = styled.div`
  background-color: ${({ theme }) => theme.grey100};
  height: 1px;
  margin: 0 24px;
`;

export default PostCard;
