import { useEffect, useState, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
  makeGetIsProfileLoading,
  makeGetIsUpdated,
  makeGetProfile,
  makeGetS3UploadedImageUrl
} from '@common/redux/modules/profile';
import {
  uploadImageToS3,
  updateProfile,
  fetchProfile
} from '@common/redux/modules/profile/reducers';
import { Form, SubmitButton, InputFieldButton, Icon } from '@common/ui/components';
import useModalDialog from '../../../../common/hooks/useModalDialog';
import styled from 'styled-components';

import { Select } from './atoms';
import LoadingIcon from './atoms/LoadingIcon';
import { NavView } from '../Account/templates';
import { NavBar } from '../Account/molecules';
import { useHistory } from 'react-router';
import { accountPath } from '@routers/routes';
import JWT from '@common/utils/jwt';

const { REACT_APP_KEY_ACCESS_TOKEN } = process.env;
interface IFormInput {
  nickname: string;
  profileSrc: string;
  birthYear: number;
  birthMonth: number;
  birthDate: number;
  gender: string;
  language: string;
}

function ModifyAccount() {
  const { t: translate } = useTranslation('translation');
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedImageFile, setSelectedImageFile] = useState<File | undefined>();
  const [uploadedProfileUrl, setUploadedProfileUrl] = useState<string>('');
  const [imageLoading, setImageLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const getProfile = useMemo(makeGetProfile, []);
  const getS3UploadedImageUrl = useMemo(makeGetS3UploadedImageUrl, []);
  const getIsProfileLoading = useMemo(makeGetIsProfileLoading, []);
  const getIsUpdated = useMemo(makeGetIsUpdated, []);

  const uploadedImageUrl = useSelector(getS3UploadedImageUrl);
  const isProfileLoading = useSelector(getIsProfileLoading);
  const profile = useSelector(getProfile);
  const isProfileUpdated = useSelector(getIsUpdated);

  const { ModalDialog, setIsModalDialogOpen, setModalDialogMessage } = useModalDialog();

  const methods = useForm<IFormInput>({ mode: 'onChange' });
  const {
    handleSubmit,
    register,
    trigger,
    watch,
    getValues,
    setValue,
    formState: { isValid }
  } = methods;

  const watchProfileThumbnail = watch('profileSrc');

  useEffect(() => {
    const [, , jwtData] = JWT.validateJwtToken(
      localStorage.getItem(REACT_APP_KEY_ACCESS_TOKEN || '')
    );

    if (jwtData.user.mainProfile) {
      dispatch(fetchProfile(jwtData.user.mainProfile.id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (isProfileUpdated) {
      setIsModalDialogOpen(true);
      setModalDialogMessage(t('str_919', '프로필 정보 수정이 완료되었습니다.'));
    }
  }, [isProfileUpdated]);

  function getGender(gender: string) {
    switch (gender) {
      case 'MALE':
        return t('str_gender_man', '남성');
      case 'FEMALE':
        return t('str_gender_woman', '여성');
      default:
        return null;
    }
  }

  function setGender(gender: string) {
    switch (gender) {
      case t('str_gender_man', '남성'):
        return 'MALE';
      case t('str_gender_woman', '여성'):
        return 'FEMALE';
      default:
        return null;
    }
  }

  useEffect(() => {
    const { nickname, imageSrc, birthYear, gender, birthMonth, birthDate } = profile;

    nickname &&
      setValue('nickname', nickname, {
        shouldValidate: true
      });
    imageSrc &&
      setValue('profileSrc', imageSrc, {
        shouldValidate: true
      });
    gender &&
      setValue('gender', getGender(gender) || '', {
        shouldValidate: true
      });
    birthYear &&
      setValue('birthYear', Number(birthYear), {
        shouldValidate: true
      });
    birthMonth &&
      setValue('birthMonth', Number(birthMonth), {
        shouldValidate: true
      });
    birthDate &&
      setValue('birthDate', Number(birthDate), {
        shouldValidate: true
      });
    setSelectedImageFile(undefined);
  }, [setValue, profile]);

  useEffect(() => {
    if (!isSubmit) return;
    try {
      if (!imageLoading && uploadedImageUrl !== '') {
        setUploadedProfileUrl(uploadedImageUrl);
        setImageLoading(true);
      }
    } catch (err) {
      alert(err);
    }
  }, [uploadedImageUrl]);

  useEffect(() => {
    if (!isSubmit) return;
    try {
      if (imageLoading && uploadedProfileUrl !== '') {
        modifyAccount();
      }
    } catch (err) {
      alert(err);
    }

    return function cleanup() {
      setImageLoading(false);
    };
  }, [imageLoading, uploadedProfileUrl]);

  const t = (key: string, defaultValue: string | { [key: string]: any }) =>
    translate(key, defaultValue) as string;

  /**
   * 출생년도 객체 생성 함수
   */
  const generateYearItems = () => {
    const yearItems = [];
    const startYear = 1900;
    const lastYear = new Date().getFullYear();

    for (let i = lastYear; i >= startYear; i -= 1) {
      yearItems.push(i);
    }

    return yearItems;
  };

  const generateMonthItems = () => {
    const monthItems = [];
    const lastMonth = 12;

    for (let i = 1; i < lastMonth + 1; i += 1) {
      monthItems.push(i);
    }

    return monthItems;
  };

  const generateDateItems = () => {
    const monthItems = [];
    const lastMonth = 31;

    for (let i = 1; i < lastMonth + 1; i += 1) {
      monthItems.push(i);
    }

    return monthItems;
  };

  /**
   * 이미지 file 타입으로 선택
   */
  const handleImageUpload = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    const fileInput = document.createElement('input');

    fileInput.type = 'file';
    fileInput.accept = 'image/png, image/jpeg, image/jpg';
    fileInput.addEventListener('change', inputListener);
    fileInput.click();

    function inputListener(event: Event) {
      const file: File = (event.target as any).files[0];
      const url = URL.createObjectURL(file);

      const availableTypes = ['image/png', 'image/jpeg', 'image/jpg'];
      const isWrongType = !availableTypes.some((type) => type === file.type);

      if (isWrongType) {
        setIsModalDialogOpen(true);
        setModalDialogMessage(
          translate('str_err_1175', {
            errCode: 'error',
            defaultValue: 'JPG, JPEG, PNG 파일을 업로드 할 수 있습니다. ({{errCode}})'
          })
        );

        return;
      }

      setValue('profileSrc', url, {
        shouldValidate: true
      });

      setSelectedImageFile(file);
      trigger('profileSrc');
    }
  };

  /**
   * 이미지가 있을 경우와 없을 경우 프로필 정보 수정
   */
  const handleProfileInfo = () => {
    if (selectedImageFile) {
      setIsSubmit(true);
      const params = { name: uuidv4(), body: selectedImageFile };
      dispatch(uploadImageToS3(params));
    } else {
      modifyAccount();
    }
  };

  /**
   * 프로필 정보 수정
   */
  const modifyAccount = () => {
    const { nickname, gender, birthYear, birthMonth, birthDate } = getValues();

    const editProfile = selectedImageFile
      ? {
          nickname,
          gender: setGender(gender),
          birthYear: Number(birthYear) || null,
          birthMonth: Number(birthMonth) || null,
          birthDay: Number(birthDate) || null,
          imageSrc: uploadedProfileUrl
        }
      : {
          nickname,
          gender: setGender(gender),
          birthYear: Number(birthYear) || null,
          birthMonth: Number(birthMonth) || null,
          birthDay: Number(birthDate) || null
        };

    if (profile && profile.id) {
      dispatch(
        updateProfile({
          id: profile.id,
          body: editProfile
        })
      );
    }
  };

  const nicknameValidation = {
    required: t('str_101', '필수 정보를 입력해 주세요.'),
    minLength: {
      value: 2,
      message: t('str_too_short', '너무 짧습니다.')
    },
    maxLength: {
      value: 12,
      message: t('str_934', '최소 2자, 최대 12자까지 입력 가능합니다.')
    },
    pattern: {
      value: /^[ㄱ-ㅎㅏ-ㅣ가-힣A-Za-z0-9]+$/,
      message: t('str_err_1016', '올바르지 않은 닉네임입니다.')
    }
  };

  return (
    <NavView
      nav={
        <NavBar
          title={t('str_update_profile_info', '프로필 정보 수정')}
          isBack
          handleBack={() => history.goBack()}
          showLogo={false}
        />
      }
    >
      <S_Form>
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(handleProfileInfo)}>
            <S_ImageWrapper>
              {profile && watchProfileThumbnail && (
                <Image src={watchProfileThumbnail} alt="Publishing Kit" />
              )}
              {profile && !watchProfileThumbnail && (
                <Icon iconName="ic_PostSocial" size={64} colorCode="blue500" fill />
              )}
              <S_CameraIcon>
                <Icon
                  iconName="ic_Camera"
                  size={16}
                  colorCode="grey500"
                  fill
                  onClick={handleImageUpload}
                />
              </S_CameraIcon>
            </S_ImageWrapper>
            <S_InputWrapper>
              <InputFieldButton
                name="nickname"
                placeholder={t('str_nickname_hint', '닉네임')}
                maxLength={12}
                validation={nicknameValidation}
              />
              <Select
                name="gender"
                isRequired={false}
                register={register}
                placeholder={t('str_gender', '성별')}
                options={[t('str_gender_man', '남성'), t('str_gender_woman', '여성')]}
                validation={{ required: false }}
              />
              <Select
                name="birthYear"
                isRequired={false}
                register={register}
                disabled={!!profile?.hasCertifiedIdentity}
                placeholder={t('str_birth_year', '출생 연도')}
                options={generateYearItems()}
                validation={{ required: false }}
                suffix={t('str_fm_birth_year', { year: '', defaultValue: '{{year}}년' })}
              />
              <Select
                name="birthMonth"
                isRequired={false}
                register={register}
                disabled={!!profile?.hasCertifiedIdentity}
                placeholder={t('str_birth_month', '출생월')}
                options={generateMonthItems()}
                validation={{ required: false }}
                suffix={t('str_fm_birth_month', { month: '', defaultValue: '{{month}}월' })}
              />
              <Select
                name="birthDate"
                isRequired={false}
                register={register}
                disabled={!!profile?.hasCertifiedIdentity}
                placeholder={t('str_birth_date', '출생일')}
                options={generateDateItems()}
                validation={{ required: false }}
                suffix={t('str_fm_birth_date', { date: '', defaultValue: '{{date}}일' })}
              />
            </S_InputWrapper>
            <S_SubmitButton>
              {isProfileLoading ? (
                <LoadingIcon />
              ) : (
                <SubmitButton full disabled={!isValid} label={t('str_39', '수정')} />
              )}
            </S_SubmitButton>
          </Form>
        </FormProvider>
      </S_Form>
      <ModalDialog onCloseModal={() => history.push(accountPath)} />
    </NavView>
  );
}

const S_Form = styled.div`
  height: 100%;
  padding-left: 24px;
  padding-right: 24px;
  text-align: left;
  user-select: none;
`;

const S_SubmitButton = styled.div`
  margin-top: 24px;
`;

const S_InputWrapper = styled.div`
  & > div {
    margin-top: 16px;
  }
`;

const Image = styled.img`
  border-radius: 9999px;
  overflow: hidden;
  position: relative;
  width: 96px;
`;

const S_ImageWrapper = styled.div`
  background-color: ${({ theme }) => theme.grey50};
  border-radius: 50%;
  display: flex;
  height: 96px;
  margin: auto;
  margin-bottom: 24px;
  margin-top: 24px;
  position: relative;
  width: 96px;

  & > svg {
    margin: auto;
  }
`;

const S_CameraIcon = styled.div`
  background-color: ${({ theme }) => theme.white};
  border: solid 1px ${({ theme }) => theme.grey100};
  border-radius: 50%;
  bottom: 0;
  display: flex;
  height: 32px;
  position: absolute;
  right: -10px;
  width: 32px;

  &:hover {
    background-color: ${({ theme }) => theme.grey50};
    cursor: pointer;
  }
  & > svg {
    margin: auto;
  }
`;

export default ModifyAccount;
