import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SubscriptionProductState } from '@common/redux/modules/channel';
import { Button } from '@common/ui/components';
import { ModalDialog } from '@common/ui/components/ModalDialog';
import styled from 'styled-components';

import { PurchaseTitle, PurchaseType } from '../atoms';

type SubscriptionProductModalProps = {
  isOpen: boolean;
  purchaseItems: SubscriptionProductState[];
  selectedItem?: SubscriptionProductState;
  onClose?: Function;
  onSelectItem?: Function;
  getSubscriptionProductType: Function;
};

function SubscriptionProductModal({
  purchaseItems,
  selectedItem,
  isOpen,
  onClose,
  onSelectItem,
  getSubscriptionProductType
}: SubscriptionProductModalProps) {
  const { t } = useTranslation('translation');

  const selectedIndex =
    selectedItem &&
    purchaseItems &&
    purchaseItems.findIndex((item) => item.alias === selectedItem.alias);
  const [purchaseType, setPurchaseType] = useState(selectedIndex && selectedIndex.toString());

  function handleCancel() {
    if (onClose) {
      setPurchaseType(selectedIndex && selectedIndex.toString());
      onClose();
    }
  }

  function handleSubmit(e: React.ChangeEvent<HTMLInputElement>) {
    e.stopPropagation();
    e.preventDefault();

    if (onSelectItem) {
      onSelectItem(purchaseType);
    }

    if (onClose) {
      onClose();
    }
  }

  function handleSelectItem(e: React.ChangeEvent<HTMLInputElement>) {
    setPurchaseType(e.target.value);
  }

  return (
    <ModalDialog
      title={t('str_subs_payment_option', '결제 종류 선택')}
      disableBackdropClick
      isOpen={isOpen}
      onClose={handleCancel}
      footer={<S_SelectButton onClick={handleSubmit} label={t('str_ok', '확인')} />}
    >
      {purchaseItems &&
        Object.keys(purchaseItems).map((key, index) => (
          <S_SelectLabel htmlFor={`purchaseType_${key}`} key={key}>
            <S_SelectRadio
              type="radio"
              id={`purchaseType_${key}`}
              name="purchaseType"
              checked={purchaseType === key}
              onChange={handleSelectItem}
              value={key}
            />
            <PurchaseType>{getSubscriptionProductType(purchaseItems[index].type)}</PurchaseType>
            <PurchaseTitle>{purchaseItems[index].alias}</PurchaseTitle>
          </S_SelectLabel>
        ))}
    </ModalDialog>
  );
}

const S_SelectLabel = styled.label`
  align-items: center;
  display: flex;
  height: 48px;
  margin: 0;
`;

const S_SelectRadio = styled.input`
  margin: 0;
  margin-right: 8px;
  vertical-align: middle;
`;

const S_SelectButton = styled(Button)`
  border-radius: 24px;
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.fontSizeBody1};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  padding: 12px 32px 12px 32px;
`;

export default SubscriptionProductModal;
