import IconType from '../IconType';

const Video = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M21.7719,15.7248 C21.7709,15.8608 21.6829,15.9238 21.6299,15.9488 C21.5779,15.9738 21.4739,16.0038 21.3669,15.9188 L18.0729,13.3138 C18.0709,13.3118 18.0679,13.3118 18.0649,13.3098 L18.0649,10.8358 C18.0789,10.8278 18.0939,10.8258 18.1059,10.8158 L21.4219,8.2628 C21.5289,8.1798 21.6329,8.2108 21.6849,8.2368 C21.7369,8.2628 21.8249,8.3268 21.8239084,8.4628 L21.7719,15.7248 Z M16.5649,16.4928 C16.5649,17.3198 15.8929,17.9928 15.0649,17.9928 L4.4259,17.9928 C3.5989,17.9928 2.9259,17.3198 2.9259,16.4928 L2.9259,7.5068 C2.9259,6.6798 3.5989,6.0068 4.4259,6.0068 L15.0649,6.0068 C15.8929,6.0068 16.5649,6.6798 16.5649,7.5068 L16.5649,16.4928 Z M22.3519,6.8928 C21.7489,6.5938 21.0419,6.6628 20.5059,7.0748 L18.0649,8.9538 L18.0649,7.5068 C18.0649,5.8498 16.7219,4.5068 15.0649,4.5068 L4.4259,4.5068 C2.7699,4.5068 1.4259,5.8498 1.4259,7.5068 L1.4259,16.4928 C1.4259,18.1498 2.7699,19.4928 4.4259,19.4928 L15.0649,19.4928 C16.7219,19.4928 18.0649,18.1498 18.0649,16.4928 L18.0649,15.2208 L20.4359,17.0958 C20.7529,17.3468 21.1339,17.4748 21.5189,17.4748 C21.7759,17.4748 22.0359,17.4178 22.2789,17.3008 C22.8869,17.0098 23.2669,16.4098 23.2719,15.7358 L23.3239496,8.4738 C23.3289,7.7988 22.9559,7.1928 22.3519,6.8928 L22.3519,6.8928 Z"
      />
    </svg>
  );
};

export default Video;
