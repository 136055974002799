import IconType from '../IconType';

const PostFree = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M16.304,10.3372 L15.084,11.5572 L12.443,8.9162 L13.663,7.6962 C13.993,7.3662 14.527,7.3662 14.857,7.6962 L16.304,9.1422 C16.634,9.4722 16.634,10.0072 16.304,10.3372 L16.304,10.3372 Z M10.374,16.2672 C10.33,16.3112 10.273,16.3372 10.211,16.3422 L7.728,16.5502 C7.569,16.5642 7.436,16.4312 7.449,16.2722 L7.657,13.7882 C7.662,13.7272 7.689,13.6692 7.732,13.6262 L11.865,9.4932 L14.507,12.1342 L10.374,16.2672 Z M17.5,2.5002 L6.5,2.5002 C4.567,2.5002 3,4.0672 3,6.0002 L3,18.0002 C3,19.9332 4.567,21.5002 6.5,21.5002 L17.5,21.5002 C19.433,21.5002 21,19.9332 21,18.0002 L21,6.0002 C21,4.0672 19.433,2.5002 17.5,2.5002 L17.5,2.5002 Z"
      />
    </svg>
  );
};

export default PostFree;
