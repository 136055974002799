import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import LOGO_IMG from '@assets/img/logo.png';
import { makeGetIsMenuOpened } from '@common/redux/modules/channel';
import { makeGetIsSessionLoading, makeGetIsAuthenticated } from '@common/redux/modules/session';
import '@common/services/i18n';
import { ModalProvider } from '@common/ui/components/Modal';

import './App.css';
import AppRouter from './AppRouter';

function App() {
  const getIsSessionLoading = useMemo(makeGetIsSessionLoading, []);
  const isLoading = useSelector(getIsSessionLoading);
  const getIsAuthenticated = useMemo(makeGetIsAuthenticated, []);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const getIsMenuOpened = useMemo(makeGetIsMenuOpened, []);
  const isMenuOpened = useSelector(getIsMenuOpened);

  return (
    <ModalProvider>
      <div className="App">
        <div
          className="App-desktop-cover"
          onDragStart={(e) => {
            e.preventDefault();
          }}
        >
          <img className="logo" src={LOGO_IMG} alt="Logo" />
        </div>
        <div className="App-mobile-content">
          <div className="App-body" style={{ overflow: isMenuOpened ? 'hidden' : 'auto' }}>
            <AppRouter isSessionLoading={isLoading} isAuthenticated={isAuthenticated} />
          </div>
        </div>
      </div>
    </ModalProvider>
  );
}

export default App;
