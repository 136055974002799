import IconType from '../IconType';

const CheckboxOn = ({ color, size, ...rest }: IconType) => (
  <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={color}
        d="M18.461,0 L5.538,0 C2.479,0 0,2.48 0,5.539 L0,18.462 C0,21.521 2.479,24 5.538,24 L18.461,24 C21.52,24 24,21.521 24,18.462 L24,5.539 C24,2.48 21.52,0 18.461,0"
      />
      <path
        fill="#FFF"
        d="M19.624,7.698 L11.173,18.772 C11.009,18.988 10.756,19.117 10.485,19.125 C10.476,19.126 10.467,19.126 10.458,19.126 C10.197,19.126 9.948,19.012 9.776,18.814 L4.409,12.588 C4.084,12.212 4.126,11.643 4.503,11.319 C4.881,10.994 5.448,11.037 5.772,11.412 L10.416,16.798 L18.193,6.606 C18.494,6.211 19.06,6.135 19.455,6.437 C19.85,6.738 19.926,7.303 19.624,7.698"
      />
    </g>
  </svg>
);

export default CheckboxOn;
