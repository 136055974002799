import { put, takeEvery, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { ChannelRepository } from '../../../repositories';
import { fetchChannel, fetchChannelByCode, fetchChannelSellerInfo } from './reducers';
import { digestResponse } from '../../middlewares/response';

function* watchFetchChannel(action: ReturnType<typeof fetchChannel>) {
  const { type } = action;

  const response: AxiosResponse = yield call(() => ChannelRepository.channels());
  yield put(digestResponse(response, type));
}

function* watchFetchChannelByCode(action: ReturnType<typeof fetchChannelByCode>) {
  const { type, payload } = action;

  const response: AxiosResponse = yield call(() =>
    ChannelRepository.channelsByCode(payload.channelId)
  );
  yield put(digestResponse(response, type));
}

function* watchFetchChannelSellerInfo(action: ReturnType<typeof fetchChannelSellerInfo>) {
  const { type, payload } = action;
  const response: AxiosResponse = yield call(() =>
    ChannelRepository.channelSellerInfo(payload.invitation_code)
  );
  yield put(digestResponse(response, type));
}

function* watch() {
  yield takeEvery(fetchChannel, watchFetchChannel);
  yield takeEvery(fetchChannelByCode, watchFetchChannelByCode);
  yield takeEvery(fetchChannelSellerInfo, watchFetchChannelSellerInfo);
}

export default watch;
