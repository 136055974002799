import IconType from '../IconType';

const VideoPlaybackSpeed = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <g fill="none" fillRule="evenodd">
        <path
          stroke={color}
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M12.8868,21.2095 C10.2328,21.4635 7.4898,20.5745 5.4578,18.5425 C1.8448,14.9285 1.8448,9.0715 5.4578,5.4575 C9.0708,1.8445 14.9288,1.8445 18.5418,5.4575 C20.3488,7.2645 21.2518,9.6325 21.2518,11.9995"
        />
        <path
          stroke={color}
          strokeDasharray="1.937 2.906"
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M21.2519,12 C21.2519,6.89 17.1099,2.748 11.9999,2.748 C6.8899,2.748 2.7479,6.89 2.7479,12 C2.7479,17.11 6.8899,21.252 11.9999,21.252 C17.1099,21.252 21.2519,17.11 21.2519,12 Z"
        />
        <path
          fill={color}
          d="M9.027,12.2314 L9.027,9.1044 C9.027,8.1764 10.031,7.5964 10.834,8.0604 L13.543,9.6244 L16.251,11.1874 C17.054,11.6514 17.054,12.8114 16.251,13.2754 L13.543,14.8384 L10.834,16.4024 C10.031,16.8664 9.027,16.2864 9.027,15.3584 L9.027,12.2314 Z"
        />
      </g>
    </svg>
  );
};

export default VideoPlaybackSpeed;
