import ic_ArrowDown from './ArrowDown';
import ic_ArrowLeft from './ArrowLeft';
import ic_ArrowLeft2 from './ArrowLeft2';
import ic_ArrowRight from './ArrowRight';
import ic_ArrowRight2 from './ArrowRight2';
import ic_ArrowUp from './ArrowUp';
import ic_Booking from './Booking';
import ic_BookingCompleted from './BookingCompleted';
import ic_BookingHistory from './BookingHistory';
import ic_BookingTicket from './BookingTicket';
import ic_BookingTime from './BookingTime';
import ic_Chat from './Chat';
import ic_ChatCounseling from './ChatCounseling';
import ic_Check from './Check';
import ic_CheckboxOff from './CheckboxOff';
import ic_Filter from './Filter';
import ic_Links from './Links';
import ic_Menu from './Menu';
import ic_Mute from './Mute';
import ic_OpenPage from './OpenPage';
import ic_PageSizeExpand from './PageSizeExpand';
import ic_PageSizeReduce from './PageSizeReduce';
import ic_Photo from './Photo';
import ic_Plus from './Plus';
import ic_PostFree from './PostFree';
import ic_PostNotice from './PostNotice';
import ic_PostSocial from './PostSocial';
import ic_RadioOff from './RadioOff';
import ic_RadioOn from './RadioOn';
import ic_Search from './Search';
import ic_Setting from './Setting';
import ic_Speaker from './Speaker';
import ic_Unavailable from './Unavailable';
import ic_Video from './Video';
import ic_VideoPip from './VideoPip';
import ic_VideoPlaybackSpeed from './VideoPlaybackSpeed';
import ic_Xmark from './Xmark';
import ic_Payment from './Payment';
import ic_Report from './Report';
import ic_PaymentGift from './PaymentGift';

const lineIcons = {
  ic_ArrowDown,
  ic_ArrowLeft,
  ic_ArrowLeft2,
  ic_ArrowRight,
  ic_ArrowRight2,
  ic_ArrowUp,
  ic_Booking,
  ic_BookingCompleted,
  ic_BookingHistory,
  ic_BookingTicket,
  ic_BookingTime,
  ic_Chat,
  ic_ChatCounseling,
  ic_Check,
  ic_CheckboxOff,
  ic_Filter,
  ic_Links,
  ic_Menu,
  ic_Mute,
  ic_OpenPage,
  ic_PageSizeExpand,
  ic_PageSizeReduce,
  ic_Photo,
  ic_Plus,
  ic_PostFree,
  ic_PostNotice,
  ic_PostSocial,
  ic_PaymentGift,
  ic_Payment,
  ic_RadioOff,
  ic_RadioOn,
  ic_Report,
  ic_Search,
  ic_Setting,
  ic_Speaker,
  ic_Unavailable,
  ic_Video,
  ic_VideoPip,
  ic_VideoPlaybackSpeed,
  ic_Xmark
} as const;

export default lineIcons;
