import styled from 'styled-components';

type Props = {
  message: string;
};

function NoItem({ message }: Props) {
  return <S_NoItem>{message}</S_NoItem>;
}

const S_NoItem = styled.div`
  color: ${({ theme }) => theme.grey400};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  margin-top: 96px;
`;

export default NoItem;
