import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Form, Icon } from '@common/ui/components';
import { fetchSeriesesSearchInput } from '@common/redux/modules/VODPlusPApp/reducers';
import { VODPLUS_SERIES_DEFAULT_LIMIT } from '@common/constants/hardcoded';

import { Text, SearchInputField } from '../../../components';

type Props = {
  channelId: number;
  isSearchSelected: boolean;
  searchHandler: () => void;
  setSearchInputValue: (input: string) => void;
};

function SearchBox({ channelId, isSearchSelected, searchHandler, setSearchInputValue }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const methods = useForm<{ SearchInput: string }>({ mode: 'onChange' });

  const { handleSubmit, setValue } = methods;

  const goToSearch = (data: { SearchInput: string }) => {
    setSearchInputValue(data.SearchInput);
    dispatch(
      fetchSeriesesSearchInput({
        limit: VODPLUS_SERIES_DEFAULT_LIMIT,
        search: data.SearchInput,
        channelId
      })
    );
  };

  return (
    <>
      {isSearchSelected ? (
        <S_SearchContentBox>
          <S_InputBox>
            <FormProvider {...methods}>
              <Form onSubmit={handleSubmit(goToSearch)}>
                <SearchInputField
                  name="SearchInput"
                  placeholder={t('str_933', '작품 제목을 입력해 주세요.')}
                />
              </Form>
            </FormProvider>
          </S_InputBox>
          <S_TextWrapper
            onClick={() => {
              searchHandler();
              setValue('SearchInput', '');
            }}
          >
            <Text fontSizes="fontSizeBody2">{t('str_cancel', '취소')}</Text>
          </S_TextWrapper>
        </S_SearchContentBox>
      ) : (
        <S_SearchBox
          onClick={() => {
            searchHandler();
          }}
        >
          <Icon iconName="ic_Search" size={24} colorCode="grey900" />
        </S_SearchBox>
      )}
    </>
  );
}

const S_SearchBox = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 56px;
  justify-content: center;
  width: 56px;
`;

const S_SearchContentBox = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const S_InputBox = styled.div`
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: space-between;
  width: 100%;
`;

const S_TextWrapper = styled.div`
  margin-left: 12px;
  width: 32px;
`;

export default SearchBox;
