import { createReducer, createAction } from '@reduxjs/toolkit';
import { builderFactory } from '../../../utils/reduxHelper';

import {
  InitialState,
  ChannelState,
  ChannelPayload,
  ChannelSellerInfoPayload,
  SellerInfoState
} from './types';

const prefix = '@channel';

export const setChannelDescParams = createAction<{ channelID: string; keyword: string }>(
  `${prefix}/CHANNEL_DESC_PARAMS`
);
export const fetchChannel = createAction(`${prefix}/FETCH_CHANNEL`);
export const fetchChannelByCode = createAction<ChannelPayload>(`${prefix}/FETCH_CHANNEL_BY_CODE`);
export const fetchChannelSellerInfo = createAction<ChannelSellerInfoPayload>(
  `${prefix}/FETCH_CHANNEL_SELLER_INFO`
);
export const openSideMenu = createAction(`${prefix}/SIDE_MENU_OPEN`);
export const closeSideMenu = createAction(`${prefix}/SIDE_MENU_CLOSE`);
export const resetChannelError = createAction(`${prefix}/RESET_CHANNEL_ERROR`);

const initialState: InitialState = {
  isLoading: true,
  isMenuOpened: false,
  isAvailableChannels: false,
  currentChannel: undefined,
  myChannelList: undefined,
  currentChannelSellerInfo: undefined,
  channelDescParams: {
    invitationCode: '',
    conversionLinkKeyword: ''
  },
  error: null
};

const reducer = createReducer(initialState, (builder) => {
  builderFactory(builder, [fetchChannel, fetchChannelByCode, fetchChannelSellerInfo])
    .addCase(`${fetchChannel}_COMPLETED`, (state, action: any) => {
      const { channels }: { channels: ChannelState[] } = action.payload.data.data;
      state.myChannelList = channels;
    })
    .addCase(`${fetchChannelByCode}_COMPLETED`, (state, action: any) => {
      const { channel }: { channel: ChannelState } = action.payload.data.data;
      state.currentChannel = channel;
    })
    .addCase(`${fetchChannelSellerInfo}_COMPLETED`, (state, action: any) => {
      const { seller }: { seller: SellerInfoState } = action.payload.data.data;
      state.currentChannelSellerInfo = seller;
    })
    .addCase(setChannelDescParams, (state, action: any) => {
      const { channelID, keyword }: { channelID: string; keyword: string } = action.payload;

      state.channelDescParams.invitationCode = channelID;
      state.channelDescParams.conversionLinkKeyword = keyword;
    })
    .addCase(openSideMenu, (state) => {
      state.isMenuOpened = true;
    })
    .addCase(closeSideMenu, (state) => {
      state.isMenuOpened = false;
    })
    .addCase(resetChannelError, (state) => {
      state.error = null;
    })
    .addMatcher(
      (action) => action.type.startsWith(prefix) && action.type.endsWith('_COMPLETED'),
      (state) => {
        state.isLoading = false;
      }
    )
    .addMatcher(
      (action) => action.type.startsWith(prefix) && action.type.endsWith('_FAILED'),
      (state, action) => {
        state.isLoading = false;
        state.error = action.payload.data.error;
      }
    );
});

export default reducer;
