/* eslint-disable */
import { OrderSheet } from './models/index.js';
import { QueryString } from './utils/index.js';

class ProfitableClient {
  constructor(options, mockServer) {
    this._mockServer = mockServer;

    const { merchantHost, publPayHost, apiKey, merchantTokenKey } = options;

    this._merchantHost = merchantHost;
    this._publPayHost = publPayHost;
    this._apiKey = apiKey;
    this._merchantTokenKey = merchantTokenKey;

    this._accessToken = null;
  }

  async ping(target = 'MERCHANT') {
    if (target === 'PUBLPAY' && !this._accessToken) {
      return {
        error: {
          code: '888',
          status: 'INITIALIZATION_ERROR',
          msg: 'Please call createSession/1 to make a request to PUBLPAY target.'
        }
      };
    }

    const url = target === 'PUBLPAY' ? '/api/v1/ping' : '/profitable/ping';

    const result = await this._request('GET', url, {}, null, target);
    console.log(result);

    return;
  }

  async authorize(rootNamespace, authorizedNamespaces) {
    const url = `/profitable/authorize`;
    const body = {
      rootNamespace,
      authorizedNamespaces
    };

    const { error, data } = await this._request('POST', url, {}, body, 'MERCHANT');
    if (error) {
      throw error;
    }

    const { accessToken } = data;
    this._accessToken = accessToken;

    return;
  }

  async requestSystemInvoice(productModel, productId, conductNote) {
    const url = `/profitable/system-invoices`;
    const body = {
      productModel,
      productId,
      conductNote
    };

    const { error, data } = await this._request('POST', url, {}, body, 'MERCHANT');
    if (error) {
      throw error;
    }

    const { systemInvoice } = data;

    return systemInvoice;
  }

  async fetchSystemInvoice(siid) {
    const url = `/profitable/system-invoices/${siid}`;

    const { error, data } = await this._request('GET', url, {}, null, 'MERCHANT');
    if (error) {
      throw error;
    }

    const { systemInvoice } = data;

    return systemInvoice;
  }

  async requestOrder(systemInvoice, isPaid = false) {
    const orderSheet = OrderSheet.fromSystemInvoice(systemInvoice);

    const url = `/api/v1/checkout`;
    const body = {
      orderSheet: orderSheet.toJSON()
    };
    // isPaid

    const { error, data } = await this._request('POST', url, {}, body, 'PUBLPAY');
    if (error) {
      // debugger;
      throw error;
    }

    const { order } = data;

    return order;
  }

  createPopup(order) {
    return new Promise((resolve, reject) => {
      const popupUrl = `${this._publPayHost}/api/v1/checkout/${order.orderId}`;

      const popup = window.open(popupUrl);
      const timer = setInterval(async () => {
        if (popup.closed) {
          clearInterval(timer);

          const url = `/profitable/system-receipts/check`;
          const body = { siid: order.siid || order.systemInvoiceId };

          const { error, data } = await this._request('POST', url, {}, body, 'MERCHANT');
          if (error) {
            reject(error);
          }

          console.log(`data`, data);

          if (data) {
            const { systemReceipt } = data;

            resolve(systemReceipt);
          }
        }
      }, 1000);
    });
  }

  _request(method, path, params, body, target = 'MERCHANT') {
    if (target === 'PUBLPAY' && !this._accessToken) {
      return {
        error: {
          code: '888',
          status: 'INITIALIZATION_ERROR',
          msg: 'Please call createSession/1 to make a request to PUBLPAY target.'
        }
      };
    }

    const headers = {
      ...(body ? { 'Content-Type': 'application/json' } : {}),
      Authorization:
        target === 'PUBLPAY'
          ? `Bearer ${this._accessToken ? this._accessToken : ''}`
          : `Bearer ${
              localStorage.getItem(this._merchantTokenKey)
                ? localStorage.getItem(this._merchantTokenKey)
                : ''
            }`
    };

    const queryParams = {
      ...params,
      apiKey: this._apiKey
    };

    const host = target === 'PUBLPAY' ? this._publPayHost : this._merchantHost;
    const url = host + path + QueryString.fromQueryParams(queryParams);

    const options = {
      method,
      url,
      headers,
      ...(body ? { body: JSON.stringify(body) } : {})
    };

    if (this._mockServer) {
      return this._mockRequest(method, url, options);
    }

    return this._normalizedFetch(url, options);
  }

  async _mockRequest(method, url, options) {
    const body = options.body;
    const token = options.headers['Authorization'].split('Bearer ')[1];

    const response = await this._mockServer.handleRequest(method, url, body, {
      token
    });

    return response.body;
  }

  async _normalizedFetch(url, options) {
    try {
      const response = await fetch(url, options);

      return await response.json();
    } catch (err) {
      console.error(`Error: ${err}`);

      const errorResult = {
        error: {
          code: '999',
          status: 'UNKNOWN_ERROR',
          msg: 'fetch api failed to shoot request or get response.'
        }
      };

      return errorResult;
    }
  }
}

export default ProfitableClient;
