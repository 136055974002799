import api, { getEndpointSet } from '../services/api';

const { ME } = getEndpointSet('AUTH', 'user');

class UserRepository {
  static me() {
    return api.get(ME, {}, {});
  }
}

export default UserRepository;
