import styled, { css } from 'styled-components';
import DateHelper from '@common/utils/dateHelper';

type CalendarDateGeneratorProps = {
  bookingCondition: boolean;
  current: ReturnType<typeof DateHelper.getInstance>;
  selectDate: ReturnType<typeof DateHelper.getInstance>;
  handleSelectAnotherDay: (currentFormat: ReturnType<typeof DateHelper.getInstance>) => void;
};

type CalendarDateGeneratorStyledProps = {
  DateSelected: boolean;
  bookingCondition: boolean;
};

function CalendarDateGenerator({
  bookingCondition,
  current,
  selectDate,
  handleSelectAnotherDay
}: CalendarDateGeneratorProps) {
  return (
    <S_CalendarDay
      DateSelected={current.format('MD') === selectDate.format('MD')}
      bookingCondition={bookingCondition}
      onClick={() => handleSelectAnotherDay(current)}
    >
      {current.format('D')}
    </S_CalendarDay>
  );
}

const S_CalendarDay = styled.button<CalendarDateGeneratorStyledProps>`
  border: none;
  border-radius: 16px;
  font-size: ${({ theme }) => theme.fontSizeBody1};
  font-stretch: normal;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  height: 100%;
  letter-spacing: normal;
  line-height: ${({ theme }) => theme.fontLineHeight};
  outline: none;
  padding: 0;
  position: relative;
  text-align: center;
  width: 100%;
  ${({ DateSelected }) =>
    DateSelected
      ? css`
          background: ${({ theme }) => theme.grey50};
        `
      : css`
          background: ${({ theme }) => theme.white};
        `};
  ${({ bookingCondition }) =>
    !bookingCondition &&
    css`
      color: ${({ theme }) => theme.textDisable};
    `};
`;

export default CalendarDateGenerator;
