import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { BookmarksType } from '@common/redux/modules/VODPlusPApp';

import { buildVodPlusPath } from '@routers/routes';
import { Episode } from '../molecules';

type Props = {
  bookmarkList: BookmarksType[];
  margin?: string;
  zeroTop?: boolean;
  invitationCode: string;
};

function BookmarkList({ bookmarkList, margin = '12px 16px', zeroTop, invitationCode }: Props) {
  const history = useHistory();
  const vodPlusPath = buildVodPlusPath(invitationCode);

  const goToSeriesDetail = (id: number) => {
    history.push(`${vodPlusPath}/serieses/${id}`, { pathName: 'wishlist' });
  };

  return (
    <S_SeriesList margin={margin}>
      {bookmarkList.map((item, i) => (
        <S_SeriesEntry
          key={i.toString()}
          zeroTop={zeroTop}
          onClick={() => {
            goToSeriesDetail(item.series.id);
          }}
        >
          <Episode src={item.series.coverImageSrc} title={item.series.title} width="auto" />
        </S_SeriesEntry>
      ))}
    </S_SeriesList>
  );
}

const S_SeriesList = styled.div<{ margin: string }>`
  margin: ${({ margin }) => margin};
`;

const S_SeriesEntry = styled.div<{ zeroTop?: boolean }>`
  float: left;
  margin: 12px 8px 12px 8px;
  width: calc(50% - 16px);
  &:nth-child(n + 1):nth-child(-n + 2) {
    ${({ zeroTop }) => zeroTop && 'margin: 0 8px 12px 8px;'}
  }
`;

export default BookmarkList;
