import IconType from '../IconType';

const PostSocial = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M12.0001,12.0918 C16.3471,12.0918 19.9731,15.1768 20.8081,19.2778 C20.9971,20.2078 20.2811,21.0808 19.3311,21.0808 L19.3311,21.0808 L4.6691,21.0808 C3.7191,21.0808 3.0031,20.2078 3.1921,19.2778 C4.0271,15.1768 7.6531,12.0918 12.0001,12.0918 Z M12.0001,13.5918 C8.4541,13.5918 5.3681,16.1088 4.6621,19.5768 L4.6621,19.5768 L19.3311,19.5808 C18.6321,16.1088 15.5461,13.5918 12.0001,13.5918 Z M11.9739,2.9196 C14.2479,2.9196 16.0909,4.7626 16.0909,7.0366 C16.0909,9.3106 14.2479,11.1536 11.9739,11.1536 C9.6999,11.1536 7.8569,9.3106 7.8569,7.0366 C7.8569,4.7626 9.6999,2.9196 11.9739,2.9196 Z M11.9739,4.4196 C10.5309,4.4196 9.3569,5.5936 9.3569,7.0366 C9.3569,8.4796 10.5309,9.6536 11.9739,9.6536 C13.4169,9.6536 14.5909,8.4796 14.5909,7.0366 C14.5909,5.5936 13.4169,4.4196 11.9739,4.4196 Z"
      />
    </svg>
  );
};

export default PostSocial;
