import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormProvider, useForm } from 'react-hook-form';

import { Button, Form, InputFieldButton, ModalDialog, SubmitButton } from '@common/ui/components';
import checkAccoountAsync from '@common/utils/checkAccoountAsync';

type ResetPwViewProps = {
  isSended: boolean;
};

interface IResetPassword {
  email: string;
}

/**
 * 비밀번호 재설정 뷰
 *
 * @param {Object} props
 * @param {boolean} props.isSended 재설정 메일 발송 여부
 */
function ResetPwView({ isSended }: ResetPwViewProps) {
  const history = useHistory();
  const { t } = useTranslation('translation');

  const [isConfirm, setIsConfirm] = useState(isSended);
  const [isModalDialogOpen, setIsModalDialogOpen] = useState(false);
  const [modalDialogMessage, setModalDialogMessage] = useState('');

  const methods = useForm<IResetPassword>({ mode: 'onChange' });
  const {
    getValues,
    handleSubmit,
    formState: { isValid }
  } = methods;

  async function handleResetPw() {
    const { email } = getValues();
    const { isError, isValidAccount } = await checkAccoountAsync(email);

    if (isError) {
      setIsModalDialogOpen(true);
      setModalDialogMessage(
        t('str_err_1239', 'Error : 알 수 없는 에러가 발생하였습니다. 관리자에게 문의하세요 (500)')
      );

      return;
    }

    if (!isValidAccount) {
      setIsModalDialogOpen(true);
      setModalDialogMessage(
        t('str_err_1026', '존재하지 않은 이메일 계정입니다. \n 다시 한번 확인해주세요.')
      );

      return;
    }

    setModalDialogMessage(
      t('str_1119', {
        email,
        defaultValue:
          '입력하신 이메일({{email}})로 인증 메일을 발송하였습니다. \n 메일에 포함된 안내사항에 따라 비밀번호를 재설정 해주세요.'
      })
    );

    setIsModalDialogOpen(true);
    setIsConfirm(true);
  }

  function handleConfrim() {
    history.push('/');
  }

  const emailValidation = {
    required: true,
    pattern: {
      // eslint-disable-next-line no-useless-escape
      value: /^([a-zA-Z0-9\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
      message: t('str_invalid_email', '올바르지 않은 이메일입니다.')
    }
  };

  return (
    <ResetPwViewContainer>
      {isModalDialogOpen && (
        <ModalDialog
          isOpen
          footer={
            <Button
              onClick={isConfirm ? handleConfrim : () => setIsModalDialogOpen(false)}
              label={t('str_confirm', '확인')}
            />
          }
        >
          <Description>
            {modalDialogMessage.split('\n').map((line, index) => (
              <div key={index}>{line}</div>
            ))}
          </Description>
        </ModalDialog>
      )}
      <Title>{t('str_242', '비밀번호를 재설정합니다.')}</Title>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(handleResetPw)}>
          <InputFieldButton
            name="email"
            placeholder={t('str_243', '가입 시 등록한 이메일을 입력해 주세요.')}
            validation={emailValidation}
          />
          <ButtonWrapper>
            <SubmitButton full label={t('str_246', '이메일 발송')} disabled={!isValid} />
          </ButtonWrapper>
        </Form>
      </FormProvider>
    </ResetPwViewContainer>
  );
}

ResetPwView.defaultProps = {
  isSended: false
};

ResetPwView.propTypes = {
  isSended: PropTypes.bool
};

const ResetPwViewContainer = styled.div`
  background-color: white;
  height: 100%;
  padding-left: 24px;
  padding-right: 24px;
  text-align: left;
  user-select: none;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.black};
  font-family: 'Roboto', sans-serif;
  font-size: ${({ theme }) => theme.fontSizeH2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  line-height: ${({ theme }) => theme.fontLineHeight};
  margin-bottom: 24px;
  margin-top: 24px;
  text-align: left;
`;

const ButtonWrapper = styled.div`
  margin-top: 24px;
`;

const Description = styled.span`
  color: ${({ theme }) => theme.grey500};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  line-height: ${({ theme }) => theme.fontLineHeight};
  text-align: left;
  word-break: keep-all;
`;

export default ResetPwView;
