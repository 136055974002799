/* eslint-disable */
class OrderSheet {
  constructor(orderSheet) {
    const {
      id,
      pgcode,
      serviceName,
      userId,
      userName,
      amount,
      taxfreeAmount,
      taxAmount,
      productName,
      customParameter,
      isSubscription,
      isAllowedPgMail,
      email,
      sellerInfo1,
      sellerInfo2,
      systemInvoice
    } = orderSheet;

    this.id = id;
    this.pgcode = pgcode;
    this.serviceName = serviceName;
    this.userId = userId;
    this.userName = userName;
    this.amount = amount;
    this.taxfreeAmount = taxfreeAmount;
    this.taxAmount = taxAmount;
    this.productName = productName;
    this.customParameter = customParameter;
    this.isSubscription = isSubscription;
    this.isAllowedPgMail = isAllowedPgMail;
    this.email = email;
    this.sellerInfo1 = sellerInfo1;
    this.sellerInfo2 = sellerInfo2;
    this.systemInvoice = systemInvoice;
  }

  setId(id) {
    this.id = id;
  }

  static fromSystemInvoice(systemInvoice) {
    const pgcode = 'creditcard';
    const serviceName = 'publ';
    const userId = systemInvoice.recipient.accountId;
    const userName = systemInvoice.recipient.accountName;
    const amount = +systemInvoice.price.amount;
    const productName = systemInvoice.name;
    const customParameter = '';
    const isSubscription = systemInvoice.recurring;

    let sellerInfo1 = '';
    let sellerInfo2 = '';

    if (systemInvoice.issuer.type === 'BUSINESS') {
      const {
        state,
        city,
        address1,
        address2,
        postalCode,
        companyName,
        businessRepresentativeName,
        businessRegistrationNumber,
        mailOrderBusinessRegistrationNumber,
        operatorPhoneCountryCode,
        operatorPhoneNumber
      } = systemInvoice.issuer;

      const fullAddress = `${state} ${city} ${address1}${
        address2 ? ' ' + address2 : ''
      }(${postalCode})`;
      const fullPhoneNumber = `${
        operatorPhoneCountryCode === 746 ? '+82 ' : ''
      }${operatorPhoneNumber}`;

      sellerInfo1 = `상호명 : ${companyName} | 소재지 : ${fullAddress} | 대표 : ${businessRepresentativeName} | 전화번호 : ${fullPhoneNumber}`;
      sellerInfo2 = `사업자등록번호 : ${businessRegistrationNumber} | 통신판매업번호 : ${mailOrderBusinessRegistrationNumber}`;
    }

    return new OrderSheet({
      pgcode,
      serviceName,
      userId,
      userName,
      amount,
      productName,
      customParameter,
      isSubscription,
      sellerInfo1,
      sellerInfo2,
      systemInvoice
    });
  }

  toJSON() {
    return {
      id: this.id,
      pgcode: this.pgcode,
      serviceName: this.serviceName,
      userId: this.userId,
      userName: this.userName,
      amount: this.amount,
      productName: this.productName,
      customParameter: this.customParameter,
      isSubscription: this.isSubscription,
      sellerInfo1: this.sellerInfo1,
      sellerInfo2: this.sellerInfo2,
      systemInvoice: this.systemInvoice
    };
  }
}

export default OrderSheet;
