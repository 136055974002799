import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  askCancelFromUser,
  confirmCancelFromSeller,
  fetchCancelListFromSeller
} from '@common/redux/modules/booking/reducers';
import { BookingDescType } from '@common/redux/modules/booking/types';
import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import { Button, Icon } from '@common/ui/components';
import styled from 'styled-components';

type ButtonModalProps = {
  ModalButtonLabel: string;
  ModalButtonText: string;
  handleModalChange: () => void;
  booking: BookingDescType;
};

function ButtonModal({
  booking,
  ModalButtonLabel,
  ModalButtonText,
  handleModalChange
}: ButtonModalProps) {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  useEffect(() => {
    currentChannel && dispatch(fetchCancelListFromSeller({ channelId: currentChannel.id }));
  }, [currentChannel, dispatch]);

  const handleAskCancelButton = () => {
    if (ModalButtonLabel === t('str_papp_cbooking_evt_refusal', '진행 거부')) {
      currentChannel &&
        dispatch(
          askCancelFromUser({
            requestType: { bid: booking.id, status: 5 },
            channelId: currentChannel.id
          })
        );
    }
    if (ModalButtonLabel === t('str_papp_cbooking_rsv_cancellation', '예약 취소')) {
      currentChannel &&
        dispatch(
          askCancelFromUser({
            requestType: { bid: booking.id, status: 1 },
            channelId: currentChannel.id
          })
        );
    }
    if (ModalButtonLabel === t('str_papp_cbooking_request_accept', '요청 수락')) {
      const { cid } = booking.history[0];
      currentChannel &&
        dispatch(
          confirmCancelFromSeller({
            requestType: { cid: cid || '', confirm: 1 },
            channelId: currentChannel.id
          })
        );
    }
    if (ModalButtonLabel === t('str_papp_cbooking_request_refuse', '요청 거절')) {
      const { cid } = booking.history[0];
      currentChannel &&
        dispatch(
          confirmCancelFromSeller({
            requestType: { cid: cid || '', confirm: 0 },
            channelId: currentChannel.id
          })
        );
    }

    handleModalChange();
  };

  return (
    <S_ButtonModalContainer>
      <S_ModalContainer>
        <S_TitleBar>
          <S_Title>{ModalButtonLabel}</S_Title>
          <Icon iconName="ic_Xmark" onClick={() => handleModalChange()} />
        </S_TitleBar>
        <S_Divider />
        <S_TextArea>
          <S_Text>{ModalButtonText}</S_Text>
        </S_TextArea>
        <Button label={ModalButtonLabel} onClick={handleAskCancelButton} />
        <S_Span />
      </S_ModalContainer>
    </S_ButtonModalContainer>
  );
}

const S_ButtonModalContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100vh;
  position: absolute;
  width: 100vw;
  z-index: 11;
`;

const S_ModalContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  margin: auto;
  width: 327px;
`;

const S_TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 12px 24px;
`;

const S_Title = styled.div`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody1};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  line-height: ${({ theme }) => theme.fontLineHeight};
`;

const S_Divider = styled.div`
  background: ${({ theme }) => theme.grey100};
  height: 1px;
  margin: 0;
  width: 100%;
`;

const S_TextArea = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 24px 0;
`;

const S_Text = styled.div`
  color: ${({ theme }) => theme.grey500};
  font-size: ${({ theme }) => theme.fontSizeBody1};
  font-weight: ${({ theme }) => theme.fontWeightNormal};
`;

const S_Span = styled.div`
  height: 24px;
`;

export default ButtonModal;
