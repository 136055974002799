import api, { getEndpointSet } from '../services/api';

const { CHANNELS, CHANNELS_BY_CODE, CHANNELS_SELLER_INFO } = getEndpointSet('CHANNEL', 'channels');

class ChannelRepository {
  static channels(params) {
    return api.get(CHANNELS, {}, params);
  }

  static channelsByCode(channelID) {
    return api.get(CHANNELS_BY_CODE, { channelID }, {});
  }

  static channelSellerInfo(invitationCode) {
    return api.get(CHANNELS_SELLER_INFO, { invitationCode }, {});
  }
}

export default ChannelRepository;
