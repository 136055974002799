import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import { BookmarksType } from '@common/redux/modules/VODPlusPApp';
import { getBookmarks } from '@common/redux/modules/VODPlusPApp/selectors';
import { cleanUpAllData, fetchSeriesByBookmark } from '@common/redux/modules/VODPlusPApp/reducers';

import { NavBar, SubTab, StickyTop, BookmarksList, Text } from '../../components';

function VodPlusWishList() {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const [channelId, setChannelId] = useState<number>(0);
  const [invitationCode, setInvitationCode] = useState<string>('');
  const [lastPlaylistId, setLastPlaylistId] = useState(0);

  const bookmarks = useSelector(getBookmarks);

  const bookmarksList: BookmarksType[] = [];
  bookmarks &&
    bookmarks.map((item) => {
      if (item.series.isPublished === true) bookmarksList.push(item);
      return;
    });

  const handleScrollDown = () => {
    setLastPlaylistId(bookmarks[bookmarks.length - 1].id);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!currentChannel) return;

    setInvitationCode(currentChannel.primitiveInvitationCode);
    setChannelId(currentChannel.id);
  }, [currentChannel]);

  useEffect(() => {
    if (!channelId) return;

    dispatch(fetchSeriesByBookmark({ channelId }));
  }, [dispatch, channelId]);

  useEffect(() => {
    dispatch(cleanUpAllData());
  }, [dispatch]);

  return (
    <>
      <StickyTop>
        <NavBar isMenu title={t('str_papp_vodp_name', '시청하기')} />
        <SubTab invitationCode={invitationCode} />
      </StickyTop>
      {bookmarksList.length > 0 ? (
        <S_ScrollField
          dataLength={bookmarksList.length}
          next={handleScrollDown}
          hasMore={bookmarksList[bookmarksList.length - 1].id !== lastPlaylistId}
        >
          <BookmarksList bookmarkList={bookmarksList} invitationCode={invitationCode} />
        </S_ScrollField>
      ) : (
        <S_NoItem>
          <Text elipsis={false} color="grey400" fontSizes="fontSizeBody2" newline>
            {t(
              'str_951',
              '아직 찜한 작품이 없습니다.\n보고 싶은 작품이나 즐겨 보는 작품을 추가해 보세요.'
            )}
          </Text>
        </S_NoItem>
      )}
    </>
  );
}

const S_ScrollField = styled(InfiniteScroll as any)`
  margin: none;
`;

const S_NoItem = styled.div`
  margin: 96px 24px;
`;

export default VodPlusWishList;
