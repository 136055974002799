import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { batchDispatchMiddleware } from 'redux-batched-actions';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

import createResponseMiddleware from './middlewares/response';
import createErrorResponseMiddleware, { digestErrorResponse } from './middlewares/errorResponse';
import createErrorMiddleware, { digestError } from './middlewares/error';

function createReduxStore(customHistory: any) {
  /* eslint-disable no-underscore-dangle */
  const reduxDevTools =
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
  /* eslint-enable */

  const sagaMiddleware = createSagaMiddleware({
    context: {
      history: customHistory
    }
  });
  const responseMiddleware = createResponseMiddleware({ digestErrorResponse });
  // @ts-ignore
  const errorResponseMiddleware = createErrorResponseMiddleware({ digestError });
  // @ts-ignore
  const errorMiddleware = createErrorMiddleware({});

  const middlewares = [
    sagaMiddleware,
    responseMiddleware,
    errorResponseMiddleware,
    errorMiddleware,
    batchDispatchMiddleware
  ];

  const appliedMiddlewares = reduxDevTools
    ? compose(applyMiddleware(...middlewares), reduxDevTools)
    : applyMiddleware(...middlewares);

  const store = createStore(rootReducer, appliedMiddlewares);
  sagaMiddleware.run(rootSaga);

  return store;
}

export type RootState = ReturnType<ReturnType<typeof createReduxStore>['getState']>;

export default createReduxStore;
