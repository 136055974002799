import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { StyledNamespace } from '@common/types/styledNamespace';
import { SmartLink, Icon } from '@common/ui/components';
import styled from 'styled-components';

const S: StyledNamespace = {};

type NavBarProps = {
  title: string;
  isBack: boolean;
  backUrl: string;
  linkUrl: string;
};
/**
 * 네비게이션 바
 *
 * @param {Object} props
 * @param {string} props.title 제목
 * @param {boolean} props.isBack 뒤로 버튼 여부
 * @param {string} props.linkUrl 페이지 링크 URL
 */
function NavBar({ title, isBack, backUrl, linkUrl }: NavBarProps) {
  const history = useHistory();

  /**
   * 뒤로가기 클릭 이벤트 핸들러
   */
  function handleBack() {
    if (backUrl) {
      history.push(backUrl);
    } else {
      history.goBack();
    }
  }

  return (
    <S.NavBar>
      {isBack ? (
        <S.Button onClick={handleBack}>
          <Icon iconName="ic_ArrowLeft" />
        </S.Button>
      ) : (
        <S.Empty />
      )}

      {title && <S.Title>{title}</S.Title>}
      {linkUrl ? (
        <SmartLink to={linkUrl}>
          <S.Button>
            <Icon iconName="ic_Setting" fill />
          </S.Button>
        </SmartLink>
      ) : (
        <S.Empty />
      )}
    </S.NavBar>
  );
}

NavBar.defaultProps = {
  isBack: false,
  title: '',
  backUrl: '',
  linkUrl: ''
};

NavBar.propTypes = {
  isBack: PropTypes.bool,
  title: PropTypes.string,
  backUrl: PropTypes.string,
  linkUrl: PropTypes.string
};

S.NavBar = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  height: 56px;
  justify-content: space-between;
  left: 0;
  padding-left: 8px;
  padding-right: 8px;
  position: sticky;
  right: 0;
  text-align: center;
  top: 0;
  transition: transform 0.4s;
  z-index: 10;
`;

S.Button = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  width: 48px;
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
`;

S.Title = styled.div`
  color: ${({ theme }) => theme.black};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  line-height: ${({ theme }) => theme.fontLineHeight};
  text-align: center;
`;

S.Empty = styled.div`
  height: 48px;
  width: 48px;
`;

export default NavBar;
