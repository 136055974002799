import React, {
  MouseEvent,
  useState,
  useEffect,
  useRef,
  useMemo,
  useContext,
  createContext
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { createPortal } from 'react-dom';

import { StyledNamespace } from '@common/types/styledNamespace';

const ModalContext = createContext({
  modalRootRef: { current: null },
  openModalIds: []
});
let modalCount = 0;

const S: StyledNamespace = {};

type ModalProps = {
  className: string;
  isOpen: boolean;
  disableBackdropClick?: boolean;
  onClose?: Function;
  children?: React.ReactNode;
};

/**
 * 모달 컴포넌트
 *
 * @param {Object} props
 * @param {string} className
 * @param {boolean} props.isOpen 모달 열림 여부
 * @param {boolean} props.disableBackdropClick 모달 바깥 클릭 종료 여부
 * @param {function} props.onClose 모달 외부 배경이 클릭되었을 때 호출될 콜백
 */
function Modal({
  className,
  isOpen = true,
  disableBackdropClick = false,
  onClose,
  children
}: ModalProps) {
  // 유일한 모달 ID 지정
  const modalId = useMemo(() => {
    modalCount += 1;
    return modalCount;
  }, []);
  const [isLoaded, setIsLoaded] = useState(false);
  /* @ts-ignore */
  const { modalRootRef, setOpenModalIds } = useContext(ModalContext);

  useEffect(() => {
    if (modalRootRef && modalRootRef.current) {
      setIsLoaded(true);
    }
  }, [modalRootRef]);

  // 모달이 열리고 닫힐때 모달 ID 배열을 변경하여 모달 컨텍스트에 상태를 전달한다.
  useEffect(() => {
    if (isOpen) {
      setOpenModalIds((ids: number[]) => (ids.indexOf(modalId) < 0 ? [...ids, modalId] : ids));
    } else {
      setOpenModalIds((ids: number[]) => {
        const idx = ids.indexOf(modalId);

        if (idx >= 0) {
          return [...ids.slice(0, idx), ...ids.slice(idx + 1, ids.length)];
        }

        return ids;
      });
    }
  }, [modalId, isOpen, setOpenModalIds]);

  function handleClickOutside(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation();

    if (!disableBackdropClick) {
      if (onClose) {
        onClose();
      }
    }
  }

  function handleClickModal(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
  }

  if (!isLoaded || !isOpen) {
    return <></>;
  }

  return createPortal(
    <S.Modal id={`modal_${modalId}`}>
      <S.Background />
      <S.Wrapper onMouseDown={handleClickOutside}>
        <div className={className} onMouseDown={handleClickModal}>
          {children}
        </div>
      </S.Wrapper>
    </S.Modal>,
    /* @ts-ignore */
    modalRootRef.current
  );
}

S.Modal = styled.div`
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 40;
`;

S.Background = styled.div`
  background-color: ${({ theme }) => theme.black};
  height: 100%;
  opacity: 0.7;
  @media only screen and (min-width: 800px) {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0 0 0 50%;
    max-width: 420px;
  }
`;

S.Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  @media only screen and (min-width: 800px) {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0 0 0 50%;
    max-width: 420px;
  }
`;

/**
 * 모달 루트 프로바이더
 * 모달을 사용하기 위해서는 이 프로바이더가 상위 컴포넌트로 있어야 함.
 */
function ModalProvider({ children }: { children?: React.ReactNode }) {
  const ref = useRef();
  const [openModalIds, setOpenModalIds] = useState([]);
  const data = {
    modalRootRef: ref,
    openModalIds,
    setOpenModalIds
  };

  return (
    <>
      <ModalContext.Provider
        /* @ts-ignore */
        value={data}
      >
        {children}
      </ModalContext.Provider>

      <div
        /* @ts-ignore */
        ref={ref}
        className="modal-root"
      />
    </>
  );
}

/**
 * 모달이 열린 총 개수를 반환하는 Hook
 */
function useModalOpenCount() {
  const { openModalIds } = useContext(ModalContext);
  return openModalIds.length;
}

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default Modal;

export { ModalProvider, useModalOpenCount };
