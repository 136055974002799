import { RootState } from '../../index';

export const getIsLoading = (state: RootState) => {
  return state.socialCommunityPApp.isLoading;
};

export const getError = (state: RootState) => {
  return state.socialCommunityPApp.error;
};

export const getPosts = (state: RootState) => {
  return state.socialCommunityPApp.posts;
};

export const getPost = (state: RootState) => {
  return state.socialCommunityPApp.post;
};

export const getTotalPostsCount = (state: RootState) => {
  return state.socialCommunityPApp.totalPostsCount;
};

export const getComments = (state: RootState) => {
  return state.socialCommunityPApp.comments;
};

export const getTotalCommentsCount = (state: RootState) => {
  return state.socialCommunityPApp.totalCommentsCount;
};

export const getResponseImageUrls = (state: RootState) => {
  return state.socialCommunityPApp.responseImageUrls;
};

export const getResponseVideoUrl = (state: RootState) => {
  return state.socialCommunityPApp.responseVideoUrl;
};

export const getIsCommentCreated = (state: RootState) => {
  return state.socialCommunityPApp.isCommentCreated;
};

export const getIsCommentDeleted = (state: RootState) => {
  return state.socialCommunityPApp.isCommentDeleted;
};

export const getIsFetched = (state: RootState) => {
  return state.socialCommunityPApp.isFetched;
};
