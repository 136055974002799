/* eslint-disable @typescript-eslint/no-unused-vars */
import { Icon } from '@common/ui/components';
import formatCurrency from '@common/utils/formatCurrency';

import styled from 'styled-components';

type Props = {
  isLiked: boolean;
  isDisliked: boolean;
  likeCount: number;
  dislikeCount: number;
  hasComment?: boolean;
  commentCount?: number | null;
  id: number | string;
  onClickLikeButton: (id: number | string) => void;
  onClickDislikeButton: (id: number | string) => void;
};

function PostMenuBar({
  isLiked,
  isDisliked,
  likeCount,
  dislikeCount,
  hasComment,
  commentCount,
  id,
  onClickLikeButton,
  onClickDislikeButton
}: Props) {
  return (
    <S_PostMenuBar>
      <S_LikeActionBox onClick={() => onClickLikeButton(id)}>
        <S_Icon iconName="ic_ThumbUp" colorCode={isLiked ? 'blue500' : 'grey500'} size={16} fill />
        <S_Count isClicked={isLiked}>{formatCurrency(likeCount)}</S_Count>
      </S_LikeActionBox>

      {/** TODO: dislike 기능 제외 */}
      {/* <S_LikeActionBox onClick={() => onClickDislikeButton(id)}>
        <S_Icon
          iconName="ic_ThumbDown"
          colorCode={isDisliked ? 'blue500' : 'grey500'}
          size={16}
          fill
        />
        <S_Count isClicked={isDisliked}>{formatCurrency(dislikeCount)}</S_Count>
      </S_LikeActionBox> */}

      {hasComment && (
        <>
          <S_Icon iconName="ic_Reply" colorCode="grey500" size={16} fill />
          <S_Count>{formatCurrency(commentCount || 0)}</S_Count>
        </>
      )}
    </S_PostMenuBar>
  );
}

const S_PostMenuBar = styled.div`
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: flex-start;
`;

const S_Icon = styled(Icon)`
  cursor: pointer;
  margin-right: 4px;
`;

const S_Count = styled.span<{
  isClicked?: boolean;
}>`
  color: ${({ isClicked, theme }) => (isClicked ? theme.blue500 : theme.grey500)};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  line-height: ${({ theme }) => theme.fontLineHeight};
  margin-right: 20px;
`;

const S_LikeActionBox = styled.div`
  align-items: center;
  display: flex;
`;

export default PostMenuBar;
