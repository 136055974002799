import { createReducer, createAction } from '@reduxjs/toolkit';
import { builderFactory } from '../../../utils/reduxHelper';

import { InitialState, FetchSubscriptionProductPayload } from './types';

const prefix = '@subscriptionProduct';

export const fetchSubscriptionProduct = createAction<FetchSubscriptionProductPayload>(
  `${prefix}/FETCH_ONE`
);

const initialState: InitialState = {
  isLoading: false,
  subscriptionProduct: {},
  error: null
};

const reducer = createReducer(initialState, (builder) => {
  builderFactory(builder, [fetchSubscriptionProduct])
    .addCase(`${fetchSubscriptionProduct}_COMPLETED`, (state, action: any) => {
      const { subscription_products } = action.payload.data.data;
      state.subscriptionProduct = subscription_products;
    })
    .addMatcher(
      (action) => action.type.startsWith(prefix) && action.type.endsWith('_COMPLETED'),
      (state) => {
        state.isLoading = false;
      }
    )
    .addMatcher(
      (action) => action.type.startsWith(prefix) && action.type.endsWith('_FAILED'),
      (state, action) => {
        state.isLoading = false;
        state.error = action.payload.data.message;
      }
    );
});

export default reducer;
