import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { makeGetCurrentChannel } from '@common/redux/modules/channel';

import { Splash } from '../../../Splash';

import { ChannelAuth } from './templates';

/**
 * 인증 화면
 */
function ChannelAuthContainer() {
  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  return currentChannel ? (
    <ChannelAuth
      channelInfo={{
        title: currentChannel.title,
        imgSrc: currentChannel.iconImageSrc
      }}
    />
  ) : (
    <Splash />
  );
}
export default ChannelAuthContainer;
