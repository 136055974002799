/* eslint-disable */
import QueryString from './QueryString.js';

class URI {
  static parse(uri) {
    const [protocol, url] = uri.split('://');
    const [host, ...rest] = url.split('/');
    const pathWithQueryString = `/${rest.join('/')}`;
    const [path, queryStringPart] = pathWithQueryString.split('?');

    const queryString = queryStringPart ? `?${queryStringPart}` : '';

    return {
      original: uri,
      protocol,
      host,
      path,
      queryString,
      queryParams: QueryString.toQueryParams(queryString)
    };
  }
}

export default URI;
