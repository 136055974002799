/* eslint-disable */
import { TimestampUUID } from '../utils/index.js';

class SystemInvoice {
  constructor(systemInvoice) {
    const {
      id,
      siid,
      status,
      name,
      lineItems,
      price,
      recurring,
      issuer,
      recipient,
      note,
      issuedAt
    } = systemInvoice;

    this.id = id;
    this.siid = siid;
    this.status = status;
    this.name = name;
    this.lineItems = lineItems;
    this.price = price;
    this.recurring = recurring;
    this.issuer = issuer;
    this.recipient = recipient;
    this.note = note || '';
    this.issuedAt = issuedAt;
  }

  setId(id) {
    this.id = id;
  }

  static fromComponents(virtualProductRow, issuer, recipient) {
    const now = new Date();
    const siid = TimestampUUID.new('SI', now);
    const status = 'ISSUED';
    const name = virtualProductRow.productName;
    const lineItems = [virtualProductRow];
    const price = {
      currency: virtualProductRow.productPrice.currency,
      amount: lineItems.reduce((acc, lineItem) => {
        return acc + lineItem.productPrice.amount;
      }, 0)
    };
    const recurring = virtualProductRow.recurring;

    return new SystemInvoice({
      siid,
      status,
      name,
      lineItems,
      price,
      recurring,
      issuer,
      recipient,
      issuedAt: now.toISOString()
    });
  }

  toJSON() {
    return {
      id: this.id,
      siid: this.siid,
      status: this.status,
      name: this.name,
      lineItems: this.lineItems,
      price: this.price,
      recurring: this.recurring,
      issuer: this.issuer,
      recipient: this.recipient,
      note: this.note,
      issuedAt: this.issuedAt
    };
  }
}

export default SystemInvoice;
