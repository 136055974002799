import { TFunctionResult } from 'i18next';
import { ReactNode } from 'react';

import fillIcons from '@assets/icons/fill';
import lineIcons from '@assets/icons/line';
import styled, { css } from 'styled-components';

export type FillIconNameKeys = keyof typeof fillIcons;
export type LineIconNameKeys = keyof typeof lineIcons;

type TextStyleType =
  | 'displayBold'
  | 'headingBold'
  | 'leadParaRegular'
  | 'subTitleBold'
  | 'subTitleRegular'
  | 'body1Bold'
  | 'body1Regular'
  | 'body2Bold'
  | 'body2Regular'
  | 'caption1Bold'
  | 'caption1Regular'
  | 'caption2Bold'
  | 'caption2Regular';

type ColorThemeType =
  | 'sysTextPrimary'
  | 'sysTextSecondary'
  | 'sysTextTertiary'
  | 'sysTextWhite'
  | 'sysTextError'
  | 'sysTextBrandPrimary'
  | 'sysTextBrandSeconVariant'
  | 'usrTextBrandPrimary'
  | 'usrTextBrandSeconVariant'
  | 'usrTextBrandOnPrimary';

type Props = {
  text: string | number | TFunctionResult | ReactNode;
  // TODO: 필요시 사용
  // iconMode?: 'none' | 'left' | 'right';
  // iconName?: FillIconNameKeys | LineIconNameKeys | string;
  textAlign?: 'left' | 'center' | 'right';
  textStyle?: TextStyleType;
  colorTheme?: ColorThemeType;
  underline?: 'none' | 'use';
};

function TextLabel({
  text,
  // TODO: 필요시 사용
  // iconMode = 'none',
  // iconName,
  textAlign = 'left',
  textStyle = 'body2Regular',
  colorTheme = 'sysTextPrimary',
  underline = 'none'
}: Props) {
  return (
    <S_TextLabel
      textType={textStyle}
      colorTheme={colorTheme}
      underline={underline}
      textAlign={textAlign}
    >
      {text}
    </S_TextLabel>
  );
}

const displayBold = css`
  font-weight: ${({ theme }) => theme.fontWeightBold};
`;

const headingBold = css`
  font-weight: ${({ theme }) => theme.fontWeightBold};
`;

const leadParaRegular = css``;

const subTitleBold = css`
  font-size: ${({ theme }) => theme.fontSizeSubtitle};
  font-weight: ${({ theme }) => theme.fontWeightBold};
`;

const subTitleRegular = css`
  font-size: ${({ theme }) => theme.fontSizeSubtitle};
`;

const body1Bold = css`
  font-size: ${({ theme }) => theme.fontSizeBody1};
  font-weight: ${({ theme }) => theme.fontWeightBold};
`;

const body1Regular = css`
  font-size: ${({ theme }) => theme.fontSizeBody1};
`;

const body2Bold = css`
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
`;

const body2Regular = css`
  font-size: ${({ theme }) => theme.fontSizeBody2};
`;

const caption1Bold = css`
  font-size: ${({ theme }) => theme.fontSizeCaption1};
  font-weight: ${({ theme }) => theme.fontWeightBold};
`;

const caption1Regular = css`
  font-size: ${({ theme }) => theme.fontSizeCaption1};
`;

const caption2Bold = css`
  font-size: ${({ theme }) => theme.fontSizeCaption2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
`;

const caption2Regular = css`
  font-size: ${({ theme }) => theme.fontSizeCaption2};
`;

const sysTextPrimary = css`
  color: ${({ theme }) => theme.grey400};
`;
const sysTextSecondary = css`
  color: ${({ theme }) => theme.grey500};
`;
const sysTextTertiary = css`
  color: ${({ theme }) => theme.grey400};
`;
const sysTextWhite = css`
  color: ${({ theme }) => theme.white};
`;
const sysTextError = css`
  color: ${({ theme }) => theme.red500};
`;
const sysTextBrandPrimary = css`
  color: ${({ theme }) => theme.blue500};
`;
const sysTextBrandSeconVariant = css`
  color: ${({ theme }) => theme.green700};
`;
const usrTextBrandPrimary = css`
  color: ${({ theme }) => theme.blue500};
`;
const usrTextBrandSeconVariant = css`
  color: ${({ theme }) => theme.green500};
`;
const usrTextBrandOnPrimary = css`
  color: ${({ theme }) => theme.green700};
`;

const S_TextLabel = styled.span<{
  textType: TextStyleType;
  colorTheme: ColorThemeType;
  textAlign: 'left' | 'center' | 'right';
  underline: 'none' | 'use';
}>`
  border-bottom: ${({ underline, theme }) => underline === 'use' && `1px solid ${theme.grey100}`};
  text-align: ${({ textAlign }) => textAlign};
  white-space: pre-wrap;
  word-break: break-word;

  ${({ textType }) =>
    textType &&
    {
      displayBold,
      headingBold,
      leadParaRegular,
      subTitleBold,
      subTitleRegular,
      body1Bold,
      body1Regular,
      body2Bold,
      body2Regular,
      caption1Regular,
      caption1Bold,
      caption2Regular,
      caption2Bold
    }[textType]};

  ${({ colorTheme }) =>
    colorTheme &&
    {
      sysTextPrimary,
      sysTextSecondary,
      sysTextTertiary,
      sysTextWhite,
      sysTextError,
      sysTextBrandPrimary,
      sysTextBrandSeconVariant,
      usrTextBrandPrimary,
      usrTextBrandSeconVariant,
      usrTextBrandOnPrimary
    }[colorTheme]}
`;

export default TextLabel;
