import { RootState } from '../..';

export const getSampleTicket = (state: RootState) => {
  return state.booking.ticket;
};

export const getMyBookings = (state: RootState) => {
  return state.booking.myBookings;
};

export const getMyBookingDesc = (state: RootState) => {
  return state.booking.myBookingDesc;
};

export const getMyBookingHistory = (state: RootState) => {
  return state.booking.myBookingHistory;
};

export const getCancelListFromSeller = (state: RootState) => {
  return state.booking.history;
};

export const getSelectDate = (state: RootState) => {
  return state.booking.selectDate;
};

export const getIsBookingLoading = (state: RootState) => {
  return state.booking.isBookingLoading;
};

export const getBookingAvailableDates = (state: RootState) => {
  return state.booking.bookingAvailableDates;
};

export const getEventsAvailable = (state: RootState) => {
  return state.booking.eventsAvailable;
};

export const getEid = (state: RootState) => {
  return state.booking.eid;
};

export const getSelectTime = (state: RootState) => {
  return state.booking.selectTime;
};

export const getCheckBookingAvailable = (state: RootState) => {
  return state.booking.responseStatus;
};
