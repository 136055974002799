import { useContext, useEffect, useRef, MutableRefObject } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  fetchPreviousDms,
  resendDm,
  readDm,
  sendingNewDm,
  setCurrentCounterpartProfileId,
  unsetCurrentCounterpartProfileId
} from '@common/redux/modules/chat/reducers';
import {
  getCounterpartDateWithDms,
  getAllDmsLength,
  getHasOldestMessage,
  getCounterpart,
  getChatError
} from '@common/redux/modules/chat/selectors';
import { ScrollContext, ScrollProvider } from '@common/ui/components';

import { NavView } from '../../components/templates';
import { NavBar, InputBar } from './molecules';
import { ChatView } from './templates';

type RoomProps = {
  chatViewRef: MutableRefObject<undefined>;
};

function ChattingRoom({ chatViewRef }: RoomProps) {
  const { scrollToBottom } = useContext(ScrollContext);
  const profileId = Number(useParams<{ profileId: string }>().profileId);
  const dispatch = useDispatch();

  const {
    latestMessageTxid,
    oldestMessageTxid,
    imageSrc,
    nickname,
    hasInitializedDms,
    isOnline
  } = useSelector(getCounterpart(profileId));
  const hasOldestMessage = useSelector(getHasOldestMessage(profileId));
  const dateWithDms = useSelector(getCounterpartDateWithDms(profileId));
  const allDmsLength = useSelector(getAllDmsLength(profileId));
  const error = useSelector(getChatError);

  const handleFetchPreviousMessages = () => {
    dispatch(fetchPreviousDms({ profileId, txid: oldestMessageTxid }));
  };

  const handleSubmit = ({ message }: { message: string }) => {
    if (message.trim().length) {
      dispatch(sendingNewDm({ profileId, message }));
    }
  };

  const handleResendDm = ({ message, messageId }: { message: string; messageId: number }) => {
    dispatch(resendDm({ message, messageId, profileId }));
  };

  useEffect(() => {
    if (oldestMessageTxid && !hasOldestMessage && !hasInitializedDms) {
      dispatch(fetchPreviousDms({ profileId, txid: oldestMessageTxid, initialFetch: true }));
    }
  }, [dispatch, profileId, hasOldestMessage, hasInitializedDms, oldestMessageTxid]);

  useEffect(() => {
    if (latestMessageTxid) {
      scrollToBottom();
    }
  }, [scrollToBottom, latestMessageTxid]);

  useEffect(() => {
    if (profileId && latestMessageTxid) {
      dispatch(readDm({ profileId, txid: latestMessageTxid }));
    }
  }, [profileId, latestMessageTxid, dispatch]);

  useEffect(() => {
    dispatch(setCurrentCounterpartProfileId(profileId));

    return () => {
      dispatch(unsetCurrentCounterpartProfileId());
    };
  }, [dispatch, profileId]);

  return (
    <NavView nav={<NavBar imageSrc={imageSrc} nickname={nickname} isOnline={isOnline} />}>
      {error}
      <ChatView
        ref={chatViewRef}
        dateWithDms={dateWithDms}
        allDmsLength={allDmsLength}
        onScrollUp={handleFetchPreviousMessages}
        hasOldestMessage={hasOldestMessage}
        resendDm={handleResendDm}
      />
      <InputBar onSubmit={handleSubmit} />
    </NavView>
  );
}

const ChattingRoomContainer = () => {
  const chatViewRef = useRef();

  return (
    <ScrollProvider containerRef={chatViewRef}>
      <ChattingRoom chatViewRef={chatViewRef} />
    </ScrollProvider>
  );
};

export default ChattingRoomContainer;
