import { put, fork, takeEvery, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import api from '../../../services/api';
import { SessionRepository } from '../../../repositories';

import { watchFetchCurrentUser } from '../user/sagas';

import { signIn, signOut, signUp, refresh, signOutCompleted } from './reducers';

import { digestResponse } from '../../middlewares/response';

function* autoSignIn() {
  yield fork(watchFetchCurrentUser, { callerType: '@session/AUTO_SIGNIN' });
}

function* watchSignIn(action: { type: any; payload: any }) {
  const { type, payload: userSignInParams } = action;

  const response: AxiosResponse = yield call(() => SessionRepository.signIn(userSignInParams));

  if (response.status === 200) {
    api.applyCredentials(response.data.data);
  }

  yield put(digestResponse(response, type));
}

function* watchSignOut() {
  const response: AxiosResponse = yield call(() => SessionRepository.signOut());

  if (response.status === 204) {
    api.purgeCredentials();
  }

  yield put(signOutCompleted());
}

function* watchSignUp(action: { type: any; payload: any }) {
  const { type, payload: userSignUpParams } = action;

  const response: AxiosResponse = yield call(() => SessionRepository.signUp(userSignUpParams));

  if (response.status === 200) {
    api.applyCredentials(response.data.data);
  }

  yield put(digestResponse(response, type));
  yield call(autoSignIn);
}

export function* watchRefreshAccessToken(action: { type: any }) {
  const { type } = action;
  const refreshTokenKey: string = process.env.REACT_APP_KEY_REFRESH_TOKEN || '';
  const refreshToken = localStorage.getItem(refreshTokenKey);

  const response: AxiosResponse = yield call(() => SessionRepository.refresh({ refreshToken }));
  if (response.status === 200) {
    api.setToken(response.data.data.token);
  }

  yield put(digestResponse(response, type));
  yield window.location.reload();
}

function* watch() {
  yield takeEvery('@session/AUTO_SIGNIN', autoSignIn);
  yield takeEvery(signIn, watchSignIn);
  yield takeEvery(signOut, watchSignOut);
  yield takeEvery(signUp, watchSignUp);
  yield takeEvery(refresh, watchRefreshAccessToken);
}

export default watch;
