import IconType from '../IconType';

const VideoPlayCircle = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M15.8339,12.7576 L13.2079,14.2736 L10.5809,15.7906 C9.9979,16.1276 9.2689,15.7056 9.2689,15.0326 L9.2689,11.9996 L9.2689,8.9666 C9.2689,8.2936 9.9979,7.8726 10.5809,8.2096 L13.2079,9.7256 L15.8339,11.2416 C16.4169,11.5786 16.4169,12.4206 15.8339,12.7576 M11.9999,2.0626 C6.5119,2.0626 2.0629,6.5116 2.0629,11.9996 C2.0629,17.4876 6.5119,21.9366 11.9999,21.9366 C17.4879,21.9366 21.9369,17.4876 21.9369,11.9996 C21.9369,6.5116 17.4879,2.0626 11.9999,2.0626"
      />
    </svg>
  );
};

export default VideoPlayCircle;
