import { BookingAvailableEventsType } from '@common/redux/modules/booking/types';
import styled from 'styled-components';

import { Event } from '../molcules';

type EventListProps = {
  eventsAvailable: BookingAvailableEventsType[];
  handleSelectTime: (current: { id: string; title: string }) => void;
  channelID: string;
};
function EventList({ eventsAvailable, handleSelectTime, channelID }: EventListProps) {
  return (
    <S_EventListContainer>
      {eventsAvailable.map((event) => (
        <Event
          bookingAbailable={event.booking}
          title={event.title}
          id={event.id}
          duration={event.duration}
          handleSelectTime={handleSelectTime}
          key={event.title}
          channelID={channelID}
        />
      ))}
    </S_EventListContainer>
  );
}

const S_EventListContainer = styled.ul`
  padding: 0;
`;

export default EventList;
