import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { SmartLink, Icon } from '@common/ui/components';

type NavBarProps = {
  title: string;
  isBack: boolean;
  onBack?: Function;
  linkUrl: string;
};

/**
 * 네비게이션 바
 *
 * @param {Object} props
 * @param {string} props.title 제목
 * @param {boolean} props.isBack 뒤로 버튼 여부
 * @param {string} props.linkUrl 페이지 링크 URL
 * @param {functoin} props.onBack 뒤로가기 버튼 클릭시 콜백 (false를 반환하면 뒤로가기 취소)
 */
function NavBar({ title, isBack, linkUrl, onBack }: NavBarProps) {
  const history = useHistory();

  /**
   * 뒤로가기 클릭 이벤트 핸들러
   */
  function handleBack() {
    if (onBack) {
      const r = onBack();

      if (r instanceof Promise) {
        r.then((v) => {
          if (v) {
            history.goBack();
          }
        });
      } else if (r) {
        history.goBack();
      }
    } else {
      history.goBack();
    }
  }

  return (
    <NavBarContainer>
      {isBack ? (
        <Button onClick={handleBack}>
          <Icon iconName="ic_ArrowLeft" />
        </Button>
      ) : (
        <Empty />
      )}

      {title && <Title>{title}</Title>}
      {linkUrl ? (
        <SmartLink to={linkUrl}>
          <Button>
            <Icon iconName="ic_Setting" fill />
          </Button>
        </SmartLink>
      ) : (
        <Empty />
      )}
    </NavBarContainer>
  );
}

NavBar.defaultProps = {
  isBack: false,
  title: '',
  linkUrl: '',
  onBack: null
};

NavBar.propTypes = {
  isBack: PropTypes.bool,
  onBack: PropTypes.func,
  title: PropTypes.string,
  linkUrl: PropTypes.string
};

const NavBarContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  height: 56px;
  justify-content: space-between;
  left: 0;
  position: sticky;
  right: 0;
  text-align: center;
  top: 0;
  transition: transform 0.4s;
  z-index: 10;
`;

const Button = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-left: 8px;
  width: 48px;
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
`;

const Title = styled.div`
  color: ${({ theme }) => theme.black};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  line-height: ${({ theme }) => theme.fontLineHeight};
  text-align: center;
`;

const Empty = styled.div`
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-left: 8px;
  width: 48px;
`;

export default NavBar;
