import { JWT } from '@common/utils/index';
import api, { getEndpointSet } from '../services/api';

const {
  SAMPLE_TICKET,
  FETCH_DAILY_EVENTS,
  FETCH_BOOKINGS,
  FETCH_CALENDAR,
  BOOKING,
  CHECK_BOOKING_AVAILABLE,
  MY_BOOKING_DESC,
  MY_BOOKING_DESC_EDIT,
  MY_BOOKING_HISTORY,
  GET_CANCEL_BOOKING_ID_FROM_SELLER,
  ASK_CANCEL_FROM_USER,
  CONFIRM_CANCEL_FROM_SELLER
} = getEndpointSet('BOOKING', 'booking');

function options(channelId: number) {
  return {
    headers: {
      'x-publ-consumer-id': JWT.getChannelHeader(channelId, 'E00001')
    }
  };
}
class BookingRepository {
  static getSampleTicket() {
    return api.get(SAMPLE_TICKET, {}, {});
  }

  static findMonthlyEvents(start, end, channelId) {
    return api.get(FETCH_CALENDAR, {}, { start, end }, options(channelId));
  }

  static findDailyEvents(date, channelId) {
    return api.get(FETCH_DAILY_EVENTS, {}, { date }, options(channelId));
  }

  static findMyBookings(channelId) {
    return api.get(FETCH_BOOKINGS, {}, {}, options(channelId));
  }

  static findMyBookingDesc(id, page, limit, channelId) {
    return api.get(MY_BOOKING_DESC, { id }, { page, limit }, options(channelId));
  }

  static findMyBookingDescEdit(id, description, channelId) {
    return api.put(MY_BOOKING_DESC_EDIT, { id }, { description }, options(channelId));
  }

  static findMyBookingHistory(page, limit, channelId) {
    return api.get(MY_BOOKING_HISTORY, {}, { page, limit }, options(channelId));
  }

  static booking(eid, ticket, description, channelId) {
    return api.post(BOOKING, {}, { eid, ticket, description }, options(channelId));
  }

  static bookingAvailable(id, channelId) {
    return api.get(CHECK_BOOKING_AVAILABLE, { id }, {}, options(channelId));
  }

  static getCancelListFromSeller(channelId) {
    return api.get(GET_CANCEL_BOOKING_ID_FROM_SELLER, {}, {}, options(channelId));
  }

  static askCancelFromUser(bid, status, channelId) {
    return api.post(ASK_CANCEL_FROM_USER, {}, { bid, status }, options(channelId));
  }

  static confirmCancelFromSeller(cid, confirm, channelId) {
    return api.put(CONFIRM_CANCEL_FROM_SELLER, { cid }, { confirm }, options(channelId));
  }
}

export default BookingRepository;
