import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeGetCurrentChannel, makeGetChannelDescParams } from '@common/redux/modules/channel';
import { makeGetIsAuthenticated } from '@common/redux/modules/session';
import { SignIn } from './pages';
import { accountPath } from '@routers/routes';
import { resetChannelError } from '@common/redux/modules/channel/reducers';

function SignInContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const getIsAuthenticated = useMemo(makeGetIsAuthenticated, []);
  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const currentChannel = useSelector(getCurrentChannel);
  const getChannelDescParams = useMemo(makeGetChannelDescParams, []);
  const channelDescParams = useSelector(getChannelDescParams);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(resetChannelError());
      if (currentChannel && Object.keys(currentChannel).length !== 0) {
        if (channelDescParams.conversionLinkKeyword !== '') {
          history.push(
            `/channels/${currentChannel.primitiveInvitationCode}?from=${channelDescParams.conversionLinkKeyword}`
          );
        } else {
          history.push(`/channels/${channelDescParams.invitationCode}`);
        }
      } else {
        history.push(accountPath);
      }
    }
  }, [currentChannel, isAuthenticated]);

  return <SignIn />;
}

export default SignInContainer;
