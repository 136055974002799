import { TextFieldButton } from '@common/ui/components';
import styled from 'styled-components';

type Props = {
  placeholder: string;
  name: string;
  maxLength: number;
};

function TextContentArea({ placeholder, name, maxLength }: Props) {
  return (
    <S_TextContentArea>
      <S_Field maxLength={maxLength} multiLine fieldName={name} placeholder={placeholder} />
    </S_TextContentArea>
  );
}

const S_TextContentArea = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.grey100};
  padding: 24px;
  position: relative;
`;

const S_Field = styled(TextFieldButton)`
  border-radius: 0;
  max-height: 224px;
  min-height: 64px;
  padding: 0;
  font-size: ${({ theme }) => theme.fontSizeForm1};
`;

export default TextContentArea;
