import { RootState } from '../..';

export const getNoticePosts = (state: RootState) => {
  return state.noticePApp.noticePosts;
};

export const getTotalPostsCount = (state: RootState) => {
  return state.noticePApp.totalPostsCount;
};

export const getNoticePost = (state: RootState) => {
  return state.noticePApp.noticePost;
};

export const getComments = (state: RootState) => {
  return state.noticePApp.comments;
};

export const getTotalCommentsCount = (state: RootState) => {
  return state.noticePApp.totalCommentsCount;
};

export const getIsCommentCreated = (state: RootState) => {
  return state.noticePApp.isCommentCreated;
};

export const getIsCommentDeleted = (state: RootState) => {
  return state.noticePApp.isCommentDeleted;
};
