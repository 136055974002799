import styled from 'styled-components';
import { STORY_TYPES } from '@common/constants/index';

import { Iframe, Video } from '../../../components/atoms';
import { PreviewList } from '../../../components/organisms';

type Props = {
  type: string;
  onDelete: (id?: string) => void;
  files: any;
};

function Contents({ type, onDelete, files }: Props) {
  return (
    <S_ContentsBox>
      {type === STORY_TYPES.IMAGE && <PreviewList images={files} handleDelete={onDelete} />}

      {type === STORY_TYPES.VIDEO && files.length > 0 && (
        <Video
          src={URL.createObjectURL(files[0].file)}
          hasDeleteButton
          onDelete={onDelete}
          thumbnailSrc={files[0].previewSrc}
        />
      )}

      {type === STORY_TYPES.YOUTUBE && files.length > 0 && (
        <Iframe src={files[0].previewSrc} hasDeleteButton onDelete={onDelete} />
      )}
    </S_ContentsBox>
  );
}

const S_ContentsBox = styled.div`
  padding: 0 24px;
  padding-bottom: 24px;
`;

export default Contents;
