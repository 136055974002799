import AWS from 'aws-sdk';
import { put, call, all } from 'redux-saga/effects';

import { uploadImageToS3, uploadVideoToS3 } from '../reducers';
import { digestResponse } from '../../../middlewares/response';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  region: process.env.REACT_APP_AWS_REGION
});

const s3 = new AWS.S3();

function makeImageUpload(el: any) {
  const promise: Promise<string> = new Promise((resolve) =>
    s3
      .upload({
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME as string,
        Key: el.name,
        ContentType: 'image/png',
        Body: el.body
      })
      .send((_, response) => {
        if (response) {
          resolve(response.Location);
        }
      })
  );

  return promise;
}

export function* watchUploadImageToS3(action: ReturnType<typeof uploadImageToS3>) {
  const { type, payload } = action;

  if (Array.isArray(payload)) {
    const response: ReturnType<typeof makeImageUpload> = yield all(
      payload.map((el) => call(makeImageUpload, el))
    );

    yield put(digestResponse(response, type));
    return;
  }

  const response: ReturnType<typeof makeImageUpload> = yield call(makeImageUpload, payload);
  yield put(digestResponse(response, type));
}

function makeVideoUpload(el: any) {
  const promise: Promise<string> = new Promise((resolve) =>
    s3
      .upload({
        Bucket: process.env.REACT_APP_AWS_SOCIAL_COMMUNITY_BUCKET_NAME as string,
        Key: `${el.name}.mp4`,
        Body: el.body
      })
      .send((_, response) => {
        if (response) {
          resolve(response.Location);
        }
      })
  );

  return promise;
}

export function* watchUploadVideoToS3(action: ReturnType<typeof uploadVideoToS3>) {
  const { type, payload } = action;

  if (Array.isArray(payload)) {
    const response: ReturnType<typeof makeVideoUpload> = yield all(
      payload.map((el) => call(makeVideoUpload, el))
    );

    yield put(digestResponse(response, type));
    return;
  }

  const response: ReturnType<typeof makeVideoUpload> = yield call(makeVideoUpload, payload);
  yield put(digestResponse(response, type));
}
