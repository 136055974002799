/* eslint-disable */
import { Delay, URI, Repo } from '../utils/index.js';

import Profitable from './profitable.js';

import config from './config.js';
import repo from './db.js';
import Order from '../models/Order.js';
import SystemReceipt from '../models/SystemReceipt.js';

const MERCHANT_CLIENT_USER_TOKEN = 'MERCHANT_CLIENT_USER_TOKEN';

// rootNamespace: '/MERCHANT', authorizedNamespaces: ['/channel-plan-products']
// signed by configured secretKey
const HARDCODED_ACCESS_TOKEN =
  'eyJhbGciOiJIUzI1NiJ9.eyJyb290TmFtZXNwYWNlIjoiL01FUkNIQU5UIiwiYXV0aG9yaXplZE5hbWVzcGFjZXMiOlsiL2NoYW5uZWwtcGxhbi1wcm9kdWN0cyJdfQ.R1ojmwzvvqmOKXbdODG32w81WGw6_j8ttE63udLCLsY';
const HARDCODED_ISSUER_PUBL = {
  type: 'BUSINESS',
  serviceName: 'publ',
  operatorName: 'OERATOR_OF_PUBL',
  operatorPhoneCountryCode: 746, // '+82'
  operatorPhoneNumber: '01083121478',
  companyName: '키클롭스(주)',
  businessRepresentativeName: '배인식',
  businessRegistrationNumber: '115-87-00415',
  mailOrderBusinessRegistrationNumber: '2019-서울성동-01388',
  address1: '뚝섬로 317',
  address2: '반석빌딩 2층',
  city: '성동구',
  state: '서울특별시',
  postalCode: '04479',
  countryCodeAlpha2: 'KR'
};

class MockServer {
  constructor(options) {
    const { mid, apiKey, secretKey } = options;

    this._mid = mid;
    this._apiKey = apiKey;
    this._secretKey = secretKey;
  }

  async _createOrder(body) {
    const { orderSheet, isPaid } = body;

    const order = Order.fromOrderSheet(orderSheet);
    const createdOrderRow = Repo.insert(repo, 'orders', order);

    const issuedSystemReceipt = SystemReceipt.fromOrder(createdOrderRow, 'ON_CREATION');
    Repo.insert(repo, 'systemReceipts', issuedSystemReceipt);

    if (isPaid) {
      const existingOrderRow = Repo.findOne(repo, 'orders', { id: createdOrderRow.id });
      // TODO: update 항목 mock data 넣기
      const updatedOrderRow = Repo.update(repo, 'orders', existingOrderRow.id, {
        balance: existingOrderRow.price
      });

      await Delay.waitInMs(10);

      const paidSystemReceipt = SystemReceipt.fromOrder(updatedOrderRow, 'ON_PAY');

      Repo.insert(repo, 'systemReceipts', paidSystemReceipt);

      const existingSystemInvoiceRow = Repo.findOne(repo, 'systemInvoices', {
        siid: orderSheet.systemInvoice.siid
      });

      Repo.update(repo, 'systemInvoices', existingSystemInvoiceRow.id, { status: 'PAID' });
    }

    return {
      body: {
        data: {
          order: createdOrderRow
        }
      }
    };
  }

  _ping() {
    return {
      body: {
        ping: 'pong'
      }
    };
  }

  _authorize(_body, _claims) {
    return {
      body: {
        data: {
          accessToken: HARDCODED_ACCESS_TOKEN
        }
      }
    };
  }

  _createSystemInvoice(body, claims) {
    const { productId, productModel } = body;
    const ownDefinedProduct = Profitable.findEntityByProductModel(repo, productId, productModel);

    if (!ownDefinedProduct) {
      // no ownDefinedProduct: 404
      // also runs when ownDefinedProduct is deactivated or deleted.
      return {
        body: {
          error: {
            code: '404',
            status: 'NOT_FOUND',
            msg: `${productModel} with id:${productId} does not exist.`
          }
        }
      };
    }

    const issuer = ownDefinedProduct.seller ? {} : HARDCODED_ISSUER_PUBL;
    const recipient = claims.seller
      ? {
          accountId: claims.seller.email,
          accountName: claims.seller.operatorName
        }
      : {};

    const { error, systemInvoice } = Profitable.genSystemInvoice(
      repo,
      ownDefinedProduct,
      issuer,
      recipient
    );

    if (error) {
      // creation failed: 400
      return {
        body: {
          error: {
            code: '400',
            status: 'BAD_REQUEST',
            msg: "couldn't create systemInvoice due to invalid input."
          }
        }
      };
    }

    return {
      body: {
        data: {
          systemInvoice
        }
      }
    };
  }

  _findSystemInvoice(siid, _claims) {
    const systemInvoice = Profitable.findSystemInvoice(repo, siid);

    // no systemInvoice: 404
    if (!systemInvoice) {
      return {
        body: {
          error: {
            code: '404',
            status: 'NOT_FOUND',
            msg: `systemInvoice with siid:${siid} does not exist.`
          }
        }
      };
    }

    return {
      body: {
        data: {
          systemInvoice
        }
      }
    };
  }

  _checkSystemReceipt(body, _claims) {
    const { siid } = body;

    const systemReceipt = Profitable.findLatestSystemReceipt(repo, siid);

    // no systemReceipt: 404
    if (!systemReceipt) {
      return {
        body: {
          error: {
            code: '404',
            status: 'NOT_FOUND',
            msg: `systemReceipt with siid:${siid} does not exist.`
          }
        }
      };
    }

    return {
      body: {
        data: {
          systemReceipt: {
            status: systemReceipt.status,
            name: systemReceipt.name,
            price: systemReceipt.price
          }
        }
      }
    };
  }

  _router(method, path, _queryParams, body, claims) {
    const methodWithPath = `${method} ${path}`;

    switch (methodWithPath) {
      case 'POST /publ-pay/orders':
        return this._createOrder(body);

      case 'GET /publ-pay/ping':
        return this._ping();

      case 'GET /profitable/ping':
        return this._ping();

      case 'POST /profitable/authorize':
        return this._authorize(body, claims);

      case 'POST /profitable/system-invoices':
        return this._createSystemInvoice(body, claims);

      case 'POST /profitable/system-receipts/check':
        return this._checkSystemReceipt(body, claims);

      default:
        if (methodWithPath.includes('GET /profitable/system-invoices/')) {
          const siid = methodWithPath.replace('GET /profitable/system-invoices/', '');

          return this._findSystemInvoice(siid, claims);
        }

        // no route: 404
        return {
          body: {
            error: {
              code: '404',
              status: 'NOT_FOUND',
              msg: 'there is no route defined.'
            }
          }
        };
    }
  }

  async handleRequest(method, url, body, options) {
    await Delay.waitInMs(100);

    const { token } = options;

    // no token: 401
    if (!token) {
      return {
        body: {
          error: {
            code: '401',
            status: 'UNAUTHENTICATED',
            msg: 'no token provided.'
          }
        }
      };
    }

    // invalid token: 403
    if (token !== MERCHANT_CLIENT_USER_TOKEN && token !== HARDCODED_ACCESS_TOKEN) {
      return {
        body: {
          error: {
            code: '403',
            status: 'FORBIDDEN',
            msg: 'invalid token provided.'
          }
        }
      };
    }

    const { path, queryParams } = URI.parse(url);

    const { apiKey } = queryParams;

    // no apiKey: 401
    if (!apiKey) {
      return {
        body: {
          error: {
            code: '401',
            status: 'UNAUTHENTICATED',
            msg: 'no apiKey provided.'
          }
        }
      };
    }

    // invalid apiKey: 403
    if (apiKey !== this._apiKey) {
      return {
        body: {
          error: {
            code: '403',
            status: 'FORBIDDEN',
            msg: 'invalid apiKey provided.'
          }
        }
      };
    }

    const claims = this._extractClaimsFromToken(token);
    const jsonBody = body ? JSON.parse(body) : {};

    return this._router(method, path, queryParams, jsonBody, claims);
  }

  _extractClaimsFromToken(token) {
    return {
      seller: {
        id: 1,
        operatorName: 'zoopeter',
        email: 'dev@publ.biz'
      }
    };
  }
}

export default new MockServer(config);
