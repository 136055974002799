import { uiColors } from 'pds-dev-kit-web';

import { DefaultTheme } from 'styled-components';

export const colors = {
  blue700: '#0040bb',
  blue500: '#455eef',
  blue300: '#838bff',
  green700: '#00b884',
  green500: '#1eebb4',
  green300: '#6effd1',
  greenBadge: '#1dd4ca',
  red500: '#ff463a',
  grey900: '#1e1e20',
  grey500: '#68686b',
  grey400: '#aaaab1',
  grey100: '#e8eaed',
  grey50: '#f5f6f8',
  white: 'white',
  black: 'black'
};

export const textColors = {
  textPrimary: '#1e1e20',
  textSecondary: '#68686b',
  textDisable: '#aaaab1'
};

export const fontSize = {
  fontSizeH1: '32px',
  fontSizeH2: '24px',
  fontSizeSubtitle: '18px',
  fontSizeBody1: '18px',
  fontSizeBody2: '16px',
  fontSizeCaption1: '14px',
  fontSizeCaption2: '12px',
  fontSizeForm1: '16px',
  fontSizeForm2: '14px',
  fontSizeBlog1: '16px'
};

export const fontLineHeight = {
  fontLineHeight: '1.6em'
};

export const fontWeight = {
  fontWeightNormal: '500',
  fontWeightBold: '700'
};

export const margins = {
  marginSideNormal: '0 24px'
};

const theme: DefaultTheme = {
  ...colors,
  ...textColors,
  ...fontSize,
  ...fontLineHeight,
  ...fontWeight,
  ...margins,
  ...uiColors
};

export default theme;
