import styled from 'styled-components';

function LoadingIcon() {
  return <S_LoadingIcon>Loading</S_LoadingIcon>;
}

const S_LoadingIcon = styled.div`
  text-align: center;
  width: 100%;
`;

export default LoadingIcon;
