import IconType from '../IconType';

const Heart = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M10.5555423,20.57659 L3.087,12.7779 L3.087,12.7779 C0.847,10.4379 0.62,6.8569 2.56,4.4489 C3.688,3.0519 5.313,2.2499 7.019,2.2499 C8.553,2.2499 10.023,2.8899 11.159,4.0519 C11.511,4.4129 11.787,4.7489 12,5.0399 C12.213,4.7489 12.489,4.4119 12.84,4.0519 C13.977,2.8899 15.447,2.2499 16.981,2.2499 C18.687,2.2499 20.313,3.0519 21.44,4.4489 C23.38,6.8569 23.154,10.4369 20.913,12.7779 L13.4444577,20.57659 C12.6804775,21.3743419 11.4144436,21.4017194 10.6166916,20.6377392 C10.5958702,20.6177992 10.5754823,20.5974114 10.5555423,20.57659 Z"
      />
    </svg>
  );
};

export default Heart;
