import AWS from 'aws-sdk';
import { AxiosResponse } from 'axios';
import { channel } from 'redux-saga';
import { put, takeEvery, call, take } from 'redux-saga/effects';

import { ProfileRepository } from '../../../repositories';
import { digestResponse } from '../../middlewares/response';

import {
  fetchCurrentChannelProfile,
  fetchProfile,
  updateCurrentChannelProfile,
  updateProfile,
  uploadImageToS3
} from './reducers';
import { UploadImagePayload } from './types';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  region: process.env.REACT_APP_AWS_REGION
});

const s3 = new AWS.S3();

function* watchFetchProfile(action: ReturnType<typeof fetchProfile>) {
  const { type, payload: id } = action;

  const response: AxiosResponse = yield call(() => ProfileRepository.findOne(id));

  yield put(digestResponse(response, type));
}

function* watchUpdateProfile(action: ReturnType<typeof updateProfile>) {
  const { type, payload: params } = action;
  const { id, body } = params;

  const response: AxiosResponse = yield call(() => ProfileRepository.update(id, body));

  yield put(digestResponse(response, type));
}

function makeUploadImageChannel(params: UploadImagePayload) {
  const ch = channel();

  const options = {
    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME as string,
    Key: `${params.name}_image`,
    ContentType: 'image/png',
    Body: params.body
  };

  s3.upload(options).send((_, response) => {
    if (response) {
      ch.put(response.Location);
    }
  });

  return ch;
}

function* watchUploadImageToS3(action: ReturnType<typeof uploadImageToS3>) {
  const { type, payload } = action;

  const channel: ReturnType<typeof makeUploadImageChannel> = yield call(
    makeUploadImageChannel,
    payload
  );

  while (true) {
    const data: ReturnType<typeof uploadImageToS3> = yield take(channel);
    yield put(digestResponse(data, type));
  }
}

function* watch() {
  yield takeEvery(fetchProfile, watchFetchProfile);
  yield takeEvery(updateProfile, watchUpdateProfile);
  yield takeEvery(fetchCurrentChannelProfile, watchFetchProfile);
  yield takeEvery(updateCurrentChannelProfile, watchUpdateProfile);
  yield takeEvery(uploadImageToS3, watchUploadImageToS3);
}

export default watch;
