import IconType from '../IconType';

const PageSizeExpand = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M3.4473,13.3591 C3.8613,13.3591 4.1973,13.6951 4.1973,14.1091 L4.1973,14.1091 L4.1973,18.7011 L8.8013,14.0971 C9.0943,13.8041 9.5683,13.8041 9.8613,14.0971 C10.1543,14.3901 10.1543,14.8651 9.8613,15.1571 L9.8613,15.1571 L5.2583,19.7611 L9.8493,19.7611 C10.2633,19.7611 10.5993,20.0971 10.5993,20.5111 C10.5993,20.9251 10.2633,21.2611 9.8493,21.2611 L9.8493,21.2611 L3.4473,21.2611 C3.0333,21.2611 2.6973,20.9251 2.6973,20.5111 L2.6973,20.5111 L2.6973,14.1091 C2.6973,13.6951 3.0333,13.3591 3.4473,13.3591 Z M20.5524,2.7391 C20.9664,2.7391 21.3024,3.0751 21.3024,3.4891 L21.3024,3.4891 L21.3024,9.8911 C21.3024,10.3051 20.9664,10.6411 20.5524,10.6411 C20.1384,10.6411 19.8024,10.3051 19.8024,9.8911 L19.8024,9.8911 L19.8024,5.2991 L15.1994,9.9031 C15.0524,10.0491 14.8614,10.1221 14.6694,10.1221 C14.4774,10.1221 14.2854,10.0491 14.1384,9.9031 C13.8454,9.6101 13.8454,9.1351 14.1384,8.8421 L14.1384,8.8421 L18.7404,4.2391 L14.1504,4.2391 C13.7364,4.2391 13.4004,3.9031 13.4004,3.4891 C13.4004,3.0751 13.7364,2.7391 14.1504,2.7391 L14.1504,2.7391 Z"
      />
    </svg>
  );
};

export default PageSizeExpand;
