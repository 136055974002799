import { useMemo, useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { makeGetIsUpdated, makeGetProfile } from '@common/redux/modules/profile';
import { fetchProfile } from '@common/redux/modules/profile/reducers';
import { signOut } from '@common/redux/modules/session/reducers';
import { JWT } from '@common/utils/index';

import { CertifyDesc } from '../Certify';

import { NavBar, MenuItem, ProfileItem, SignOut, CertifyButton } from './molecules';
import { NavView } from './templates';
import { accountProfilePath, resetPwPath } from '@routers/routes';
import { useHistory } from 'react-router';

const { REACT_APP_KEY_ACCESS_TOKEN } = process.env;

function AccountContainer() {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const [profileId, setProfileId] = useState();
  const [isCertify, setIsCertify] = useState(false);
  const history = useHistory();

  const getIsUpdated = useMemo(makeGetIsUpdated, []);
  const isUpdated = useSelector(getIsUpdated);

  const getProfile = useMemo(makeGetProfile, []);
  const profile = useSelector(getProfile);
  const [email, setEmail] = useState();

  useEffect(() => {
    const [, , jwtData] = JWT.validateJwtToken(
      localStorage.getItem(REACT_APP_KEY_ACCESS_TOKEN || '')
    );

    if (jwtData.user) {
      if (jwtData.user.mainProfile) {
        setEmail(jwtData.user.email);
        setProfileId(jwtData.user.mainProfile.id);
        dispatch(fetchProfile(jwtData.user.mainProfile.id));
      }
    }
  }, [dispatch]);

  const handleClickSignOut = useCallback(() => {
    dispatch(signOut());
  }, [dispatch]);

  useEffect(() => {
    if (isUpdated) {
      if (profileId) {
        dispatch(fetchProfile(profileId));
      }
    }
  }, [dispatch, profileId, isUpdated]);

  function handleClickEdit() {
    history.push(accountProfilePath);
  }

  return (
    <>
      {!isCertify && (
        <NavView
          nav={<NavBar title={t('str_935', 'Account')} isCertify={isCertify} isBack={false} />}
        >
          <ProfileItem
            nickname={profile.nickname}
            imageSrc={profile.imageSrc}
            email={email}
            onClickEdit={handleClickEdit}
          />
          <MenuItem icon>{t('str_accout_1', '모든 채널 결제 기록')}</MenuItem>
          <MenuItem iconLink={resetPwPath} icon>
            {t('str_reset_password', '비밀번호 재설정')}
          </MenuItem>
          <MenuItem iconLink="https://publr.co/" icon iconName="ic_OpenPage">
            {t('str_customer_center', 'publ 고객 센터')}
          </MenuItem>
          <CertifyButton setIsCertify={setIsCertify}>
            {' '}
            {t('str_936', '본인 인증 (성인인증)')}{' '}
          </CertifyButton>
          <SignOut onSignOut={handleClickSignOut} />
        </NavView>
      )}
      {isCertify && (
        <NavView
          nav={<NavBar title={t('str_935', 'Account')} isBack={isCertify} isCertify={isCertify} />}
        >
          <CertifyDesc />
        </NavView>
      )}
    </>
  );
}

export default AccountContainer;
