import { useEffect, useMemo } from 'react';
import { Switch, Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { PrivateRoute } from '@common/overrides/index';
import { makeGetIsAuthenticated } from '@common/redux/modules/session';

import { makeGetCurrentChannel } from '@common/redux/modules/channel';

import { NoticePosts, NoticeDetail } from './pages';
import { noticeBasicPath } from '@routers/routes';

const noticePostsPath = `${noticeBasicPath}/posts`;
const noticeDetailPath = `${noticeBasicPath}/posts/:postId`;

function NoticesContainer() {
  const history = useHistory();
  const getIsAuthenticated = useMemo(makeGetIsAuthenticated, []);
  const isAuthenticated = useSelector(getIsAuthenticated);

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  useEffect(() => {
    if (currentChannel) {
      if (Object.keys(currentChannel.activePApps).indexOf('A00002') < 0) {
        history.push('/');
      }
    }
  }, [currentChannel]);

  return (
    <Switch>
      <PrivateRoute
        exact
        path={noticePostsPath}
        component={NoticePosts}
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path={noticeDetailPath}
        component={NoticeDetail}
        isAuthenticated={isAuthenticated}
      />
      <Redirect from={noticeBasicPath} to={`${noticePostsPath}?page=1&limit=8`} />
    </Switch>
  );
}

export default NoticesContainer;
