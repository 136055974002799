import React from 'react';
import styled, { css } from 'styled-components';
import NukaCarousel from 'nuka-carousel';

type ImageCarouselProps = {
  children?: React.ReactNode;
  cellSpacing?: number;
  slideWidth?: number;
  slidesToShow?: number;
  wrapAround?: boolean;
};

const renderDotControls = ({ currentSlide, slideCount }: any) => {
  const dotList = [...Array(slideCount)].map((x, i) => ({ id: i }));

  return (
    <>
      {dotList.map((item, i) => (
        <S_Dot key={item.id} currentSlide={currentSlide} i={i} />
      ))}
    </>
  );
};

function ImageCarousel({
  children,
  cellSpacing = 0,
  slideWidth,
  slidesToShow,
  wrapAround
}: ImageCarouselProps) {
  return (
    <S_Carousel>
      <NukaCarousel
        cellSpacing={cellSpacing}
        cellAlign="center"
        slideWidth={slideWidth}
        initialSlideHeight={100}
        renderCenterLeftControls={null}
        renderCenterRightControls={null}
        renderBottomCenterControls={(props) => renderDotControls(props)}
        slidesToShow={slidesToShow}
        wrapAround={wrapAround}
      >
        {children}
      </NukaCarousel>
    </S_Carousel>
  );
}

const S_Carousel = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  & > .slider {
    height: auto !important;
  }
`;

const S_Dot = styled.div<{ currentSlide: number; i: number }>`
  border-radius: 99px;
  display: inline-block;
  height: 8px;
  margin: 8px 4px;
  width: 8px;

  ${({ currentSlide, i }) =>
    currentSlide === i
      ? css`
          background-color: ${({ theme }) => theme.white};
        `
      : css`
          background-color: ${({ theme }) => theme.grey500};
        `}
`;

export default ImageCarousel;
