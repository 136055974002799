import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  fetchCounterpart,
  connectSocket,
  disconnectSocket
} from '@common/redux/modules/chat/reducers';

import { ChattingList, ChattingRoom } from './pages';
import { chatBasicPath } from '@routers/routes';

const listPath = `${chatBasicPath}/chat`;
const roomPath = `${chatBasicPath}/chat/:profileId`;

function ChatContainer() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(connectSocket());
    dispatch(fetchCounterpart());

    return () => {
      dispatch(disconnectSocket());
    };
  }, [dispatch]);

  return (
    <Switch>
      <Route exact path={listPath}>
        <ChattingList />
      </Route>
      <Route path={roomPath}>
        <ChattingRoom />
      </Route>
      <Redirect from={chatBasicPath} to={listPath} />
    </Switch>
  );
}

export default ChatContainer;
