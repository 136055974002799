import React, { useState, useRef, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useFormContext } from 'react-hook-form';

import { StyledNamespace } from '@common/types/styledNamespace';
import { Icon } from '@common/ui/components';
import { Popup } from '../Popup';
import ScrollView from '../ScrollView/ScrollView';

const S: StyledNamespace = {};

export type DropdownInputProps = {
  name?: string;
  value?: string;
  readOnly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  selectOnly?: boolean;
  onChange?: (target: TargetType | React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (target: TargetType | React.ChangeEvent<HTMLInputElement>) => void;
  renderButton?: (args: RenderButtonType) => void;
  validation?: any;
  children?: React.ReactNode;
};

type TargetType = {
  target: {
    name?: string;
    value?: string;
  };
};

type RenderButtonType = {
  value?: string;
  isFocused?: boolean;
  toggleFocused: () => void;
  selectValue: (child?: any) => void;
};

type DropdownWrapperStyle = {
  isFocused?: boolean;
};

type DropdownInputStyle = {
  selectOnly?: boolean;
};

type DropdownButtonStyle = {
  focusedIndex?: number;
  idx?: number;
};

function DropdownInput({
  name = '',
  value,
  readOnly,
  disabled,
  placeholder,
  selectOnly,
  onChange,
  onBlur,
  renderButton,
  children,
  validation,
  ...rest
}: DropdownInputProps) {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  const id = `input_${name}`;
  const { register } = useFormContext();
  const boxRef = useRef();
  const inputRef = useRef<HTMLInputElement>();
  const totalOptions = React.Children.count(children);
  const selectedLabel = useMemo(() => {
    let v = '';
    React.Children.forEach(children, (child: any, idx: number) => {
      if (idx === focusedIndex) {
        v = child.props.children;
      }
    });

    return v;
  }, [focusedIndex, children]);

  // 값이 변경될 때마다 값과 맞는 옵션을 찾아 활성화시켜줌(Focusing)
  useEffect(() => {
    let matched = false;

    React.Children.forEach(children, (child: any, idx: number) => {
      if (child && child.props.value === value) {
        setFocusedIndex(idx);
        matched = true;
      }
    });

    if (!matched) {
      setFocusedIndex(null);
    }
  }, [value, children]);

  /**
   * 입력 포커스 이벤트
   */
  function handleFocus() {
    if (!readOnly) {
      setIsFocused(true);
      setIsOpen(true);
    }
  }

  /**
   * 입력 블러 이벤트
   */
  function handleBlur() {
    setIsFocused(false);
    setIsOpen(false);

    if (onBlur) {
      onBlur({
        target: {
          name,
          value
        }
      });
    }
  }

  /**
   * 입력 포커스 토글 이벤트
   */
  function handleToggleFocused() {
    if (inputRef.current) {
      if (isFocused) {
        inputRef.current.blur();
      } else {
        inputRef.current.focus();
      }
    }
  }

  /**
   * 값 선택 이벤트
   *
   * @param {any} optionValue
   */
  function handleSelectValue(optionValue?: string) {
    if (onChange) {
      onChange({
        target: {
          name,
          value: optionValue
        }
      });
    }

    setIsOpen(false);
  }

  /**
   * 키 입력 이벤트
   *
   * @param {InputEvent} e
   */
  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'ArrowUp') {
      if (typeof focusedIndex === 'number' && focusedIndex > 0) {
        e.preventDefault();
        setIsOpen(true);
        setFocusedIndex(focusedIndex - 1);
      }
    } else if (e.key === 'ArrowDown') {
      if (focusedIndex === null) {
        e.preventDefault();
        setFocusedIndex(0);
      } else if (focusedIndex < totalOptions - 1) {
        e.preventDefault();
        setIsOpen(true);
        setFocusedIndex(focusedIndex + 1);
      }
    } else if (e.key === ' ') {
      if (selectOnly) {
        e.preventDefault();
        if (!isOpen) {
          setIsOpen(true);
        } else if (focusedIndex === null) {
          setFocusedIndex(0);
        } else {
          setFocusedIndex(focusedIndex + 1 >= totalOptions ? 0 : focusedIndex + 1);
        }
      }
    } else if (e.key === 'Enter') {
      e.preventDefault();

      if (!isOpen) {
        setIsOpen(true);
      } else if (focusedIndex !== null) {
        // 활성화된 옵션값이 있다면 그 옵션값으로 선택함
        React.Children.forEach(children, (child: any, idx: number) => {
          if (focusedIndex === idx) {
            handleSelectValue(child.props.value);
          }
        });
      } else {
        // 활성화된 옵션값이 없으면 입력된 값을 선택함
        handleSelectValue(value);
      }
    }
  }

  return (
    <S.DropdownInput>
      <S.DropdownWrapper ref={boxRef} isFocused={isFocused}>
        <S.InputWrapper>
          <S.Input
            {...register(name as any, validation)}
            type="text"
            id={id}
            name={name}
            value={selectOnly ? selectedLabel : value}
            selectOnly={selectOnly}
            readOnly={selectOnly || readOnly}
            disabled={disabled}
            placeholder={placeholder}
            onChange={onChange}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...rest}
          />
        </S.InputWrapper>

        {!disabled &&
          !readOnly &&
          !!renderButton &&
          renderButton({
            value,
            isFocused,
            toggleFocused: handleToggleFocused,
            selectValue: handleSelectValue
          })}

        {!disabled && !readOnly && !renderButton && (
          <S.Icon type="button" onFocus={handleFocus} selectOnly={selectOnly} tabIndex="-1">
            {!isOpen && <Icon iconName="ic_ArrowDown" colorCode="grey500" size={16} />}
            {isOpen && <Icon iconName="ic_ArrowUp" colorCode="grey500" size={16} />}
          </S.Icon>
        )}
      </S.DropdownWrapper>

      <Popup targetRef={boxRef} placement="bottom-both" isOpen={isOpen} onClickOutside={handleBlur}>
        <S.ItemWrapper>
          <ScrollView style={{ maxHeight: '448px' }}>
            {React.Children.map(
              children,
              (child: any, idx: number) =>
                !!child && (
                  <S.Item
                    idx={idx}
                    type="button"
                    focusedIndex={focusedIndex}
                    key={child.props.value}
                    style={{
                      minHeight: '2.5rem'
                    }}
                    tabIndex="-1"
                    onMouseDown={() => handleSelectValue(child.props.value)}
                  >
                    {child.props.children}
                  </S.Item>
                )
            )}
          </ScrollView>
        </S.ItemWrapper>
      </Popup>
    </S.DropdownInput>
  );
}

S.DropdownInput = styled.div`
  display: block;

  & + & {
    margin-top: 16px;
  }
`;

const disabledStyle = css`
  background-color: ${({ theme }) => theme.grey50};
  color: ${({ theme }) => theme.grey400};
`;

const readOnlyStyle = css`
  background-color: ${({ theme }) => theme.grey50};
  color: ${({ theme }) => theme.grey900};
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  user-select: none;
`;

S.DropdownWrapper = styled.div<DropdownWrapperStyle>`
  align-items: center;
  background-color: ${({ theme }) => {
    return theme.white;
  }};
  border: solid 1px
    ${({ theme, isFocused }) => {
      if (isFocused) return theme.blue500;
      return theme.grey100;
    }};
  border-radius: 8px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.grey900};
  display: flex;
  height: 48px;
  outline: 0;
  overflow: hidden;
  width: 100%;
`;

S.InputWrapper = styled.div`
  flex: 1 1 0%;
  height: 100%;
`;

S.Input = styled.input<DropdownInputStyle>`
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeForm2};
  font-stretch: normal;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  height: 100%;
  letter-spacing: normal;
  outline: 0;
  overflow: hidden;
  padding-left: 12px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;

  ${({ selectOnly }) =>
    selectOnly &&
    css`
      cursor: pointer;
    `}

  ${({ readOnly, selectOnly }) =>
    readOnly &&
    !selectOnly &&
    css`
      cursor: default;
    `}

  ${({ disabled }) => disabled && disabledStyle}
  ${({ readOnly, selectOnly }) => !selectOnly && readOnly && readOnlyStyle}
`;

S.Icon = styled.button<DropdownInputStyle>`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  border: none;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  outline: none;
  width: 40px;
`;

S.ItemWrapper = styled.div`
  background-color: ${({ theme }) => {
    return theme.white;
  }};
  border: solid 1px
    ${({ theme }) => {
      return theme.grey100;
    }};
  border-radius: 8px;
  border-top-width: 0;
  overflow: hidden;
`;

S.Item = styled.button<DropdownButtonStyle>`
  background-color: transparent;
  background-color: ${({ theme, focusedIndex, idx }) => focusedIndex === idx && theme.grey50};
  background-image: none;
  border: none;
  color: inherit;
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: ${({ theme }) => theme.fontSizeForm2};
  margin: 0;
  min-height: 10px;
  outline: none;
  overflow: visible;
  padding: 0;
  padding-left: 12px;
  padding-right: 12px;
  text-align: left;
  text-transform: none;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.grey100};
  }
`;

export default DropdownInput;
