import IconType from '../IconType';

const ArrowRight = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M11.1385,20.1498 C10.9465,20.1498 10.7545,20.0768 10.6085,19.9298 C10.3155,19.6368 10.3155,19.1628 10.6085,18.8698 L17.4265,12.0508 L10.6085,5.1298 C10.3155,4.8368 10.3155,4.3628 10.6085,4.0698 C10.9015,3.7768 11.3755,3.7768 11.6685,4.0698 L18.4875,10.8888 C18.7845,11.1848 18.9495,11.5788 18.9495,11.9998 C18.9495,12.4208 18.7845,12.8158 18.4865,13.1128 L11.6685,19.9298 C11.5225,20.0768 11.3295,20.1498 11.1385,20.1498"
      />
    </svg>
  );
};

export default ArrowRight;
