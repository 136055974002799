import { AxiosResponse } from 'axios';

import JWT from '@common/utils/jwt';
import { ResponseOK } from '@common/types/response';
import api, { getEndpointSet } from '@common/services/api';

import {
  VODPostQueryParamType,
  PlaylistQueryParamType,
  VODPostsWithField,
  VODPostWithField,
  PlaylistsWithField,
  PlaylistWithField,
  EpisodesWithField
} from './VODPApp.types';

const {
  FIND_ALL_VOD,
  FIND_VOD_BY_ID,
  FIND_ALL_PLAYLIST,
  FIND_PLAYLIST_BY_ID,
  FIND_ALL_EPISODES_BY_PLAYLIST_ID
} = getEndpointSet('VOD_PAPP', 'postable');

const { GET_VOD_TOKEN } = getEndpointSet('VOD', 'token');

function getHeaderOptions(id: number) {
  return {
    headers: {
      'x-publ-consumer-id': JWT.getChannelHeader(id, 'C00001')
    }
  };
}

class VODRepository {
  static findAllVOD(
    queryParams: VODPostQueryParamType,
    channelId: number
  ): Promise<AxiosResponse<ResponseOK<VODPostsWithField>>> {
    return api.get(FIND_ALL_VOD, {}, queryParams, getHeaderOptions(channelId));
  }

  static findVOD(
    postId: string,
    channelId: number
  ): Promise<AxiosResponse<ResponseOK<VODPostWithField>>> {
    return api.get(FIND_VOD_BY_ID, { postId }, {}, getHeaderOptions(channelId));
  }

  static findAllPlaylist(
    queryParams: PlaylistQueryParamType,
    channelId: number
  ): Promise<AxiosResponse<ResponseOK<PlaylistsWithField>>> {
    return api.get(FIND_ALL_PLAYLIST, {}, queryParams, getHeaderOptions(channelId));
  }

  static findPlaylist(
    seriesId: string,
    channelId: number
  ): Promise<AxiosResponse<ResponseOK<PlaylistWithField>>> {
    return api.get(FIND_PLAYLIST_BY_ID, { seriesId }, {}, getHeaderOptions(channelId));
  }

  static findAllEpisodes(
    seriesId: string,
    channelId: number
  ): Promise<AxiosResponse<ResponseOK<EpisodesWithField>>> {
    return api.get(
      FIND_ALL_EPISODES_BY_PLAYLIST_ID,
      { seriesId },
      { isPublished: true },
      getHeaderOptions(channelId)
    );
  }

  static vodToken(channelId: number, vid: number): Promise<AxiosResponse<ResponseOK<string>>> {
    return api.post(GET_VOD_TOKEN, {}, { vid }, getHeaderOptions(channelId));
  }
}

export default VODRepository;
