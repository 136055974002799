import { useMemo } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { PrivateRoute } from '@common/overrides/index';
import { makeGetIsAuthenticated } from '@common/redux/modules/session';

import { Posts, PostDetail, CreatePost, EditPost } from './pages';
import { postsBasicPath } from '@routers/routes';

const postsPath = `${postsBasicPath}/posts`;
const postPath = `${postsBasicPath}/posts/:postId`;
const createPostPath = `${postsBasicPath}/create`;
const editPostPath = `${postsBasicPath}/posts/:postId/edit`;

function SocialCommunityPAppContainer() {
  const getIsAuthenticated = useMemo(makeGetIsAuthenticated, []);
  const isAuthenticated = useSelector(getIsAuthenticated);

  return (
    <Switch>
      <PrivateRoute exact path={postsPath} component={Posts} isAuthenticated={isAuthenticated} />
      <PrivateRoute
        exact
        path={postPath}
        component={PostDetail}
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        exact
        path={createPostPath}
        component={CreatePost}
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        exact
        path={editPostPath}
        component={EditPost}
        isAuthenticated={isAuthenticated}
      />
      <Redirect from={postsBasicPath} to={postsPath} />
    </Switch>
  );
}

export default SocialCommunityPAppContainer;
