import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button, Icon } from '@common/ui/components';
import DateHelper from '@common/utils/dateHelper';
import styled from 'styled-components';

import { buildBookingMyBookingDescPath } from '@routers/routes';

type BookingCardProps = {
  nowProgressing?: boolean;
  cancelWaiting?: boolean;
  title: number | null;
  time: string;
  duration: number;
  body: number;
  id: string;
  channelID: string;
};

type BookingCardStyleProps = {
  nowProgressing?: boolean;
  cancelWaiting?: boolean;
};

function BookingCard({
  nowProgressing,
  cancelWaiting,
  title,
  time,
  duration,
  body,
  id,
  channelID
}: BookingCardProps) {
  const { t } = useTranslation('translation');
  const history = useHistory();
  const startDate = DateHelper.getInstance(time).format('YYYY-MM-DD');
  const startTime = DateHelper.getInstance(time).format('HH:mm');
  const endTime = DateHelper.getInstance(time).add(duration, 'minute');

  const bumperTime = DateHelper.getInstance() > endTime.add(-3, 'minute');

  return (
    <S_BookingCardContainer
      onClick={() => {
        history.push(buildBookingMyBookingDescPath(channelID), { id, fromHistory: false });
      }}
    >
      <S_ColorBar nowProgressing={nowProgressing} cancelWaiting={cancelWaiting} />
      <S_ContentArea>
        <S_Title>
          {/* MyBooking - 예약 및 일정 카드 상세
          https://publ.atlassian.net/wiki/spaces/UM0007/pages/2474475902/MyBooking#MyBooking---%EC%98%88%EC%95%BD-%EB%B0%8F-%EC%9D%BC%EC%A0%95-%EC%B9%B4%EB%93%9C-%EC%83%81%EC%84%B8
          */}
          {/* Status 1) 일정 진행 중 */}
          {nowProgressing && `${t('str_papp_cbooking_subs_card_status_a', '상담 진행 중')}`}
          {/* Status 2) 상담 예정 */}
          {!nowProgressing &&
            title === 0 &&
            `${t('str_papp_cbooking_subs_card_status_b', '상담 예정')}`}
          {/* Status 3) 예약 취소 대기 */}
          {!nowProgressing &&
            title === null &&
            `${t('str_papp_cbooking_subs_card_status_c', '예약 취소 대기')}`}
        </S_Title>
        <S_Time>
          {`${t('str_26', { startDate, startTime, defaultValue: '{{startDate}} {{startTime}}' })}`}
          {` ${t('str_27', { duration, defaultValue: '({{duration}}$t(str_fm_minute))' })}`}
        </S_Time>
        <S_Status>
          {/* 구독자의 예약 취소 요청 */}
          {!nowProgressing &&
            title === null &&
            body === 1 &&
            `${t('str_papp_cbooking_subs_history_rsv_cancel_rq', {
              defaultValue: '$t(str_papp_cbooking_rsv_cancellation) 요청'
            })}`}
          {/* 구독자의 예약 취소 요청을 셀러가 거절 */}
          {!nowProgressing &&
            title === 0 &&
            body === 1 &&
            `${t('papp_cbooking_subs_history_rsv_cancel_deny_from_seller', {
              defaultValue: '$t(str_seller)의 $t(str_papp_cbooking_rsv_cancellation) 거절'
            })}`}
          {/* 셀러의 예약 취소 요청 */}
          {!nowProgressing &&
            title === null &&
            body === 3 &&
            `${t('str_papp_cbooking_subs_history_rsv_cancel_rq_from_seller', {
              defaultValue: '$t(str_seller)의 $t(str_papp_cbooking_rsv_cancellation) 요청'
            })}`}
          {/* 셀러의 예약 취소 요청을 구독자가 거절 */}
          {!nowProgressing &&
            title === 0 &&
            body === 3 &&
            `${t('papp_cbooking_subs_history_rsv_cancel_deny', {
              defaultValue: '$t(str_papp_cbooking_rsv_cancellation) 거절'
            })}`}
          {/* 구독자가 일정 진행 거부 요청한 상태 */}
          {nowProgressing &&
            title === null &&
            body === 5 &&
            `${t('str_papp_cbooking_history_evt_refusal_rq', '상담 거부 요청')}`}
          {/* 구독자 일정 진행 거부 요청을 셀러가 거절한 상태 */}
          {nowProgressing &&
            title === 0 &&
            body === 5 &&
            `${t('str_papp_cbooking_subs_history_evt_refusal_deny', '')}`}
        </S_Status>
        <S_FakeButton>
          {nowProgressing && title !== null && !bumperTime && (
            <Button
              label={t('str_papp_cbooking_evt_refusal', {
                defaultValue: '진행 거부'
              })}
              line
              size="regular"
            />
          )}
          {!nowProgressing && title === 0 && (
            <Button
              label={t('str_papp_cbooking_rsv_cancellation', '예약 취소')}
              line
              size="regular"
            />
          )}
          {!nowProgressing && title === null && body === 3 && (
            <Button label={t('str_request_acc_or_ref', '수락 / 거절')} line size="regular" />
          )}
        </S_FakeButton>
      </S_ContentArea>
      <S_ArrowBar>
        <Icon iconName="ic_ArrowRight" colorCode="grey400" />
      </S_ArrowBar>
    </S_BookingCardContainer>
  );
}

const S_BookingCardContainer = styled.div<BookingCardStyleProps>`
  display: flex;
  flex-direction: flex-start;
  height: 160px;
  margin: 24px;
`;

const S_ColorBar = styled.div<BookingCardStyleProps>`
  background-color: ${({ theme, nowProgressing, cancelWaiting }) => {
    if (nowProgressing) return theme.blue500;
    if (cancelWaiting) return '#f9a33a';
    return theme.green700;
  }};
  border-radius: 16px 0 0 16px;
  height: 160px;
  width: 16px;
`;

const S_ArrowBar = styled.div`
  background-color: white;
  border-radius: 0 16px 16px 0;
  height: 24px;
  padding: 68px 16px 68px 0;
  width: 24px;
`;

const S_ContentArea = styled.div`
  background-color: white;
  height: 160px;
  line-height: ${({ theme }) => theme.fontLineHeight};
  text-align: left;
  width: 100%;
`;

const S_Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizeSubtitle};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  margin: 16px 16px 0 16px;
`;

const S_Time = styled.div`
  color: ${({ theme }) => theme.grey400};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  height: 21px;
  margin: 4px 16px 8px 16px;
`;

const S_Status = styled.div`
  color: ${({ theme }) => theme.red500};
  font-size: ${({ theme }) => theme.fontSizeCaption1};
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  height: 21px;
  margin: 0 16px;
`;

const S_FakeButton = styled.div`
  margin: 16px;
`;

export default BookingCard;
