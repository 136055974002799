import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Icon } from '@common/ui/components';
import styled from 'styled-components';

type NavBarProps = {
  title?: string;
  isBack: boolean;
  onBack?: Function;
};

/**
 * 상단 네비게이션 아이콘 바
 *
 * @param {Object} props
 * @param {boolean} props.isBack 뒤로 버튼 여부
 * @param {string} props.title 제목
 * @param {functoin} props.onBack 뒤로가기 버튼 클릭시 콜백 (false를 반환하면 뒤로가기 취소)
 */
function NavBar({ title, isBack, onBack }: NavBarProps) {
  const history = useHistory();

  /**
   * 뒤로가기 클릭 이벤트 핸들러
   */
  function handleBack() {
    if (onBack) {
      const r = onBack();

      if (r instanceof Promise) {
        r.then((v) => {
          if (v) {
            history.goBack();
          }
        });
      } else if (r) {
        history.goBack();
      }
    } else {
      history.goBack();
    }
  }

  return (
    <NavBarContainer>
      {isBack && (
        <Button onClick={handleBack}>
          <Icon iconName="ic_ArrowLeft" />
        </Button>
      )}

      <Title>{title}</Title>
      <ButtonEmpty />
    </NavBarContainer>
  );
}

NavBar.defaultProps = {
  isBack: false,
  onBack: null
};

NavBar.propTypes = {
  isBack: PropTypes.bool,
  onBack: PropTypes.func,
  title: PropTypes.string.isRequired
};

const NavBarContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  height: 56px;
  justify-content: space-between;
  left: 0;
  position: sticky;
  right: 0;
  text-align: center;
  top: 0;
  transition: transform 0.4s;
  z-index: 10;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-stretch: normal;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  margin: 0;
`;

const ButtonEmpty = styled.div`
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-right: 8px;
  width: 48px;
`;

const Button = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-left: 8px;
  width: 48px;
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
`;

export default NavBar;
