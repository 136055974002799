import { all } from 'redux-saga/effects';
import { sessionSagas } from './modules/session';
import { userSagas } from './modules/user';
import { channelSagas } from './modules/channel';
import { VODPAppSagas } from './modules/VODPApp';
import { VODPlusPAppSagas } from './modules/VODPlusPApp';
import { chatSagas } from './modules/chat';
import { subscriptionProductSagas } from './modules/subscriptionProduct';
import { bookingSagas } from './modules/booking';
import { subscriptionSagas } from './modules/subscription';
import { profileSagas } from './modules/profile';
import { socialCommunityPAppSagas } from './modules/socialCommunityPApp';
import { noticePAppSagas } from './modules/noticePApp';
import { freeBoardPAppSagas } from './modules/freeBoardPApp';

function* rootSaga() {
  yield all([
    sessionSagas(),
    userSagas(),
    subscriptionProductSagas(),
    channelSagas(),
    VODPAppSagas(),
    VODPlusPAppSagas(),
    chatSagas(),
    bookingSagas(),
    subscriptionSagas(),
    profileSagas(),
    socialCommunityPAppSagas(),
    noticePAppSagas(),
    freeBoardPAppSagas()
  ]);
}

export default rootSaga;
