import IconType from '../IconType';

const PostNotice = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        d="M12.5668,1.08 C13.8581569,1.08 14.9808785,1.80265424 15.5525832,2.86558095 L17.5,2.8667 C19.433,2.8667 21,4.4337 21,6.3667 L21,6.3667 L21,18.3667 C21,20.2997 19.433,21.8667 17.5,21.8667 L17.5,21.8667 L6.5,21.8667 C4.567,21.8667 3,20.2997 3,18.3667 L3,18.3667 L3,6.3667 C3,4.4337 4.567,2.8667 6.5,2.8667 L6.5,2.8667 L8.44778832,2.86558095 C9.01919785,1.80265424 10.1414431,1.08 11.4328,1.08 L12.5668,1.08 Z M17.5,4.3667 L15.9532864,4.36575874 C15.9542931,4.39971614 15.9548,4.43379892 15.9548,4.468 C15.9548,4.956 15.5588,5.351 15.0718,5.351 L8.9278,5.351 C8.4408,5.351 8.0458,4.956 8.0458,4.468 L8.048,4.366 L6.5,4.3667 C5.44713636,4.3667 4.58197107,5.18400785 4.50549502,6.21763266 L4.5,6.3667 L4.5,18.3667 C4.5,19.4697 5.397,20.3667 6.5,20.3667 L6.5,20.3667 L17.5,20.3667 C18.603,20.3667 19.5,19.4697 19.5,18.3667 L19.5,18.3667 L19.5,6.3667 C19.5,5.2637 18.603,4.3667 17.5,4.3667 L17.5,4.3667 Z"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M7.7793,8.8139 L16.2203,8.8139 M7.7793,12.6562 L16.2203,12.6562 M7.7793,16.498 L12.7523,16.498"
      />
    </svg>
  );
};

export default PostNotice;
