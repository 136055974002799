import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';

import { PrivateRoute } from '@common/overrides/index';
import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import { makeGetIsAuthenticated } from '@common/redux/modules/session';

import { buildVodPlusPath } from '@routers/routes';

import {
  VodPlusCategory,
  VodPlusHome,
  VodPlusSeries,
  VodPlusSeriesDetail,
  VodPlusEpisodeDetail,
  VodPlusWishList
} from './pages';
import VodPlusIFrame from './pages/VodPlusEpisodeDetail/organisms/VodPlusIFrame';

function VODPlusContainer() {
  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);
  const [invitationCode, setInvitationCode] = useState<string>('');

  const vodPlusPath = buildVodPlusPath(invitationCode);

  useEffect(() => {
    if (currentChannel) {
      setInvitationCode(currentChannel.primitiveInvitationCode);
    }
  }, [currentChannel]);

  const getIsAuthenticated = useMemo(makeGetIsAuthenticated, []);
  const isAuthenticated = useSelector(getIsAuthenticated);

  return (
    <Switch>
      <PrivateRoute
        path={`${vodPlusPath}/home`}
        component={VodPlusHome}
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        exact
        path={`${vodPlusPath}/serieses`}
        component={VodPlusSeries}
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        exact
        path={`${vodPlusPath}/serieses/:seriesId`}
        component={VodPlusSeriesDetail}
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        exact
        path={`${vodPlusPath}/serieses/:seriesId/episodes/:episodeId`}
        component={VodPlusEpisodeDetail}
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        exact
        path={`${vodPlusPath}/fullscreen`}
        component={VodPlusIFrame}
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        exact
        path={`${vodPlusPath}/wishlist`}
        component={VodPlusWishList}
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        exact
        path={`${vodPlusPath}/category`}
        component={VodPlusCategory}
        isAuthenticated={isAuthenticated}
      />
      <Redirect from={vodPlusPath} to={`${vodPlusPath}/home`} />
    </Switch>
  );
}

export default VODPlusContainer;
