import { useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeGetIsAuthenticated, makeGetIsAuthenticating } from '@common/redux/modules/session';
import { makeGetCurrentChannel, makeGetChannelDescParams } from '@common/redux/modules/channel';
import styled from 'styled-components';

import { SignUp } from './pages';
import { accountPath } from '@routers/routes';

function SignUpContainer() {
  const getIsAuthenticated = useMemo(makeGetIsAuthenticated, []);
  const getIsAuthenticating = useMemo(makeGetIsAuthenticating, []);
  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);

  const getChannelDescParams = useMemo(makeGetChannelDescParams, []);
  const channelDescParams = useSelector(getChannelDescParams);

  const currentChannel = useSelector(getCurrentChannel);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const isAuthenticating = useSelector(getIsAuthenticating);

  if (isAuthenticating) {
    // NOTE: 임시 로딩입니다.
    return (
      <Box>
        <h2>Loading...</h2>
      </Box>
    );
  }

  if (isAuthenticated) {
    if (currentChannel && Object.keys(currentChannel).length !== 0) {
      if (channelDescParams.conversionLinkKeyword !== '') {
        return (
          <Redirect
            to={`/channels/${currentChannel.primitiveInvitationCode}?from=${channelDescParams.conversionLinkKeyword}`}
          />
        );
      }
      return <Redirect to={`/channels/${currentChannel.primitiveInvitationCode}`} />;
    }

    return <Redirect to={accountPath} />;
  }

  return <SignUp />;
}

const Box = styled.div`
  align-items: center;
  box-shadow: 0 100px 0 0 white, 0 -100px 0 0 white, 0 0 50px -30px rgb(0 0 0 / 50%),
    0 0 50px -30px rgb(0 0 0 / 50%);
  color: ${({ theme }) => theme.grey500};
  display: flex;
  height: 100%;
  justify-content: center;
`;

export default SignUpContainer;
