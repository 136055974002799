import { takeEvery } from 'redux-saga/effects';

import {
  fetchDetail,
  fetchList,
  addPost,
  fetchComments,
  addComment,
  deleteComment,
  uploadImageToS3,
  uploadVideoToS3,
  removePost,
  editPost,
  likePost,
  dislikePost,
  increaseViewCount
} from '../reducers';

import {
  watchFetchList,
  watchFetchDetail,
  watchAddPost,
  watchEditPost,
  watchDeletePost,
  watchLikePost,
  watchDislikePost,
  watchCountHits
} from './postSaga';
import { watchFetchComments, watchAddComment, watchDeleteComment } from './commentSaga';
import { watchUploadImageToS3, watchUploadVideoToS3 } from './s3Saga';

function* watch() {
  yield takeEvery(fetchList, watchFetchList);
  yield takeEvery(fetchDetail, watchFetchDetail);
  yield takeEvery(addPost, watchAddPost);
  yield takeEvery(editPost, watchEditPost);
  yield takeEvery(removePost, watchDeletePost);
  yield takeEvery(fetchComments, watchFetchComments);
  yield takeEvery(addComment, watchAddComment);
  yield takeEvery(deleteComment, watchDeleteComment);
  yield takeEvery(uploadImageToS3, watchUploadImageToS3);
  yield takeEvery(uploadVideoToS3, watchUploadVideoToS3);
  yield takeEvery(likePost, watchLikePost);
  yield takeEvery(dislikePost, watchDislikePost);
  yield takeEvery(increaseViewCount, watchCountHits);
}

export default watch;
