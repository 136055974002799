import { batchActions } from 'redux-batched-actions';

const prefix = '@middlewares/response';

const DIGEST_RESPONSE = `${prefix}/DIGEST_RESPONSE`;

export const digestResponse = (response, originalType) => ({
  payload: response,
  type: DIGEST_RESPONSE,
  meta: { originalType }
});

function handleSuccess(action) {
  const {
    payload: response,
    meta: { originalType }
  } = action;

  return { payload: response, type: `${originalType}_COMPLETED` };
}

function handleError(action, digestErrorResponse) {
  const {
    payload: response,
    meta: { originalType }
  } = action;
  const failureAction = { payload: response, type: `${originalType}_FAILED` };

  return digestErrorResponse
    ? batchActions([failureAction, digestErrorResponse(response, originalType)])
    : failureAction;
}

const createResponseMiddleware = ({ digestErrorResponse }) => () => (next) => (action) => {
  const { type, payload: response } = action;

  if (type !== DIGEST_RESPONSE) {
    return next(action);
  }

  if (response.status > 299) {
    return next(handleError(action, digestErrorResponse));
  }

  return next(handleSuccess(action));
};

export default createResponseMiddleware;
