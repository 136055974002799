import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import DateHelper from '@common/utils/dateHelper';
import styled from 'styled-components';

import { buildBookingPath } from '@routers/routes';
import { Title } from '../../../components/atoms';
import { ContentBar } from '../../../components/molcules';
import { Caption } from '../molcules';

type BookingInfoProps = {
  duration: number;
  ticketLeft: number;
  selectDate: ReturnType<typeof DateHelper.getInstance>;
  selectTime: string;
  channelID: string;
};

function BookingInfo({
  duration,
  ticketLeft,
  selectDate,
  selectTime,
  channelID
}: BookingInfoProps) {
  const { t } = useTranslation('translation');
  const history = useHistory();
  const startDate = selectDate.format('YYYY-MM-DD');

  useEffect(() => {
    if (selectTime === '' && channelID.length > 0) {
      history.push(buildBookingPath(channelID));
    }
  }, [channelID, channelID.length, history, selectTime]);
  return (
    <S_BookingInfoContainer>
      <Title text={t('str_8', '예약 정보')} align="left" />
      <ContentBar
        iconName="ic_Booking"
        title={t('str_14', '$t(str_papp_cbooking_rsv) 일시')}
        content={t('str_26', {
          startDate,
          startTime: selectTime,
          defaultValue: '{{startDate}} {{startTime}}'
        })}
      />
      <ContentBar
        iconName="ic_BookingTime"
        title={t('str_46', '상담 시간')}
        content={t('str_42', { duration, defaultValue: '{{duration}}분' })}
      />
      <ContentBar
        iconName="ic_BookingTicket"
        title={t('str_papp_cbooking_ticket', '진행 중인 상담이 취소되었습니다.')}
        content={t('str_15', {
          ticketAmount: 1,
          defaultValue: '$t(str_papp_cbooking_ticket) {{ticketAmount}}장 차감'
        })}
      />
      <Caption ticketLeft={ticketLeft} channelId={channelID} />
    </S_BookingInfoContainer>
  );
}

const S_BookingInfoContainer = styled.div`
  background-color: white;
`;

export default BookingInfo;
