import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@common/ui/components';
import { buildVodPlusPath } from '@routers/routes';

import { Text } from '../../../components';

type Props = {
  invitationCode: string;
};

function EmptyHome({ invitationCode }: Props) {
  const { t } = useTranslation('translation');
  const history = useHistory();
  const vodPlusPath = buildVodPlusPath(invitationCode);

  return (
    <S_EmptyHome>
      <S_TextWrapper>
        <Text fontSizes="fontSizeBody2">{t('str_946', '더 많은 시리즈를 만나보세요!')}</Text>
      </S_TextWrapper>
      <Button
        label={t('str_papp_vodp_series_more', '시리즈 더 보기')}
        line
        size="regular"
        onClick={() => {
          history.push(`${vodPlusPath}/serieses`);
        }}
      />
    </S_EmptyHome>
  );
}

const S_EmptyHome = styled.div`
  margin-top: 56px;
`;

const S_TextWrapper = styled.div`
  margin-bottom: 16px;
`;

export default EmptyHome;
