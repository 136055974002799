import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect, useContext, createContext } from 'react';
import { createPortal } from 'react-dom';

import { StyledNamespace } from '@common/types/styledNamespace';
import styled from 'styled-components';

const S: StyledNamespace = {};

const PopupContext = createContext({});

function getHAutoPlacement({ targetRect, popupRect }: { targetRect: any; popupRect: any }) {
  const leftSpaced = targetRect.right + popupRect.width > window.innerWidth;
  const topSpaced = targetRect.bottom + popupRect.height > window.innerHeight;
  return `${leftSpaced ? 'left' : 'right'}-${topSpaced ? 'end' : 'start'}`;
}

function getVAutoPlacement({ targetRect, popupRect }: { targetRect: any; popupRect: any }) {
  const leftSpaced = targetRect.right + popupRect.width > window.innerWidth;
  const topSpaced = targetRect.bottom + popupRect.height > window.innerHeight;
  return `${topSpaced ? 'top' : 'bottom'}-${leftSpaced ? 'end' : 'start'}`;
}

function getVCenterPlacement({ targetRect, popupRect }: { targetRect: any; popupRect: any }) {
  const leftSpaced = targetRect.right + popupRect.width > window.innerWidth;
  return leftSpaced ? 'left-center' : 'right-center';
}

function getHCenterPlacement({ targetRect, popupRect }: { targetRect: any; popupRect: any }) {
  const topSpaced = targetRect.bottom + popupRect.height > window.innerHeight;
  return topSpaced ? 'top-center' : 'bottom-center';
}

/**
 * 위치 정규화
 */
function getNormalizedPlacement({
  placement,
  targetRect,
  popupRect
}: {
  placement: string;
  targetRect: any;
  popupRect: any;
}) {
  switch (placement) {
    case 'v-auto':
      return getVAutoPlacement({ targetRect, popupRect });
    case 'h-auto':
      return getHAutoPlacement({ targetRect, popupRect });
    case 'v-center':
      return getVCenterPlacement({ targetRect, popupRect });
    case 'h-center':
      return getHCenterPlacement({ targetRect, popupRect });
    default:
      return placement;
  }
}

function updatePosition({
  targetElem,
  popupElem,
  placement
}: {
  targetElem: any;
  popupElem: any;
  placement: string;
}) {
  const targetRect = targetElem.getBoundingClientRect();
  const popupRect = popupElem.getBoundingClientRect();
  const normPlacement = getNormalizedPlacement({ placement, targetRect, popupRect });

  switch (normPlacement) {
    case 'top':
      popupElem.style.top = `${targetRect.top - popupRect.height}px`;
      popupElem.style.left = `${targetRect.left - (popupRect.width - targetRect.width) / 2}px`;
      popupElem.style.width = 'auto';
      break;
    case 'top-start':
      popupElem.style.top = `${targetRect.top - popupRect.height}px`;
      popupElem.style.left = `${targetRect.left}px`;
      popupElem.style.width = 'auto';
      break;
    case 'top-end':
      popupElem.style.top = `${targetRect.top - popupRect.height}px`;
      popupElem.style.left = `${targetRect.left + targetRect.width - popupRect.width}px`;
      popupElem.style.width = 'auto';
      break;
    case 'top-both':
      popupElem.style.top = `${targetRect.top - popupRect.height}px`;
      popupElem.style.left = `${targetRect.left}px`;
      popupElem.style.width = `${targetRect.width}px`;
      break;
    case 'top-center':
      popupElem.style.top = `${targetRect.top - popupRect.height}px`;
      popupElem.style.left = `${targetRect.left - (popupRect.width - targetRect.width) / 2}px`;
      popupElem.style.width = 'auto';
      break;
    case 'bottom':
      popupElem.style.top = `${targetRect.bottom}px`;
      popupElem.style.left = `${targetRect.left - (popupRect.width - targetRect.width) / 2}px`;
      popupElem.style.width = 'auto';
      break;
    case 'bottom-start':
      popupElem.style.top = `${targetRect.bottom}px`;
      popupElem.style.left = `${targetRect.left}px`;
      popupElem.style.width = 'auto';
      break;
    case 'bottom-end':
      popupElem.style.top = `${targetRect.bottom}px`;
      popupElem.style.left = `${targetRect.right - popupRect.width}px`;
      popupElem.style.width = 'auto';
      break;
    case 'bottom-both':
      popupElem.style.top = `${targetRect.bottom}px`;
      popupElem.style.left = `${targetRect.left}px`;
      popupElem.style.width = `${targetRect.width}px`;
      break;
    case 'bottom-center':
      popupElem.style.top = `${targetRect.bottom}px`;
      popupElem.style.left = `${targetRect.left - (popupRect.width - targetRect.width) / 2}px`;
      popupElem.style.width = 'auto';
      break;
    case 'left':
      popupElem.style.top = `${targetRect.top - (popupRect.height - targetRect.height) / 2}px`;
      popupElem.style.left = `${targetRect.left - popupRect.width}px`;
      popupElem.style.width = 'auto';
      break;
    case 'left-start':
      popupElem.style.top = `${targetRect.top}px`;
      popupElem.style.left = `${targetRect.left - popupRect.width}px`;
      popupElem.style.width = 'auto';
      break;
    case 'left-end':
      popupElem.style.top = `${targetRect.top - (popupRect.height - targetRect.height)}px`;
      popupElem.style.left = `${targetRect.left - popupRect.width}px`;
      popupElem.style.width = 'auto';
      break;
    case 'left-center':
      popupElem.style.top = `${targetRect.top - (popupRect.height - targetRect.height) / 2}px`;
      popupElem.style.left = `${targetRect.left - popupRect.width}px`;
      popupElem.style.width = 'auto';
      break;
    case 'right':
      popupElem.style.top = `${targetRect.top - (popupRect.height - targetRect.height) / 2}px`;
      popupElem.style.left = `${targetRect.left + targetRect.width}px`;
      popupElem.style.width = 'auto';
      break;
    case 'right-start':
      popupElem.style.top = `${targetRect.top}px`;
      popupElem.style.left = `${targetRect.left + targetRect.width}px`;
      popupElem.style.width = 'auto';
      break;
    case 'right-end':
      popupElem.style.top = `${targetRect.top - (popupRect.height - targetRect.height)}px`;
      popupElem.style.left = `${targetRect.left + targetRect.width}px`;
      popupElem.style.width = 'auto';
      break;
    case 'right-center':
      popupElem.style.top = `${targetRect.top - (popupRect.height - targetRect.height) / 2}px`;
      popupElem.style.left = `${targetRect.left + targetRect.width}px`;
      popupElem.style.width = 'auto';
      break;
    default:
      break;
  }

  return normPlacement;
}

type PopupProps = {
  targetRef: any;
  isOpen: boolean;
  onClickOutside?: (...args: any) => any;
  onClick?: (...args: any) => any;
  placement: string;
  children: React.ReactNode;
};

/**
 * 팝업 컴포넌트
 * 특정 대상의 위치를 기준으로 팝업의 위치를 잡습니다.
 *
 * @param {Object} props
 * @param {Object} props.targetRef 팝업의 위치 기준이 될 대상
 * @param {boolean} props.isOpen 팝업 열림 여부
 * @param {function} props.onClickOutside 팝업 밖을 클릭했을 때의 콜백
 * @param {function} props.onClick 팝업 안쪽을 클릭했을 때의 콜백
 * @param {string} props.placement 팝업 위치
 * 'v-auto', 'h-auto', 'v-center', 'h-center'
 * 'top', 'top-start', 'top-end', 'top-both', 'top-center',
 * 'bottom', 'bottom-start', 'bottom-end', 'bottom-both', 'bottom-center',
 * 'left', 'left-start', 'left-end', 'left-center',
 * 'right', 'right-start', 'right-end', 'right-center',
 */
function Popup({
  targetRef,
  isOpen = false,
  onClickOutside,
  onClick,
  placement = 'bottom-start',
  children
}: PopupProps) {
  const popupRootRef: any = useContext(PopupContext);
  const [normPlacement, setNormPlacement] = useState('bottom');
  const popupRef = useRef();

  /**
   * 팝업 외의 다른 요소 클릭시 팝업이 닫히도록 합니다.
   */
  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if (popupRootRef.current && !popupRootRef.current.contains(e.target)) {
        if (onClickOutside) {
          onClickOutside();
        }
      }
    }

    window.document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRootRef, onClickOutside]);

  /**
   * 전체 UI 렌더링이 한 프레임 완료된 후에 팝업 위치를 결정합니다.
   */
  useEffect(() => {
    window.requestAnimationFrame(() => {
      if (!targetRef.current || !popupRef.current) {
        return;
      }

      const targetElem = targetRef.current;
      const popupElem = popupRef.current;
      const updatedPlacement = updatePosition({ targetElem, popupElem, placement });
      setNormPlacement(updatedPlacement);
    });
  }, [isOpen, placement, targetRef, popupRef]);

  const handlePopupClick = (e: MouseEvent) => {
    if (onClick) {
      onClick(e);
    }
  };

  if (!popupRootRef || !popupRootRef.current || !isOpen) {
    return <></>;
  }

  const popupComponent = (
    <S.Popup ref={popupRef} onClick={handlePopupClick}>
      {typeof children === 'function' ? children(normPlacement) : children}
    </S.Popup>
  );

  return createPortal(popupComponent, popupRootRef.current);
}

S.Popup = styled.div`
  left: -1000px;
  position: fixed;
  top: -1000px;
  z-index: 1000;
`;

/**
 * 팝업 루트 프로바이더
 * 팝업을 사용하기 위해서는 팝업 프로바이더가 상위 컴포넌트로 있어야 합니다.
 */
function PopupProvider({ children }: { children: React.ReactNode }) {
  const ref: any = useRef();

  return (
    <>
      <PopupContext.Provider value={ref}>{children}</PopupContext.Provider>

      <div ref={ref} />
    </>
  );
}

PopupProvider.defaultProps = {
  children: null
};

PopupProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default Popup;

export { PopupProvider };
