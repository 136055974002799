import IconType from '../IconType';

const Chat = ({ color, size, ...rest }: IconType) => (
  <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M16.28515,11.3922 C15.63115,11.3922 15.10065,10.8622 15.10065,10.2082 C15.10065,9.5542 15.63115,9.0237 16.28515,9.0237 C16.93915,9.0237 17.46915,9.5542 17.46915,10.2082 C17.46915,10.8622 16.93915,11.3922 16.28515,11.3922 M12.00015,11.3922 C11.34615,11.3922 10.81615,10.8622 10.81615,10.2082 C10.81615,9.5542 11.34615,9.0237 12.00015,9.0237 C12.65415,9.0237 13.18415,9.5542 13.18415,10.2082 C13.18415,10.8622 12.65415,11.3922 12.00015,11.3922 M7.71515,11.3922 C7.06115,11.3922 6.53065,10.8622 6.53065,10.2082 C6.53065,9.5542 7.06115,9.0237 7.71515,9.0237 C8.36915,9.0237 8.89915,9.5542 8.89915,10.2082 C8.89915,10.8622 8.36915,11.3922 7.71515,11.3922 M18.16615,2.5632 L5.83415,2.5632 C3.90065,2.5632 2.33415,4.1302 2.33415,6.0632 L2.33415,14.2227 C2.33415,16.1557 3.90065,17.7227 5.83415,17.7227 L10.97715,17.7227 L14.23465,20.6837 C15.19865,21.5597 16.74365,20.8762 16.74365,19.5737 L16.74365,17.7227 L18.16615,17.7227 C20.09915,17.7227 21.66615,16.1557 21.66615,14.2227 L21.66615,6.0632 C21.66615,4.1302 20.09915,2.5632 18.16615,2.5632"
    />
  </svg>
);

export default Chat;
