/* eslint-disable @typescript-eslint/no-unused-vars */
// TODO : 현재 SW가 없는 관계로 업로드 된 데이터가 없습니다.
// 목데이터를 추가했으며 추후 실 데이터로 테스트를 진행하면서 주석처리를 삭제할 예정입니다.
import { AxiosResponse } from 'axios';

import JWT from '@common/utils/jwt';
import { ResponseOK } from '@common/types/response';
import api, { getEndpointSet } from '@common/services/api';

import {
  EpisodesWithField,
  SeriesQueryParamType,
  SeriesesWithField,
  ILabel,
  SeriesWithField,
  VODPlusHomeWithField,
  VODPlusPostWithField
} from './VODPlusPApp.types';
import { FetchViewCounterPayload } from '@common/redux/modules/VODPlusPApp';

const {
  VOD_PLUS_HOME,
  FIND_VOD_PLUS_BY_ID,
  FIND_SERIES_ALL,
  FIND_LABELS,
  FIND_SERIES_BY_ID,
  FIND_ALL_SERIES_BY_BOOKMARK,
  FIND_ALL_EPISODES_BY_SERIES_ID,
  ADD_BOOKMARK,
  DELETE_BOOKMARK
} = getEndpointSet('VOD_PLUS_PAPP', 'postable');

const { COUNT_HITS } = getEndpointSet('STATISTICS', 'viewcount');
const { GET_VOD_TOKEN } = getEndpointSet('VOD_PLUS', 'token');

function getHeaderOptions(id: number) {
  return {
    headers: {
      'x-publ-consumer-id': JWT.getChannelHeader(id, 'C00002')
    }
  };
}

class VODPlusRepository {
  // home 동적 레이아웃
  static findVODPlusHome(
    channelId: number
  ): Promise<AxiosResponse<ResponseOK<VODPlusHomeWithField>>> {
    return api.get(VOD_PLUS_HOME, {}, {}, getHeaderOptions(channelId));
  }

  // 영상 하나 가져오기
  static findPost(
    postId: string,
    channelId: number
  ): Promise<AxiosResponse<ResponseOK<VODPlusPostWithField>>> {
    return api.get(FIND_VOD_PLUS_BY_ID, { postId }, {}, getHeaderOptions(channelId));
  }

  // 모든 시리즈 가져오기
  static findAllSeries(
    queryParams: SeriesQueryParamType,
    channelId: number
  ): Promise<AxiosResponse<ResponseOK<SeriesesWithField>>> {
    return api.get(FIND_SERIES_ALL, {}, queryParams, getHeaderOptions(channelId));
  }

  // 라벨 가져오기
  static findLabels(
    useCase: string,
    channelId: number
  ): Promise<AxiosResponse<ResponseOK<ILabel>>> {
    return api.get(FIND_LABELS, {}, { useCase }, getHeaderOptions(channelId));
  }

  // 시리즈 하나 가져오기
  static findSeries(
    seriesId: string,
    channelId: number
  ): Promise<AxiosResponse<ResponseOK<SeriesWithField>>> {
    return api.get(FIND_SERIES_BY_ID, { seriesId }, {}, getHeaderOptions(channelId));
  }

  // 북마크 시리즈 가져오기
  static findSeriesesBookmark(
    channelId: number
  ): Promise<AxiosResponse<ResponseOK<SeriesesWithField>>> {
    return api.get(FIND_ALL_SERIES_BY_BOOKMARK, {}, {}, getHeaderOptions(channelId));
  }

  // 북마크 추가
  static addBookmark(seriesId: string, channelId: number) {
    return api.post(ADD_BOOKMARK, { seriesId }, {}, getHeaderOptions(channelId));
  }

  // 북마크 삭제
  static deleteBookmark(seriesId: string, channelId: number) {
    return api.remove(DELETE_BOOKMARK, { seriesId }, getHeaderOptions(channelId));
  }

  // 시리즈 내 에피소드 목록 보기
  static findAllEpisodes(
    seriesId: string,
    channelId: number
  ): Promise<AxiosResponse<ResponseOK<EpisodesWithField>>> {
    return api.get(FIND_ALL_EPISODES_BY_SERIES_ID, { seriesId }, {}, getHeaderOptions(channelId));
  }

  // 에피소드 뷰카운터
  static vodPlusViewCounter(
    resourceType: string,
    resourceId: string
  ): Promise<AxiosResponse<ResponseOK<FetchViewCounterPayload>>> {
    return api.post(
      COUNT_HITS,
      {
        'resource-type': 'post',
        'resource-id': resourceId
      },
      {},
      {}
    );
  }

  // 재생에 필요한 토큰
  static vodPlusToken(channelId: number, vid: number): Promise<AxiosResponse<ResponseOK<string>>> {
    return api.post(GET_VOD_TOKEN, {}, { vid }, getHeaderOptions(channelId));
  }
}

export default VODPlusRepository;
