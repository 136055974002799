import styled from 'styled-components';
import { StyledNamespace } from '@common/types/styledNamespace';

const S: StyledNamespace = {};

S.NavView = styled.div`
  height: 100%;
`;

type NavViewProps = {
  nav: JSX.Element;
  children: JSX.Element | JSX.Element[];
};

function NavView({ nav, children }: NavViewProps) {
  return (
    <S.NavView>
      {nav}
      {children}
    </S.NavView>
  );
}

export default NavView;
