import IconType from '../IconType';

const PostSocial = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M11.9999,12.2847 L12.276369,12.2888698 C16.4999344,12.4164365 19.990666,15.4569553 20.8079,19.4707 C20.9969,20.4007 20.2809,21.2737 19.3319,21.2737 L19.3319,21.2737 L4.6689,21.2737 C3.7199,21.2737 3.0029,20.4007 3.1919,19.4707 C4.0269,15.3697 7.6529,12.2847 11.9999,12.2847 L11.9999,12.2847 Z M11.9742,2.6999 C14.2482,2.6999 16.0912,4.5429 16.0912,6.8169 C16.0912,9.0899 14.2482,10.9339 11.9742,10.9339 C9.7002,10.9339 7.8572,9.0899 7.8572,6.8169 C7.8572,4.5429 9.7002,2.6999 11.9742,2.6999 Z"
      />
    </svg>
  );
};

export default PostSocial;
