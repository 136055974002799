import IconType from '../IconType';

const More = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M17.6022,3.2243 L6.3972,3.2243 C4.5272,3.2243 3.0112,4.7403 3.0112,6.6103 L3.0112,13.9343 C3.0112,15.8053 4.5272,17.3213 6.3972,17.3213 L11.0482,17.3213 L14.5232,20.4793 C15.2482,21.1383 16.4112,20.6243 16.4112,19.6443 L16.4112,17.3213 L17.6022,17.3213 C19.4722,17.3213 20.9882,15.8053 20.9882,13.9343 L20.9882,6.6103 C20.9882,4.7403 19.4722,3.2243 17.6022,3.2243"
      />
    </svg>
  );
};

export default More;
