import React, { useRef, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useFormContext } from 'react-hook-form';

type TextFieldButtonProps = {
  fieldName: string;
  multiLine?: boolean;
  textArea?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  maxLength?: number;
  rows?: number;
  opacity?: boolean;
  validation?: { [key: string]: any };
  type?: string;
  onFocus?: () => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>) => void;
  onIcon?: (el: boolean) => void;
  onTarget?: () => void;
  [x: string]: any;
};

type TextFieldStyleProps = {
  textColorBright?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
};

function TextFieldButton({
  fieldName = '',
  multiLine,
  textArea,
  readOnly,
  disabled,
  placeholder = '',
  maxLength = 30,
  rows = 1,
  opacity,
  validation,
  onFocus,
  onBlur,
  onIcon,
  onTarget,
  ...rest
}: TextFieldButtonProps) {
  const { register } = useFormContext();

  function handleFocus() {
    if (onFocus) {
      onFocus();
    }
  }

  function handleBlur(
    e: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>
  ) {
    if (onBlur && onTarget) {
      const { relatedTarget }: any = e;

      if (relatedTarget && relatedTarget.type === 'submit') {
        onTarget();
        onBlur(e);
        return;
      }

      if (onIcon) {
        onIcon(false);
      }

      onBlur(e);
    }
  }
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const { ref, ...refRest } = register(fieldName as any, validation);

  useEffect(() => {
    if (textAreaRef === null || textAreaRef.current === null) {
      return;
    }
    textAreaRef.current.style.height = '48px';
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
  }, []);

  const handleResizeHeight = useCallback(() => {
    if (textAreaRef === null || textAreaRef.current === null) {
      return;
    }
    textAreaRef.current.style.height = '48px';
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
  }, []);

  if (multiLine === true) {
    return (
      <S_MultiLine
        {...refRest}
        {...rest}
        name={fieldName}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
        maxLength={maxLength}
        textColorBright={opacity}
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={(e: any) => {
          ref(e);
          textAreaRef.current = e;
        }}
        onInput={handleResizeHeight}
      />
    );
  }
  if (textArea === true) {
    return (
      <S_Textarea
        {...register(fieldName as string, validation)}
        name={fieldName}
        rows={rows}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
        maxLength={maxLength}
        textColorBright={opacity}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...rest}
      />
    );
  }
  return (
    <S_Input
      {...register(fieldName as string, validation)}
      name={fieldName}
      placeholder={placeholder}
      readOnly={readOnly}
      disabled={disabled}
      maxLength={maxLength}
      textColorBright={opacity}
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...rest}
    />
  );
}

const styles = css<TextFieldStyleProps>`
  background-color: transparent;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  color: ${({ textColorBright, theme }) => (textColorBright ? theme.white : theme.grey900)};
  display: inline-flex;
  font-size: ${({ theme }) => theme.fontSizeForm2};
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  height: 100%;
  justify-content: center;
  outline: 0;
  overflow: hidden;
  padding: 0 12px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;

  &::placeholder {
    color: ${({ theme, textColorBright }) => {
      if (textColorBright) return theme.grey400;
      return theme.grey500;
    }};
  }

  ${({ theme, disabled }) =>
    disabled &&
    css`
      color: ${theme.grey400};
    `}

  ${({ readOnly }) =>
    readOnly &&
    css`
      -ms-user-select: none;
      -moz-user-select: -moz-none;
      -webkit-user-select: none;
      user-select: none;
    `}
`;

const textAreaStyle = css`
  line-height: ${({ theme }) => theme.fontLineHeight};
  overflow-y: auto;
  padding: 10px 12px;
  resize: none;
`;

const S_Input = styled.input`
  ${styles}
`;

const S_Textarea = styled.textarea`
  ${styles}
  ${textAreaStyle}
`;

const S_MultiLine = styled.textarea`
  ${styles}
  ${textAreaStyle}
`;

export default TextFieldButton;
