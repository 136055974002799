import { createReducer, createAction, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import DateHelper from '@common/utils/dateHelper';
import {
  InitialStateType,
  PostBookingType,
  AskCancelFromUserType,
  ConfirmCancelFromSellerType,
  FetchMyBookingHistoryType,
  FetchMyBookingDescType,
  FetchMyBookingDescEditType,
  FetchCalendarType
} from './types';

const prefix = '@booking';
// 티켓 생성
export const fetchSampleTicket = createAction(`${prefix}/FETCH_SAMPLE_TICKET`);
// BookingCalendar
export const dateSelect = createAction<ReturnType<typeof DateHelper.getInstance>>(
  `${prefix}/DATE_SELECT`
);
export const eidSelect = createAction<string>(`${prefix}/EID_SELECT`);
export const fetchCalendar = createAction<{ date: FetchCalendarType; channelId: number }>(
  `${prefix}/FETCH_CALENDAR`
);
export const fetchEvents = createAction<{ date: string; channelId: number }>(
  `${prefix}/FETCH_EVENTS`
);
export const timeSelect = createAction<string>(`${prefix}/TIME_SELECT`);
// BookingCalendar
export const CheckBookingAvailable = createAction<{ id: string; channelId: number }>(
  `${prefix}/CHECK_BOOKING_AVAILABLE`
);
export const responseStatusReset = createAction(`${prefix}/RESPONSE_STATUS_RESET`);
// BookingDesc
export const booking = createAction<PostBookingType>(`${prefix}/BOOKINGS`);
// MyBooking
export const fetchMyBookings = createAction<{ channelId: number }>(`${prefix}/FETCH_BOOKINGS`);
// MyBookingDesc
export const fetchMyBookingDesc = createAction<FetchMyBookingDescType>(
  `${prefix}/FETCH_MY_BOOKING_DESC`
);
// MyBookingDescEdit
export const fetchMyBookingDescEdit = createAction<{
  eventData: FetchMyBookingDescEditType;
  channelId: number;
}>(`${prefix}/FETCH_MY_BOOKING_DESC_EDIT`);
// MyBookingHistory
export const fetchMyBookingHistory = createAction<{
  pagenation: FetchMyBookingHistoryType;
  channelId: number;
}>(`${prefix}/FETCH_MY_BOOKING_HISTORY`);
// GET_CANCEL_BOOKING_ID_FROM_SELLER
export const fetchCancelListFromSeller = createAction<{ channelId: number }>(
  `${prefix}/GET_CANCEL_BOOKING_ID_FROM_SELLER`
);
// ASK_CANCEL_FROM_USER
export const askCancelFromUser = createAction<{
  requestType: AskCancelFromUserType;
  channelId: number;
}>(`${prefix}/ASK_CANCEL_FROM_USER`);
export const confirmCancelFromSeller = createAction<{
  requestType: ConfirmCancelFromSellerType;
  channelId: number;
}>(`${prefix}/CONFIRM_CANCEL_FROM_SELLER`);

const initialState: InitialStateType = {
  description: '',
  ticket: '',
  isBookingLoading: true,
  eventsAvailable: [],
  myBookings: {
    progressing: [],
    bookings: []
  },
  myBookingDesc: {
    booking: {
      id: '',
      description: null,
      title: '',
      startDate: '1992-03-05T00:00:00+09:00',
      duration: 0,
      metaData: {},
      processing: false,
      createdAt: '1992-03-05T00:00:00+09:00',
      history: [
        {
          cid: '',
          status: 0,
          time: '1992-03-05T00:00:00+09:00'
        }
      ],
      pagination: {
        total: 0,
        page: 0,
        limit: 0
      }
    }
  },
  myBookingHistory: {
    bookings: [
      {
        id: '',
        startDate: '1992-03-05T00:00:00+09:00',
        duration: 0
      }
    ],
    pagination: {
      total: 1,
      page: 1,
      limit: 10
    }
  },
  history: [],
  bookingAvailableDates: {},
  bookingEvents: {
    createdAt: '1992-03-05T00:00:00+09:00',
    description: '',
    duration: 0,
    id: '',
    startDate: '1992-03-05T00:00:00+09:00',
    title: ''
  },
  selectDate: DateHelper.getInstance(),
  selectTime: '',
  eid: 0,
  bookingConfirm: null,
  responseStatus: 0,
  error: null
};

const builderFactory = (
  builder: ActionReducerMapBuilder<InitialStateType>,
  actions: any[]
): ActionReducerMapBuilder<InitialStateType> => {
  return actions.reduce((acc, action) => {
    return acc.addCase(action, (state: InitialStateType) => {
      state.isBookingLoading = true;
    });
  }, builder);
};

const reducer = createReducer(initialState, (builder) => {
  builderFactory(builder, [
    CheckBookingAvailable,
    fetchSampleTicket,
    fetchMyBookings,
    fetchCalendar,
    askCancelFromUser,
    fetchEvents,
    fetchMyBookingDesc,
    confirmCancelFromSeller,
    fetchMyBookingHistory
  ])
    .addCase(`${booking}_COMPLETED`, (state, action: any) => {
      state.responseStatus = action.payload.status;
    })
    .addCase(`${booking}_FAILED`, (state, action: any) => {
      state.responseStatus = action.payload.status;
    })
    .addCase(`${fetchSampleTicket}_COMPLETED`, (state, action: any) => {
      state.ticket = action.payload.data;
    })
    .addCase(`${fetchMyBookings}_COMPLETED`, (state, action: any) => {
      state.myBookings = action.payload.data.data;
    })
    .addCase(`${fetchCalendar}_COMPLETED`, (state, action: any) => {
      state.bookingAvailableDates = action.payload.data.data.calendar;
    })
    .addCase(`${fetchEvents}_COMPLETED`, (state, action: any) => {
      state.eventsAvailable = action.payload.data.data.events;
    })
    .addCase(`${fetchMyBookingDesc}_COMPLETED`, (state, action: any) => {
      state.myBookingDesc = action.payload.data.data;
    })
    .addCase(`${fetchMyBookingHistory}_COMPLETED`, (state, action: any) => {
      state.myBookingHistory = action.payload.data.data;
    })
    .addCase(`${timeSelect}`, (state, action: any) => {
      state.selectTime = action.payload;
    })
    .addCase(`${dateSelect}`, (state, action: any) => {
      state.selectDate = action.payload;
    })
    .addCase(`${eidSelect}`, (state, action: any) => {
      state.eid = action.payload;
    })
    .addCase(`${fetchCancelListFromSeller}_COMPLETED`, (state, action: any) => {
      state.history = action.payload.data.data.history;
    })
    .addCase(`${responseStatusReset}`, (state) => {
      state.responseStatus = 0;
    })
    .addCase(`${fetchMyBookingDescEdit}_COMPLETED`, (state, action: any) => {
      state.responseStatus = action.payload.status;
    })
    .addCase(`${askCancelFromUser}_COMPLETED`, (state, action: any) => {
      state.responseStatus = action.payload.status;
    })
    .addCase(`${CheckBookingAvailable}_COMPLETED`, (state, action: any) => {
      state.responseStatus = action.payload.status;
    })
    .addCase(`${confirmCancelFromSeller}_COMPLETED`, (state, action: any) => {
      state.responseStatus = action.payload.status;
    })
    .addCase(`${CheckBookingAvailable}_FAILED`, (state, action: any) => {
      state.responseStatus = action.payload.status;
    })
    .addMatcher(
      (action: { type: string }) =>
        action.type.startsWith(prefix) && action.type.endsWith('_COMPLETED'),
      (state: { isBookingLoading: boolean }) => {
        state.isBookingLoading = false;
      }
    )
    .addMatcher(
      (action: { type: string }) =>
        action.type.startsWith(prefix) && action.type.endsWith('_FAILED'),
      (state, action: any) => {
        state.isBookingLoading = false;
        state.error = action.payload.data.message;
      }
    );
});

export default reducer;
