import styled from 'styled-components';

import { NavBarWrapper } from '../../../components/molecules';

type NavBarProps = {
  title: string;
};

function NavBar({ title }: NavBarProps) {
  return (
    <NavBarWrapper isMenu>
      <S_Title>{title}</S_Title>
    </NavBarWrapper>
  );
}

const S_Title = styled.h1`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  text-align: center;
`;

export default NavBar;
