import IconType from '../IconType';

const Xmark = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5.25,5.25 L18.75,18.75 M18.75,5.25 L5.25,18.75"
      />
    </svg>
  );
};

export default Xmark;
