import React from 'react';
import { NavView } from './templates';
import { NavBar } from './molecules';
import { ResetPwView } from './organisms';

/**
 * 비밀번호 재설정 컨테이너
 */
function ResetPwContainer() {
  return (
    <NavView nav={<NavBar isBack />}>
      <ResetPwView />
    </NavView>
  );
}

export default ResetPwContainer;
