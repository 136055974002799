import styled from 'styled-components';

import { NavBar, TabBar } from '../molecules/index';

type Props = {
  channelCode: string;
  title: string;
  backUrl?: string;
  hasTabBar?: boolean;
  isBack?: boolean;
  isMenu?: boolean;
};

function TobBar({ channelCode, title, backUrl, hasTabBar, isBack, isMenu }: Props) {
  return (
    <S_TopBar isBack={isBack}>
      <NavBar isMenu={isMenu} isBack={isBack} title={title} backUrl={backUrl} />
      {hasTabBar && <TabBar channelCode={channelCode} />}
    </S_TopBar>
  );
}

const S_TopBar = styled.div<{ isBack?: boolean }>`
  background-color: ${({ theme }) => theme.white};
  box-sizing: border-box;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  transform: scale(1);
  width: 100%;
  z-index: ${({ isBack }) => (isBack ? 10 : 4)};
`;

export default TobBar;
