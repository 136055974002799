import IconType from '../IconType';

const CheckboxIndeterminate = ({ color, size, ...rest }: IconType) => (
  <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={color}
        d="M18.462,0 L5.539,0 C2.48,0 0,2.479 0,5.538 L0,18.461 C0,21.52 2.48,24 5.539,24 L18.462,24 C21.521,24 24,21.52 24,18.461 L24,5.538 C24,2.479 21.521,0 18.462,0"
      />
      <path
        fill="#FFF"
        d="M18,12.9 L6,12.9 C5.503,12.9 5.1,12.497 5.1,12 C5.1,11.503 5.503,11.1 6,11.1 L18,11.1 C18.497,11.1 18.9,11.503 18.9,12 C18.9,12.497 18.497,12.9 18,12.9"
      />
    </g>
  </svg>
);

export default CheckboxIndeterminate;
