import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { EpisodeType } from '@common/redux/modules/VODPlusPApp/types';

import { Text, EpisodeEntry } from '../../../components';

type Props = {
  episodeCounts: number;
  episodes: EpisodeType[];
  invitationCode: string;
};

function EpisodeList({ episodeCounts, episodes, invitationCode }: Props) {
  const { t } = useTranslation('translation');

  return (
    <S_EpisodeList>
      <S_TextWrapper>
        <Text fontSizes="fontSizeBody2" color="grey500">
          {t('str_909', { seriesVodCnt: episodeCounts, defaultValue: '총 {{episodeCounts}}개' })}
        </Text>
      </S_TextWrapper>
      {episodes.map((entry: any, i: number) => (
        <EpisodeEntry entry={entry} invitationCode={invitationCode} key={i.toString()} />
      ))}
    </S_EpisodeList>
  );
}

const S_EpisodeList = styled.div`
  padding-top: 24px;
  text-align: left;
`;

const S_TextWrapper = styled.div`
  padding: 0 24px;
`;

export default EpisodeList;
