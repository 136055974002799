import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

import { buildVodPlusPath } from '@routers/routes';
import { Text } from '../atoms';

type Props = {
  invitationCode: string;
};

function SubTab({ invitationCode }: Props) {
  const { t } = useTranslation('translation');
  const history = useHistory();
  const { pathname } = history.location;

  const homeRoute = `${buildVodPlusPath(invitationCode)}/home`;
  const seriesRoute = `${buildVodPlusPath(invitationCode)}/serieses`;
  const wishListRoute = `${buildVodPlusPath(invitationCode)}/wishlist`;

  return (
    <S_SubTap>
      <S_TabWrapper
        isBorderColored={pathname === homeRoute}
        onClick={() => history.push(homeRoute)}
      >
        <Text
          fontSizes="fontSizeBody2"
          fontWeights={`${pathname === homeRoute ? 'fontWeightBold' : 'fontWeightNormal'}`}
          color={`${pathname === homeRoute ? 'blue500' : 'grey500'}`}
        >
          {t('str_papp_vodp_btn_home', '홈')}
        </Text>
      </S_TabWrapper>
      <S_TabWrapper
        isBorderColored={pathname === seriesRoute}
        onClick={() => history.push(seriesRoute, { selectedCategory: '' })}
      >
        <Text
          fontSizes="fontSizeBody2"
          fontWeights={`${pathname === seriesRoute ? 'fontWeightBold' : 'fontWeightNormal'}`}
          color={`${pathname === seriesRoute ? 'blue500' : 'grey500'}`}
        >
          {t('str_papp_vodp_btn_series', '시리즈')}
        </Text>
      </S_TabWrapper>
      <S_TabWrapper
        isBorderColored={pathname === wishListRoute}
        onClick={() => history.push(wishListRoute)}
      >
        <Text
          fontSizes="fontSizeBody2"
          fontWeights={`${pathname === wishListRoute ? 'fontWeightBold' : 'fontWeightNormal'}`}
          color={`${pathname === wishListRoute ? 'blue500' : 'grey500'}`}
        >
          {t('str_papp_vodp_btn_series_bookmark', '내가 찜한 목록')}
        </Text>
      </S_TabWrapper>
    </S_SubTap>
  );
}

const S_SubTap = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 48px;
  justify-content: space-between;
`;

const S_TabWrapper = styled.div<{ isBorderColored: boolean }>`
  align-items: center;
  border-bottom: ${({ isBorderColored, theme }) =>
    isBorderColored ? `2px solid ${theme.blue500}` : `1px solid ${theme.grey100}`};
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  width: 100%;
`;

export default SubTab;
