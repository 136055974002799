import IconType from '../IconType';

const VideoPip = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M18.4176,3.85 C20.3966,3.85 22.0006,5.454 22.0006,7.433 L22.0006,7.433 L22.0006,16.567 C22.0006,18.546 20.3966,20.15 18.4176,20.15 L18.4176,20.15 L5.5826,20.15 C3.6036,20.15 2.0006,18.546 2.0006,16.567 L2.0006,16.567 L2.0006,7.433 C2.0006,5.454 3.6036,3.85 5.5826,3.85 L5.5826,3.85 Z M18.4176,5.35 L5.5826,5.35 C4.4346,5.35 3.5006,6.284 3.5006,7.433 L3.5006,7.433 L3.5006,16.567 C3.5006,17.716 4.4346,18.65 5.5826,18.65 L5.5826,18.65 L18.4176,18.65 C19.5656,18.65 20.5006,17.716 20.5006,16.567 L20.5006,16.567 L20.5006,7.433 C20.5006,6.284 19.5656,5.35 18.4176,5.35 L18.4176,5.35 Z M17.5615,9.5819 C18.3355,9.5819 18.9625,10.2099 18.9625,10.9829 L18.9625,15.7019 C18.9625,16.4759 18.3355,17.1029 17.5615,17.1029 L11.1355,17.1029 C10.3615,17.1029 9.7345,16.4759 9.7345,15.7019 L9.7345,10.9829 C9.7345,10.2099 10.3615,9.5819 11.1355,9.5819 L17.5615,9.5819 Z"
      />
    </svg>
  );
};

export default VideoPip;
