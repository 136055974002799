import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getSampleTicket, getCheckBookingAvailable } from '@common/redux/modules/booking';
import {
  fetchSampleTicket,
  booking,
  responseStatusReset
} from '@common/redux/modules/booking/reducers';
import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import DateHelper from '@common/utils/dateHelper';
import styled from 'styled-components';

import { buildBookingPath, buildBookingConfirmPath } from '@routers/routes';
import { BookingView, NavBar } from '../../../components/molcules';
import { BookingInfo, RequestCheck } from '../organisms';

type BookingDescTemplateProps = {
  duration: number;
  ticketLeft: number;
  selectDate: ReturnType<typeof DateHelper.getInstance>;
  selectTime: string;
  selectEid: number;
  channelID: string;
  comment: string;
  setComment: (currentComment: string) => void;
  check: boolean;
  setCheck: (currentCheck: boolean) => void;
};

function BookingDescTemplate({
  duration,
  ticketLeft,
  selectDate,
  selectTime,
  selectEid,
  channelID,
  comment,
  setComment,
  check,
  setCheck
}: BookingDescTemplateProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const ticket = useSelector(getSampleTicket);

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const [request, setRequest] = useState('');
  const responseStatus = useSelector(getCheckBookingAvailable);

  const handleOnBooking = (data: { request: string }) => {
    setRequest(data.request);
    if (currentChannel) {
      if (data.request) {
        dispatch(
          booking({
            eid: selectEid,
            ticket,
            description: data.request,
            channelID,
            channelId: currentChannel.id
          })
        );
      } else {
        dispatch(
          booking({
            eid: selectEid,
            ticket,
            description: '',
            channelID,
            channelId: currentChannel.id
          })
        );
      }
    }
  };

  useEffect(() => {
    dispatch(fetchSampleTicket());
    if (responseStatus === 201) {
      history.push({
        pathname: buildBookingConfirmPath(channelID),
        state: { request, duration }
      });
    }
    if (responseStatus === 500) {
      // eslint-disable-next-line no-alert
      alert('예약에 실패하였습니다. 다른 일정을 선택해 주세요.');
      history.push(buildBookingPath(channelID));
    }
    dispatch(responseStatusReset());
  }, [channelID, dispatch, duration, history, request, responseStatus]);

  return (
    <S_BookingDescTemplateContainer>
      <BookingView nav={<NavBar channelID={channelID} pathTo="calendar" emptyButton />}>
        <BookingInfo
          duration={duration}
          ticketLeft={ticketLeft}
          selectTime={selectTime}
          selectDate={selectDate}
          channelID={channelID}
        />
        <RequestCheck
          onBooking={handleOnBooking}
          ticketLeft={ticketLeft}
          comment={comment}
          setComment={setComment}
          check={check}
          setCheck={setCheck}
        />
      </BookingView>
    </S_BookingDescTemplateContainer>
  );
}

const S_BookingDescTemplateContainer = styled.div`
  position: relative;
`;

export default BookingDescTemplate;
