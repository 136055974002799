import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import DateHelper from '@common/utils/dateHelper';
import { DATE_FORMAT, TIME_FORMAT } from '@common/constants/index';

type Props = {
  profileImage: string;
  nickname: string;
  createdAt: string;
  content: string;
  isAuthor: boolean;
  id: number;
  onDeleteComment: (id: number) => void;
};

function CommentCard({
  profileImage,
  nickname,
  createdAt,
  content,
  isAuthor,
  id,
  onDeleteComment
}: Props) {
  const { t } = useTranslation('translation');

  return (
    <S_CommentCard>
      <S_ImageColumn>
        <S_ProfileImage imageSrc={profileImage} />
      </S_ImageColumn>

      <S_ContentsColumn>
        <S_InfoContents>
          <S_CommentInfo>
            <S_Nickname>{nickname}</S_Nickname>
            <S_CenterDiv />
            <S_Time>
              {createdAt &&
                (DateHelper.isToday(createdAt)
                  ? DateHelper.formatUTCtoKST(createdAt, TIME_FORMAT)
                  : DateHelper.formatUTCtoKST(createdAt, DATE_FORMAT))}
            </S_Time>
          </S_CommentInfo>
          {isAuthor && (
            <S_Text onClick={() => onDeleteComment(id)}>{t('str_delete', '삭제')}</S_Text>
          )}
        </S_InfoContents>
        <S_CommentContent>{content}</S_CommentContent>
      </S_ContentsColumn>
    </S_CommentCard>
  );
}

const S_CommentCard = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
`;

const S_InfoContents = styled.div`
  align-items: center;
  display: flex;
  font-size: ${({ theme }) => theme.fontSizeCaption1};
  height: 18px;
  justify-content: space-between;
  line-height: ${({ theme }) => theme.fontLineHeight};
  margin-bottom: 8px;
`;

const S_CommentInfo = styled.div`
  align-items: center;
  display: flex;
`;

const S_ImageColumn = styled.div`
  height: 40px;
  width: 40px;
`;

const S_ContentsColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  width: 100%;
`;

const S_Nickname = styled.span`
  color: ${({ theme }) => theme.grey900};
`;

const S_Time = styled.span`
  color: ${({ theme }) => theme.grey400};
`;

const S_Text = styled.div`
  color: ${({ theme }) => theme.grey500};
`;

const S_CenterDiv = styled.div`
  background-color: ${({ theme }) => theme.grey50};
  height: 2px;
  margin: 0 4px;
  width: 2px;
`;

const S_ProfileImage = styled.div<{
  imageSrc: string;
}>`
  background-color: ${({ theme }) => theme.grey400};
  background-image: ${({ imageSrc }) => imageSrc && `url(${imageSrc})`};
  background-position: center;
  background-repeat: none;
  background-size: cover;
  border-radius: 20px;
  height: 40px;
  overflow: hidden;
  width: 40px;
`;

const S_CommentContent = styled.div`
  background-color: ${({ theme }) => theme.grey50};
  border-radius: 8px;
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  line-height: ${({ theme }) => theme.fontLineHeight};
  padding: 8px 12px;
`;

export default CommentCard;
