import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { makeGetCurrentChannelSellerInfo } from '@common/redux/modules/channel';
import { PersonalSellerInfo, RegisteredSellerInfo } from './pages';

function SellerInfo() {
  const getCurrentChannelSellerInfo = useMemo(makeGetCurrentChannelSellerInfo, []);
  const currentChannelSellerInfo = useSelector(getCurrentChannelSellerInfo);
  const { type } = currentChannelSellerInfo;

  if (type === 'BUSINESS') {
    return <RegisteredSellerInfo sellerInfo={currentChannelSellerInfo} />;
  }

  return <PersonalSellerInfo sellerInfo={currentChannelSellerInfo} />;
}

export default SellerInfo;
