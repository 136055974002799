import styled, { css } from 'styled-components';

import CancelButton from './CancelButton';

type Props = {
  src: string;
  hasDeleteButton?: boolean;
  onDelete?: (id?: string) => void;
};

function Video({ src, hasDeleteButton, onDelete }: Props) {
  return (
    <S_IframeWrapper hasDeleteButton={hasDeleteButton}>
      <S_Iframe
        title="youtube"
        src={src}
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
      />
      {hasDeleteButton && onDelete && <CancelButton onDelete={onDelete} />}
    </S_IframeWrapper>
  );
}

const S_IframeWrapper = styled.div<{
  hasDeleteButton?: boolean;
}>`
  padding-bottom: 56.25%;
  position: relative;

  ${({ hasDeleteButton }) =>
    hasDeleteButton &&
    css`
      border-radius: 24px;
      overflow: hidden;
    `}
`;

const S_Iframe = styled.iframe`
  border: none;
  height: auto;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;
export default Video;
