import IconType from '../IconType';

const Menu = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.8"
        d="M5,7 L19,7 M5,12 L19,12 M5,17 L19,17"
      />
    </svg>
  );
};

export default Menu;
