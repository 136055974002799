import styled from 'styled-components';

import { Icon } from '@common/ui/components';

type Props = {
  onDelete: (id?: string) => void;
  id?: string;
};

function CancelButton({ onDelete, id }: Props) {
  return (
    <S_CancelButton onClick={() => onDelete(id)} type="button">
      <Icon iconName="ic_Xmark" colorCode="white" size={16} />
    </S_CancelButton>
  );
}

const S_CancelButton = styled.button`
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 8px;
  position: absolute;
  right: 8px;
  top: 8px;
  width: 32px;
`;

export default CancelButton;
