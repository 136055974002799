import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import {
  addPost,
  makeIsFetchedStatus,
  resetUrls,
  uploadImageToS3,
  uploadVideoToS3
} from '@common/redux/modules/socialCommunityPApp/reducers';
import {
  getIsFetched,
  getIsLoading,
  getResponseImageUrls,
  getResponseVideoUrl
} from '@common/redux/modules/socialCommunityPApp';
import {
  dataURItoBlob,
  extractVideoMetaInfo
} from '@common/redux/modules/socialCommunityPApp/service';
import { Form, Icon } from '@common/ui/components';
import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import { YoutubeLinkParser } from '@common/utils/index';
import { SOCIAL_POST_MAX_LENGTH, MEDIA_LIMIT, STORY_TYPES } from '@common/constants/index';

import { SubmitNavBar, SelectBar } from '../../components/molecules';
import {
  CancelButtonModal,
  ConfirmModal,
  TextContentArea,
  YoutubeLinkModal
} from '../../components/templates';
import { buildPostsPath } from '@routers/routes';

import { Contents } from './molecules';
import { Loading } from '@common/ui/components/Loading';

type SelectedFileType = {
  id: string;
  previewSrc: string;
  file?: File | Blob;
};

type FormField = {
  content: string;
};

function CreatePost() {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');
  const history = useHistory();

  const [channelId, setChannelId] = useState(0);
  const [channelCode, setChannelCode] = useState('');

  const [selectedOption, setSelectedOption] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<SelectedFileType[]>([]);

  const [isYoutubeLinkModalOpen, setYoutubeLinkModalOpen] = useState(false);
  const [isAlertModalOpen, setAlertModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isCloseModalOpen, setCloseModalOpen] = useState(false);

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);
  const s3ImageUrls = useSelector(getResponseImageUrls);
  const s3VideoUrl = useSelector(getResponseVideoUrl);

  const isFetched = useSelector(getIsFetched);
  const isLoading = useSelector(getIsLoading);

  const methods = useForm<FormField>();
  const contentValue = methods.watch('content');

  useEffect(
    () => () => {
      dispatch(resetUrls());
      dispatch(makeIsFetchedStatus(false));
    },
    []
  );

  useEffect(() => {
    if (isFetched && channelCode) {
      history.push(buildPostsPath(channelCode));
    }
  }, [history, channelCode, isFetched]);

  useEffect(() => {
    if (!currentChannel) return;

    setChannelId(currentChannel.id);
    setChannelCode(currentChannel.primitiveInvitationCode);
  }, [currentChannel]);

  useEffect(() => {
    const description = methods.getValues('content');

    if (selectedOption === STORY_TYPES.IMAGE && s3ImageUrls.length) {
      dispatch(
        addPost({
          channelId,
          content: description,
          storyResource: {
            type: selectedOption,
            imageSrcs: s3ImageUrls
          }
        })
      );
      return;
    }

    if (selectedOption === STORY_TYPES.VIDEO && s3VideoUrl && s3ImageUrls.length) {
      dispatch(
        addPost({
          channelId,
          content: description,
          storyResource: {
            type: selectedOption,
            imageSrcs: s3ImageUrls,
            videoSrc: s3VideoUrl
          }
        })
      );
    }
  }, [dispatch, s3ImageUrls, s3VideoUrl]);

  const handleUploadPost: SubmitHandler<FormField> = ({ content }, e) => {
    e?.target.reset();

    switch (selectedOption) {
      case STORY_TYPES.VIDEO: {
        const [videoFile] = selectedFiles;
        if (!videoFile.file) return;

        dispatch(uploadImageToS3({ name: uuid(), body: dataURItoBlob(videoFile.previewSrc) }));

        dispatch(
          uploadVideoToS3({
            name: uuid(),
            body: videoFile.file as File,
            format: (videoFile.file as File).name.slice(-3)
          })
        );
        break;
      }
      case STORY_TYPES.IMAGE: {
        const params = selectedFiles.map((el: SelectedFileType) => ({
          name: uuid(),
          body: el.file
        }));

        dispatch(uploadImageToS3(params));
        break;
      }
      case STORY_TYPES.YOUTUBE: {
        dispatch(
          addPost({
            channelId,
            content,
            storyResource: {
              type: selectedOption,
              youtubeSrc: selectedFiles[0].id
            }
          })
        );
        break;
      }
      default:
        dispatch(addPost({ content, channelId, storyResource: { type: STORY_TYPES.PLAIN } }));
    }
  };

  const handleDeleteResource = (id?: string) => {
    if (selectedOption === STORY_TYPES.VIDEO || selectedOption === STORY_TYPES.YOUTUBE) {
      setSelectedFiles([]);
      return;
    }

    const filteredFiles = selectedFiles.filter((file) => file.id !== id);
    setSelectedFiles(filteredFiles);
  };

  const handleYoutubeLink = ({ youtubeLink }: any) => {
    if (!YoutubeLinkParser.getId(youtubeLink)) {
      setAlertMessage(t('str_err_993', '유효하지 않은 youTube 주소입니다.'));
      setAlertModalOpen(true);
      return;
    }

    setSelectedFiles([
      {
        id: youtubeLink,
        previewSrc: YoutubeLinkParser.getEmbedUrl(youtubeLink)
      }
    ]);
    setSelectedOption(STORY_TYPES.YOUTUBE);
    setYoutubeLinkModalOpen(false);
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const { files } = e.target;

    if (!files?.length) return;

    if (type === STORY_TYPES.VIDEO) {
      const [file] = files;
      const { duration, dataUrl } = await extractVideoMetaInfo(file);

      if (duration > MEDIA_LIMIT.VIDEO_MAX_DURATION) {
        setAlertMessage(
          t('str_err_991', {
            defaultValue: '영상은 최대 1분 분량까지 업로드할 수 있습니다.({{errCode}})',
            errCode: 'err'
          })
        );
        setAlertModalOpen(true);
        return;
      }

      if (file.size >= MEDIA_LIMIT.VIDEO_MAX_SIZE) {
        setAlertMessage(
          t('str_err_992', {
            defaultValue: '영상은 최대 30MB까지 업로드할 수 있습니다.({{errCode}})',
            errCode: 'err'
          })
        );
        setAlertModalOpen(true);
        return;
      }

      setSelectedFiles([{ id: uuid(), previewSrc: dataUrl, file }]);
      setSelectedOption(type);
      return;
    }

    if (type === STORY_TYPES.IMAGE) {
      if (selectedFiles.length + files.length > MEDIA_LIMIT.IMAGE_MAX_COUNT) {
        setAlertMessage(
          t('str_err_989', {
            defaultValue: '이미지는 최대 10개까지 업로드할 수 있습니다.({{errCode}})',
            errCode: 'err'
          })
        );
        setAlertModalOpen(true);
        return;
      }

      const isImageSizeOver = [...files].some((file) => file.size >= MEDIA_LIMIT.IMAGE_MAX_SIZE);
      if (isImageSizeOver) {
        setAlertMessage(
          t('str_err_990', {
            defaultValue: '이미지는 최대 10MB까지 업로드 할 수 있습니다.({{errCode}})',
            errCode: 'err'
          })
        );
        setAlertModalOpen(true);
        return;
      }

      const extractedImages = [...files].map((file) => ({
        id: uuid(),
        previewSrc: URL.createObjectURL(file),
        file
      }));

      setSelectedFiles([...selectedFiles, ...extractedImages]);
      setSelectedOption(type);
    }
  };

  const handleCancelPost = () => {
    setCloseModalOpen(false);

    history.goBack();
  };

  const handleBackToPostsPage = () => {
    if (!methods.getValues('content')) {
      history.goBack();
      return;
    }

    setCloseModalOpen(true);
  };

  return (
    <S_Wrapper>
      {isLoading && <Loading />}
      <div>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(handleUploadPost)}>
            <SubmitNavBar
              title={t('str_pg_title_papp_scommunity_create_post', '게시글 작성')}
              icon={
                <Icon iconName="ic_PaperPlane" colorCode={contentValue ? 'blue500' : 'grey400'} />
              }
              disabled={!contentValue}
              onClose={handleBackToPostsPage}
            />

            <S_EmptyNavBar />

            <TextContentArea
              name="content"
              placeholder={t('str_86', '내용을 입력해주세요 ....')}
              maxLength={SOCIAL_POST_MAX_LENGTH}
            />
          </Form>
        </FormProvider>

        <SelectBar
          handleModal={() => setYoutubeLinkModalOpen(true)}
          handleFileChange={handleFileChange}
        />
      </div>

      <Contents type={selectedOption} onDelete={handleDeleteResource} files={selectedFiles} />

      {isYoutubeLinkModalOpen && (
        <YoutubeLinkModal
          isOpen={isYoutubeLinkModalOpen}
          onSubmit={handleYoutubeLink}
          onClose={() => setYoutubeLinkModalOpen(false)}
        />
      )}

      <ConfirmModal
        isOpen={isAlertModalOpen}
        onClick={() => setAlertModalOpen(false)}
        onClose={() => setAlertModalOpen(false)}
        message={alertMessage}
      />

      <CancelButtonModal
        isOpen={isCloseModalOpen}
        onClick={handleCancelPost}
        onClose={() => setCloseModalOpen(false)}
        message={t(
          'str_85',
          '이전 화면으로 돌아가시겠습니까?\n(입력 중인 내용은 보존되지 않습니다.)'
        )}
      />
    </S_Wrapper>
  );
}

const S_EmptyNavBar = styled.div`
  height: 56px;
`;

const S_Wrapper = styled.div`
  height: 100%;
`;

export default CreatePost;
