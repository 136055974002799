import { call, put, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { UserRepository } from '../../../repositories';
import { fetchCurrentUser } from './reducers';
import { digestResponse } from '../../middlewares/response';

export function* watchFetchCurrentUser(action: any) {
  const { type, callerType } = action;
  const response: AxiosResponse = yield call(UserRepository.me);

  yield put(digestResponse(response, callerType || type));
}

function* watch() {
  yield takeEvery(fetchCurrentUser, watchFetchCurrentUser);
}

export default watch;
