import IconType from '../IconType';

const PageSizeReduce = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M9.8488,13.3593 C10.2628,13.3593 10.5988,13.6953 10.5988,14.1093 L10.5988,14.1093 L10.5988,20.5113 C10.5988,20.9253 10.2628,21.2613 9.8488,21.2613 C9.4348,21.2613 9.0988,20.9253 9.0988,20.5113 L9.0988,20.5113 L9.0988,15.9203 L4.1548,20.8643 C4.0088,21.0103 3.8168,21.0843 3.6248,21.0843 C3.4328,21.0843 3.2408,21.0103 3.0948,20.8643 C2.8018,20.5713 2.8018,20.0963 3.0948,19.8033 L3.0948,19.8033 L8.0378,14.8593 L3.4478,14.8593 C3.0338,14.8593 2.6978,14.5233 2.6978,14.1093 C2.6978,13.6953 3.0338,13.3593 3.4478,13.3593 L3.4478,13.3593 Z M14.1499,2.7396 C14.5639,2.7396 14.8999,3.0756 14.8999,3.4896 L14.8999,3.4896 L14.8999,8.0816 L19.5029,3.4776 C19.7959,3.1846 20.2699,3.1846 20.5629,3.4776 C20.8559,3.7706 20.8559,4.2456 20.5629,4.5386 L20.5629,4.5386 L15.9609,9.1416 L20.5519,9.1416 C20.9659,9.1416 21.3019,9.4776 21.3019,9.8916 C21.3019,10.3056 20.9659,10.6416 20.5519,10.6416 L20.5519,10.6416 L14.1499,10.6416 C13.7359,10.6416 13.3999,10.3056 13.3999,9.8916 L13.3999,9.8916 L13.3999,3.4896 C13.3999,3.0756 13.7359,2.7396 14.1499,2.7396 Z"
      />
    </svg>
  );
};

export default PageSizeReduce;
