import styled from 'styled-components';

import { RatioImage, Icon } from '@common/ui/components';
import { Image } from '../../../components/atoms';

type Props = {
  src: string[] | string;
};

function Thumbnail({ src }: Props) {
  return (
    <S_Thumbnail>
      {typeof src === 'string' && (
        <S_MediaThumbnailBox>
          <RatioImage ratio={16 / 9} src={src} />
          <S_Icon fill iconName="ic_VideoPlayCircle" colorCode="white" size={48} />
        </S_MediaThumbnailBox>
      )}

      {typeof src === 'object' && <Image imageSrcs={src} />}
    </S_Thumbnail>
  );
}

const S_Thumbnail = styled.div`
  position: relative;
`;

const S_MediaThumbnailBox = styled.div`
  padding: 0 24px;
`;

const S_Icon = styled(Icon)`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export default Thumbnail;
