import { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import { PurchaseProduct } from './pages';
import Icon from '@common/ui/components/Icon/Icon';

import styled from 'styled-components';
import formatCurrency from '@common/utils/formatCurrency';
import { ProfitableClient } from '@common/profitable-js/src';

const {
  REACT_APP_API_HOST_WITHOUT_API,
  REACT_APP_PUBL_PAY_API_KEY,
  REACT_APP_PUBL_PAY_API_HOST,
  REACT_APP_KEY_ACCESS_TOKEN
} = process.env;

const CONFIG = {
  merchantHost: REACT_APP_API_HOST_WITHOUT_API, // 환경변수1
  publPayHost: REACT_APP_PUBL_PAY_API_HOST, // 환경번수2
  apiKey: REACT_APP_PUBL_PAY_API_KEY, // 환경변수3
  merchantTokenKey: REACT_APP_KEY_ACCESS_TOKEN // 환경변수4
};

const ORDER = {
  titleStr: 'str_1263',
  title: '상품을 주문합니다.',
  descriptionStr: 'str_1266',
  description: '잠시 후 주문서 창이 표시됩니다.\n표시되지 않으면 아래 버튼을 눌러주세요.',
  buttonTitleStr: 'str_1267',
  buttonTitle: '$t(str_order)하기'
};

const SUCCESS = {
  titleStr: 'str_1293',
  title: '$t(str_payment)가 완료되었습니다.',
  descriptionStr: 'str_1295',
  description: '창을 닫고 이전 페이지로 돌아가\n주문을 완료해 주세요.',
  buttonTitleStr: 'str_1297',
  buttonTitle: '창 닫기'
};

const PENDING = {
  titleStr: 'str_1268',
  title: '상품을 주문합니다.',
  descriptionStr: 'str_1269',
  description: '정상적으로 작동되지 않으면\n처음부터 다시 주문을 진행해 주세요.',
  buttonTitleStr: 'str_1297',
  buttonTitle: '창 닫기'
};

const FAIL = {
  titleStr: 'str_1294',
  title: '$t(str_payment)에 실패하였습니다.',
  descriptionStr: 'str_1296',
  description: '정상적으로 작동되지 않으면\n처음부터 다시 주문을 진행해 주세요.',
  buttonTitleStr: 'str_1297',
  buttonTitle: '창 닫기'
};

const initialState = {
  systemInvoice: {
    name: '',
    price: {
      amount: 0,
      currency: ''
    }
  },
  isFail: false,
  isSuccess: false,
  isPending: false,
  strKeys: ORDER
};

async function fetchOrder({
  siid,
  onTry,
  onSuccess,
  onFail
}: {
  siid: string;
  onTry: (invoide: any) => void;
  onSuccess: () => void;
  onFail: () => void;
}) {
  const profitableClient = new ProfitableClient({ ...CONFIG });

  try {
    const fetchedSystemInvoice = await profitableClient.fetchSystemInvoice(siid);

    onTry(fetchedSystemInvoice);
    await waitFor(2000);

    await profitableClient.authorize('/MERCHANT', '/channel-plan-products');
    const order = await profitableClient.requestOrder(fetchedSystemInvoice);
    const systemReceipt = await profitableClient.createPopup(order);

    if (['PAID', 'RECURRING_DELEGATED'].includes(systemReceipt.status)) {
      onSuccess();
      return;
    }
  } catch (error) {
    onFail();
  }
}

function PurchaseProductContainer() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { t } = useTranslation('translation');
  const history = useHistory();
  const { siid }: { siid: string } = useParams();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const invitationCode = query.get('ret');

  const { strKeys, systemInvoice, isFail, isSuccess, isPending } = state;

  useEffect(() => {
    async function fetch() {
      await fetchOrder({
        siid,
        onTry: (invoice: any) => dispatch({ payload: invoice, type: 'PAY_TRY' }),
        onSuccess: () => dispatch({ type: 'PAY_SUCCESS' }),
        onFail: () => dispatch({ type: 'PAY_FAIL' })
      });
    }

    fetch();
  }, []);

  async function handleClickButton() {
    if (isFail) {
      history.push(`/channels/${invitationCode}`);
      return;
    }

    if (isSuccess) {
      history.push(`/channels/${invitationCode}`);
      return;
    }

    if (!isPending) {
      await fetchOrder({
        siid,
        onTry: (invoice: any) => dispatch({ payload: invoice, type: 'PAY_TRY' }),
        onSuccess: () => dispatch({ type: 'PAY_SUCCESS' }),
        onFail: () => dispatch({ type: 'PAY_FAIL' })
      });

      return;
    }
  }

  return (
    <PurchaseProduct
      title={t(strKeys.titleStr, strKeys.title)}
      titleIcon={
        isFail ? (
          <S_IconWrapper_Warning>
            <Icon iconName="ic_Report" colorCode="red500" size={72} />
          </S_IconWrapper_Warning>
        ) : (
          <S_IconWrapper_Primary>
            <Icon iconName="ic_Payment" fill colorCode="white" size={48} />
          </S_IconWrapper_Primary>
        )
      }
      description={t(strKeys.descriptionStr, strKeys.description)}
      buttonTitle={isPending ? '' : t(strKeys.buttonTitleStr, strKeys.buttonTitle)}
      productName={systemInvoice.name}
      productPrice={t('str_pp_price', {
        price: formatCurrency(Number(systemInvoice.price.amount)),
        defaultValue: `{{price}}원`
      })}
      onClick={handleClickButton}
    />
  );
}

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'PAY_TRY':
      return {
        systemInvoice: action.payload,
        isFail: false,
        isSuccess: false,
        isPending: true,
        strKeys: PENDING
      };
    case 'PAY_SUCCESS':
      return {
        ...state,
        isFail: false,
        isSuccess: true,
        isPending: false,
        strKeys: SUCCESS
      };
    case 'PAY_FAIL':
      return {
        ...state,
        isFail: true,
        isSuccess: false,
        isPending: false,
        strKeys: FAIL
      };
    default:
      return {
        ...initialState
      };
  }
}

function waitFor(delay: number) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

const S_IconWrapper_Primary = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 72px;
  width: 72px;
  margin: auto;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.blue500};
`;

const S_IconWrapper_Warning = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 72px;
  width: 72px;
  margin: auto;
  margin-bottom: 20px;
`;

export default PurchaseProductContainer;
