import React from 'react';

import styled from 'styled-components';

type Props = {
  icon: React.ReactNode;
  label: string;
  fieldName: string;
  type?: string;
  accept?: string;
  handleModal?: () => void;
  handleFile?: (e: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  isModalButton?: boolean;
  value: string;
};

function SelectOption({
  icon,
  label,
  fieldName,
  type = 'file',
  accept,
  handleModal,
  handleFile,
  isModalButton = false,
  value
}: Props) {
  return (
    <>
      <S_SelectOption htmlFor={fieldName} onClick={handleModal}>
        {icon}
        <S_Text>{label}</S_Text>
      </S_SelectOption>

      {!isModalButton && (
        <S_InvisibleInput
          id={fieldName}
          type={type}
          accept={accept ? `${accept}/*` : 'none'}
          multiple
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e && handleFile) {
              handleFile(e, value);
            }
          }}
        />
      )}
    </>
  );
}

const S_SelectOption = styled.label`
  align-items: center;
  display: flex;
  margin-right: 24px;
`;

const S_Text = styled.span`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeCaption1};
  margin-left: 4px;
`;

const S_InvisibleInput = styled.input`
  display: none;
`;

export default SelectOption;
