import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CheckBookingAvailable } from '@common/redux/modules/booking/reducers';
import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import { Button } from '@common/ui/components';
import styled, { css } from 'styled-components';

import { buildBookingStationPath } from '@routers/routes';
import { EventText } from '../atoms';

type EventProps = {
  bookingAbailable: boolean;
  id: string;
  duration: number;
  title: string;
  handleSelectTime: (current: { id: string; title: string }) => void;
  channelID: string;
};

function Event({ bookingAbailable, title, id, duration, handleSelectTime, channelID }: EventProps) {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const history = useHistory();

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const handleButtonGoToDesc = () => {
    currentChannel && dispatch(CheckBookingAvailable({ id, channelId: currentChannel.id }));
    pushBookingDesc();
  };

  const pushBookingDesc = () => {
    history.push(buildBookingStationPath(channelID), { id, duration });
  };

  return (
    <S_EventContainer
      bookingAbailable={bookingAbailable}
      onClick={() => {
        handleSelectTime({ id, title });
      }}
    >
      <EventText time={title} bookingAbailable={bookingAbailable} duration={duration} />
      {bookingAbailable && (
        <Button
          label={t('str_papp_cbooking_rsv_do', '예약하기')}
          size="regular"
          onClick={handleButtonGoToDesc}
        />
      )}
    </S_EventContainer>
  );
}

const SelectableStyle = css`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const S_EventContainer = styled.ul<{ bookingAbailable: boolean }>`
  border-bottom: solid 1px ${({ theme }) => theme.grey100};
  display: flex;
  flex-direction: column;
  font-stretch: normal;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  height: 80px;
  justify-content: center;
  line-height: ${({ theme }) => theme.fontLineHeight};
  list-style: none;
  padding: 0;
  text-align: left;
  ${({ bookingAbailable }) => bookingAbailable && SelectableStyle};
`;

export default Event;
