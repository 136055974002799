import { Icon, RatioImage } from '@common/ui/components';
import DateHelper from '@common/utils/dateHelper';
import styled from 'styled-components';

type Props = {
  thumbnailSrc: string;
  createdAt: string;
  totalCount: number;
  title: string;
};

function PlaylistCardItem({ thumbnailSrc, createdAt, title, totalCount }: Props) {
  return (
    <S_PlaylistCardItem>
      <S_ThumbnailBox>
        <RatioImage ratio={16 / 9} src={thumbnailSrc} />
        <S_Overlay>
          <Icon iconName="ic_VideoPlaylist" colorCode="white" size={16} fill />
          {totalCount}
        </S_Overlay>
      </S_ThumbnailBox>
      <S_Contents>
        <span className="playlistTitle">{title}</span>
        <span className="createdDate">{DateHelper.fromNow(createdAt)}</span>
      </S_Contents>
    </S_PlaylistCardItem>
  );
}

const S_ThumbnailBox = styled.div`
  margin-right: 12px;
  min-width: 160px;
  position: relative;
  width: 100%;
`;

const S_PlaylistCardItem = styled.div`
  display: flex;
  flex: 1;
  padding: 24px;
`;

const S_Overlay = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0 12px 12px 0;
  bottom: 0;
  color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.fontSizeCaption1};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  gap: 1px;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 30%;
`;

const S_Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 155px;
  text-align: left;
  width: 100%;

  .playlistTitle {
    color: ${({ theme }) => theme.grey900};
    font-size: ${({ theme }) => theme.fontSizeBody2};
    font-weight: ${({ theme }) => theme.fontWeightBold};
    line-height: ${({ theme }) => theme.fontLineHeight};
    overflow-wrap: break-word;
    word-break: break-word;
  }

  .createdDate {
    color: ${({ theme }) => theme.grey400};
    font-size: ${({ theme }) => theme.fontSizeCaption1};
  }
`;

export default PlaylistCardItem;
