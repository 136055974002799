import styled from 'styled-components';

import { CancelButton } from '../atoms';

type Props = {
  src: string;
  handleDelete: (value?: string) => void;
  id: string;
};

function PreviewCard({ src, handleDelete, id }: Props) {
  return (
    <S_PreviewCard image={src}>
      <CancelButton id={id} onDelete={handleDelete} />
    </S_PreviewCard>
  );
}

const S_PreviewCard = styled.div<{
  image: string;
}>`
  background-image: ${({ image }) => `url(${image})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px;
  margin-bottom: 24px;
  overflow: hidden;
  padding-top: ${100 / (1 / 1)}%;
  position: relative;
`;

export default PreviewCard;
