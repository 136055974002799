import IconType from '../IconType';

const Setting = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15.5625,12 C15.5625,13.967 13.9675,15.562 12.0005,15.562 C10.0325,15.562 8.4375,13.967 8.4375,12 C8.4375,10.032 10.0325,8.438 12.0005,8.438 C13.9675,8.438 15.5625,10.032 15.5625,12 Z M12.8155,4.765 C12.5415,4.734 11.4535,4.735 11.1845,4.765 L9.9185,3.473 C9.6195,3.167 9.1515,3.103 8.7805,3.317 L6.0905,4.87 C5.7195,5.084 5.5415,5.522 5.6555,5.934 L6.1425,7.676 L6.1425,7.676 C5.9805,7.896 5.4365,8.837 5.3265,9.089 L3.5745,9.539 C3.1605,9.645 2.8705,10.019 2.8705,10.447 L2.8705,13.553 C2.8705,13.981 3.1605,14.355 3.5745,14.461 L5.3265,14.911 C5.4365,15.164 5.9815,16.105 6.1425,16.324 L5.6555,18.066 C5.5415,18.478 5.7195,18.916 6.0905,19.13 L8.7805,20.683 C9.1515,20.897 9.6195,20.833 9.9185,20.527 L11.1845,19.235 C11.4585,19.266 12.5465,19.265 12.8155,19.235 L14.0815,20.527 C14.3805,20.833 14.8495,20.897 15.2195,20.683 L17.9105,19.13 C18.2805,18.916 18.4595,18.478 18.3445,18.066 L17.8575,16.324 L17.8585,16.324 C18.0205,16.104 18.6195,15.034 18.6735,14.911 L20.4255,14.461 C20.8395,14.355 21.1295,13.981 21.1295,13.553 L21.1295,10.447 C21.1295,10.019 20.8395,9.645 20.4255,9.539 L18.6735,9.089 C18.5645,8.838 17.9215,7.763 17.8575,7.676 L18.3445,5.934 C18.4595,5.522 18.2805,5.084 17.9105,4.87 L15.2195,3.317 C14.8495,3.103 14.3805,3.167 14.0815,3.473 L12.8155,4.765 Z"
      />
    </svg>
  );
};

export default Setting;
