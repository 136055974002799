import JWT from '@common/utils/jwt';

import api, { getEndpointSet } from '../services/api';
import { PostsQueryParams, PaginationPayload } from './noticePApp.types';

const { FIND_ALL_POSTS, FIND_POST_BY_ID } = getEndpointSet('NOTICE_PAPP', 'postable');

const { FIND_ALL_COMMENTS, CREATE_COMMENT, DELETE_COMMENT } = getEndpointSet(
  'NOTICE_PAPP',
  'comments'
);

function getHeaderOptions(id: number) {
  return {
    headers: {
      'x-publ-consumer-id': JWT.getChannelHeader(id, 'A00002')
    }
  };
}

class NoticePAppRepository {
  static findAllPosts(queryParams: PostsQueryParams, channelId: number) {
    return api.get(FIND_ALL_POSTS, {}, queryParams, getHeaderOptions(channelId));
  }

  static findPost(postId: string, channelId: number) {
    return api.get(FIND_POST_BY_ID, { postId }, {}, getHeaderOptions(channelId));
  }

  static findAllComments(postId: string, queryParams: PaginationPayload, channelId: number) {
    return api.get(FIND_ALL_COMMENTS, { postId }, queryParams, getHeaderOptions(channelId));
  }

  static createComment(id: string, body: { content: string }, channelId: number) {
    const channelProfile = JWT.getChannelProfile(channelId);
    if (!channelProfile) return;
    const { nickname, imageSrc } = channelProfile;

    const postBody = {
      author: nickname,
      authorThumbnailSrc: imageSrc,
      ...body
    };

    return api.post(CREATE_COMMENT, { postId: id }, postBody, getHeaderOptions(channelId));
  }

  static deleteComment(queryParams: { postId: string; commentId: number }, channelId: number) {
    return api.remove(DELETE_COMMENT, queryParams, getHeaderOptions(channelId));
  }
}

export default NoticePAppRepository;
