import { createReducer, createAction } from '@reduxjs/toolkit';

import { builderFactory } from '@common/utils/reduxHelper';

import {
  InitialStateType,
  FetchListQueryParamTypes,
  PostIdQueryParamTypes,
  CreatePostPayload,
  FetchCommentsQueryParamTypes,
  CreateCommentPayload,
  DeleteCommentPathVariables,
  UploadImagePayload,
  UploadVideoPayload
} from './types';

const prefix = '@posts';

export const fetchList = createAction<FetchListQueryParamTypes>(`${prefix}/FETCH_LIST`);
export const fetchDetail = createAction<PostIdQueryParamTypes>(`${prefix}/FETCH_DETAIL`);
export const addPost = createAction<CreatePostPayload>(`${prefix}/ADD_POST`);
export const editPost = createAction<any>(`${prefix}/EDIT_POST`);
export const removePost = createAction<{ postId: string | number; channelId: number }>(
  `${prefix}/REMOVE_POST`
);

export const fetchComments = createAction<FetchCommentsQueryParamTypes>(`${prefix}/FETCH_COMMENTS`);
export const addComment = createAction<CreateCommentPayload>(`${prefix}/ADD_COMMENT`);
export const deleteComment = createAction<DeleteCommentPathVariables>(`${prefix}/DELETE_COMMENT`);

export const cleanUpPosts = createAction(`${prefix}/CLEAN_UP_POSTS`);
export const cleanUpPost = createAction(`${prefix}/CLEAN_UP_POST`);

export const uploadImageToS3 = createAction<UploadImagePayload[] | UploadImagePayload>(
  `${prefix}/UPLOAD_IMAGE_TO_S3`
);
export const uploadVideoToS3 = createAction<UploadVideoPayload>(`${prefix}/UPLOAD_VIDEO_TO_S3`);
export const resetUrls = createAction(`${prefix}/RESET_URLS`);

export const likePost = createAction<{
  channelId: number;
  postId: number | string;
  isLiked: boolean;
}>(`${prefix}/LIKE_POST`);
export const dislikePost = createAction<{
  channelId: number;
  postId: number | string;
  isDisliked: boolean;
}>(`${prefix}/DISLIKE_POST`);
export const makeIsFetchedStatus = createAction<boolean>(`${prefix}/MAKE_IS_FETCHED_STATUS`);
export const increaseViewCount = createAction<any>(`${prefix}/INCREASE_VIEW_COUNT`);

const initialState: InitialStateType = {
  isLoading: true,
  error: null,
  posts: [],
  post: null,
  totalPostsCount: 0,
  comments: [],
  totalCommentsCount: 0,
  responseImageUrls: [],
  responseVideoUrl: '',
  isCommentCreated: false,
  isCommentDeleted: false,
  isFetched: false
};

const reducer = createReducer(initialState, (builder) => {
  builderFactory(builder, [
    fetchDetail,
    fetchList,
    addPost,
    editPost,
    removePost,
    fetchComments,
    addComment,
    deleteComment,
    uploadVideoToS3,
    uploadImageToS3
  ])
    .addCase(`${fetchList}_COMPLETED`, (state, action: any) => {
      const { posts, pagination } = action.payload.data.data;

      state.posts = [...state.posts, ...posts];
      state.totalPostsCount = pagination.total;
    })
    .addCase(`${fetchDetail}_COMPLETED`, (state, action: any) => {
      const { post } = action.payload.data.data;

      state.post = post;
    })
    .addCase(`${addPost}_COMPLETED`, (state) => {
      state.isFetched = true;
    })
    .addCase(`${editPost}_COMPLETED`, (state) => {
      state.isFetched = true;
    })
    .addCase(`${removePost}_COMPLETED`, (state, action: any) => {
      const targetId = action.payload.config.pathVariables.postId;
      const filteredPosts = state.posts.filter((post) => post.id !== targetId);

      state.posts = filteredPosts;
    })
    .addCase(`${fetchComments}_COMPLETED`, (state, action: any) => {
      const { comments, pagination } = action.payload.data.data;

      state.totalCommentsCount = pagination.total;

      if (state.isCommentCreated) {
        state.comments = comments;
        state.isCommentCreated = false;
        return;
      }

      if (state.isCommentDeleted) {
        state.isCommentDeleted = false;
        return;
      }

      state.comments = [...state.comments, ...comments];
    })
    .addCase(`${addComment}_COMPLETED`, (state) => {
      state.isCommentCreated = true;
    })
    .addCase(`${deleteComment}_COMPLETED`, (state, action: any) => {
      const targetId = action.payload.config.pathVariables.commentId;
      const filteredComments = state.comments.filter((comment) => comment.id !== targetId);

      state.comments = filteredComments;
      state.isCommentDeleted = true;
    })
    .addCase(`${uploadImageToS3}_COMPLETED`, (state, action: any) => {
      if (Array.isArray(action.payload)) {
        state.responseImageUrls = action.payload;
        return;
      }

      state.responseImageUrls = [action.payload];
    })
    .addCase(`${uploadVideoToS3}_COMPLETED`, (state, action: any) => {
      state.responseVideoUrl = action.payload;
    })
    .addCase(cleanUpPosts, (state) => {
      state.posts = [];
    })
    .addCase(cleanUpPost, (state) => {
      state.post = null;
      state.comments = [];
    })
    .addCase(resetUrls, (state) => {
      state.responseImageUrls = [];
      state.responseVideoUrl = '';
    })
    .addCase(likePost, (state) => {
      state.isFetched = true;
    })
    .addCase(dislikePost, (state) => {
      state.isFetched = true;
    })
    .addCase(makeIsFetchedStatus, (state, action: any) => {
      state.isFetched = action.payload;
    })
    .addMatcher(
      (action) => action.type.startsWith(prefix) && action.type.endsWith('_COMPLETED'),
      (state) => {
        state.isLoading = false;
      }
    )
    .addMatcher(
      (action) => action.type.startsWith(prefix) && action.type.endsWith('_FAILED'),
      (state, action) => {
        state.isLoading = false;
        state.error = action.payload.data.message;
      }
    );
});

export default reducer;
