import styled from 'styled-components';

type TitleProps = {
  text: string;
  align?: string;
  margin?: string;
};

type TitleStyleProps = {
  align?: string;
  margin?: string;
  buttonLabel?: string;
};

function Title({ text, align, margin }: TitleProps) {
  return (
    <S_TitleContainer align={align} margin={margin}>
      {text}
    </S_TitleContainer>
  );
}

const S_TitleContainer = styled.div<TitleStyleProps>`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeH2};
  font-stretch: normal;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  justify-content: space-between;
  margin: 24px;
  margin: ${({ margin }) => {
    if (margin) {
      return margin;
    }
    return '24px';
  }};
  text-align: ${({ align }) => align};
`;

export default Title;
