import { put, call, select } from 'redux-saga/effects';

import { ChatRepository } from '../../../../repositories';
import { digestResponse } from '../../../middlewares/response';
import DateHelper from '../../../../utils/dateHelper';
import {
  sendingNewDm,
  addRecentDms,
  addPreviousDms,
  removeDm,
  readDm,
  fetchCounterpart,
  fetchCounterpartProfile,
  EVENT,
  fetchPreviousDms,
  resendDm
} from '../reducers';
import { getCurrentCounterpartProfileId } from '../selectors';
import { isOK, makeDefaultDm, getMyProfileIdByToken } from '../service';
import { head } from '../../../../utils/fpHelper';
import {
  CounterpartProfilesResponse,
  CounterpartsResponse,
  Dm,
  RecentDmsResponse,
  SendingNewDmResponse
} from '../types';
import Socket from '../../../../services/socket';

export function* watchReadDm(action: ReturnType<typeof readDm>) {
  const { profileId, txid } = action.payload;

  yield call(ChatRepository.readDm, { profileId, txid });
}

export function* watchFetchCounterparts() {
  const response: CounterpartsResponse = yield call(ChatRepository.counterparts);

  yield put(
    digestResponse({ ...response, myProfileId: getMyProfileIdByToken() }, fetchCounterpart)
  );

  if (isOK(response)) {
    const ids = response.data.data.recentDms.map((dm) => dm.counterpartProfileId).join(',');
    const profileResponse: CounterpartProfilesResponse = yield call(
      ChatRepository.counterpartProfiles,
      { ids }
    );
    yield put(digestResponse(profileResponse, fetchCounterpartProfile));
  }
}

export function* watchFetchPreviousDms(action: ReturnType<typeof fetchPreviousDms>) {
  const {
    type,
    payload: { profileId, txid, initialFetch = false }
  } = action;

  const response: RecentDmsResponse = yield call(ChatRepository.recentDms, { profileId }, { txid });
  yield put(digestResponse(response, type));

  if (isOK(response)) {
    yield put(
      addPreviousDms({
        counterpartProfileId: profileId,
        messages: response.data.data.dms.sort((a, b) =>
          DateHelper.sortByNewest(a.createdAt, b.createdAt)
        ),
        initialFetch,
        myProfileId: getMyProfileIdByToken()
      })
    );
  }
}

// @ts-ignore
export function* watchFetchAllRecentDms(latestTxid: string, prevDms: Dm[]) {
  if (!latestTxid) return prevDms;

  const currentCounterpartProfileId: number = yield select(getCurrentCounterpartProfileId);
  const response: RecentDmsResponse = yield call(
    ChatRepository.recentDms,
    { profileId: currentCounterpartProfileId },
    { txid: latestTxid, direction: 'NEXT' }
  );

  let dms = prevDms;

  if (isOK(response)) {
    dms = response.data.data.dms.sort((a, b) => DateHelper.sortByNewest(a.createdAt, b.createdAt));

    const result: Dm[] = yield call(watchFetchAllRecentDms, head(dms)?.txid, dms.concat(prevDms));
    return result;
  }

  return dms;
}

export function* watchSendingNewDm(action: ReturnType<typeof sendingNewDm>) {
  const {
    type,
    payload: { profileId, message }
  } = action;

  const response: SendingNewDmResponse = yield call(ChatRepository.sendingNewDm, {
    profileId,
    message
  });
  const myProfileId = getMyProfileIdByToken();
  yield put(digestResponse(response, type));

  if (isOK(response)) {
    const { dm } = response.data.data;

    yield put(
      addRecentDms({
        counterpartProfileId: profileId,
        messages: [dm],
        myProfileId
      })
    );
  } else {
    yield put(
      addRecentDms({
        counterpartProfileId: profileId,
        messages: [makeDefaultDm(message, profileId, myProfileId)],
        myProfileId
      })
    );
  }
}

export function* watchAuthorize(socket: Socket) {
  yield call(
    socket.emit.bind(socket),
    EVENT.AUTH_USER,
    JSON.stringify({
      authorization: `Bearer ${localStorage.getItem(
        process.env.REACT_APP_KEY_ACCESS_TOKEN as string
      )}`
    })
  );
}

export function* watchResendDm(action: ReturnType<typeof resendDm>) {
  const {
    payload: { message, messageId, profileId }
  } = action;

  yield put(removeDm({ counterpartProfileId: profileId, messageId }));
  yield call(watchSendingNewDm, sendingNewDm({ message, profileId }));
}
