import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { PopupProvider } from '@common/ui/components/Popup';
import { SubmitButton } from '@common/ui/components';
import { Select } from './atoms';
import { NavBar } from '../../../Booking/components/molcules';
import { NavView } from '../../../Chatting/components/templates';

type props = {
  onClickNext: MouseEventHandler;
  methods: {
    register: Function;
    formState: { isValid: boolean };
  };
};

function SignUpCountryInfo({ onClickNext, methods }: props) {
  const { t: translate } = useTranslation('translation');
  const t = (key: string, defaultValue: string) => translate(key, defaultValue) as string;

  const { register } = methods;

  return (
    <NavView nav={<NavBar isBack />}>
      <PopupProvider>
        <Box>
          <S_Title>{t('str_select_country', '국가 정보 선택')}</S_Title>
          <S_InputWrapper>
            <Select
              name="country"
              isRequired
              register={register}
              placeholder={t('str_select_country', '국가 정보 선택')}
              options={[t('str_country_korea', '대한민국')]}
              defaultValue={t('str_country_korea', '대한민국')}
            />
          </S_InputWrapper>
          <S_SubmitButton>
            <SubmitButton onClick={onClickNext} full label={t('str_next', '다음')} />
          </S_SubmitButton>
        </Box>
      </PopupProvider>
    </NavView>
  );
}

const Box = styled.div`
  height: 100%;
  padding-left: 24px;
  padding-right: 24px;
  text-align: left;
  user-select: none;
`;

const S_Title = styled.div`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeH2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  margin-bottom: 24px;
  margin-top: 24px;
  text-align: left;
`;

const S_SubmitButton = styled.div`
  margin-top: 24px;
`;

const S_InputWrapper = styled.div`
  & > div {
    margin-top: 16px;
  }
`;

export default SignUpCountryInfo;
