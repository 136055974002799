/* eslint-disable jsx-a11y/label-has-associated-control */
import { FormProvider, useForm } from 'react-hook-form';

import { v4 as uuidv4 } from 'uuid';
import { Form, Icon, InputFieldButton, SubmitButton } from '@common/ui/components';
import styled from 'styled-components';
import { useEffect, useMemo, useState } from 'react';
import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import { useDispatch, useSelector } from 'react-redux';
import { makeGetChannelProfiles } from '@common/redux/modules/user';
import { fetchCurrentUser } from '@common/redux/modules/user/reducers';
import useModalDialog from '../../../../../common/hooks/useModalDialog';

import { Select } from '../../../../Account/pages/ModifyAccount/atoms';
import { useTranslation } from 'react-i18next';
import {
  fetchCurrentChannelProfile,
  updateProfile,
  uploadImageToS3
} from '@common/redux/modules/profile/reducers';
import {
  makeGetCurrentChannelProfile,
  makeGetIsProfileLoading,
  makeGetProfileError,
  makeGetS3UploadedImageUrl
} from '@common/redux/modules/profile';
import { useHistory } from 'react-router';

interface INicknameForm {
  nickname: string;
  imageSrc: string;
  gender: string;
  birthYear: number;
  birthDate: number;
  birthMonth: number;
}

/**
 * 채널 프로필 수정 폼
 */
function ChannelSettingProfileForm() {
  const { t } = useTranslation('translation');
  const history = useHistory();
  const dispatch = useDispatch();

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const getCurrentChannelProfile = useMemo(makeGetCurrentChannelProfile, []);
  const getChannelProfiles = useMemo(makeGetChannelProfiles, []);
  const getIsProfileLoading = useMemo(makeGetIsProfileLoading, []);
  const getS3UploadedImageUrl = useMemo(makeGetS3UploadedImageUrl, []);
  const getProfileError = useMemo(makeGetProfileError, []);

  const uploadedImageUrl = useSelector(getS3UploadedImageUrl);
  const channelProfiles = useSelector(getChannelProfiles);
  const currentChannel = useSelector(getCurrentChannel);
  const currentChannelProfile = useSelector(getCurrentChannelProfile);
  const profileError = useSelector(getProfileError);
  const isProfileLoading = useSelector(getIsProfileLoading);

  const [selectedImageFile, setSelectedImageFile] = useState<File | undefined>();
  const [uploadedProfileUrl, setUploadedProfileUrl] = useState<string>('');
  const [imageLoading, setImageLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const { ModalDialog, setIsModalDialogOpen, setModalDialogMessage } = useModalDialog();

  const methods = useForm<INicknameForm>({
    mode: 'onChange'
  });

  useEffect(() => {
    if (isSubmit && profileError) {
      setIsModalDialogOpen(true);
      setModalDialogMessage(
        t('str_err_1239', 'Error : 알 수 없는 에러가 발생하였습니다. 관리자에게 문의하세요 (500)')
      );

      return;
    }

    if (isSubmit) {
      setIsModalDialogOpen(true);
      setModalDialogMessage(t('str_919', '프로필 정보 수정이 완료되었습니다.'));
    }
  }, [profileError, isSubmit]);

  const {
    handleSubmit,
    register,
    watch,
    getValues,
    setValue,
    formState: { isValid }
  } = methods;

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, []);

  useEffect(() => {
    if (channelProfiles && currentChannel) {
      let channelProfileId = null;

      channelProfiles.forEach((eachProfile: any) => {
        if (eachProfile.channelId === currentChannel.id) {
          channelProfileId = eachProfile.id;
        }
      });

      if (channelProfileId) {
        dispatch(fetchCurrentChannelProfile(channelProfileId));
      }
    }
  }, [channelProfiles, currentChannel]);

  useEffect(() => {
    if (!isSubmit) return;

    try {
      if (!imageLoading && uploadedImageUrl !== '') {
        setUploadedProfileUrl(uploadedImageUrl);
        setImageLoading(true);
      }
    } catch (err) {
      alert(err);
    }
  }, [uploadedImageUrl]);

  function getGender(gender: string) {
    switch (gender) {
      case 'MALE':
        return t('str_gender_man', '남성');
      case 'FEMALE':
        return t('str_gender_woman', '여성');
      default:
        return null;
    }
  }

  useEffect(() => {
    const { nickname, birthYear, gender, imageSrc, birthDate, birthMonth } = currentChannelProfile;

    nickname &&
      setValue('nickname', nickname, {
        shouldValidate: true
      });
    birthYear &&
      birthYear !== 0 &&
      setValue('birthYear', birthYear, {
        shouldValidate: true
      });
    gender &&
      setValue('gender', getGender(gender) ?? '', {
        shouldValidate: true
      });
    imageSrc &&
      setValue('imageSrc', imageSrc, {
        shouldValidate: true
      });
    birthDate &&
      setValue('birthDate', birthDate, {
        shouldValidate: true
      });
    birthMonth &&
      setValue('birthMonth', birthMonth, {
        shouldValidate: true
      });
  }, [currentChannelProfile]);

  const nicknameValidation = {
    required: t('str_101', '필수 정보를 입력해 주세요.'),
    minLength: {
      value: 2,
      message: t('str_too_short', '너무 짧습니다.')
    },
    maxLength: {
      value: 12,
      message: t('str_934', '최소 2자, 최대 12자까지 입력 가능합니다.')
    },
    pattern: {
      value: /^[ㄱ-ㅎㅏ-ㅣ가-힣A-Za-z0-9]+$/,
      message: t('str_err_1016', '올바른 [[str_nickname]] 형식으로 입력되어야 합니다.')
    }
  };

  const isButtonDisabled = !isValid;
  const watchProfileThumbnail = watch('imageSrc');

  useEffect(() => {
    if (!isSubmit) return;
    try {
      if (!imageLoading && uploadedImageUrl !== '') {
        setUploadedProfileUrl(uploadedImageUrl);
        setImageLoading(true);
      }
    } catch (err) {
      alert(err);
    }
  }, [uploadedImageUrl]);

  useEffect(() => {
    if (!isSubmit) return;
    try {
      if (imageLoading && uploadedProfileUrl !== '') {
        modifyAccount();
      }
    } catch (err) {
      alert(err);
    }

    return function cleanup() {
      setImageLoading(false);
    };
  }, [imageLoading, uploadedProfileUrl]);

  const handleImageUpload = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    const fileInput = document.createElement('input');

    fileInput.type = 'file';
    fileInput.accept = 'image/png, image/jpeg, image/jpg';
    fileInput.addEventListener('change', inputListener);
    fileInput.click();

    async function inputListener(event: Event) {
      const file: File = (event.target as any).files[0];
      const url = URL.createObjectURL(file);

      const availableTypes = ['image/png', 'image/jpeg', 'image/jpg'];
      const isWrongType = !availableTypes.some((type) => type === file.type);

      if (isWrongType) {
        setIsModalDialogOpen(true);
        setModalDialogMessage(
          t('str_err_1175', {
            errCode: 'error',
            defaultValue: 'JPG, JPEG, PNG 파일을 업로드 할 수 있습니다. ({{errCode}})'
          })
        );

        return;
      }

      setValue('imageSrc', url, {
        shouldValidate: true
      });

      setSelectedImageFile(file);
    }
  };

  const handleProfileInfo = () => {
    setIsSubmit(true);
    if (selectedImageFile) {
      const params = { name: uuidv4(), body: selectedImageFile };
      dispatch(uploadImageToS3(params));
    } else {
      modifyAccount();
    }
  };

  const modifyAccount = () => {
    const { nickname, gender, birthYear, birthDate, birthMonth } = getValues();
    const editProfile = selectedImageFile
      ? {
          nickname,
          gender: getGender(gender),
          imageSrc: uploadedProfileUrl,
          birthYear: Number(birthYear) || null,
          birthDay: Number(birthDate) || null,
          birthMonth: Number(birthMonth) || null
        }
      : {
          nickname,
          gender: getGender(gender),
          birthYear: Number(birthYear) || null,
          birthDay: Number(birthDate) || null,
          birthMonth: Number(birthMonth) || null
        };

    if (currentChannelProfile && currentChannelProfile.id) {
      dispatch(
        updateProfile({
          id: currentChannelProfile.id,
          body: editProfile
        })
      );
    }
  };

  return (
    <S_Form>
      <ModalDialog onCloseModal={() => history.goBack()} />
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(handleProfileInfo)}>
          <S_InputWrapper>
            <S_ImageWrapper>
              {currentChannelProfile && watchProfileThumbnail && (
                <Image src={watchProfileThumbnail} alt="Publishing Kit" />
              )}

              {currentChannelProfile && !watchProfileThumbnail && (
                <Icon iconName="ic_PostSocial" size={64} colorCode="blue500" fill />
              )}
              <S_CameraIcon>
                <Icon
                  iconName="ic_Camera"
                  size={16}
                  colorCode="grey500"
                  fill
                  onClick={handleImageUpload}
                />
              </S_CameraIcon>
            </S_ImageWrapper>
            <InputFieldButton
              name="nickname"
              placeholder="닉네임"
              maxLength={12}
              validation={nicknameValidation}
              cancel
            />
            <Select
              name="gender"
              isRequired={false}
              register={register}
              placeholder={t('str_gender', '성별')}
              options={[
                t('str_gender_man', '남성') as string,
                t('str_gender_woman', '여성') as string
              ]}
              validation={{ required: false }}
            />
            <Select
              name="birthYear"
              isRequired={false}
              register={register}
              disabled={!!currentChannelProfile?.hasCertifiedIdentity}
              placeholder={t('str_birth_year', '출생 연도')}
              options={generateYearItems()}
              validation={{ required: false }}
              suffix={t('str_fm_birth_year', { year: '', defaultValue: '{{year}}년' })}
            />
            <Select
              name="birthMonth"
              isRequired={false}
              register={register}
              disabled={!!currentChannelProfile?.hasCertifiedIdentity}
              placeholder={t('str_birth_month', '출생월')}
              options={generateMonthItems()}
              validation={{ required: false }}
              suffix={t('str_fm_birth_month', { month: '', defaultValue: '{{month}}월' })}
            />
            <Select
              name="birthDate"
              isRequired={false}
              register={register}
              disabled={!!currentChannelProfile?.hasCertifiedIdentity}
              placeholder={t('str_birth_date', '출생일')}
              options={generateDateItems()}
              validation={{ required: false }}
              suffix={t('str_fm_birth_date', { date: '', defaultValue: '{{date}}일' })}
            />
          </S_InputWrapper>
          <S_SubmitButton>
            <SubmitButton
              full
              disabled={isButtonDisabled}
              label={isSubmit && isProfileLoading ? 'Loading' : t('str_39', '수정')}
            />
          </S_SubmitButton>
        </Form>
      </FormProvider>
    </S_Form>
  );
}

const generateYearItems = () => {
  const yearItems = [];
  const startYear = 1900;
  const lastYear = new Date().getFullYear();

  for (let i = lastYear; i >= startYear; i -= 1) {
    yearItems.push(i);
  }

  return yearItems;
};

const generateMonthItems = () => {
  const monthItems = [];
  const lastMonth = 12;

  for (let i = 1; i < lastMonth + 1; i += 1) {
    monthItems.push(i);
  }

  return monthItems;
};

const generateDateItems = () => {
  const monthItems = [];
  const lastMonth = 31;

  for (let i = 1; i < lastMonth + 1; i += 1) {
    monthItems.push(i);
  }

  return monthItems;
};

const S_Form = styled.div`
  background-color: white;
  height: 100%;
  padding-left: 24px;
  padding-right: 24px;
  text-align: left;
  user-select: none;
`;

const S_SubmitButton = styled.div`
  margin-top: 24px;
`;

const S_InputWrapper = styled.div`
  & > div {
    margin-top: 16px;
  }
`;

const Image = styled.img`
  border-radius: 9999px;
  overflow: hidden;
  position: relative;
  width: 96px;
`;

const S_ImageWrapper = styled.div`
  background-color: ${({ theme }) => theme.grey50};
  border-radius: 50%;
  display: flex;
  height: 96px;
  margin: auto;
  margin-bottom: 24px;
  margin-top: 24px;
  position: relative;
  width: 96px;

  & > svg {
    margin: auto;
  }
`;

const S_CameraIcon = styled.div`
  background-color: ${({ theme }) => theme.white};
  border: solid 1px ${({ theme }) => theme.grey100};
  border-radius: 50%;
  bottom: 0;
  display: flex;
  height: 32px;
  position: absolute;
  right: -10px;
  width: 32px;

  &:hover {
    background-color: ${({ theme }) => theme.grey50};
    cursor: pointer;
  }
  & > svg {
    margin: auto;
  }
`;

export default ChannelSettingProfileForm;
