import IconType from '../IconType';

const Filter = ({ color, size, ...rest }: IconType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      {...rest}
    >
      <defs>
        <path
          id="line-a"
          d="M8,17 C8.55228475,17 9,17.4477153 9,18 C9,18.5522847 8.55228475,19 8,19 L8,19 L3,19 C2.44771525,19 2,18.5522847 2,18 C2,17.4477153 2.44771525,17 3,17 L3,17 Z M14,11 C14.5522847,11 15,11.4477153 15,12 C15,12.5522847 14.5522847,13 14,13 L14,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 L3,11 Z M21,5 C21.5522847,5 22,5.44771525 22,6 C22,6.55228475 21.5522847,7 21,7 L21,7 L3,7 C2.44771525,7 2,6.55228475 2,6 C2,5.44771525 2.44771525,5 3,5 L3,5 Z"
        />
      </defs>
      <use fill={color} fillRule="evenodd" xlinkHref="#line-a" />
    </svg>
  );
};

export default Filter;
