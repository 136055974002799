import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  SeriesListSectionItems,
  VODPlusHomeSectionType
} from '@common/redux/modules/VODPlusPApp/types';
import { buildVodPlusPath } from '@routers/routes';

import { Episode, Text } from '../../../components';

type Props = {
  sectionData: VODPlusHomeSectionType;
  isLastSection?: boolean;
  invitationCode: string;
};

function Section({ sectionData, isLastSection, invitationCode }: Props) {
  const { t } = useTranslation('translation');
  const history = useHistory();
  const vodPlusPath = buildVodPlusPath(invitationCode);

  const { isActive, seriesListSectionItems, title } = sectionData;
  const orderedSectionItems = seriesListSectionItems
    ?.slice()
    .sort((a, b): number => a.order - b.order);

  const sectionList: SeriesListSectionItems[] = [];

  if (orderedSectionItems && orderedSectionItems.length > 0)
    orderedSectionItems.map((item) => {
      if (item.series.isPublished) {
        sectionList.push(item);
      }
      return;
    });

  return isActive ? (
    <S_Section isLastSection={isLastSection}>
      <S_TitleWrapper>
        <Text fontSizes="fontSizeSubtitle" fontWeights="fontWeightBold">
          {title}
        </Text>
      </S_TitleWrapper>
      <S_SlideRow>
        {sectionList.map((item, i) => (
          <S_SlideEntry
            key={i.toString()}
            onClick={() => {
              if (item.series?.id)
                history.push(`${vodPlusPath}/serieses/${item.series.id}`, { pathName: 'home' });
            }}
          >
            <Episode src={item.series.coverImageSrc} title={item.series.title} />
          </S_SlideEntry>
        ))}
      </S_SlideRow>
      {sectionList.length === 0 && (
        <S_TextWrapper>
          <Text color="grey400">{t('str_1323', '더 많은 시리즈들을 만나보세요!')}</Text>
        </S_TextWrapper>
      )}
    </S_Section>
  ) : (
    <></>
  );
}

const S_Section = styled.div<{ isLastSection?: boolean }>`
  margin: 48px 0;
  padding-bottom: ${({ isLastSection }) => isLastSection && '36px'};
`;

const S_TitleWrapper = styled.div`
  margin: 0 24px 12px 24px;
  text-align: left;
`;

const S_SlideRow = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const S_SlideEntry = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 12px;
  &:first-child {
    margin-left: 24px;
  }
  &:last-child {
    margin-right: 24px;
  }
`;

const S_TextWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 140px;
  justify-content: center;
`;

export default Section;
