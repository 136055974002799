import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@common/ui/components';
import { VODPlusPostType } from '@common/redux/modules/VODPlusPApp';

import { Text } from '../../../components';

type Props = {
  detailContentProps: VODPlusPostType | null;
  isUserLegallyIneligible?: boolean;
};

function DetailContent({ detailContentProps, isUserLegallyIneligible }: Props) {
  const { t } = useTranslation('translation');

  const { title, content, isPublished } = { ...detailContentProps };

  return (
    <>
      <S_DetailContent>
        {isPublished && !isUserLegallyIneligible && (
          <>
            <S_TopContent>
              <S_TextWrapper top={16}>
                <Text fontSizes="fontSizeBody2" fontWeights="fontWeightBold" color="grey900">
                  {title}
                </Text>
              </S_TextWrapper>
            </S_TopContent>
            <S_TextWrapper top={isUserLegallyIneligible ? 72 : 24}>
              <Text fontSizes="fontSizeCaption1" color="grey500" elipsis={false}>
                {content}
              </Text>
            </S_TextWrapper>
          </>
        )}

        {isUserLegallyIneligible && (
          <S_ButtonWrapper>
            <Button
              label={t('str_verfication_identity', '본인 인증')}
              line
              size="regular"
              onClick={() => {
                alert('본인 인증하러 gogo');
              }}
            />
          </S_ButtonWrapper>
        )}
      </S_DetailContent>
    </>
  );
}

const S_DetailContent = styled.div`
  padding: 0 24px;
`;

const S_TopContent = styled.div`
  border-bottom: ${({ theme }) => `1px solid  ${theme.grey100}`};
  padding-bottom: 24px;
`;

const S_TextWrapper = styled.div<{ top: number }>`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: ${({ top }) => top}px;
  text-align: left;
`;

const S_ButtonWrapper = styled.div`
  margin-top: 16px;
`;

export default DetailContent;
