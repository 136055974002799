import IconType from '../IconType';

const ChatCounseling = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        d="M18.1661,2.563 L5.8341,2.563 C3.9011,2.563 2.3341,4.13 2.3341,6.063 L2.3341,14.223 C2.3341,16.156 3.9011,17.723 5.8341,17.723 L10.9771,17.723 L14.2351,20.684 C14.5331,20.955 14.8881,21.077 15.2351,21.077 C16.0081,21.077 16.7441,20.473 16.7441,19.574 L16.7441,17.723 L18.1661,17.723 C20.0991,17.723 21.6661,16.156 21.6661,14.223 L21.6661,6.063 C21.6661,4.13 20.0991,2.563 18.1661,2.563 M18.1661,4.063 C19.2691,4.063 20.1661,4.96 20.1661,6.063 L20.1661,14.223 C20.1661,15.325 19.2691,16.223 18.1661,16.223 L16.7441,16.223 L15.2441,16.223 L15.2441,17.723 L15.2441,19.574 L11.9861,16.613 L11.5571,16.223 L10.9771,16.223 L5.8341,16.223 C4.7311,16.223 3.8341,15.325 3.8341,14.223 L3.8341,6.063 C3.8341,4.96 4.7311,4.063 5.8341,4.063 L18.1661,4.063"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M7.554,8.4074 L16.446,8.4074 M7.554,12 L12.499,12"
      />
    </svg>
  );
};

export default ChatCounseling;
