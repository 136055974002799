import { NotFoundPage } from './pages';

/**
 * Not Found Page 컨테이너
 */
function NotFoundPageContainer() {
  return <NotFoundPage />;
}

export default NotFoundPageContainer;
