import { dislikePost, likePost } from '@common/redux/modules/socialCommunityPApp/reducers';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { ProfileBar, PostMenuBar } from '../../../components/organisms';

import { Contents } from '../molecules';

type ResourceType = {
  type: string;
  imageSrcs: string[] | null;
  videoSrc: string | null;
  youtubeSrc: string | null;
};

export type Props = {
  id: number;
  author: string;
  authorThumbnailSrc: string;
  createdAt: string;
  isAuthor: boolean;
  content: string;
  handleClickPopUpMenu: (value: string) => void;
  resource: ResourceType;
  isFoldable?: boolean;
  isLiked: boolean;
  isDisliked: boolean;
  likeCount: number;
  dislikeCount: number;
  channelId: number;
};

function PostCard({
  id,
  author,
  isAuthor,
  authorThumbnailSrc,
  content,
  createdAt,
  handleClickPopUpMenu,
  resource,
  isFoldable,
  isLiked,
  isDisliked,
  likeCount,
  dislikeCount,
  channelId
}: Props) {
  const dispatch = useDispatch();

  const [hasLike, setHasLike] = useState(isLiked);
  const [hasDislike, setHasDislike] = useState(isDisliked);

  const [totalLikeCount, setTotalLikeCount] = useState(likeCount);
  const [totalDisikeCount, setTotalDisikeCount] = useState(dislikeCount);

  const handleClickLikeButton = (id: number | string) => {
    setHasLike(!hasLike);
    hasLike ? setTotalLikeCount(totalLikeCount - 1) : setTotalLikeCount(totalLikeCount + 1);
    dispatch(likePost({ channelId, postId: id, isLiked: !hasLike }));
  };

  const handleClickDisikeButton = (id: number | string) => {
    setHasDislike(!hasDislike);
    hasDislike
      ? setTotalDisikeCount(totalDisikeCount - 1)
      : setTotalDisikeCount(totalDisikeCount + 1);
    dispatch(dislikePost({ channelId, postId: id, isDisliked: !hasDislike }));
  };

  return (
    <S_PostCard>
      <ProfileBar
        id={id}
        isAuthor={isAuthor}
        authorThumbnailSrc={authorThumbnailSrc}
        author={author}
        createdAt={createdAt}
        onClick={handleClickPopUpMenu}
      />

      <S_ContentsWrapper>
        <Contents content={content} resource={resource} isFoldable={isFoldable} />
      </S_ContentsWrapper>

      <S_MenuBarWrapper>
        <PostMenuBar
          isLiked={hasLike}
          likeCount={totalLikeCount}
          isDisliked={hasDislike}
          dislikeCount={totalDisikeCount}
          onClickLikeButton={handleClickLikeButton}
          onClickDislikeButton={handleClickDisikeButton}
          id={id}
        />
      </S_MenuBarWrapper>
    </S_PostCard>
  );
}

const S_PostCard = styled.div`
  padding: 32px 0;
  text-align: left;
`;

const S_ContentsWrapper = styled.div`
  margin: 12px 0;
`;

const S_MenuBarWrapper = styled.div`
  margin-top: 12px;
  padding-left: 24px;
`;

export default PostCard;
