import { useMemo, useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signUp, signUpAuthenticating } from '@common/redux/modules/session/reducers';
import { makeGetIsAuthenticated } from '@common/redux/modules/session';
import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { Button, Form, ModalDialog } from '@common/ui/components';
import styled from 'styled-components';
import { SignUpCountryInfo } from '../../../SignUpCountryInfo';
import { SignUpForm } from './organisms';
import { accountPath } from '@routers/routes';
import { useTranslation } from 'react-i18next';
import checkDuplicateEmailAsync from '@common/utils/checkDuplicateEmailAsync';

const STEPS = {
  COUNTRY_INFO: 0,
  SIGN_UP_FORM: 1
};

type FormValues = {
  nickname: string;
  email: string;
  password: string;
  passwordConfirm: string;
  agreeMarketing: boolean;
  agreeTerms: boolean;
  agreePolicy: boolean;
  country: string;
};

function SignUpContainer() {
  const dispatch = useDispatch();
  const getIsAuthenticated = useMemo(makeGetIsAuthenticated, []);
  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);
  const isAuthenticated = useSelector(getIsAuthenticated);

  const [isModalDialogOpen, setIsModalDialogOpen] = useState(false);
  const [modalDialogMessage, setModalDialogMessage] = useState('');
  const [isSignUpSuccess, setIsSignUpSuccess] = useState(false);
  const [formData, setFormData] = useState({});

  const { t } = useTranslation('translation');

  const methods = useForm<FormValues>({ mode: 'onChange' });

  const handleSubmitForm = useCallback(
    (values) => {
      dispatch(signUpAuthenticating());
      dispatch(
        signUp({
          nickname: values.nickname,
          email: values.email,
          password: values.password,
          passwordConfirmation: values.passwordConfirm,
          country: values.country
        })
      );
    },
    [dispatch]
  );

  const handleSignUp: SubmitHandler<FormValues> = async (data) => {
    const { getValues } = methods;
    const { email } = getValues();
    const { isError, isDuplicateEmail } = await checkDuplicateEmailAsync(email);

    if (isError) {
      setIsModalDialogOpen(true);
      setModalDialogMessage(
        t('str_err_1239', 'Error : 알 수 없는 에러가 발생하였습니다. 관리자에게 문의하세요 (500)')
      );
      return;
    }

    if (isDuplicateEmail) {
      setIsModalDialogOpen(true);
      setModalDialogMessage(
        t('str_err_1115', '이미 존재하는 계정입니다.\n 다시 한번 확인해주세요.')
      );
      return;
    }

    setIsModalDialogOpen(true);
    setModalDialogMessage(t('str_224', '회원가입이 완료되었습니다.'));
    setIsSignUpSuccess(true);
    setFormData(data);
  };

  const [currentStep, setCurrentStep] = useState(0);

  async function handleClickNext() {
    setCurrentStep((prev) => prev + 1);
  }

  function handleClickPrev() {
    setCurrentStep((prev) => prev - 1);
  }

  function getStepComponent(step: number) {
    switch (step) {
      case STEPS.COUNTRY_INFO:
        return <SignUpCountryInfo methods={methods} onClickNext={handleClickNext} />;
      case STEPS.SIGN_UP_FORM:
        return <SignUpForm methods={methods} handleClickPrev={handleClickPrev} />;
      default:
        return <SignUpCountryInfo methods={methods} onClickNext={handleClickNext} />;
    }
  }

  if (isAuthenticated) {
    if (currentChannel && Object.keys(currentChannel).length !== 0) {
      return <Redirect to={`/channels/${currentChannel.primitiveInvitationCode}`} />;
    }

    return <Redirect to={accountPath} />;
  }

  return (
    <S_FormWrapper>
      {isModalDialogOpen && (
        <ModalDialog
          isOpen
          onClose={() => setIsModalDialogOpen(false)}
          footer={
            <Button
              onClick={
                isSignUpSuccess
                  ? () => handleSubmitForm(formData)
                  : () => setIsModalDialogOpen(false)
              }
              label={t('str_confirm', '확인')}
            />
          }
        >
          <MessageWrapper>
            {modalDialogMessage.split('\n').map((line, index) => (
              <div key={index}>{line}</div>
            ))}
          </MessageWrapper>
        </ModalDialog>
      )}

      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(handleSignUp)}>{getStepComponent(currentStep)}</Form>
      </FormProvider>
    </S_FormWrapper>
  );
}

const S_FormWrapper = styled.div`
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0 100px 0 0 white, 0 -100px 0 0 white, 0 0 50px -30px rgba(0, 0, 0, 0.5),
    0 0 50px -30px rgba(0, 0, 0, 0.5);
  height: 100%;
`;

const MessageWrapper = styled.div`
  word-break: keep-all;
`;

export default SignUpContainer;
