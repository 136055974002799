import styled from 'styled-components';

type RatioImageProps = {
  src: string | null;
  ratio: number;
};

function RatioImage({ ratio, src }: RatioImageProps) {
  return <S_RatioImage ratio={ratio} src={src} />;
}

const S_RatioImage = styled.span<{
  ratio: number;
  src: string | null;
}>`
  background-color: ${({ theme }) => theme.grey100};
  background-image: ${({ src }) => src && `url(${src})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px;
  display: block;
  padding-top: ${({ ratio }) => `${Math.floor(100 / ratio)}%`};
  position: relative;
  width: 100%;
`;

export default RatioImage;
