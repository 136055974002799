import { useState, useMemo } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { fetchMyBookingDescEdit } from '@common/redux/modules/booking/reducers';
import { BookingDescType } from '@common/redux/modules/booking/types';
import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import styled from 'styled-components';

import { BookingView, NavBar } from '../../../components/molcules';
import { StatusCard } from '../molcules';
import { MyBookingDescHistory, MyBookingDescInfo, MyBookingDescRequest } from '../organisms';

import { ButtonModal } from './index';

type MyBookingDescTemplateProps = {
  myBookingDesc: BookingDescType;
  fromHistory: boolean;
};

type FormValues = {
  requestEdit: string;
};
function MyBookingDescTemplate({ myBookingDesc, fromHistory }: MyBookingDescTemplateProps) {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const [isButtonModalOpen, setIsButtonModalOpen] = useState(false);
  const [ModalButtonLabel, setModalButtonLabel] = useState('');
  const [ModalButtonText, setModalButtonText] = useState('');
  const [isEditModeOpen, setIsEditModeOpen] = useState(false);

  const handleModalChange = () => {
    setIsButtonModalOpen(!isButtonModalOpen);
  };

  const handleButtonModal = (buttonLabel: string, ContentsText: string) => {
    setIsButtonModalOpen(!isButtonModalOpen);
    setModalButtonLabel(buttonLabel);
    setModalButtonText(ContentsText);
  };

  const bookingId = myBookingDesc.id;

  const handleEdit: SubmitHandler<FormValues> = (data) => {
    if (currentChannel) {
      if (data.requestEdit) {
        dispatch(
          fetchMyBookingDescEdit({
            eventData: { id: bookingId, description: data.requestEdit },
            channelId: currentChannel.id
          })
        );
      }
      if (data.requestEdit === '') {
        dispatch(
          fetchMyBookingDescEdit({
            eventData: { id: bookingId, description: '' },
            channelId: currentChannel.id
          })
        );
      } else if (data.requestEdit === undefined && myBookingDesc.description) {
        dispatch(
          fetchMyBookingDescEdit({
            eventData: { id: bookingId, description: myBookingDesc.description },
            channelId: currentChannel.id
          })
        );
      }
    }
    setIsEditModeOpen(!isEditModeOpen);
  };

  const { duration, startDate, history, description } = myBookingDesc;

  return (
    <S_MyBookingDescTemplateContainer>
      {isButtonModalOpen && (
        <ButtonModal
          booking={myBookingDesc}
          ModalButtonLabel={ModalButtonLabel}
          ModalButtonText={ModalButtonText}
          handleModalChange={handleModalChange}
        />
      )}
      <BookingView
        nav={
          <NavBar
            isBack
            title={t('str_pg_title_papp_cbooking_my_booking_detail', {
              defaultValue: '예약내역 상세'
            })}
          />
        }
      >
        <StatusCard
          startDate={startDate}
          duration={duration}
          history={history}
          handleButtonModal={handleButtonModal}
        />
        <MyBookingDescInfo startDate={startDate} duration={duration} />
        <MyBookingDescRequest
          description={description}
          handleEdit={handleEdit}
          isEditModeOpen={isEditModeOpen}
          setIsEditModeOpen={setIsEditModeOpen}
          fromHistory={fromHistory}
        />
        <MyBookingDescHistory history={history} />
        <S_MyBookingDescFooter />
      </BookingView>
    </S_MyBookingDescTemplateContainer>
  );
}

const S_MyBookingDescTemplateContainer = styled.div`
  background: none;
`;

const S_MyBookingDescFooter = styled.div`
  height: 32px;
`;

export default MyBookingDescTemplate;
