import { useTranslation } from 'react-i18next';

import { ReactComponent as ConfirmCheckIcon } from '@assets/img/im-booking-confirm-check.svg';
import styled from 'styled-components';

import { Title } from '../../../components/atoms';

function ConfirmCheck() {
  const { t } = useTranslation('translation');
  return (
    <S_ConfirmCheckContainer>
      <ConfirmCheckIcon />
      <Title text={t('str_49', '예약이 완료 되었습니다.')} align="center" />
    </S_ConfirmCheckContainer>
  );
}

const S_ConfirmCheckContainer = styled.div`
  background-color: ${({ theme }) => theme.white};
  padding: 32px 0 15px;
`;

export default ConfirmCheck;
