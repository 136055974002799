import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';

import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import { getVODPosts } from '@common/redux/modules/VODPApp';
import { cleanUpAllData, fetchVODPosts } from '@common/redux/modules/VODPApp/reducers';
import { SmartLink } from '@common/ui/components';
import { VOD_POST_DEFAULT_LIMIT, VOD_POST_NEXT_LIMIT } from '@common/constants/index';
import styled from 'styled-components';

import { buildVodPostDetailPath, buildChannelsPath } from '@routers/routes';
import TobBar from '../../components/organisms/TobBar';

import { VODPostListItem } from './molecules';

function VODPosts() {
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const [channelId, setChannelId] = useState(0);
  const [channelCode, setChannelCode] = useState('');
  const [lastPostId, setLastPostId] = useState(0);

  const posts = useSelector(getVODPosts);

  useEffect(() => {
    if (!currentChannel) return;

    setChannelCode(currentChannel.primitiveInvitationCode);
    setChannelId(currentChannel.id);
  }, [currentChannel]);

  useEffect(() => {
    if (!channelId) return;

    lastPostId
      ? dispatch(fetchVODPosts({ limit: VOD_POST_NEXT_LIMIT, cursor: lastPostId, channelId }))
      : dispatch(fetchVODPosts({ limit: VOD_POST_DEFAULT_LIMIT, channelId }));
  }, [dispatch, lastPostId, channelId]);

  useEffect(
    () => () => {
      dispatch(cleanUpAllData());
    },
    [dispatch]
  );

  const handleScrollDown = () => {
    setLastPostId(posts[posts.length - 1].id);
  };

  return (
    <>
      <TobBar
        isMenu
        title={t('str_papp_vod_board_post', 'VOD 게시글')}
        backUrl={buildChannelsPath(channelCode)}
        channelCode={channelCode}
        hasTabBar
      />
      <S_EmptyDiv />
      {posts.length === 0 ? (
        <S_NoItem>{t('str_374', '아직 업로드한 게시글이 없습니다.')}</S_NoItem>
      ) : (
        <S_ScrollField
          dataLength={posts.length}
          next={handleScrollDown}
          hasMore={posts?.[posts.length - 1].id !== lastPostId}
        >
          {posts.map(({ id, vodResource, insertedAt, title }) => (
            <SmartLink key={id} to={buildVodPostDetailPath(channelCode, id)} openNewTab={false}>
              <VODPostListItem
                thumbnailSrc={vodResource.thumbnailSrc}
                createdAt={insertedAt}
                runningTime={vodResource.duration}
                title={title}
              />
            </SmartLink>
          ))}
        </S_ScrollField>
      )}
    </>
  );
}

const S_EmptyDiv = styled.div`
  height: 104px;
`;

const S_ScrollField = styled(InfiniteScroll as any)`
  margin-bottom: 24px;
  padding: 24px;
`;

const S_NoItem = styled.div`
  color: ${({ theme }) => theme.grey400};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  margin-top: 96px;
`;

export default VODPosts;
