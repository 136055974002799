import { PreviewCard } from '../molecules';

type ImageType = {
  id: string;
  previewSrc: string;
  file?: File | Blob;
};

type Props = {
  images: ImageType[];
  handleDelete: (value?: string) => void;
};

function PreviewList({ images, handleDelete }: Props) {
  return (
    <>
      {images.map((image) => (
        <PreviewCard
          key={image.id}
          src={image.previewSrc}
          id={image.id}
          handleDelete={handleDelete}
        />
      ))}
    </>
  );
}

export default PreviewList;
