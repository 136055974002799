import { useState } from 'react';

import styled from 'styled-components';

import { MyTicketTemplate, PurchaseTicketModal } from './template';

type MyTicketProps = {
  ticketLeft: number;
  purchaseTicket: () => void;
};

function MyTicket({ ticketLeft, purchaseTicket }: MyTicketProps) {
  const [isPurchaseTicketModalOpen, setIsPurchaseTicketModalOpen] = useState(false);
  const [ticketQuantity, setTicketQuantity] = useState(1);

  const plusTicketQuantity = (ticketCounter: number) => {
    if (ticketCounter === 1) {
      // sprint #3 에서는 1장만 구매 가능 -> 전환 불가
      // setTicketQuantity(5);
      return;
    }
    if (ticketCounter === 5) {
      setTicketQuantity(10);
      return;
    }
    if (ticketCounter === 10) {
      setTicketQuantity(100);
    }
  };

  const minusTicketQuantity = (ticketCounter: number) => {
    if (ticketCounter === 5) {
      setTicketQuantity(1);
      return;
    }
    if (ticketCounter === 10) {
      setTicketQuantity(5);
      return;
    }
    if (ticketCounter === 100) {
      setTicketQuantity(10);
    }
  };

  const handlePurchaseTicketModalOpen = () => {
    setIsPurchaseTicketModalOpen(!isPurchaseTicketModalOpen);
  };

  return (
    <S_MyTicketPage>
      {isPurchaseTicketModalOpen && (
        <PurchaseTicketModal
          ticketCounter={ticketQuantity}
          plusTicketQuantity={plusTicketQuantity}
          minusTicketQuantity={minusTicketQuantity}
          handlePurchaseTicketModalOpen={handlePurchaseTicketModalOpen}
          purchaseTicket={purchaseTicket}
        />
      )}
      <MyTicketTemplate
        ticketLeft={ticketLeft}
        handlePurchaseTicketModalOpen={handlePurchaseTicketModalOpen}
      />
    </S_MyTicketPage>
  );
}

const S_MyTicketPage = styled.div`
  border: none;
`;

export default MyTicket;
