class TimestampUUID {
  static new(prefix, givenNow) {
    const now = givenNow || new Date();
    const isoDate = now.toISOString();
    const [datePart, timePart] = isoDate.split('T');
    const timestampId = `${datePart.replace(/-/g, '')}${timePart.replace(/[:.Z]/g, '')}`;

    const salt = Math.random().toString(36).slice(2, 7).toUpperCase();

    return `${prefix}${timestampId}-${salt}`;
  }
}

export default TimestampUUID;
