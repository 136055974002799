import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';

export const getIsUserLoadingSelector = (state: RootState) => state.user.isLoading;
export const getCurrentUserSelector = (state: RootState) => state.user.currentUser;
export const getMainProfileSelector = (state: RootState) => state.user.mainProfile;
export const getChannelProfilesSelector = (state: RootState) => state.user.channelProfiles;

export const getUserError = (state: RootState) => {
  return state.user.error;
};

export const makeGetIsUserLoading = () =>
  createSelector([getIsUserLoadingSelector], (isLoading) => isLoading);

export const makeGetCurrentUser = () =>
  createSelector([getCurrentUserSelector], (currentUser) => currentUser);

export const makeGetMainProfile = () =>
  createSelector([getMainProfileSelector], (mainProfile) => mainProfile);

export const makeGetChannelProfiles = () =>
  createSelector([getChannelProfilesSelector], (channelProfiles) => channelProfiles);
