import { createSelector } from 'reselect';

import { RootState } from '../../index';

export const getIsProfileLoading = (state: RootState) => {
  return state.profile.isLoading;
};

export const getIsUpdated = (state: RootState) => {
  return state.profile.isUpdated;
};

export const getProfileError = (state: RootState) => {
  return state.profile.error;
};

export const getProfile = (state: RootState) => {
  return state.profile.profile;
};

export const getCurrentChannelProfile = (state: RootState) => {
  return state.profile.currentChannelProfile;
};

export const getS3UploadedImageUrl = (state: RootState) => state.profile.uploadedImageUrl;

export const makeGetIsProfileLoading = () => {
  return createSelector([getIsProfileLoading], (isLoading) => isLoading);
};

export const makeGetIsUpdated = () => {
  return createSelector([getIsUpdated], (isUpdated) => isUpdated);
};

export const makeGetProfile = () => {
  return createSelector([getProfile], (profile) => profile);
};

export const makeGetCurrentChannelProfile = () => {
  return createSelector(
    [getCurrentChannelProfile],
    (currentChannelProfile) => currentChannelProfile
  );
};

export const makeGetS3UploadedImageUrl = () => {
  return createSelector([getS3UploadedImageUrl], (imageUrl) => imageUrl);
};

export const makeGetProfileError = () => {
  return createSelector([getProfileError], (error) => error);
};
