import React from 'react';

import styled from 'styled-components';

type NavViewProps = {
  nav?: React.ReactElement;
  children: React.ReactNode;
};

/**
 * 모바일 네비게이션 뷰
 *
 * @param {Object} props
 * @param {Object} props.nav 네비게이션 컴포넌트
 */
function NavView({ nav, children }: NavViewProps) {
  return (
    <S_NavView>
      {nav}
      <S_Form>{children}</S_Form>
    </S_NavView>
  );
}

const S_NavView = styled.div`
  box-shadow: 0 100px 0 0 white, 0 -100px 0 0 white, 0 0 50px -30px rgba(0, 0, 0, 0.5),
    0 0 50px -30px rgba(0, 0, 0, 0.5);
  height: 100%;
  position: relative;
  user-select: none;
`;

const S_Form = styled.div`
  position: relative;
  width: 100%;
`;

export default NavView;
