import { Button, ModalDialog as ModalDialogComponent } from '@common/ui/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type ModalDialogProps = {
  onCloseModal?: () => void;
};

function useModalDialog(initialStatus = false, initialMessage = '') {
  const [isModalDialogOpen, setIsModalDialogOpen] = useState(initialStatus);
  const [message, setMessage] = useState(initialMessage);
  const { t } = useTranslation('translation');

  function ModalDialog({ onCloseModal }: ModalDialogProps) {
    return (
      <ModalDialogComponent
        isOpen={isModalDialogOpen}
        onClose={onCloseModal ?? (() => setIsModalDialogOpen(false))}
        footer={
          <Button
            onClick={onCloseModal ?? (() => setIsModalDialogOpen(false))}
            label={t('str_ok', '확인')}
          />
        }
      >
        {message}
      </ModalDialogComponent>
    );
  }

  return {
    ModalDialog,
    setModalDialogMessage: setMessage,
    setIsModalDialogOpen
  };
}

export default useModalDialog;
