import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

export function builderFactory<T extends { isLoading: boolean }>(
  builder: ActionReducerMapBuilder<T>,
  actions: any[]
): ActionReducerMapBuilder<T> {
  return actions.reduce((acc, action) => {
    return acc.addCase(action, (state: T) => {
      state.isLoading = true;
    });
  }, builder);
}
