import styled from 'styled-components';

import CommentCount from './CommentCount';
import { PostInfo } from '../../../components/molcules';

type Props = {
  title: string;
  author: string;
  createdAt: string;
  viewCount: number;
  commentCount: number;
};

function NoticeCard({ title, author, createdAt, viewCount, commentCount }: Props) {
  return (
    <>
      <S_NoticeCard>
        <S_NoticeInfo>
          <S_Title>{title}</S_Title>
          <PostInfo author={author} createdAt={createdAt} viewCount={viewCount} />
        </S_NoticeInfo>
        <CommentCount count={commentCount} />
      </S_NoticeCard>
      <S_Line />
    </>
  );
}

const S_NoticeCard = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  text-align: left;
`;

const S_NoticeInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const S_Title = styled.span`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  margin-bottom: 4px;
`;

const S_Line = styled.div`
  background-color: ${({ theme }) => theme.grey100};
  height: 1px;
  margin: 0 24px;
`;

export default NoticeCard;
