import { useTranslation } from 'react-i18next';

import { BookingDescHistoryType } from '@common/redux/modules/booking/types';
import styled from 'styled-components';

import { BookingView, NavBar } from '../../../components/molcules';
import { HistoryList } from '../organisms';

type MyBookingHistoryTemplateProps = {
  bookingList: BookingDescHistoryType[];
  handleScrollUp: () => void;
  hasMore: boolean;
  channelID: string;
};

function MyBookingHistoryTemplate({
  bookingList,
  handleScrollUp,
  hasMore,
  channelID
}: MyBookingHistoryTemplateProps) {
  const { t } = useTranslation('translation');
  return (
    <S_MyBookingHistoryTemplateContainer>
      <BookingView
        nav={
          <NavBar
            isBack
            title={t('str_pg_title_papp_cbooking_booking_history', {
              defaultValue: '이전 예약 이력'
            })}
            backgroundColor="#f5f6f8"
          />
        }
      >
        <S_PageLabel>
          {t('str_50', {
            defaultValue:
              '일정이 종료된 예약 이력입니다. \n 각 카드를 클릭하여 이전 상담의 상세내역을 확인할 수 있습니다.'
          })
            .split('\n')
            .map((line, i) => (
              <div key={i.toString()}>
                {line}
                <br />
              </div>
            ))}
        </S_PageLabel>
        <HistoryList
          bookingHistoryList={bookingList}
          onScrollUp={handleScrollUp}
          hasMore={hasMore}
          channelID={channelID}
        />
        <S_MyBookingHistoryFooter />
      </BookingView>
    </S_MyBookingHistoryTemplateContainer>
  );
}

const S_MyBookingHistoryTemplateContainer = styled.div`
  background-color: #f5f6f8;
`;

const S_PageLabel = styled.div`
  color: ${({ theme }) => theme.grey500};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  line-height: ${({ theme }) => theme.fontLineHeight};
  margin: 24px 24px 0 24px;
`;

const S_MyBookingHistoryFooter = styled.div`
  height: 12px;
`;

export default MyBookingHistoryTemplate;
