import SessionRepository from '@common/repositories/session';

async function checkDuplicateEmailAsync(email: string) {
  const { status } = await SessionRepository.checkDuplicateEmail({ email });

  switch (status) {
    case 204:
      return {
        isDuplicateEmail: false,
        isError: false
      };
    case 422:
      return {
        isDuplicateEmail: true,
        isError: false
      };
    default:
      return {
        isDuplicateEmail: null,
        isError: true
      };
  }
}

export default checkDuplicateEmailAsync;
