import { useEffect, useRef } from 'react';
import { JWT } from '@common/utils/index';

const { REACT_APP_KEY_ACCESS_TOKEN } = process.env;
function CertifyPage() {
  const submitRef = useRef<HTMLInputElement>(null);
  const [, jwtData] = JWT.validateJwtToken(localStorage.getItem(REACT_APP_KEY_ACCESS_TOKEN || ''));
  const { REACT_APP_ENV } = process.env;
  const certificationUrl =
    REACT_APP_ENV !== 'production'
      ? `https://api.${REACT_APP_ENV}.publishingkit.net/api/v1/certified-identity/certify`
      : 'https://api.publishingkit.net/api/v1/certified-identity/certify';

  useEffect(() => {
    submitRef.current?.click();
  }, []);

  return (
    <form action={certificationUrl} method="post" target="_self" style={{ display: 'none' }}>
      <input type="hidden" name="token" value={jwtData} />
      <input type="submit" ref={submitRef} />
    </form>
  );
}

export default CertifyPage;
