import styled from 'styled-components';

import LOGO from '@assets/img/logo.png';
import { Icon } from '@common/ui/components';

type Props = {
  title: string;
  isBack: boolean;
  handleBack?: (...args: any) => any;
  showLogo?: boolean;
  setIsCertify?: (...args: any) => any;
  isCertify: boolean;
};

function NavBar({ title, isBack, isCertify, setIsCertify, handleBack, showLogo = true }: Props) {
  const onClickBack = () => {
    if (handleBack) {
      handleBack();
    }

    if (setIsCertify) {
      setIsCertify(false);
    }
  };

  return (
    <S_NavBar isCertify={isCertify}>
      {isBack ? (
        <S_Button onClick={onClickBack}>
          <Icon iconName="ic_ArrowLeft" />
        </S_Button>
      ) : (
        <S_Empty />
      )}
      {isCertify ? (
        <S_Empty />
      ) : (
        <>
          <S_LogoWrapper>
            {showLogo && <S_Logo className="logo" src={LOGO} alt="Logo" />}
            {title && <S_Title>{title}</S_Title>}
          </S_LogoWrapper>
          <S_Empty />{' '}
        </>
      )}
    </S_NavBar>
  );
}

const S_NavBar = styled.div<{ isCertify: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  border-bottom: ${({ isCertify, theme }) => !isCertify && `1px solid ${theme.grey100}`};
  display: flex;
  height: 64px;
  justify-content: space-between;
  left: 0;
  position: sticky;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 10;
`;

const S_LogoWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
`;

const S_Logo = styled.img`
  height: 32px;
  margin-right: 8px;
  object-fit: cover;
  width: 60px;
`;

const S_Title = styled.div`
  color: ${({ theme }) => theme.black};
  font-size: ${({ theme }) => theme.fontSizeSubtitle};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  text-align: center;
`;

const S_Button = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  width: 48px;

  &:hover {
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
`;

const S_Empty = styled.div`
  height: 48px;
  width: 48px;
`;

export default NavBar;
