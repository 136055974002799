export const HARDCODED_MAGIC_NUMBER = 6;
export const SIGNIN_DEFAULT_ROW = 1;
export const SIGNIN_DEFAULT_MAX = 10;
export const SIGNIN_EMAIL_MAX = 50;
export const SIGNIN_PASSWORD_MAX = 30;
export const HARDCODED_DAY_KO = ['일', '월', '화', '수', '목', '금', '토'];
export const LABEL_SEEMORE_HEIGHT = 22;
export const SYNOPSIS_SEEMORE_HEIGHT = 70;

/** pApp input validation */
export const FREE_BOARD_TITLE_MIN_LENGTH = 2;
export const FREE_BOARD_TITLE_MAX_LENGTH = 30;
export const FREE_BOARD_POST_MAX_LENGTH = 2000;
export const SOCIAL_POST_MAX_LENGTH = 5000;
export const COMMENT_MAX_LENGTH = 800;
export const YOUTUBE_LINK_MAX_LENGTH = 300;

/** pApp pagination */
export const COMMENT_DEFAULT_LIMIT = 15;
export const OFFSET_DEFAULT_LIMIT = 8;
export const SOCIAL_POST_DEFAULT_LIMIT = 15;
export const VOD_POST_DEFAULT_LIMIT = 15;
export const VOD_POST_NEXT_LIMIT = 10;
export const PLAYLIST_DEFAULT_LIMIT = 20;
export const PLAYLIST_NEXT_LIMIT = 5;
export const VODPLUS_SERIES_DEFAULT_LIMIT = 30;
export const VODPLUS_SERIES_NEXT_LIMIT = 20;

/** pApp input row */
export const FREE_BOARD_POST_DEFAULT_ROW = 10;

/** date format */
export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'hh:mm';

export const MEDIA_LIMIT = {
  IMAGE_MAX_COUNT: 10,
  IMAGE_MAX_SIZE: 10000000,
  VIDEO_MAX_DURATION: 60,
  VIDEO_MAX_SIZE: 30000000
};

export const STORY_TYPES = {
  VIDEO: 'VIDEO',
  IMAGE: 'IMAGE',
  YOUTUBE: 'YOUTUBE',
  PLAIN: 'PLAIN'
};

export const POP_UP_MENU = {
  EDIT: '수정',
  DELETE: '삭제'
};
