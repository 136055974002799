import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { EpisodeType } from '@common/redux/modules/VODPlusPApp/types';
import { Icon } from '@common/ui/components';
import DateHelper from '@common/utils/dateHelper';
import { buildVodPlusPath } from '@routers/routes';

import { Image, Text } from '..';

type Props = {
  entry: EpisodeType;
  invitationCode: string;
  isCurrentVOD?: boolean;
};

function EpisodeEntry({ entry, invitationCode, isCurrentVOD }: Props) {
  const { t } = useTranslation('translation');
  const history = useHistory();

  const { seriesId, postId } = entry;
  const { content, contentRatingsAge, isPublished, isUserLegallyIneligible, title } = entry.post;

  const { duration, thumbnailSrc } = entry.post.vodResource;

  const vodPlusPath = buildVodPlusPath(invitationCode);

  const goToEpisodeDetail = (sId: number, pId: number) => {
    history.push(`${vodPlusPath}/serieses/${sId}/episodes/${pId}`);
  };

  return (
    <>
      {!isUserLegallyIneligible && isPublished && (
        <S_EpisodeEntry
          isCurrentVOD={isCurrentVOD}
          onClick={() => {
            goToEpisodeDetail(seriesId, postId);
          }}
        >
          <S_FlexTop>
            <S_TopLeftWrapper>
              <Image ratio={16 / 9} src={thumbnailSrc} borderRadius="8px" />
              <S_TopLeftTextWrapper>
                <Text
                  fontSizes="fontSizeCaption2"
                  color="white"
                  fontWeights="fontWeightBold"
                  lineLimit={2}
                >
                  {DateHelper.makeTimeFormatDate(duration)}
                </Text>
              </S_TopLeftTextWrapper>
            </S_TopLeftWrapper>
            <S_TopRightWrapper>
              <Text fontSizes="fontSizeBody2" lineLimit={2}>
                {title}
              </Text>
              <Text fontSizes="fontSizeCaption1" color="grey400">
                {contentRatingsAge === 0
                  ? ''
                  : t('str_922', {
                      restrictedAge: contentRatingsAge,
                      defaultValue: '만 {{restrictedAge}}세'
                    })}
              </Text>
            </S_TopRightWrapper>
          </S_FlexTop>
          <S_FlexBottom>
            <Text fontSizes="fontSizeCaption1" color="grey500" lineLimit={4}>
              {content}
            </Text>
          </S_FlexBottom>
        </S_EpisodeEntry>
      )}
      {isUserLegallyIneligible && isPublished && (
        <S_EpisodeEntry
          isCurrentVOD={isCurrentVOD}
          onClick={() => {
            goToEpisodeDetail(seriesId, postId);
          }}
        >
          <S_FlexTop>
            <S_TopLeftWrapper>
              <Image ratio={16 / 9} borderRadius="8px" />
              <S_TopLeftIconWrapper>
                <Icon iconName="ic_Unavailable" size={48} colorCode="grey400" />
              </S_TopLeftIconWrapper>
            </S_TopLeftWrapper>
            <S_TopRightWrapper>
              <Text fontSizes="fontSizeBody2" lineLimit={2}>
                {t('str_err_1114', '이 콘텐츠를 표시하기에 적합한 나이가 아닙니다.')}
              </Text>
            </S_TopRightWrapper>
          </S_FlexTop>
        </S_EpisodeEntry>
      )}
      {!isPublished && (
        <S_EpisodeEntry
          isCurrentVOD={isCurrentVOD}
          onClick={() => {
            goToEpisodeDetail(seriesId, postId);
          }}
        >
          <S_FlexTop>
            <S_TopLeftWrapper>
              <Image ratio={16 / 9} borderRadius="8px" />
              <S_TopLeftIconWrapper>
                <Icon iconName="ic_Unavailable" size={48} colorCode="grey400" />
              </S_TopLeftIconWrapper>
            </S_TopLeftWrapper>
            <S_TopRightWrapper>
              <Text fontSizes="fontSizeBody2" lineLimit={2}>
                {t('papp_vodp_episode_private', '비공개 콘텐츠')}
              </Text>
            </S_TopRightWrapper>
          </S_FlexTop>
        </S_EpisodeEntry>
      )}
    </>
  );
}

const S_EpisodeEntry = styled.div<{ isCurrentVOD?: boolean }>`
  background-color: ${({ theme, isCurrentVOD }) => isCurrentVOD && theme.grey50};
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
`;

const S_FlexTop = styled.div`
  display: flex;
  flex-direction: row;
`;

const S_TopLeftWrapper = styled.div`
  flex: 1;
  margin-right: 12px;
  min-width: 115px;
  position: relative;
`;

const S_TopLeftTextWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 16px;
  bottom: 4px;
  padding: 0 6px;
  position: absolute;
  right: 4px;
`;

const S_TopLeftIconWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  left: 50%;
  margin: -24px 0 0 -24px;
  position: absolute;
  top: 50%;
`;

const S_TopRightWrapper = styled.div`
  flex: 2;
  max-width: 200px;
`;

const S_FlexBottom = styled.div`
  margin-top: 12px;
`;

export default EpisodeEntry;
