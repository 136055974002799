import io from 'socket.io-client';
import { eventChannel } from 'redux-saga';

const { REACT_APP_CHAT_API_HOST } = process.env;

const SERVICE_TYPE = {
  chat: {
    url: REACT_APP_CHAT_API_HOST,
    channelId: '1'
  },
  mock: {
    url: 'https://chat.dev.publishingkit.net/1',
    port: ''
  }
};

class Socket {
  connectTo(domain) {
    this.socket = io(`${SERVICE_TYPE[domain].url}/${SERVICE_TYPE[domain].channelId}`, {
      upgrade: false,
      transports: ['websocket']
    });
    return this;
  }

  emit(eventName, payload) {
    this.socket.emit(eventName, payload);
    return this;
  }

  makeEventChannel(type) {
    return eventChannel((emitter) => {
      this.socket.on(type, (payload = true) => {
        emitter(payload);
      });
      return () => this.socket.disconnect();
    });
  }

  disconnect() {
    this.socket.disconnect();
  }
}

export default Socket;
