import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import styled from 'styled-components';
import { Icon } from '@common/ui/components';

export type CheckBoxProps = {
  label: string | JSX.Element;
  labelId?: string;
  name: string;
  isRequired?: boolean;
  bold?: boolean;
  validation?: { [key: string]: any };
  rest?: any[];
  disabled?: boolean;
};

export type LabelStyle = {
  disabled?: boolean;
};

export type TitleStyle = {
  bold?: boolean;
};

function CheckBoxSeperateLabel({
  label,
  isRequired,
  name,
  bold,
  validation,
  disabled,
  ...rest
}: CheckBoxProps) {
  const { t } = useTranslation('translation');
  const id = `input_${name}`;
  const {
    watch,
    register,
    formState: { errors }
  } = useFormContext();
  const isChecked = watch(name as any, false);

  return (
    <CheckBoxContainer>
      <>
        <HiddenInput {...register(name as any, validation)} type="checkbox" id={id} {...rest} />
        <LabelWrapper>
          <Label htmlFor={id}>
            <IconWrapper>
              {!disabled && isChecked ? (
                <Icon iconName="ic_CheckboxOn" colorCode="blue500" size={16} />
              ) : (
                !disabled && <Icon iconName="ic_CheckboxOff" colorCode="grey400" size={16} />
              )}
              {disabled && <Icon iconName="ic_CheckboxOff" colorCode="grey100" size={16} />}
            </IconWrapper>
          </Label>
          <Title bold={bold}>{label}</Title>
          {isRequired && <RequiredTitle>(필수)</RequiredTitle>}
        </LabelWrapper>
      </>
      {errors[name] && <ErrorContainer>{t(errors[name].message)}</ErrorContainer>}
    </CheckBoxContainer>
  );
}

const CheckBoxContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const HiddenInput = styled.input`
  left: 0;
  opacity: 0.00000001;
  position: absolute;
  width: auto;
`;

const Label = styled.label<LabelStyle>`
  align-items: center;
  color: ${({ theme, disabled }) => (disabled ? theme.grey400 : theme.grey500)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: inline-flex;
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-stretch: normal;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  justify-content: center;
  letter-spacing: normal;
  position: relative;
  text-align: left;
  user-select: none;
`;

const LabelWrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.grey500};
  cursor: pointer;
  display: inline-flex;
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-stretch: normal;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  justify-content: center;
  letter-spacing: normal;
  position: relative;
  text-align: left;
  user-select: none;
`;

const IconWrapper = styled.div`
  height: 16px;
  margin-right: 8px;
  width: 16px;
`;

const Title = styled.span<TitleStyle>`
  display: inline-block;
  font-weight: ${({ theme, bold }) => bold && theme.fontWeightBold};
  & > div {
    font-weight: ${({ theme, bold }) => bold && theme.fontWeightBold};
  }
`;

const RequiredTitle = styled.span`
  display: inline-block;
  margin-left: 4px;
`;

const ErrorContainer = styled.div`
  color: ${({ theme }) => theme.red500};
  font-size: ${({ theme }) => theme.fontSizeCaption2};
  font-stretch: normal;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  letter-spacing: normal;
  text-align: left;
`;

export default CheckBoxSeperateLabel;
