import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { responseStatusReset } from '@common/redux/modules/booking/reducers';
import DateHelper from '@common/utils/dateHelper';
import styled from 'styled-components';

import { BookingDescTemplate } from './templates';

type BookingDescProps = {
  ticketLeft: number;
  selectDate: ReturnType<typeof DateHelper.getInstance>;
  selectTime: string;
  channelID: string;
  comment: string;
  setComment: (currentComment: string) => void;
  check: boolean;
  setCheck: (currentCheck: boolean) => void;
};

function BookingDesc({
  selectDate,
  selectTime,
  channelID,
  ticketLeft,
  comment,
  setComment,
  check,
  setCheck
}: BookingDescProps) {
  const location = useLocation<{ id: number; duration: number }>();
  const dispatch = useDispatch();

  const { id, duration } = location.state;

  useEffect(() => {
    dispatch(responseStatusReset());
  }, [channelID, dispatch, duration]);

  return (
    <S_BookingDescPage>
      <BookingDescTemplate
        duration={duration}
        ticketLeft={ticketLeft}
        selectDate={selectDate}
        selectTime={selectTime}
        selectEid={id}
        channelID={channelID}
        comment={comment}
        setComment={setComment}
        check={check}
        setCheck={setCheck}
      />
    </S_BookingDescPage>
  );
}

const S_BookingDescPage = styled.div`
  background-color: white;
`;

export default BookingDesc;
