import IconType from '../IconType';

const ThumbDown = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M15.57465,3.18705 C16.68015,3.18705 17.57415,4.08255 17.57415,5.18655 L17.57415,5.18655 L17.57415,15.73155 C17.57415,16.27905 17.34915,16.80555 16.95015,17.18205 L16.95015,17.18205 L12.09015,21.79155 C11.66565,22.22955 10.99065,22.30455 10.48065,21.96855 C9.97215,21.63405 9.77415,20.98455 10.00965,20.42205 L10.00965,20.42205 L11.31615,17.28255 C11.45265,16.95405 11.21115,16.59105 10.85415,16.59105 L10.85415,16.59105 L2.71815,16.59105 C1.61415,16.59105 0.71865,15.69555 0.71865,14.59155 L0.71865,14.59155 L0.71865,12.21405 C0.71865,11.66205 0.87015,11.12205 1.15815,10.64955 L1.15815,10.64955 L5.13465,4.14405 C5.49765,3.55005 6.14265,3.18705 6.84015,3.18705 L6.84015,3.18705 Z M21.78195,3.18705 C22.61145,3.18705 23.28195,3.85905 23.28195,4.68705 L23.28195,15.09105 C23.28195,15.91905 22.61145,16.59105 21.78195,16.59105 L20.46195,16.59105 C19.63395,16.59105 18.96195,15.91905 18.96195,15.09105 L18.96195,4.68705 C18.96195,3.85905 19.63395,3.18705 20.46195,3.18705 L21.78195,3.18705 Z"
      />
    </svg>
  );
};

export default ThumbDown;
