import styled from 'styled-components';

export type TitleBlockProps = {
  title?: string;
};

function TitleBlock({ title }: TitleBlockProps) {
  return (
    <S_TitleBlock>
      <S_Title>{title}</S_Title>
    </S_TitleBlock>
  );
}

const S_TitleBlock = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 24px 24px 0 0;
  box-sizing: border-box;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  text-align: left;
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`;

const S_Title = styled.div`
  color: ${({ theme }) => theme.black};
  font-size: ${({ theme }) => theme.fontSizeH2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  line-height: ${({ theme }) => theme.fontLineHeight};
  white-space: pre-line;
`;

export default TitleBlock;
