import { useTranslation } from 'react-i18next';

import { Button } from '@common/ui/components';
import styled from 'styled-components';

export type Props = {
  nickname?: string;
  imageSrc?: string;
  email?: string;
  onClickEdit: (...args: any) => any;
};

function ProfileItem({ nickname, imageSrc, onClickEdit, email }: Props) {
  const { t } = useTranslation('translation');
  return (
    <S_ProfileItem>
      <S_Img src={imageSrc} alt="ProfileImg" />
      <S_Nickname>{nickname} </S_Nickname>
      <S_Email>{`(${email})`}</S_Email>
      <Button
        onClick={onClickEdit}
        line
        size="regular"
        label={t('str_edit_profile', '프로필 편집')}
      />
    </S_ProfileItem>
  );
}

const S_ProfileItem = styled.span`
  align-items: center;
  border-bottom: 16px solid ${({ theme }) => theme.grey50};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;
  padding: 24px 0;
`;

const S_Img = styled.img`
  border-radius: 9999px;
  height: 96px;
  margin-bottom: 8px;
  overflow: hidden;
  width: 96px;
`;

const S_Nickname = styled.div`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeH2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
`;

const S_Email = styled.span`
  color: ${({ theme }) => theme.grey400};
  font-size: ${({ theme }) => theme.fontSizeCaption1};
  margin: 4px 0 16px;
`;

export default ProfileItem;
