import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components';

import { EpisodeType, SeriesType } from '@common/redux/modules/VODPlusPApp';
import { Icon } from '@common/ui/components';

import { EpisodeEntry } from '../../../components/molecules';

type Props = {
  offsetBottom: number;
  episodes: EpisodeType[];
  series: SeriesType | null;
  invitationCode: string;
  episodeId: string;
};

function BottomSheet({ offsetBottom, episodes, series, invitationCode, episodeId }: Props) {
  const { t } = useTranslation('translation');

  const [isBottomSheetOpen, setBottomSheetOpen] = useState<boolean>(false);
  const [isBottomSheetFullOpen, setBottomSheetFullOpen] = useState<boolean>(false);

  const { title, totalEpisodes } = { ...series };

  const handleBottomSheet = () => {
    if (isBottomSheetFullOpen || isBottomSheetOpen) {
      setBottomSheetOpen(false);
      setBottomSheetFullOpen(false);
      return;
    }

    setBottomSheetOpen(true);
  };

  const handleSwipe = useSwipeable({
    onSwipedUp: () => {
      if (!isBottomSheetOpen) {
        setBottomSheetOpen(true);
        return;
      }
      setBottomSheetFullOpen(true);
    },
    onSwipedDown: () => {
      setBottomSheetOpen(false);
      setBottomSheetFullOpen(false);
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    trackTouch: true
  });

  return (
    <S_BottomSheet
      isBottomSheetOpen={isBottomSheetOpen}
      bottom={offsetBottom}
      isOpenFull={isBottomSheetFullOpen}
    >
      <S_Header {...handleSwipe}>
        <S_SeriesIcon iconName="ic_VideoPlaylist" size={16} fill />

        <S_SeriesInfo>
          {title}
          <S_Count>
            {t('str_54', {
              playlistVodCnt: totalEpisodes,
              defaultValue: '총 {{playlistVodCnt}}개'
            })}
          </S_Count>
        </S_SeriesInfo>

        <S_ArrowButton
          iconName={isBottomSheetOpen ? 'ic_ArrowDown' : 'ic_ArrowUp'}
          colorCode="grey500"
          size={16}
          onClick={handleBottomSheet}
        />

        {isBottomSheetOpen && <S_GreyBar />}
      </S_Header>

      {isBottomSheetOpen && (
        <S_Series>
          {episodes.length > 0 &&
            episodes.map((entry) => (
              <div key={entry.postId}>
                <EpisodeEntry
                  entry={entry}
                  invitationCode={invitationCode}
                  isCurrentVOD={+episodeId === entry.postId}
                />
              </div>
            ))}
          <S_EmptyDiv />
        </S_Series>
      )}
    </S_BottomSheet>
  );
}

const S_BottomSheet = styled.div<{
  isBottomSheetOpen: boolean;
  bottom: number;
  isOpenFull: boolean;
}>`
  background-color: white;
  border-radius: 24px 24px 0 0;
  bottom: 0;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  height: ${({ isBottomSheetOpen, bottom, isOpenFull }) => {
    if (isBottomSheetOpen) {
      if (isOpenFull) return '100%';
      return `calc(100% - ${bottom}px)`;
    }

    return '72px';
  }};
  position: fixed;
  text-align: left;
  touch-action: 'pan-y';
  transition: height 0.2s ease-in-out;
  width: 100vw;
  z-index: 1;
`;

const S_Header = styled.div`
  display: flex;
  margin-bottom: 12px;
  padding-top: 20px;
  touch-action: 'pan-y';
`;

const S_Series = styled.div`
  display: 'flex';
  flex-direction: 'column-reverse';
  height: 100%;
  overflow: auto;
  touch-action: 'pan-y';
  &::-webkit-scrollbar {
    display: none !important;
  }
`;

const S_SeriesIcon = styled(Icon)`
  margin-left: 24px;
`;

const S_SeriesInfo = styled.div`
  color: ${({ theme }) => theme.grey900};
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.fontSizeCaption1};
  gap: 4px;
  margin-left: 12px;
  text-align: left;
`;

const S_Count = styled.span`
  color: ${({ theme }) => theme.grey500};
`;

const S_ArrowButton = styled(Icon)`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 24px;
  position: absolute;
  right: 0;
  top: 28px;
`;

const S_GreyBar = styled.div`
  background-color: #d8d8d8;
  border-radius: 2px;
  height: 4px;
  left: 0;
  margin: 0 131px 4px 59px;
  margin: auto;
  margin-top: 8px;
  position: absolute;
  right: 0;
  top: 0;
  width: 35px;
`;

const S_EmptyDiv = styled.div`
  height: 72px;
`;

export default BottomSheet;
