import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { VODPLUS_SERIES_DEFAULT_LIMIT, VODPLUS_SERIES_NEXT_LIMIT } from '@common/constants/index';
import { makeGetCurrentChannel } from '@common/redux/modules/channel';
import { getSerieses } from '@common/redux/modules/VODPlusPApp';
import {
  fetchSeriesesLoadMore,
  cleanUpAllData,
  fetchSeriesesNewLabel
} from '@common/redux/modules/VODPlusPApp/reducers';

import { StickyTop, SeriesList, NavBar, SubTab } from '../../components';

import { CategorizeTab } from './organisms';

function VodPlusSeries() {
  const { t } = useTranslation('translation');
  const location = useLocation<{
    category: string;
    labelId?: number;
    searchInput?: string;
  }>();
  const dispatch = useDispatch();

  const { category, labelId, searchInput } = location.state || '';

  const getCurrentChannel = useMemo(makeGetCurrentChannel, []);
  const currentChannel = useSelector(getCurrentChannel);

  const [channelId, setChannelId] = useState<number>(0);
  const [invitationCode, setInvitationCode] = useState<string>('');
  const [lastPlaylistId, setLastPlaylistId] = useState<number>(0);
  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const serieses = useSelector(getSerieses);

  const handleScrollDown = () => {
    setLastPlaylistId(serieses[serieses.length - 1].id);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!currentChannel) return;

    setInvitationCode(currentChannel.primitiveInvitationCode);
    setChannelId(currentChannel.id);
  }, [currentChannel]);

  useEffect(() => {
    if (!channelId) return;
    if (!category && !searchInput) {
      dispatch(
        fetchSeriesesLoadMore({
          limit: lastPlaylistId ? VODPLUS_SERIES_NEXT_LIMIT : VODPLUS_SERIES_DEFAULT_LIMIT,
          cursor: lastPlaylistId > 0 ? lastPlaylistId : undefined,
          channelId
        })
      );
    }
    if (category) {
      dispatch(
        fetchSeriesesNewLabel({
          limit: lastPlaylistId ? VODPLUS_SERIES_NEXT_LIMIT : VODPLUS_SERIES_DEFAULT_LIMIT,
          labelId,
          channelId
        })
      );
    }
    if (searchInput) {
      dispatch(
        fetchSeriesesNewLabel({
          limit: lastPlaylistId ? VODPLUS_SERIES_NEXT_LIMIT : VODPLUS_SERIES_DEFAULT_LIMIT,
          search: searchInputValue,
          channelId
        })
      );
    }
  }, [dispatch, lastPlaylistId, channelId, category, labelId, searchInput]);

  useEffect(() => {
    dispatch(cleanUpAllData());
  }, [dispatch]);

  return (
    <>
      <StickyTop>
        <NavBar isMenu title={t('str_papp_vodp_name', '시청하기')} />
        <SubTab invitationCode={invitationCode} />
        <CategorizeTab
          invitationCode={invitationCode}
          category={category || ''}
          labelId={labelId}
          channelId={channelId}
          setSearchInputValue={setSearchInputValue}
        />
      </StickyTop>
      {serieses.length === 0 ? (
        <S_NoItem>
          {t('str_papp_vodp_search_no_results_found', '아직 업로드한 시리즈가 없습니다.')}
        </S_NoItem>
      ) : (
        <S_ScrollField
          dataLength={serieses.length}
          next={handleScrollDown}
          hasMore={serieses[serieses.length - 1].id !== lastPlaylistId}
        >
          <SeriesList
            seriesList={serieses}
            margin="0 16px 12px 16px"
            zeroTop
            invitationCode={invitationCode}
          />
        </S_ScrollField>
      )}
    </>
  );
}

const S_ScrollField = styled(InfiniteScroll as any)`
  margin: none;
`;

const S_NoItem = styled.div`
  color: ${({ theme }) => theme.grey400};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  margin-top: 96px;
`;

export default VodPlusSeries;
