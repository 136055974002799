import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';

import resources from './resources';

i18n
  .use(LanguageDetector)
  .use(intervalPlural)
  .use(initReactI18next)
  .init({
    resources,
    ns: ['glossary', 'validation', 'screens', 'common'],
    defaultNS: 'common',
    load: 'languageOnly',
    fallbackLng: 'ko'
  });

// override getSuffix method to support plurals in 'ko'
const originalGetSuffix = i18n.services.pluralResolver.getSuffix;
i18n.services.pluralResolver.getSuffix = (code: string, count: number, ...other: any[]) => {
  if (code === 'ko') {
    return count > 1 ? '_plural' : '';
  }

  return originalGetSuffix.call(i18n.services.pluralResolver, code, count, ...other);
};

export default i18n;
