import React from 'react';

import styled from 'styled-components';

import { Icon } from '@common/ui/components';

type Props = {
  title?: string;
  icon: React.ReactElement;
  disabled: boolean;
  onClick: () => void;
};

function SubmitNavBar({ title, icon, disabled, onClick }: Props) {
  return (
    <S_SubmitNavBar>
      <S_CloseButton onClick={onClick}>
        <Icon iconName="ic_Xmark" />
      </S_CloseButton>
      <S_Title>{title}</S_Title>
      <S_UploadButton type="submit" disabled={disabled}>
        {icon}
      </S_UploadButton>
    </S_SubmitNavBar>
  );
}

const S_SubmitNavBar = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  border-bottom: 1px solid #e8eaed;
  box-sizing: border-box;
  display: flex;
  height: 56px;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  z-index: 1;
`;

const S_CloseButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-left: 8px;
  width: 48px;
`;

const S_Title = styled.h1`
  color: ${({ theme }) => theme.grey900};
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  line-height: normal;
  text-align: center;
`;

const S_UploadButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-right: 8px;
  outline: none;
  width: 48px;
`;

export default SubmitNavBar;
