import IconType from '../IconType';

const ArrowRight2 = ({ color, size, ...rest }: IconType) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M13.281375,4.0455 L20.098875,10.8645 C20.397375,11.16 20.560875,11.5545 20.560875,11.9745 C20.560875,12.396 20.397375,12.7905 20.097375,13.089 L13.281375,19.9065 C13.134375,20.052 12.942375,20.1255 12.750375,20.1255 C12.558375,20.1255 12.366375,20.052 12.219375,19.9065 C11.926875,19.6125 11.926875,19.1385 12.219375,18.8445 L19.038375,12.027 L12.219375,5.106 C11.926875,4.8135 11.926875,4.338 12.219375,4.0455 C12.513375,3.7515 12.987375,3.7515 13.281375,4.0455 Z M7.281375,4.0455 L14.098875,10.8645 C14.397375,11.16 14.560875,11.5545 14.560875,11.9745 C14.560875,12.396 14.397375,12.7905 14.097375,13.089 L7.281375,19.9065 C7.134375,20.052 6.942375,20.1255 6.750375,20.1255 C6.558375,20.1255 6.366375,20.052 6.219375,19.9065 C5.926875,19.6125 5.926875,19.1385 6.219375,18.8445 L13.038375,12.027 L6.219375,5.106 C5.926875,4.8135 5.926875,4.338 6.219375,4.0455 C6.513375,3.7515 6.987375,3.7515 7.281375,4.0455 Z"
      />
    </svg>
  );
};

export default ArrowRight2;
