import PropTypes from 'prop-types';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function PublicRoute({
  isAuthenticated,
  guestOnly,
  ...rest
}: {
  isAuthenticated?: boolean;
  guestOnly?: boolean;
  path: string;
  exact?: boolean;
  component: any;
}) {
  return guestOnly && isAuthenticated ? <Redirect to="/private-zone" /> : <Route {...rest} />;
}

PublicRoute.defaultProps = {
  isAuthenticated: false,
  guestOnly: false
};

PublicRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  guestOnly: PropTypes.bool
};

export default PublicRoute;
