import styled from 'styled-components';

type DescImageProps = {
  coverUrl?: string;
  ratio?: number;
};

/**
 * 채널 소개 페이지 - 커버 이미지
 *
 * @param {Object} props
 * @param {number} props.ratio 이미지 고정비율 (가로크기/세로크기)
 * @param {string} props.coverUrl 이미지 주소
 */
function DescImage({ ratio = 4 / 3, coverUrl = '' }: DescImageProps) {
  return (
    <S_DescImage
      style={{
        paddingTop: ratio && `${Math.floor(100 / ratio)}%`
      }}
    >
      <S_Image
        style={{
          backgroundImage: `url(${coverUrl})`,
          backgroundSize: 'cover'
        }}
      />
    </S_DescImage>
  );
}

const S_DescImage = styled.span`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: -30px 0 100px -100px #000, 30px 0 100px -100px #000;
  display: block;
  position: relative;
  width: 100%;
`;

const S_Image = styled.span`
  background-position: center;
  background-repeat: no-repeat;
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export default DescImage;
