import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { BasicList } from '@common/ui/patterns';
import { InfoItem } from '../../organisms';
import { NavView } from '../../templates';
import {
  Divider,
  InformationListItem,
  HeaderBar,
  MainButton,
  TextLabel
} from '@common/ui/components';

import { SellerInfoState } from '@common/redux/modules/channel/types';
import { SmartLink } from '@common/ui/components/SmartLink';

type Props = {
  sellerInfo: SellerInfoState;
};

function PersonalSellerInfo({ sellerInfo }: Props) {
  const { t } = useTranslation('translation');

  const { operatorName, email } = sellerInfo;

  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  const goToTermsSellerSubscriber = () => {
    const win = window.open('https://publr.co/ko/terms-seller-subscriber/', '_blank');
    win?.focus();
  };

  return (
    <S_NavView
      nav={
        <HeaderBar
          titleText={t('str_channel_seller_info', '판매자 정보')}
          leftBtnMode="back"
          handleAction={handleBack}
        />
      }
    >
      <InfoItem />
      <S_DividerBlock>
        <Divider />
      </S_DividerBlock>

      <BasicList>
        <InformationListItem
          titleText={t('str_1390', '대표자')}
          descText={operatorName}
          iconMode="none"
        />
        <InformationListItem titleText={t('str_1390', '대표자')} descText={email} iconMode="none" />
        <S_ButtonWrapper>
          <MainButton
            text={t('str_terms', '이용약관')}
            size="small"
            fillType="line"
            handleAction={goToTermsSellerSubscriber}
          />
        </S_ButtonWrapper>
      </BasicList>
      <S_TermDesc>
        <S_TermDescUpperWrapper>
          <S_TermDescUpperBox>
            <SmartLink openNewTab to="https://publr.co/ko/terms-for-user/ ">
              <TextLabel text={t('str_1392', 'publ 이용약관')} textStyle="body2Bold" />
            </SmartLink>
            <Divider direction="vertical" height={16} />
            <SmartLink openNewTab to="https://publr.co/ko/privacy/">
              <TextLabel text={t('str_1391', 'publ 개인정보 정책')} textStyle="body2Bold" />
            </SmartLink>
          </S_TermDescUpperBox>
        </S_TermDescUpperWrapper>
        <TextLabel
          text={t(
            'str_1393',
            'publ은 상품 및 서비스에 직접 관여하지 않습니다. publ은 거래 당사자간 원활한 의사소통, 상담 등 거래이행을 위하여 필요한 최소한의 개인정보를 관리하고 있습니다.'
          )}
          textStyle="caption1Regular"
        />
      </S_TermDesc>
    </S_NavView>
  );
}

const S_NavView = styled(NavView)`
  box-shadow: 0;
`;

const S_DividerBlock = styled.div`
  padding-bottom: 24px;
`;

const S_ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 32px;
`;

const S_TermDesc = styled.div`
  margin-top: 195px;
  padding: 0 24px;
`;

const S_TermDescUpperWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 16px;
`;

const S_TermDescUpperBox = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export default PersonalSellerInfo;
