import NukaCarousel from 'nuka-carousel';
import styled from 'styled-components';

import {
  MainBannerSlidesSectionItems,
  VODPlusHomeSectionType
} from '@common/redux/modules/VODPlusPApp/types';

import { HomeCarouselContents } from '../molecules';

type Props = {
  sectionData: VODPlusHomeSectionType;
  invitationCode: string;
};

const renderDotControls = ({ currentSlide, slideCount }: any) => {
  const dotList = [...Array(slideCount)].map((x, i) => ({ id: i }));

  return (
    <>
      <S_DotList>
        {dotList.map((_, i) => (
          <S_Dot key={i.toString()} currentSlide={currentSlide} i={i} />
        ))}
      </S_DotList>
    </>
  );
};
function HomeCarousel({ sectionData, invitationCode }: Props) {
  const { mainBannerSlidesSectionItems } = sectionData;
  const orderedBannerItems = mainBannerSlidesSectionItems
    ?.slice()
    .sort((a, b): number => a.order - b.order);

  const bannerList: MainBannerSlidesSectionItems[] = [];

  if (orderedBannerItems && orderedBannerItems.length > 0)
    orderedBannerItems.map((item) => {
      if (item.isActive) {
        bannerList.push(item);
      }
      return;
    });

  return (
    <S_HomeCarousel>
      <NukaCarousel
        cellAlign="center"
        initialSlideHeight={100}
        autoplay
        wrapAround
        autoplayInterval={15000}
        renderCenterLeftControls={null}
        renderCenterRightControls={null}
        renderBottomCenterControls={null}
        renderBottomLeftControls={(props) => renderDotControls(props)}
      >
        {bannerList?.map((item, i) => {
          if (item.isActive) {
            return (
              <div key={i.toString()}>
                <HomeCarouselContents carouselItem={item} invitationCode={invitationCode} />
              </div>
            );
          }
          return;
        })}
      </NukaCarousel>
    </S_HomeCarousel>
  );
}

const S_HomeCarousel = styled.span`
  position: relative;
`;

const S_DotList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin: 16px 0 0 24px;
`;

const S_Dot = styled.div<{ currentSlide: number; i: number }>`
  background-color: ${({ currentSlide, i, theme }) =>
    currentSlide === i ? theme.blue500 : theme.grey400};
  border-radius: 8px;
  height: 8px;
  width: 8px;
`;

export default HomeCarousel;
