import { useTranslation } from 'react-i18next';

import { SmartLink } from '@common/ui/components';
import styled from 'styled-components';

import { buildChannelsAuthPath } from '@routers/routes';

function DescNavBar({
  channelID,
  isAuthenticated
}: {
  channelID: string;
  isAuthenticated?: boolean;
}) {
  const { t } = useTranslation('translation');

  return (
    <S_DescNavBar>
      {!isAuthenticated && (
        <SmartLink to={buildChannelsAuthPath(channelID)}>
          <S_Title>{t('str_sign_in', '로그인')}</S_Title>
        </SmartLink>
      )}
    </S_DescNavBar>
  );
}

const S_DescNavBar = styled.div`
  align-items: center;
  display: flex;
  height: 56px;
  justify-content: flex-end;
  left: 0;
  position: sticky;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 10;
`;

const S_Title = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  display: flex;
  font-size: ${({ theme }) => theme.fontSizeBody2};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  height: 56px;
  justify-content: center;
  padding-right: 8px;
  text-shadow: 1px 1px 1px gray;
  width: 48px;

  &:hover {
    color: ${({ theme }) => theme.grey50};
  }

  &:active {
    text-shadow: 1px 1px 1px black;
  }
`;

export default DescNavBar;
