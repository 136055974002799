import { useEffect, useState } from 'react';
import styled from 'styled-components';

import YoutubeLinkParser from '@common/utils/youtubeLinkParser';
import { SmartLink } from '@common/ui/components';
import { STORY_TYPES } from '@common/constants/index';

import { ProfileBar, PostMenuBar } from '../../../components/organisms';
import { buildPostDetailPath } from '@routers/routes';

import Contents from './Contents';
import { useDispatch } from 'react-redux';
import { dislikePost, likePost } from '@common/redux/modules/socialCommunityPApp/reducers';

export type Props = {
  author: string;
  authorThumbnailSrc: string;
  content: string;
  type: string;
  previews: string[] | string | null;
  id: number;
  channelCode?: string;
  createdAt: string;
  isAuthor: boolean;
  commentCount: number | null;
  likeCount: number;
  dislikeCount: number;
  onPopUpMenuClick: (value: string, id?: number) => void;
  channelId: number;
  isLiked: boolean;
  isDisliked: boolean;
};

function PostCard({
  id,
  channelCode,
  author,
  authorThumbnailSrc,
  content,
  type,
  previews,
  commentCount,
  createdAt,
  isAuthor,
  likeCount,
  dislikeCount,
  onPopUpMenuClick,
  channelId,
  isLiked,
  isDisliked
}: Props) {
  const dispatch = useDispatch();
  const [images, setImages] = useState<string[] | string | null>(null);

  const [hasLike, setHasLike] = useState(isLiked);
  const [hasDislike, setHasDislike] = useState(isDisliked);

  const [totalLikeCount, setTotalLikeCount] = useState(likeCount);
  const [totalDisikeCount, setTotalDisikeCount] = useState(dislikeCount);

  useEffect(() => {
    if (!previews) setImages(null);

    if (type === STORY_TYPES.VIDEO && previews) {
      const [videoThumbnail] = previews;
      setImages(videoThumbnail);
      return;
    }

    if (type === STORY_TYPES.YOUTUBE && previews) {
      setImages(YoutubeLinkParser.getThumbnailUrl(previews as string));
      return;
    }

    setImages(previews);
  }, [previews, type]);

  const handleClickLikeButton = (id: number | string) => {
    setHasLike(!hasLike);
    hasLike ? setTotalLikeCount(totalLikeCount - 1) : setTotalLikeCount(totalLikeCount + 1);
    dispatch(likePost({ channelId, postId: id, isLiked: !hasLike }));
  };

  const handleClickDisikeButton = (id: number | string) => {
    setHasDislike(!hasDislike);
    hasDislike
      ? setTotalDisikeCount(totalDisikeCount - 1)
      : setTotalDisikeCount(totalDisikeCount + 1);
    dispatch(dislikePost({ channelId, postId: id, isDisliked: !hasDislike }));
  };

  return (
    <S_PostCard>
      <ProfileBar
        id={id}
        authorThumbnailSrc={authorThumbnailSrc}
        author={author}
        createdAt={createdAt}
        isAuthor={isAuthor}
        onClick={onPopUpMenuClick}
      />

      <S_ContentsWrapper>
        <SmartLink to={buildPostDetailPath(channelCode, id)}>
          <Contents coverImageSrc={images} content={content} />
        </SmartLink>
      </S_ContentsWrapper>

      <S_MenuBarWrapper>
        <PostMenuBar
          isLiked={hasLike}
          likeCount={totalLikeCount}
          isDisliked={hasDislike}
          dislikeCount={totalDisikeCount}
          hasComment
          commentCount={commentCount}
          id={id}
          onClickLikeButton={handleClickLikeButton}
          onClickDislikeButton={handleClickDisikeButton}
        />
      </S_MenuBarWrapper>
    </S_PostCard>
  );
}

const S_PostCard = styled.div`
  padding: 32px 0;
  text-align: left;

  &:first-child {
    padding-top: 28px;
  }
`;

const S_ContentsWrapper = styled.div`
  margin: 12px 0;
`;

const S_MenuBarWrapper = styled.div`
  margin-top: 12px;
  padding: 0 24px;
`;

export default PostCard;
