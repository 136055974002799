/* eslint-disable */
class VirtualProduct {
  constructor(virtualProduct) {
    const { id, productId, productModel, productName, productPrice, recurring } = virtualProduct;

    (this.id = id), (this.productId = productId);
    this.productModel = productModel;
    this.productName = productName;
    this.productPrice = productPrice;
    this.recurring = recurring;
  }

  setId(id) {
    this.id = id;
  }

  static fromOwnDefinedProduct(ownDefinedProduct) {
    const {
      id: productId,
      model: productModel,
      name: productName,
      price: productPrice,
      recurring
    } = ownDefinedProduct;

    return new VirtualProduct({
      productId,
      productModel,
      productName,
      productPrice,
      recurring
    });
  }

  toJSON() {
    return {
      id: this.id,
      productId: this.productId,
      productModel: this.productModel,
      productName: this.productName,
      productPrice: this.productPrice,
      recurring: this.recurring
    };
  }
}

export default VirtualProduct;
