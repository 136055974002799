import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Button } from '@common/ui/components';
import styled from 'styled-components';

function ChannelNoPApp() {
  const { t } = useTranslation('translation');
  const history = useHistory();

  return (
    <S_NoPApp>
      {t('str_no_active_papp', 'No Active pApp')}
      <Button onClick={() => history.push('/account')} label={t('str_ok', '확인')} />
    </S_NoPApp>
  );
}

const S_NoPApp = styled.div`
  align-items: center;
  box-shadow: -30px 0 100px -100px #000, 30px 0 100px -100px #000;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  width: 100%;
`;

export default ChannelNoPApp;
